import React, { useState, useEffect } from 'react';

const ReserveApproval = () => {
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        fetchEFTBookings();
    }, []);

    const fetchEFTBookings = () => {
        fetch('https://backendjaes.passiontrials.co.za/api/eft-reserve')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => setBookings(data))
            .catch((err) => setError('Failed to fetch reserve'))
            .finally(() => setLoading(false)); // Stop loading
    }

    const handleApproval = (id, status, user, room, transport, funding, dateCreated, plan, amount) => {
        fetch(`https://backendjaes.passiontrials.co.za/api/eft-reserve/${user}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ApprovalStatus: status, Approval: status === 'Approved' ? 1 : 0, }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update approval status');
                }
                return response.json();
            })
            .then(() => {
                // setBookings((prevBookings) =>
                //     prevBookings.map((booking) =>
                //         booking.Id === id
                //             ? { ...booking, ApprovalStatus: status, Approval: status === 'Approved' }
                //             : booking
                //     )
                // );
                if (status == 'Approved') {
                    associateUserWithApartment(room, user, transport, funding, dateCreated, plan, amount);


                }

                if (status == 'Dismissed') {
                    dismissUser(user);
                }
                fetchEFTBookings();


            })
            .catch((err) => {
                console.error(`Error updating booking with ID ${id}:`, err);
            });
    };


    // const markApartmentAsOccupied = async (roomid) => {
    //     try {
    //         // Ensure apartmentId exists before proceeding
    //         if (roomid) {
    //             // Decrement the Capacity2 in the apartment_tb table
    //             const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${roomid}`, {
    //                 method: 'PUT',
    //                 headers: { 'Content-Type': 'application/json' }
    //             });

    //             if (!decrementCapacity.ok) {
    //                 const errorText = await decrementCapacity.text();
    //                 throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
    //             }

    //             console.log('Apartment capacity has been decremented successfully');
    //         } else {
    //             console.log('No apartment or room found');
    //         }
    //     } catch (error) {
    //         throw new Error(`Error marking apartment as occupied: ${error.message}`);
    //     }
    // };

    const associateUserWithApartment = async (room, user, transport, funding, dateCreated, plan, amount) => {
        try {

            console.log('this is the date ', dateCreated);

            const validDate = new Date(dateCreated);
            if (isNaN(validDate)) {
                throw new Error('Invalid date');
            }

            const formattedDate = validDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });

            const response = await fetch('https://backendjaes.passiontrials.co.za/reserve', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    MoveInDate: formattedDate,
                    MoveOutDate: '30 November 2025', // Use the formatted date
                    // MoveInDate: 'test',
                    // MoveOutDate: 'test',
                    RentAmount: amount,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'No specific notes',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: plan,
                    ServiceFee: 0,
                    selectedTransport: transport,
                    selectedFunding: funding
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate user with reserve table: ${errorText}`);
            }

            console.log('User associated with reserve table successfully!');
        } catch (error) {
            throw new Error(`Error associating user with reserve table: ${error.message}`);
        }
    };

    const dismissUser = async (userid) => {

        const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/api/reserve/cancel/${userid}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!removeApartmentFromUser.ok) {
            if (removeApartmentFromUser.status !== 404) { // Ignore 404 errors as this might be expected
                const errorText = await removeApartmentFromUser.text();
                throw new Error(`Failed to delete user reserve record: ${errorText}`);
            } else {
                console.log('No reserve record found for the user, which is expected.');
            }
        } else {
            console.log('reserve has been removed from user successfully');
        }

        // Increment the Capacity2 for the ApartmentId

    };


    if (loading) {
        return <div>Loading reservations...</div>;
    }

    if (error) {
        return console.log('an error occured', error);
    }

    return (
        <div className="eft-bookings-container">
            <h1>EFT Reservations</h1>
            {bookings.length === 0 ? ( // Check if bookings array is empty
                <div>No reservations found.</div>
            ) : (
                <table className="eft-bookings-table">
                    <thead>
                        <tr>
                            <th>Reservation Number</th>
                            <th>Full Name</th>
                            <th>Funding Type</th>
                            <th>Email</th>
                            <th>Approval Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking) => (
                            <tr key={booking.Id}>
                                <td>{booking.ReservationNumber}</td>
                                <td>{booking.FullName}</td>

                                <td>{booking.TypeOfFunding}</td>
                                <td>{booking.Email}</td>
                                <td>{booking.ApprovalStatus}</td>
                                <td>
                                    <button
                                        onClick={() => handleApproval(booking.Id, 'Approved', booking.UserId, booking.ApartmentId, booking.TransportStatus, booking.TypeOfFunding, booking.CreatedAt, booking.ReservePlan, booking.ReserveAmount)}
                                        disabled={booking.ApprovalStatus === 'Approved'}
                                        style={{ backgroundColor: booking.ApprovalStatus === 'Approved' ? 'gray' : 'green' }}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => handleApproval(booking.Id, 'Dismissed', booking.UserId, booking.ApartmentId, booking.TransportStatus, booking.TypeOfFunding, booking.CreatedAt, booking.ReservePlan, booking.ReserveAmount)}
                                        disabled={booking.ApprovalStatus === 'Dismissed'}
                                        style={{ backgroundColor: booking.ApprovalStatus === 'Dismissed' ? 'gray' : 'red' }}
                                    >
                                        Dismiss
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )
            }
            <style>{`
                .eft-bookings-container {
                    padding: 20px;
                }
                .eft-bookings-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px;
                }
                .eft-bookings-table th,
                .eft-bookings-table td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                .eft-bookings-table th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                }
                .eft-bookings-table tr:hover {
                    background-color: #f1f1f1;
                }
                .eft-bookings-table button {
                    margin-right: 10px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
            `}</style>
        </div >
    );
};

export default ReserveApproval;
