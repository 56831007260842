import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider'; //8 jan 2025


function TransportWallet() {
    const { isLoggedIn, logout, role, userId } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const errorPreventorRef = useRef();
    const [apartment, setApartment] = useState(null);


    const queryParams = new URLSearchParams(location.search);
    const amount = parseFloat(queryParams.get('amount'));
    const userID = parseFloat(queryParams.get('user'));
    const AAddress = queryParams.get('address'); // Extract 'address' from query parameters
    console.log("Address received:", AAddress);


useEffect(() => {
    // Fetch user data and check if images are missing
    const fetchUserDetails = async () => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/user_full_details/${userId}`);
            const data = await response.json();
            
            if (data && data.length > 0) {
                const userData = data[0];
                setApartment(userData); // Set apartment data in state
                
               
            } else {
                console.log('No user data found');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Failed to load user data');
        }
    };

    fetchUserDetails();
}, [userId]); // The effect depends on userId
    useEffect(() => {
        // Redirect if the payment has already been processed
        // if (sessionStorage.getItem('paymentProcessed')) {
        //     navigate('/userdashboard', { replace: true });
        //     return;
        // }

        if (!userID || !amount) {
            setError('Missing necessary data.');
            return;
        }

        
        // insertIntoWallet();
        if (!errorPreventorRef.current) {
            errorPreventorRef.current = true;
            transitionReserverToBooker();

        }
    }, [userID, amount, navigate]);

    const transitionReserverToBooker = async () => {
        try {
            await payTransport();
            setTimeout(() => navigate('/userDashboard', { replace: true }), 1000);
        } catch (error) {
            console.error('error recording transport record:', error);
            alert(`Error processing your transport transaction: ${error.message}`);
        }
    };

    const payTransport = async () => {
        const date = new Date();
        const currentMonth = date.toLocaleDateString('en-US', { month: 'long' });
        const currentYear = date.getFullYear();
        const paymentData = {
            UserId: userID,
            Month: `${currentMonth} ${date.getFullYear()}`,
            Price: amount,
            Building: AAddress, // Add the address field
        };
        // Initialize all months to 0
        const months = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0,
        };
        // Set the current month to 'Paid'
        months[currentMonth] = 'Paid';
        /// Add months data to paymentData
        Object.assign(paymentData, months);
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/transport_monthly_payment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(paymentData),
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Transport payment recorded successfully:', data);
            }


        }
        catch (error) {
            console.log('Failed record transport payment', error);
        }
    };



    if (error) {
        return <div style={{ marginTop: '149px' }}>Error: {error}</div>;
    }

    return (
        <div style={{ marginTop: '149px' }}>
            <h1>{status || 'Processing your payment...'}</h1>
            <p>Please wait, you will be redirected shortly.</p>
        </div>
    );
}

export default TransportWallet;

