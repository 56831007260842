
import React, { useState, useEffect } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { useAuth } from './AuthProvider'; // Ensure to import the useAuth hook

import './slides.css'
import './HeroSection.css';
import './tiles.css';

import './card.css';
import logo from './logowhitefooter.png';
import remove from './remove-location_12557391.png';
import wifi from './wifi-signal_3178314.png';
import chat from './chat-bubble_1048123.png';
import About from './E7E6162E-1C97-466B-B6E4-9C4334401A03_1_201_a.jpeg';

function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

function Home_page() {
  const [buildings, setBuildings] = useState([]);
  const { isLoggedIn, role } = useAuth(); // Use the isLoggedIn and role from useAuth


  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://backendjaes.passiontrials.co.za/displayapartment`)  // Endpoint to fetch all apartments
      .then(response => response.json())
      .then(data => {
        //  setBuildings(data); // Assume the data is an array of apartments
      })
      .catch(error => console.error("Error loading all buildings:", error));

    buildingInfo();
  }, []);

  const buildingInfo = () => {
    fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
      .then(res => res.json())
      .then(info => {
        setBuildings(info);
        console.log('this is the data that must be revealed', info);
      })
      .catch(error => {
        console.log('An error occurred', error);
      })
  };


  const [servicesShown, setServicesShown] = useState(3);
   // Changes made
   const services = [
    {
      title: 'Uncapped Wi-Fi',
      description: 'Fast Fibre Wi-Fi of up to 50 mbp/s.',
      img: 'fas fa-wifi' // Wi-Fi icon
    },
    {
      title: 'Transport',
      description: 'To and from campus at designated times.',
      img: 'fas fa-bus' // Bus icon
    },
    {
      title: 'Loadshedding Solar System',
      description: 'Backup power units with charging ports.',
      img: 'fas fa-bolt' // Bolt icon for solar power
    },
    {
      title: 'Fully Kitted Houses',
      description: 'Fully furnished with laundry and kitchen appliances.',
      img: 'fas fa-toolbox' // Toolbox icon for fully kitted houses
    },
    {
      title: 'Full-Time Administration',
      description: 'Full-time administrative team to help with queries and documents.',
      img: 'fas fa-server' // Server icon for administration
    },
    {
      title: 'Sky Glass TV',
      description: 'The smarter TV. Stream all your favorite movies and shows with ease.',
      img: 'fas fa-tv' // TV icon for Sky Glass TV
    },
    {
      title: 'Capacity Ratios',
      description: 'Our houses are set-up to ensure students share an equal amount of space.',
      img: 'fas fa-chart-pie' // Pie chart icon for capacity ratios
    },
    {
      title: 'JAES Alumni',
      description: 'Career opportunities for students to expand knowledge and secure jobs.',
      img: 'fas fa-suitcase' // Suitcase icon for JAES Alumni
    },
    {
      title: 'Youth Development',
      description: 'Programs that promote self-growth for students.',
      img: 'fas fa-bar-chart' // Bar chart icon for youth development
    }
  ];  
  const showMoreServices = () => {
    if (servicesShown < services.length) {
      setServicesShown(services.length); 
    } else {
      setServicesShown(3); 
    }
  };
  const allapartments = () => {
    navigate('/allapartments');
  };
  const userDashboard = () => {
    if (isLoggedIn) {
      // Redirect to the appropriate dashboard based on the role
      if (role === 'User') {
        navigate('/userDashboard');
      } else if (role === 'Admin') {
        navigate('/RegularAdmin');
      }  else if (role === 'reserve') {
        navigate('/ReserveUserDashboard');
      } else if (role === 'TransportOnly') {
        navigate('/TransportuserDashboard');
      } else if (role === 'Super Admin') {
        navigate('/NewAdminDashboard');
      } else if (role === 'SalesAndServiceProvider') {
        navigate('/NewAdminDashboard');
      } else if (role === 'Transport') {
        navigate('/transport-admin');
      } else if (role === 'Media') {
        navigate('/event-details');
      } else {
        console.log("Unknown role");
      }
    } else {
      // If not logged in, navigate to login page
      navigate('/login');
    }
  };
  const ContactUs = () => {
    navigate('/ContactUs');
  };
  const listyourhome = () => {
    navigate('/ListHomes');
  };
  const Event_Ticket = () => {
    navigate('/Event_Ticket');
  };
  const Transport = () => {
    if (role === 'Transport') {
      navigate('/transport-admin');
    } else if (role === 'Super Admin') {
      navigate('/transport-admin');
    }else if (role === 'SalesAndServiceProvider') {
      navigate('/transport-admin');
    } else if (role === 'TransportOnly') {
      navigate('/TransportuserDashboard');
    } else{
       navigate('/IntroTransport');     
    }
    
  };
  return (
    <body>
   <div class="astral-viewport">
    <div class="nebula-construct">
        <div class="quasar-zone" onClick={allapartments}>
            <Link to="/allapartments">
            <h2 class="starlight-title">Student Accommodations</h2>
            </Link>
        </div>
        <div class="lunar-chamber">
        <div className="lunar-rise" onClick={userDashboard}>
        <h2 className="orbit-title">
                {role === 'User' && 'User Dashboard'} 
                {role === 'Admin' && 'Admin Dashboard'}
                {role === 'reserve' && 'Reserve Dashboard'}
                {role === 'TransportOnly' && 'Transport Dashboard'}
                {role === 'Super Admin' && 'Super Admin Dashboard'}
                {role === 'Transport' && 'Transport Admin Dashboard'}
                {role === 'Media' && 'Media Dashboard'}
                {isLoggedIn && !['User', 'Admin', 'reserve', 'TransportOnly', 'Super Admin', 'Transport', 'Media'].includes(role) && 'Dashboard'}
                {!isLoggedIn && 'Login'}
              </h2>
            </div>
            <div class="solar-dive"  onClick={listyourhome}>
               
                <h2 class="orbit-title">List your Home</h2>
            
            </div>
        </div>
    </div>

    <div class="comet-ribbon">
        <div class="starfal-spark">
            <Link to="/">
            <h2 class="galactic-header">Food</h2>
            </Link>
        </div>
        <div class="cosmic-surge"  onClick={Transport}>
            <Link >
            <h2 class="galactic-header">Transport</h2>
        </Link>
        </div>
        <div class="galactic-glow"  onClick={Event_Ticket}>
        <Link to="/Event_Ticket" >
            <h2 class="galactic-header">Rewards & Benefits</h2>
        </Link>
        </div>

        <div class="starfall-spark"  onClick={ContactUs}>
            <Link to="/ContactUs">
            <h2 class="galactic-header">Contact Us</h2>
            </Link>
        </div>
    </div>
</div>











      {/* <section class="w3l-aboutblock py-lg-5 py-4" id="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_2865-scaled.jpg" alt="" class="img-fluid radius-image" />
            </div>

            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5 text-start">
              <div class="title-main">
                <p>About Us</p>
                <h3 class="title-style">THE PERFECT NEW HOME</h3>
              </div>
              <p class="mt-3">Step into a world where students connect with welcoming homes, supportive landlords, and trusted academic partners.
                 Our platform is designed to make finding a safe and comfortable place to live easy and stress-free, 
                 so you can focus on what matters most—your studies and student life.</p>
              <ul class="mt-4 list-style-lis">
              <li><i class="fas fa-check-circle"></i>120+ Verified Listings</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>100% Satisfaction Guarantee</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>24+ Years of Experience</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>Over 10 Years of Experience You Can Trust</li>
              </ul>
              <a href="/Building" class="btn btn-style mt-5">Booking Now</a>
            </div>
          </div>
        </div>
      </section> */}

      <section class="w3l-index5 pb-5 pt-4">
        {/* <div class="container-fluid py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Featured tours</p>
            <h3 class="title-style">Most Popular Tours</h3>
          </div>
          <div className="building-gridHome">
            {displayedBuildings.map((build) => (
              <Link to={`/Apartments/${build.BuildingId}`} className="view-link" key={build.BuildingId}>
                <div className="room-cardHome">
                  <img src={`https://backendjaes.passiontrials.co.za/src${build.BuildingInteriorImage1}`} alt="Building Exterior" className="room-card-imageHome" />
                  <div className="room-card-content">
                    <h2 className="room-title">{build.BuildingName}</h2>
                    <p className="b-type"><i className="fas fa-map-marker-alt me-1"></i>{build.ZoneName}</p>
                    <div className="plan-options">
                      <button className="plan-option">{build.PreferedGender}</button>
                    </div>
                    <div className="room-details">
                      <div className="detail-item">
                        <p>From {build.Best_Price} per room</p>
                        <span>{build.Capacity > 0 ? `${build.Capacity} Beds Available` : 'No Beds Available'}</span>
                      </div> {/*
                      <div className="detail-item">
                        <p>Zone</p>
                        <span>{build.Zone}</span>
                      </div> 
                      <div className="detail-item">
                        <p>Sleeps</p>
                        <span>{build.Capacity}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {/* Add button to navigate to all buildings 
          <div className="text-center mt-4">
            <Link to="/Building">
              <button className="btn btn-style">View All Buildings</button>
            </Link>
          </div>
        </div> */}
      </section>
      {/* <section class="w3_stats py-5" id="stats"><div class="title-main text-center mx-auto mb-5" style={{ maxWidth: '600px' }}>
            <h3 class="title-style text-white">We have over 10 years Experience</h3>
          </div>
        <div class="container py-lg-5 py-md-4 py-2">
          
          <div class="w3-stats text-center py-lg-4">
            <div class="row">
               <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-route"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="200" data-speed="1500"></div>
                  </div>
                  <p class="count-text">Reliable Transportation services</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-smile"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="100" data-speed="1500"></div>
                
                  </div>
                  <p class="count-text">Safety Assured Accommodations</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-umbrella-beach"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="130" data-speed="1500"></div>
                  </div>
                  <p class="count-text">Consistently Beautiful Facilities </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
      <section className="w3l-grids-block py-5">
      <div className="title-main mb-3" style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
        <h2>Our Services</h2>
        <p className="title-style">We help students find beautiful homes</p>
      </div>

      <div className="py-lg-5 py-md-4 py-2">
        <div className="row text-center justify-content-center">
          {/* Render services based on servicesShown state 
          {services.slice(0, servicesShown).map((service, index) => (
            <div className="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4" key={index}>
              <div className="bottom-block smaller-items">
                <a href="tours.html" className="d-block">
                  <i className={service.img} style={{ fontSize: '40px' }} aria-hidden="true"></i>
                  <h3 className="my-3">{service.title}</h3>
                  <p>{service.description}</p>
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Show More/Less button positioned under services 
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary" onClick={showMoreServices}>
            {servicesShown < services.length ? 'Show More' : 'Show Less'}
          </button>
        </div>
      </div>
    </section>
      {/* <section class="w3l-whyblock pb-5 pt-2">
        <div class="container pb-lg-5 pb-md-4 pb-2">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img  src={About} alt="" class="img-fluid radius-image" />
            </div>
            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5">
              <div class="title-main mb-4" style={{ maxWidth: '600px' }}>
                <p>Our Features</p>
                <h3  style={{textAlign: "center"}} class="title-style">Why Pick Us !</h3>
              </div>
              <p style={{textAlign: "center"}}> Backed by years of trust and a wide range of verified listings, we ensure hassle-free living and transportation. Simplify your student life with a platform designed for your peace of mind!</p>
              <div class="two-grids mt-5">
                <div class="grids_info">
                  <i class="fas fa-certificate"></i>
                  <div class="detail">
                    <h4> 2025 NSFAS Approved accommodations</h4>
                    <p style={{textAlign: "left"}}>Find your ideal student home with NSFAS Approved Accommodations today.</p>
                  </div>
                </div>
                <div class="grids_info mt-xl-5 mt-4">
                  <i class="fas fa-money-bill-wave"></i>
                  <div class="detail">
                    <h4>Book student accommodation instantly</h4>
                    <p style={{textAlign: "left"}}>Instant booking for a seamless experience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <div class="w3l-blog-content py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Our Blog</p>
            <h3 class="title-style">Learn more from our latest Blog Posts</h3>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog1.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Trips</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>John</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 23 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 06, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">Travel the Most Beautiful Places in the
                    World</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog2.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Travels</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>Anton</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 24 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 07, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">A Place where Start New Life with Peace</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog3.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Journey</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>Miche</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 22 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 08, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">Top 10 Destinations & Adventure Trips</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <section class="w3l-promocode py-5">
        <div class="container ppy-lg-5 py-md-4 py-2">
          <div class="row aap-4-section align-items-center">
            <div class="col-lg-6 col-8 m-auto app4-right-image pe-md-5 text-center">
              <img src="assets/images/img.jpg" class="img-fluid radius-image" alt="App Device" />
            </div>
            <div class="col-lg-6 app4-left-text ps-lg-0 mb-lg-0 mb-5">
              <h6>For 30% Discount</h6>
              <h4>Get Our Promocode</h4>
              <p class="mb-4"> Uspendisse efficitur orci urna. In et augue ornare, tempor massa in, luctus
                sapien. Proin a
                diam et dui fermentum molestie vel id neque. </p>
              <div class="app-4-connection">
                <div class="newsletter">
                  <label>Never Miss a Deal !</label>
                  <form action="#" methos="GET" class="d-flex wrap-align">
                    <input type="email" placeholder="Enter your email id" required="required" />
                    <button type="submit" class="button-style">Get Promocode</button>
                  </form>
                </div>
                <p class="mobile-text-app mt-4 pt-2">(Or) To Get Our Mobile Apps</p>
                <div class="app-4-icon">
                  <ul>
                    <li><a href="#url" title="Apple" class="app-icon apple-vv"><span
                      class="fab fa-apple  icon-color" aria-hidden="true"></span></a></li>
                    <li><a href="#url" title="Google play" class="app-icon play-vv"><span
                      class="fab fa-google-play icon-color" aria-hidden="true"></span></a>
                    </li>
                    <li><a href="#url" title="Microsoft" class="app-icon windows-vv"><span
                      class="fab fa-windows icon-color" aria-hidden="true"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <footer className="w3l-footer-29-main py-10">
  <div className="container pt-lg-5 pt-sm-4 pt-2 pb-2">
    <div className="row footer-top-29">
      {/* Logo on the left 
      <div className="logoFooterItem">
        <Link className="btn-group-vertical" to="/">
          <img src={logo} alt="Tours Logo" className="" />
        </Link>
      </div>

      {/* Footer content side by side (Quick Links, Explore, Contact Info) 
      <div className="w3l-promocode">
        <div className="row footer-list-29">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <ul>
              <h6 className="footer-title-29">Quick Links</h6>
              <li><a href="about.html"style={{color:"white"}}>About Us</a></li>
              <li><a href="#services" style={{color:"white"}}>Services</a></li>
              <li><a href="/ContactUs" style={{color:"white"}}>Contact Us</a></li>
              <li><a href="#rewards" style={{color:"white"}}>Rewards</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <ul>
              <h6 className="footer-title-29" style={{color:"white"}}>Explore</h6>
              <li><a href="#blog" style={{color:"white"}}>Blog Posts</a></li>
              <li><a href="#privacy" style={{color:"white"}}>Privacy Policy</a></li>
              <li><a href="#partners" style={{color:"white"}}>Our Partners</a></li>
              <li><a href="#events" style={{color:"white"}}>Events</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12">
            <h6 className="footer-title-29">Contact Info</h6>
            <p className="mb-2" style={{color:"white"}}>Phone Number: <a href="tel:+27(81)5006508" style={{color:"white"}}>+27(81) 500 6508</a></p>
            <p className="mb-2" style={{color:"white"}}>Email: <a href="mailto:Info@jaes.co.za" style={{color:"white"}}>Info@jaes.co.za</a></p>
            <div className="mt-2">
              <ul className="social">
                <li><a href="https://web.whatsapp.com/send?phone=27815006508" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                <li><a href="https://www.tiktok.com/@jaesproperties" target="_blank"><i className="fab fa-tiktok"></i></a></li>
                <li><a href="https://www.instagram.com/jaesproperties" target="_blank"><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="copy-footer-234">
      <p style={{color:"white"}}>© 2024 Jae's Homes. All rights reserved. Design by <a href="https://vingamedia.co.za" target="_blank" style={{color:"white"}}>VingaMedia</a></p>
    </div>
        </div>
      </div>
    </div>
  </div>
</footer> */}
    </body>
  );
}
export default Home_page;
