import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './TransportHistory.css';

const AllTransportHistory = () => {
    const [history, setHistory] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const apiEndpoint = 'https://backendjaes.passiontrials.co.za/transport_monthly_payment';
    const userApiEndpoint = 'https://backendjaes.passiontrials.co.za/user_reserve/';

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await fetch(apiEndpoint);
                if (!response.ok) {
                    throw new Error('Failed to fetch history.');
                }
                const data = await response.json();
                setHistory(data);
                await fetchUserDetails(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchHistory();
    }, []);

    const fetchUserDetails = async (historyData) => {
        const userDetails = {};
        for (let record of historyData) {
            try {
                const response = await fetch(`${userApiEndpoint}${record.UserId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user details.');
                }
                const userData = await response.json();
                userDetails[record.UserId] = userData[0];
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }
        setUserDetails(userDetails);
    };

    const filteredHistory = history.filter(record => {
        const query = searchQuery.toLowerCase();
        return (
            userDetails[record.UserId]?.Name?.toLowerCase().includes(query) ||
            userDetails[record.UserId]?.Surname?.toLowerCase().includes(query) ||
            userDetails[record.UserId]?.InstituteName?.toLowerCase().includes(query) ||
            userDetails[record.UserId]?.TypeOfFunding?.toLowerCase().includes(query) ||
            record.Month?.toLowerCase().includes(query)
        );
    });

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    return (
        <div className="transport-history-container">
            <h2>Transport Payment History</h2>
            <div className="search-field">
                <input
                    type="text"
                    placeholder="Search by Name, Surname,funding , University, or Month"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {!loading && !error && (
                <table className="transport-history-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Full Name</th>
                            <th>Type of Funding</th>
                            <th>Month</th>
                            <th>Price</th>
                            <th>Months Status</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHistory.map((record, index) => (
                            <tr key={record.TMP_Id}>
                                <td>{index + 1}</td>
                                <td>
                                    <Link to={`/TransportHistory/${record.UserId}`}>
                                        {`${userDetails[record.UserId]?.Name || 'Unknown'} ${userDetails[record.UserId]?.Surname || 'Unknown'}`}
                                    </Link>
                                </td>
                                <td>{userDetails[record.UserId]?.TypeOfFunding || 'Unknown'}</td>
                                <td>{record.Month}</td>
                                <td>{`R${record.Price.toFixed(2)}`}</td>
                                <td>
                                    {monthNames.map((month, i) => (
                                        <div key={i} className="month-status">
                                            <span className={record[month] === "Paid" ? 'month-name paid' : 'month-name unpaid'}>{month.substring(0, 3)}</span>
                                            <span className={'status-symbol'}>
                                                {record[month] === "Paid" ? '✔️' : '❌'}
                                            </span>
                                        </div>
                                    ))}
                                </td>
                                <td>{new Date(record.CreatedAt).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AllTransportHistory;
