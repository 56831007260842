import { useParams, Link, useNavigate  } from "react-router-dom";
import React , { useEffect, useState, useRef } from "react";
import "./Amberrooms.css";
import './Allcard.css';
import './map.css';
import './amber.css';
import logo from './logowhitefooter.png';
import './Apartmentslide.css';
import './Search.css';
import './homes.css';
import { House, Globe, BedDouble } from 'lucide-react';

import correct from './icons8-correct-30.png'
const ListHomes = () => {
    const navigate = useNavigate();

     const [selectedPackage, setSelectedPackage] = useState(null);
  
      const handlePackageSelection = (packageName) => {
        setSelectedPackage(packageName); // Set the selected package
        
        // Scroll to the description section
        setTimeout(() => {
          document.querySelector(".package-description").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100); // Allow state to update before scrolling
      };
      
  
      const packageDescriptions = {
        Basic: "As a landlord, you will manage your tenants manually on paper. Your tenants won't have access to the dashboard or additional tools. Tenants will book their own transport, and the property will be listed as owned by you.",
        Standard: "This package includes tenant transport for a seamless experience. Property listings and tenant matching are included, ensuring a reliable connection between landlords and tenants. Students can pay online",
        Premium: "All the features of the Standard package, plus advanced tools to independently manage your property. Gain access to enhanced tools for seamless operations.",
        Enterprise: "Includes all Premium features along with full property management services. We'll handle day-to-day operations, so you can relax and watch your property thrive."
      };
  return (
    <div data-radix-scroll-area-viewport="" className="amber-xlwgkm amber-ScrollArea-viewport"
         style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
      <div >
        <div className="amber-1gna992" radius="0">
         
           
          <div>
      <style>{`  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    body {
      font-family: "Helvetica", sans-serif;
      color: #FFFFFF;
      line-height: 1.5;
      overflow-x: hidden;
     
    }
    .containerFomexa {
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
      padding: 12rem;
    }
    h1 {
      font-size: 4rem;
      margin-bottom: 2rem;
    }
   .tab-boxFomexa {
    background-color: rgba(0, 0, 0, 0.35); /* Semi-transparent black */
    backdrop-filter: blur(10px); /* Blur effect */
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.11); /* Optional: Add a subtle shadow */
}

    input[type="radio"] {
      display: none;
    }
    .tabFomexa {
      display: flex;
      justify-content: center;
      gap: 2rem;
    }
    .tab-labels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .tab-labels label {
      display: block;
      padding: 1rem 2rem;
      cursor: pointer;
      font-weight: 500;
      font-size: 2rem;
      border-left: 4px solid transparent;
      transition: color 0.3s, border-color 0.3s;
      text-align: left;
    }
    .tab-labels label:hover {
      color: #d4a24c;
    }

    /* Highlight active label (Desktop) */
    #tab1:checked ~ .tabFomexa .tab-labels label[for="tab1"],
    #tab2:checked ~ .tabFomexa .tab-labels label[for="tab2"],
    #tab3:checked ~ .tabFomexa .tab-labels label[for="tab3"],
    #tab4:checked ~ .tabFomexa .tab-labels label[for="tab4"] {
      color: #FFFFFF;
      border-left-color: #d4a24c;
    }
    .tab-content {
      border-left: 1px solid #333;
      padding-left: 2rem;
      max-width: 600px;
      text-align: center; 
    }
    .content-panel {
      display: none;
      animation: fadeIn 0.3s ease-in;
    }
    @keyframes fadeIn {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
    #tab1:checked ~ .tabFomexa .tab-content #content1 { display: block; }
    #tab2:checked ~ .tabFomexa .tab-content #content2 { display: block; }
    #tab3:checked ~ .tabFomexa .tab-content #content3 { display: block; }
    #tab4:checked ~ .tabFomexa .tab-content #content4 { display: block; }
    .pricetabFomexa {
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      text-align: left;
        text-decoration: underline; /* ADDED to underline text */

    }
    .periodtabFomexa {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.25rem;
    }
    .descriptiontabFomexa {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      text-align: left;
      color:#fff;
    }
    .check-list {
      list-style: none;
      margin: 0 auto;
      text-align: left;
      columns: 2; 
    }
    .check-list li {
      margin-bottom: 0.5rem;
      position: relative;
      padding-left: 1.5rem;
      display: block;
      font-size: 1.5rem;
      width: 80%;
    }
    .check-list li::before {
      content: "✓";
      position: absolute;
      left: 0;
      color: #d4a24c;
    }
    @media (max-width: 768px) {
      .containerFomexa {
        padding: 1rem;
        width: 100%;
      }
        .tab-boxFomexa {
    background-color: rgba(0, 0, 0, 0.57); /* Semi-transparent black */
    backdrop-filter: blur(10px); /* Blur effect */
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07); /* Optional: Add a subtle shadow */
}
      .tabFomexa {
    flex-direction: column;
    align-items: center; 
    padding-left: 0;   
    
  }
  h1 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  .tab-labels {
    display: grid;        
    grid-template-columns: 1fr 2fr; 
  }
      .tab-labels label {
        border-left: none;
        border-bottom: 2px solid transparent; 
        text-align: center;
        font-size: 0.7rem;
        width: 100%;
      }
    /* Highlight the correct label */
#tab1:checked ~ .tabFomexa .tab-labels label[for="tab1"],
#tab2:checked ~ .tabFomexa .tab-labels label[for="tab2"],
#tab3:checked ~ .tabFomexa .tab-labels label[for="tab3"],
#tab4:checked ~ .tabFomexa .tab-labels label[for="tab4"] {
  color: #FFFFFF;
  border-left-color: #d4a24c; /* or border-bottom on mobile */
}
      .tab-content {
        border-left: none;
        border-top: 1px solid #333;
        padding-left: 10px;
        padding-top: 1rem;
        max-width: 90%;
        text-align: left; 
      }
      .pricetabFomexa {
        text-align: left;
      }
      .descriptiontabFomexa {
        text-align: left;
        width: 95%;
      }
      .check-list li {
    width: 95%; 
  }
    }
    @media (max-width: 768px) {
    .tab-labels label {
        border-left: none;
        border-bottom: 2px solid transparent; 
        text-align: center;
        font-size: 1rem; /* Adjust font size for better visibility */
        width: 100%;
        padding: 1rem 0; /* Add spacing */
        cursor: pointer;
    }

    /* Highlight the active tab */
    #tab1:checked ~ .tabFomexa .tab-labels label[for="tab1"],
    #tab2:checked ~ .tabFomexa .tab-labels label[for="tab2"],
    #tab3:checked ~ .tabFomexa .tab-labels label[for="tab3"],
    #tab4:checked ~ .tabFomexa .tab-labels label[for="tab4"] {
        color: #FFFFFF;
        border-bottom-color: #d4a24c; /* Highlight active tab with color */
        font-weight: bold; /* Make the active tab stand out */
    }

    /* Ensure tab content aligns properly */
    .tab-content {
        border-left: none;
        border-top: 1px solid #333;
        padding-left: 10px;
        padding-top: 1rem;
        max-width: 90%;
        text-align: left; 
    }
}

    `}</style>

<div class="containerFomexa">

    <div className="tab-boxFomexa"><section className="header-section">
      <h1>List, Relax, and <br/> Let Us Handle the Rest</h1>
<p style={{color:'white', marginBottom:'30px'}}>List your student property with us and unlock its full potential. From seamless management to maximizing your rental success, we handle the hard work so you can enjoy the rewards stress-free.</p>

      </section>
      <input type="radio" name="tabFomexa" id="tab1" defaultChecked  />
      <input type="radio" name="tabFomexa" id="tab2"  />
      <input type="radio" name="tabFomexa" id="tab3" />
      <input type="radio" name="tabFomexa" id="tab4" />

      <div className="tabFomexa">
        <div className="tab-labels">
          <label htmlFor="tab1">Basic</label>
          <label htmlFor="tab2">Standard</label>
          <label htmlFor="tab3">Premium</label>
          <label htmlFor="tab4">Enterprise <br/>
       </label>
        </div>

      
        <div className="tab-content">
          <div className="content-panel" id="content1">
            <div className="pricetabFomexa">
            7.5% of the first month's rental payment<span className="periodtabFomexa"></span>
            </div>
            <p className="descriptiontabFomexa">
            Focus solely on connecting with tenants while they handle their own transport.
            </p>
            <ul className="check-list">
            <li> Property Listings 
              </li>
              <li>  Tenant Matching
              </li>
              <li>Tenant-Focused Flexibility</li>
             
            </ul>
            <button onClick={() => navigate("/RegisterAdmin", { state: { packageName: "Basic" } })}>
            Continue with Basic Package
          </button>
          </div>
          <div className="content-panel" id="content2">
            <div class="pricetabFomexa">
            7.5% of the first month's rental payment
            </div>
            <p class="descriptiontabFomexa">
            Includes tenant transport, offering a seamless and reliable service.
            </p>
            <ul class="check-list">
            <li> Property Listings 
              </li>
              <li>  Tenant Matching
              </li>
              <li>Tenant Transportation</li>
              
            </ul>
            <button onClick={() => navigate("/RegisterAdmin", { state: { packageName: "Standard" } })}>
            Continue with Standard Package
          </button>
          </div>
          <div class="content-panel" id="content3">
            <div class="pricetabFomexa">
            7.5% of all monthly rental payments processed
            </div>
            <p class="descriptiontabFomexa">
            Includes everything in Standard, plus tools for independent property management.
            </p>
            <ul class="check-list">
            <li> Property Listings 
              </li>
              <li>  Tenant Matching
              </li>
              <li>Tenant Transportation</li>
              <li>Advanced Management Tools</li>
           
            </ul>
            <button onClick={() => navigate("/RegisterAdmin", { state: { packageName: "Premium" } })}>
            Continue with Premium Package
          </button>
          </div>
          <div class="content-panel" id="content4">
            <div class="pricetabFomexa">
            9–15% of all monthly rental payments
            </div>
            <p class="descriptiontabFomexa">
            All Premium features plus complete management of your property.
            </p>
            <ul class="check-list">
              <li> Property Listings 
              </li>
              <li>  Tenant Matching
              </li>
              <li> Tenant Transportation
              </li>
              <li> Advanced Management Tools
              </li>
              <li> Day-to-Day Management</li>
              
            </ul>
            <button onClick={() => navigate("/RegisterAdmin", { state: { packageName: "Enterprise" } })}>
            Continue with Enterprise Package
          </button>
          </div>
        </div>

      </div>
    </div>
  </div>

     
    </div>
        </div>
        <div className="amber-1d9vc5z">
          <div className="amber-1fr50if">
            <h2 className="amber-Text-root amber-Title-root amber-1f1rhpv">Helping students find their perfect home away from home, nationwide</h2>
            <div className="amber-Text-root amber-nduyv1">Supporting our partners with consistent bookings and reliable revenue growth</div>
<div id="scrollboxContentshelp-ideas" class="bookYourPerfectAccommodation-module__insightsContainer" role="list">
  
  <div class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withPinkGradient bookYourPerfectAccommodation-module__desktopInsight" role="listitem">
    <img height="46" width="46" src="https://prod-static-assets.amberstudent.com/images/list-with-us/simplified-sales.svg" class="Image-module__animateOpacity Image-module__show" aria-hidden="true" />
    <div class="bookYourPerfectAccommodation-module__detailsContainer">
      <span class="bookYourPerfectAccommodation-module__title">Simplified Sales &amp; Booking</span>
      <span class="bookYourPerfectAccommodation-module__subtitle">At JAES, we make the booking process seamless with efficient booking &amp; robust sales support teams</span>
    </div>
  </div>
  
  <div class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withPinkGradient bookYourPerfectAccommodation-module__desktopInsight" role="listitem">
    <img height="46" width="46" src="https://prod-static-assets.amberstudent.com/images/list-with-us/high-visibility.svg" class="Image-module__animateOpacity Image-module__show" aria-hidden="true" />
    <div class="bookYourPerfectAccommodation-module__detailsContainer">
      <span class="bookYourPerfectAccommodation-module__title">High Visibility For Your Property</span>
      <span class="bookYourPerfectAccommodation-module__subtitle">Our marketing and &amp; galleries bring the keenest tenants to your doorstep.</span>
    </div>
  </div>

  <div class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withGreenGradient bookYourPerfectAccommodation-module__desktopInsight" role="listitem">
    <img height="46" width="46" src="https://prod-static-assets.amberstudent.com/images/list-with-us/recon.svg" class="Image-module__animateOpacity Image-module__show" aria-hidden="true" />
    <div class="bookYourPerfectAccommodation-module__detailsContainer">
      <span class="bookYourPerfectAccommodation-module__title">Reconciliations &amp; Grievances Support</span>
      <span class="bookYourPerfectAccommodation-module__subtitle">Our account managers ensure prompt support to tackle discrepancies during bookings.</span>
    </div>
  </div>

</div>

          </div>
          <div class="amber-zbrtlh">
            <h3 class="amber-Text-root amber-Title-root amber-1irhk22">Our Presence</h3>
            <div class="amber-Grid-root amber-o1n9j9">
              <div class="amber-Grid-col amber-tm7vxk">
                <div class="amber-8oh7lq">
                  <div class="amber-1k05507"><i class="icon-globe amber-13qrgm7"></i>
                  <House className="amber-13qrgm7" size={40} style={{ color: 'black' }} />
                    <div class="amber-1fr50if"><span><strong class="amber-1pche7w">20+</strong></span><span
                        class="amber-1jzsuhn">Suburbs</span></div>
                  </div>
                  <div class="amber-1k05507"><i class="icon-home amber-13qrgm7"></i>
                  <Globe className="amber-13qrgm7" size={40} style={{ color: 'black' }} />
                    <div class="amber-1fr50if"><span><strong class="amber-1pche7w">1</strong></span><span
                        class="amber-1jzsuhn">city</span></div>
                  </div>
                  <div class="amber-1k05507"><i class="icon-beds amber-13qrgm7"></i>
                  <BedDouble className="amber-13qrgm7" size={40} style={{ color: 'black' }} />
                    <div class="amber-1fr50if"><span><strong class="amber-1pche7w">250+</strong></span><span
                        class="amber-1jzsuhn">beds listed</span></div>
                  </div>
                  <div class="amber-1k05507"><img src="https://prod-static-assets.amberstudent.com/images/cities.png"
                      class="amber-1xppgb4" alt="cities" />
                    <div class="amber-1fr50if"><span><strong class="amber-1pche7w">25+ </strong></span><span
                        class="amber-1jzsuhn">Properties</span></div>
                  </div>
                </div>
                <div class="amber-Text-root amber-c1a6li">
   JAES creates more than just student housing – we build communities that support growth and success. Through data-driven insights, tailored growth strategies, and reliable bookings, we help our partners thrive in the student housing market.
   
   <br /><br />

   Our platform, <strong>JAES Homes</strong>, provides real-time analytics, allowing partners to track trends, maximize occupancy, and grow their brand nationwide. With local market insights, we help future-proof your business, boost revenue, and stay ahead of changes.
   
   <br /><br />

   Our partnership model focuses on mutual growth with a flexible commission structure. Our easy booking process and dedicated support ensure your property thrives while you focus on creating a welcoming, student-centered environment.
   
   <br /><br />

   Imagine spaces designed for students to succeed – secure, modern, and full of community spirit. From cozy study areas to vibrant common spaces, JAES provides more than housing. We create connections, foster growth, and make lasting memories for every resident.
</div>

              </div>
              <div class="amber-Grid-col amber-14olkzq">
  <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/map.png" alt="Amber Map" />
</div>

            </div>
            
          </div>
          <div class="HorizontalScroll-module__hrContainer ReferralProgram-module__horizontalScroll">
  <div class="StepCard-module__stepCardOuter">
    <div class="StepCard-module__stepCardInnerDesktop" data-testid="Fill the Form">
      <div class="StepCard-module__stepNumber">1</div>
      <img height="46" width="46"
           src="https://prod-static-assets.amberstudent.com/images/list-with-us/fill-form.svg"
           class="Image-module__animateOpacity Image-module__show" aria-hidden="true" alt="Fill the Form" />
      <h4 class="StepCard-module__title">Fill the Form</h4>
      <div class="StepCard-module__subTitle">
        Our team will connect with you within 24 hours.
      </div>
    </div>
    <img height="6" width="25.2"
         src="https://prod-static-assets.amberstudent.com/images/list-with-us/step-arrow-right.svg"
         class="Image-module__animateOpacity Image-module__show" aria-hidden="true" />
  </div>

  <div class="StepCard-module__stepCardOuter">
    <div class="StepCard-module__stepCardInnerDesktop" data-testid="Let Us Do the Work">
      <div class="StepCard-module__stepNumber">2</div>
      <img height="46" width="46"
           src="https://prod-static-assets.amberstudent.com/images/list-with-us/let-us-do-work.svg"
           class="Image-module__animateOpacity Image-module__show" aria-hidden="true" alt="Let Us Do the Work" />
      <h4 class="StepCard-module__title">Let Us Do the Work</h4>
      <div class="StepCard-module__subTitle">
      Finalize the onboarding agreement.
      </div>
    </div>
    <img height="6" width="25.2"
         src="https://prod-static-assets.amberstudent.com/images/list-with-us/step-arrow-right.svg"
         class="Image-module__animateOpacity Image-module__show" aria-hidden="true" />
  </div>

  <div class="StepCard-module__stepCardOuter">
    <div class="StepCard-module__stepCardInnerDesktop" data-testid="Get Booked">
      <div class="StepCard-module__stepNumber">3</div>
      <img height="46" width="46"
           src="https://prod-static-assets.amberstudent.com/images/list-with-us/get-booked.svg"
           class="Image-module__animateOpacity Image-module__show" aria-hidden="true" alt="Get Booked!" />
      <h4 class="StepCard-module__title">Get Booked!</h4>
      <div class="StepCard-module__subTitle">
    Sit back, relax, and let the money roll in.
      </div>
      
    </div>

  </div>
</div>
          <div className="scroll-box-wrapper">
            <nav id="scrollboxhelp-ideas" className="scroll-box">
              <div id="scrollboxContentshelp-ideas" className="scroll-box-contents" role="list" style={{ paddingLeft: '20px' }}>
              <div className="amber-zbrtlh">
  <h3 className="amber-Text-root amber-Title-root amber-epequm">Grow With Us</h3>
  <div className="amber-Text-root amber-1f9zaai">
    A seamless booking experience for students. Global demand generation for our partners.
  </div>
  <div className="amber-Grid-root amber-o1n9j9">
  <div className="amber-Grid-col amber-tm7vxk">
    <div className="amber-cd3crb">
      <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/check-mark.svg" alt="check mark" />
      <div className="amber-Text-root amber-1962v11">
        Serving over <strong>20+ suburbs</strong> with tailored student housing
      </div>
    </div>
    <div className="amber-cd3crb">
      <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/check-mark.svg" alt="check mark" />
      <div className="amber-Text-root amber-1962v11">
        <strong>1 major city and expanding</strong> focus to provide in-depth, localized support
      </div>
    </div>
    <div className="amber-cd3crb">
      <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/check-mark.svg" alt="check mark" />
      <div className="amber-Text-root amber-1962v11">
        Over <strong>250+ beds</strong> listed, offering diverse options for students
      </div>
    </div>
    <div className="amber-cd3crb">
      <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/check-mark.svg" alt="check mark" />
      <div className="amber-Text-root amber-1962v11">
        <strong>25+ properties</strong> available, fostering vibrant communities
      </div>
    </div>
    <div className="amber-cd3crb">
      <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/check-mark.svg" alt="check mark" />
      <div className="amber-Text-root amber-1962v11">
        Real-time insights with <strong>JAES Homes</strong> to optimize occupancy and revenue
      </div>
    </div>
    <div className="amber-cd3crb">
      <img src="https://prod-static-assets.amberstudent.com/images/list-with-us/check-mark.svg" alt="check mark" />
      <div className="amber-Text-root amber-1962v11">
        <strong>Flexible commission structure</strong> to ensure mutual growth with partners
      </div>
    </div>
  </div>
  <div className="amber-Grid-col amber-14olkzq">
    <img 
      src="https://images.unsplash.com/photo-1560462936-367818f2d1fe?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
      alt="JAES Map" 
      style={{ width: '100%', maxWidth: '500px', height: 'auto' }} 
    />
  </div>
</div>

</div>
              </div>
            </nav>
          </div>
        </div>

<footer className="w3l-footer-29-main py-10">
        <div className="container pt-lg-5 pt-sm-4 pt-2 pb-2">
          <div className="row footer-top-29">
            {/* Logo on the left */}
            <div className="logoFooterItem">
              <Link className="btn-group-vertical" to="/">
                <img src={logo} alt="Tours Logo" className="" />
              </Link>
            </div>

            {/* Footer content side by side (Quick Links, Explore, Contact Info) */}
            <div className="w3l-promocode">
              <div className="row footer-list-29">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <ul>
                    <h6 className="footer-title-29">Quick Links</h6>
                    <li>
                      <a href="about.html" style={{ color: "white" }}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#services" style={{ color: "white" }}>
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="/ContactUs" style={{ color: "white" }}>
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="#rewards" style={{ color: "white" }}>
                        Rewards
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <ul>
                    <h6 className="footer-title-29" style={{ color: "white" }}>
                      Explore
                    </h6>
                    <li>
                      <a href="#blog" style={{ color: "white" }}>
                        Blog Posts
                      </a>
                    </li>
                    <li>
                      <a href="#privacy" style={{ color: "white" }}>
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="#partners" style={{ color: "white" }}>
                        Our Partners
                      </a>
                    </li>
                    <li>
                      <a href="#events" style={{ color: "white" }}>
                        Events
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h6 className="footer-title-29">Contact Info</h6>
                  <p className="mb-2" style={{ color: "white" }}>
                    Phone Number:{" "}
                    <a href="tel:+27(81)5006508" style={{ color: "white" }}>
                      +27(81) 500 6508
                    </a>
                  </p>
                  <p className="mb-2" style={{ color: "white" }}>
                    Email:{" "}
                    <a href="mailto:Info@jaes.co.za" style={{ color: "white" }}>
                      Info@jaes.co.za
                    </a>
                  </p>
                  <div className="mt-2">
                    <ul className="social">
                      <li>
                        <a
                          href="https://web.whatsapp.com/send?phone=27815006508"
                          target="_blank"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@jaesproperties"
                          target="_blank"
                        >
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/jaesproperties"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="copy-footer-234">
                  <p style={{ color: "white" }}>
                    © 2024 Jae's Homes. All rights reserved. Design by{" "}
                    <a
                      href="https://vingamedia.co.za"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      VingaMedia
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </div>
    </div>
  );
};

export default ListHomes;
