
import React, { useEffect, useRef, useState } from 'react'; // Added useState to manage user data
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider'; // Assuming you have AuthProvider

function ReservePayment() {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentUpdatedRef = useRef(false); // Ref to track if payment has been updated
  const { userId } = useAuth(); // Get userId from AuthContext
  const [userData, setUserData] = useState(null); // State to store user data

  // Extract the query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const date = queryParams.get('date');
  const apartmentId = queryParams.get('apartment');
  const totalCost = queryParams.get('totalCost');
  const chosenDefault = queryParams.get('chosenDefault');
  const serviceFee = queryParams.get('serviceFee');


  const apartmentNumber = queryParams.get('apartmentnumber');
  const apartmentType = queryParams.get('apartmenttype');
  const apartmentPreferedGender = queryParams.get('apartmentpreferedgender');
  const apartmentDeposit = queryParams.get('apartmentdeposit');
  const apartmentRating = queryParams.get('apartmentrating');
  const livingArrangement = queryParams.get('livingarrangement');
  const bedRoomStatus = queryParams.get('bedroomstatus');
  const bedRoomCount = queryParams.get('bedroomcount');
  const dateParam = queryParams.get('date'); // If the date is being passed like this
  const buildingManager = queryParams.get('buildingmanager') || 'unclear';
  const guest = queryParams.get('guest');
  const apartmentLoungeImage = queryParams.get('apartmentloungeimage');
  const apartmentKitchenImage = queryParams.get('apartmentkitchenimage');
  const apartmentBathroomImage = queryParams.get('apartmentbathroomimage');
  const apartmentRoomImage = queryParams.get('apartmentroomimage');
  const apartmentSize = queryParams.get('apartmentsize');
  const livingSpace = queryParams.get('livingspace');
  const totalNumOfBeds = queryParams.get('totalnumofbeds');
  const priceB = queryParams.get('priceb') || "not used";
  const createdDate = queryParams.get('createddate');
  const createdBy = queryParams.get('createdby');
  const capacity2 = queryParams.get('capacity2');
  const roomIdentifier = queryParams.get('roomidentifier');
  const nextPaymentDate = queryParams.get('nextPaymentDate');
  const ApartmentPrice = queryParams.get('ApartmentPrice');
  const fundingOption = queryParams.get('fundingOption');
  const transport = queryParams.get('transport');
// Log all data for debugging
console.log('Extracted Query Parameters:', {
  date,
  apartmentId,
  totalCost,
  chosenDefault,
  serviceFee,
  apartmentNumber,
  apartmentType,
  apartmentPreferedGender,
  apartmentDeposit,
  apartmentRating,
  livingArrangement,
  bedRoomStatus,
  bedRoomCount,
  dateParam,
  buildingManager,
  guest,
  apartmentLoungeImage,
  apartmentKitchenImage,
  apartmentBathroomImage,
  apartmentRoomImage,
  apartmentSize,
  livingSpace,
  totalNumOfBeds,
  priceB,
  createdDate,
  createdBy,
  capacity2,
  roomIdentifier,
  nextPaymentDate,
  ApartmentPrice,
  fundingOption,
  transport,
});
  // Fetch user data by userId
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://backendjaes.passiontrials.co.za/user_full_reserve/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        setUserData(data); // Store user data in state
        console.log('Fetched user data:', data); // Check if userData is set correctly

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (userId) {
      fetchUserData(); // Fetch user data if userId exists
    }
  }, [userId]);
  useEffect(() => {
    // Ensure all necessary data is available before proceeding
    if (!userId || !apartmentId || !date || !totalCost || !chosenDefault  || !fundingOption || !transport) {
      console.error('Missing necessary data for payment processing', {
        userId,
        date,
        apartmentId,
        totalCost,
        serviceFee,
        chosenDefault,
        apartmentNumber,
        // apartmentType,
        // apartmentPreferedGender,
        // apartmentDeposit,
        // apartmentRating,
        // livingArrangement,
        // bedRoomStatus,
        // bedRoomCount,
        // buildingManager,
        // guest,
        // apartmentLoungeImage,
        // apartmentKitchenImage,
        // apartmentBathroomImage,
        // apartmentRoomImage,
        // apartmentSize,
        // livingSpace,
        // totalNumOfBeds,
        // priceB,
        // createdDate,
        // createdBy,
        // capacity2,
        // roomIdentifier,
        fundingOption,
        transport
      });
      return;
    }

    // Ensure the API call happens only once
    if (!paymentUpdatedRef.current) {
      paymentUpdatedRef.current = true; // Set ref to true to prevent multiple calls
      handlePaymentSuccess();
    }
  }, [userId, apartmentId, date, totalCost, chosenDefault, serviceFee, fundingOption, transport, userData]);
  useEffect(() => {
    if (userData) {
      sendBookingEmail();
    } else {
      console.log('userData not loaded yet.');
    }
  }, [userData]);

  const handlePaymentSuccess = async () => {
    try {
      // Step 1: Associate user with apartment
      //await associateUserWithApartment();
      await saveToReserve();

      // Step 3: Fetch ApartmentPrice and calculate daily rate
      const apartmentData = await fetchApartmentData();
      const apartmentPrice = ApartmentPrice;

      // Calculate daily rate
      const paymentDate = new Date(date);
      const year = paymentDate.getFullYear();
      const month = paymentDate.getMonth(); // 0-based month
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const dailyRate = apartmentPrice / daysInMonth;

      // DaysPaidFor: daysInMonth (assuming full month paid)
      const daysPaidFor = daysInMonth;

      // Step 4: Save to wallet
      //await saveToWallet(apartmentPrice, dailyRate, daysPaidFor);
      await saveToReserveWallet(apartmentPrice, totalCost);

      await updateUserRoleToReserve(); // Call to update user role
      // Step 5: Send the booking email
      // if (userData) {
      //     await sendBookingEmail();
      // } else {
      //     console.error('userData is not available');
      // }


      // Step 6: Mark apartment as occupied
      //await markApartmentAsOccupied();

      // Redirect the user to the homepage after success
      setTimeout(() => {
        navigate('/ReserveUserDashboard'); // Or replace with the correct redirect route
      }, 5000); // Redirect after 5 seconds
    } catch (error) {
      console.error('Error processing payment success:', error);
      alert(`Error processing your payment: ${error.message}`);
    }
  };



  const updateUserRoleToReserve = async () => {
    try {
      const response = await fetch('https://backendjaes.passiontrials.co.za/updateUserRole', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, newRole: 'reserve' })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update user role: ${errorText}`);
      }

      console.log('User role updated to reserve successfully!');
    } catch (error) {
      console.error(`Error updating user role: ${error.message}`);
    }
  };


  const saveToReserve = async () => {
    try {
      const moveInDate = new Date(date);
      const moveOutDate = new Date(moveInDate);
      moveOutDate.setMonth(moveOutDate.getMonth() + 1); // Add one month for move-out date
      console.log('in side the reserve save end point');

      const response = await fetch('https://backendjaes.passiontrials.co.za/reserve', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          UserId: userId,
          ApartmentId: apartmentId,
          MoveInDate: moveInDate.toISOString().split('T')[0],
          MoveOutDate: moveOutDate.toISOString().split('T')[0],
          RentAmount: totalCost,
          LeaseTerm: '1 Month',
          IsActive: true,
          DepositPaid: true,
          ApartmentRating: 5,
          Guest: 1,
          Notes: 'No specific notes',
          TenantAgreementType: 'Month-to-Month',
          ChosenPlan: chosenDefault,
          ServiceFee: 0,
          selectedFunding: fundingOption,
          selectedTransport: transport,

        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to save reservation data: ${errorText}`);
      }

      console.log('Reservation saved successfully in reserve table!');
    } catch (error) {
      throw new Error(`Error saving reservation data: ${error.message}`);
    }
  };



  const fetchApartmentData = async () => {
    try {
      const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/${apartmentId}`);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch apartment data: ${errorText}`);
      }
      const apartmentData = await response.json();
      console.log('Fetched Apartment Data:', apartmentData); // For debugging
      return apartmentData;
    } catch (error) {
      throw new Error(`Error fetching apartment data: ${error.message}`);
    }
  };
  const saveToReserveWallet = async (apartmentPrice, totalCost) => {
    try {
        const response = await fetch('https://backendjaes.passiontrials.co.za/reserveWallet', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: userId,
                ApartmentId: apartmentId,
                PaymentDate: new Date(date).toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
                AmountPaid: totalCost,
                MonthYear: new Date(date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
                PaymentMethod: "Yoco",
                Transport: transport,
                FundingOption: fundingOption
            })
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to save to ReserveWallet: ${errorText}`);
        }
        console.log('ReserveWallet record saved successfully!');
    } catch (error) {
        throw new Error(`Error saving to ReserveWallet: ${error.message}`);
    }
};


  // const saveToWallet = async (apartmentPrice, dailyRate, daysPaidFor) => {
  //   try {
  //     const response = await fetch('https://backendjaes.passiontrials.co.za/wallet', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         UserId: userId,
  //         ApartmentId: apartmentId,
  //         PaymentDate: new Date(date).toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
  //         AmountPaid: totalCost,
  //         DailyRate: dailyRate.toFixed(2),
  //         DaysPaidFor: daysPaidFor,
  //         MonthYear: new Date(date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
  //         PaymentMethod: "Yoco"
  //       })
  //     });
  //     if (!response.ok) {
  //       const errorText = await response.text();
  //       throw new Error(`Failed to save to wallet: ${errorText}`);
  //     }
  //     console.log('Wallet record saved successfully!');
  //   } catch (error) {
  //     throw new Error(`Error saving to wallet: ${error.message}`);
  //   }
  // };

  // New function to send booking email
  const sendBookingEmail = async () => {
    try {
      if (!userData || !userData[0]?.Name || !userData[0]?.Surname) {
        console.error('userData or required fields are missing');
        return;
      }

      console.log('Preparing to send booking email with data:', {
        apartmentId,
        userId,
        name: `${userData[0]?.Name} ${userData[0]?.Surname}`, // Concatenate Name and Surname
        IdNumber: userData[0]?.IdNumber,
        RoomIdentifier: userData[0]?.RoomIdentifier,
        reservedate: new Date().toLocaleDateString('en-GB'), // Format as DD/MM/YYYY
        cell: userData[0]?.PhoneNumber,
        BuildingLocation: userData[0]?.BuildingLocation,
        Transport: transport,
        Homes: fundingOption,
        userEmail: userData[0]?.Email
      });

      const response = await fetch('https://backendjaes.passiontrials.co.za/sendBookingEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          apartmentId,
          userId,
          name: `${userData[0]?.Name} ${userData[0]?.Surname}`, // Concatenate Name and Surname
          IdNumber: userData[0]?.IdNumber,
          BookingNo: userData[0]?.RoomIdentifier,
          Date: new Date().toLocaleDateString('en-GB'), // Format as DD/MM/YYYY
          Cell: userData[0]?.PhoneNumber,
          Address: userData[0]?.BuildingLocation,

          Transport: transport,
          Homes: fundingOption,
          userEmail: userData[0]?.Email
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send booking email: ${errorText}`);
      }
      console.log('Booking email sent successfully!');
    } catch (error) {
      console.error('Error sending booking email:', error.message);
    }
  };

  // const markApartmentAsOccupied = async () => {
  //     try {
  //         // Ensure apartmentId exists before proceeding
  //         if (apartmentId) {
  //             // Decrement the Capacity2 in the apartment_tb table
  //             const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${apartmentId}`, {
  //                 method: 'PUT',
  //                 headers: { 'Content-Type': 'application/json' }
  //             });

  //             if (!decrementCapacity.ok) {
  //                 const errorText = await decrementCapacity.text();
  //                 throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
  //             }

  //             console.log('Apartment capacity has been decremented successfully');
  //         } else {
  //             console.log('No apartment or room found');
  //         }
  //     } catch (error) {
  //         throw new Error(`Error marking apartment as occupied: ${error.message}`);
  //     }
  // };

  return (
    <div style={{ textAlign: 'center', marginTop: '150px' }}>
      <h1>Payment Success</h1>
      <style>{`/* From Uiverse.io by vinodjangid07 */ 
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.truckWrapper {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  overflow-x: hidden;
}
/* truck upper body */
.truckBody {
  width: 130px;
  height: fit-content;
  margin-bottom: 6px;
  animation: motion 1s linear infinite;
}
/* truck suspension animation*/
@keyframes motion {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* truck's tires */
.truckTires {
  width: 130px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 15px;
  position: absolute;
  bottom: 0;
}
.truckTires svg {
  width: 24px;
}

.road {
  width: 100%;
  height: 1.5px;
  background-color: #282828;
  position: relative;
  bottom: 0;
  align-self: flex-end;
  border-radius: 3px;
}
.road::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: #282828;
  right: -50%;
  border-radius: 3px;
  animation: roadAnimation 1.4s linear infinite;
  border-left: 10px solid white;
}
.road::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: #282828;
  right: -65%;
  border-radius: 3px;
  animation: roadAnimation 1.4s linear infinite;
  border-left: 4px solid white;
}

.lampPost {
  position: absolute;
  bottom: 0;
  right: -90%;
  height: 90px;
  animation: roadAnimation 1.4s linear infinite;
}

@keyframes roadAnimation {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-350px);
  }
}
`}</style>
      <div class="loader">
        <div class="truckWrapper">
          <div class="truckBody">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 198 93"
              class="trucksvg"
            >
              <path
                stroke-width="3"
                stroke="#282828"
                fill="#F83D3D"
                d="M135 22.5H177.264C178.295 22.5 179.22 23.133 179.594 24.0939L192.33 56.8443C192.442 57.1332 192.5 57.4404 192.5 57.7504V89C192.5 90.3807 191.381 91.5 190 91.5H135C133.619 91.5 132.5 90.3807 132.5 89V25C132.5 23.6193 133.619 22.5 135 22.5Z"
              ></path>
              <path
                stroke-width="3"
                stroke="#282828"
                fill="#7D7C7C"
                d="M146 33.5H181.741C182.779 33.5 183.709 34.1415 184.078 35.112L190.538 52.112C191.16 53.748 189.951 55.5 188.201 55.5H146C144.619 55.5 143.5 54.3807 143.5 53V36C143.5 34.6193 144.619 33.5 146 33.5Z"
              ></path>
              <path
                stroke-width="2"
                stroke="#282828"
                fill="#282828"
                d="M150 65C150 65.39 149.763 65.8656 149.127 66.2893C148.499 66.7083 147.573 67 146.5 67C145.427 67 144.501 66.7083 143.873 66.2893C143.237 65.8656 143 65.39 143 65C143 64.61 143.237 64.1344 143.873 63.7107C144.501 63.2917 145.427 63 146.5 63C147.573 63 148.499 63.2917 149.127 63.7107C149.763 64.1344 150 64.61 150 65Z"
              ></path>
              <rect
                stroke-width="2"
                stroke="#282828"
                fill="#FFFCAB"
                rx="1"
                height="7"
                width="5"
                y="63"
                x="187"
              ></rect>
              <rect
                stroke-width="2"
                stroke="#282828"
                fill="#282828"
                rx="1"
                height="11"
                width="4"
                y="81"
                x="193"
              ></rect>
              <rect
                stroke-width="3"
                stroke="#282828"
                fill="#DFDFDF"
                rx="2.5"
                height="90"
                width="121"
                y="1.5"
                x="6.5"
              ></rect>
              <rect
                stroke-width="2"
                stroke="#282828"
                fill="#DFDFDF"
                rx="2"
                height="4"
                width="6"
                y="84"
                x="1"
              ></rect>
            </svg>
          </div>
          <div class="truckTires">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 30 30"
              class="tiresvg"
            >
              <circle
                stroke-width="3"
                stroke="#282828"
                fill="#282828"
                r="13.5"
                cy="15"
                cx="15"
              ></circle>
              <circle fill="#DFDFDF" r="7" cy="15" cx="15"></circle>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 30 30"
              class="tiresvg"
            >
              <circle
                stroke-width="3"
                stroke="#282828"
                fill="#282828"
                r="13.5"
                cy="15"
                cx="15"
              ></circle>
              <circle fill="#DFDFDF" r="7" cy="15" cx="15"></circle>
            </svg>
          </div>
          <div class="road"></div>

          <svg
            // xml:space="preserve"
            viewBox="0 0 453.459 453.459"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            id="Capa_1"
            version="1.1"
            fill="#000000"
            class="lampPost"
          >
            <path
              d="M252.882,0c-37.781,0-68.686,29.953-70.245,67.358h-6.917v8.954c-26.109,2.163-45.463,10.011-45.463,19.366h9.993
c-1.65,5.146-2.507,10.54-2.507,16.017c0,28.956,23.558,52.514,52.514,52.514c28.956,0,52.514-23.558,52.514-52.514
c0-5.478-0.856-10.872-2.506-16.017h9.992c0-9.354-19.352-17.204-45.463-19.366v-8.954h-6.149C200.189,38.779,223.924,16,252.882,16
c29.952,0,54.32,24.368,54.32,54.32c0,28.774-11.078,37.009-25.105,47.437c-17.444,12.968-37.216,27.667-37.216,78.884v113.914
h-0.797c-5.068,0-9.174,4.108-9.174,9.177c0,2.844,1.293,5.383,3.321,7.066c-3.432,27.933-26.851,95.744-8.226,115.459v11.202h45.75
v-11.202c18.625-19.715-4.794-87.527-8.227-115.459c2.029-1.683,3.322-4.223,3.322-7.066c0-5.068-4.107-9.177-9.176-9.177h-0.795
V196.641c0-43.174,14.942-54.283,30.762-66.043c14.793-10.997,31.559-23.461,31.559-60.277C323.202,31.545,291.656,0,252.882,0z
M232.77,111.694c0,23.442-19.071,42.514-42.514,42.514c-23.442,0-42.514-19.072-42.514-42.514c0-5.531,1.078-10.957,3.141-16.017
h78.747C231.693,100.736,232.77,106.162,232.77,111.694z"
            ></path>
          </svg>
        </div>
      </div>

      {/* {userData && (
                <div>
                    <h2>User Details</h2>
                    <p><strong>Name:</strong> {userData[0]?.Name}</p>
                    <p><strong>Email:</strong> {userData[0]?.email}</p>
                    <p><strong>Phone:</strong> {userData[0]?.phone}</p>
                </div>
            )} */}

      {/* <button
                onClick={handleReturnHome}
                style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#ff5a5f', color: 'white', border: 'none', borderRadius: '5px' }}>
                Return Home
            </button> */}
    </div>
  );
}

export default ReservePayment;

