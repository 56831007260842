import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Ensure to import the useAuth hook
import "./transportGrab.css";
import exp from "./richbusinesswoman.jpg";
import m402x from "./richbusinesswoman.jpg";
import sectiond from "./113.jpg";
import sectionm from "./113.jpg";
import minscaled from "./richbusinesswoman.jpg";
import ridecoversection from "./richbusinesswoman.jpg";
import RegistrationModal from "./components/TransportRegistrationModal.js"; // Adjust the path based on your folder structure

const IntroTransport = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false); // State to show the registration modal
  const [activeIndex, setActiveIndex] = useState(null); // Track which question is active

  const { isLoggedIn } = useAuth(); // Use the isLoggedIn and role from useAuth
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleJoinClick = () => {
    if (isLoggedIn) {
      navigate("/userDashboard");
    } else {
      setShowRegistrationModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const faqData = [
    {
      question: "How can I pay for my ride?",
      answer:
        "You can pay via EFT or online through our secure payment platform. Choose the method that works best for you when booking.",
    },
    {
      question: "Can I contact my driver?",
      answer:
        "No, direct contact with drivers is not available. Please ensure you're at the pickup point 30 minutes before departure to avoid any delays.",
    },
    {
      question: "Why do I need to be present 30 minutes before departure?",
      answer:
        "Being present ensures a smooth boarding process and helps us stay on schedule for all passengers.",
    },
  ];
  

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <body class="page-template page-template-grab21-default page page-id-59985 page-parent translatepress-en_US site-id-4 transport elementor-default gr-gr21-default elementor-kit-89147 elementor-page elementor-page-59985">
      <style>{`body {
    font-family: Arial, sans-serif;
    text-align: left;
}`}</style>
      <style>{`html {
    font-family: inherit;
    font-weight: 400;
    line-height: var(--typ-base-line-height);
    -webkit-text-size-adjust: 100%;
    background: #fff;
    color: #161a1d;
    font-size: 16px;
    line-height: var(--typ-base-line-height, 1.333);
    -webkit-font-smoothing: antialiased
}

@media (min-width:768px) {
    html {
        font-size: 16px;
        line-height: var(--typ-base-line-height-m, 1.412)
    }
}

body {
    margin: 0
}

.uk-link,
a {
    color: #1594e6;
    text-decoration: none;
    cursor: pointer
}

.uk-link-toggle:hover .uk-link,
.uk-link:hover,
a:hover {
    color: #1594e6;
    text-decoration: underline
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-style: dotted
}

b,
strong {
    font-weight: bolder
}

:not(pre)>code,
:not(pre)>kbd,
:not(pre)>samp {
    font-family: Consolas, monaco, monospace;
    font-size: .875rem;
    color: #ee6352;
    white-space: nowrap;
    padding: 2px 6px;
    background: #f5f5f5
}

em {
    color: #ee6352
}

ins {
    text-decoration: none
}

ins,
mark {
    background: #ffd;
    color: #161a1d
}

q {
    font-style: italic
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle
}

canvas,
img,
svg,
video {
    max-width: 100%;
    height: auto;
    box-sizing: border-box
}

img:not([src]) {
    visibility: hidden;
    min-width: 1px
}

iframe {
    border: 0
}

address,
dl,
fieldset,
figure,
ol,
p,
pre,
ul {
    margin: 0 0 20px
}

*+address,
*+dl,
*+fieldset,
*+figure,
*+ol,
*+p,
*+pre,
*+ul {
    margin-top: 20px
}

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-3xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 20px;
    font-family: inherit;
    font-weight: 400;
    color: #161a1d;
    text-transform: none
}

*+.uk-h1,
*+.uk-h2,
*+.uk-h3,
*+.uk-h4,
*+.uk-h5,
*+.uk-h6,
*+.uk-heading-2xlarge,
*+.uk-heading-3xlarge,
*+.uk-heading-large,
*+.uk-heading-medium,
*+.uk-heading-small,
*+.uk-heading-xlarge,
*+h1,
*+h2,
*+h3,
*+h4,
*+h5,
*+h6 {
    margin-top: 40px
}

.uk-h1,
h1 {
    font-size: 47.6px;
    line-height: 1.2;
    font-size: 40px;
    line-height: var(--typ-h1-line-height, 1.1);
    font-weight: 500
}

@media (min-width:768px) {

    .uk-h1,
    h1 {
        font-size: 56px;
        line-height: var(--typ-h1-line-height-m, 1.143)
    }
}

.uk-h2,
h2 {
    font-size: 34px;
    line-height: 1.3;
    font-size: 32px;
    line-height: var(--typ-h2-line-height, 1.123);
    font-weight: 500
}

@media (min-width:768px) {

    .uk-h2,
    h2 {
        font-size: 40px;
        line-height: var(--typ-h2-line-height-m, 1.2)
    }
}

.uk-h3,
h3 {
    line-height: 1.4;
    font-size: 24px;
    line-height: var(--typ-h3-line-height, 1.167);
    font-weight: 500
}

@media (min-width:768px) {

    .uk-h3,
    h3 {
        font-size: 32px;
        line-height: var(--typ-h3-line-height-m, 1.25)
    }
}

.uk-h4,
h4 {
    line-height: 1.4;
    font-size: 20px;
    line-height: var(--typ-h4-line-height, 1.2);
    font-weight: 500
}

@media (min-width:768px) {

    .uk-h4,
    h4 {
        font-size: 24px;
        line-height: var(--typ-h4-line-height-m, 1.333)
    }
}

.uk-h5,
h5 {
    font-size: 16px;
    line-height: 1.4;
    font-size: 20px;
    line-height: var(--typ-h5-line-height, 1.2);
    font-weight: 500
}

@media (min-width:768px) {

    .uk-h5,
    h5 {
        font-size: 24px;
        line-height: var(--typ-h5-line-height-m, 1.333)
    }
}

.uk-h6,
h6 {
    font-size: .875rem;
    line-height: 1.4;
    font-size: 20px;
    line-height: var(--typ-h6-line-height, 1.2);
    font-weight: 500
}

@media (min-width:768px) {

    .uk-h6,
    h6 {
        font-size: 24px;
        line-height: var(--typ-h6-line-height-m, 1.333)
    }
}

@media (min-width:768px) {

    .uk-h1,
    h1 {
        font-size: 56px
    }

    .uk-h2,
    h2 {
        font-size: 40px
    }
}

ol,
ul {
    padding-left: 30px
}

ol>li>ol,
ol>li>ul,
ul>li>ol,
ul>li>ul {
    margin: 0
}

dt {
    font-weight: 700
}

dd {
    margin-left: 0
}

.uk-hr,
hr {
    overflow: visible;
    text-align: inherit;
    margin: 0 0 20px;
    border: 0;
    border-top: 1px solid #dbdbdb
}

*+.uk-hr,
*+hr {
    margin-top: 20px
}

address {
    font-style: normal
}

blockquote {
    margin: 0 0 20px;
    font-size: 20px;
    line-height: 1.5;
    font-style: italic;
    color: #161a1d
}

*+blockquote {
    margin-top: 20px
}

blockquote p:last-of-type {
    margin-bottom: 0
}

blockquote footer {
    margin-top: 10px;
    font-size: .875rem;
    line-height: 1.5;
    color: #161a1d
}

blockquote footer:before {
    content: "— "
}

pre {
    font: .875rem/1.5 Consolas, monaco, monospace;
    color: #161a1d;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    overflow: auto;
    padding: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    background: #fff
}

pre code {
    font-family: Consolas, monaco, monospace
}

:focus {
    outline: none
}

:focus-visible {
    outline: 2px dotted #161a1d
}

::-moz-selection {
    background: #39f;
    color: #fff;
    text-shadow: none
}

::selection {
    background: #39f;
    color: #fff;
    text-shadow: none
}

details,
main {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

:root {
    --uk-breakpoint-s: 360px;
    --uk-breakpoint-m: 768px;
    --uk-breakpoint-l: 1025px;
    --uk-breakpoint-xl: 1280px
}

.uk-link-muted a,
.uk-link-toggle .uk-link-muted,
a.uk-link-muted {
    color: #8c8c8c
}

.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted,
a.uk-link-muted:hover {
    color: #161a1d
}

.uk-link-text a,
.uk-link-toggle .uk-link-text,
a.uk-link-text {
    color: inherit
}

.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text,
a.uk-link-text:hover {
    color: #8c8c8c
}

.uk-link-heading a,
.uk-link-toggle .uk-link-heading,
a.uk-link-heading {
    color: inherit
}

.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading,
a.uk-link-heading:hover {
    color: #00b14f;
    text-decoration: none
}

.uk-link-reset a,
.uk-link-toggle,
a.uk-link-reset {
    color: inherit !important;
    text-decoration: none !important
}

.uk-heading-small {
    font-size: 2.6rem;
    line-height: 1.2
}

.uk-heading-medium {
    font-size: 2.8875rem;
    line-height: 1.1
}

.uk-heading-large {
    font-size: 3.4rem;
    line-height: 1.1
}

.uk-heading-xlarge {
    font-size: 4rem;
    line-height: 1
}

.uk-heading-2xlarge {
    font-size: 6rem;
    line-height: 1
}

.uk-heading-3xlarge {
    font-size: 8rem;
    line-height: 1
}

@media (min-width:768px) {
    .uk-heading-small {
        font-size: 3.25rem
    }

    .uk-heading-medium {
        font-size: 3.5rem
    }

    .uk-heading-large {
        font-size: 4rem
    }

    .uk-heading-xlarge {
        font-size: 6rem
    }

    .uk-heading-2xlarge {
        font-size: 8rem
    }

    .uk-heading-3xlarge {
        font-size: 11rem
    }
}

@media (min-width:1025px) {
    .uk-heading-medium {
        font-size: 4rem
    }

    .uk-heading-large {
        font-size: 6rem
    }

    .uk-heading-xlarge {
        font-size: 8rem
    }

    .uk-heading-2xlarge {
        font-size: 11rem
    }

    .uk-heading-3xlarge {
        font-size: 15rem
    }
}

.uk-heading-divider {
    padding-bottom: calc(5px + .1em);
    border-bottom: calc(.2px + .05em) solid #dbdbdb
}

.uk-heading-bullet {
    position: relative
}

.uk-heading-bullet:before {
    content: "";
    display: inline-block;
    position: relative;
    top: -.1em;
    vertical-align: middle;
    height: calc(4px + .7em);
    margin-right: calc(5px + .2em);
    border-left: calc(5px + .1em) solid #dbdbdb
}

.uk-heading-line {
    overflow: hidden
}

.uk-heading-line>* {
    display: inline-block;
    position: relative
}

.uk-heading-line>:after,
.uk-heading-line>:before {
    content: "";
    position: absolute;
    top: calc(50% - .1px - .025em);
    width: 2000px;
    border-bottom: calc(.2px + .05em) solid #dbdbdb
}

.uk-heading-line>:before {
    right: 100%;
    margin-right: calc(5px + .3em)
}

.uk-heading-line>:after {
    left: 100%;
    margin-left: calc(5px + .3em)
}

[class*=uk-divider] {
    border: none;
    margin-bottom: 20px
}

*+[class*=uk-divider] {
    margin-top: 20px
}

.uk-divider-icon {
    position: relative;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='none' stroke='%23dbdbdb' stroke-width='2' cx='10' cy='10' r='7'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%
}

.uk-divider-icon:after,
.uk-divider-icon:before {
    content: "";
    position: absolute;
    top: 50%;
    max-width: calc(50% - 25px);
    border-bottom: 1px solid #dbdbdb
}

.uk-divider-icon:before {
    right: calc(50% + 25px);
    width: 100%
}

.uk-divider-icon:after {
    left: calc(50% + 25px);
    width: 100%
}

.uk-divider-small {
    line-height: 0
}

.uk-divider-small:after {
    content: "";
    display: inline-block;
    width: 100px;
    max-width: 100%;
    border-top: 1px solid #dbdbdb;
    vertical-align: top
}

.uk-divider-vertical {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid #dbdbdb
}

.uk-list {
    padding: 0;
    list-style: none
}

.uk-list>* {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid-column
}

.uk-list>*>:last-child {
    margin-bottom: 0
}

.uk-list>*>ul,
.uk-list>:nth-child(n+2) {
    margin-top: 10px
}

.uk-list-circle>*,
.uk-list-decimal>*,
.uk-list-disc>*,
.uk-list-hyphen>*,
.uk-list-square>* {
    padding-left: 30px
}

.uk-list-decimal {
    counter-reset: decimal
}

.uk-list-decimal>* {
    counter-increment: decimal
}

.uk-list-circle>:before,
.uk-list-decimal>:before,
.uk-list-disc>:before,
.uk-list-hyphen>:before,
.uk-list-square>:before {
    content: "";
    position: relative;
    left: -30px;
    width: 30px;
    height: 1.333em;
    margin-bottom: -1.333em;
    display: list-item;
    list-style-position: inside;
    text-align: right
}

.uk-list-disc>:before {
    list-style-type: disc
}

.uk-list-circle>:before {
    list-style-type: circle
}

.uk-list-square>:before {
    list-style-type: square
}

.uk-list-decimal>:before {
    content: counter(decimal, decimal) 
}

.uk-list-hyphen>:before {
    content: 
}

.uk-list-muted>:before {
    color: #8c8c8c !important
}

.uk-list-emphasis>:before {
    color: #161a1d !important
}

.uk-list-primary>:before {
    color: #00b14f !important
}

.uk-list-secondary>:before {
    color: #292929 !important
}

.uk-list-bullet>* {
    padding-left: 30px
}

.uk-list-bullet>:before {
    content: "";
    display: list-item;
    position: relative;
    left: -30px;
    width: 30px;
    height: 1.333em;
    margin-bottom: -1.333em;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='6' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23161a1d' cx='3' cy='3' r='3'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%
}

.uk-list-divider>:nth-child(n+2) {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #dbdbdb
}

.uk-list-striped>* {
    padding: 10px
}

.uk-list-striped>:nth-of-type(odd) {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    background: #f5f5f5
}

.uk-list-striped>:nth-child(n+2) {
    margin-top: 0
}

.uk-list-large>*>ul,
.uk-list-large>:nth-child(n+2) {
    margin-top: 20px
}

.uk-list-collapse>*>ul,
.uk-list-collapse>:nth-child(n+2) {
    margin-top: 0
}

.uk-list-large.uk-list-divider>:nth-child(n+2) {
    margin-top: 20px;
    padding-top: 20px
}

.uk-list-collapse.uk-list-divider>:nth-child(n+2) {
    margin-top: 0;
    padding-top: 0
}

.uk-list-large.uk-list-striped>* {
    padding: 20px 10px
}

.uk-list-collapse.uk-list-striped>* {
    padding-top: 0;
    padding-bottom: 0
}

.uk-list-collapse.uk-list-striped>:nth-child(n+2),
.uk-list-large.uk-list-striped>:nth-child(n+2) {
    margin-top: 0
}

.uk-description-list>dt {
    color: #161a1d;
    font-size: .875rem;
    font-weight: 400;
    text-transform: uppercase
}

.uk-description-list>dt:nth-child(n+2) {
    margin-top: 20px
}

.uk-description-list-divider>dt:nth-child(n+2) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #dbdbdb
}

.uk-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px
}

*+.uk-table {
    margin-top: 20px
}

.uk-table th {
    padding: 16px 12px;
    text-align: left;
    vertical-align: bottom;
    font-size: .875rem;
    font-weight: 400;
    color: #8c8c8c;
    text-transform: uppercase
}

.uk-table td {
    padding: 16px 12px;
    vertical-align: top
}

.uk-table td>:last-child {
    margin-bottom: 0
}

.uk-table tfoot {
    font-size: .875rem
}

.uk-table caption {
    font-size: .875rem;
    text-align: left;
    color: #8c8c8c
}

.uk-table-middle,
.uk-table-middle td {
    vertical-align: middle !important
}

.uk-table-divider>:first-child>tr:not(:first-child),
.uk-table-divider>:not(:first-child)>tr,
.uk-table-divider>tr:not(:first-child) {
    border-top: 1px solid #dbdbdb
}

.uk-table-striped>tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
    background: #f5f5f5;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb
}

.uk-table-hover>tr:hover,
.uk-table-hover tbody tr:hover,
.uk-table>tr.uk-active,
.uk-table tbody tr.uk-active {
    background: #ffd
}

.uk-table-small td,
.uk-table-small th {
    padding: 10px 12px
}

.uk-table-large td,
.uk-table-large th {
    padding: 22px 12px
}

.uk-table-justify td:first-child,
.uk-table-justify th:first-child {
    padding-left: 0
}

.uk-table-justify td:last-child,
.uk-table-justify th:last-child {
    padding-right: 0
}

.uk-table-shrink {
    width: 1px
}

.uk-table-expand {
    min-width: 150px
}

.uk-table-link {
    padding: 0 !important
}

.uk-table-link>a {
    display: block;
    padding: 16px 12px
}

.uk-table-small .uk-table-link>a {
    padding: 10px 12px
}

@media (max-width:767px) {

    .uk-table-responsive,
    .uk-table-responsive tbody,
    .uk-table-responsive td,
    .uk-table-responsive th,
    .uk-table-responsive tr {
        display: block
    }

    .uk-table-responsive thead {
        display: none
    }

    .uk-table-responsive td,
    .uk-table-responsive th {
        width: auto !important;
        max-width: none !important;
        min-width: 0 !important;
        overflow: visible !important;
        white-space: normal !important
    }

    .uk-table-responsive .uk-table-link:not(:first-child)>a,
    .uk-table-responsive td:not(:first-child):not(.uk-table-link),
    .uk-table-responsive th:not(:first-child):not(.uk-table-link) {
        padding-top: 5px !important
    }

    .uk-table-responsive .uk-table-link:not(:last-child)>a,
    .uk-table-responsive td:not(:last-child):not(.uk-table-link),
    .uk-table-responsive th:not(:last-child):not(.uk-table-link) {
        padding-bottom: 5px !important
    }

    .uk-table-justify.uk-table-responsive td,
    .uk-table-justify.uk-table-responsive th {
        padding-left: 0;
        padding-right: 0
    }
}

.uk-table tbody tr {
    transition: background-color .1s linear
}

.uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-table-striped tbody tr:nth-of-type(2n):last-child {
    border-bottom: 1px solid #dbdbdb
}

.uk-icon {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0
}

button.uk-icon:not(:disabled) {
    cursor: pointer
}

.uk-icon::-moz-focus-inner {
    border: 0;
    padding: 0
}

.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
    fill: currentcolor
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
    stroke: currentcolor
}

.uk-icon>* {
    transform: translate(0)
}

.uk-icon-image {
    width: 20px;
    height: 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    max-width: none
}

.uk-icon-link {
    color: #8c8c8c;
    text-decoration: none !important
}

.uk-icon-link:hover {
    color: #161a1d
}

.uk-active>.uk-icon-link,
.uk-icon-link:active {
    color: #0b0d0f
}

.uk-icon-button {
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-radius: 500px;
    background: #f5f5f5;
    color: #8c8c8c;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: .1s ease-in-out;
    transition-property: color, background-color
}

.uk-icon-button:hover {
    background-color: #e8e8e8;
    color: #161a1d
}

.uk-active>.uk-icon-button,
.uk-icon-button:active {
    background-color: #dcdcdc;
    color: #161a1d
}

.uk-range {
    -webkit-appearance: none;
    box-sizing: border-box;
    margin: 0;
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
    background: transparent
}

.uk-range:focus {
    outline: none
}

.uk-range::-moz-focus-outer {
    border: none
}

.uk-range:not(:disabled)::-webkit-slider-thumb {
    cursor: pointer
}

.uk-range:not(:disabled)::-moz-range-thumb {
    cursor: pointer
}

.uk-range::-webkit-slider-runnable-track {
    height: 3px;
    background: #e8e8e8;
    border-radius: 500px
}

.uk-range:active::-webkit-slider-runnable-track,
.uk-range:focus::-webkit-slider-runnable-track {
    background: #dbdbdb
}

.uk-range::-moz-range-track {
    height: 3px;
    background: #e8e8e8;
    border-radius: 500px
}

.uk-range:focus::-moz-range-track {
    background: #dbdbdb
}

.uk-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -7px;
    height: 15px;
    width: 15px;
    border-radius: 500px;
    background: #fff;
    border: 1px solid #c2c2c2
}

.uk-range::-moz-range-thumb {
    height: 15px;
    width: 15px;
    margin-top: -7px;
    border-radius: 500px;
    background: #fff;
    border: 1px solid #c2c2c2
}

.uk-checkbox,
.uk-input,
.uk-radio,
.uk-select,
.uk-textarea {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit
}

.uk-input {
    overflow: visible
}

.uk-select {
    text-transform: none
}

.uk-select optgroup {
    font: inherit;
    font-weight: 700
}

.uk-textarea {
    overflow: auto
}

.uk-input[type=search]::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

.uk-input[type=number]::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button {
    height: auto
}

.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
    opacity: 1
}

.uk-checkbox:not(:disabled),
.uk-radio:not(:disabled) {
    cursor: pointer
}

.uk-fieldset {
    border: none;
    margin: 0;
    padding: 0;
    min-width: 0
}

.uk-input,
.uk-textarea {
    -webkit-appearance: none
}

.uk-input,
.uk-select,
.uk-textarea {
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
    background: #fff;
    color: #161a1d;
    border: 1px solid #dbdbdb;
    transition: .2s ease-in-out;
    transition-property: color, background-color, border
}

.uk-input,
.uk-select:not([multiple]):not([size]) {
    height: 40px;
    vertical-align: middle;
    display: inline-block
}

.uk-input:not(input),
.uk-select:not(select) {
    line-height: 38px
}

.uk-select[multiple],
.uk-select[size],
.uk-textarea {
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: top
}

.uk-select[multiple],
.uk-select[size] {
    resize: vertical
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
    outline: none;
    background-color: #fff;
    color: #161a1d;
    border-color: #00b14f
}

.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
    background-color: #f5f5f5;
    color: #8c8c8c;
    border-color: #dbdbdb
}

.uk-input::-webkit-input-placeholder {
    color: #8c8c8c
}

.uk-input::-moz-placeholder {
    color: #8c8c8c
}

.uk-input:-ms-input-placeholder {
    color: #8c8c8c
}

.uk-input::-ms-input-placeholder {
    color: #8c8c8c
}

.uk-input::placeholder {
    color: #8c8c8c
}

.uk-textarea::-webkit-input-placeholder {
    color: #8c8c8c
}

.uk-textarea::-moz-placeholder {
    color: #8c8c8c
}

.uk-textarea:-ms-input-placeholder {
    color: #8c8c8c
}

.uk-textarea::-ms-input-placeholder {
    color: #8c8c8c
}

.uk-textarea::placeholder {
    color: #8c8c8c
}

.uk-form-small {
    font-size: .875rem
}

.uk-form-small:not(textarea):not([multiple]):not([size]) {
    height: 30px;
    padding-left: 8px;
    padding-right: 8px
}

[multiple].uk-form-small,
[size].uk-form-small,
textarea.uk-form-small {
    padding: 5px 8px
}

.uk-form-small:not(select):not(input):not(textarea) {
    line-height: 28px
}

.uk-form-large {
    font-size: 20px
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
    height: 55px;
    padding-left: 12px;
    padding-right: 12px
}

[multiple].uk-form-large,
[size].uk-form-large,
textarea.uk-form-large {
    padding: 7px 12px
}

.uk-form-large:not(select):not(input):not(textarea) {
    line-height: 53px
}

.uk-form-danger,
.uk-form-danger:focus {
    color: #ee6352;
    border-color: #ee6352
}

.uk-form-success,
.uk-form-success:focus {
    color: #00b14f;
    border-color: #00b14f
}

.uk-form-blank {
    background: none;
    border-color: transparent
}

.uk-form-blank:focus {
    border-color: #dbdbdb;
    border-style: solid
}

input.uk-form-width-xsmall {
    width: 50px
}

select.uk-form-width-xsmall {
    width: 75px
}

.uk-form-width-small {
    width: 130px
}

.uk-form-width-medium {
    width: 200px
}

.uk-form-width-large {
    width: 500px
}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23161a1d' d='M12 1L9 6h6zM12 13L9 8h6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100% 50%
}

.uk-select:not([multiple]):not([size]) option {
    color: #161a1d
}

.uk-select:not([multiple]):not([size]):disabled {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238c8c8c' d='M12 1L9 6h6zM12 13L9 8h6z'/%3E%3C/svg%3E")
}

.uk-input[list] {
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: 100% 50%
}

.uk-input[list]:focus,
.uk-input[list]:hover {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23161a1d' d='M12 12L8 6h8z'/%3E%3C/svg%3E")
}

.uk-input[list]::-webkit-calendar-picker-indicator {
    display: none !important
}

.uk-checkbox,
.uk-radio {
    display: inline-block;
    height: 16px;
    width: 16px;
    overflow: hidden;
    margin-top: -4px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #c2c2c2;
    transition: .2s ease-in-out;
    transition-property: background-color, border
}

.uk-radio {
    border-radius: 50%
}

.uk-checkbox:focus,
.uk-radio:focus {
    background-color: transparent;
    outline: none;
    border-color: #00b14f
}

.uk-checkbox:checked,
.uk-checkbox:indeterminate,
.uk-radio:checked {
    background-color: #00b14f;
    border-color: transparent
}

.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus,
.uk-radio:checked:focus {
    background-color: #007e38
}

.uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23fff' cx='8' cy='8' r='2'/%3E%3C/svg%3E")
}

.uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M12 1L5 7.5 2 5l-1 .5L5 10l8-8.5z'/%3E%3C/svg%3E")
}

.uk-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M3 8h10v1H3z'/%3E%3C/svg%3E")
}

.uk-checkbox:disabled,
.uk-radio:disabled {
    background-color: #f5f5f5;
    border-color: #dbdbdb
}

.uk-radio:disabled:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%238c8c8c' cx='8' cy='8' r='2'/%3E%3C/svg%3E")
}

.uk-checkbox:disabled:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238c8c8c' d='M12 1L5 7.5 2 5l-1 .5L5 10l8-8.5z'/%3E%3C/svg%3E")
}

.uk-checkbox:disabled:indeterminate {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238c8c8c' d='M3 8h10v1H3z'/%3E%3C/svg%3E")
}

.uk-legend {
    width: 100%;
    color: inherit;
    padding: 0;
    font-size: 24px;
    line-height: 1.4
}

.uk-form-custom {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle
}

.uk-form-custom input[type=file],
.uk-form-custom select {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-appearance: none;
    opacity: 0;
    cursor: pointer
}

.uk-form-custom input[type=file] {
    font-size: 500px;
    overflow: hidden
}

.uk-form-label {
    color: #161a1d;
    font-size: .875rem
}

.uk-form-stacked .uk-form-label {
    display: block;
    margin-bottom: 5px
}

@media (max-width:767px) {
    .uk-form-horizontal .uk-form-label {
        display: block;
        margin-bottom: 5px
    }
}

@media (min-width:768px) {
    .uk-form-horizontal .uk-form-label {
        width: 200px;
        margin-top: 7px;
        float: left
    }

    .uk-form-horizontal .uk-form-controls {
        margin-left: 215px
    }

    .uk-form-horizontal .uk-form-controls-text {
        padding-top: 7px
    }
}

.uk-form-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c
}

.uk-form-icon:hover {
    color: #161a1d
}

.uk-form-icon:not(a):not(button):not(input) {
    pointer-events: none
}

.uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
    padding-left: 40px !important
}

.uk-form-icon-flip {
    right: 0;
    left: auto
}

.uk-form-icon-flip~.uk-input {
    padding-right: 40px !important
}

.uk-button {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: .875rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color, background-color, border-color
}

.uk-button:not(:disabled) {
    cursor: pointer
}

.uk-button::-moz-focus-inner {
    border: 0;
    padding: 0
}

.uk-button:hover {
    text-decoration: none
}

.uk-button-default {
    background-color: transparent;
    color: #161a1d;
    border: 1px solid #dbdbdb
}

.uk-button-default:hover {
    background-color: transparent;
    color: #161a1d;
    border-color: #a8a8a8
}

.uk-button-default.uk-active,
.uk-button-default:active {
    background-color: transparent;
    color: #161a1d;
    border-color: #8f8f8f
}

.uk-button-primary {
    background-color: #00b14f;
    color: #fff;
    border: 1px solid transparent
}

.uk-button-primary:hover {
    background-color: #009844;
    color: #fff
}

.uk-button-primary.uk-active,
.uk-button-primary:active {
    background-color: #007e38;
    color: #fff
}

.uk-button-secondary {
    background-color: #292929;
    color: #fff;
    border: 1px solid transparent
}

.uk-button-secondary:hover {
    background-color: #1c1c1c;
    color: #fff
}

.uk-button-secondary.uk-active,
.uk-button-secondary:active {
    background-color: #101010;
    color: #fff
}

.uk-button-danger {
    background-color: #ee6352;
    color: #fff;
    border: 1px solid transparent
}

.uk-button-danger:hover {
    background-color: #ec4e3b;
    color: #fff
}

.uk-button-danger.uk-active,
.uk-button-danger:active {
    background-color: #e93924;
    color: #fff
}

.uk-button-danger:disabled,
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled {
    background-color: transparent;
    color: #8c8c8c;
    border-color: #dbdbdb
}

.uk-button-small {
    padding: 0 15px;
    line-height: 28px;
    font-size: .875rem
}

.uk-button-large {
    padding: 0 40px;
    line-height: 53px;
    font-size: .875rem
}

.uk-button-text {
    padding: 0;
    line-height: var(--typ-base-line-height);
    background: none;
    color: #161a1d;
    position: relative
}

.uk-button-text:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    border-bottom: 1px solid;
    transition: right .3s ease-out
}

.uk-button-text:hover {
    color: #161a1d
}

.uk-button-text:hover:before {
    right: 0
}

.uk-button-text:disabled {
    color: #8c8c8c
}

.uk-button-text:disabled:before {
    display: none
}

.uk-button-link {
    padding: 0;
    line-height: var(--typ-base-line-height);
    background: none;
    color: #161a1d
}

.uk-button-link:disabled,
.uk-button-link:hover {
    color: #8c8c8c;
    text-decoration: none
}

.uk-button-group {
    display: inline-flex;
    vertical-align: middle;
    position: relative
}

.uk-button-group>.uk-button:nth-child(n+2),
.uk-button-group>div:nth-child(n+2) .uk-button {
    margin-left: -1px
}

.uk-button-group .uk-button.uk-active,
.uk-button-group .uk-button:active,
.uk-button-group .uk-button:focus,
.uk-button-group .uk-button:hover {
    position: relative;
    z-index: 1
}

.uk-progress {
    vertical-align: baseline;
    display: block;
    width: 100%;
    border: 0;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    height: 15px;
    border-radius: 500px;
    overflow: hidden
}

*+.uk-progress {
    margin-top: 20px
}

.uk-progress::-webkit-progress-bar {
    background-color: transparent
}

.uk-progress::-webkit-progress-value {
    background-color: #00b14f;
    -webkit-transition: width .6s ease;
    transition: width .6s ease
}

.uk-progress::-moz-progress-bar {
    background-color: #00b14f;
    -moz-transition: width .6s ease;
    transition: width .6s ease
}

.uk-section {
    display: flow-root;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px
}

@media (min-width:768px) {
    .uk-section {
        padding-top: 70px;
        padding-bottom: 70px
    }
}

.uk-section>:last-child {
    margin-bottom: 0
}

.uk-section-xsmall {
    padding-top: 20px;
    padding-bottom: 20px
}

.uk-section-small {
    padding-top: 40px;
    padding-bottom: 40px
}

.uk-section-large {
    padding-top: 70px;
    padding-bottom: 70px
}

@media (min-width:768px) {
    .uk-section-large {
        padding-top: 140px;
        padding-bottom: 140px
    }
}

.uk-section-xlarge {
    padding-top: 140px;
    padding-bottom: 140px
}

@media (min-width:768px) {
    .uk-section-xlarge {
        padding-top: 210px;
        padding-bottom: 210px
    }
}

.uk-section-default {
    background: #fff;
    --uk-navbar-color: dark
}

.uk-section-muted {
    background: #f5f5f5;
    --uk-navbar-color: dark
}

.uk-section-primary {
    background: #00b14f;
    --uk-navbar-color: light
}

.uk-section-secondary {
    background: #292929;
    --uk-navbar-color: light
}

.uk-container {
    display: flow-root;
    box-sizing: content-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width:360px) {
    .uk-container {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media (min-width:768px) {
    .uk-container {
        padding-left: 40px;
        padding-right: 40px
    }
}

.uk-container>:last-child {
    margin-bottom: 0
}

.uk-container .uk-container {
    padding-left: 0;
    padding-right: 0
}

.uk-container-xsmall {
    max-width: 750px
}

.uk-container-small {
    max-width: 900px
}

.uk-container-large {
    max-width: 1400px
}

.uk-container-xlarge {
    max-width: 1600px
}

.uk-container-expand {
    max-width: none
}

.uk-container-expand-left {
    margin-left: 0
}

.uk-container-expand-right {
    margin-right: 0
}

@media (min-width:360px) {

    .uk-container-expand-left.uk-container-xsmall,
    .uk-container-expand-right.uk-container-xsmall {
        max-width: calc(50% + 345px)
    }

    .uk-container-expand-left.uk-container-small,
    .uk-container-expand-right.uk-container-small {
        max-width: calc(50% + 420px)
    }
}

@media (min-width:768px) {

    .uk-container-expand-left,
    .uk-container-expand-right {
        max-width: calc(50% + 560px)
    }

    .uk-container-expand-left.uk-container-xsmall,
    .uk-container-expand-right.uk-container-xsmall {
        max-width: calc(50% + 335px)
    }

    .uk-container-expand-left.uk-container-small,
    .uk-container-expand-right.uk-container-small {
        max-width: calc(50% + 410px)
    }

    .uk-container-expand-left.uk-container-large,
    .uk-container-expand-right.uk-container-large {
        max-width: calc(50% + 660px)
    }

    .uk-container-expand-left.uk-container-xlarge,
    .uk-container-expand-right.uk-container-xlarge {
        max-width: calc(50% + 760px)
    }
}

.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 15px)
}

.uk-container-item-padding-remove-left {
    margin-left: -15px
}

.uk-container-item-padding-remove-right {
    margin-right: -15px
}

@media (min-width:360px) {

    .uk-container-item-padding-remove-left,
    .uk-container-item-padding-remove-right {
        width: calc(100% + 30px)
    }

    .uk-container-item-padding-remove-left {
        margin-left: -30px
    }

    .uk-container-item-padding-remove-right {
        margin-right: -30px
    }
}

@media (min-width:768px) {

    .uk-container-item-padding-remove-left,
    .uk-container-item-padding-remove-right {
        width: calc(100% + 40px)
    }

    .uk-container-item-padding-remove-left {
        margin-left: -40px
    }

    .uk-container-item-padding-remove-right {
        margin-right: -40px
    }
}

.uk-tile {
    display: flow-root;
    position: relative;
    box-sizing: border-box;
    padding: 40px 15px
}

@media (min-width:360px) {
    .uk-tile {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media (min-width:768px) {
    .uk-tile {
        padding: 70px 40px
    }
}

.uk-tile>:last-child {
    margin-bottom: 0
}

.uk-tile-xsmall {
    padding-top: 20px;
    padding-bottom: 20px
}

.uk-tile-small {
    padding-top: 40px;
    padding-bottom: 40px
}

.uk-tile-large {
    padding-top: 70px;
    padding-bottom: 70px
}

@media (min-width:768px) {
    .uk-tile-large {
        padding-top: 140px;
        padding-bottom: 140px
    }
}

.uk-tile-xlarge {
    padding-top: 140px;
    padding-bottom: 140px
}

@media (min-width:768px) {
    .uk-tile-xlarge {
        padding-top: 210px;
        padding-bottom: 210px
    }
}

.uk-tile-default {
    background-color: #fff
}

.uk-tile-muted {
    background-color: #f5f5f5
}

.uk-tile-primary {
    background-color: #00b14f
}

.uk-tile-secondary {
    background-color: #292929
}

.uk-card {
    position: relative;
    box-sizing: border-box;
    transition: box-shadow .1s ease-in-out
}

.uk-card-body {
    display: flow-root;
    padding: 30px
}

.uk-card-footer,
.uk-card-header {
    display: flow-root;
    padding: 15px 30px
}

@media (min-width:1025px) {
    .uk-card-body {
        padding: 40px
    }

    .uk-card-footer,
    .uk-card-header {
        padding: 20px 40px
    }
}

.uk-card-body>:last-child,
.uk-card-footer>:last-child,
.uk-card-header>:last-child {
    margin-bottom: 0
}

.uk-card-title {
    font-size: 24px;
    line-height: 1.4
}

.uk-card-badge {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    height: 22px;
    padding: 0 10px;
    background: #00b14f;
    color: #fff;
    font-size: .875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 2px;
    text-transform: uppercase
}

.uk-card-badge:first-child+* {
    margin-top: 0
}

.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
    background-color: #fff;
    box-shadow: 0 14px 25px rgba(0, 0, 0, .16)
}

.uk-card-default {
    background-color: #fff;
    color: #161a1d;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08)
}

.uk-card-default .uk-card-title {
    color: #161a1d
}

.uk-card-default.uk-card-hover:hover {
    background-color: #fff;
    box-shadow: 0 14px 25px rgba(0, 0, 0, .16)
}

.uk-card-default .uk-card-header {
    border-bottom: 1px solid #dbdbdb
}

.uk-card-default .uk-card-footer {
    border-top: 1px solid #dbdbdb
}

.uk-card-primary {
    background-color: #00b14f;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08)
}

.uk-card-primary .uk-card-title {
    color: #fff
}

.uk-card-primary.uk-card-hover:hover {
    background-color: #00b14f;
    box-shadow: 0 14px 25px rgba(0, 0, 0, .16)
}

.uk-card-secondary {
    background-color: #292929;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08)
}

.uk-card-secondary .uk-card-title {
    color: #fff
}

.uk-card-secondary.uk-card-hover:hover {
    background-color: #292929;
    box-shadow: 0 14px 25px rgba(0, 0, 0, .16)
}

.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
    padding: 20px
}

.uk-card-small .uk-card-footer,
.uk-card-small .uk-card-header {
    padding: 13px 20px
}

@media (min-width:1025px) {

    .uk-card-large.uk-card-body,
    .uk-card-large .uk-card-body {
        padding: 70px
    }

    .uk-card-large .uk-card-footer,
    .uk-card-large .uk-card-header {
        padding: 35px 70px
    }
}

.uk-card-body>.uk-nav-default {
    margin-left: -30px;
    margin-right: -30px
}

.uk-card-body>.uk-nav-default:only-child {
    margin-top: -15px;
    margin-bottom: -15px
}

.uk-card-body>.uk-nav-default .uk-nav-divider,
.uk-card-body>.uk-nav-default .uk-nav-header,
.uk-card-body>.uk-nav-default>li>a {
    padding-left: 30px;
    padding-right: 30px
}

.uk-card-body>.uk-nav-default .uk-nav-sub {
    padding-left: 45px
}

@media (min-width:1025px) {
    .uk-card-body>.uk-nav-default {
        margin-left: -40px;
        margin-right: -40px
    }

    .uk-card-body>.uk-nav-default:only-child {
        margin-top: -25px;
        margin-bottom: -25px
    }

    .uk-card-body>.uk-nav-default .uk-nav-divider,
    .uk-card-body>.uk-nav-default .uk-nav-header,
    .uk-card-body>.uk-nav-default>li>a {
        padding-left: 40px;
        padding-right: 40px
    }

    .uk-card-body>.uk-nav-default .uk-nav-sub {
        padding-left: 55px
    }
}

.uk-card-small>.uk-nav-default {
    margin-left: -20px;
    margin-right: -20px
}

.uk-card-small>.uk-nav-default:only-child {
    margin-top: -5px;
    margin-bottom: -5px
}

.uk-card-small>.uk-nav-default .uk-nav-divider,
.uk-card-small>.uk-nav-default .uk-nav-header,
.uk-card-small>.uk-nav-default>li>a {
    padding-left: 20px;
    padding-right: 20px
}

.uk-card-small>.uk-nav-default .uk-nav-sub {
    padding-left: 35px
}

@media (min-width:1025px) {

    .uk-card-large>.uk-nav-default,
    .uk-card-large>.uk-nav-default:only-child {
        margin: 0
    }

    .uk-card-large>.uk-nav-default .uk-nav-divider,
    .uk-card-large>.uk-nav-default .uk-nav-header,
    .uk-card-large>.uk-nav-default>li>a {
        padding-left: 0;
        padding-right: 0
    }

    .uk-card-large>.uk-nav-default .uk-nav-sub {
        padding-left: 15px
    }
}

.uk-close {
    color: #8c8c8c;
    transition: .1s ease-in-out;
    transition-property: color, opacity
}

.uk-close:hover {
    color: #161a1d
}

.uk-spinner>* {
    -webkit-animation: uk-spinner-rotate 1.4s linear infinite;
    animation: uk-spinner-rotate 1.4s linear infinite
}

@-webkit-keyframes uk-spinner-rotate {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(270deg)
    }
}

@keyframes uk-spinner-rotate {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(270deg)
    }
}

.uk-spinner>*>* {
    stroke-dasharray: 88px;
    stroke-dashoffset: 0;
    transform-origin: center;
    -webkit-animation: uk-spinner-dash 1.4s ease-in-out infinite;
    animation: uk-spinner-dash 1.4s ease-in-out infinite;
    stroke-width: 1;
    stroke-linecap: round
}

@-webkit-keyframes uk-spinner-dash {
    0% {
        stroke-dashoffset: 88px
    }

    50% {
        stroke-dashoffset: 22px;
        transform: rotate(135deg)
    }

    to {
        stroke-dashoffset: 88px;
        transform: rotate(450deg)
    }
}

@keyframes uk-spinner-dash {
    0% {
        stroke-dashoffset: 88px
    }

    50% {
        stroke-dashoffset: 22px;
        transform: rotate(135deg)
    }

    to {
        stroke-dashoffset: 88px;
        transform: rotate(450deg)
    }
}

.uk-totop {
    padding: 5px;
    color: #8c8c8c;
    transition: color .1s ease-in-out
}

.uk-totop:active,
.uk-totop:hover {
    color: #161a1d
}

.uk-marker {
    padding: 5px;
    background: #292929;
    color: #fff;
    border-radius: 500px
}

.uk-marker:hover {
    color: #fff
}

.uk-alert {
    position: relative;
    margin-bottom: 20px;
    padding: 15px 29px 15px 15px;
    background: #f5f5f5;
    color: #161a1d
}

*+.uk-alert {
    margin-top: 20px
}

.uk-alert>:last-child {
    margin-bottom: 0
}

.uk-alert-close {
    position: absolute;
    top: 20px;
    right: 15px;
    color: inherit;
    opacity: .4
}

.uk-alert-close:first-child+* {
    margin-top: 0
}

.uk-alert-close:hover {
    color: inherit;
    opacity: .8
}

.uk-alert-primary {
    background: #b4e8cb;
    color: #00b14f
}

.uk-alert-success {
    background: #c8eed9;
    color: #00b14f
}

.uk-alert-warning {
    background: #fce4ce;
    color: #f38522
}

.uk-alert-danger {
    background: #fef5f4;
    color: #ee6352
}

.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 {
    color: inherit
}

.uk-alert a:not([class]),
.uk-alert a:not([class]):hover {
    color: inherit;
    text-decoration: underline
}

.uk-placeholder {
    margin-bottom: 20px;
    padding: 30px;
    background: transparent;
    border: 1px dashed #dbdbdb
}

*+.uk-placeholder {
    margin-top: 20px
}

.uk-placeholder>:last-child {
    margin-bottom: 0
}

.uk-badge {
    box-sizing: border-box;
    min-width: 18px;
    height: 18px;
    padding: 0 5px;
    border-radius: 500px;
    vertical-align: middle;
    background: #00b14f;
    color: #fff !important;
    font-size: 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 0
}

.uk-badge:hover {
    text-decoration: none
}

.uk-label {
    display: inline-block;
    padding: 0 10px;
    background: #00b14f;
    line-height: var(--typ-base-line-height);
    font-size: .875rem;
    color: #fff;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 2px;
    text-transform: uppercase
}

.uk-label-success {
    background-color: #00b14f;
    color: #fff
}

.uk-label-warning {
    background-color: #f38522;
    color: #fff
}

.uk-label-danger {
    background-color: #ee6352;
    color: #fff
}

.uk-overlay {
    padding: 30px
}

.uk-overlay>:last-child {
    margin-bottom: 0
}

.uk-overlay-default {
    background: hsla(0, 0%, 100%, .8)
}

.uk-overlay-primary {
    background: rgba(41, 41, 41, .8)
}

.uk-article {
    display: flow-root
}

.uk-article>:last-child {
    margin-bottom: 0
}

.uk-article+.uk-article {
    margin-top: 70px
}

.uk-article-title {
    font-size: 2.23125rem;
    line-height: 1.2
}

@media (min-width:768px) {
    .uk-article-title {
        font-size: 2.625rem
    }
}

.uk-article-meta {
    font-size: .875rem;
    line-height: 1.4;
    color: #8c8c8c
}

.uk-article-meta a {
    color: #8c8c8c
}

.uk-article-meta a:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-comment-body {
    display: flow-root;
    overflow-wrap: break-word;
    word-wrap: break-word
}

.uk-comment-header {
    display: flow-root;
    margin-bottom: 20px
}

.uk-comment-body>:last-child,
.uk-comment-header>:last-child {
    margin-bottom: 0
}

.uk-comment-title {
    font-size: 20px;
    line-height: 1.4
}

.uk-comment-meta {
    font-size: .875rem;
    line-height: 1.4;
    color: #8c8c8c
}

.uk-comment-list {
    padding: 0;
    list-style: none
}

.uk-comment-list>:nth-child(n+2) {
    margin-top: 70px
}

.uk-comment-list .uk-comment~ul {
    margin: 70px 0 0;
    padding-left: 30px;
    list-style: none
}

@media (min-width:768px) {
    .uk-comment-list .uk-comment~ul {
        padding-left: 100px
    }
}

.uk-comment-list .uk-comment~ul>:nth-child(n+2) {
    margin-top: 70px
}

.uk-comment-primary {
    padding: 30px;
    background-color: #f5f5f5
}

.uk-search {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0
}

.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
    -webkit-appearance: none
}

.uk-search-input::-moz-placeholder {
    opacity: 1
}

.uk-search-input {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit;
    overflow: visible;
    -webkit-appearance: none;
    vertical-align: middle;
    width: 100%;
    border: none;
    color: #161a1d
}

.uk-search-input:focus {
    outline: none
}

.uk-search-input::-webkit-input-placeholder {
    color: #8c8c8c
}

.uk-search-input::-moz-placeholder {
    color: #8c8c8c
}

.uk-search-input:-ms-input-placeholder {
    color: #8c8c8c
}

.uk-search-input::-ms-input-placeholder {
    color: #8c8c8c
}

.uk-search-input::placeholder {
    color: #8c8c8c
}

.uk-search .uk-search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c
}

.uk-search .uk-search-icon:hover {
    color: #8c8c8c
}

.uk-search .uk-search-icon:not(a):not(button):not(input) {
    pointer-events: none
}

.uk-search .uk-search-icon-flip {
    right: 0;
    left: auto
}

.uk-search-default {
    width: 240px
}

.uk-search-default .uk-search-input {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
    border: 1px solid #dbdbdb
}

.uk-search-default .uk-search-input:focus {
    background-color: transparent;
    border-color: #00b14f
}

.uk-search-default .uk-search-icon {
    width: 40px
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
    padding-left: 40px
}

.uk-search-default .uk-search-icon-flip~.uk-search-input {
    padding-right: 40px
}

.uk-search-navbar {
    width: 400px
}

.uk-search-navbar .uk-search-input {
    height: 40px;
    background: transparent;
    font-size: 24px
}

.uk-search-navbar .uk-search-icon {
    width: 40px
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
    padding-left: 40px
}

.uk-search-navbar .uk-search-icon-flip~.uk-search-input {
    padding-right: 40px
}

.uk-search-large {
    width: 500px
}

.uk-search-large .uk-search-input {
    height: 80px;
    background: transparent;
    font-size: 2.625rem
}

.uk-search-large .uk-search-icon {
    width: 80px
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
    padding-left: 80px
}

.uk-search-large .uk-search-icon-flip~.uk-search-input {
    padding-right: 80px
}

.uk-search-toggle {
    color: #8c8c8c
}

.uk-search-toggle:hover {
    color: #161a1d
}

.uk-accordion {
    padding: 0;
    list-style: none
}

.uk-accordion>:nth-child(n+2) {
    margin-top: 20px
}

.uk-accordion-title {
    display: block;
    font-size: 20px;
    line-height: 1.4;
    color: #161a1d;
    overflow: hidden
}

.uk-accordion-title:before {
    content: "";
    width: 1.4em;
    height: 1.4em;
    margin-left: 10px;
    float: right;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23161a1d' d='M0 6h13v1H0z'/%3E%3Cpath fill='%23161a1d' d='M6 0h1v13H6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%
}

.uk-open>.uk-accordion-title:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23161a1d' d='M0 6h13v1H0z'/%3E%3C/svg%3E")
}

.uk-accordion-title:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-accordion-content {
    display: flow-root;
    margin-top: 20px
}

.uk-accordion-content>:last-child {
    margin-bottom: 0
}

.uk-drop {
    display: none;
    position: absolute;
    z-index: 1020;
    --uk-position-offset: 20px;
    --uk-position-viewport-offset: 15px;
    box-sizing: border-box;
    width: 300px
}

.uk-drop.uk-open {
    display: block
}

.uk-drop-stack .uk-drop-grid>* {
    width: 100% !important
}

.uk-drop-parent-icon {
    margin-left: .25em;
    transition: transform .3s ease-out
}

[aria-expanded=true]>.uk-drop-parent-icon {
    transform: rotateX(180deg)
}

.uk-dropbar {
    --uk-position-offset: 0;
    --uk-position-shift-offset: 0;
    --uk-position-viewport-offset: 0;
    width: auto;
    padding: 25px 15px;
    background: #fff;
    color: #161a1d
}

.uk-dropbar>:last-child {
    margin-bottom: 0
}

@media (min-width:360px) {
    .uk-dropbar {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media (min-width:768px) {
    .uk-dropbar {
        padding-left: 40px;
        padding-right: 40px
    }
}

.uk-dropbar :focus-visible {
    outline-color: #161a1d !important
}

.uk-dropbar-large {
    padding-top: 40px;
    padding-bottom: 40px
}

.uk-dropbar-top {
    box-shadow: 0 12px 7px -6px rgba(0, 0, 0, .05)
}

.uk-dropbar-bottom {
    box-shadow: 0 -12px 7px -6px rgba(0, 0, 0, .05)
}

.uk-dropbar-left {
    box-shadow: 12px 0 7px -6px rgba(0, 0, 0, .05)
}

.uk-dropbar-right {
    box-shadow: -12px 0 7px -6px rgba(0, 0, 0, .05)
}

.uk-dropnav-dropbar {
    position: absolute;
    z-index: 980;
    padding: 0;
    left: 0;
    right: 0
}

.uk-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    padding: 15px;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    transition: opacity .15s linear
}

@media (min-width:360px) {
    .uk-modal {
        padding: 50px 30px
    }
}

@media (min-width:768px) {
    .uk-modal {
        padding-left: 40px;
        padding-right: 40px
    }
}

.uk-modal.uk-open {
    opacity: 1
}

.uk-modal-page {
    overflow: hidden
}

.uk-modal-dialog {
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;
    width: 600px;
    max-width: 100% !important;
    background: #fff;
    opacity: 0;
    transform: translateY(-100px);
    transition: .3s linear;
    transition-property: opacity, transform
}

.uk-open>.uk-modal-dialog {
    opacity: 1;
    transform: translateY(0)
}

.uk-modal-container .uk-modal-dialog {
    width: 1200px
}

.uk-modal-full {
    padding: 0;
    background: none
}

.uk-modal-full .uk-modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%;
    transform: translateY(0)
}

.uk-modal-body {
    display: flow-root;
    padding: 20px
}

.uk-modal-header {
    border-bottom: 1px solid #dbdbdb
}

.uk-modal-footer,
.uk-modal-header {
    display: flow-root;
    padding: 10px 20px;
    background: #fff
}

.uk-modal-footer {
    border-top: 1px solid #dbdbdb
}

@media (min-width:360px) {
    .uk-modal-body {
        padding: 30px
    }

    .uk-modal-footer,
    .uk-modal-header {
        padding: 15px 30px
    }
}

.uk-modal-body>:last-child,
.uk-modal-footer>:last-child,
.uk-modal-header>:last-child {
    margin-bottom: 0
}

.uk-modal-title {
    font-size: 32px;
    line-height: 1.3
}

[class*=uk-modal-close-] {
    position: absolute;
    z-index: 1010;
    top: 10px;
    right: 10px;
    padding: 5px
}

[class*=uk-modal-close-]:first-child+* {
    margin-top: 0
}

.uk-modal-close-outside {
    top: 0;
    right: -5px;
    transform: translateY(-100%);
    color: #fff
}

.uk-modal-close-outside:hover {
    color: #fff
}

@media (min-width:768px) {
    .uk-modal-close-outside {
        right: 0;
        transform: translate(100%, -100%)
    }
}

.uk-modal-close-full {
    top: 0;
    right: 0;
    padding: 10px;
    background: #fff
}

@media (min-width:768px) {
    .uk-modal-close-full {
        padding: 20px
    }
}

.uk-slideshow {
    -webkit-tap-highlight-color: transparent
}

.uk-slideshow-items {
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    -webkit-touch-callout: none;
    touch-action: pan-y
}

.uk-slideshow-items>* {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    will-change: transform, opacity
}

.uk-slideshow-items>:not(.uk-active) {
    display: none
}

.uk-slider {
    -webkit-tap-highlight-color: transparent
}

.uk-slider-container {
    overflow: hidden;
    overflow: clip
}

.uk-slider-container-offset {
    margin: -11px -25px -39px;
    padding: 11px 25px 39px
}

.uk-slider-items {
    will-change: transform;
    position: relative;
    touch-action: pan-y
}

.uk-slider-items:not(.uk-grid) {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-touch-callout: none
}

.uk-slider-items.uk-grid {
    flex-wrap: nowrap
}

.uk-slider-items>* {
    flex: none;
    max-width: 100%
}

.uk-slider-items>*,
.uk-sticky {
    box-sizing: border-box;
    position: relative
}

.uk-sticky {
    z-index: 980
}

.uk-sticky-fixed {
    margin: 0 !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.uk-sticky.uk-animation-reverse,
.uk-sticky[class*=uk-animation-] {
    -webkit-animation-duration: .2s;
    animation-duration: .2s
}

.uk-sticky-placeholder {
    pointer-events: none
}

.uk-offcanvas {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000
}

.uk-offcanvas-flip .uk-offcanvas {
    right: 0;
    left: auto
}

.uk-offcanvas-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -270px;
    box-sizing: border-box;
    width: 270px;
    padding: 20px;
    background: #292929;
    overflow-y: auto
}

@media (min-width:360px) {
    .uk-offcanvas-bar {
        left: -350px;
        width: 350px;
        padding: 30px
    }
}

.uk-offcanvas-flip .uk-offcanvas-bar {
    left: auto;
    right: -270px
}

@media (min-width:360px) {
    .uk-offcanvas-flip .uk-offcanvas-bar {
        right: -350px
    }
}

.uk-open>.uk-offcanvas-bar {
    left: 0
}

.uk-offcanvas-flip .uk-open>.uk-offcanvas-bar {
    left: auto;
    right: 0
}

.uk-offcanvas-bar-animation {
    transition: left .3s ease-out
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
    transition-property: right
}

.uk-offcanvas-reveal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    transition: width .3s ease-out
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
    left: 0
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
    left: auto;
    right: 0
}

.uk-open>.uk-offcanvas-reveal {
    width: 270px
}

@media (min-width:360px) {
    .uk-open>.uk-offcanvas-reveal {
        width: 350px
    }
}

.uk-offcanvas-flip .uk-offcanvas-reveal {
    right: 0;
    left: auto
}

.uk-offcanvas-close {
    position: absolute;
    z-index: 1000;
    top: 5px;
    right: 5px;
    padding: 5px
}

@media (min-width:360px) {
    .uk-offcanvas-close {
        top: 10px;
        right: 10px
    }
}

.uk-offcanvas-close:first-child+* {
    margin-top: 0
}

.uk-offcanvas-overlay {
    width: 100vw;
    touch-action: none
}

.uk-offcanvas-overlay:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .1);
    opacity: 0;
    transition: opacity .15s linear
}

.uk-offcanvas-overlay.uk-open:before {
    opacity: 1
}

.uk-offcanvas-container,
.uk-offcanvas-page {
    overflow-x: hidden;
    overflow-x: clip
}

.uk-offcanvas-container {
    position: relative;
    left: 0;
    transition: left .3s ease-out;
    box-sizing: border-box;
    width: 100%
}

:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 270px
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -270px
}

@media (min-width:360px) {
    :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
        left: 350px
    }

    .uk-offcanvas-flip.uk-offcanvas-container-animation {
        left: -350px
    }
}

.uk-switcher {
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-switcher>:not(.uk-active) {
    display: none
}

.uk-switcher>*>:last-child {
    margin-bottom: 0
}

.uk-leader {
    overflow: hidden
}

.uk-leader-fill:after {
    display: inline-block;
    margin-left: 15px;
    width: 0;
    content: attr(data-fill);
    white-space: nowrap
}

.uk-leader-fill.uk-leader-hide:after {
    display: none
}

:root {
    --uk-leader-fill-content: .
}

.uk-notification {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1040;
    box-sizing: border-box;
    width: 350px
}

.uk-notification-bottom-right,
.uk-notification-top-right {
    left: auto;
    right: 10px
}

.uk-notification-bottom-center,
.uk-notification-top-center {
    left: 50%;
    margin-left: -175px
}

.uk-notification-bottom-center,
.uk-notification-bottom-left,
.uk-notification-bottom-right {
    top: auto;
    bottom: 10px
}

@media (max-width:359px) {
    .uk-notification {
        left: 10px;
        right: 10px;
        width: auto;
        margin: 0
    }
}

.uk-notification-message {
    position: relative;
    padding: 15px;
    background: #f5f5f5;
    color: #161a1d;
    font-size: 20px;
    line-height: 1.4;
    cursor: pointer
}

*+.uk-notification-message {
    margin-top: 10px
}

.uk-notification-close {
    display: none;
    position: absolute;
    top: 20px;
    right: 15px
}

.uk-notification-message:hover .uk-notification-close {
    display: block
}

.uk-notification-message-primary,
.uk-notification-message-success {
    color: #00b14f
}

.uk-notification-message-warning {
    color: #f38522
}

.uk-notification-message-danger {
    color: #ee6352
}

.uk-tooltip {
    display: none;
    position: absolute;
    z-index: 1030;
    --uk-position-offset: 10px;
    --uk-position-viewport-offset: 10;
    top: 0;
    box-sizing: border-box;
    max-width: 200px;
    padding: 3px 6px;
    background: #666;
    border-radius: 2px;
    color: #fff;
    font-size: 12px
}

.uk-tooltip.uk-active {
    display: block
}

.uk-sortable {
    position: relative
}

.uk-sortable>:last-child {
    margin-bottom: 0
}

.uk-sortable-drag {
    position: fixed !important;
    z-index: 1050 !important;
    pointer-events: none
}

.uk-sortable-placeholder {
    opacity: 0;
    pointer-events: none
}

.uk-sortable-empty {
    min-height: 50px
}

.uk-sortable-handle:hover {
    cursor: move
}

.uk-countdown-number {
    font-variant-numeric: tabular-nums;
    font-size: 2rem;
    line-height: .8
}

@media (min-width:360px) {
    .uk-countdown-number {
        font-size: 4rem
    }
}

@media (min-width:768px) {
    .uk-countdown-number {
        font-size: 6rem
    }
}

.uk-countdown-separator {
    font-size: 1rem;
    line-height: 1.6
}

@media (min-width:360px) {
    .uk-countdown-separator {
        font-size: 2rem
    }
}

@media (min-width:768px) {
    .uk-countdown-separator {
        font-size: 3rem
    }
}

.uk-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-grid>* {
    margin: 0
}

.uk-grid>*>:last-child {
    margin-bottom: 0
}

.uk-grid {
    margin-left: -16px
}

.uk-grid>* {
    padding-left: 16px
}

*+.uk-grid-margin,
.uk-grid+.uk-grid,
.uk-grid>.uk-grid-margin {
    margin-top: 16px
}

@media (min-width:1025px) {
    .uk-grid {
        margin-left: -16px
    }

    .uk-grid>* {
        padding-left: 16px
    }

    *+.uk-grid-margin,
    .uk-grid+.uk-grid,
    .uk-grid>.uk-grid-margin {
        margin-top: 16px
    }
}

.uk-grid-column-small,
.uk-grid-small {
    margin-left: -8px
}

.uk-grid-column-small>*,
.uk-grid-small>* {
    padding-left: 8px
}

*+.uk-grid-margin-small,
.uk-grid+.uk-grid-row-small,
.uk-grid+.uk-grid-small,
.uk-grid-row-small>.uk-grid-margin,
.uk-grid-small>.uk-grid-margin {
    margin-top: 8px
}

.uk-grid-column-medium,
.uk-grid-medium {
    margin-left: -16px
}

.uk-grid-column-medium>*,
.uk-grid-medium>* {
    padding-left: 16px
}

*+.uk-grid-margin-medium,
.uk-grid+.uk-grid-medium,
.uk-grid+.uk-grid-row-medium,
.uk-grid-medium>.uk-grid-margin,
.uk-grid-row-medium>.uk-grid-margin {
    margin-top: 16px
}

.uk-grid-column-large,
.uk-grid-large {
    margin-left: -40px
}

.uk-grid-column-large>*,
.uk-grid-large>* {
    padding-left: 40px
}

*+.uk-grid-margin-large,
.uk-grid+.uk-grid-large,
.uk-grid+.uk-grid-row-large,
.uk-grid-large>.uk-grid-margin,
.uk-grid-row-large>.uk-grid-margin {
    margin-top: 40px
}

@media (min-width:1025px) {

    .uk-grid-column-large,
    .uk-grid-large {
        margin-left: -70px
    }

    .uk-grid-column-large>*,
    .uk-grid-large>* {
        padding-left: 70px
    }

    *+.uk-grid-margin-large,
    .uk-grid+.uk-grid-large,
    .uk-grid+.uk-grid-row-large,
    .uk-grid-large>.uk-grid-margin,
    .uk-grid-row-large>.uk-grid-margin {
        margin-top: 70px
    }
}

.uk-grid-collapse,
.uk-grid-column-collapse {
    margin-left: 0
}

.uk-grid-collapse>*,
.uk-grid-column-collapse>* {
    padding-left: 0
}

.uk-grid+.uk-grid-collapse,
.uk-grid+.uk-grid-row-collapse,
.uk-grid-collapse>.uk-grid-margin,
.uk-grid-row-collapse>.uk-grid-margin {
    margin-top: 0
}

.uk-grid-divider>* {
    position: relative
}

.uk-grid-divider>:not(.uk-first-column):before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px solid #dbdbdb
}

.uk-grid-divider.uk-grid-stack>.uk-grid-margin:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #dbdbdb
}

.uk-grid-divider {
    margin-left: -32px
}

.uk-grid-divider>* {
    padding-left: 32px
}

.uk-grid-divider>:not(.uk-first-column):before {
    left: 16px
}

.uk-grid-divider.uk-grid-stack>.uk-grid-margin {
    margin-top: 32px
}

.uk-grid-divider.uk-grid-stack>.uk-grid-margin:before {
    top: -16px;
    left: 32px
}

@media (min-width:1025px) {
    .uk-grid-divider {
        margin-left: -32px
    }

    .uk-grid-divider>* {
        padding-left: 32px
    }

    .uk-grid-divider>:not(.uk-first-column):before {
        left: 16px
    }

    .uk-grid-divider.uk-grid-stack>.uk-grid-margin {
        margin-top: 32px
    }

    .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before {
        top: -16px;
        left: 32px
    }
}

.uk-grid-divider.uk-grid-column-small,
.uk-grid-divider.uk-grid-small {
    margin-left: -16px
}

.uk-grid-divider.uk-grid-column-small>*,
.uk-grid-divider.uk-grid-small>* {
    padding-left: 16px
}

.uk-grid-divider.uk-grid-column-small>:not(.uk-first-column):before,
.uk-grid-divider.uk-grid-small>:not(.uk-first-column):before {
    left: 8px
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack>.uk-grid-margin,
.uk-grid-divider.uk-grid-small.uk-grid-stack>.uk-grid-margin {
    margin-top: 16px
}

.uk-grid-divider.uk-grid-small.uk-grid-stack>.uk-grid-margin:before {
    top: -8px;
    left: 16px
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack>.uk-grid-margin:before {
    top: -8px
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack>.uk-grid-margin:before {
    left: 16px
}

.uk-grid-divider.uk-grid-column-medium,
.uk-grid-divider.uk-grid-medium {
    margin-left: -32px
}

.uk-grid-divider.uk-grid-column-medium>*,
.uk-grid-divider.uk-grid-medium>* {
    padding-left: 32px
}

.uk-grid-divider.uk-grid-column-medium>:not(.uk-first-column):before,
.uk-grid-divider.uk-grid-medium>:not(.uk-first-column):before {
    left: 16px
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack>.uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack>.uk-grid-margin {
    margin-top: 32px
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack>.uk-grid-margin:before {
    top: -16px;
    left: 32px
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack>.uk-grid-margin:before {
    top: -16px
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack>.uk-grid-margin:before {
    left: 32px
}

.uk-grid-divider.uk-grid-column-large,
.uk-grid-divider.uk-grid-large {
    margin-left: -80px
}

.uk-grid-divider.uk-grid-column-large>*,
.uk-grid-divider.uk-grid-large>* {
    padding-left: 80px
}

.uk-grid-divider.uk-grid-column-large>:not(.uk-first-column):before,
.uk-grid-divider.uk-grid-large>:not(.uk-first-column):before {
    left: 40px
}

.uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin {
    margin-top: 80px
}

.uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin:before {
    top: -40px;
    left: 80px
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin:before {
    top: -40px
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack>.uk-grid-margin:before {
    left: 80px
}

@media (min-width:1025px) {

    .uk-grid-divider.uk-grid-column-large,
    .uk-grid-divider.uk-grid-large {
        margin-left: -140px
    }

    .uk-grid-divider.uk-grid-column-large>*,
    .uk-grid-divider.uk-grid-large>* {
        padding-left: 140px
    }

    .uk-grid-divider.uk-grid-column-large>:not(.uk-first-column):before,
    .uk-grid-divider.uk-grid-large>:not(.uk-first-column):before {
        left: 70px
    }

    .uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin,
    .uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin {
        margin-top: 140px
    }

    .uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin:before {
        top: -70px;
        left: 140px
    }

    .uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin:before {
        top: -70px
    }

    .uk-grid-divider.uk-grid-column-large.uk-grid-stack>.uk-grid-margin:before {
        left: 140px
    }
}

.uk-grid-item-match,
.uk-grid-match>* {
    display: flex;
    flex-wrap: wrap
}

.uk-grid-item-match>:not([class*=uk-width]),
.uk-grid-match>*>:not([class*=uk-width]) {
    box-sizing: border-box;
    width: 100%;
    flex: auto
}

.uk-nav,
.uk-nav ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-nav li>a {
    display: flex;
    align-items: center;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
    text-decoration: none
}

.uk-nav>li>a {
    padding: 5px 0
}

ul.uk-nav-sub {
    padding: 5px 0 5px 15px
}

.uk-nav-sub ul {
    padding-left: 15px
}

.uk-nav-sub a {
    padding: 2px 0
}

.uk-nav-parent-icon {
    margin-left: auto;
    transition: transform .3s ease-out
}

.uk-nav>li.uk-open>a .uk-nav-parent-icon {
    transform: rotateX(180deg)
}

.uk-nav-header {
    padding: 5px 0;
    text-transform: uppercase;
    font-size: .875rem
}

.uk-nav-header:not(:first-child) {
    margin-top: 20px
}

.uk-nav .uk-nav-divider {
    margin: 5px 0
}

.uk-nav-default {
    font-size: .875rem;
    line-height: 1.333
}

.uk-nav-default>li>a {
    color: #8c8c8c
}

.uk-nav-default>li.uk-active>a,
.uk-nav-default>li>a:hover {
    color: #161a1d
}

.uk-nav-default .uk-nav-subtitle {
    font-size: 12px
}

.uk-nav-default .uk-nav-header {
    color: #161a1d
}

.uk-nav-default .uk-nav-divider {
    border-top: 1px solid #dbdbdb
}

.uk-nav-default .uk-nav-sub {
    font-size: .875rem;
    line-height: 1.333
}

.uk-nav-default .uk-nav-sub a {
    color: #8c8c8c
}

.uk-nav-default .uk-nav-sub a:hover,
.uk-nav-default .uk-nav-sub li.uk-active>a {
    color: #161a1d
}

.uk-nav-primary {
    font-size: 24px;
    line-height: 1.333
}

.uk-nav-primary>li>a {
    color: #8c8c8c
}

.uk-nav-primary>li.uk-active>a,
.uk-nav-primary>li>a:hover {
    color: #161a1d
}

.uk-nav-primary .uk-nav-subtitle {
    font-size: 20px
}

.uk-nav-primary .uk-nav-header {
    color: #161a1d
}

.uk-nav-primary .uk-nav-divider {
    border-top: 1px solid #dbdbdb
}

.uk-nav-primary .uk-nav-sub {
    font-size: 20px;
    line-height: 1.333
}

.uk-nav-primary .uk-nav-sub a {
    color: #8c8c8c
}

.uk-nav-primary .uk-nav-sub a:hover,
.uk-nav-primary .uk-nav-sub li.uk-active>a {
    color: #161a1d
}

.uk-nav-secondary {
    font-size: 16px;
    line-height: 1.333
}

.uk-nav-secondary>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider) {
    margin-top: 0
}

.uk-nav-secondary>li>a {
    color: #161a1d;
    padding: 10px
}

.uk-nav-secondary>li.uk-active>a,
.uk-nav-secondary>li>a:hover {
    color: #161a1d;
    background-color: #f5f5f5
}

.uk-nav-secondary .uk-nav-subtitle {
    font-size: .875rem;
    color: #8c8c8c
}

.uk-nav-secondary .uk-nav-header,
.uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-nav-secondary>li>a:hover .uk-nav-subtitle {
    color: #161a1d
}

.uk-nav-secondary .uk-nav-divider {
    border-top: 1px solid #dbdbdb
}

.uk-nav-secondary .uk-nav-sub {
    font-size: .875rem;
    line-height: 1.333
}

.uk-nav-secondary .uk-nav-sub a {
    color: #8c8c8c
}

.uk-nav-secondary .uk-nav-sub a:hover,
.uk-nav-secondary .uk-nav-sub li.uk-active>a {
    color: #161a1d
}

.uk-nav-center {
    text-align: center
}

.uk-nav-center li>a {
    justify-content: center
}

.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
    padding-left: 0
}

.uk-nav-center .uk-nav-parent-icon {
    margin-left: .25em
}

.uk-nav.uk-nav-divider>:not(.uk-nav-header, .uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider) {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #dbdbdb
}

.uk-navbar {
    display: flex;
    position: relative;
    --uk-navbar-dropbar-behind-color: dark
}

.uk-navbar-container:not(.uk-navbar-transparent) {
    background: #f5f5f5
}

.uk-navbar-left,
.uk-navbar-right,
[class*=uk-navbar-center] {
    display: flex;
    gap: 15px;
    align-items: center
}

.uk-navbar-right {
    margin-left: auto
}

.uk-navbar-center:only-child {
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.uk-navbar-center:not(:only-child) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    box-sizing: border-box;
    z-index: 990
}

.uk-navbar-center-left,
.uk-navbar-center-right {
    position: absolute;
    top: 0
}

.uk-navbar-center-left {
    right: calc(100% + 15px)
}

.uk-navbar-center-right {
    left: calc(100% + 15px)
}

[class*=uk-navbar-center-] {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    box-sizing: border-box
}

.uk-navbar-nav {
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-navbar-center:only-child,
.uk-navbar-left,
.uk-navbar-right {
    flex-wrap: wrap
}

.uk-navbar-item,
.uk-navbar-nav>li>a,
.uk-navbar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
    box-sizing: border-box;
    min-height: 80px;
    font-size: .875rem;
    font-family: inherit;
    text-decoration: none
}

.uk-navbar-nav>li>a {
    padding: 0;
    color: #8c8c8c;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color, background-color
}

.uk-navbar-nav>li.uk-active>a,
.uk-navbar-nav>li:hover>a,
.uk-navbar-nav>li>a:active,
.uk-navbar-nav>li>a[aria-expanded=true] {
    color: #161a1d
}

.uk-navbar-parent-icon {
    margin-left: 4px;
    transition: transform .3s ease-out
}

.uk-navbar-nav>li>a[aria-expanded=true] .uk-navbar-parent-icon {
    transform: rotateX(180deg)
}

.uk-navbar-item {
    padding: 0;
    color: #161a1d
}

.uk-navbar-item>:last-child {
    margin-bottom: 0
}

.uk-navbar-toggle {
    padding: 0;
    color: #8c8c8c
}

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded=true] {
    color: #161a1d;
    text-decoration: none
}

.uk-navbar-subtitle {
    font-size: .875rem
}

.uk-navbar-justify .uk-navbar-item,
.uk-navbar-justify .uk-navbar-left,
.uk-navbar-justify .uk-navbar-nav,
.uk-navbar-justify .uk-navbar-nav>li,
.uk-navbar-justify .uk-navbar-right,
.uk-navbar-justify .uk-navbar-toggle {
    flex-grow: 1
}

.uk-navbar-dropdown {
    --uk-position-offset: 15px;
    --uk-position-shift-offset: 0;
    --uk-position-viewport-offset: 15px;
    width: 200px;
    padding: 25px;
    background: #fff;
    color: #161a1d;
    box-shadow: 0 5px 12px rgba(0, 0, 0, .15)
}

.uk-navbar-dropdown>:last-child {
    margin-bottom: 0
}

.uk-navbar-dropdown :focus-visible {
    outline-color: #161a1d !important
}

.uk-navbar-dropdown .uk-drop-grid {
    margin-left: -30px
}

.uk-navbar-dropdown .uk-drop-grid>* {
    padding-left: 30px
}

.uk-navbar-dropdown .uk-drop-grid>.uk-grid-margin {
    margin-top: 30px
}

.uk-navbar-dropdown-width-2:not(.uk-drop-stack) {
    width: 400px
}

.uk-navbar-dropdown-width-3:not(.uk-drop-stack) {
    width: 600px
}

.uk-navbar-dropdown-width-4:not(.uk-drop-stack) {
    width: 800px
}

.uk-navbar-dropdown-width-5:not(.uk-drop-stack) {
    width: 1000px
}

.uk-navbar-dropdown-large {
    --uk-position-shift-offset: 0;
    padding: 40px
}

.uk-navbar-dropdown-dropbar {
    width: auto;
    background: transparent;
    padding: 25px 0;
    --uk-position-offset: 0;
    --uk-position-shift-offset: 0;
    --uk-position-viewport-offset: 15px;
    box-shadow: none
}

@media (min-width:360px) {
    .uk-navbar-dropdown-dropbar {
        --uk-position-viewport-offset: 30px
    }
}

@media (min-width:768px) {
    .uk-navbar-dropdown-dropbar {
        --uk-position-viewport-offset: 40px
    }
}

.uk-navbar-dropdown-dropbar-large {
    --uk-position-shift-offset: 0;
    padding-top: 40px;
    padding-bottom: 40px
}

.uk-navbar-dropdown-nav {
    font-size: .875rem
}

.uk-navbar-dropdown-nav>li>a {
    color: #8c8c8c
}

.uk-navbar-dropdown-nav>li.uk-active>a,
.uk-navbar-dropdown-nav>li>a:hover {
    color: #161a1d
}

.uk-navbar-dropdown-nav .uk-nav-subtitle {
    font-size: 12px
}

.uk-navbar-dropdown-nav .uk-nav-header {
    color: #161a1d
}

.uk-navbar-dropdown-nav .uk-nav-divider {
    border-top: 1px solid #dbdbdb
}

.uk-navbar-dropdown-nav .uk-nav-sub a {
    color: #8c8c8c
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover,
.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active>a {
    color: #161a1d
}

.uk-navbar-container {
    transition: .1s ease-in-out;
    transition-property: background-color
}

@media (min-width:768px) {

    .uk-navbar-left,
    .uk-navbar-right,
    [class*=uk-navbar-center] {
        gap: 30px
    }

    .uk-navbar-center-left {
        right: calc(100% + 30px)
    }

    .uk-navbar-center-right {
        left: calc(100% + 30px)
    }
}

@media (min-width:768px) {
    .uk-navbar-nav {
        gap: 30px
    }
}

.uk-subnav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -20px;
    padding: 0;
    list-style: none
}

.uk-subnav>* {
    flex: none;
    padding-left: 20px;
    position: relative
}

.uk-subnav>*>:first-child {
    display: flex;
    align-items: center;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
    color: #8c8c8c;
    font-size: .875rem;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color, background-color
}

.uk-subnav>*>a:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-subnav>.uk-active>a {
    color: #161a1d
}

.uk-subnav-divider {
    margin-left: -41px
}

.uk-subnav-divider>* {
    display: flex;
    align-items: center
}

.uk-subnav-divider>:before {
    content: "";
    height: 1.5em;
    margin-left: 0;
    margin-right: 20px;
    border-left: 1px solid transparent
}

.uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before {
    border-left-color: #dbdbdb
}

.uk-subnav-pill>*>:first-child {
    padding: 5px 10px;
    background: transparent;
    color: #8c8c8c
}

.uk-subnav-pill>*>a:active,
.uk-subnav-pill>*>a:hover {
    background-color: #f5f5f5;
    color: #161a1d
}

.uk-subnav-pill>.uk-active>a {
    background-color: #00b14f;
    color: #fff
}

.uk-subnav>.uk-disabled>a {
    color: #8c8c8c
}

.uk-breadcrumb {
    padding: 0;
    list-style: none
}

.uk-breadcrumb>* {
    display: contents
}

.uk-breadcrumb>*>* {
    font-size: .875rem;
    color: #8c8c8c
}

.uk-breadcrumb>*>:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-breadcrumb>:last-child>a:not([href]),
.uk-breadcrumb>:last-child>span {
    color: #161a1d
}

.uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before {
    content: "/";
    display: inline-block;
    margin: 0 20px 0 16px;
    font-size: .875rem;
    color: #8c8c8c
}

.uk-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 0;
    padding: 0;
    list-style: none
}

.uk-pagination>* {
    flex: none;
    padding-left: 0;
    position: relative
}

.uk-pagination>*>* {
    display: flex;
    align-items: center;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
    padding: 5px 10px;
    color: #8c8c8c;
    transition: color .1s ease-in-out
}

.uk-pagination>*>:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-pagination>.uk-active>* {
    color: #161a1d
}

.uk-pagination>.uk-disabled>* {
    color: #8c8c8c
}

.uk-tab {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    padding: 0;
    list-style: none;
    position: relative
}

.uk-tab:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    border-bottom: 1px solid #dbdbdb
}

.uk-tab>* {
    flex: none;
    padding-left: 20px;
    position: relative
}

.uk-tab>*>a {
    display: flex;
    align-items: center;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
    justify-content: center;
    padding: 5px 10px;
    color: #8c8c8c;
    border-bottom: 1px solid transparent;
    font-size: .875rem;
    text-transform: uppercase;
    transition: color .1s ease-in-out
}

.uk-tab>*>a:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-tab>.uk-active>a {
    color: #161a1d;
    border-color: #00b14f
}

.uk-tab>.uk-disabled>a {
    color: #8c8c8c
}

.uk-tab-bottom:before {
    top: 0;
    bottom: auto
}

.uk-tab-bottom>*>a {
    border-top: 1px solid transparent;
    border-bottom: none
}

.uk-tab-left,
.uk-tab-right {
    flex-direction: column;
    margin-left: 0
}

.uk-tab-left>*,
.uk-tab-right>* {
    padding-left: 0
}

.uk-tab-left:before {
    left: auto;
    right: 0
}

.uk-tab-left:before,
.uk-tab-right:before {
    top: 0;
    bottom: 0;
    border-left: 1px solid #dbdbdb;
    border-bottom: none
}

.uk-tab-right:before {
    left: 0;
    right: auto
}

.uk-tab-left>*>a {
    border-right: 1px solid transparent
}

.uk-tab-left>*>a,
.uk-tab-right>*>a {
    justify-content: left;
    border-bottom: none
}

.uk-tab-right>*>a {
    border-left: 1px solid transparent
}

.uk-tab .uk-dropdown {
    margin-left: 30px
}

.uk-slidenav {
    padding: 5px 10px;
    color: rgba(22, 26, 29, .5);
    transition: color .1s ease-in-out
}

.uk-slidenav:hover {
    color: rgba(22, 26, 29, .9)
}

.uk-slidenav:active {
    color: rgba(22, 26, 29, .5)
}

.uk-slidenav-large {
    padding: 10px
}

.uk-dotnav,
.uk-slidenav-container {
    display: flex
}

.uk-dotnav {
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0 0 0 -12px
}

.uk-dotnav>* {
    flex: none;
    padding-left: 12px
}

.uk-dotnav>*>* {
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: transparent;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid rgba(22, 26, 29, .4);
    transition: .2s ease-in-out;
    transition-property: background-color, border-color
}

.uk-dotnav>*>:hover {
    background-color: rgba(22, 26, 29, .6);
    border-color: transparent
}

.uk-dotnav>*>:active {
    background-color: rgba(22, 26, 29, .2);
    border-color: transparent
}

.uk-dotnav>.uk-active>* {
    background-color: rgba(22, 26, 29, .6);
    border-color: transparent
}

.uk-dotnav-vertical {
    flex-direction: column;
    margin-left: 0;
    margin-top: -12px
}

.uk-dotnav-vertical>* {
    padding-left: 0;
    padding-top: 12px
}

.uk-thumbnav {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0 0 0 -15px
}

.uk-thumbnav>* {
    padding-left: 15px
}

.uk-thumbnav>*>* {
    display: inline-block;
    position: relative
}

.uk-thumbnav>*>:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .4));
    transition: opacity .1s ease-in-out
}

.uk-thumbnav>*>:hover:after,
.uk-thumbnav>.uk-active>:after {
    opacity: 0
}

.uk-thumbnav-vertical {
    flex-direction: column;
    margin-left: 0;
    margin-top: -15px
}

.uk-thumbnav-vertical>* {
    padding-left: 0;
    padding-top: 15px
}

.uk-iconnav {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0 0 0 -10px
}

.uk-iconnav>* {
    padding-left: 10px
}

.uk-iconnav>*>a {
    display: flex;
    align-items: center;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
    line-height: 0;
    color: #8c8c8c;
    text-decoration: none;
    font-size: .875rem;
    transition: .1s ease-in-out;
    transition-property: color, background-color
}

.uk-iconnav>*>a:hover,
.uk-iconnav>.uk-active>a {
    color: #161a1d
}

.uk-iconnav-vertical {
    flex-direction: column;
    margin-left: 0;
    margin-top: -10px
}

.uk-iconnav-vertical>* {
    padding-left: 0;
    padding-top: 10px
}

.uk-dropdown {
    --uk-position-offset: 10px;
    --uk-position-viewport-offset: 15px;
    width: auto;
    min-width: 200px;
    padding: 25px;
    background: #fff;
    color: #161a1d;
    box-shadow: 0 5px 12px rgba(0, 0, 0, .15)
}

.uk-dropdown>:last-child {
    margin-bottom: 0
}

.uk-dropdown :focus-visible {
    outline-color: #161a1d !important
}

.uk-dropdown-large {
    padding: 40px
}

.uk-dropdown-dropbar {
    width: auto;
    background: transparent;
    padding: 5px 0 25px;
    --uk-position-viewport-offset: 15px;
    box-shadow: none
}

@media (min-width:360px) {
    .uk-dropdown-dropbar {
        --uk-position-viewport-offset: 30px
    }
}

@media (min-width:768px) {
    .uk-dropdown-dropbar {
        --uk-position-viewport-offset: 40px
    }
}

.uk-dropdown-dropbar-large {
    padding-top: 40px;
    padding-bottom: 40px
}

.uk-dropdown-nav {
    font-size: .875rem
}

.uk-dropdown-nav>li>a {
    color: #8c8c8c
}

.uk-dropdown-nav>li.uk-active>a,
.uk-dropdown-nav>li>a:hover {
    color: #161a1d
}

.uk-dropdown-nav .uk-nav-subtitle {
    font-size: 12px
}

.uk-dropdown-nav .uk-nav-header {
    color: #161a1d
}

.uk-dropdown-nav .uk-nav-divider {
    border-top: 1px solid #dbdbdb
}

.uk-dropdown-nav .uk-nav-sub a {
    color: #8c8c8c
}

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active>a {
    color: #161a1d
}

.uk-lightbox {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    background: #000;
    opacity: 0;
    transition: opacity .15s linear;
    touch-action: pinch-zoom
}

.uk-lightbox.uk-open {
    display: block;
    opacity: 1
}

.uk-lightbox :focus-visible {
    outline-color: hsla(0, 0%, 100%, .7)
}

.uk-lightbox-page {
    overflow: hidden
}

.uk-lightbox-items>* {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    color: hsla(0, 0%, 100%, .7);
    will-change: transform, opacity
}

.uk-lightbox-items>*>* {
    max-width: 100vw;
    max-height: 100vh
}

.uk-lightbox-items>*>:not(iframe) {
    width: auto;
    height: auto
}

.uk-lightbox-items>.uk-active {
    display: flex
}

.uk-lightbox-toolbar {
    padding: 10px;
    background: rgba(0, 0, 0, .3)
}

.uk-lightbox-toolbar,
.uk-lightbox-toolbar>* {
    color: hsla(0, 0%, 100%, .7)
}

.uk-lightbox-toolbar-icon {
    padding: 5px;
    color: hsla(0, 0%, 100%, .7)
}

.uk-lightbox-toolbar-icon:hover {
    color: #fff
}

.uk-lightbox-button {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, .3);
    color: hsla(0, 0%, 100%, .7);
    display: inline-flex;
    justify-content: center;
    align-items: center
}

.uk-lightbox-button:hover {
    color: #fff
}

.uk-lightbox-caption:empty {
    display: none
}

.uk-lightbox-iframe {
    width: 80%;
    height: 80%
}

[class*=uk-animation-] {
    -webkit-animation: .5s ease-out both;
    animation: .5s ease-out both
}

.uk-animation-fade {
    -webkit-animation-name: uk-fade;
    animation-name: uk-fade;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
}

.uk-animation-scale-up {
    -webkit-animation-name: uk-fade, uk-scale-up;
    animation-name: uk-fade, uk-scale-up
}

.uk-animation-scale-down {
    -webkit-animation-name: uk-fade, uk-scale-down;
    animation-name: uk-fade, uk-scale-down
}

.uk-animation-slide-top {
    -webkit-animation-name: uk-fade, uk-slide-top;
    animation-name: uk-fade, uk-slide-top
}

.uk-animation-slide-bottom {
    -webkit-animation-name: uk-fade, uk-slide-bottom;
    animation-name: uk-fade, uk-slide-bottom
}

.uk-animation-slide-left {
    -webkit-animation-name: uk-fade, uk-slide-left;
    animation-name: uk-fade, uk-slide-left
}

.uk-animation-slide-right {
    -webkit-animation-name: uk-fade, uk-slide-right;
    animation-name: uk-fade, uk-slide-right
}

.uk-animation-slide-top-small {
    -webkit-animation-name: uk-fade, uk-slide-top-small;
    animation-name: uk-fade, uk-slide-top-small
}

.uk-animation-slide-bottom-small {
    -webkit-animation-name: uk-fade, uk-slide-bottom-small;
    animation-name: uk-fade, uk-slide-bottom-small
}

.uk-animation-slide-left-small {
    -webkit-animation-name: uk-fade, uk-slide-left-small;
    animation-name: uk-fade, uk-slide-left-small
}

.uk-animation-slide-right-small {
    -webkit-animation-name: uk-fade, uk-slide-right-small;
    animation-name: uk-fade, uk-slide-right-small
}

.uk-animation-slide-top-medium {
    -webkit-animation-name: uk-fade, uk-slide-top-medium;
    animation-name: uk-fade, uk-slide-top-medium
}

.uk-animation-slide-bottom-medium {
    -webkit-animation-name: uk-fade, uk-slide-bottom-medium;
    animation-name: uk-fade, uk-slide-bottom-medium
}

.uk-animation-slide-left-medium {
    -webkit-animation-name: uk-fade, uk-slide-left-medium;
    animation-name: uk-fade, uk-slide-left-medium
}

.uk-animation-slide-right-medium {
    -webkit-animation-name: uk-fade, uk-slide-right-medium;
    animation-name: uk-fade, uk-slide-right-medium
}

.uk-animation-kenburns {
    -webkit-animation-name: uk-kenburns;
    animation-name: uk-kenburns;
    -webkit-animation-duration: 15s;
    animation-duration: 15s
}

.uk-animation-shake {
    -webkit-animation-name: uk-shake;
    animation-name: uk-shake
}

.uk-animation-stroke {
    -webkit-animation-name: uk-stroke;
    animation-name: uk-stroke;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    stroke-dasharray: var(--uk-animation-stroke)
}

.uk-animation-reverse {
    animation-direction: reverse;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

.uk-animation-fast {
    -webkit-animation-duration: .1s;
    animation-duration: .1s
}

.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
    -webkit-animation-name: none;
    animation-name: none
}

@-webkit-keyframes uk-fade {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes uk-fade {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes uk-scale-up {
    0% {
        transform: scale(.9)
    }

    to {
        transform: scale(1)
    }
}

@keyframes uk-scale-up {
    0% {
        transform: scale(.9)
    }

    to {
        transform: scale(1)
    }
}

@-webkit-keyframes uk-scale-down {
    0% {
        transform: scale(1.1)
    }

    to {
        transform: scale(1)
    }
}

@keyframes uk-scale-down {
    0% {
        transform: scale(1.1)
    }

    to {
        transform: scale(1)
    }
}

@-webkit-keyframes uk-slide-top {
    0% {
        transform: translateY(-100%)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes uk-slide-top {
    0% {
        transform: translateY(-100%)
    }

    to {
        transform: translateY(0)
    }
}

@-webkit-keyframes uk-slide-bottom {
    0% {
        transform: translateY(100%)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes uk-slide-bottom {
    0% {
        transform: translateY(100%)
    }

    to {
        transform: translateY(0)
    }
}

@-webkit-keyframes uk-slide-left {
    0% {
        transform: translateX(-100%)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes uk-slide-left {
    0% {
        transform: translateX(-100%)
    }

    to {
        transform: translateX(0)
    }
}

@-webkit-keyframes uk-slide-right {
    0% {
        transform: translateX(100%)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes uk-slide-right {
    0% {
        transform: translateX(100%)
    }

    to {
        transform: translateX(0)
    }
}

@-webkit-keyframes uk-slide-top-small {
    0% {
        transform: translateY(-10px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes uk-slide-top-small {
    0% {
        transform: translateY(-10px)
    }

    to {
        transform: translateY(0)
    }
}

@-webkit-keyframes uk-slide-bottom-small {
    0% {
        transform: translateY(10px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes uk-slide-bottom-small {
    0% {
        transform: translateY(10px)
    }

    to {
        transform: translateY(0)
    }
}

@-webkit-keyframes uk-slide-left-small {
    0% {
        transform: translateX(-10px)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes uk-slide-left-small {
    0% {
        transform: translateX(-10px)
    }

    to {
        transform: translateX(0)
    }
}

@-webkit-keyframes uk-slide-right-small {
    0% {
        transform: translateX(10px)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes uk-slide-right-small {
    0% {
        transform: translateX(10px)
    }

    to {
        transform: translateX(0)
    }
}

@-webkit-keyframes uk-slide-top-medium {
    0% {
        transform: translateY(-50px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes uk-slide-top-medium {
    0% {
        transform: translateY(-50px)
    }

    to {
        transform: translateY(0)
    }
}

@-webkit-keyframes uk-slide-bottom-medium {
    0% {
        transform: translateY(50px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes uk-slide-bottom-medium {
    0% {
        transform: translateY(50px)
    }

    to {
        transform: translateY(0)
    }
}

@-webkit-keyframes uk-slide-left-medium {
    0% {
        transform: translateX(-50px)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes uk-slide-left-medium {
    0% {
        transform: translateX(-50px)
    }

    to {
        transform: translateX(0)
    }
}

@-webkit-keyframes uk-slide-right-medium {
    0% {
        transform: translateX(50px)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes uk-slide-right-medium {
    0% {
        transform: translateX(50px)
    }

    to {
        transform: translateX(0)
    }
}

@-webkit-keyframes uk-kenburns {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(1.2)
    }
}

@keyframes uk-kenburns {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(1.2)
    }
}

@-webkit-keyframes uk-shake {

    0%,
    to {
        transform: translateX(0)
    }

    10% {
        transform: translateX(-9px)
    }

    20% {
        transform: translateX(8px)
    }

    30% {
        transform: translateX(-7px)
    }

    40% {
        transform: translateX(6px)
    }

    50% {
        transform: translateX(-5px)
    }

    60% {
        transform: translateX(4px)
    }

    70% {
        transform: translateX(-3px)
    }

    80% {
        transform: translateX(2px)
    }

    90% {
        transform: translateX(-1px)
    }
}

@keyframes uk-shake {

    0%,
    to {
        transform: translateX(0)
    }

    10% {
        transform: translateX(-9px)
    }

    20% {
        transform: translateX(8px)
    }

    30% {
        transform: translateX(-7px)
    }

    40% {
        transform: translateX(6px)
    }

    50% {
        transform: translateX(-5px)
    }

    60% {
        transform: translateX(4px)
    }

    70% {
        transform: translateX(-3px)
    }

    80% {
        transform: translateX(2px)
    }

    90% {
        transform: translateX(-1px)
    }
}

@-webkit-keyframes uk-stroke {
    0% {
        stroke-dashoffset: var(--uk-animation-stroke)
    }

    to {
        stroke-dashoffset: 0
    }
}

@keyframes uk-stroke {
    0% {
        stroke-dashoffset: var(--uk-animation-stroke)
    }

    to {
        stroke-dashoffset: 0
    }
}

[class*=uk-child-width]>* {
    box-sizing: border-box;
    width: 100%
}

.uk-child-width-1-2>* {
    width: 50%
}

.uk-child-width-1-3>* {
    width: 33.32223%
}

.uk-child-width-1-4>* {
    width: 25%
}

.uk-child-width-1-5>* {
    width: 20%
}

.uk-child-width-1-6>* {
    width: 16.66389%
}

.uk-child-width-auto>* {
    width: auto
}

.uk-child-width-expand>:not([class*=uk-width]) {
    flex: 1;
    min-width: 1px
}

@media (min-width:360px) {
    .uk-child-width-1-1\@s>* {
        width: 100%
    }

    .uk-child-width-1-2\@s>* {
        width: 50%
    }

    .uk-child-width-1-3\@s>* {
        width: 33.32223%
    }

    .uk-child-width-1-4\@s>* {
        width: 25%
    }

    .uk-child-width-1-5\@s>* {
        width: 20%
    }

    .uk-child-width-1-6\@s>* {
        width: 16.66389%
    }

    .uk-child-width-auto\@s>* {
        width: auto
    }

    .uk-child-width-expand\@s>:not([class*=uk-width]) {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width:768px) {
    .uk-child-width-1-1\@m>* {
        width: 100%
    }

    .uk-child-width-1-2\@m>* {
        width: 50%
    }

    .uk-child-width-1-3\@m>* {
        width: 33.32223%
    }

    .uk-child-width-1-4\@m>* {
        width: 25%
    }

    .uk-child-width-1-5\@m>* {
        width: 20%
    }

    .uk-child-width-1-6\@m>* {
        width: 16.66389%
    }

    .uk-child-width-auto\@m>* {
        width: auto
    }

    .uk-child-width-expand\@m>:not([class*=uk-width]) {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width:1025px) {
    .uk-child-width-1-1\@l>* {
        width: 100%
    }

    .uk-child-width-1-2\@l>* {
        width: 50%
    }

    .uk-child-width-1-3\@l>* {
        width: 33.32223%
    }

    .uk-child-width-1-4\@l>* {
        width: 25%
    }

    .uk-child-width-1-5\@l>* {
        width: 20%
    }

    .uk-child-width-1-6\@l>* {
        width: 16.66389%
    }

    .uk-child-width-auto\@l>* {
        width: auto
    }

    .uk-child-width-expand\@l>:not([class*=uk-width]) {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width:1280px) {
    .uk-child-width-1-1\@xl>* {
        width: 100%
    }

    .uk-child-width-1-2\@xl>* {
        width: 50%
    }

    .uk-child-width-1-3\@xl>* {
        width: 33.32223%
    }

    .uk-child-width-1-4\@xl>* {
        width: 25%
    }

    .uk-child-width-1-5\@xl>* {
        width: 20%
    }

    .uk-child-width-1-6\@xl>* {
        width: 16.66389%
    }

    .uk-child-width-auto\@xl>* {
        width: auto
    }

    .uk-child-width-expand\@xl>:not([class*=uk-width]) {
        flex: 1;
        min-width: 1px
    }
}

[class*=uk-width] {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%
}

.uk-width-1-2 {
    width: 50%
}

.uk-width-1-3 {
    width: 33.32223%
}

.uk-width-2-3 {
    width: 66.64445%
}

.uk-width-1-4 {
    width: 25%
}

.uk-width-3-4 {
    width: 75%
}

.uk-width-1-5 {
    width: 20%
}

.uk-width-2-5 {
    width: 40%
}

.uk-width-3-5 {
    width: 60%
}

.uk-width-4-5 {
    width: 80%
}

.uk-width-1-6 {
    width: 16.66389%
}

.uk-width-5-6 {
    width: 83.31945%
}

.uk-width-small {
    width: 150px
}

.uk-width-medium {
    width: 300px
}

.uk-width-large {
    width: 450px
}

.uk-width-xlarge {
    width: 600px
}

.uk-width-2xlarge {
    width: 750px
}

.uk-width-auto {
    width: auto
}

.uk-width-expand {
    flex: 1;
    min-width: 1px
}

@media (min-width:360px) {
    .uk-width-1-1\@s {
        width: 100%
    }

    .uk-width-1-2\@s {
        width: 50%
    }

    .uk-width-1-3\@s {
        width: 33.32223%
    }

    .uk-width-2-3\@s {
        width: 66.64445%
    }

    .uk-width-1-4\@s {
        width: 25%
    }

    .uk-width-3-4\@s {
        width: 75%
    }

    .uk-width-1-5\@s {
        width: 20%
    }

    .uk-width-2-5\@s {
        width: 40%
    }

    .uk-width-3-5\@s {
        width: 60%
    }

    .uk-width-4-5\@s {
        width: 80%
    }

    .uk-width-1-6\@s {
        width: 16.66389%
    }

    .uk-width-5-6\@s {
        width: 83.31945%
    }

    .uk-width-small\@s {
        width: 150px
    }

    .uk-width-medium\@s {
        width: 300px
    }

    .uk-width-large\@s {
        width: 450px
    }

    .uk-width-xlarge\@s {
        width: 600px
    }

    .uk-width-2xlarge\@s {
        width: 750px
    }

    .uk-width-auto\@s {
        width: auto
    }

    .uk-width-expand\@s {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width:768px) {
    .uk-width-1-1\@m {
        width: 100%
    }

    .uk-width-1-2\@m {
        width: 50%
    }

    .uk-width-1-3\@m {
        width: 33.32223%
    }

    .uk-width-2-3\@m {
        width: 66.64445%
    }

    .uk-width-1-4\@m {
        width: 25%
    }

    .uk-width-3-4\@m {
        width: 75%
    }

    .uk-width-1-5\@m {
        width: 20%
    }

    .uk-width-2-5\@m {
        width: 40%
    }

    .uk-width-3-5\@m {
        width: 60%
    }

    .uk-width-4-5\@m {
        width: 80%
    }

    .uk-width-1-6\@m {
        width: 16.66389%
    }

    .uk-width-5-6\@m {
        width: 83.31945%
    }

    .uk-width-small\@m {
        width: 150px
    }

    .uk-width-medium\@m {
        width: 300px
    }

    .uk-width-large\@m {
        width: 450px
    }

    .uk-width-xlarge\@m {
        width: 600px
    }

    .uk-width-2xlarge\@m {
        width: 750px
    }

    .uk-width-auto\@m {
        width: auto
    }

    .uk-width-expand\@m {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width:1025px) {
    .uk-width-1-1\@l {
        width: 100%
    }

    .uk-width-1-2\@l {
        width: 50%
    }

    .uk-width-1-3\@l {
        width: 33.32223%
    }

    .uk-width-2-3\@l {
        width: 66.64445%
    }

    .uk-width-1-4\@l {
        width: 25%
    }

    .uk-width-3-4\@l {
        width: 75%
    }

    .uk-width-1-5\@l {
        width: 20%
    }

    .uk-width-2-5\@l {
        width: 40%
    }

    .uk-width-3-5\@l {
        width: 60%
    }

    .uk-width-4-5\@l {
        width: 80%
    }

    .uk-width-1-6\@l {
        width: 16.66389%
    }

    .uk-width-5-6\@l {
        width: 83.31945%
    }

    .uk-width-small\@l {
        width: 150px
    }

    .uk-width-medium\@l {
        width: 300px
    }

    .uk-width-large\@l {
        width: 450px
    }

    .uk-width-xlarge\@l {
        width: 600px
    }

    .uk-width-2xlarge\@l {
        width: 750px
    }

    .uk-width-auto\@l {
        width: auto
    }

    .uk-width-expand\@l {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width:1280px) {
    .uk-width-1-1\@xl {
        width: 100%
    }

    .uk-width-1-2\@xl {
        width: 50%
    }

    .uk-width-1-3\@xl {
        width: 33.32223%
    }

    .uk-width-2-3\@xl {
        width: 66.64445%
    }

    .uk-width-1-4\@xl {
        width: 25%
    }

    .uk-width-3-4\@xl {
        width: 75%
    }

    .uk-width-1-5\@xl {
        width: 20%
    }

    .uk-width-2-5\@xl {
        width: 40%
    }

    .uk-width-3-5\@xl {
        width: 60%
    }

    .uk-width-4-5\@xl {
        width: 80%
    }

    .uk-width-1-6\@xl {
        width: 16.66389%
    }

    .uk-width-5-6\@xl {
        width: 83.31945%
    }

    .uk-width-small\@xl {
        width: 150px
    }

    .uk-width-medium\@xl {
        width: 300px
    }

    .uk-width-large\@xl {
        width: 450px
    }

    .uk-width-xlarge\@xl {
        width: 600px
    }

    .uk-width-2xlarge\@xl {
        width: 750px
    }

    .uk-width-auto\@xl {
        width: auto
    }

    .uk-width-expand\@xl {
        flex: 1;
        min-width: 1px
    }
}

.uk-width-max-content {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.uk-width-min-content {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content
}

[class*=uk-height] {
    box-sizing: border-box
}

.uk-height-1-1 {
    height: 100%
}

.uk-height-viewport {
    min-height: 100vh
}

.uk-height-viewport-2 {
    min-height: 200vh
}

.uk-height-viewport-3 {
    min-height: 300vh
}

.uk-height-viewport-4 {
    min-height: 400vh
}

.uk-height-small {
    height: 150px
}

.uk-height-medium {
    height: 300px
}

.uk-height-large {
    height: 450px
}

.uk-height-max-small {
    max-height: 150px
}

.uk-height-max-medium {
    max-height: 300px
}

.uk-height-max-large {
    max-height: 450px
}

.uk-text-lead {
    font-size: 24px;
    line-height: 1.5;
    color: #161a1d
}

.uk-text-meta {
    font-size: .875rem;
    line-height: 1.4;
    color: #8c8c8c
}

.uk-text-meta>a {
    color: #8c8c8c
}

.uk-text-meta>a:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-text-small {
    font-size: .875rem;
    line-height: 1.5
}

.uk-text-large {
    font-size: 24px;
    line-height: 1.5
}

.uk-text-default {
    font-size: 16px;
    line-height: 1.333
}

.uk-text-light {
    font-weight: 300
}

.uk-text-normal {
    font-weight: 400
}

.uk-text-bold {
    font-weight: 700
}

.uk-text-lighter {
    font-weight: lighter
}

.uk-text-bolder {
    font-weight: bolder
}

.uk-text-italic {
    font-style: italic
}

.uk-text-capitalize {
    text-transform: capitalize !important
}

.uk-text-uppercase {
    text-transform: uppercase !important
}

.uk-text-lowercase {
    text-transform: lowercase !important
}

.uk-text-decoration-none {
    text-decoration: none !important
}

.uk-text-muted {
    color: #8c8c8c !important
}

.uk-text-emphasis {
    color: #161a1d !important
}

.uk-text-primary {
    color: #00b14f !important
}

.uk-text-secondary {
    color: #292929 !important
}

.uk-text-success {
    color: #00b14f !important
}

.uk-text-warning {
    color: #f38522 !important
}

.uk-text-danger {
    color: #ee6352 !important
}

.uk-text-background {
    -webkit-background-clip: text;
    color: transparent !important;
    display: inline-block;
    background-color: #00b14f;
    background-image: linear-gradient(90deg, #00b14f, #009db1)
}

.uk-text-left {
    text-align: left !important
}

.uk-text-right {
    text-align: right !important
}

.uk-text-center {
    text-align: center !important
}

.uk-text-justify {
    text-align: justify !important
}

@media (min-width:360px) {
    .uk-text-left\@s {
        text-align: left !important
    }

    .uk-text-right\@s {
        text-align: right !important
    }

    .uk-text-center\@s {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .uk-text-left\@m {
        text-align: left !important
    }

    .uk-text-right\@m {
        text-align: right !important
    }

    .uk-text-center\@m {
        text-align: center !important
    }
}

@media (min-width:1025px) {
    .uk-text-left\@l {
        text-align: left !important
    }

    .uk-text-right\@l {
        text-align: right !important
    }

    .uk-text-center\@l {
        text-align: center !important
    }
}

@media (min-width:1280px) {
    .uk-text-left\@xl {
        text-align: left !important
    }

    .uk-text-right\@xl {
        text-align: right !important
    }

    .uk-text-center\@xl {
        text-align: center !important
    }
}

.uk-text-top {
    vertical-align: top !important
}

.uk-text-middle {
    vertical-align: middle !important
}

.uk-text-bottom {
    vertical-align: bottom !important
}

.uk-text-baseline {
    vertical-align: baseline !important
}

.uk-text-nowrap,
.uk-text-truncate {
    white-space: nowrap
}

.uk-text-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis
}

td.uk-text-truncate,
th.uk-text-truncate {
    max-width: 0
}

.uk-text-break {
    overflow-wrap: break-word
}

td.uk-text-break,
th.uk-text-break {
    word-break: break-word
}

[class*=uk-column-] {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px
}

@media (min-width:1025px) {
    [class*=uk-column-] {
        -webkit-column-gap: 40px;
        -moz-column-gap: 40px;
        column-gap: 40px
    }
}

[class*=uk-column-] img {
    transform: translateZ(0)
}

.uk-column-divider {
    -webkit-column-rule: 1px solid #dbdbdb;
    -moz-column-rule: 1px solid #dbdbdb;
    column-rule: 1px solid #dbdbdb;
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px
}

@media (min-width:1025px) {
    .uk-column-divider {
        -webkit-column-gap: 80px;
        -moz-column-gap: 80px;
        column-gap: 80px
    }
}

.uk-column-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
}

.uk-column-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3
}

.uk-column-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4
}

.uk-column-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5
}

.uk-column-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6
}

@media (min-width:360px) {
    .uk-column-1-2\@s {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
    }

    .uk-column-1-3\@s {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }

    .uk-column-1-4\@s {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4
    }

    .uk-column-1-5\@s {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5
    }

    .uk-column-1-6\@s {
        -webkit-column-count: 6;
        -moz-column-count: 6;
        column-count: 6
    }
}

@media (min-width:768px) {
    .uk-column-1-2\@m {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
    }

    .uk-column-1-3\@m {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }

    .uk-column-1-4\@m {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4
    }

    .uk-column-1-5\@m {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5
    }

    .uk-column-1-6\@m {
        -webkit-column-count: 6;
        -moz-column-count: 6;
        column-count: 6
    }
}

@media (min-width:1025px) {
    .uk-column-1-2\@l {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
    }

    .uk-column-1-3\@l {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }

    .uk-column-1-4\@l {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4
    }

    .uk-column-1-5\@l {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5
    }

    .uk-column-1-6\@l {
        -webkit-column-count: 6;
        -moz-column-count: 6;
        column-count: 6
    }
}

@media (min-width:1280px) {
    .uk-column-1-2\@xl {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
    }

    .uk-column-1-3\@xl {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }

    .uk-column-1-4\@xl {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4
    }

    .uk-column-1-5\@xl {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5
    }

    .uk-column-1-6\@xl {
        -webkit-column-count: 6;
        -moz-column-count: 6;
        column-count: 6
    }
}

.uk-column-span {
    -webkit-column-span: all;
    -moz-column-span: all;
    column-span: all
}

img[data-uk-cover],
img[uk-cover],
video[data-uk-cover],
video[uk-cover] {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

:not(img, video)[data-uk-cover],
:not(img, video)[uk-cover] {
    pointer-events: none;
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    --uk-position-translate-x: -50%;
    --uk-position-translate-y: -50%;
    transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y))
}

iframe[data-uk-cover],
iframe[uk-cover] {
    pointer-events: none
}

.uk-cover-container {
    overflow: hidden;
    position: relative
}

.uk-background-default {
    background-color: #fff
}

.uk-background-muted {
    background-color: #f5f5f5
}

.uk-background-primary {
    background-color: #00b14f
}

.uk-background-secondary {
    background-color: #292929
}

.uk-background-contain,
.uk-background-cover,
.uk-background-height-1-1,
.uk-background-width-1-1 {
    background-position: 50% 50%;
    background-repeat: no-repeat
}

.uk-background-cover {
    background-size: cover
}

.uk-background-contain {
    background-size: contain
}

.uk-background-width-1-1 {
    background-size: 100%
}

.uk-background-height-1-1 {
    background-size: auto 100%
}

.uk-background-top-left {
    background-position: 0 0
}

.uk-background-top-center {
    background-position: 50% 0
}

.uk-background-top-right {
    background-position: 100% 0
}

.uk-background-center-left {
    background-position: 0 50%
}

.uk-background-center-center {
    background-position: 50% 50%
}

.uk-background-center-right {
    background-position: 100% 50%
}

.uk-background-bottom-left {
    background-position: 0 100%
}

.uk-background-bottom-center {
    background-position: 50% 100%
}

.uk-background-bottom-right {
    background-position: 100% 100%
}

.uk-background-norepeat {
    background-repeat: no-repeat
}

.uk-background-fixed {
    background-attachment: fixed;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

@media (pointer:coarse) {
    .uk-background-fixed {
        background-attachment: scroll
    }
}

@media (max-width:359px) {
    .uk-background-image\@s {
        background-image: none !important
    }
}

@media (max-width:767px) {
    .uk-background-image\@m {
        background-image: none !important
    }
}

@media (max-width:1024px) {
    .uk-background-image\@l {
        background-image: none !important
    }
}

@media (max-width:1279px) {
    .uk-background-image\@xl {
        background-image: none !important
    }
}

.uk-background-blend-multiply {
    background-blend-mode: multiply
}

.uk-background-blend-screen {
    background-blend-mode: screen
}

.uk-background-blend-overlay {
    background-blend-mode: overlay
}

.uk-background-blend-darken {
    background-blend-mode: darken
}

.uk-background-blend-lighten {
    background-blend-mode: lighten
}

.uk-background-blend-color-dodge {
    background-blend-mode: color-dodge
}

.uk-background-blend-color-burn {
    background-blend-mode: color-burn
}

.uk-background-blend-hard-light {
    background-blend-mode: hard-light
}

.uk-background-blend-soft-light {
    background-blend-mode: soft-light
}

.uk-background-blend-difference {
    background-blend-mode: difference
}

.uk-background-blend-exclusion {
    background-blend-mode: exclusion
}

.uk-background-blend-hue {
    background-blend-mode: hue
}

.uk-background-blend-saturation {
    background-blend-mode: saturation
}

.uk-background-blend-color {
    background-blend-mode: color
}

.uk-background-blend-luminosity {
    background-blend-mode: luminosity
}

[class*=uk-align] {
    display: block;
    margin-bottom: 30px
}

*+[class*=uk-align] {
    margin-top: 30px
}

.uk-align-center {
    margin-left: auto;
    margin-right: auto
}

.uk-align-left {
    margin-top: 0;
    margin-right: 30px;
    float: left
}

.uk-align-right {
    margin-top: 0;
    margin-left: 30px;
    float: right
}

@media (min-width:360px) {
    .uk-align-left\@s {
        margin-top: 0;
        margin-right: 30px;
        float: left
    }

    .uk-align-right\@s {
        margin-top: 0;
        margin-left: 30px;
        float: right
    }
}

@media (min-width:768px) {
    .uk-align-left\@m {
        margin-top: 0;
        margin-right: 30px;
        float: left
    }

    .uk-align-right\@m {
        margin-top: 0;
        margin-left: 30px;
        float: right
    }
}

@media (min-width:1025px) {
    .uk-align-left\@l {
        margin-top: 0;
        float: left
    }

    .uk-align-right\@l {
        margin-top: 0;
        float: right
    }

    .uk-align-left,
    .uk-align-left\@l,
    .uk-align-left\@m,
    .uk-align-left\@s {
        margin-right: 40px
    }

    .uk-align-right,
    .uk-align-right\@l,
    .uk-align-right\@m,
    .uk-align-right\@s {
        margin-left: 40px
    }
}

@media (min-width:1280px) {
    .uk-align-left\@xl {
        margin-top: 0;
        margin-right: 40px;
        float: left
    }

    .uk-align-right\@xl {
        margin-top: 0;
        margin-left: 40px;
        float: right
    }
}

.uk-svg,
.uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
    fill: currentcolor
}

.uk-svg:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
    stroke: currentcolor
}

.uk-svg {
    transform: translate(0)
}

.uk-panel {
    display: flow-root;
    position: relative;
    box-sizing: border-box
}

.uk-panel>:last-child {
    margin-bottom: 0
}

.uk-panel-scrollable {
    height: 170px;
    padding: 10px;
    border: 1px solid #dbdbdb;
    overflow: auto;
    resize: both
}

.uk-clearfix:before {
    content: "";
    display: table-cell
}

.uk-clearfix:after {
    content: "";
    display: table;
    clear: both
}

.uk-float-left {
    float: left
}

.uk-float-right {
    float: right
}

[class*=uk-float-] {
    max-width: 100%
}

.uk-overflow-hidden {
    overflow: hidden
}

.uk-overflow-auto {
    overflow: auto
}

.uk-overflow-auto>:last-child {
    margin-bottom: 0
}

.uk-box-sizing-content {
    box-sizing: content-box
}

.uk-box-sizing-border {
    box-sizing: border-box
}

.uk-resize {
    resize: both
}

.uk-resize-horizontal {
    resize: horizontal
}

.uk-resize-vertical {
    resize: vertical
}

.uk-display-block {
    display: block !important
}

.uk-display-inline {
    display: inline !important
}

.uk-display-inline-block {
    display: inline-block !important
}

[class*=uk-inline] {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    -webkit-backface-visibility: hidden
}

.uk-inline-clip {
    overflow: hidden
}

.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
    max-width: none
}

.uk-responsive-height,
.uk-responsive-width {
    box-sizing: border-box
}

.uk-responsive-width {
    max-width: 100% !important;
    height: auto
}

.uk-responsive-height {
    max-height: 100%;
    width: auto;
    max-width: none
}

[data-uk-responsive],
[uk-responsive] {
    max-width: 100%
}

.uk-object-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.uk-object-contain {
    -o-object-fit: contain;
    object-fit: contain
}

.uk-object-fill {
    -o-object-fit: fill;
    object-fit: fill
}

.uk-object-none {
    -o-object-fit: none;
    object-fit: none
}

.uk-object-scale-down {
    -o-object-fit: scale-down;
    object-fit: scale-down
}

.uk-object-top-left {
    -o-object-position: 0 0;
    object-position: 0 0
}

.uk-object-top-center {
    -o-object-position: 50% 0;
    object-position: 50% 0
}

.uk-object-top-right {
    -o-object-position: 100% 0;
    object-position: 100% 0
}

.uk-object-center-left {
    -o-object-position: 0 50%;
    object-position: 0 50%
}

.uk-object-center-center {
    -o-object-position: 50% 50%;
    object-position: 50% 50%
}

.uk-object-center-right {
    -o-object-position: 100% 50%;
    object-position: 100% 50%
}

.uk-object-bottom-left {
    -o-object-position: 0 100%;
    object-position: 0 100%
}

.uk-object-bottom-center {
    -o-object-position: 50% 100%;
    object-position: 50% 100%
}

.uk-object-bottom-right {
    -o-object-position: 100% 100%;
    object-position: 100% 100%
}

.uk-border-circle {
    border-radius: 50%
}

.uk-border-pill {
    border-radius: 500px
}

.uk-border-rounded {
    border-radius: 5px
}

.uk-inline-clip[class*=uk-border-] {
    -webkit-transform: translateZ(0)
}

.uk-box-shadow-small {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .08)
}

.uk-box-shadow-medium {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08)
}

.uk-box-shadow-large {
    box-shadow: 0 14px 25px rgba(0, 0, 0, .16)
}

.uk-box-shadow-xlarge {
    box-shadow: 0 28px 50px rgba(0, 0, 0, .16)
}

[class*=uk-box-shadow-hover] {
    transition: box-shadow .1s ease-in-out
}

.uk-box-shadow-hover-small:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .08)
}

.uk-box-shadow-hover-medium:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08)
}

.uk-box-shadow-hover-large:hover {
    box-shadow: 0 14px 25px rgba(0, 0, 0, .16)
}

.uk-box-shadow-hover-xlarge:hover {
    box-shadow: 0 28px 50px rgba(0, 0, 0, .16)
}

@supports ((-webkit-filter:blur(0)) or (filter:blur(0))) {
    .uk-box-shadow-bottom {
        display: inline-block;
        position: relative;
        z-index: 0;
        max-width: 100%;
        vertical-align: middle
    }

    .uk-box-shadow-bottom:after {
        content: "";
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
        height: 30px;
        border-radius: 100%;
        background: #444;
        -webkit-filter: blur(20px);
        filter: blur(20px);
        will-change: filter
    }
}

.uk-dropcap:first-letter,
.uk-dropcap>p:first-of-type:first-letter {
    display: block;
    margin-right: 10px;
    float: left;
    font-size: calc(var(--typ-base-line-height)*3*1em);
    line-height: 1;
    margin-bottom: -2px
}

@-moz-document url-prefix() {

    .uk-dropcap:first-letter,
    .uk-dropcap>p:first-of-type:first-letter {
        margin-top: 1.1%
    }
}

.uk-logo {
    font-size: 24px;
    font-family: inherit;
    color: #161a1d;
    text-decoration: none
}

:where(.uk-logo) {
    display: inline-block;
    vertical-align: middle
}

.uk-logo:hover {
    color: #161a1d;
    text-decoration: none
}

.uk-logo :where(img, svg, video) {
    display: block
}

.uk-logo-inverse {
    display: none
}

.uk-disabled {
    pointer-events: none
}

.uk-drag,
.uk-drag * {
    cursor: move
}

.uk-drag iframe {
    pointer-events: none
}

.uk-dragover {
    box-shadow: 0 0 20px hsla(0, 0%, 39.2%, .3)
}

.uk-blend-multiply {
    mix-blend-mode: multiply
}

.uk-blend-screen {
    mix-blend-mode: screen
}

.uk-blend-overlay {
    mix-blend-mode: overlay
}

.uk-blend-darken {
    mix-blend-mode: darken
}

.uk-blend-lighten {
    mix-blend-mode: lighten
}

.uk-blend-color-dodge {
    mix-blend-mode: color-dodge
}

.uk-blend-color-burn {
    mix-blend-mode: color-burn
}

.uk-blend-hard-light {
    mix-blend-mode: hard-light
}

.uk-blend-soft-light {
    mix-blend-mode: soft-light
}

.uk-blend-difference {
    mix-blend-mode: difference
}

.uk-blend-exclusion {
    mix-blend-mode: exclusion
}

.uk-blend-hue {
    mix-blend-mode: hue
}

.uk-blend-saturation {
    mix-blend-mode: saturation
}

.uk-blend-color {
    mix-blend-mode: color
}

.uk-blend-luminosity {
    mix-blend-mode: luminosity
}

.uk-transform-center {
    transform: translate(-50%, -50%)
}

.uk-transform-origin-top-left {
    transform-origin: 0 0
}

.uk-transform-origin-top-center {
    transform-origin: 50% 0
}

.uk-transform-origin-top-right {
    transform-origin: 100% 0
}

.uk-transform-origin-center-left {
    transform-origin: 0 50%
}

.uk-transform-origin-center-right {
    transform-origin: 100% 50%
}

.uk-transform-origin-bottom-left {
    transform-origin: 0 100%
}

.uk-transform-origin-bottom-center {
    transform-origin: 50% 100%
}

.uk-transform-origin-bottom-right {
    transform-origin: 100% 100%
}

.uk-flex {
    display: flex
}

.uk-flex-inline {
    display: inline-flex
}

.uk-flex-left {
    justify-content: flex-start
}

.uk-flex-center {
    justify-content: center
}

.uk-flex-right {
    justify-content: flex-end
}

.uk-flex-between {
    justify-content: space-between
}

.uk-flex-around {
    justify-content: space-around
}

@media (min-width:360px) {
    .uk-flex-left\@s {
        justify-content: flex-start
    }

    .uk-flex-center\@s {
        justify-content: center
    }

    .uk-flex-right\@s {
        justify-content: flex-end
    }

    .uk-flex-between\@s {
        justify-content: space-between
    }

    .uk-flex-around\@s {
        justify-content: space-around
    }
}

@media (min-width:768px) {
    .uk-flex-left\@m {
        justify-content: flex-start
    }

    .uk-flex-center\@m {
        justify-content: center
    }

    .uk-flex-right\@m {
        justify-content: flex-end
    }

    .uk-flex-between\@m {
        justify-content: space-between
    }

    .uk-flex-around\@m {
        justify-content: space-around
    }
}

@media (min-width:1025px) {
    .uk-flex-left\@l {
        justify-content: flex-start
    }

    .uk-flex-center\@l {
        justify-content: center
    }

    .uk-flex-right\@l {
        justify-content: flex-end
    }

    .uk-flex-between\@l {
        justify-content: space-between
    }

    .uk-flex-around\@l {
        justify-content: space-around
    }
}

@media (min-width:1280px) {
    .uk-flex-left\@xl {
        justify-content: flex-start
    }

    .uk-flex-center\@xl {
        justify-content: center
    }

    .uk-flex-right\@xl {
        justify-content: flex-end
    }

    .uk-flex-between\@xl {
        justify-content: space-between
    }

    .uk-flex-around\@xl {
        justify-content: space-around
    }
}

.uk-flex-stretch {
    align-items: stretch
}

.uk-flex-top {
    align-items: flex-start
}

.uk-flex-middle {
    align-items: center
}

.uk-flex-bottom {
    align-items: flex-end
}

.uk-flex-row {
    flex-direction: row
}

.uk-flex-row-reverse {
    flex-direction: row-reverse
}

.uk-flex-column {
    flex-direction: column
}

.uk-flex-column-reverse {
    flex-direction: column-reverse
}

.uk-flex-nowrap {
    flex-wrap: nowrap
}

.uk-flex-wrap {
    flex-wrap: wrap
}

.uk-flex-wrap-reverse {
    flex-wrap: wrap-reverse
}

.uk-flex-wrap-stretch {
    align-content: stretch
}

.uk-flex-wrap-top {
    align-content: flex-start
}

.uk-flex-wrap-middle {
    align-content: center
}

.uk-flex-wrap-bottom {
    align-content: flex-end
}

.uk-flex-wrap-between {
    align-content: space-between
}

.uk-flex-wrap-around {
    align-content: space-around
}

.uk-flex-first {
    order: -1
}

.uk-flex-last {
    order: 99
}

@media (min-width:360px) {
    .uk-flex-first\@s {
        order: -1
    }

    .uk-flex-last\@s {
        order: 99
    }
}

@media (min-width:768px) {
    .uk-flex-first\@m {
        order: -1
    }

    .uk-flex-last\@m {
        order: 99
    }
}

@media (min-width:1025px) {
    .uk-flex-first\@l {
        order: -1
    }

    .uk-flex-last\@l {
        order: 99
    }
}

@media (min-width:1280px) {
    .uk-flex-first\@xl {
        order: -1
    }

    .uk-flex-last\@xl {
        order: 99
    }
}

.uk-flex-none {
    flex: none
}

.uk-flex-auto {
    flex: auto
}

.uk-flex-1 {
    flex: 1
}

.uk-margin {
    margin-bottom: 20px
}

*+.uk-margin,
.uk-margin-top {
    margin-top: 20px !important
}

.uk-margin-bottom {
    margin-bottom: 20px !important
}

.uk-margin-left {
    margin-left: 20px !important
}

.uk-margin-right {
    margin-right: 20px !important
}

.uk-margin-small {
    margin-bottom: 10px
}

*+.uk-margin-small,
.uk-margin-small-top {
    margin-top: 10px !important
}

.uk-margin-small-bottom {
    margin-bottom: 10px !important
}

.uk-margin-small-left {
    margin-left: 10px !important
}

.uk-margin-small-right {
    margin-right: 10px !important
}

.uk-margin-medium {
    margin-bottom: 40px
}

*+.uk-margin-medium,
.uk-margin-medium-top {
    margin-top: 40px !important
}

.uk-margin-medium-bottom {
    margin-bottom: 40px !important
}

.uk-margin-medium-left {
    margin-left: 40px !important
}

.uk-margin-medium-right {
    margin-right: 40px !important
}

.uk-margin-large {
    margin-bottom: 40px
}

*+.uk-margin-large,
.uk-margin-large-top {
    margin-top: 40px !important
}

.uk-margin-large-bottom {
    margin-bottom: 40px !important
}

.uk-margin-large-left {
    margin-left: 40px !important
}

.uk-margin-large-right {
    margin-right: 40px !important
}

@media (min-width:1025px) {
    .uk-margin-large {
        margin-bottom: 70px
    }

    *+.uk-margin-large,
    .uk-margin-large-top {
        margin-top: 70px !important
    }

    .uk-margin-large-bottom {
        margin-bottom: 70px !important
    }

    .uk-margin-large-left {
        margin-left: 70px !important
    }

    .uk-margin-large-right {
        margin-right: 70px !important
    }
}

.uk-margin-xlarge {
    margin-bottom: 70px
}

*+.uk-margin-xlarge,
.uk-margin-xlarge-top {
    margin-top: 70px !important
}

.uk-margin-xlarge-bottom {
    margin-bottom: 70px !important
}

.uk-margin-xlarge-left {
    margin-left: 70px !important
}

.uk-margin-xlarge-right {
    margin-right: 70px !important
}

@media (min-width:1025px) {
    .uk-margin-xlarge {
        margin-bottom: 140px
    }

    *+.uk-margin-xlarge,
    .uk-margin-xlarge-top {
        margin-top: 140px !important
    }

    .uk-margin-xlarge-bottom {
        margin-bottom: 140px !important
    }

    .uk-margin-xlarge-left {
        margin-left: 140px !important
    }

    .uk-margin-xlarge-right {
        margin-right: 140px !important
    }
}

.uk-margin-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.uk-margin-auto-top {
    margin-top: auto !important
}

.uk-margin-auto-bottom {
    margin-bottom: auto !important
}

.uk-margin-auto-left {
    margin-left: auto !important
}

.uk-margin-auto-right {
    margin-right: auto !important
}

.uk-margin-auto-vertical {
    margin-top: auto !important;
    margin-bottom: auto !important
}

@media (min-width:360px) {
    .uk-margin-auto\@s {
        margin-right: auto !important
    }

    .uk-margin-auto-left\@s,
    .uk-margin-auto\@s {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@s {
        margin-right: auto !important
    }
}

@media (min-width:768px) {
    .uk-margin-auto\@m {
        margin-right: auto !important
    }

    .uk-margin-auto-left\@m,
    .uk-margin-auto\@m {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@m {
        margin-right: auto !important
    }
}

@media (min-width:1025px) {
    .uk-margin-auto\@l {
        margin-right: auto !important
    }

    .uk-margin-auto-left\@l,
    .uk-margin-auto\@l {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@l {
        margin-right: auto !important
    }
}

@media (min-width:1280px) {
    .uk-margin-auto\@xl {
        margin-right: auto !important
    }

    .uk-margin-auto-left\@xl,
    .uk-margin-auto\@xl {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@xl {
        margin-right: auto !important
    }
}

.uk-margin-remove {
    margin: 0 !important
}

.uk-margin-remove-top {
    margin-top: 0 !important
}

.uk-margin-remove-bottom {
    margin-bottom: 0 !important
}

.uk-margin-remove-left {
    margin-left: 0 !important
}

.uk-margin-remove-right {
    margin-right: 0 !important
}

.uk-margin-remove-vertical {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.uk-margin-remove-adjacent+*,
.uk-margin-remove-first-child>:first-child {
    margin-top: 0 !important
}

.uk-margin-remove-last-child>:last-child {
    margin-bottom: 0 !important
}

@media (min-width:360px) {
    .uk-margin-remove-left\@s {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@s {
        margin-right: 0 !important
    }
}

@media (min-width:768px) {
    .uk-margin-remove-left\@m {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@m {
        margin-right: 0 !important
    }
}

@media (min-width:1025px) {
    .uk-margin-remove-left\@l {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@l {
        margin-right: 0 !important
    }
}

@media (min-width:1280px) {
    .uk-margin-remove-left\@xl {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@xl {
        margin-right: 0 !important
    }
}

.uk-padding {
    padding: 30px
}

@media (min-width:1025px) {
    .uk-padding {
        padding: 40px
    }
}

.uk-padding-small {
    padding: 15px
}

.uk-padding-large {
    padding: 40px
}

@media (min-width:1025px) {
    .uk-padding-large {
        padding: 70px
    }
}

.uk-padding-remove {
    padding: 0 !important
}

.uk-padding-remove-top {
    padding-top: 0 !important
}

.uk-padding-remove-bottom {
    padding-bottom: 0 !important
}

.uk-padding-remove-left {
    padding-left: 0 !important
}

.uk-padding-remove-right {
    padding-right: 0 !important
}

.uk-padding-remove-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.uk-padding-remove-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important
}

:root {
    --uk-position-margin-offset: 0px
}

[class*=uk-position-bottom],
[class*=uk-position-center],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-top] {
    position: absolute !important;
    max-width: calc(100% - var(--uk-position-margin-offset)*2);
    box-sizing: border-box
}

.uk-position-top {
    top: 0;
    left: 0;
    right: 0
}

.uk-position-bottom {
    bottom: 0;
    left: 0;
    right: 0
}

.uk-position-left {
    top: 0;
    bottom: 0;
    left: 0
}

.uk-position-right {
    top: 0;
    bottom: 0;
    right: 0
}

.uk-position-top-left {
    top: 0;
    left: 0
}

.uk-position-top-right {
    top: 0;
    right: 0
}

.uk-position-bottom-left {
    bottom: 0;
    left: 0
}

.uk-position-bottom-right {
    bottom: 0;
    right: 0
}

.uk-position-center {
    top: calc(50% - var(--uk-position-margin-offset));
    left: calc(50% - var(--uk-position-margin-offset));
    --uk-position-translate-x: -50%;
    --uk-position-translate-y: -50%;
    transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

[class*=uk-position-center-left],
[class*=uk-position-center-right] {
    top: calc(50% - var(--uk-position-margin-offset));
    --uk-position-translate-y: -50%;
    transform: translateY(var(--uk-position-translate-y))
}

.uk-position-center-left {
    left: 0
}

.uk-position-center-right {
    right: 0
}

.uk-position-center-left-out {
    right: 100%
}

.uk-position-center-left-out,
.uk-position-center-right-out {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.uk-position-center-right-out {
    left: 100%
}

.uk-position-bottom-center,
.uk-position-top-center {
    left: calc(50% - var(--uk-position-margin-offset));
    --uk-position-translate-x: -50%;
    transform: translate(var(--uk-position-translate-x));
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.uk-position-top-center {
    top: 0
}

.uk-position-bottom-center {
    bottom: 0
}

.uk-position-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.uk-position-small {
    margin: 15px;
    --uk-position-margin-offset: 15px
}

.uk-position-large,
.uk-position-medium {
    margin: 30px;
    --uk-position-margin-offset: 30px
}

@media (min-width:1025px) {
    .uk-position-large {
        margin: 50px;
        --uk-position-margin-offset: 50px
    }
}

.uk-position-relative {
    position: relative !important
}

.uk-position-absolute {
    position: absolute !important
}

.uk-position-fixed {
    position: fixed !important
}

.uk-position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.uk-position-z-index {
    z-index: 1
}

.uk-position-z-index-zero {
    z-index: 0
}

.uk-position-z-index-negative {
    z-index: -1
}

.uk-position-z-index-high {
    z-index: 990
}

:where(.uk-transition-fade),
:where([class*=uk-transition-scale]),
:where([class*=uk-transition-slide]) {
    --uk-position-translate-x: 0;
    --uk-position-translate-y: 0
}

.uk-transition-fade,
[class*=uk-transition-scale],
[class*=uk-transition-slide] {
    --uk-translate-x: 0;
    --uk-translate-y: 0;
    --uk-scale-x: 1;
    --uk-scale-y: 1;
    transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
    transition: .3s ease-out;
    transition-property: opacity, transform, -webkit-filter;
    transition-property: opacity, transform, filter;
    transition-property: opacity, transform, filter, -webkit-filter;
    opacity: 0
}

.uk-transition-active.uk-active .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle:hover .uk-transition-fade {
    opacity: 1
}

[class*=uk-transition-scale] {
    -webkit-backface-visibility: hidden
}

.uk-transition-scale-up {
    --uk-scale-x: 1;
    --uk-scale-y: 1
}

.uk-transition-scale-down {
    --uk-scale-x: 1.03;
    --uk-scale-y: 1.03
}

.uk-transition-active.uk-active .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle:hover .uk-transition-scale-up {
    --uk-scale-x: 1.03;
    --uk-scale-y: 1.03;
    opacity: 1
}

.uk-transition-active.uk-active .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle:hover .uk-transition-scale-down {
    --uk-scale-x: 1;
    --uk-scale-y: 1;
    opacity: 1
}

.uk-transition-slide-top {
    --uk-translate-y: -100%
}

.uk-transition-slide-bottom {
    --uk-translate-y: 100%
}

.uk-transition-slide-left {
    --uk-translate-x: -100%
}

.uk-transition-slide-right {
    --uk-translate-x: 100%
}

.uk-transition-slide-top-small {
    --uk-translate-y: -10px
}

.uk-transition-slide-bottom-small {
    --uk-translate-y: 10px
}

.uk-transition-slide-left-small {
    --uk-translate-x: -10px
}

.uk-transition-slide-right-small {
    --uk-translate-x: 10px
}

.uk-transition-slide-top-medium {
    --uk-translate-y: -50px
}

.uk-transition-slide-bottom-medium {
    --uk-translate-y: 50px
}

.uk-transition-slide-left-medium {
    --uk-translate-x: -50px
}

.uk-transition-slide-right-medium {
    --uk-translate-x: 50px
}

.uk-transition-active.uk-active [class*=uk-transition-slide],
.uk-transition-toggle:focus [class*=uk-transition-slide],
.uk-transition-toggle:hover [class*=uk-transition-slide],
.uk-transition-toggle [class*=uk-transition-slide]:focus-within {
    --uk-translate-x: 0;
    --uk-translate-y: 0;
    opacity: 1
}

.uk-transition-opaque {
    opacity: 1
}

.uk-transition-slow {
    transition-duration: .7s
}

.uk-hidden,
[hidden] {
    display: none !important
}

@media (min-width:360px) {
    .uk-hidden\@s {
        display: none !important
    }
}

@media (min-width:768px) {
    .uk-hidden\@m {
        display: none !important
    }
}

@media (min-width:1025px) {
    .uk-hidden\@l {
        display: none !important
    }
}

@media (min-width:1280px) {
    .uk-hidden\@xl {
        display: none !important
    }
}

@media (max-width:359px) {
    .uk-visible\@s {
        display: none !important
    }
}

@media (max-width:767px) {
    .uk-visible\@m {
        display: none !important
    }
}

@media (max-width:1024px) {
    .uk-visible\@l {
        display: none !important
    }
}

@media (max-width:1279px) {
    .uk-visible\@xl {
        display: none !important
    }
}

.uk-invisible {
    visibility: hidden !important
}

.uk-hidden-visually:not(:focus):not(:active):not(:focus-within),
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important
}

.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
    opacity: 0 !important
}

@media (hover:none) {
    .uk-hidden-touch {
        display: none !important
    }
}

@media (hover) {
    .uk-hidden-notouch {
        display: none !important
    }
}

.uk-card-primary.uk-card-body,
.uk-card-primary.uk-card-body .uk-link,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-primary.uk-card-body .uk-link:hover,
.uk-card-primary.uk-card-body a,
.uk-card-primary.uk-card-body a:hover,
.uk-card-primary>:not([class*=uk-card-media]),
.uk-card-primary>:not([class*=uk-card-media]) .uk-link,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link:hover,
.uk-card-primary>:not([class*=uk-card-media]) a,
.uk-card-primary>:not([class*=uk-card-media]) a:hover,
.uk-card-secondary.uk-card-body,
.uk-card-secondary.uk-card-body .uk-link,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-secondary.uk-card-body .uk-link:hover,
.uk-card-secondary.uk-card-body a,
.uk-card-secondary.uk-card-body a:hover,
.uk-card-secondary>:not([class*=uk-card-media]),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link:hover,
.uk-card-secondary>:not([class*=uk-card-media]) a,
.uk-card-secondary>:not([class*=uk-card-media]) a:hover,
.uk-light,
.uk-light .uk-link,
.uk-light .uk-link-toggle:hover .uk-link,
.uk-light .uk-link:hover,
.uk-light a,
.uk-light a:hover,
.uk-offcanvas-bar,
.uk-offcanvas-bar .uk-link,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link,
.uk-offcanvas-bar .uk-link:hover,
.uk-offcanvas-bar a,
.uk-offcanvas-bar a:hover,
.uk-overlay-primary,
.uk-overlay-primary .uk-link,
.uk-overlay-primary .uk-link-toggle:hover .uk-link,
.uk-overlay-primary .uk-link:hover,
.uk-overlay-primary a,
.uk-overlay-primary a:hover,
.uk-section-primary:not(.uk-preserve-color),
.uk-section-primary:not(.uk-preserve-color) .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-section-primary:not(.uk-preserve-color) a,
.uk-section-primary:not(.uk-preserve-color) a:hover,
.uk-section-secondary:not(.uk-preserve-color),
.uk-section-secondary:not(.uk-preserve-color) .uk-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-section-secondary:not(.uk-preserve-color) a,
.uk-section-secondary:not(.uk-preserve-color) a:hover,
.uk-tile-primary:not(.uk-preserve-color),
.uk-tile-primary:not(.uk-preserve-color) .uk-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-primary:not(.uk-preserve-color) a,
.uk-tile-primary:not(.uk-preserve-color) a:hover,
.uk-tile-secondary:not(.uk-preserve-color),
.uk-tile-secondary:not(.uk-preserve-color) .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) a,
.uk-tile-secondary:not(.uk-preserve-color) a:hover {
    color: #fff
}

.uk-card-primary.uk-card-body :not(pre)>code,
.uk-card-primary.uk-card-body :not(pre)>kbd,
.uk-card-primary.uk-card-body :not(pre)>samp,
.uk-card-primary>:not([class*=uk-card-media]) :not(pre)>code,
.uk-card-primary>:not([class*=uk-card-media]) :not(pre)>kbd,
.uk-card-primary>:not([class*=uk-card-media]) :not(pre)>samp,
.uk-card-secondary.uk-card-body :not(pre)>code,
.uk-card-secondary.uk-card-body :not(pre)>kbd,
.uk-card-secondary.uk-card-body :not(pre)>samp,
.uk-card-secondary>:not([class*=uk-card-media]) :not(pre)>code,
.uk-card-secondary>:not([class*=uk-card-media]) :not(pre)>kbd,
.uk-card-secondary>:not([class*=uk-card-media]) :not(pre)>samp,
.uk-light :not(pre)>code,
.uk-light :not(pre)>kbd,
.uk-light :not(pre)>samp,
.uk-offcanvas-bar :not(pre)>code,
.uk-offcanvas-bar :not(pre)>kbd,
.uk-offcanvas-bar :not(pre)>samp,
.uk-overlay-primary :not(pre)>code,
.uk-overlay-primary :not(pre)>kbd,
.uk-overlay-primary :not(pre)>samp,
.uk-section-primary:not(.uk-preserve-color) :not(pre)>code,
.uk-section-primary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-section-primary:not(.uk-preserve-color) :not(pre)>samp,
.uk-section-secondary:not(.uk-preserve-color) :not(pre)>code,
.uk-section-secondary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-section-secondary:not(.uk-preserve-color) :not(pre)>samp,
.uk-tile-primary:not(.uk-preserve-color) :not(pre)>code,
.uk-tile-primary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-tile-primary:not(.uk-preserve-color) :not(pre)>samp,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre)>code,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre)>samp {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .1)
}

.uk-card-primary.uk-card-body .uk-h1,
.uk-card-primary.uk-card-body .uk-h2,
.uk-card-primary.uk-card-body .uk-h3,
.uk-card-primary.uk-card-body .uk-h4,
.uk-card-primary.uk-card-body .uk-h5,
.uk-card-primary.uk-card-body .uk-h6,
.uk-card-primary.uk-card-body .uk-heading-2xlarge,
.uk-card-primary.uk-card-body .uk-heading-3xlarge,
.uk-card-primary.uk-card-body .uk-heading-large,
.uk-card-primary.uk-card-body .uk-heading-medium,
.uk-card-primary.uk-card-body .uk-heading-small,
.uk-card-primary.uk-card-body .uk-heading-xlarge,
.uk-card-primary.uk-card-body blockquote,
.uk-card-primary.uk-card-body blockquote footer,
.uk-card-primary.uk-card-body em,
.uk-card-primary.uk-card-body h1,
.uk-card-primary.uk-card-body h2,
.uk-card-primary.uk-card-body h3,
.uk-card-primary.uk-card-body h4,
.uk-card-primary.uk-card-body h5,
.uk-card-primary.uk-card-body h6,
.uk-card-primary>:not([class*=uk-card-media]) .uk-h1,
.uk-card-primary>:not([class*=uk-card-media]) .uk-h2,
.uk-card-primary>:not([class*=uk-card-media]) .uk-h3,
.uk-card-primary>:not([class*=uk-card-media]) .uk-h4,
.uk-card-primary>:not([class*=uk-card-media]) .uk-h5,
.uk-card-primary>:not([class*=uk-card-media]) .uk-h6,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-3xlarge,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-large,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-medium,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-small,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-card-primary>:not([class*=uk-card-media]) blockquote,
.uk-card-primary>:not([class*=uk-card-media]) blockquote footer,
.uk-card-primary>:not([class*=uk-card-media]) em,
.uk-card-primary>:not([class*=uk-card-media]) h1,
.uk-card-primary>:not([class*=uk-card-media]) h2,
.uk-card-primary>:not([class*=uk-card-media]) h3,
.uk-card-primary>:not([class*=uk-card-media]) h4,
.uk-card-primary>:not([class*=uk-card-media]) h5,
.uk-card-primary>:not([class*=uk-card-media]) h6,
.uk-card-secondary.uk-card-body .uk-h1,
.uk-card-secondary.uk-card-body .uk-h2,
.uk-card-secondary.uk-card-body .uk-h3,
.uk-card-secondary.uk-card-body .uk-h4,
.uk-card-secondary.uk-card-body .uk-h5,
.uk-card-secondary.uk-card-body .uk-h6,
.uk-card-secondary.uk-card-body .uk-heading-2xlarge,
.uk-card-secondary.uk-card-body .uk-heading-3xlarge,
.uk-card-secondary.uk-card-body .uk-heading-large,
.uk-card-secondary.uk-card-body .uk-heading-medium,
.uk-card-secondary.uk-card-body .uk-heading-small,
.uk-card-secondary.uk-card-body .uk-heading-xlarge,
.uk-card-secondary.uk-card-body blockquote,
.uk-card-secondary.uk-card-body blockquote footer,
.uk-card-secondary.uk-card-body em,
.uk-card-secondary.uk-card-body h1,
.uk-card-secondary.uk-card-body h2,
.uk-card-secondary.uk-card-body h3,
.uk-card-secondary.uk-card-body h4,
.uk-card-secondary.uk-card-body h5,
.uk-card-secondary.uk-card-body h6,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-h1,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-h2,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-h3,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-h4,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-h5,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-h6,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-3xlarge,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-large,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-medium,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-small,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-card-secondary>:not([class*=uk-card-media]) blockquote,
.uk-card-secondary>:not([class*=uk-card-media]) blockquote footer,
.uk-card-secondary>:not([class*=uk-card-media]) em,
.uk-card-secondary>:not([class*=uk-card-media]) h1,
.uk-card-secondary>:not([class*=uk-card-media]) h2,
.uk-card-secondary>:not([class*=uk-card-media]) h3,
.uk-card-secondary>:not([class*=uk-card-media]) h4,
.uk-card-secondary>:not([class*=uk-card-media]) h5,
.uk-card-secondary>:not([class*=uk-card-media]) h6,
.uk-light .uk-h1,
.uk-light .uk-h2,
.uk-light .uk-h3,
.uk-light .uk-h4,
.uk-light .uk-h5,
.uk-light .uk-h6,
.uk-light .uk-heading-2xlarge,
.uk-light .uk-heading-3xlarge,
.uk-light .uk-heading-large,
.uk-light .uk-heading-medium,
.uk-light .uk-heading-small,
.uk-light .uk-heading-xlarge,
.uk-light blockquote,
.uk-light blockquote footer,
.uk-light em,
.uk-light h1,
.uk-light h2,
.uk-light h3,
.uk-light h4,
.uk-light h5,
.uk-light h6,
.uk-offcanvas-bar .uk-h1,
.uk-offcanvas-bar .uk-h2,
.uk-offcanvas-bar .uk-h3,
.uk-offcanvas-bar .uk-h4,
.uk-offcanvas-bar .uk-h5,
.uk-offcanvas-bar .uk-h6,
.uk-offcanvas-bar .uk-heading-2xlarge,
.uk-offcanvas-bar .uk-heading-3xlarge,
.uk-offcanvas-bar .uk-heading-large,
.uk-offcanvas-bar .uk-heading-medium,
.uk-offcanvas-bar .uk-heading-small,
.uk-offcanvas-bar .uk-heading-xlarge,
.uk-offcanvas-bar blockquote,
.uk-offcanvas-bar blockquote footer,
.uk-offcanvas-bar em,
.uk-offcanvas-bar h1,
.uk-offcanvas-bar h2,
.uk-offcanvas-bar h3,
.uk-offcanvas-bar h4,
.uk-offcanvas-bar h5,
.uk-offcanvas-bar h6,
.uk-overlay-primary .uk-h1,
.uk-overlay-primary .uk-h2,
.uk-overlay-primary .uk-h3,
.uk-overlay-primary .uk-h4,
.uk-overlay-primary .uk-h5,
.uk-overlay-primary .uk-h6,
.uk-overlay-primary .uk-heading-2xlarge,
.uk-overlay-primary .uk-heading-3xlarge,
.uk-overlay-primary .uk-heading-large,
.uk-overlay-primary .uk-heading-medium,
.uk-overlay-primary .uk-heading-small,
.uk-overlay-primary .uk-heading-xlarge,
.uk-overlay-primary blockquote,
.uk-overlay-primary blockquote footer,
.uk-overlay-primary em,
.uk-overlay-primary h1,
.uk-overlay-primary h2,
.uk-overlay-primary h3,
.uk-overlay-primary h4,
.uk-overlay-primary h5,
.uk-overlay-primary h6,
.uk-section-primary:not(.uk-preserve-color) .uk-h1,
.uk-section-primary:not(.uk-preserve-color) .uk-h2,
.uk-section-primary:not(.uk-preserve-color) .uk-h3,
.uk-section-primary:not(.uk-preserve-color) .uk-h4,
.uk-section-primary:not(.uk-preserve-color) .uk-h5,
.uk-section-primary:not(.uk-preserve-color) .uk-h6,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-3xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-section-primary:not(.uk-preserve-color) blockquote,
.uk-section-primary:not(.uk-preserve-color) blockquote footer,
.uk-section-primary:not(.uk-preserve-color) em,
.uk-section-primary:not(.uk-preserve-color) h1,
.uk-section-primary:not(.uk-preserve-color) h2,
.uk-section-primary:not(.uk-preserve-color) h3,
.uk-section-primary:not(.uk-preserve-color) h4,
.uk-section-primary:not(.uk-preserve-color) h5,
.uk-section-primary:not(.uk-preserve-color) h6,
.uk-section-secondary:not(.uk-preserve-color) .uk-h1,
.uk-section-secondary:not(.uk-preserve-color) .uk-h2,
.uk-section-secondary:not(.uk-preserve-color) .uk-h3,
.uk-section-secondary:not(.uk-preserve-color) .uk-h4,
.uk-section-secondary:not(.uk-preserve-color) .uk-h5,
.uk-section-secondary:not(.uk-preserve-color) .uk-h6,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-3xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-section-secondary:not(.uk-preserve-color) blockquote,
.uk-section-secondary:not(.uk-preserve-color) blockquote footer,
.uk-section-secondary:not(.uk-preserve-color) em,
.uk-section-secondary:not(.uk-preserve-color) h1,
.uk-section-secondary:not(.uk-preserve-color) h2,
.uk-section-secondary:not(.uk-preserve-color) h3,
.uk-section-secondary:not(.uk-preserve-color) h4,
.uk-section-secondary:not(.uk-preserve-color) h5,
.uk-section-secondary:not(.uk-preserve-color) h6,
.uk-tile-primary:not(.uk-preserve-color) .uk-h1,
.uk-tile-primary:not(.uk-preserve-color) .uk-h2,
.uk-tile-primary:not(.uk-preserve-color) .uk-h3,
.uk-tile-primary:not(.uk-preserve-color) .uk-h4,
.uk-tile-primary:not(.uk-preserve-color) .uk-h5,
.uk-tile-primary:not(.uk-preserve-color) .uk-h6,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-3xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-primary:not(.uk-preserve-color) blockquote,
.uk-tile-primary:not(.uk-preserve-color) blockquote footer,
.uk-tile-primary:not(.uk-preserve-color) em,
.uk-tile-primary:not(.uk-preserve-color) h1,
.uk-tile-primary:not(.uk-preserve-color) h2,
.uk-tile-primary:not(.uk-preserve-color) h3,
.uk-tile-primary:not(.uk-preserve-color) h4,
.uk-tile-primary:not(.uk-preserve-color) h5,
.uk-tile-primary:not(.uk-preserve-color) h6,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h1,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h2,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h3,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h4,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h5,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h6,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-3xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-secondary:not(.uk-preserve-color) blockquote,
.uk-tile-secondary:not(.uk-preserve-color) blockquote footer,
.uk-tile-secondary:not(.uk-preserve-color) em,
.uk-tile-secondary:not(.uk-preserve-color) h1,
.uk-tile-secondary:not(.uk-preserve-color) h2,
.uk-tile-secondary:not(.uk-preserve-color) h3,
.uk-tile-secondary:not(.uk-preserve-color) h4,
.uk-tile-secondary:not(.uk-preserve-color) h5,
.uk-tile-secondary:not(.uk-preserve-color) h6 {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-hr,
.uk-card-primary.uk-card-body hr,
.uk-card-primary>:not([class*=uk-card-media]) .uk-hr,
.uk-card-primary>:not([class*=uk-card-media]) hr,
.uk-card-secondary.uk-card-body .uk-hr,
.uk-card-secondary.uk-card-body hr,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-hr,
.uk-card-secondary>:not([class*=uk-card-media]) hr,
.uk-light .uk-hr,
.uk-light hr,
.uk-offcanvas-bar .uk-hr,
.uk-offcanvas-bar hr,
.uk-overlay-primary .uk-hr,
.uk-overlay-primary hr,
.uk-section-primary:not(.uk-preserve-color) .uk-hr,
.uk-section-primary:not(.uk-preserve-color) hr,
.uk-section-secondary:not(.uk-preserve-color) .uk-hr,
.uk-section-secondary:not(.uk-preserve-color) hr,
.uk-tile-primary:not(.uk-preserve-color) .uk-hr,
.uk-tile-primary:not(.uk-preserve-color) hr,
.uk-tile-secondary:not(.uk-preserve-color) .uk-hr,
.uk-tile-secondary:not(.uk-preserve-color) hr {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body :focus-visible,
.uk-card-primary>:not([class*=uk-card-media]) :focus-visible,
.uk-card-secondary.uk-card-body :focus-visible,
.uk-card-secondary>:not([class*=uk-card-media]) :focus-visible,
.uk-light :focus-visible,
.uk-offcanvas-bar :focus-visible,
.uk-overlay-primary :focus-visible,
.uk-section-primary:not(.uk-preserve-color) :focus-visible,
.uk-section-secondary:not(.uk-preserve-color) :focus-visible,
.uk-tile-primary:not(.uk-preserve-color) :focus-visible,
.uk-tile-secondary:not(.uk-preserve-color) :focus-visible {
    outline-color: #fff
}

.uk-card-primary.uk-card-body .uk-link-muted a,
.uk-card-primary.uk-card-body a.uk-link-muted,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-muted a,
.uk-card-primary>:not([class*=uk-card-media]) a.uk-link-muted,
.uk-card-secondary.uk-card-body .uk-link-muted a,
.uk-card-secondary.uk-card-body a.uk-link-muted,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-muted a,
.uk-card-secondary>:not([class*=uk-card-media]) a.uk-link-muted,
.uk-light .uk-link-muted a,
.uk-light a.uk-link-muted,
.uk-offcanvas-bar .uk-link-muted a,
.uk-offcanvas-bar a.uk-link-muted,
.uk-overlay-primary .uk-link-muted a,
.uk-overlay-primary a.uk-link-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-section-primary:not(.uk-preserve-color) a.uk-link-muted,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-link-muted a:hover,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary.uk-card-body a.uk-link-muted:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary>:not([class*=uk-card-media]) a.uk-link-muted:hover,
.uk-card-secondary.uk-card-body .uk-link-muted a:hover,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-secondary.uk-card-body a.uk-link-muted:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-card-secondary>:not([class*=uk-card-media]) a.uk-link-muted:hover,
.uk-light .uk-link-muted a:hover,
.uk-light .uk-link-toggle:hover .uk-link-muted,
.uk-light a.uk-link-muted:hover,
.uk-offcanvas-bar .uk-link-muted a:hover,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link-muted,
.uk-offcanvas-bar a.uk-link-muted:hover,
.uk-overlay-primary .uk-link-muted a:hover,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-muted,
.uk-overlay-primary a.uk-link-muted:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-section-primary:not(.uk-preserve-color) a.uk-link-muted:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-link-text a:hover,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-primary.uk-card-body a.uk-link-text:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-card-primary>:not([class*=uk-card-media]) a.uk-link-text:hover,
.uk-card-secondary.uk-card-body .uk-link-text a:hover,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-secondary.uk-card-body a.uk-link-text:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-card-secondary>:not([class*=uk-card-media]) a.uk-link-text:hover,
.uk-light .uk-link-text a:hover,
.uk-light .uk-link-toggle:hover .uk-link-text,
.uk-light a.uk-link-text:hover,
.uk-offcanvas-bar .uk-link-text a:hover,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link-text,
.uk-offcanvas-bar a.uk-link-text:hover,
.uk-overlay-primary .uk-link-text a:hover,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-text,
.uk-overlay-primary a.uk-link-text:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-section-primary:not(.uk-preserve-color) a.uk-link-text:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-section-secondary:not(.uk-preserve-color) a.uk-link-text:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-tile-primary:not(.uk-preserve-color) a.uk-link-text:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-tile-secondary:not(.uk-preserve-color) a.uk-link-text:hover {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-link-heading a:hover,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary.uk-card-body a.uk-link-heading:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary>:not([class*=uk-card-media]) a.uk-link-heading:hover,
.uk-card-secondary.uk-card-body .uk-link-heading a:hover,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-secondary.uk-card-body a.uk-link-heading:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-card-secondary>:not([class*=uk-card-media]) a.uk-link-heading:hover,
.uk-light .uk-link-heading a:hover,
.uk-light .uk-link-toggle:hover .uk-link-heading,
.uk-light a.uk-link-heading:hover,
.uk-offcanvas-bar .uk-link-heading a:hover,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link-heading,
.uk-offcanvas-bar a.uk-link-heading:hover,
.uk-overlay-primary .uk-link-heading a:hover,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-heading,
.uk-overlay-primary a.uk-link-heading:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-section-primary:not(.uk-preserve-color) a.uk-link-heading:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-section-secondary:not(.uk-preserve-color) a.uk-link-heading:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-tile-primary:not(.uk-preserve-color) a.uk-link-heading:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-tile-secondary:not(.uk-preserve-color) a.uk-link-heading:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-heading-divider,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-divider,
.uk-card-secondary.uk-card-body .uk-heading-divider,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-divider,
.uk-light .uk-heading-divider,
.uk-offcanvas-bar .uk-heading-divider,
.uk-overlay-primary .uk-heading-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-divider {
    border-bottom-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-heading-bullet:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-bullet:before,
.uk-card-secondary.uk-card-body .uk-heading-bullet:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-bullet:before,
.uk-light .uk-heading-bullet:before,
.uk-offcanvas-bar .uk-heading-bullet:before,
.uk-overlay-primary .uk-heading-bullet:before,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-bullet:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-bullet:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-bullet:before {
    border-left-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-heading-line>:after,
.uk-card-primary.uk-card-body .uk-heading-line>:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-line>:after,
.uk-card-primary>:not([class*=uk-card-media]) .uk-heading-line>:before,
.uk-card-secondary.uk-card-body .uk-heading-line>:after,
.uk-card-secondary.uk-card-body .uk-heading-line>:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-line>:after,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-heading-line>:before,
.uk-light .uk-heading-line>:after,
.uk-light .uk-heading-line>:before,
.uk-offcanvas-bar .uk-heading-line>:after,
.uk-offcanvas-bar .uk-heading-line>:before,
.uk-overlay-primary .uk-heading-line>:after,
.uk-overlay-primary .uk-heading-line>:before,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line>:after,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line>:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line>:after,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line>:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-line>:after,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-line>:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line>:after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line>:before {
    border-bottom-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-divider-icon,
.uk-card-primary>:not([class*=uk-card-media]) .uk-divider-icon,
.uk-card-secondary.uk-card-body .uk-divider-icon,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-divider-icon,
.uk-light .uk-divider-icon,
.uk-offcanvas-bar .uk-divider-icon,
.uk-overlay-primary .uk-divider-icon,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='none' stroke='rgba(255, 255, 255, 0.2)' stroke-width='2' cx='10' cy='10' r='7'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-divider-icon:after,
.uk-card-primary.uk-card-body .uk-divider-icon:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-divider-icon:after,
.uk-card-primary>:not([class*=uk-card-media]) .uk-divider-icon:before,
.uk-card-secondary.uk-card-body .uk-divider-icon:after,
.uk-card-secondary.uk-card-body .uk-divider-icon:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-divider-icon:after,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-divider-icon:before,
.uk-light .uk-divider-icon:after,
.uk-light .uk-divider-icon:before,
.uk-offcanvas-bar .uk-divider-icon:after,
.uk-offcanvas-bar .uk-divider-icon:before,
.uk-overlay-primary .uk-divider-icon:after,
.uk-overlay-primary .uk-divider-icon:before,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon:after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon:after,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon:after,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon:after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon:before {
    border-bottom-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-divider-small:after,
.uk-card-primary>:not([class*=uk-card-media]) .uk-divider-small:after,
.uk-card-secondary.uk-card-body .uk-divider-small:after,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-divider-small:after,
.uk-light .uk-divider-small:after,
.uk-offcanvas-bar .uk-divider-small:after,
.uk-overlay-primary .uk-divider-small:after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-small:after,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-small:after,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-small:after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-small:after {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-divider-vertical,
.uk-card-primary>:not([class*=uk-card-media]) .uk-divider-vertical,
.uk-card-secondary.uk-card-body .uk-divider-vertical,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-divider-vertical,
.uk-light .uk-divider-vertical,
.uk-offcanvas-bar .uk-divider-vertical,
.uk-overlay-primary .uk-divider-vertical,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-vertical {
    border-left-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-list-muted>:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-muted>:before,
.uk-card-secondary.uk-card-body .uk-list-muted>:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-muted>:before,
.uk-light .uk-list-muted>:before,
.uk-offcanvas-bar .uk-list-muted>:before,
.uk-overlay-primary .uk-list-muted>:before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-muted>:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-muted>:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-muted>:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-muted>:before {
    color: hsla(0, 0%, 100%, .5) !important
}

.uk-card-primary.uk-card-body .uk-list-emphasis>:before,
.uk-card-primary.uk-card-body .uk-list-primary>:before,
.uk-card-primary.uk-card-body .uk-list-secondary>:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-emphasis>:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-primary>:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-secondary>:before,
.uk-card-secondary.uk-card-body .uk-list-emphasis>:before,
.uk-card-secondary.uk-card-body .uk-list-primary>:before,
.uk-card-secondary.uk-card-body .uk-list-secondary>:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-emphasis>:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-primary>:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-secondary>:before,
.uk-light .uk-list-emphasis>:before,
.uk-light .uk-list-primary>:before,
.uk-light .uk-list-secondary>:before,
.uk-offcanvas-bar .uk-list-emphasis>:before,
.uk-offcanvas-bar .uk-list-primary>:before,
.uk-offcanvas-bar .uk-list-secondary>:before,
.uk-overlay-primary .uk-list-emphasis>:before,
.uk-overlay-primary .uk-list-primary>:before,
.uk-overlay-primary .uk-list-secondary>:before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-emphasis>:before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-primary>:before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-secondary>:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-emphasis>:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-primary>:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-secondary>:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-emphasis>:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-primary>:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-secondary>:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-emphasis>:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-primary>:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-secondary>:before {
    color: #fff !important
}

.uk-card-primary.uk-card-body .uk-list-bullet>:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-bullet>:before,
.uk-card-secondary.uk-card-body .uk-list-bullet>:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-bullet>:before,
.uk-light .uk-list-bullet>:before,
.uk-offcanvas-bar .uk-list-bullet>:before,
.uk-overlay-primary .uk-list-bullet>:before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-bullet>:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-bullet>:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-bullet>:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-bullet>:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='6' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23fff' cx='3' cy='3' r='3'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-list-divider>:nth-child(n+2),
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-divider>:nth-child(n+2),
.uk-card-secondary.uk-card-body .uk-list-divider>:nth-child(n+2),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-divider>:nth-child(n+2),
.uk-light .uk-list-divider>:nth-child(n+2),
.uk-offcanvas-bar .uk-list-divider>:nth-child(n+2),
.uk-overlay-primary .uk-list-divider>:nth-child(n+2),
.uk-section-primary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-section-secondary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-tile-primary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2) {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-list-striped>:nth-of-type(odd),
.uk-card-primary>:not([class*=uk-card-media]) .uk-list-striped>:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-list-striped>:nth-of-type(odd),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-list-striped>:nth-of-type(odd),
.uk-light .uk-list-striped>:nth-of-type(odd),
.uk-offcanvas-bar .uk-list-striped>:nth-of-type(odd),
.uk-overlay-primary .uk-list-striped>:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd) {
    border-top-color: hsla(0, 0%, 100%, .2);
    border-bottom-color: hsla(0, 0%, 100%, .2);
    background-color: hsla(0, 0%, 100%, .1)
}

.uk-card-primary.uk-card-body .uk-table th,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table th,
.uk-card-secondary.uk-card-body .uk-table th,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table th,
.uk-light .uk-table th,
.uk-offcanvas-bar .uk-table th,
.uk-overlay-primary .uk-table th,
.uk-section-primary:not(.uk-preserve-color) .uk-table th,
.uk-section-secondary:not(.uk-preserve-color) .uk-table th,
.uk-tile-primary:not(.uk-preserve-color) .uk-table th,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table th {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-table caption,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table caption,
.uk-card-secondary.uk-card-body .uk-table caption,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table caption,
.uk-light .uk-table caption,
.uk-offcanvas-bar .uk-table caption,
.uk-overlay-primary .uk-table caption,
.uk-section-primary:not(.uk-preserve-color) .uk-table caption,
.uk-section-secondary:not(.uk-preserve-color) .uk-table caption,
.uk-tile-primary:not(.uk-preserve-color) .uk-table caption,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table caption {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-table>tr.uk-active,
.uk-card-primary.uk-card-body .uk-table tbody tr.uk-active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table>tr.uk-active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table tbody tr.uk-active,
.uk-card-secondary.uk-card-body .uk-table>tr.uk-active,
.uk-card-secondary.uk-card-body .uk-table tbody tr.uk-active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table>tr.uk-active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table tbody tr.uk-active,
.uk-light .uk-table>tr.uk-active,
.uk-light .uk-table tbody tr.uk-active,
.uk-offcanvas-bar .uk-table>tr.uk-active,
.uk-offcanvas-bar .uk-table tbody tr.uk-active,
.uk-overlay-primary .uk-table>tr.uk-active,
.uk-overlay-primary .uk-table tbody tr.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table tbody tr.uk-active {
    background: hsla(0, 0%, 100%, .08)
}

.uk-card-primary.uk-card-body .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-primary.uk-card-body .uk-table-divider>:not(:first-child)>tr,
.uk-card-primary.uk-card-body .uk-table-divider>tr:not(:first-child),
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-divider>:not(:first-child)>tr,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-divider>tr:not(:first-child),
.uk-card-secondary.uk-card-body .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-secondary.uk-card-body .uk-table-divider>:not(:first-child)>tr,
.uk-card-secondary.uk-card-body .uk-table-divider>tr:not(:first-child),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-divider>:not(:first-child)>tr,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-divider>tr:not(:first-child),
.uk-light .uk-table-divider>:first-child>tr:not(:first-child),
.uk-light .uk-table-divider>:not(:first-child)>tr,
.uk-light .uk-table-divider>tr:not(:first-child),
.uk-offcanvas-bar .uk-table-divider>:first-child>tr:not(:first-child),
.uk-offcanvas-bar .uk-table-divider>:not(:first-child)>tr,
.uk-offcanvas-bar .uk-table-divider>tr:not(:first-child),
.uk-overlay-primary .uk-table-divider>:first-child>tr:not(:first-child),
.uk-overlay-primary .uk-table-divider>:not(:first-child)>tr,
.uk-overlay-primary .uk-table-divider>tr:not(:first-child),
.uk-section-primary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-section-primary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-section-primary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child) {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-table-striped>tr:nth-of-type(odd),
.uk-card-primary.uk-card-body .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-striped>tr:nth-of-type(odd),
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-table-striped>tr:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-striped>tr:nth-of-type(odd),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-light .uk-table-striped>tr:nth-of-type(odd),
.uk-light .uk-table-striped tbody tr:nth-of-type(odd),
.uk-offcanvas-bar .uk-table-striped>tr:nth-of-type(odd),
.uk-offcanvas-bar .uk-table-striped tbody tr:nth-of-type(odd),
.uk-overlay-primary .uk-table-striped>tr:nth-of-type(odd),
.uk-overlay-primary .uk-table-striped tbody tr:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd) {
    background: hsla(0, 0%, 100%, .1);
    border-top-color: hsla(0, 0%, 100%, .2);
    border-bottom-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-table-hover>tr:hover,
.uk-card-primary.uk-card-body .uk-table-hover tbody tr:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-hover>tr:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-hover tbody tr:hover,
.uk-card-secondary.uk-card-body .uk-table-hover>tr:hover,
.uk-card-secondary.uk-card-body .uk-table-hover tbody tr:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-hover>tr:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-hover tbody tr:hover,
.uk-light .uk-table-hover>tr:hover,
.uk-light .uk-table-hover tbody tr:hover,
.uk-offcanvas-bar .uk-table-hover>tr:hover,
.uk-offcanvas-bar .uk-table-hover tbody tr:hover,
.uk-overlay-primary .uk-table-hover>tr:hover,
.uk-overlay-primary .uk-table-hover tbody tr:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover {
    background: hsla(0, 0%, 100%, .08)
}

.uk-card-primary.uk-card-body .uk-icon-link,
.uk-card-primary>:not([class*=uk-card-media]) .uk-icon-link,
.uk-card-secondary.uk-card-body .uk-icon-link,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-icon-link,
.uk-light .uk-icon-link,
.uk-offcanvas-bar .uk-icon-link,
.uk-overlay-primary .uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-active>.uk-icon-link,
.uk-card-primary.uk-card-body .uk-icon-link:active,
.uk-card-primary.uk-card-body .uk-icon-link:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-active>.uk-icon-link,
.uk-card-primary>:not([class*=uk-card-media]) .uk-icon-link:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-icon-link:hover,
.uk-card-secondary.uk-card-body .uk-active>.uk-icon-link,
.uk-card-secondary.uk-card-body .uk-icon-link:active,
.uk-card-secondary.uk-card-body .uk-icon-link:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-active>.uk-icon-link,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-icon-link:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-icon-link:hover,
.uk-light .uk-active>.uk-icon-link,
.uk-light .uk-icon-link:active,
.uk-light .uk-icon-link:hover,
.uk-offcanvas-bar .uk-active>.uk-icon-link,
.uk-offcanvas-bar .uk-icon-link:active,
.uk-offcanvas-bar .uk-icon-link:hover,
.uk-overlay-primary .uk-active>.uk-icon-link,
.uk-overlay-primary .uk-icon-link:active,
.uk-overlay-primary .uk-icon-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-icon-button,
.uk-card-primary>:not([class*=uk-card-media]) .uk-icon-button,
.uk-card-secondary.uk-card-body .uk-icon-button,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-icon-button,
.uk-light .uk-icon-button,
.uk-offcanvas-bar .uk-icon-button,
.uk-overlay-primary .uk-icon-button,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button {
    background-color: hsla(0, 0%, 100%, .1);
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-icon-button:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-icon-button:hover,
.uk-card-secondary.uk-card-body .uk-icon-button:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-icon-button:hover,
.uk-light .uk-icon-button:hover,
.uk-offcanvas-bar .uk-icon-button:hover,
.uk-overlay-primary .uk-icon-button:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:hover {
    background-color: hsla(0, 0%, 100%, .15);
    color: #fff
}

.uk-card-primary.uk-card-body .uk-icon-button:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-icon-button:active,
.uk-card-secondary.uk-card-body .uk-icon-button:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-icon-button:active,
.uk-light .uk-icon-button:active,
.uk-offcanvas-bar .uk-icon-button:active,
.uk-overlay-primary .uk-icon-button:active,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:active {
    background-color: hsla(0, 0%, 100%, .2);
    color: #fff
}

.uk-card-primary.uk-card-body .uk-input,
.uk-card-primary.uk-card-body .uk-select,
.uk-card-primary.uk-card-body .uk-textarea,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input,
.uk-card-primary>:not([class*=uk-card-media]) .uk-select,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea,
.uk-card-secondary.uk-card-body .uk-input,
.uk-card-secondary.uk-card-body .uk-select,
.uk-card-secondary.uk-card-body .uk-textarea,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-select,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea,
.uk-light .uk-input,
.uk-light .uk-select,
.uk-light .uk-textarea,
.uk-offcanvas-bar .uk-input,
.uk-offcanvas-bar .uk-select,
.uk-offcanvas-bar .uk-textarea,
.uk-overlay-primary .uk-input,
.uk-overlay-primary .uk-select,
.uk-overlay-primary .uk-textarea,
.uk-section-primary:not(.uk-preserve-color) .uk-input,
.uk-section-primary:not(.uk-preserve-color) .uk-select,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea,
.uk-section-secondary:not(.uk-preserve-color) .uk-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-select,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-primary:not(.uk-preserve-color) .uk-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-select,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea {
    background-color: hsla(0, 0%, 100%, .1);
    color: #fff;
    background-clip: padding-box;
    border-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-input:focus,
.uk-card-primary.uk-card-body .uk-select:focus,
.uk-card-primary.uk-card-body .uk-textarea:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-select:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea:focus,
.uk-card-secondary.uk-card-body .uk-input:focus,
.uk-card-secondary.uk-card-body .uk-select:focus,
.uk-card-secondary.uk-card-body .uk-textarea:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-select:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea:focus,
.uk-light .uk-input:focus,
.uk-light .uk-select:focus,
.uk-light .uk-textarea:focus,
.uk-offcanvas-bar .uk-input:focus,
.uk-offcanvas-bar .uk-select:focus,
.uk-offcanvas-bar .uk-textarea:focus,
.uk-overlay-primary .uk-input:focus,
.uk-overlay-primary .uk-select:focus,
.uk-overlay-primary .uk-textarea:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-input:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-input:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:focus {
    background-color: hsla(0, 0%, 100%, .15);
    color: #fff;
    border-color: #fff
}

.uk-card-primary.uk-card-body .uk-input::-webkit-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input::-webkit-input-placeholder,
.uk-card-secondary.uk-card-body .uk-input::-webkit-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input::-webkit-input-placeholder,
.uk-light .uk-input::-webkit-input-placeholder,
.uk-offcanvas-bar .uk-input::-webkit-input-placeholder,
.uk-overlay-primary .uk-input::-webkit-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input::-webkit-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input::-webkit-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input::-webkit-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-input::-moz-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input::-moz-placeholder,
.uk-card-secondary.uk-card-body .uk-input::-moz-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input::-moz-placeholder,
.uk-light .uk-input::-moz-placeholder,
.uk-offcanvas-bar .uk-input::-moz-placeholder,
.uk-overlay-primary .uk-input::-moz-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input::-moz-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input::-moz-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input::-moz-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input::-moz-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-input:-ms-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input:-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-input:-ms-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input:-ms-input-placeholder,
.uk-light .uk-input:-ms-input-placeholder,
.uk-offcanvas-bar .uk-input:-ms-input-placeholder,
.uk-overlay-primary .uk-input:-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input:-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input:-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input:-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input:-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-input::-ms-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input::-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-input::-ms-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input::-ms-input-placeholder,
.uk-light .uk-input::-ms-input-placeholder,
.uk-offcanvas-bar .uk-input::-ms-input-placeholder,
.uk-overlay-primary .uk-input::-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-input::placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input::placeholder,
.uk-card-secondary.uk-card-body .uk-input::placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input::placeholder,
.uk-light .uk-input::placeholder,
.uk-offcanvas-bar .uk-input::placeholder,
.uk-overlay-primary .uk-input::placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input::placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-textarea::-webkit-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea::-webkit-input-placeholder,
.uk-card-secondary.uk-card-body .uk-textarea::-webkit-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea::-webkit-input-placeholder,
.uk-light .uk-textarea::-webkit-input-placeholder,
.uk-offcanvas-bar .uk-textarea::-webkit-input-placeholder,
.uk-overlay-primary .uk-textarea::-webkit-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea::-webkit-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea::-webkit-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea::-webkit-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-textarea::-moz-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea::-moz-placeholder,
.uk-card-secondary.uk-card-body .uk-textarea::-moz-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea::-moz-placeholder,
.uk-light .uk-textarea::-moz-placeholder,
.uk-offcanvas-bar .uk-textarea::-moz-placeholder,
.uk-overlay-primary .uk-textarea::-moz-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea::-moz-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea::-moz-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea::-moz-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::-moz-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-textarea:-ms-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea:-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-textarea:-ms-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea:-ms-input-placeholder,
.uk-light .uk-textarea:-ms-input-placeholder,
.uk-offcanvas-bar .uk-textarea:-ms-input-placeholder,
.uk-overlay-primary .uk-textarea:-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea:-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea:-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea:-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-textarea::-ms-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea::-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-textarea::-ms-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea::-ms-input-placeholder,
.uk-light .uk-textarea::-ms-input-placeholder,
.uk-offcanvas-bar .uk-textarea::-ms-input-placeholder,
.uk-overlay-primary .uk-textarea::-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-textarea::placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-textarea::placeholder,
.uk-card-secondary.uk-card-body .uk-textarea::placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-textarea::placeholder,
.uk-light .uk-textarea::placeholder,
.uk-offcanvas-bar .uk-textarea::placeholder,
.uk-overlay-primary .uk-textarea::placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]),
.uk-card-primary>:not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]),
.uk-card-secondary.uk-card-body .uk-select:not([multiple]):not([size]),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]),
.uk-light .uk-select:not([multiple]):not([size]),
.uk-offcanvas-bar .uk-select:not([multiple]):not([size]),
.uk-overlay-primary .uk-select:not([multiple]):not([size]),
.uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-section-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-tile-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-tile-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M12 1L9 6h6zM12 13L9 8h6z'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-input[list]:focus,
.uk-card-primary.uk-card-body .uk-input[list]:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-input[list]:hover,
.uk-card-secondary.uk-card-body .uk-input[list]:focus,
.uk-card-secondary.uk-card-body .uk-input[list]:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-input[list]:hover,
.uk-light .uk-input[list]:focus,
.uk-light .uk-input[list]:hover,
.uk-offcanvas-bar .uk-input[list]:focus,
.uk-offcanvas-bar .uk-input[list]:hover,
.uk-overlay-primary .uk-input[list]:focus,
.uk-overlay-primary .uk-input[list]:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:hover {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M12 12L8 6h8z'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-checkbox,
.uk-card-primary.uk-card-body .uk-radio,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox,
.uk-card-primary>:not([class*=uk-card-media]) .uk-radio,
.uk-card-secondary.uk-card-body .uk-checkbox,
.uk-card-secondary.uk-card-body .uk-radio,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-radio,
.uk-light .uk-checkbox,
.uk-light .uk-radio,
.uk-offcanvas-bar .uk-checkbox,
.uk-offcanvas-bar .uk-radio,
.uk-overlay-primary .uk-checkbox,
.uk-overlay-primary .uk-radio,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-section-primary:not(.uk-preserve-color) .uk-radio,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio {
    background-color: hsla(0, 0%, 100%, .1);
    border-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-checkbox:focus,
.uk-card-primary.uk-card-body .uk-radio:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-radio:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:focus,
.uk-card-secondary.uk-card-body .uk-radio:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-radio:focus,
.uk-light .uk-checkbox:focus,
.uk-light .uk-radio:focus,
.uk-offcanvas-bar .uk-checkbox:focus,
.uk-offcanvas-bar .uk-radio:focus,
.uk-overlay-primary .uk-checkbox:focus,
.uk-overlay-primary .uk-radio:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:focus {
    background-color: hsla(0, 0%, 100%, .15);
    border-color: #fff
}

.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary.uk-card-body .uk-radio:checked,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-card-primary>:not([class*=uk-card-media]) .uk-radio:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-secondary.uk-card-body .uk-radio:checked,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-radio:checked,
.uk-light .uk-checkbox:checked,
.uk-light .uk-checkbox:indeterminate,
.uk-light .uk-radio:checked,
.uk-offcanvas-bar .uk-checkbox:checked,
.uk-offcanvas-bar .uk-checkbox:indeterminate,
.uk-offcanvas-bar .uk-radio:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-radio:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked {
    background-color: #fff;
    border-color: #fff
}

.uk-card-primary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-primary.uk-card-body .uk-radio:checked:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-radio:checked:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-secondary.uk-card-body .uk-radio:checked:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-radio:checked:focus,
.uk-light .uk-checkbox:checked:focus,
.uk-light .uk-checkbox:indeterminate:focus,
.uk-light .uk-radio:checked:focus,
.uk-offcanvas-bar .uk-checkbox:checked:focus,
.uk-offcanvas-bar .uk-checkbox:indeterminate:focus,
.uk-offcanvas-bar .uk-radio:checked:focus,
.uk-overlay-primary .uk-checkbox:checked:focus,
.uk-overlay-primary .uk-checkbox:indeterminate:focus,
.uk-overlay-primary .uk-radio:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked:focus {
    background-color: #fff
}

.uk-card-primary.uk-card-body .uk-radio:checked,
.uk-card-primary>:not([class*=uk-card-media]) .uk-radio:checked,
.uk-card-secondary.uk-card-body .uk-radio:checked,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-radio:checked,
.uk-light .uk-radio:checked,
.uk-offcanvas-bar .uk-radio:checked,
.uk-overlay-primary .uk-radio:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23161a1d' cx='8' cy='8' r='2'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:checked,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-light .uk-checkbox:checked,
.uk-offcanvas-bar .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23161a1d' d='M12 1L5 7.5 2 5l-1 .5L5 10l8-8.5z'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary>:not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-light .uk-checkbox:indeterminate,
.uk-offcanvas-bar .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23161a1d' d='M3 8h10v1H3z'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-form-label,
.uk-card-primary>:not([class*=uk-card-media]) .uk-form-label,
.uk-card-secondary.uk-card-body .uk-form-label,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-form-label,
.uk-light .uk-form-label,
.uk-offcanvas-bar .uk-form-label,
.uk-overlay-primary .uk-form-label,
.uk-section-primary:not(.uk-preserve-color) .uk-form-label,
.uk-section-secondary:not(.uk-preserve-color) .uk-form-label,
.uk-tile-primary:not(.uk-preserve-color) .uk-form-label,
.uk-tile-secondary:not(.uk-preserve-color) .uk-form-label {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-form-icon,
.uk-card-primary>:not([class*=uk-card-media]) .uk-form-icon,
.uk-card-secondary.uk-card-body .uk-form-icon,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-form-icon,
.uk-light .uk-form-icon,
.uk-offcanvas-bar .uk-form-icon,
.uk-overlay-primary .uk-form-icon,
.uk-section-primary:not(.uk-preserve-color) .uk-form-icon,
.uk-section-secondary:not(.uk-preserve-color) .uk-form-icon,
.uk-tile-primary:not(.uk-preserve-color) .uk-form-icon,
.uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-form-icon:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-form-icon:hover,
.uk-card-secondary.uk-card-body .uk-form-icon:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-form-icon:hover,
.uk-light .uk-form-icon:hover,
.uk-offcanvas-bar .uk-form-icon:hover,
.uk-overlay-primary .uk-form-icon:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-button-default,
.uk-card-primary.uk-card-body .uk-button-default.uk-active,
.uk-card-primary.uk-card-body .uk-button-default:active,
.uk-card-primary.uk-card-body .uk-button-default:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-default,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-default:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-default:hover,
.uk-card-secondary.uk-card-body .uk-button-default,
.uk-card-secondary.uk-card-body .uk-button-default.uk-active,
.uk-card-secondary.uk-card-body .uk-button-default:active,
.uk-card-secondary.uk-card-body .uk-button-default:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-default,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-default:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-default:hover,
.uk-light .uk-button-default,
.uk-light .uk-button-default.uk-active,
.uk-light .uk-button-default:active,
.uk-light .uk-button-default:hover,
.uk-offcanvas-bar .uk-button-default,
.uk-offcanvas-bar .uk-button-default.uk-active,
.uk-offcanvas-bar .uk-button-default:active,
.uk-offcanvas-bar .uk-button-default:hover,
.uk-overlay-primary .uk-button-default,
.uk-overlay-primary .uk-button-default.uk-active,
.uk-overlay-primary .uk-button-default:active,
.uk-overlay-primary .uk-button-default:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default:active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff
}

.uk-card-primary.uk-card-body .uk-button-primary,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-primary,
.uk-card-secondary.uk-card-body .uk-button-primary,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-primary,
.uk-light .uk-button-primary,
.uk-offcanvas-bar .uk-button-primary,
.uk-overlay-primary .uk-button-primary,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary {
    background-color: #fff;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-button-primary:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-primary:hover,
.uk-card-secondary.uk-card-body .uk-button-primary:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-primary:hover,
.uk-light .uk-button-primary:hover,
.uk-offcanvas-bar .uk-button-primary:hover,
.uk-overlay-primary .uk-button-primary:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:hover {
    background-color: #f2f2f2;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-button-primary.uk-active,
.uk-card-primary.uk-card-body .uk-button-primary:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-primary:active,
.uk-card-secondary.uk-card-body .uk-button-primary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-primary:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-primary:active,
.uk-light .uk-button-primary.uk-active,
.uk-light .uk-button-primary:active,
.uk-offcanvas-bar .uk-button-primary.uk-active,
.uk-offcanvas-bar .uk-button-primary:active,
.uk-overlay-primary .uk-button-primary.uk-active,
.uk-overlay-primary .uk-button-primary:active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:active {
    background-color: #e6e6e6;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-button-secondary,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-secondary,
.uk-card-secondary.uk-card-body .uk-button-secondary,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-secondary,
.uk-light .uk-button-secondary,
.uk-offcanvas-bar .uk-button-secondary,
.uk-overlay-primary .uk-button-secondary,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary {
    background-color: #fff;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-button-secondary:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-secondary:hover,
.uk-card-secondary.uk-card-body .uk-button-secondary:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-secondary:hover,
.uk-light .uk-button-secondary:hover,
.uk-offcanvas-bar .uk-button-secondary:hover,
.uk-overlay-primary .uk-button-secondary:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:hover {
    background-color: #f2f2f2;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-primary.uk-card-body .uk-button-secondary:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-secondary:active,
.uk-card-secondary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-secondary:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-secondary:active,
.uk-light .uk-button-secondary.uk-active,
.uk-light .uk-button-secondary:active,
.uk-offcanvas-bar .uk-button-secondary.uk-active,
.uk-offcanvas-bar .uk-button-secondary:active,
.uk-overlay-primary .uk-button-secondary.uk-active,
.uk-overlay-primary .uk-button-secondary:active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:active {
    background-color: #e6e6e6;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-button-text,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-text,
.uk-card-secondary.uk-card-body .uk-button-text,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-text,
.uk-light .uk-button-text,
.uk-offcanvas-bar .uk-button-text,
.uk-overlay-primary .uk-button-text,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-button-text:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-text:before,
.uk-card-secondary.uk-card-body .uk-button-text:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-text:before,
.uk-light .uk-button-text:before,
.uk-offcanvas-bar .uk-button-text:before,
.uk-overlay-primary .uk-button-text:before,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:before {
    border-bottom-color: #fff
}

.uk-card-primary.uk-card-body .uk-button-text:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-text:hover,
.uk-card-secondary.uk-card-body .uk-button-text:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-text:hover,
.uk-light .uk-button-text:hover,
.uk-offcanvas-bar .uk-button-text:hover,
.uk-overlay-primary .uk-button-text:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-button-text:disabled,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-text:disabled,
.uk-card-secondary.uk-card-body .uk-button-text:disabled,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-text:disabled,
.uk-light .uk-button-text:disabled,
.uk-offcanvas-bar .uk-button-text:disabled,
.uk-overlay-primary .uk-button-text:disabled,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:disabled {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-button-link,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-link,
.uk-card-secondary.uk-card-body .uk-button-link,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-link,
.uk-light .uk-button-link,
.uk-offcanvas-bar .uk-button-link,
.uk-overlay-primary .uk-button-link,
.uk-section-primary:not(.uk-preserve-color) .uk-button-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-link {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-button-link:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-button-link:hover,
.uk-card-secondary.uk-card-body .uk-button-link:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-button-link:hover,
.uk-light .uk-button-link:hover,
.uk-offcanvas-bar .uk-button-link:hover,
.uk-overlay-primary .uk-button-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:hover {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body.uk-card-badge,
.uk-card-primary>:not([class*=uk-card-media]).uk-card-badge,
.uk-card-secondary.uk-card-body.uk-card-badge,
.uk-card-secondary>:not([class*=uk-card-media]).uk-card-badge,
.uk-light.uk-card-badge,
.uk-offcanvas-bar.uk-card-badge,
.uk-overlay-primary.uk-card-badge,
.uk-section-primary:not(.uk-preserve-color).uk-card-badge,
.uk-section-secondary:not(.uk-preserve-color).uk-card-badge,
.uk-tile-primary:not(.uk-preserve-color).uk-card-badge,
.uk-tile-secondary:not(.uk-preserve-color).uk-card-badge {
    background-color: #fff;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-close,
.uk-card-primary>:not([class*=uk-card-media]) .uk-close,
.uk-card-secondary.uk-card-body .uk-close,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-close,
.uk-light .uk-close,
.uk-offcanvas-bar .uk-close,
.uk-overlay-primary .uk-close,
.uk-section-primary:not(.uk-preserve-color) .uk-close,
.uk-section-secondary:not(.uk-preserve-color) .uk-close,
.uk-tile-primary:not(.uk-preserve-color) .uk-close,
.uk-tile-secondary:not(.uk-preserve-color) .uk-close {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-close:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-close:hover,
.uk-card-secondary.uk-card-body .uk-close:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-close:hover,
.uk-light .uk-close:hover,
.uk-offcanvas-bar .uk-close:hover,
.uk-overlay-primary .uk-close:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-close:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-close:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-close:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-close:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-totop,
.uk-card-primary>:not([class*=uk-card-media]) .uk-totop,
.uk-card-secondary.uk-card-body .uk-totop,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-totop,
.uk-light .uk-totop,
.uk-offcanvas-bar .uk-totop,
.uk-overlay-primary .uk-totop,
.uk-section-primary:not(.uk-preserve-color) .uk-totop,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-totop:active,
.uk-card-primary.uk-card-body .uk-totop:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-totop:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-totop:hover,
.uk-card-secondary.uk-card-body .uk-totop:active,
.uk-card-secondary.uk-card-body .uk-totop:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-totop:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-totop:hover,
.uk-light .uk-totop:active,
.uk-light .uk-totop:hover,
.uk-offcanvas-bar .uk-totop:active,
.uk-offcanvas-bar .uk-totop:hover,
.uk-overlay-primary .uk-totop:active,
.uk-overlay-primary .uk-totop:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-totop:active,
.uk-section-primary:not(.uk-preserve-color) .uk-totop:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-marker,
.uk-card-primary>:not([class*=uk-card-media]) .uk-marker,
.uk-card-secondary.uk-card-body .uk-marker,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-marker,
.uk-light .uk-marker,
.uk-offcanvas-bar .uk-marker,
.uk-overlay-primary .uk-marker,
.uk-section-primary:not(.uk-preserve-color) .uk-marker,
.uk-section-secondary:not(.uk-preserve-color) .uk-marker,
.uk-tile-primary:not(.uk-preserve-color) .uk-marker,
.uk-tile-secondary:not(.uk-preserve-color) .uk-marker {
    background: #f5f5f5;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-marker:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-marker:hover,
.uk-card-secondary.uk-card-body .uk-marker:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-marker:hover,
.uk-light .uk-marker:hover,
.uk-offcanvas-bar .uk-marker:hover,
.uk-overlay-primary .uk-marker:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-marker:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-marker:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-marker:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-marker:hover {
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-badge,
.uk-card-primary>:not([class*=uk-card-media]) .uk-badge,
.uk-card-secondary.uk-card-body .uk-badge,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-badge,
.uk-light .uk-badge,
.uk-offcanvas-bar .uk-badge,
.uk-overlay-primary .uk-badge,
.uk-section-primary:not(.uk-preserve-color) .uk-badge,
.uk-section-secondary:not(.uk-preserve-color) .uk-badge,
.uk-tile-primary:not(.uk-preserve-color) .uk-badge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-badge {
    background-color: #fff;
    color: #161a1d !important
}

.uk-card-primary.uk-card-body .uk-label,
.uk-card-primary>:not([class*=uk-card-media]) .uk-label,
.uk-card-secondary.uk-card-body .uk-label,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-label,
.uk-light .uk-label,
.uk-offcanvas-bar .uk-label,
.uk-overlay-primary .uk-label,
.uk-section-primary:not(.uk-preserve-color) .uk-label,
.uk-section-secondary:not(.uk-preserve-color) .uk-label,
.uk-tile-primary:not(.uk-preserve-color) .uk-label,
.uk-tile-secondary:not(.uk-preserve-color) .uk-label {
    background-color: #fff;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-article-meta,
.uk-card-primary>:not([class*=uk-card-media]) .uk-article-meta,
.uk-card-secondary.uk-card-body .uk-article-meta,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-article-meta,
.uk-light .uk-article-meta,
.uk-offcanvas-bar .uk-article-meta,
.uk-overlay-primary .uk-article-meta,
.uk-section-primary:not(.uk-preserve-color) .uk-article-meta,
.uk-section-secondary:not(.uk-preserve-color) .uk-article-meta,
.uk-tile-primary:not(.uk-preserve-color) .uk-article-meta,
.uk-tile-secondary:not(.uk-preserve-color) .uk-article-meta {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search-input,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-input,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-input,
.uk-light .uk-search-input,
.uk-offcanvas-bar .uk-search-input,
.uk-overlay-primary .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-search-input::-webkit-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-input::-webkit-input-placeholder,
.uk-card-secondary.uk-card-body .uk-search-input::-webkit-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-input::-webkit-input-placeholder,
.uk-light .uk-search-input::-webkit-input-placeholder,
.uk-offcanvas-bar .uk-search-input::-webkit-input-placeholder,
.uk-overlay-primary .uk-search-input::-webkit-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input::-webkit-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input::-webkit-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input::-webkit-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search-input::-moz-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-input::-moz-placeholder,
.uk-card-secondary.uk-card-body .uk-search-input::-moz-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-input::-moz-placeholder,
.uk-light .uk-search-input::-moz-placeholder,
.uk-offcanvas-bar .uk-search-input::-moz-placeholder,
.uk-overlay-primary .uk-search-input::-moz-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input::-moz-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input::-moz-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input::-moz-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::-moz-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search-input:-ms-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-input:-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-search-input:-ms-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-input:-ms-input-placeholder,
.uk-light .uk-search-input:-ms-input-placeholder,
.uk-offcanvas-bar .uk-search-input:-ms-input-placeholder,
.uk-overlay-primary .uk-search-input:-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search-input::-ms-input-placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-input::-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-search-input::-ms-input-placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-input::-ms-input-placeholder,
.uk-light .uk-search-input::-ms-input-placeholder,
.uk-offcanvas-bar .uk-search-input::-ms-input-placeholder,
.uk-overlay-primary .uk-search-input::-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input::-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input::-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input::-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search-input::placeholder,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-input::placeholder,
.uk-card-secondary.uk-card-body .uk-search-input::placeholder,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-input::placeholder,
.uk-light .uk-search-input::placeholder,
.uk-offcanvas-bar .uk-search-input::placeholder,
.uk-overlay-primary .uk-search-input::placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search .uk-search-icon,
.uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search .uk-search-icon,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search .uk-search-icon:hover,
.uk-card-secondary.uk-card-body .uk-search .uk-search-icon,
.uk-card-secondary.uk-card-body .uk-search .uk-search-icon:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search .uk-search-icon,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search .uk-search-icon:hover,
.uk-light .uk-search .uk-search-icon,
.uk-light .uk-search .uk-search-icon:hover,
.uk-offcanvas-bar .uk-search .uk-search-icon,
.uk-offcanvas-bar .uk-search .uk-search-icon:hover,
.uk-overlay-primary .uk-search .uk-search-icon,
.uk-overlay-primary .uk-search .uk-search-icon:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-search-default .uk-search-input,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-default .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-default .uk-search-input,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-default .uk-search-input,
.uk-light .uk-search-default .uk-search-input,
.uk-offcanvas-bar .uk-search-default .uk-search-input,
.uk-overlay-primary .uk-search-default .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input {
    background-color: transparent;
    border-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus,
.uk-card-secondary.uk-card-body .uk-search-default .uk-search-input:focus,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus,
.uk-light .uk-search-default .uk-search-input:focus,
.uk-offcanvas-bar .uk-search-default .uk-search-input:focus,
.uk-overlay-primary .uk-search-default .uk-search-input:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus {
    background-color: rgba(0, 0, 0, .05)
}

.uk-card-primary.uk-card-body .uk-search-large .uk-search-input,
.uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-large .uk-search-input,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-navbar .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-large .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-navbar .uk-search-input,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-large .uk-search-input,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-navbar .uk-search-input,
.uk-light .uk-search-large .uk-search-input,
.uk-light .uk-search-navbar .uk-search-input,
.uk-offcanvas-bar .uk-search-large .uk-search-input,
.uk-offcanvas-bar .uk-search-navbar .uk-search-input,
.uk-overlay-primary .uk-search-large .uk-search-input,
.uk-overlay-primary .uk-search-navbar .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input {
    background-color: transparent
}

.uk-card-primary.uk-card-body .uk-search-toggle,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-toggle,
.uk-card-secondary.uk-card-body .uk-search-toggle,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-toggle,
.uk-light .uk-search-toggle,
.uk-offcanvas-bar .uk-search-toggle,
.uk-overlay-primary .uk-search-toggle,
.uk-section-primary:not(.uk-preserve-color) .uk-search-toggle,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-accordion-title,
.uk-card-primary.uk-card-body .uk-accordion-title:hover,
.uk-card-primary.uk-card-body .uk-search-toggle:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-accordion-title,
.uk-card-primary>:not([class*=uk-card-media]) .uk-accordion-title:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-search-toggle:hover,
.uk-card-secondary.uk-card-body .uk-accordion-title,
.uk-card-secondary.uk-card-body .uk-accordion-title:hover,
.uk-card-secondary.uk-card-body .uk-search-toggle:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-accordion-title,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-accordion-title:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-search-toggle:hover,
.uk-light .uk-accordion-title,
.uk-light .uk-accordion-title:hover,
.uk-light .uk-search-toggle:hover,
.uk-offcanvas-bar .uk-accordion-title,
.uk-offcanvas-bar .uk-accordion-title:hover,
.uk-offcanvas-bar .uk-search-toggle:hover,
.uk-overlay-primary .uk-accordion-title,
.uk-overlay-primary .uk-accordion-title:hover,
.uk-overlay-primary .uk-search-toggle:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-grid-divider>:not(.uk-first-column):before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-grid-divider>:not(.uk-first-column):before,
.uk-card-secondary.uk-card-body .uk-grid-divider>:not(.uk-first-column):before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-grid-divider>:not(.uk-first-column):before,
.uk-light .uk-grid-divider>:not(.uk-first-column):before,
.uk-offcanvas-bar .uk-grid-divider>:not(.uk-first-column):before,
.uk-overlay-primary .uk-grid-divider>:not(.uk-first-column):before,
.uk-section-primary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column):before,
.uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column):before,
.uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column):before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column):before {
    border-left-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-card-secondary.uk-card-body .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-light .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-offcanvas-bar .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-overlay-primary .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin:before {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-nav-default>li>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default>li>a,
.uk-card-secondary.uk-card-body .uk-nav-default>li>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default>li>a,
.uk-light .uk-nav-default>li>a,
.uk-offcanvas-bar .uk-nav-default>li>a,
.uk-overlay-primary .uk-nav-default>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header,
.uk-card-primary.uk-card-body .uk-nav-default>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-default>li>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-header,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default>li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default>li>a:hover,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-header,
.uk-card-secondary.uk-card-body .uk-nav-default>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-default>li>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-header,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default>li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default>li>a:hover,
.uk-light .uk-nav-default .uk-nav-header,
.uk-light .uk-nav-default>li.uk-active>a,
.uk-light .uk-nav-default>li>a:hover,
.uk-offcanvas-bar .uk-nav-default .uk-nav-header,
.uk-offcanvas-bar .uk-nav-default>li.uk-active>a,
.uk-offcanvas-bar .uk-nav-default>li>a:hover,
.uk-overlay-primary .uk-nav-default .uk-nav-header,
.uk-overlay-primary .uk-nav-default>li.uk-active>a,
.uk-overlay-primary .uk-nav-default>li>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li>a:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-divider,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider,
.uk-light .uk-nav-default .uk-nav-divider,
.uk-offcanvas-bar .uk-nav-default .uk-nav-divider,
.uk-overlay-primary .uk-nav-default .uk-nav-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a,
.uk-light .uk-nav-default .uk-nav-sub a,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub a,
.uk-overlay-primary .uk-nav-default .uk-nav-sub a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:hover,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-light .uk-nav-default .uk-nav-sub a:hover,
.uk-light .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:hover,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover,
.uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-nav-primary>li>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary>li>a,
.uk-card-secondary.uk-card-body .uk-nav-primary>li>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary>li>a,
.uk-light .uk-nav-primary>li>a,
.uk-offcanvas-bar .uk-nav-primary>li>a,
.uk-overlay-primary .uk-nav-primary>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header,
.uk-card-primary.uk-card-body .uk-nav-primary>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-primary>li>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary>li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary>li>a:hover,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-header,
.uk-card-secondary.uk-card-body .uk-nav-primary>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-primary>li>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary>li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary>li>a:hover,
.uk-light .uk-nav-primary .uk-nav-header,
.uk-light .uk-nav-primary>li.uk-active>a,
.uk-light .uk-nav-primary>li>a:hover,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-header,
.uk-offcanvas-bar .uk-nav-primary>li.uk-active>a,
.uk-offcanvas-bar .uk-nav-primary>li>a:hover,
.uk-overlay-primary .uk-nav-primary .uk-nav-header,
.uk-overlay-primary .uk-nav-primary>li.uk-active>a,
.uk-overlay-primary .uk-nav-primary>li>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-divider,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider,
.uk-light .uk-nav-primary .uk-nav-divider,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-divider,
.uk-overlay-primary .uk-nav-primary .uk-nav-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a,
.uk-light .uk-nav-primary .uk-nav-sub a,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-secondary>li>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary>li>a,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-secondary>li>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary>li>a,
.uk-light .uk-nav-primary .uk-nav-sub a:hover,
.uk-light .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-light .uk-nav-secondary>li>a,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:hover,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-offcanvas-bar .uk-nav-secondary>li>a,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-overlay-primary .uk-nav-secondary>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary>li>a {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-nav-secondary>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-secondary>li>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary>li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary>li>a:hover,
.uk-card-secondary.uk-card-body .uk-nav-secondary>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-secondary>li>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary>li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary>li>a:hover,
.uk-light .uk-nav-secondary>li.uk-active>a,
.uk-light .uk-nav-secondary>li>a:hover,
.uk-offcanvas-bar .uk-nav-secondary>li.uk-active>a,
.uk-offcanvas-bar .uk-nav-secondary>li>a:hover,
.uk-overlay-primary .uk-nav-secondary>li.uk-active>a,
.uk-overlay-primary .uk-nav-secondary>li>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .1)
}

.uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-subtitle,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-subtitle,
.uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-subtitle,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-subtitle,
.uk-light .uk-nav-secondary .uk-nav-subtitle,
.uk-offcanvas-bar .uk-nav-secondary .uk-nav-subtitle,
.uk-overlay-primary .uk-nav-secondary .uk-nav-subtitle,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-header,
.uk-card-primary.uk-card-body .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-card-primary.uk-card-body .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-header,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-header,
.uk-card-secondary.uk-card-body .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-card-secondary.uk-card-body .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-header,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-light .uk-nav-secondary .uk-nav-header,
.uk-light .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-light .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-offcanvas-bar .uk-nav-secondary .uk-nav-header,
.uk-offcanvas-bar .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-offcanvas-bar .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-overlay-primary .uk-nav-secondary .uk-nav-header,
.uk-overlay-primary .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-overlay-primary .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover .uk-nav-subtitle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary>li.uk-active>a .uk-nav-subtitle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary>li>a:hover .uk-nav-subtitle {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-divider,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-divider,
.uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-divider,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-divider,
.uk-light .uk-nav-secondary .uk-nav-divider,
.uk-offcanvas-bar .uk-nav-secondary .uk-nav-divider,
.uk-overlay-primary .uk-nav-secondary .uk-nav-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a,
.uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a,
.uk-light .uk-nav-secondary .uk-nav-sub a,
.uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub a,
.uk-overlay-primary .uk-nav-secondary .uk-nav-sub a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover,
.uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover,
.uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-light .uk-nav-secondary .uk-nav-sub a:hover,
.uk-light .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub a:hover,
.uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-overlay-primary .uk-nav-secondary .uk-nav-sub a:hover,
.uk-overlay-primary .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active>a {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-card-primary>:not([class*=uk-card-media]) .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-card-secondary.uk-card-body .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-light .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-offcanvas-bar .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-overlay-primary .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-section-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-section-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-tile-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider),
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider>:not(.uk-nav-divider)+:not(.uk-nav-header, .uk-nav-divider) {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-navbar-nav>li>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a,
.uk-light .uk-navbar-nav>li>a,
.uk-offcanvas-bar .uk-navbar-nav>li>a,
.uk-overlay-primary .uk-navbar-nav>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-navbar-item,
.uk-card-primary.uk-card-body .uk-navbar-nav>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-navbar-nav>li:hover>a,
.uk-card-primary.uk-card-body .uk-navbar-nav>li>a:active,
.uk-card-primary.uk-card-body .uk-navbar-nav>li>a[aria-expanded=true],
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-item,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-nav>li.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-nav>li:hover>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a[aria-expanded=true],
.uk-card-secondary.uk-card-body .uk-navbar-item,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li:hover>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a:active,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a[aria-expanded=true],
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-item,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-nav>li.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-nav>li:hover>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a[aria-expanded=true],
.uk-light .uk-navbar-item,
.uk-light .uk-navbar-nav>li.uk-active>a,
.uk-light .uk-navbar-nav>li:hover>a,
.uk-light .uk-navbar-nav>li>a:active,
.uk-light .uk-navbar-nav>li>a[aria-expanded=true],
.uk-offcanvas-bar .uk-navbar-item,
.uk-offcanvas-bar .uk-navbar-nav>li.uk-active>a,
.uk-offcanvas-bar .uk-navbar-nav>li:hover>a,
.uk-offcanvas-bar .uk-navbar-nav>li>a:active,
.uk-offcanvas-bar .uk-navbar-nav>li>a[aria-expanded=true],
.uk-overlay-primary .uk-navbar-item,
.uk-overlay-primary .uk-navbar-nav>li.uk-active>a,
.uk-overlay-primary .uk-navbar-nav>li:hover>a,
.uk-overlay-primary .uk-navbar-nav>li>a:active,
.uk-overlay-primary .uk-navbar-nav>li>a[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-item,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a[aria-expanded=true],
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-item,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a[aria-expanded=true],
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-item,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a[aria-expanded=true],
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-item,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a[aria-expanded=true] {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-navbar-toggle,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-toggle,
.uk-card-secondary.uk-card-body .uk-navbar-toggle,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-toggle,
.uk-light .uk-navbar-toggle,
.uk-offcanvas-bar .uk-navbar-toggle,
.uk-overlay-primary .uk-navbar-toggle,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-navbar-toggle:hover,
.uk-card-primary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-toggle:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-card-secondary.uk-card-body .uk-navbar-toggle:hover,
.uk-card-secondary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-toggle:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-light .uk-navbar-toggle:hover,
.uk-light .uk-navbar-toggle[aria-expanded=true],
.uk-offcanvas-bar .uk-navbar-toggle:hover,
.uk-offcanvas-bar .uk-navbar-toggle[aria-expanded=true],
.uk-overlay-primary .uk-navbar-toggle:hover,
.uk-overlay-primary .uk-navbar-toggle[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true] {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-subnav>*>:first-child,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav>*>:first-child,
.uk-card-secondary.uk-card-body .uk-subnav>*>:first-child,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav>*>:first-child,
.uk-light .uk-subnav>*>:first-child,
.uk-offcanvas-bar .uk-subnav>*>:first-child,
.uk-overlay-primary .uk-subnav>*>:first-child,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>*>:first-child {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-subnav>*>a:hover,
.uk-card-primary.uk-card-body .uk-subnav>.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav>*>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-subnav>*>a:hover,
.uk-card-secondary.uk-card-body .uk-subnav>.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav>*>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav>.uk-active>a,
.uk-light .uk-subnav>*>a:hover,
.uk-light .uk-subnav>.uk-active>a,
.uk-offcanvas-bar .uk-subnav>*>a:hover,
.uk-offcanvas-bar .uk-subnav>.uk-active>a,
.uk-overlay-primary .uk-subnav>*>a:hover,
.uk-overlay-primary .uk-subnav>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>.uk-active>a {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-secondary.uk-card-body .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-light .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-offcanvas-bar .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-overlay-primary .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column):before {
    border-left-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-subnav-pill>*>:first-child,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav-pill>*>:first-child,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>:first-child,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav-pill>*>:first-child,
.uk-light .uk-subnav-pill>*>:first-child,
.uk-offcanvas-bar .uk-subnav-pill>*>:first-child,
.uk-overlay-primary .uk-subnav-pill>*>:first-child,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child {
    background-color: transparent;
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-subnav-pill>*>a:active,
.uk-card-primary.uk-card-body .uk-subnav-pill>*>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav-pill>*>a:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav-pill>*>a:hover,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>a:active,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav-pill>*>a:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav-pill>*>a:hover,
.uk-light .uk-subnav-pill>*>a:active,
.uk-light .uk-subnav-pill>*>a:hover,
.uk-offcanvas-bar .uk-subnav-pill>*>a:active,
.uk-offcanvas-bar .uk-subnav-pill>*>a:hover,
.uk-overlay-primary .uk-subnav-pill>*>a:active,
.uk-overlay-primary .uk-subnav-pill>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover {
    background-color: hsla(0, 0%, 100%, .1);
    color: #fff
}

.uk-card-primary.uk-card-body .uk-subnav-pill>.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav-pill>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-subnav-pill>.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav-pill>.uk-active>a,
.uk-light .uk-subnav-pill>.uk-active>a,
.uk-offcanvas-bar .uk-subnav-pill>.uk-active>a,
.uk-overlay-primary .uk-subnav-pill>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a {
    background-color: #fff;
    color: #161a1d
}

.uk-card-primary.uk-card-body .uk-breadcrumb>*>*,
.uk-card-primary.uk-card-body .uk-subnav>.uk-disabled>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-breadcrumb>*>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-subnav>.uk-disabled>a,
.uk-card-secondary.uk-card-body .uk-breadcrumb>*>*,
.uk-card-secondary.uk-card-body .uk-subnav>.uk-disabled>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-breadcrumb>*>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-subnav>.uk-disabled>a,
.uk-light .uk-breadcrumb>*>*,
.uk-light .uk-subnav>.uk-disabled>a,
.uk-offcanvas-bar .uk-breadcrumb>*>*,
.uk-offcanvas-bar .uk-subnav>.uk-disabled>a,
.uk-overlay-primary .uk-breadcrumb>*>*,
.uk-overlay-primary .uk-subnav>.uk-disabled>a,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-breadcrumb>*>:hover,
.uk-card-primary.uk-card-body .uk-breadcrumb>:last-child>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-breadcrumb>*>:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-breadcrumb>:last-child>*,
.uk-card-secondary.uk-card-body .uk-breadcrumb>*>:hover,
.uk-card-secondary.uk-card-body .uk-breadcrumb>:last-child>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-breadcrumb>*>:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-breadcrumb>:last-child>*,
.uk-light .uk-breadcrumb>*>:hover,
.uk-light .uk-breadcrumb>:last-child>*,
.uk-offcanvas-bar .uk-breadcrumb>*>:hover,
.uk-offcanvas-bar .uk-breadcrumb>:last-child>*,
.uk-overlay-primary .uk-breadcrumb>*>:hover,
.uk-overlay-primary .uk-breadcrumb>:last-child>*,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>* {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-primary.uk-card-body .uk-pagination>*>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-pagination>*>*,
.uk-card-secondary.uk-card-body .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-secondary.uk-card-body .uk-pagination>*>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-pagination>*>*,
.uk-light .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-light .uk-pagination>*>*,
.uk-offcanvas-bar .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-offcanvas-bar .uk-pagination>*>*,
.uk-overlay-primary .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-overlay-primary .uk-pagination>*>*,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column):before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>*>* {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-pagination>*>:hover,
.uk-card-primary.uk-card-body .uk-pagination>.uk-active>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-pagination>*>:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-pagination>.uk-active>*,
.uk-card-secondary.uk-card-body .uk-pagination>*>:hover,
.uk-card-secondary.uk-card-body .uk-pagination>.uk-active>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-pagination>*>:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-pagination>.uk-active>*,
.uk-light .uk-pagination>*>:hover,
.uk-light .uk-pagination>.uk-active>*,
.uk-offcanvas-bar .uk-pagination>*>:hover,
.uk-offcanvas-bar .uk-pagination>.uk-active>*,
.uk-overlay-primary .uk-pagination>*>:hover,
.uk-overlay-primary .uk-pagination>.uk-active>*,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>.uk-active>* {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-pagination>.uk-disabled>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-pagination>.uk-disabled>*,
.uk-card-secondary.uk-card-body .uk-pagination>.uk-disabled>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-pagination>.uk-disabled>*,
.uk-light .uk-pagination>.uk-disabled>*,
.uk-offcanvas-bar .uk-pagination>.uk-disabled>*,
.uk-overlay-primary .uk-pagination>.uk-disabled>*,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>* {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-tab:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-tab:before,
.uk-card-secondary.uk-card-body .uk-tab:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-tab:before,
.uk-light .uk-tab:before,
.uk-offcanvas-bar .uk-tab:before,
.uk-overlay-primary .uk-tab:before,
.uk-section-primary:not(.uk-preserve-color) .uk-tab:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab:before {
    border-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-tab>*>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-tab>*>a,
.uk-card-secondary.uk-card-body .uk-tab>*>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-tab>*>a,
.uk-light .uk-tab>*>a,
.uk-offcanvas-bar .uk-tab>*>a,
.uk-overlay-primary .uk-tab>*>a,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>*>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-tab>*>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-tab>*>a:hover,
.uk-card-secondary.uk-card-body .uk-tab>*>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-tab>*>a:hover,
.uk-light .uk-tab>*>a:hover,
.uk-offcanvas-bar .uk-tab>*>a:hover,
.uk-overlay-primary .uk-tab>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>*>a:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-tab>.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-tab>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-tab>.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-tab>.uk-active>a,
.uk-light .uk-tab>.uk-active>a,
.uk-offcanvas-bar .uk-tab>.uk-active>a,
.uk-overlay-primary .uk-tab>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>.uk-active>a {
    color: #fff;
    border-color: #fff
}

.uk-card-primary.uk-card-body .uk-tab>.uk-disabled>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-tab>.uk-disabled>a,
.uk-card-secondary.uk-card-body .uk-tab>.uk-disabled>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-tab>.uk-disabled>a,
.uk-light .uk-tab>.uk-disabled>a,
.uk-offcanvas-bar .uk-tab>.uk-disabled>a,
.uk-overlay-primary .uk-tab>.uk-disabled>a,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-slidenav,
.uk-card-primary>:not([class*=uk-card-media]) .uk-slidenav,
.uk-card-secondary.uk-card-body .uk-slidenav,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-slidenav,
.uk-light .uk-slidenav,
.uk-offcanvas-bar .uk-slidenav,
.uk-overlay-primary .uk-slidenav,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav {
    color: hsla(0, 0%, 100%, .7)
}

.uk-card-primary.uk-card-body .uk-slidenav:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-slidenav:hover,
.uk-card-secondary.uk-card-body .uk-slidenav:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-slidenav:hover,
.uk-light .uk-slidenav:hover,
.uk-offcanvas-bar .uk-slidenav:hover,
.uk-overlay-primary .uk-slidenav:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:hover {
    color: hsla(0, 0%, 100%, .95)
}

.uk-card-primary.uk-card-body .uk-slidenav:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-slidenav:active,
.uk-card-secondary.uk-card-body .uk-slidenav:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-slidenav:active,
.uk-light .uk-slidenav:active,
.uk-offcanvas-bar .uk-slidenav:active,
.uk-overlay-primary .uk-slidenav:active,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:active {
    color: hsla(0, 0%, 100%, .7)
}

.uk-card-primary.uk-card-body .uk-dotnav>*>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-dotnav>*>*,
.uk-card-secondary.uk-card-body .uk-dotnav>*>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-dotnav>*>*,
.uk-light .uk-dotnav>*>*,
.uk-offcanvas-bar .uk-dotnav>*>*,
.uk-overlay-primary .uk-dotnav>*>*,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>* {
    background-color: transparent;
    border-color: hsla(0, 0%, 100%, .9)
}

.uk-card-primary.uk-card-body .uk-dotnav>*>:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-dotnav>*>:hover,
.uk-card-secondary.uk-card-body .uk-dotnav>*>:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-dotnav>*>:hover,
.uk-light .uk-dotnav>*>:hover,
.uk-offcanvas-bar .uk-dotnav>*>:hover,
.uk-overlay-primary .uk-dotnav>*>:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>:hover {
    background-color: hsla(0, 0%, 100%, .9);
    border-color: transparent
}

.uk-card-primary.uk-card-body .uk-dotnav>*>:active,
.uk-card-primary>:not([class*=uk-card-media]) .uk-dotnav>*>:active,
.uk-card-secondary.uk-card-body .uk-dotnav>*>:active,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-dotnav>*>:active,
.uk-light .uk-dotnav>*>:active,
.uk-offcanvas-bar .uk-dotnav>*>:active,
.uk-overlay-primary .uk-dotnav>*>:active,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>:active {
    background-color: hsla(0, 0%, 100%, .5);
    border-color: transparent
}

.uk-card-primary.uk-card-body .uk-dotnav>.uk-active>*,
.uk-card-primary>:not([class*=uk-card-media]) .uk-dotnav>.uk-active>*,
.uk-card-secondary.uk-card-body .uk-dotnav>.uk-active>*,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-dotnav>.uk-active>*,
.uk-light .uk-dotnav>.uk-active>*,
.uk-offcanvas-bar .uk-dotnav>.uk-active>*,
.uk-overlay-primary .uk-dotnav>.uk-active>*,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>.uk-active>* {
    background-color: hsla(0, 0%, 100%, .9);
    border-color: transparent
}

.uk-card-primary.uk-card-body .uk-thumbnav>*>:after,
.uk-card-primary>:not([class*=uk-card-media]) .uk-thumbnav>*>:after,
.uk-card-secondary.uk-card-body .uk-thumbnav>*>:after,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-thumbnav>*>:after,
.uk-light .uk-thumbnav>*>:after,
.uk-offcanvas-bar .uk-thumbnav>*>:after,
.uk-overlay-primary .uk-thumbnav>*>:after,
.uk-section-primary:not(.uk-preserve-color) .uk-thumbnav>*>:after,
.uk-section-secondary:not(.uk-preserve-color) .uk-thumbnav>*>:after,
.uk-tile-primary:not(.uk-preserve-color) .uk-thumbnav>*>:after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-thumbnav>*>:after {
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, .4))
}

.uk-card-primary.uk-card-body .uk-iconnav>*>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-iconnav>*>a,
.uk-card-secondary.uk-card-body .uk-iconnav>*>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-iconnav>*>a,
.uk-light .uk-iconnav>*>a,
.uk-offcanvas-bar .uk-iconnav>*>a,
.uk-overlay-primary .uk-iconnav>*>a,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>*>a {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-iconnav>*>a:hover,
.uk-card-primary.uk-card-body .uk-iconnav>.uk-active>a,
.uk-card-primary.uk-card-body .uk-text-lead,
.uk-card-primary>:not([class*=uk-card-media]) .uk-iconnav>*>a:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-iconnav>.uk-active>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-text-lead,
.uk-card-secondary.uk-card-body .uk-iconnav>*>a:hover,
.uk-card-secondary.uk-card-body .uk-iconnav>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-text-lead,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-iconnav>*>a:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-iconnav>.uk-active>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-text-lead,
.uk-light .uk-iconnav>*>a:hover,
.uk-light .uk-iconnav>.uk-active>a,
.uk-light .uk-text-lead,
.uk-offcanvas-bar .uk-iconnav>*>a:hover,
.uk-offcanvas-bar .uk-iconnav>.uk-active>a,
.uk-offcanvas-bar .uk-text-lead,
.uk-overlay-primary .uk-iconnav>*>a:hover,
.uk-overlay-primary .uk-iconnav>.uk-active>a,
.uk-overlay-primary .uk-text-lead,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-text-lead,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-lead,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-lead,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-lead {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-text-meta,
.uk-card-primary>:not([class*=uk-card-media]) .uk-text-meta,
.uk-card-secondary.uk-card-body .uk-text-meta,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-text-meta,
.uk-light .uk-text-meta,
.uk-offcanvas-bar .uk-text-meta,
.uk-overlay-primary .uk-text-meta,
.uk-section-primary:not(.uk-preserve-color) .uk-text-meta,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-meta,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-meta,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-meta {
    color: hsla(0, 0%, 100%, .5)
}

.uk-card-primary.uk-card-body .uk-text-muted,
.uk-card-primary>:not([class*=uk-card-media]) .uk-text-muted,
.uk-card-secondary.uk-card-body .uk-text-muted,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-text-muted,
.uk-light .uk-text-muted,
.uk-offcanvas-bar .uk-text-muted,
.uk-overlay-primary .uk-text-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-text-muted,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-muted,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-muted,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-muted {
    color: hsla(0, 0%, 100%, .5) !important
}

.uk-card-primary.uk-card-body .uk-text-emphasis,
.uk-card-primary.uk-card-body .uk-text-primary,
.uk-card-primary.uk-card-body .uk-text-secondary,
.uk-card-primary>:not([class*=uk-card-media]) .uk-text-emphasis,
.uk-card-primary>:not([class*=uk-card-media]) .uk-text-primary,
.uk-card-primary>:not([class*=uk-card-media]) .uk-text-secondary,
.uk-card-secondary.uk-card-body .uk-text-emphasis,
.uk-card-secondary.uk-card-body .uk-text-primary,
.uk-card-secondary.uk-card-body .uk-text-secondary,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-text-emphasis,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-text-primary,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-text-secondary,
.uk-light .uk-text-emphasis,
.uk-light .uk-text-primary,
.uk-light .uk-text-secondary,
.uk-offcanvas-bar .uk-text-emphasis,
.uk-offcanvas-bar .uk-text-primary,
.uk-offcanvas-bar .uk-text-secondary,
.uk-overlay-primary .uk-text-emphasis,
.uk-overlay-primary .uk-text-primary,
.uk-overlay-primary .uk-text-secondary,
.uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-section-primary:not(.uk-preserve-color) .uk-text-primary,
.uk-section-primary:not(.uk-preserve-color) .uk-text-secondary,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-primary,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-secondary,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-primary,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-secondary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-primary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-secondary {
    color: #fff !important
}

.uk-card-primary.uk-card-body .uk-column-divider,
.uk-card-primary>:not([class*=uk-card-media]) .uk-column-divider,
.uk-card-secondary.uk-card-body .uk-column-divider,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-column-divider,
.uk-light .uk-column-divider,
.uk-offcanvas-bar .uk-column-divider,
.uk-overlay-primary .uk-column-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-column-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-column-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-column-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-column-divider {
    -webkit-column-rule-color: hsla(0, 0%, 100%, .2);
    -moz-column-rule-color: hsla(0, 0%, 100%, .2);
    column-rule-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-logo,
.uk-card-primary.uk-card-body .uk-logo:hover,
.uk-card-primary>:not([class*=uk-card-media]) .uk-logo,
.uk-card-primary>:not([class*=uk-card-media]) .uk-logo:hover,
.uk-card-secondary.uk-card-body .uk-logo,
.uk-card-secondary.uk-card-body .uk-logo:hover,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-logo,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-logo:hover,
.uk-light .uk-logo,
.uk-light .uk-logo:hover,
.uk-offcanvas-bar .uk-logo,
.uk-offcanvas-bar .uk-logo:hover,
.uk-overlay-primary .uk-logo,
.uk-overlay-primary .uk-logo:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-logo,
.uk-section-primary:not(.uk-preserve-color) .uk-logo:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo:hover {
    color: #fff
}

.uk-card-primary.uk-card-body .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-primary.uk-card-body .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-card-primary>:not([class*=uk-card-media]) .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-primary>:not([class*=uk-card-media]) .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-card-secondary.uk-card-body .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-secondary.uk-card-body .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-secondary>:not([class*=uk-card-media]) .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-light .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-light .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-offcanvas-bar .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-offcanvas-bar .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-overlay-primary .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-overlay-primary .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-section-primary:not(.uk-preserve-color) .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-section-primary:not(.uk-preserve-color) .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-section-secondary:not(.uk-preserve-color) .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-section-secondary:not(.uk-preserve-color) .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-tile-primary:not(.uk-preserve-color) .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-tile-primary:not(.uk-preserve-color) .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse),
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo>:not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo>picture:not(:only-of-type)>:not(.uk-logo-inverse) {
    display: none
}

.uk-card-primary.uk-card-body .uk-logo-inverse,
.uk-card-primary>:not([class*=uk-card-media]) .uk-logo-inverse,
.uk-card-secondary.uk-card-body .uk-logo-inverse,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-logo-inverse,
.uk-light .uk-logo-inverse,
.uk-offcanvas-bar .uk-logo-inverse,
.uk-overlay-primary .uk-logo-inverse,
.uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo-inverse {
    display: block
}

.uk-card-primary.uk-card-body .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-card-primary.uk-card-body .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-card-primary>:not([class*=uk-card-media]) .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-card-secondary.uk-card-body .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-card-secondary.uk-card-body .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-light .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-light .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-offcanvas-bar .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-offcanvas-bar .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-overlay-primary .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-overlay-primary .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(2n):last-child,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(2n):last-child,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(2n):last-child {
    border-bottom-color: hsla(0, 0%, 100%, .2)
}

.uk-card-primary.uk-card-body .uk-accordion-title:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-accordion-title:before,
.uk-card-secondary.uk-card-body .uk-accordion-title:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-accordion-title:before,
.uk-light .uk-accordion-title:before,
.uk-offcanvas-bar .uk-accordion-title:before,
.uk-overlay-primary .uk-accordion-title:before,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 6h13v1H0z'/%3E%3Cpath fill='%23fff' d='M6 0h1v13H6z'/%3E%3C/svg%3E")
}

.uk-card-primary.uk-card-body .uk-open>.uk-accordion-title:before,
.uk-card-primary>:not([class*=uk-card-media]) .uk-open>.uk-accordion-title:before,
.uk-card-secondary.uk-card-body .uk-open>.uk-accordion-title:before,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-open>.uk-accordion-title:before,
.uk-light .uk-open>.uk-accordion-title:before,
.uk-offcanvas-bar .uk-open>.uk-accordion-title:before,
.uk-overlay-primary .uk-open>.uk-accordion-title:before,
.uk-section-primary:not(.uk-preserve-color) .uk-open>.uk-accordion-title:before,
.uk-section-secondary:not(.uk-preserve-color) .uk-open>.uk-accordion-title:before,
.uk-tile-primary:not(.uk-preserve-color) .uk-open>.uk-accordion-title:before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-open>.uk-accordion-title:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 6h13v1H0z'/%3E%3C/svg%3E")
}

@media print {

    *,
    :after,
    :before {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    @page {
        margin: .5cm
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }
}

*,
:after,
:before {
    box-sizing: border-box
}

:not(p)>a {
    color: inherit
}

:not(p)>a:hover {
    color: inherit;
    text-decoration: none
}

.uk-text-medium {
    font-weight: 500
}

.uk-flex-grow {
    flex-grow: 1
}

@list-bullet-width : calc((--typ-base-line-height) * 1em);

body {
    font-family: Inter, var(--gr21-main-font-family, sans-serif)
}

.elementor-column-gap-narrow>.elementor-column {
    padding-left: 8px
}

.elementor-column-gap-narrow>.elementor-column>.elementor-element-populated {
    padding: 4px 0
}

.elementor-column-gap-default>.elementor-column {
    padding-left: 16px
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 8px 0
}

.elementor-column-gap-extended>.elementor-column {
    padding-left: 24px
}

.elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: 12px 0
}

.elementor-column-gap-wide>.elementor-column {
    padding-left: 32px
}

.elementor-column-gap-wide>.elementor-column>.elementor-element-populated {
    padding: 16px 0
}

.elementor-column-gap-wider>.elementor-column {
    padding-left: 48px
}

.elementor-column-gap-wider>.elementor-column>.elementor-element-populated {
    padding: 24px 0
}

.gr-gr21-default .gr-g-container-fluid {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1680px;
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:1440px) {
    .gr-gr21-default .gr-g-container-fluid {
        width: 1356px;
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .gr-g-container-fluid {
        width: 1356px
    }
}

@media (min-width:1920px) {
    .gr-gr21-default .gr-g-container-fluid {
        width: 1548px
    }
}

.gr-gr21-default .elementor-column-gap-narrow>.elementor-column {
    padding-left: 0
}

.gr-gr21-default .elementor-column-gap-narrow>.elementor-column>.elementor-element-populated {
    padding: 0
}

.gr-gr21-default .elementor-column-gap-default>.elementor-column {
    padding-left: 0
}

.gr-gr21-default .elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 0
}

.gr-gr21-default .elementor-column-gap-extended>.elementor-column {
    padding-left: 0
}

.gr-gr21-default .elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: 0
}

.gr-gr21-default .elementor-column-gap-wide>.elementor-column {
    padding-left: 0
}

.gr-gr21-default .elementor-column-gap-wide>.elementor-column>.elementor-element-populated {
    padding: 0
}

.gr-gr21-default .elementor-column-gap-wider>.elementor-column {
    padding-left: 0
}

.gr-gr21-default .elementor-column-gap-wider>.elementor-column>.elementor-element-populated {
    padding: 0
}

.gr-gr21-default .elementor-section.elementor-top-section>.elementor-container {
    max-width: none
}

.gr-gr21-default .text-h1,
.gr-gr21-default h1 {
    font-size: 3.5rem;
    line-height: 4rem;
    font-weight: 800;
    color: #242a2e
}

@media (min-width:1025px) {

    .gr-gr21-default .text-h1,
    .gr-gr21-default h1 {
        font-size: 4rem;
        line-height: 4.5rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-h1,
    .gr-gr21-default h1 {
        font-size: 5rem;
        line-height: 5.5rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-h1,
    .gr-gr21-default h1 {
        font-size: 6rem;
        line-height: 6.5rem
    }
}

.gr-gr21-default .text-h2,
.gr-gr21-default h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 800;
    color: #242a2e
}

@media (min-width:1025px) {

    .gr-gr21-default .text-h2,
    .gr-gr21-default h2 {
        font-size: 3.5rem;
        line-height: 4rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-h2,
    .gr-gr21-default h2 {
        font-size: 4.5rem;
        line-height: 5rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-h2,
    .gr-gr21-default h2 {
        font-size: 5rem;
        line-height: 5.5rem
    }
}

.gr-gr21-default .text-h3,
.gr-gr21-default h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 800;
    color: #242a2e
}

@media (min-width:1025px) {

    .gr-gr21-default .text-h3,
    .gr-gr21-default h3 {
        font-size: 2.25rem;
        line-height: 2.75rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-h3,
    .gr-gr21-default h3 {
        font-size: 2.5rem;
        line-height: 2.75rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-h3,
    .gr-gr21-default h3 {
        font-size: 3rem;
        line-height: 3.5rem
    }
}

.gr-gr21-default .text-h4,
.gr-gr21-default h4 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    color: #242a2e
}

@media (min-width:1025px) {

    .gr-gr21-default .text-h4,
    .gr-gr21-default h4 {
        font-size: 1.75rem;
        line-height: 2.25rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-h4,
    .gr-gr21-default h4 {
        font-size: 2rem;
        line-height: 2.5rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-h4,
    .gr-gr21-default h4 {
        font-size: 2.25rem;
        line-height: 2.75rem
    }
}

.gr-gr21-default .text-h5,
.gr-gr21-default h5 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #383e43
}

@media (min-width:1025px) {

    .gr-gr21-default .text-h5,
    .gr-gr21-default h5 {
        font-size: 1.125rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-h5,
    .gr-gr21-default h5 {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-h5,
    .gr-gr21-default h5 {
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.gr-gr21-default .text-h6,
.gr-gr21-default h6 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #383e43
}

@media (min-width:1025px) {

    .gr-gr21-default .text-h6,
    .gr-gr21-default h6 {
        font-size: 1.125rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-h6,
    .gr-gr21-default h6 {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-h6,
    .gr-gr21-default h6 {
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.gr-gr21-default .text-p1 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #383e43
}

@media (min-width:1025px) {
    .gr-gr21-default .text-p1 {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .text-p1 {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .text-p1 {
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.gr-gr21-default .text-p2,
.gr-gr21-default div,
.gr-gr21-default p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #383e43
}

@media (min-width:1025px) {

    .gr-gr21-default .text-p2,
    .gr-gr21-default div,
    .gr-gr21-default p {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1280px) {

    .gr-gr21-default .text-p2,
    .gr-gr21-default div,
    .gr-gr21-default p {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {

    .gr-gr21-default .text-p2,
    .gr-gr21-default div,
    .gr-gr21-default p {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

.gr-gr21-default .text-p3 {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #383e43
}

@media (min-width:1025px) {
    .gr-gr21-default .text-p3 {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .text-p3 {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .text-p3 {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.gr-gr21-default .callout1 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600
}

@media (min-width:1025px) {
    .gr-gr21-default .callout1 {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .callout1 {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .callout1 {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

.gr-gr21-default .callout2 {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600
}

@media (min-width:1025px) {
    .gr-gr21-default .callout2 {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .callout2 {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .callout2 {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.gr-gr21-default .label {
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
    color: #242a2e
}

@media (min-width:1025px) {
    .gr-gr21-default .label {
        font-size: .75rem;
        line-height: 1rem
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .label {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .label {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

.gr-gr21-default a {
    font-size: inherit;
    line-height: inherit;
    font-weight: 600;
    text-decoration: none;
    color: #00804a
}

.gr-gr21-default a:hover {
    color: #005339
}

.gr-gr21-default b,
.gr-gr21-default strong {
    font-weight: 600
}

.gr-gr21-default .notransition * {
    transition: none !important
}

.gr-gr21-default .gr21-boxed-content {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap
}

@media (max-width:767px) {
    .gr-gr21-default .gr21-boxed-content {
        margin-left: 20px;
        margin-right: 20px
    }
}

@media (min-width:768px) {
    .gr-gr21-default .gr21-boxed-content {
        width: 100%;
        max-width: 688px
    }
}

@media (min-width:1025px) {
    .gr-gr21-default .gr21-boxed-content {
        max-width: 860px
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .gr21-boxed-content {
        max-width: 1116px
    }
}

@media (min-width:1440px) {
    .gr-gr21-default .gr21-boxed-content {
        max-width: 1164px
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .gr21-boxed-content {
        max-width: 1356px
    }
}

@media (min-width:1920px) {
    .gr-gr21-default .gr21-boxed-content {
        max-width: 1548px
    }
}

.gr-gr21-default .gr21-h-margin-left {
    margin-left: 20px
}

@media (min-width:768px) {
    .gr-gr21-default .gr21-h-margin-left {
        margin-left: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .gr-gr21-default .gr21-h-margin-left {
        margin-left: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .gr21-h-margin-left {
        margin-left: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .gr-gr21-default .gr21-h-margin-left {
        margin-left: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .gr21-h-margin-left {
        margin-left: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .gr-gr21-default .gr21-h-margin-left {
        margin-left: calc(50% - 774px)
    }
}

.gr-gr21-default .gr21-h-margin-right {
    margin-right: 20px
}

@media (min-width:768px) {
    .gr-gr21-default .gr21-h-margin-right {
        margin-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .gr-gr21-default .gr21-h-margin-right {
        margin-right: calc(50% - 430px)
    }
}

@media (min-width:1440px) {
    .gr-gr21-default .gr21-h-margin-right {
        margin-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .gr21-h-margin-right {
        margin-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .gr-gr21-default .gr21-h-margin-right {
        margin-right: calc(50% - 774px)
    }
}

.gr-gr21-default .gr21-h-padding-left {
    padding-left: 20px
}

@media (min-width:768px) {
    .gr-gr21-default .gr21-h-padding-left {
        padding-left: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .gr-gr21-default .gr21-h-padding-left {
        padding-left: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .gr21-h-padding-left {
        padding-left: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .gr-gr21-default .gr21-h-padding-left {
        padding-left: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .gr21-h-padding-left {
        padding-left: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .gr-gr21-default .gr21-h-padding-left {
        padding-left: calc(50% - 774px)
    }
}

.gr-gr21-default .gr21-h-padding-right {
    padding-right: 20px
}

@media (min-width:768px) {
    .gr-gr21-default .gr21-h-padding-right {
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .gr-gr21-default .gr21-h-padding-right {
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .gr-gr21-default .gr21-h-padding-right {
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .gr-gr21-default .gr21-h-padding-right {
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .gr-gr21-default .gr21-h-padding-right {
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .gr-gr21-default .gr21-h-padding-right {
        padding-right: calc(50% - 774px)
    }
}

@media (max-width:767px) {
    .gr-gr21-default .elementor-section .gr21-mobile-fullwidth {
        margin-left: 0;
        margin-right: 0
    }
}

html:lang(km) .gr-gr21-default .text-h1,
html:lang(km) .gr-gr21-default h1 {
    font-size: 3.5rem;
    line-height: 4.125rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-h1,
    html:lang(km) .gr-gr21-default h1 {
        font-size: 4rem;
        line-height: 4.625rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-h1,
    html:lang(km) .gr-gr21-default h1 {
        font-size: 5rem;
        line-height: 5.625rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-h1,
    html:lang(km) .gr-gr21-default h1 {
        font-size: 6rem;
        line-height: 6.625rem
    }
}

html:lang(km) .gr-gr21-default .text-h2,
html:lang(km) .gr-gr21-default h2 {
    font-size: 3rem;
    line-height: 3.625rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-h2,
    html:lang(km) .gr-gr21-default h2 {
        font-size: 3.5rem;
        line-height: 4.125rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-h2,
    html:lang(km) .gr-gr21-default h2 {
        font-size: 4.5rem;
        line-height: 5.125rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-h2,
    html:lang(km) .gr-gr21-default h2 {
        font-size: 5rem;
        line-height: 5.625rem
    }
}

html:lang(km) .gr-gr21-default .text-h3,
html:lang(km) .gr-gr21-default h3 {
    font-size: 2rem;
    line-height: 2.625rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-h3,
    html:lang(km) .gr-gr21-default h3 {
        font-size: 2.25rem;
        line-height: 2.875rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-h3,
    html:lang(km) .gr-gr21-default h3 {
        font-size: 2.5rem;
        line-height: 2.875rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-h3,
    html:lang(km) .gr-gr21-default h3 {
        font-size: 3rem;
        line-height: 3.625rem
    }
}

html:lang(km) .gr-gr21-default .text-h4,
html:lang(km) .gr-gr21-default h4 {
    font-size: 1.5rem;
    line-height: 2.125rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-h4,
    html:lang(km) .gr-gr21-default h4 {
        font-size: 1.75rem;
        line-height: 2.375rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-h4,
    html:lang(km) .gr-gr21-default h4 {
        font-size: 2rem;
        line-height: 2.625rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-h4,
    html:lang(km) .gr-gr21-default h4 {
        font-size: 2.25rem;
        line-height: 2.875rem
    }
}

html:lang(km) .gr-gr21-default .text-h5,
html:lang(km) .gr-gr21-default h5 {
    font-size: 1.125rem;
    line-height: 1.625rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-h5,
    html:lang(km) .gr-gr21-default h5 {
        font-size: 1.125rem;
        line-height: 1.625rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-h5,
    html:lang(km) .gr-gr21-default h5 {
        font-size: 1.25rem;
        line-height: 1.875rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-h5,
    html:lang(km) .gr-gr21-default h5 {
        font-size: 1.5rem;
        line-height: 2.125rem
    }
}

html:lang(km) .gr-gr21-default .text-h6,
html:lang(km) .gr-gr21-default h6 {
    font-size: 1.125rem;
    line-height: 1.625rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-h6,
    html:lang(km) .gr-gr21-default h6 {
        font-size: 1.125rem;
        line-height: 1.625rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-h6,
    html:lang(km) .gr-gr21-default h6 {
        font-size: 1.25rem;
        line-height: 1.875rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-h6,
    html:lang(km) .gr-gr21-default h6 {
        font-size: 1.5rem;
        line-height: 2.125rem
    }
}

html:lang(km) .gr-gr21-default .text-p1 {
    font-size: 1.125rem;
    line-height: 1.625rem
}

@media (min-width:1025px) {
    html:lang(km) .gr-gr21-default .text-p1 {
        font-size: 1.25rem;
        line-height: 1.875rem
    }
}

@media (min-width:1280px) {
    html:lang(km) .gr-gr21-default .text-p1 {
        font-size: 1.25rem;
        line-height: 1.875rem
    }
}

@media (min-width:1680px) {
    html:lang(km) .gr-gr21-default .text-p1 {
        font-size: 1.5rem;
        line-height: 2.125rem
    }
}

html:lang(km) .gr-gr21-default .text-p2,
html:lang(km) .gr-gr21-default div,
html:lang(km) .gr-gr21-default p {
    font-size: 1rem;
    line-height: 1.625rem
}

@media (min-width:1025px) {

    html:lang(km) .gr-gr21-default .text-p2,
    html:lang(km) .gr-gr21-default div,
    html:lang(km) .gr-gr21-default p {
        font-size: 1.125rem;
        line-height: 1.875rem
    }
}

@media (min-width:1280px) {

    html:lang(km) .gr-gr21-default .text-p2,
    html:lang(km) .gr-gr21-default div,
    html:lang(km) .gr-gr21-default p {
        font-size: 1.125rem;
        line-height: 1.875rem
    }
}

@media (min-width:1680px) {

    html:lang(km) .gr-gr21-default .text-p2,
    html:lang(km) .gr-gr21-default div,
    html:lang(km) .gr-gr21-default p {
        font-size: 1.25rem;
        line-height: 1.875rem
    }
}

html:lang(km) .gr-gr21-default .text-p3 {
    font-size: .875rem;
    line-height: 1.375rem
}

@media (min-width:1025px) {
    html:lang(km) .gr-gr21-default .text-p3 {
        font-size: 1rem;
        line-height: 1.625rem
    }
}

@media (min-width:1280px) {
    html:lang(km) .gr-gr21-default .text-p3 {
        font-size: 1rem;
        line-height: 1.625rem
    }
}

@media (min-width:1680px) {
    html:lang(km) .gr-gr21-default .text-p3 {
        font-size: 1.125rem;
        line-height: 1.875rem
    }
}

html:lang(km) .gr-gr21-default .callout1 {
    font-size: 1rem;
    line-height: 1.375rem
}

@media (min-width:1025px) {
    html:lang(km) .gr-gr21-default .callout1 {
        font-size: 1.125rem;
        line-height: 1.875rem
    }
}

@media (min-width:1280px) {
    html:lang(km) .gr-gr21-default .callout1 {
        font-size: 1.125rem;
        line-height: 1.875rem
    }
}

@media (min-width:1680px) {
    html:lang(km) .gr-gr21-default .callout1 {
        font-size: 1.25rem;
        line-height: 1.875rem
    }
}

html:lang(km) .gr-gr21-default .callout2 {
    font-size: .875rem;
    line-height: 1.375rem
}

@media (min-width:1025px) {
    html:lang(km) .gr-gr21-default .callout2 {
        font-size: .875rem;
        line-height: 1.375rem
    }
}

@media (min-width:1280px) {
    html:lang(km) .gr-gr21-default .callout2 {
        font-size: 1rem;
        line-height: 1.625rem
    }
}

@media (min-width:1680px) {
    html:lang(km) .gr-gr21-default .callout2 {
        font-size: 1.125rem;
        line-height: 1.875rem
    }
}

html:lang(km) .gr-gr21-default .label {
    font-size: .75rem;
    line-height: 1.125rem
}

@media (min-width:1025px) {
    html:lang(km) .gr-gr21-default .label {
        font-size: .75rem;
        line-height: 1.125rem
    }
}

@media (min-width:1280px) {
    html:lang(km) .gr-gr21-default .label {
        font-size: .875rem;
        line-height: 1.375rem
    }
}

@media (min-width:1680px) {
    html:lang(km) .gr-gr21-default .label {
        font-size: .875rem;
        line-height: 1.375rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-h1,
html:lang(my-MM) .gr-gr21-default h1 {
    font-size: 3.5rem;
    line-height: 6rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-h1,
    html:lang(my-MM) .gr-gr21-default h1 {
        font-size: 3.75rem;
        line-height: 6.25rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-h1,
    html:lang(my-MM) .gr-gr21-default h1 {
        font-size: 4.5rem;
        line-height: 7.5rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-h1,
    html:lang(my-MM) .gr-gr21-default h1 {
        font-size: 5rem;
        line-height: 8.5rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-h2,
html:lang(my-MM) .gr-gr21-default h2 {
    font-size: 2.75rem;
    line-height: 4.5rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-h2,
    html:lang(my-MM) .gr-gr21-default h2 {
        font-size: 3rem;
        line-height: 4.75rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-h2,
    html:lang(my-MM) .gr-gr21-default h2 {
        font-size: 4rem;
        line-height: 7rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-h2,
    html:lang(my-MM) .gr-gr21-default h2 {
        font-size: 4.5rem;
        line-height: 7.5rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-h3,
html:lang(my-MM) .gr-gr21-default h3 {
    font-size: 1.75rem;
    line-height: 3.5rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-h3,
    html:lang(my-MM) .gr-gr21-default h3 {
        font-size: 2rem;
        line-height: 3.75rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-h3,
    html:lang(my-MM) .gr-gr21-default h3 {
        font-size: 2rem;
        line-height: 3.75rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-h3,
    html:lang(my-MM) .gr-gr21-default h3 {
        font-size: 2.5rem;
        line-height: 4.5rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-h4,
html:lang(my-MM) .gr-gr21-default h4 {
    font-size: 1.375rem;
    line-height: 2.25rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-h4,
    html:lang(my-MM) .gr-gr21-default h4 {
        font-size: 1.475rem;
        line-height: 2.25rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-h4,
    html:lang(my-MM) .gr-gr21-default h4 {
        font-size: 1.5rem;
        line-height: 2.75rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-h4,
    html:lang(my-MM) .gr-gr21-default h4 {
        font-size: 1.75rem;
        line-height: 3rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-h5,
html:lang(my-MM) .gr-gr21-default h5 {
    font-size: 1.125rem;
    line-height: 2.25rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-h5,
    html:lang(my-MM) .gr-gr21-default h5 {
        font-size: 1.25rem;
        line-height: 2.25rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-h5,
    html:lang(my-MM) .gr-gr21-default h5 {
        font-size: 1.25rem;
        line-height: 2.25rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-h5,
    html:lang(my-MM) .gr-gr21-default h5 {
        font-size: 1.5rem;
        line-height: 2.5rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-h6,
html:lang(my-MM) .gr-gr21-default h6 {
    font-size: 1.125rem;
    line-height: 2.25rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-h6,
    html:lang(my-MM) .gr-gr21-default h6 {
        font-size: 1.25rem;
        line-height: 2.25rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-h6,
    html:lang(my-MM) .gr-gr21-default h6 {
        font-size: 1.25rem;
        line-height: 2.25rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-h6,
    html:lang(my-MM) .gr-gr21-default h6 {
        font-size: 1.5rem;
        line-height: 2.5rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-p1 {
    font-size: 1.125rem;
    line-height: 2.25rem
}

@media (min-width:1025px) {
    html:lang(my-MM) .gr-gr21-default .text-p1 {
        font-size: 1.25rem;
        line-height: 2.25rem
    }
}

@media (min-width:1280px) {
    html:lang(my-MM) .gr-gr21-default .text-p1 {
        font-size: 1.25rem;
        line-height: 2.25rem
    }
}

@media (min-width:1680px) {
    html:lang(my-MM) .gr-gr21-default .text-p1 {
        font-size: 1.5rem;
        line-height: 2.5rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-p2,
html:lang(my-MM) .gr-gr21-default div,
html:lang(my-MM) .gr-gr21-default p {
    font-size: .875rem;
    line-height: 1.75rem
}

@media (min-width:1025px) {

    html:lang(my-MM) .gr-gr21-default .text-p2,
    html:lang(my-MM) .gr-gr21-default div,
    html:lang(my-MM) .gr-gr21-default p {
        font-size: 1rem;
        line-height: 1.875rem
    }
}

@media (min-width:1280px) {

    html:lang(my-MM) .gr-gr21-default .text-p2,
    html:lang(my-MM) .gr-gr21-default div,
    html:lang(my-MM) .gr-gr21-default p {
        font-size: 1rem;
        line-height: 1.875rem
    }
}

@media (min-width:1680px) {

    html:lang(my-MM) .gr-gr21-default .text-p2,
    html:lang(my-MM) .gr-gr21-default div,
    html:lang(my-MM) .gr-gr21-default p {
        font-size: 1.125rem;
        line-height: 2rem
    }
}

html:lang(my-MM) .gr-gr21-default .text-p3 {
    font-size: .75rem;
    line-height: 1.5rem
}

@media (min-width:1025px) {
    html:lang(my-MM) .gr-gr21-default .text-p3 {
        font-size: .875rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {
    html:lang(my-MM) .gr-gr21-default .text-p3 {
        font-size: .875rem;
        line-height: 1.5rem
    }
}

@media (min-width:1680px) {
    html:lang(my-MM) .gr-gr21-default .text-p3 {
        font-size: 1.125rem;
        line-height: 2rem
    }
}

html:lang(my-MM) .gr-gr21-default .callout1 {
    font-size: 1rem;
    line-height: 1.5rem
}

@media (min-width:1025px) {
    html:lang(my-MM) .gr-gr21-default .callout1 {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {
    html:lang(my-MM) .gr-gr21-default .callout1 {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1680px) {
    html:lang(my-MM) .gr-gr21-default .callout1 {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

html:lang(my-MM) .gr-gr21-default .callout2 {
    font-size: .875rem;
    line-height: 1.5rem
}

@media (min-width:1025px) {
    html:lang(my-MM) .gr-gr21-default .callout2 {
        font-size: .875rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {
    html:lang(my-MM) .gr-gr21-default .callout2 {
        font-size: .875rem;
        line-height: 1.5rem
    }
}

@media (min-width:1680px) {
    html:lang(my-MM) .gr-gr21-default .callout2 {
        font-size: 1rem;
        line-height: 1.25rem
    }
}

html:lang(my-MM) .gr-gr21-default .label {
    font-size: .625rem;
    line-height: 1.25rem
}

@media (min-width:1025px) {
    html:lang(my-MM) .gr-gr21-default .label {
        font-size: .625rem;
        line-height: 1.25rem
    }
}

@media (min-width:1280px) {
    html:lang(my-MM) .gr-gr21-default .label {
        font-size: .625rem;
        line-height: 1.25rem
    }
}

@media (min-width:1680px) {
    html:lang(my-MM) .gr-gr21-default .label {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

html:lang(vi) .gr-gr21-default .text-h1,
html:lang(vi) .gr-gr21-default .text-h2,
html:lang(vi) .gr-gr21-default .text-h3,
html:lang(vi) .gr-gr21-default .text-h4,
html:lang(vi) .gr-gr21-default .text-h5,
html:lang(vi) .gr-gr21-default .text-h6,
html:lang(vi) .gr-gr21-default h1,
html:lang(vi) .gr-gr21-default h2,
html:lang(vi) .gr-gr21-default h3,
html:lang(vi) .gr-gr21-default h4,
html:lang(vi) .gr-gr21-default h5,
html:lang(vi) .gr-gr21-default h6 {
    font-weight: 500
}

.elementor-widget-heading .elementor-heading-title {
    line-height: var(--typ-base-line-height, 1.333)
}

@media (min-width:768px) {
    .elementor-widget-heading .elementor-heading-title {
        line-height: var(--typ-base-line-height-m, 1.412)
    }
}

.elementor-widget-heading h1.elementor-heading-title {
    line-height: var(--typ-h1-line-height, 1.1)
}

@media (min-width:768px) {
    .elementor-widget-heading h1.elementor-heading-title {
        line-height: var(--typ-h1-line-height-m, 1.143)
    }
}

.elementor-widget-heading h2.elementor-heading-title {
    line-height: var(--typ-h2-line-height, 1.123)
}

@media (min-width:768px) {
    .elementor-widget-heading h2.elementor-heading-title {
        line-height: var(--typ-h2-line-height-m, 1.2)
    }
}

.elementor-widget-heading h3.elementor-heading-title {
    line-height: var(--typ-h3-line-height, 1.167)
}

@media (min-width:768px) {
    .elementor-widget-heading h3.elementor-heading-title {
        line-height: var(--typ-h3-line-height-m, 1.25)
    }
}

.elementor-widget-heading h4.elementor-heading-title {
    line-height: var(--typ-h4-line-height, 1.2)
}

@media (min-width:768px) {
    .elementor-widget-heading h4.elementor-heading-title {
        line-height: var(--typ-h4-line-height-m, 1.333)
    }
}

.elementor-widget-heading h5.elementor-heading-title {
    line-height: var(--typ-h5-line-height, 1.2)
}

@media (min-width:768px) {
    .elementor-widget-heading h5.elementor-heading-title {
        line-height: var(--typ-h5-line-height-m, 1.333)
    }
}

.elementor-widget-heading h6.elementor-heading-title {
    line-height: var(--typ-h6-line-height, 1.2)
}

@media (min-width:768px) {
    .elementor-widget-heading h6.elementor-heading-title {
        line-height: var(--typ-h6-line-height-m, 1.333)
    }
}

.elementor-accordion .elementor-accordion-item {
    border: none;
    border-top: 1px solid #ccc
}

.elementor-accordion .elementor-accordion-item+.elementor-accordion-item {
    border-top: 1px solid #ccc
}

.uk-light .elementor-accordion .elementor-accordion-item,
.uk-light .elementor-accordion .elementor-accordion-item+.elementor-accordion-item {
    border-color: hsla(0, 0%, 100%, .5) !important
}

.elementor-widget-accordion .elementor-accordion .elementor-tab-title {
    font-weight: 500
}

.elementor-widget-accordion .elementor-accordion .elementor-tab-content {
    border-top: none
}

.elementor-accordion .elementor-tab-content,
.elementor-accordion .elementor-tab-title {
    padding-left: 0;
    padding-right: 0
}

.elementor-accordion .elementor-tab-title {
    position: relative;
    line-height: inherit;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex
}

.elementor-accordion .elementor-tab-title a {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 30px;
    min-height: 20px
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon {
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon i {
    width: 100%;
    height: 100%
}

.elementor-accordion .elementor-tab-content {
    padding-top: 0;
    padding-bottom: 20px
}

.elementor-accordion .elementor-tab-content>p:last-child {
    margin-bottom: 0
}

@media (min-width:768px) {
    .elementor-accordion .elementor-accordion-item {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .elementor-accordion .elementor-tab-title {
        padding: 40px 10% 40px 2%
    }

    .elementor-accordion .elementor-tab-title a {
        min-height: 24px
    }

    .elementor-accordion .elementor-tab-title .elementor-accordion-icon {
        right: 36px;
        width: 24px;
        height: 24px
    }

    .elementor-accordion .elementor-tab-content {
        padding: 0 10% 40px 2%
    }
}

.elementor-accordion.elementor-accordion-numlist .elementor-tab-title {
    position: relative;
    display: flex
}

.elementor-accordion.elementor-accordion-numlist .elementor-tab-title .elementor-accordion-bullet {
    font-family: Inter;
    font-size: 24px;
    line-height: 1;
    letter-spacing: .75px;
    width: 16%
}

.elementor-accordion.elementor-accordion-numlist .elementor-tab-title a {
    min-height: 24px
}

.elementor-accordion.elementor-accordion-numlist .elementor-tab-title .elementor-accordion-icon {
    margin-top: 2px
}

.elementor-accordion.elementor-accordion-numlist .elementor-tab-content {
    padding-left: 16%
}

@media (min-width:768px) {
    .elementor-accordion.elementor-accordion-numlist .elementor-tab-title {
        padding-left: 0;
        padding-right: 10%
    }

    .elementor-accordion.elementor-accordion-numlist .elementor-tab-title .elementor-accordion-bullet {
        font-size: 64px;
        letter-spacing: 2px;
        width: 133px
    }

    .elementor-accordion.elementor-accordion-numlist .elementor-tab-title a {
        min-height: 64px;
        box-sizing: content-box;
        max-width: 708px
    }

    .elementor-accordion.elementor-accordion-numlist .elementor-tab-title .elementor-accordion-icon {
        margin-top: 20px
    }

    .elementor-accordion.elementor-accordion-numlist .elementor-tab-content {
        padding-left: 133px;
        padding-right: 10%;
        box-sizing: content-box;
        max-width: 708px
    }
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon i:before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon .elementor-accordion-icon-closed,
.elementor-accordion .elementor-tab-title .elementor-accordion-icon .elementor-accordion-icon-opened {
    height: 100%
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon i.elementor-accordion-icon-opened:before,
.elementor-accordion .elementor-tab-title .elementor-accordion-icon span.elementor-accordion-icon-opened>i:before {
    background-image: url(./multi.png)
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon i.elementor-accordion-icon-closed:before,
.elementor-accordion .elementor-tab-title .elementor-accordion-icon span.elementor-accordion-icon-closed>i:before {
    background-image: url(https://assets.grab.com/wp-content/uploads/2020/04/plus.png)
}

.main-nav___OPT6O {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1021;
    background: hsla(0, 0%, 100%, .9);
    border-bottom: 0;
    transition-property: all;
    transition-duration: .4s;
    color: #363a45
}

.main-nav___OPT6O:hover {
    background: #fff
}

@media (min-width:768px) {
    .main-nav___OPT6O {
        border-bottom: 1px solid #ddd
    }
}

.main-nav___OPT6O a {
    color: #363a45
}

.main-nav___OPT6O a:hover {
    color: #363a45;
    text-decoration: none
}

.desk-menu___QTPyx {
    height: 69px
}

.desk-menu___QTPyx .nav-item___2uElI {
    margin: 0;
    padding: 0
}

.desk-menu___QTPyx .nav-item___2uElI,
.desk-menu___QTPyx .nav-item___2uElI>.menu-item___3Tik- {
    height: 100%;
    display: flex;
    align-items: center
}

.desk-menu___QTPyx .nav-item___2uElI>.menu-item___3Tik-:not(:first-of-type) {
    margin-left: 15px
}

.desk-menu___QTPyx .nav-item___2uElI>.menu-item___3Tik-:not(:last-of-type) {
    margin-right: 15px
}

.desk-menu___QTPyx .hbg-menu___fN0Ym>a {
    width: 28px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.desk-menu___QTPyx .hbg-menu___fN0Ym>a .fa {
    font-size: 32px
}

.desk-menu___QTPyx .hbg-menu___fN0Ym>a .icon-desc___1Q-sN {
    font-size: 11px;
    font-weight: 500
}

.desk-menu___QTPyx .hbg-menu___fN0Ym>a.uk-open .fa:before {
    content: "\f00d"
}

.desk-menu___QTPyx .hbg-menu___fN0Ym>a.uk-open .icon-desc___1Q-sN {
    display: none
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .dropdown-menu___1wBly {
    min-width: 100vw;
    padding: 0;
    overflow: hidden;
    background-color: #f6f8fa;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

@media (min-width:1440px) {
    .desk-menu___QTPyx .hbg-menu___fN0Ym .dropdown-menu___1wBly {
        min-width: 1440px
    }
}

.dropdown-menu___1wBly {
    display: none
}

@media (min-width:1440px) {
    .grabnav-tab-container___7TOTF {
        width: 1156px
    }

    #main-menu___iYtZA .dropdown-menu___1wBly.columns-3___1QLij {
        min-width: 1156px
    }
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-container___7TOTF {
    display: flex;
    z-index: 10;
    padding: 0 !important;
    width: 100%
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    width: 338px;
    float: left;
    min-height: 400px;
    background-color: #fff
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j {
    float: left;
    width: calc(100% - 338px)
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj {
    margin-bottom: 0
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj a.list-group-item___2Rh2z {
    height: 80px;
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 40px;
    font-size: 20px;
    text-align: left;
    border-bottom: 1px solid #f6f8fa;
    border-top: none;
    border-right: 1px solid #f6f8fa;
    font-weight: 500;
    display: flex;
    align-items: center
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj>a .fa,
.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj>a .glyphicon {
    color: #5a55a3
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj>a:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    -moz-border-top-right-radius: 0
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj>a:last-child {
    border-bottom-right-radius: 0;
    -moz-border-bottom-right-radius: 0
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-menu___2wFU4 .list-group___3mSjj a.active___38mVC {
    background-color: #f6f8fa;
    background-image: #f6f8fa;
    border-color: #f6f8fa
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab-content___2S_ph {
    background-color: transparent;
    padding-left: 20px;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph:not(.active___38mVC) {
    display: none
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph .item-link___3xC5z {
    width: 134px;
    margin: 16px 0 0 28px
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph .item-link___3xC5z h4,
.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph .item-link___3xC5z h4 a {
    font-size: 16px
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph .item-link___3xC5z h4 {
    margin-bottom: 10px;
    line-height: 1.8;
    font-weight: 500
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph .item-link___3xC5z p {
    font-size: 12px;
    line-height: 1.43;
    margin-top: 0;
    margin-bottom: 16px
}

.desk-menu___QTPyx .hbg-menu___fN0Ym .grabnav-tab___29s5j .grabnav-tab-content___2S_ph .item-link___3xC5z h4 a:hover {
    border-bottom: 2px solid #00b14f;
    padding-bottom: 2px;
    text-decoration: none
}

.desk-menu___QTPyx .grab-logo___2ZcXU {
    display: block;
    height: 35px;
    width: 91px;
    background-size: contain;
    background-image: url(https://assets.grab.com/wp-content/uploads/media/grab_logo.png);
    background-repeat: no-repeat;
    font: 0/0 a !important;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.desk-menu___QTPyx .secondary-menu___2vDa7 a {
    font-weight: 500;
    font-size: 17px
}

.desk-menu___QTPyx .secondary-menu___2vDa7 .menu-item___3Tik-.icon-globe___1kgTX:before {
    content: " ";
    display: block;
    height: 20px;
    width: 20px;
    padding: 15px 0;
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/globe.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 6px
}

.desk-menu___QTPyx .partner-menu___2BNj1>a .fa {
    margin-left: 5px
}

.desk-menu___QTPyx .partner-menu___2BNj1 .submenu___3LeeQ {
    background: #fff;
    box-shadow: 0 3px 4px 0 rgba(87, 85, 85, .175);
    padding: 5px 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid rgba(0, 0, 0, .15);
    border-top: none;
    list-style: none
}

.desk-menu___QTPyx .partner-menu___2BNj1 .submenu___3LeeQ>li {
    border-bottom: 1px solid #f1f4f6;
    padding: 12px 0;
    line-height: 22px
}

.desk-menu___QTPyx .partner-menu___2BNj1 .submenu___3LeeQ>li:last-child {
    border-bottom: none
}

.desk-menu___QTPyx .partner-menu___2BNj1 .submenu___3LeeQ>li>a {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 2px solid #fff;
    white-space: nowrap;
    line-height: 22px
}

.desk-menu___QTPyx .partner-menu___2BNj1 .submenu___3LeeQ>li>a:hover {
    border-bottom: 2px solid #00b14f;
    padding-bottom: 4px;
    text-decoration: none;
    background-color: #fff
}

.desk-menu___QTPyx .partner-menu___2BNj1>a.uk-open .fa-angle-down:before {
    content: "\f106";
    color: #00b13f
}

.desk-menu___QTPyx .search-bar___2Ucrm {
    min-width: 32px;
    position: relative
}

.desk-menu___QTPyx .search-bar___2Ucrm .fa {
    cursor: pointer;
    position: absolute;
    right: 8px
}

.desk-menu___QTPyx .search-bar___2Ucrm input {
    height: 36px;
    width: 30vw;
    max-width: 250px;
    background-color: #f1f4f6;
    border-radius: 20px;
    border: 1px solid #fdfdfd;
    padding: 4px 20px 6px 12px;
    margin-right: 0;
    font-size: 14px;
    box-sizing: border-box
}

.mobile-menu___2Kcdm {
    margin-bottom: 0;
    height: 50px
}

.mobile-menu___2Kcdm .nav-item___2uElI {
    height: 100%;
    display: flex;
    align-items: center
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym {
    margin-right: 30px
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym>a {
    margin-left: -16px;
    padding-left: 16px
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym>a .fa {
    font-size: 28px
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly {
    width: calc(100vw - 30px);
    padding: 0;
    border-top-color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .175);
    overflow: scroll;
    max-height: calc(100vh - 50px);
    -webkit-overflow-scrolling: touch;
    font-size: 14px;
    font-weight: 500
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- {
    padding: 0 16px;
    background-color: #f6f8fa;
    border-bottom: 1px solid #f1f4f6
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik-:last-child {
    border-bottom: none
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .icon-globe___1kgTX {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/globe.png);
    display: block;
    height: 20px;
    width: 20px;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    float: left
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .submenu___3LeeQ {
    margin-left: -16px;
    margin-right: -16px;
    display: none;
    list-style: none;
    padding: 0
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .submenu___3LeeQ li {
    padding: 0 0 0 6px;
    background-color: #fff;
    border-bottom: 1px solid #f1f4f6;
    border-left: 3px solid #fff
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .submenu___3LeeQ li:hover {
    border-left: 3px solid #00b13f
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .submenu___3LeeQ a {
    padding: 14px 0 14px 10px;
    display: block
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .submenu___3LeeQ a:hover {
    text-decoration: none
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik->a {
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    padding-top: 13px
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik->a .label___ZSnRe {
    flex: 1
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly .menu-item___3Tik- .submenu-toggle___1fgmT.open___3Sj0I .fa-angle-right:before {
    content: "\f106"
}

.mobile-menu___2Kcdm .hbg-menu___fN0Ym .dropdown-menu___1wBly>li>a:hover {
    background-color: inherit
}

.mobile-menu___2Kcdm .grab-logo___2ZcXU {
    display: block;
    height: 30px;
    width: 67px;
    background-size: contain;
    background-image: url(https://assets.grab.com/wp-content/uploads/media/grab_logo.png);
    background-repeat: no-repeat;
    font: 0/0 a !important;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.mobile-menu___2Kcdm .search-bar___2Ucrm {
    position: relative
}

.mobile-menu___2Kcdm .search-bar___2Ucrm .fa {
    cursor: pointer;
    position: absolute;
    right: 8px
}

.mobile-menu___2Kcdm .search-bar___2Ucrm input {
    height: 32px;
    width: 50vw;
    background-color: #f1f4f6;
    border-radius: 20px;
    border: 1px solid #fdfdfd;
    padding: 4px 20px 6px 12px;
    margin-right: 0;
    font-size: 14px;
    box-sizing: border-box
}

.gr-page-container {
    margin-top: 50px
}

@media (min-width:768px) {
    .gr-page-container {
        margin-top: 69px
    }
}

.global-menu___1kTjI {
    padding: 0;
    border: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    list-style-type: none
}

.global-menu-item___Oc9Ng:not(:first-child) {
    border-top: 1px solid #f6f8fa
}

.global-menu___1kTjI .global-menu-item___Oc9Ng a {
    display: block;
    padding: 20px 0 20px 36px;
    font-size: 20px;
    width: 360px
}

.global-menu___1kTjI .global-menu-item___Oc9Ng a:hover {
    background-color: #f5f5f5
}

.global-menu___1kTjI .global-menu-item___Oc9Ng:last-child a {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.secondary-menu___2vDa7 .whats-new a,
.secondary-menu___2vDa7 .whats-new a span {
    color: #00b14f
}

.sitemap___1JMa0 {
    background-color: #eaeff2;
    color: #363a45;
    padding: 40px 0;
    text-align: left;
    font-size: 14px
}

.sitemap___1JMa0 .col-heading___1X9ca,
.sitemap___1JMa0 .col-heading___1X9ca a,
.sitemap___1JMa0 .sitemap-list___YHIc2 a,
.sitemap___1JMa0 .wrapper-about-grab___123yY .grab-info___2jOT2 {
    color: #363a45
}

.sitemap___1JMa0 .wrapper-about-grab___123yY {
    margin-bottom: 30px
}

.sitemap___1JMa0 .grab-logo___2eXtu {
    display: block;
    width: 78px;
    height: 28px;
    background-size: cover;
    background-image: url(https://assets.grab.com/wp-content/uploads/media/grab_logo.png);
    background-repeat: no-repeat;
    font: 0/0 a !important;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.sitemap___1JMa0 .grab-info___2jOT2 {
    line-height: 1.43;
    letter-spacing: .2px;
    color: #363a45;
    width: 100%
}

.sitemap___1JMa0 .grab-info___2jOT2 p {
    font-size: 14px;
    line-height: 20px
}

.sitemap___1JMa0 .grab-info___2jOT2 .slogan___ECyAI {
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 20px;
    line-height: 20px
}

.sitemap___1JMa0 .grab-contact___23Qcb {
    margin-top: 26px;
    margin-bottom: 20px;
    line-height: 20px
}

.sitemap___1JMa0 .grab-contact___23Qcb .fa {
    border-radius: 50%;
    background-color: #363a45;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px
}

.sitemap___1JMa0 .grab-contact___23Qcb .fa-tiktok {
    font-family: Font Awesome\ 5 Brands;
    font-weight: 400
}

.sitemap___1JMa0 .grab-contact___23Qcb .social-title___2ym7Y {
    font-weight: 500;
    margin-bottom: 1.25rem;
    font-size: 14px;
    line-height: 20px
}

.sitemap___1JMa0 .country-selector___1iPVB {
    position: relative;
    font-style: normal;
    margin-top: 30px;
    cursor: pointer
}

.sitemap___1JMa0 .country-selector___1iPVB a {
    color: inherit;
    font-size: 16px;
    font-weight: 400
}

.sitemap___1JMa0 .country-selector___1iPVB a:hover {
    text-decoration: none
}

.sitemap___1JMa0 .country-selector___1iPVB>a {
    width: 180px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 6px 10px
}

.sitemap___1JMa0 .country-selector___1iPVB>a.uk-open .fa-angle-up:before {
    content: "\f107"
}

.sitemap___1JMa0 .country-selector___1iPVB .dropdown-menu___18r7p {
    min-width: 100%;
    padding: 5px 0;
    margin-top: 31px;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px
}

.sitemap___1JMa0 .country-selector___1iPVB .dropdown-menu___18r7p a {
    display: block;
    width: 100%;
    padding: 6px 10px !important;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
}

.sitemap___1JMa0 .country-selector___1iPVB .dropdown-menu___18r7p a:hover {
    background-color: #f1f4f6
}

.sitemap___1JMa0 .gr-flag {
    float: left;
    margin-bottom: 0;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-size: cover
}

.sitemap___1JMa0 .gr-flag--sg {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/sg.png)
}

.sitemap___1JMa0 .gr-flag--my {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/my.png)
}

.sitemap___1JMa0 .gr-flag--id {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/ind.png)
}

.sitemap___1JMa0 .gr-flag--th {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/th.png)
}

.sitemap___1JMa0 .gr-flag--vn {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/vn.png)
}

.sitemap___1JMa0 .gr-flag--ph {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/ph.png)
}

.sitemap___1JMa0 .gr-flag--us {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/us.png)
}

.sitemap___1JMa0 .gr-flag--mm {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/mm.png)
}

.sitemap___1JMa0 .gr-flag--kh {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/kh.png)
}

.sitemap___1JMa0 .gr-flag--globe {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/flag/globe.png)
}

@media (min-width:768px) {

    .sitemap___1JMa0 .country-selector___1iPVB>a,
    .sitemap___1JMa0 .extra___Nn8iF {
        font-size: 16px
    }
}

.sitemap___1JMa0 .col-heading___1X9ca {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1em;
    line-height: 22px
}

.sitemap___1JMa0 .sitemap-column___1mP21 {
    padding-left: 1rem;
    padding-right: 1rem
}

.sitemap___1JMa0 .sitemap-list___YHIc2 {
    list-style: none;
    padding: 0;
    margin: 0
}

.sitemap___1JMa0 .sitemap-list___YHIc2 li {
    line-height: 16px;
    padding: 4px 0;
    font-size: 14px
}

.sitemap___1JMa0 .sitemap-list___YHIc2 a {
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px
}

.sitemap___1JMa0 .sitemap-list___YHIc2 a:hover {
    text-decoration: underline
}

.footer___26qiI {
    font-size: 14px;
    padding: 20px 0;
    background-color: #363a45;
    color: #fff
}

.footer___26qiI a {
    color: inherit
}

.footer___26qiI .policylist___1Fy8_ .policyitem___ZIQ1q:hover {
    text-decoration: underline
}

.footer___26qiI .policylist___1Fy8_ .policyitem___ZIQ1q {
    color: #fff;
    font-size: 16px;
    font-weight: 500
}

.footer___26qiI .policylist___1Fy8_ .policy-divider___3-oE- {
    margin: 0 8px;
    color: #fff;
    font-size: 16px
}

.footer___26qiI .copyright___1SYqb {
    font-size: 16px;
    color: hsla(0, 0%, 100%, .6);
    text-align: center
}

.footer___26qiI .playstore___3MwPS a:not(:last-of-type) {
    margin-right: 10px
}

.footer___26qiI .playstore___3MwPS a img {
    display: block;
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 38px
}

.gr21-col-1 {
    width: calc(25% - 12px)
}

.gr21-col-2 {
    width: calc(50% - 8px)
}

.gr21-col-3 {
    width: calc(75% - 4px)
}

.gr21-col-4 {
    width: 100%
}

[class*=gr21-col-]:not(:first-child) {
    margin-left: 16px
}

@media (min-width:768px) {

    .gr21-col-1,
    .gr21-col-m-1 {
        width: 160px
    }

    .gr21-col-2,
    .gr21-col-m-2 {
        width: 336px
    }

    .gr21-col-3,
    .gr21-col-m-3 {
        width: 512px
    }

    .gr21-col-4,
    .gr21-col-m-4 {
        width: 688px
    }

    [class*=gr21-col-]:not(:first-child) {
        margin-left: 16px
    }
}

@media (min-width:1025px) {

    .gr21-col-1,
    .gr21-col-l-1,
    .gr21-col-m-1 {
        width: 35px
    }

    .gr21-col-2,
    .gr21-col-l-2,
    .gr21-col-m-2 {
        width: 90px
    }

    .gr21-col-3,
    .gr21-col-l-3,
    .gr21-col-m-3 {
        width: 145px
    }

    .gr21-col-4,
    .gr21-col-l-4,
    .gr21-col-m-4 {
        width: 200px
    }

    .gr21-col-5,
    .gr21-col-l-5,
    .gr21-col-m-5 {
        width: 255px
    }

    .gr21-col-6,
    .gr21-col-l-6,
    .gr21-col-m-6 {
        width: 310px
    }

    .gr21-col-7,
    .gr21-col-l-7,
    .gr21-col-m-7 {
        width: 365px
    }

    .gr21-col-8,
    .gr21-col-l-8,
    .gr21-col-m-8 {
        width: 420px
    }

    .gr21-col-9,
    .gr21-col-l-9,
    .gr21-col-m-9 {
        width: 475px
    }

    .gr21-col-10,
    .gr21-col-l-10,
    .gr21-col-m-10 {
        width: 530px
    }

    .gr21-col-11,
    .gr21-col-l-11,
    .gr21-col-m-11 {
        width: 585px
    }

    .gr21-col-12,
    .gr21-col-l-12,
    .gr21-col-m-12 {
        width: 640px
    }

    .gr21-col-13,
    .gr21-col-l-13,
    .gr21-col-m-13 {
        width: 695px
    }

    .gr21-col-14,
    .gr21-col-l-14,
    .gr21-col-m-14 {
        width: 750px
    }

    .gr21-col-15,
    .gr21-col-l-15,
    .gr21-col-m-15 {
        width: 805px
    }

    .gr21-col-16,
    .gr21-col-l-16,
    .gr21-col-m-16 {
        width: 860px
    }

    [class*=gr21-col-]:not(:first-child) {
        margin-left: 20px
    }
}

@media (min-width:1280px) {

    .gr21-col-1,
    .gr21-col-l-1,
    .gr21-col-m-1,
    .gr21-col-xl-1 {
        width: 51px
    }

    .gr21-col-2,
    .gr21-col-l-2,
    .gr21-col-m-2,
    .gr21-col-xl-2 {
        width: 122px
    }

    .gr21-col-3,
    .gr21-col-l-3,
    .gr21-col-m-3,
    .gr21-col-xl-3 {
        width: 193px
    }

    .gr21-col-4,
    .gr21-col-l-4,
    .gr21-col-m-4,
    .gr21-col-xl-4 {
        width: 264px
    }

    .gr21-col-5,
    .gr21-col-l-5,
    .gr21-col-m-5,
    .gr21-col-xl-5 {
        width: 335px
    }

    .gr21-col-6,
    .gr21-col-l-6,
    .gr21-col-m-6,
    .gr21-col-xl-6 {
        width: 406px
    }

    .gr21-col-7,
    .gr21-col-l-7,
    .gr21-col-m-7,
    .gr21-col-xl-7 {
        width: 477px
    }

    .gr21-col-8,
    .gr21-col-l-8,
    .gr21-col-m-8,
    .gr21-col-xl-8 {
        width: 548px
    }

    .gr21-col-9,
    .gr21-col-l-9,
    .gr21-col-m-9,
    .gr21-col-xl-9 {
        width: 619px
    }

    .gr21-col-10,
    .gr21-col-l-10,
    .gr21-col-m-10,
    .gr21-col-xl-10 {
        width: 690px
    }

    .gr21-col-11,
    .gr21-col-l-11,
    .gr21-col-m-11,
    .gr21-col-xl-11 {
        width: 761px
    }

    .gr21-col-12,
    .gr21-col-l-12,
    .gr21-col-m-12,
    .gr21-col-xl-12 {
        width: 832px
    }

    .gr21-col-13,
    .gr21-col-l-13,
    .gr21-col-m-13,
    .gr21-col-xl-13 {
        width: 903px
    }

    .gr21-col-14,
    .gr21-col-l-14,
    .gr21-col-m-14,
    .gr21-col-xl-14 {
        width: 974px
    }

    .gr21-col-15,
    .gr21-col-l-15,
    .gr21-col-m-15,
    .gr21-col-xl-15 {
        width: 1045px
    }

    .gr21-col-16,
    .gr21-col-l-16,
    .gr21-col-m-16,
    .gr21-col-xl-16 {
        width: 1116px
    }

    [class*=gr21-col-]:not(:first-child) {
        margin-left: 20px
    }
}

@media (min-width:1440px) {

    .gr21-col-1,
    .gr21-col-2xl-1,
    .gr21-col-l-1,
    .gr21-col-m-1,
    .gr21-col-xl-1 {
        width: 54px
    }

    .gr21-col-2,
    .gr21-col-2xl-2,
    .gr21-col-l-2,
    .gr21-col-m-2,
    .gr21-col-xl-2 {
        width: 128px
    }

    .gr21-col-2xl-3,
    .gr21-col-3,
    .gr21-col-l-3,
    .gr21-col-m-3,
    .gr21-col-xl-3 {
        width: 202px
    }

    .gr21-col-2xl-4,
    .gr21-col-4,
    .gr21-col-l-4,
    .gr21-col-m-4,
    .gr21-col-xl-4 {
        width: 276px
    }

    .gr21-col-2xl-5,
    .gr21-col-5,
    .gr21-col-l-5,
    .gr21-col-m-5,
    .gr21-col-xl-5 {
        width: 350px
    }

    .gr21-col-2xl-6,
    .gr21-col-6,
    .gr21-col-l-6,
    .gr21-col-m-6,
    .gr21-col-xl-6 {
        width: 424px
    }

    .gr21-col-2xl-7,
    .gr21-col-7,
    .gr21-col-l-7,
    .gr21-col-m-7,
    .gr21-col-xl-7 {
        width: 498px
    }

    .gr21-col-2xl-8,
    .gr21-col-8,
    .gr21-col-l-8,
    .gr21-col-m-8,
    .gr21-col-xl-8 {
        width: 572px
    }

    .gr21-col-2xl-9,
    .gr21-col-9,
    .gr21-col-l-9,
    .gr21-col-m-9,
    .gr21-col-xl-9 {
        width: 646px
    }

    .gr21-col-2xl-10,
    .gr21-col-10,
    .gr21-col-l-10,
    .gr21-col-m-10,
    .gr21-col-xl-10 {
        width: 720px
    }

    .gr21-col-2xl-11,
    .gr21-col-11,
    .gr21-col-l-11,
    .gr21-col-m-11,
    .gr21-col-xl-11 {
        width: 794px
    }

    .gr21-col-2xl-12,
    .gr21-col-12,
    .gr21-col-l-12,
    .gr21-col-m-12,
    .gr21-col-xl-12 {
        width: 868px
    }

    .gr21-col-2xl-13,
    .gr21-col-13,
    .gr21-col-l-13,
    .gr21-col-m-13,
    .gr21-col-xl-13 {
        width: 942px
    }

    .gr21-col-2xl-14,
    .gr21-col-14,
    .gr21-col-l-14,
    .gr21-col-m-14,
    .gr21-col-xl-14 {
        width: 1016px
    }

    .gr21-col-2xl-15,
    .gr21-col-15,
    .gr21-col-l-15,
    .gr21-col-m-15,
    .gr21-col-xl-15 {
        width: 1090px
    }

    .gr21-col-2xl-16,
    .gr21-col-16,
    .gr21-col-l-16,
    .gr21-col-m-16,
    .gr21-col-xl-16 {
        width: 1164px
    }

    [class*=gr21-col-]:not(:first-child) {
        margin-left: 20px
    }
}

@media (min-width:1680px) {

    .gr21-col-1,
    .gr21-col-2xl-1,
    .gr21-col-3xl-1,
    .gr21-col-l-1,
    .gr21-col-m-1,
    .gr21-col-xl-1 {
        width: 66px
    }

    .gr21-col-2,
    .gr21-col-2xl-2,
    .gr21-col-3xl-2,
    .gr21-col-l-2,
    .gr21-col-m-2,
    .gr21-col-xl-2 {
        width: 152px
    }

    .gr21-col-2xl-3,
    .gr21-col-3,
    .gr21-col-3xl-3,
    .gr21-col-l-3,
    .gr21-col-m-3,
    .gr21-col-xl-3 {
        width: 238px
    }

    .gr21-col-2xl-4,
    .gr21-col-3xl-4,
    .gr21-col-4,
    .gr21-col-l-4,
    .gr21-col-m-4,
    .gr21-col-xl-4 {
        width: 324px
    }

    .gr21-col-2xl-5,
    .gr21-col-3xl-5,
    .gr21-col-5,
    .gr21-col-l-5,
    .gr21-col-m-5,
    .gr21-col-xl-5 {
        width: 410px
    }

    .gr21-col-2xl-6,
    .gr21-col-3xl-6,
    .gr21-col-6,
    .gr21-col-l-6,
    .gr21-col-m-6,
    .gr21-col-xl-6 {
        width: 496px
    }

    .gr21-col-2xl-7,
    .gr21-col-3xl-7,
    .gr21-col-7,
    .gr21-col-l-7,
    .gr21-col-m-7,
    .gr21-col-xl-7 {
        width: 582px
    }

    .gr21-col-2xl-8,
    .gr21-col-3xl-8,
    .gr21-col-8,
    .gr21-col-l-8,
    .gr21-col-m-8,
    .gr21-col-xl-8 {
        width: 668px
    }

    .gr21-col-2xl-9,
    .gr21-col-3xl-9,
    .gr21-col-9,
    .gr21-col-l-9,
    .gr21-col-m-9,
    .gr21-col-xl-9 {
        width: 754px
    }

    .gr21-col-2xl-10,
    .gr21-col-3xl-10,
    .gr21-col-10,
    .gr21-col-l-10,
    .gr21-col-m-10,
    .gr21-col-xl-10 {
        width: 840px
    }

    .gr21-col-2xl-11,
    .gr21-col-3xl-11,
    .gr21-col-11,
    .gr21-col-l-11,
    .gr21-col-m-11,
    .gr21-col-xl-11 {
        width: 926px
    }

    .gr21-col-2xl-12,
    .gr21-col-3xl-12,
    .gr21-col-12,
    .gr21-col-l-12,
    .gr21-col-m-12,
    .gr21-col-xl-12 {
        width: 1012px
    }

    .gr21-col-2xl-13,
    .gr21-col-3xl-13,
    .gr21-col-13,
    .gr21-col-l-13,
    .gr21-col-m-13,
    .gr21-col-xl-13 {
        width: 1098px
    }

    .gr21-col-2xl-14,
    .gr21-col-3xl-14,
    .gr21-col-14,
    .gr21-col-l-14,
    .gr21-col-m-14,
    .gr21-col-xl-14 {
        width: 1184px
    }

    .gr21-col-2xl-15,
    .gr21-col-3xl-15,
    .gr21-col-15,
    .gr21-col-l-15,
    .gr21-col-m-15,
    .gr21-col-xl-15 {
        width: 1270px
    }

    .gr21-col-2xl-16,
    .gr21-col-3xl-16,
    .gr21-col-16,
    .gr21-col-l-16,
    .gr21-col-m-16,
    .gr21-col-xl-16 {
        width: 1356px
    }

    [class*=gr21-col-]:not(:first-child) {
        margin-left: 20px
    }
}

@media (min-width:1920px) {

    .gr21-col-1,
    .gr21-col-2xl-1,
    .gr21-col-3xl-1,
    .gr21-col-4xl-1,
    .gr21-col-l-1,
    .gr21-col-m-1,
    .gr21-col-xl-1 {
        width: 78px
    }

    .gr21-col-2,
    .gr21-col-2xl-2,
    .gr21-col-3xl-2,
    .gr21-col-4xl-2,
    .gr21-col-l-2,
    .gr21-col-m-2,
    .gr21-col-xl-2 {
        width: 176px
    }

    .gr21-col-2xl-3,
    .gr21-col-3,
    .gr21-col-3xl-3,
    .gr21-col-4xl-3,
    .gr21-col-l-3,
    .gr21-col-m-3,
    .gr21-col-xl-3 {
        width: 274px
    }

    .gr21-col-2xl-4,
    .gr21-col-3xl-4,
    .gr21-col-4,
    .gr21-col-4xl-4,
    .gr21-col-l-4,
    .gr21-col-m-4,
    .gr21-col-xl-4 {
        width: 372px
    }

    .gr21-col-2xl-5,
    .gr21-col-3xl-5,
    .gr21-col-4xl-5,
    .gr21-col-5,
    .gr21-col-l-5,
    .gr21-col-m-5,
    .gr21-col-xl-5 {
        width: 470px
    }

    .gr21-col-2xl-6,
    .gr21-col-3xl-6,
    .gr21-col-4xl-6,
    .gr21-col-6,
    .gr21-col-l-6,
    .gr21-col-m-6,
    .gr21-col-xl-6 {
        width: 568px
    }

    .gr21-col-2xl-7,
    .gr21-col-3xl-7,
    .gr21-col-4xl-7,
    .gr21-col-7,
    .gr21-col-l-7,
    .gr21-col-m-7,
    .gr21-col-xl-7 {
        width: 666px
    }

    .gr21-col-2xl-8,
    .gr21-col-3xl-8,
    .gr21-col-4xl-8,
    .gr21-col-8,
    .gr21-col-l-8,
    .gr21-col-m-8,
    .gr21-col-xl-8 {
        width: 764px
    }

    .gr21-col-2xl-9,
    .gr21-col-3xl-9,
    .gr21-col-4xl-9,
    .gr21-col-9,
    .gr21-col-l-9,
    .gr21-col-m-9,
    .gr21-col-xl-9 {
        width: 862px
    }

    .gr21-col-2xl-10,
    .gr21-col-3xl-10,
    .gr21-col-4xl-10,
    .gr21-col-10,
    .gr21-col-l-10,
    .gr21-col-m-10,
    .gr21-col-xl-10 {
        width: 960px
    }

    .gr21-col-2xl-11,
    .gr21-col-3xl-11,
    .gr21-col-4xl-11,
    .gr21-col-11,
    .gr21-col-l-11,
    .gr21-col-m-11,
    .gr21-col-xl-11 {
        width: 1058px
    }

    .gr21-col-2xl-12,
    .gr21-col-3xl-12,
    .gr21-col-4xl-12,
    .gr21-col-12,
    .gr21-col-l-12,
    .gr21-col-m-12,
    .gr21-col-xl-12 {
        width: 1156px
    }

    .gr21-col-2xl-13,
    .gr21-col-3xl-13,
    .gr21-col-4xl-13,
    .gr21-col-13,
    .gr21-col-l-13,
    .gr21-col-m-13,
    .gr21-col-xl-13 {
        width: 1254px
    }

    .gr21-col-2xl-14,
    .gr21-col-3xl-14,
    .gr21-col-4xl-14,
    .gr21-col-14,
    .gr21-col-l-14,
    .gr21-col-m-14,
    .gr21-col-xl-14 {
        width: 1352px
    }

    .gr21-col-2xl-15,
    .gr21-col-3xl-15,
    .gr21-col-4xl-15,
    .gr21-col-15,
    .gr21-col-l-15,
    .gr21-col-m-15,
    .gr21-col-xl-15 {
        width: 1450px
    }

    .gr21-col-2xl-16,
    .gr21-col-3xl-16,
    .gr21-col-4xl-16,
    .gr21-col-16,
    .gr21-col-l-16,
    .gr21-col-m-16,
    .gr21-col-xl-16 {
        width: 1548px
    }

    [class*=gr21-col-]:not(:first-child) {
        margin-left: 20px
    }
}

[class*=gr21-col-].gr21-col-no-margin,
[class*=gr21-col-].gr21-col-no-margin-4xl {
    margin-left: 0
}

@media (max-width:1919px) {
    [class*=gr21-col-].gr21-col-no-margin-3xl {
        margin-left: 0
    }
}

@media (max-width:1679px) {
    [class*=gr21-col-].gr21-col-no-margin-xl {
        margin-left: 0
    }
}

@media (max-width:1439px) {
    [class*=gr21-col-].gr21-col-no-margin-xl {
        margin-left: 0
    }
}

@media (max-width:1279px) {
    [class*=gr21-col-].gr21-col-no-margin-l {
        margin-left: 0
    }
}

@media (max-width:1024px) {
    [class*=gr21-col-].gr21-col-no-margin-m {
        margin-left: 0
    }
}

@media (max-width:767px) {
    [class*=gr21-col-].gr21-col-no-margin-s {
        margin-left: 0
    }
}

@media (max-width:1024px) {
    .gr21-col-4.gr21-col-full-width-m {
        width: 100vw;
        position: relative;
        left: 50%;
        margin-left: -50vw
    }
}

.gr21-aos {
    visibility: hidden
}

.gr21-loading-splash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1022;
    transition: transform .3s cubic-bezier(.32, 0, .67, 0) 1.4s;
    transform: translateZ(0)
}

.gr21-loading-splash .layer1,
.gr21-loading-splash .layer2,
.gr21-loading-splash .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center
}

.gr21-loading-splash .layer1 {
    transition: transform .5s cubic-bezier(.83, 0, .17, 1) .3s;
    transform: translateZ(0)
}

.gr21-loading-splash .layer2 {
    transition: transform .5s cubic-bezier(.83, 0, .17, 1) .8s;
    transform: translateZ(0)
}

.gr21-loading-splash .title {
    justify-content: center;
    opacity: 0;
    transition: opacity .2s
}

.gr21-loading-splash .title svg {
    fill: #fff;
    height: 90px
}

.gr21-loading-splash.start,
.gr21-loading-splash.start .layer1,
.gr21-loading-splash.start .layer2 {
    transform: translate3d(0, -100%, 0)
}

.gr21-loading-splash.start .title {
    opacity: 1
}

@-webkit-keyframes wipeInUp {
    0% {
        -webkit-clip-path: inset(100% 0 0 0);
        clip-path: inset(100% 0 0 0)
    }

    to {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0)
    }
}

@keyframes wipeInUp {
    0% {
        -webkit-clip-path: inset(100% 0 0 0);
        clip-path: inset(100% 0 0 0)
    }

    to {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0)
    }
}

.section-hr___2HLly {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

@media (max-width:1024px) {
    .desktop___2OvF5 {
        display: none !important
    }
}

@media (min-width:1025px) {
    .mobile___13GEt {
        display: none !important
    }
}

.feature-row___kJReD {
    width: 100%;
    display: block;
    padding: 32px 0;
    border-bottom: 2px solid #e5e9f0
}

.feature-row___kJReD .feature-col1___3jwE- h3 {
    margin-bottom: 8px
}

.feature-row___kJReD .feature-col2___TVmDM h3 {
    margin-bottom: 18px
}

.feature-row___kJReD .feature-col3___2U73l h6 {
    margin-bottom: 0
}

.feature-row___kJReD:last-child {
    border-bottom: 0
}

@media (min-width:1025px) {
    .feature-row___kJReD {
        display: flex;
        padding: 48px 0
    }

    .feature-row___kJReD .feature-col1___3jwE- h3,
    .feature-row___kJReD .feature-col2___TVmDM h3,
    .feature-row___kJReD .feature-col3___2U73l h6 {
        margin-bottom: 0
    }

    .feature-row___kJReD>div {
        display: flex;
        align-items: center
    }
}

.fw-button___B0CJs {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 72px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px
}

.fw-button___B0CJs dl,
.fw-button___B0CJs h1,
.fw-button___B0CJs h2,
.fw-button___B0CJs h3,
.fw-button___B0CJs h4,
.fw-button___B0CJs h5,
.fw-button___B0CJs h6,
.fw-button___B0CJs ol,
.fw-button___B0CJs p,
.fw-button___B0CJs ul {
    margin-top: 0;
    margin-bottom: 0
}

@media (min-width:768px) {
    .fw-button___B0CJs {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .fw-button___B0CJs {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .fw-button___B0CJs {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .fw-button___B0CJs {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .fw-button___B0CJs {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .fw-button___B0CJs {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.fw-button___B0CJs p.text___1IRRf {
    color: #fff;
    text-transform: capitalize
}

.fw-button___B0CJs .background___14xZF {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1
}

.fw-button___B0CJs .background___14xZF.cta-primary___xMeog {
    background-color: #00b14f
}

.fw-button___B0CJs .background___14xZF.cta-secondary___1Puie {
    background-color: #002e27
}

.fw-button___B0CJs .background___14xZF.safety___10U_W {
    background-color: #008388
}

.fw-button___B0CJs .background___14xZF.blue-chip___xxNX- {
    background-color: #0f2d2f
}

@media (max-width:1024px) {
    .mobile___3d3QE {
        display: inline
    }

    .desktop___Bg3MA {
        display: none
    }
}

@media (min-width:1025px) {
    .fw-button___B0CJs {
        height: 80px
    }

    .mobile___3d3QE {
        display: none
    }

    .desktop___Bg3MA {
        display: inline
    }
}

@media (min-width:1680px) {
    .fw-button___B0CJs {
        height: 88px
    }
}

.hero-wrapper___20BVa {
    display: flex;
    flex-wrap: wrap
}

.hero-wrapper___20BVa .title-wrapper___2mGBF .hero-cta___Sfjzc a p {
    white-space: nowrap
}

.img-wrapper___1oG4K {
    width: 100%
}

.img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 75%
}

@media (min-width:768px) {
    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) {
    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        padding-top: 84.76563%
    }
}

@media (min-width:1280px) {
    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        padding-top: 84.7619%
    }
}

@media (min-width:1440px) {
    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        padding-top: 84.78873%
    }
}

@media (min-width:1680px) {
    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        padding-top: 84.81928%
    }
}

@media (min-width:1920px) {
    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        padding-top: 84.84211%
    }
}

.img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
    display: none
}

@media (max-width:1024px) {
    .hero-wrapper___20BVa {
        margin-top: 40px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .title-h1___2A0ZC {
        margin-top: 16px;
        font-size: 40px;
        line-height: 48px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .desc___1WPBJ {
        margin-top: 16px;
        font-size: 20px;
        line-height: 28px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .hero-cta___Sfjzc {
        margin-top: 24px;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .hero-cta___Sfjzc a {
        margin-top: 8px;
        width: 100%;
        justify-content: center
    }
}

@media (max-width:767px) {
    .hero-wrapper___20BVa .title-wrapper___2mGBF {
        margin: 0 20px
    }
}

@media (min-width:1025px) {
    .hero-wrapper___20BVa .title-wrapper___2mGBF {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        align-content: center
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .title-h1___2A0ZC {
        margin-top: 16px;
        font-size: 44px;
        line-height: 52px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .desc___1WPBJ {
        margin-top: 16px;
        font-size: 24px;
        line-height: 32px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .hero-cta___Sfjzc {
        display: flex;
        flex-flow: wrap;
        gap: 8px;
        width: 100%;
        margin-top: 32px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF h1,
    .hero-wrapper___20BVa .title-wrapper___2mGBF h6 {
        margin-bottom: 0
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF h1 {
        margin-top: 32px
    }

    .img-wrapper___1oG4K {
        flex: 1;
        margin-left: 20px
    }

    .img-wrapper___1oG4K .mobile-img-wrapper___2JOLr {
        display: none
    }

    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        display: inherit;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 75%
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        padding-top: 84.76563%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        padding-top: 84.7619%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        padding-top: 84.78873%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        padding-top: 84.81928%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .img-wrapper___1oG4K .desktop-img-wrapper___1fuZD {
        padding-top: 84.84211%
    }
}

@media (min-width:1440px) {
    .hero-wrapper___20BVa .title-wrapper___2mGBF .title-h1___2A0ZC {
        font-size: 56px;
        line-height: 64px
    }

    .hero-wrapper___20BVa .title-wrapper___2mGBF .desc___1WPBJ {
        margin-top: 16px;
        font-size: 32px;
        line-height: 40px
    }
}

.hero-wrapper___1u_Q6 {
    display: flex;
    flex-wrap: wrap
}

.img-wrapper___1oelF {
    width: 100%
}

.img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 75%
}

@media (min-width:768px) {
    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) {
    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        padding-top: 84.76563%
    }
}

@media (min-width:1280px) {
    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        padding-top: 84.7619%
    }
}

@media (min-width:1440px) {
    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        padding-top: 84.78873%
    }
}

@media (min-width:1680px) {
    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        padding-top: 84.81928%
    }
}

@media (min-width:1920px) {
    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        padding-top: 84.84211%
    }
}

.img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
    display: none
}

@media (max-width:1024px) {
    .hero-wrapper___1u_Q6 {
        margin-top: 64px
    }
}

@media (max-width:767px) {
    .hero-wrapper___1u_Q6 .title-wrapper___2zdSF {
        margin: 0 20px
    }
}

@media (min-width:1025px) {
    .hero-wrapper___1u_Q6 .title-wrapper___2zdSF {
        display: flex;
        align-items: flex-end;
        align-content: flex-end;
        padding-bottom: 64px
    }

    .hero-wrapper___1u_Q6 .title-wrapper___2zdSF h1,
    .hero-wrapper___1u_Q6 .title-wrapper___2zdSF h6 {
        margin-bottom: 0
    }

    .hero-wrapper___1u_Q6 .title-wrapper___2zdSF h1 {
        margin-top: 32px
    }

    .img-wrapper___1oelF {
        flex: 1;
        margin-left: 20px
    }

    .img-wrapper___1oelF .mobile-img-wrapper___2JKEa {
        display: none
    }

    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        display: inherit;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 75%
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        padding-top: 84.76563%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        padding-top: 84.7619%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        padding-top: 84.78873%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        padding-top: 84.81928%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .img-wrapper___1oelF .desktop-img-wrapper___3DCjj {
        padding-top: 84.84211%
    }
}

.desc-wrapper___1Z8Zm,
.hero-wrapper___2fC81 {
    display: flex;
    flex-wrap: wrap
}

.hero-wrapper___2fC81 .title-wrapper___3OXE- h1 {
    margin-bottom: 32px
}

.img-wrapper___Y2rgw {
    width: 100%
}

.img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp {
    display: grid;
    overflow: hidden
}

.img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 75%;
    width: 112%;
    height: 112%
}

@media (min-width:768px) {
    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) {
    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
        padding-top: 84.76563%
    }
}

@media (min-width:1280px) {
    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
        padding-top: 84.7619%
    }
}

@media (min-width:1440px) {
    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
        padding-top: 84.78873%
    }
}

@media (min-width:1680px) {
    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
        padding-top: 84.81928%
    }
}

@media (min-width:1920px) {
    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp .mobile-img___2GbQB {
        padding-top: 84.84211%
    }
}

.img-wrapper___Y2rgw .desktop-img-wrapper___1OPru {
    display: none
}

.desc-wrapper___1Z8Zm {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px
}

@media (min-width:768px) {
    .desc-wrapper___1Z8Zm {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .desc-wrapper___1Z8Zm {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .desc-wrapper___1Z8Zm {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .desc-wrapper___1Z8Zm {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .desc-wrapper___1Z8Zm {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .desc-wrapper___1Z8Zm {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.desc-wrapper___1Z8Zm h4 {
    margin-bottom: 0
}

@media (max-width:1024px) {
    .hero-wrapper___2fC81 {
        margin-top: 64px
    }
}

@media (max-width:767px) {
    .hero-wrapper___2fC81 .title-wrapper___3OXE- {
        margin: 0 20px
    }
}

@media (min-width:1025px) {
    .hero-wrapper___2fC81 .title-wrapper___3OXE- {
        display: flex;
        align-items: flex-end;
        align-content: flex-end
    }

    .hero-wrapper___2fC81 .title-wrapper___3OXE- h1,
    .hero-wrapper___2fC81 .title-wrapper___3OXE- h6 {
        margin-bottom: 0
    }

    .hero-wrapper___2fC81 .title-wrapper___3OXE- h1 {
        margin-bottom: -14px
    }

    .img-wrapper___Y2rgw {
        flex: 1;
        margin-left: 20px
    }

    .img-wrapper___Y2rgw .mobile-img-wrapper___lkSGp {
        display: none
    }

    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru {
        display: grid;
        overflow: hidden
    }

    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 75%;
        width: 112%;
        height: 112%
    }

    .desc-wrapper___1Z8Zm {
        padding-top: 48px
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        padding-top: 84.76563%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        padding-top: 84.7619%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        padding-top: 84.78873%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        padding-top: 84.81928%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .img-wrapper___Y2rgw .desktop-img-wrapper___1OPru .desktop-img___hwJoQ {
        padding-top: 84.84211%
    }
}

.img-wrapper___3Bp0f {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative
}

@media (min-width:768px) {
    .img-wrapper___3Bp0f {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .img-wrapper___3Bp0f {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .img-wrapper___3Bp0f {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .img-wrapper___3Bp0f {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .img-wrapper___3Bp0f {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .img-wrapper___3Bp0f {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.img-wrapper___3Bp0f .background___ud7f0 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(180deg, #fff, #fff 50%, #f9f6f6 0, #f9f6f6)
}

.img-wrapper___3Bp0f img {
    width: 100%
}

.img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
    display: none
}

.img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
    display: inherit;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 75%
}

@media (min-width:768px) {
    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        padding-top: 41.66667%
    }
}

@media (min-width:1025px) {
    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        padding-top: 41.86047%
    }
}

.desc-wrapper___2hXqf {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background: #f9f6f6;
    padding-bottom: 96px !important
}

@media (min-width:768px) {
    .desc-wrapper___2hXqf {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .desc-wrapper___2hXqf {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .desc-wrapper___2hXqf {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .desc-wrapper___2hXqf {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .desc-wrapper___2hXqf {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .desc-wrapper___2hXqf {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.desc-wrapper___2hXqf>p {
    padding: 48px 12.72% 96px;
    background: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    color: #242a2e
}

@media (min-width:1025px) {
    .desc-wrapper___2hXqf>p {
        font-size: 1.75rem;
        line-height: 2.25rem
    }
}

@media (min-width:1280px) {
    .desc-wrapper___2hXqf>p {
        font-size: 2rem;
        line-height: 2.5rem
    }
}

@media (min-width:1680px) {
    .desc-wrapper___2hXqf>p {
        font-size: 2.25rem;
        line-height: 2.75rem
    }
}

@media (max-width:1024px) {

    .desc-wrapper___2hXqf,
    .img-wrapper___3Bp0f {
        padding: 0 !important
    }

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px
    }

    .desc-wrapper___2hXqf>div {
        margin-top: 24px;
        margin-bottom: 48px
    }

    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        display: inherit;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 75%
    }

    .img-wrapper___3Bp0f .desktop-img-wrapper___1C24N {
        display: none
    }
}

@media (max-width:1024px) and (min-width:768px) {

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (max-width:1024px) and (min-width:1025px) {

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (max-width:1024px) and (min-width:1280px) {

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (max-width:1024px) and (min-width:1440px) {

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (max-width:1024px) and (min-width:1680px) {

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (max-width:1024px) and (min-width:1920px) {

    .desc-wrapper___2hXqf>div,
    .img-wrapper___3Bp0f>div {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

@media (max-width:1024px) and (min-width:768px) {
    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 41.66667%
    }
}

@media (max-width:1024px) and (min-width:1025px) {
    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 41.86047%
    }
}

@media (max-width:1024px) and (min-width:1280px) {
    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 41.75627%
    }
}

@media (max-width:1024px) and (min-width:1440px) {
    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 41.75258%
    }
}

@media (max-width:1024px) and (min-width:1680px) {
    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 41.88791%
    }
}

@media (max-width:1024px) and (min-width:1920px) {
    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 41.86047%
    }
}

@media (max-width:767px) {
    .desc-wrapper___2hXqf>div {
        padding: 0 20px
    }

    .img-wrapper___3Bp0f .mobile-img-wrapper___2hnKv {
        padding-top: 75%
    }
}

.options-slider___14YAq {
    padding-top: 48px
}

.options-slider___14YAq .option-item___1KYIa {
    width: calc(100vw - 82px);
    margin-left: 20px;
    margin-right: 22px;
    max-width: 512px
}

.options-slider___14YAq .option-item___1KYIa .option-index___3WXyg {
    margin-bottom: 16px;
    font-size: 14px;
    height: 20px
}

.options-slider___14YAq .option-item___1KYIa .option-title___3P_UR {
    margin: 16px 0 0
}

.options-slider___14YAq .option-item___1KYIa .option-title___3P_UR+p {
    margin: 8px 0 0
}

.options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
    width: 100%;
    background-color: #f0e5e4;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
        padding-top: 48.4375%
    }
}

@media (min-width:1025px) {
    .options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
        padding-top: 55.77889%
    }
}

@media (min-width:1280px) {
    .options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
        padding-top: 55.89354%
    }
}

@media (min-width:1440px) {
    .options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
        padding-top: 56%
    }
}

@media (min-width:1680px) {
    .options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
        padding-top: 56.03715%
    }
}

@media (min-width:1920px) {
    .options-slider___14YAq .option-item___1KYIa .option-banner-mobile___32Tr5 {
        padding-top: 56.06469%
    }
}

.options-slider___14YAq .option-item___1KYIa .option-pagi___2t4_o {
    display: none
}

.options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
    background-color: #f0e5e4;
    width: 100%;
    height: 202px
}

@media (min-width:768px) {
    .options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
        height: 372px
    }
}

@media (min-width:1025px) {
    .options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
        height: 236px
    }
}

@media (min-width:1280px) {
    .options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
        height: 308px
    }
}

@media (min-width:1440px) {
    .options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
        height: 320px
    }
}

@media (min-width:1680px) {
    .options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
        height: 380px
    }
}

@media (min-width:1920px) {
    .options-slider___14YAq .option-item___1KYIa .img-wrapper___34TVl {
        height: 432px
    }
}

.options-slider___14YAq .uk-active .option-item___1KYIa .option-pagi___2t4_o {
    display: block
}

@media (min-width:768px) {
    .options-slider___14YAq .option-item___1KYIa {
        margin-left: calc(50vw - 344px);
        margin-right: 0
    }
}

@media (min-width:1025px) {
    .options-slider___14YAq {
        display: none
    }
}

.round-button___2cysa {
    height: 56px;
    max-width: 320px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.round-button___2cysa dl,
.round-button___2cysa h1,
.round-button___2cysa h2,
.round-button___2cysa h3,
.round-button___2cysa h4,
.round-button___2cysa h5,
.round-button___2cysa h6,
.round-button___2cysa ol,
.round-button___2cysa p,
.round-button___2cysa ul {
    margin-top: 0;
    margin-bottom: 0
}

.round-button___2cysa .text___2QfwM {
    font-size: 16px;
    line-height: 24px
}

.round-button___2cysa.cta-primary___3ikdi {
    background-color: #00b14f
}

.round-button___2cysa.cta-primary___3ikdi p.text___2QfwM {
    color: #fff;
    text-transform: capitalize
}

.round-button___2cysa.cta-primary___3ikdi:hover {
    background-color: #00804a
}

.round-button___2cysa.cta-primary___3ikdi:active {
    background-color: #005339
}

.round-button___2cysa.cta-secondary___zVgLV {
    background-color: #eef9f9
}

.round-button___2cysa.cta-secondary___zVgLV p.text___2QfwM {
    color: #184440;
    text-transform: capitalize
}

.round-button___2cysa.cta-secondary___zVgLV:hover {
    background-color: #d2edea
}

.round-button___2cysa.cta-secondary___zVgLV:active {
    background-color: #2d9f94
}

.round-button___2cysa.cta-secondary___zVgLV:active p.text___2QfwM {
    color: #fff
}

.round-button___2cysa.cta-white___3bbz6 {
    background-color: #fff
}

.round-button___2cysa.cta-white___3bbz6 p.text___2QfwM {
    color: #03633e;
    text-transform: capitalize
}

.round-button___2cysa.cta-white___3bbz6:hover {
    background-color: #d9fcde
}

.round-button___2cysa.cta-white___3bbz6:active {
    background-color: #c6f4cc
}

@media (max-width:1024px) {
    .mobile___H3Ghy {
        display: inline
    }

    .desktop___3_fZQ {
        display: none
    }
}

@media (min-width:1025px) {
    .mobile___H3Ghy {
        display: none
    }

    .desktop___3_fZQ {
        display: inline
    }
}

.subtitle-wrapper___y-82B {
    padding-top: 64px;
    padding-bottom: 64px
}

@media (max-width:1024px) {
    .subtitle-wrapper___y-82B .subtitle-h4___lxLfY {
        margin-top: 8px;
    }
}

@media (min-width:1025px) {
    .subtitle-wrapper___y-82B {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .subtitle-wrapper___y-82B.half-button-exp___QFhPP {
        padding-top: 48px
    }
}

.video-player___9HM70 {
    position: relative
}

.video-inner___B4cR5 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .video-inner___B4cR5 {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .video-inner___B4cR5 {
        padding-top: 56.19048%
    }
}

@media (min-width:1280px) {
    .video-inner___B4cR5 {
        padding-top: 56.20438%
    }
}

@media (min-width:1440px) {
    .video-inner___B4cR5 {
        padding-top: 55.94406%
    }
}

@media (min-width:1680px) {
    .video-inner___B4cR5 {
        padding-top: 56.88623%
    }
}

@media (min-width:1920px) {
    .video-inner___B4cR5 {
        padding-top: 56.5445%
    }
}

.video-inner___B4cR5 .play-btn___3gau0 {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background-color: #00b14f;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 42px);
    left: calc(50% - 42px);
    cursor: pointer
}

@media (min-width:1025px) {
    .video-inner___B4cR5 {
        margin-left: 0;
        margin-right: 0
    }

    .video-inner___B4cR5 .play-btn___3gau0 {
        width: 101px;
        height: 101px;
        border-radius: 50%;
        background-color: #00b14f;
        color: #fff;
        top: calc(50% - 50px);
        left: calc(50% - 50px)
    }
}

.country-link___2mAV8 {
    color: #00b14f
}

.country-link___2mAV8:hover {
    cursor: pointer
}

.segment-wrapper___TTRm3.segment-new___21Mxz {
    margin-top: 64px;
    border-top: 2px solid #242a2e;
    padding-top: 32px
}

.segment-wrapper___TTRm3.segment-new-dt___cdhkC {
    margin-top: 64px
}

.segment-wrapper___TTRm3.segment-sub___1sgmX {
    margin-top: 56px;
    border-top: 2px solid #e5e9f0;
    padding-top: 32px
}

.segment-wrapper___TTRm3.segment-sub-l___2uvaI,
.segment-wrapper___TTRm3.segment-sub-m___2tahQ,
.segment-wrapper___TTRm3.segment-sub-s___2EnlV {
    margin-top: 32px;
    border-top: 2px solid #e5e9f0;
    padding-top: 32px
}

.segment-wrapper___TTRm3.segment-sub-new___3TIYK {
    margin-top: 56px
}

.segment-wrapper___TTRm3.segment-cont___h7ga5 {
    margin-top: 48px
}

.segment-wrapper___TTRm3.segment-cont___h7ga5 .data-title___GDXnK {
    margin-top: 8px
}

.segment-wrapper___TTRm3.segment-cont-l___3mwiV {
    margin-top: 64px
}

.segment-wrapper___TTRm3.segment-cont-m___2TtVS,
.segment-wrapper___TTRm3.segment-cont-s___1c6PV {
    margin-top: 32px
}

.segment-wrapper___TTRm3.segment-cont-link___9MO3c {
    margin-top: 24px
}

.segment-wrapper___TTRm3.segment-bottom___22ox- {
    margin-top: 64px;
    border-top: 2px solid #e5e9f0;
    padding-top: 16px;
    padding-bottom: 24px
}

.segment-wrapper___TTRm3.segment-sub-half___3cc8S {
    margin-top: 32px
}

.segment-wrapper___TTRm3.segment-sub-half___3cc8S .hr-light___32-T4 {
    border-top: 2px solid #e5e9f0;
    padding-top: 32px
}

.segment-wrapper___TTRm3 .text-media-above___qC-cN,
.segment-wrapper___TTRm3.text-media-above___qC-cN {
    margin-bottom: 32px
}

.segment-wrapper___TTRm3 .text-media-below___2PULX,
.segment-wrapper___TTRm3.text-media-below___2PULX {
    margin-top: 32px
}

.segment-wrapper___TTRm3 .text-media-desktop___1xCM9 {
    margin-top: 48px
}

.segment-wrapper___TTRm3 .link___2GF4c {
    margin-top: 24px;
    text-transform: capitalize
}

.segment-wrapper___TTRm3 .link2___2RTzD {
    margin-top: 16px;
    text-transform: capitalize
}

.segment-wrapper___TTRm3 .link___2GF4c.border___267uQ {
    margin-top: 32px;
    border-top: 1px solid #00804a;
    border-bottom: 1px solid #00804a
}

.segment-wrapper___TTRm3 .link___2GF4c.border___267uQ>a>div {
    color: #00804a;
    padding: 16px 0
}

.segment-wrapper___TTRm3 .data-title-desc___1EOCg,
.segment-wrapper___TTRm3 .three-desc___16u2B {
    margin-bottom: 56px
}

.segment-wrapper___TTRm3 .data-suffix___3BXlg {
    margin-top: 16px
}

.segment-wrapper___TTRm3 .data-desc___1Op_2 {
    margin-top: 16px;
    border-top: 2px solid #e5e9f0;
    padding-top: 16px
}

.segment-wrapper___TTRm3 .ttm-wrapper___exSfh {
    display: flex
}

.segment-wrapper___TTRm3 .ttm-open-quote___1CM_t {
    margin-right: 1px
}

.segment-wrapper___TTRm3 .ttm-name___3wEeY {
    margin-top: 32px
}

.segment-wrapper___TTRm3 .ttm-job-title___SEZc- {
    margin-top: 2px
}

.segment-wrapper___TTRm3 .case-content___3-lLF .video___2DX9R.desktop___2Uwqy {
    margin-top: 48px
}

.segment-wrapper___TTRm3 .case-eyebrow___3GKQ2 {
    margin-bottom: 32px
}

.segment-wrapper___TTRm3 .toggle-hidden-text___2FqRU {
    display: none
}

.segment-wrapper___TTRm3 .toggle-btn___2fBaN {
    margin-top: 24px
}

.segment-wrapper___TTRm3 .toggle-btn___2fBaN .toggle-hide-btn___38W1h,
.segment-wrapper___TTRm3 .toggle-btn___2fBaN .toggle-show-btn___1J2AW {
    color: #00804a;
    cursor: pointer;
    text-transform: capitalize
}

.segment-wrapper___TTRm3 .toggle-btn___2fBaN .toggle-hide-btn___38W1h:hover,
.segment-wrapper___TTRm3 .toggle-btn___2fBaN .toggle-show-btn___1J2AW:hover {
    color: #005339
}

.segment-wrapper___TTRm3 .toggle-btn___2fBaN .toggle-hide-btn___38W1h {
    display: none
}

.segment-wrapper___TTRm3 .case-media___1t957.img-wrapper___3STv_ {
    margin-top: 48px
}

.segment-wrapper___TTRm3 .three-row___3q2E0 {
    border-top: 2px solid #e5e9f0;
    padding-top: 16px
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .desc___2hSRW,
.segment-wrapper___TTRm3 .three-row___3q2E0~.three-row___3q2E0 {
    margin-top: 16px
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .desc___2hSRW:first-child {
    margin-top: 18px
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .rich-text___1O6YP,
.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .rich-text___1O6YP p:not(:first-child),
.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .rich-text___1O6YP ul:not(:first-child) {
    margin-top: 16px
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .link___2GF4c {
    margin-top: 24px
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .link___2GF4c:first-child {
    margin-top: 0
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .link___2GF4c a {
    color: #00804a;
    cursor: pointer
}

.segment-wrapper___TTRm3 .text-wrapper___2sqh_ .link___2GF4c a:hover {
    color: #005339
}

.segment-wrapper___TTRm3 .foot-btn___30zOL {
    cursor: pointer;
    display: flex;
    justify-content: space-between
}

.segment-wrapper___TTRm3 .foot-btn___30zOL p {
    color: #697078
}

.segment-wrapper___TTRm3 .foot-btn___30zOL path {
    fill: #697078
}

.segment-wrapper___TTRm3 .foot-btn___30zOL .foot-icon-hide___1qb-p,
.segment-wrapper___TTRm3 .foot-btn___30zOL .foot-icon-show___1_5MU {
    display: flex
}

.segment-wrapper___TTRm3 .foot-btn___30zOL .foot-btn-hide___2emGH,
.segment-wrapper___TTRm3 .foot-btn___30zOL .foot-icon-hide___1qb-p {
    display: none
}

.segment-wrapper___TTRm3 .foot-btn___30zOL .foot-btn-show___1sQlT {
    display: block
}

.segment-wrapper___TTRm3 .foot-wrapper___FUeq8 {
    display: none
}

.segment-wrapper___TTRm3 .foot-wrapper___FUeq8 .foot-ind___3uzJi {
    padding-right: 16px;
    min-width: 32px;
    max-width: 32px
}

.segment-wrapper___TTRm3 .foot-wrapper___FUeq8 p {
    color: #697078
}

.segment-wrapper___TTRm3 .img-div___1FdYV {
    width: 112%;
    height: 112%
}

.segment-wrapper___TTRm3 .img-div___1FdYV.img-div-no-padding___2ap34 {
    width: 100%;
    height: 100%
}

.segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka,
.segment-wrapper___TTRm3 .img-wrapper___3STv_ {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
.segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {

    .segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
    .segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {

    .segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
    .segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
        padding-top: 56.19048%
    }
}

@media (min-width:1280px) {

    .segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
    .segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
        padding-top: 56.20438%
    }
}

@media (min-width:1440px) {

    .segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
    .segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
        padding-top: 55.94406%
    }
}

@media (min-width:1680px) {

    .segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
    .segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
        padding-top: 56.88623%
    }
}

@media (min-width:1920px) {

    .segment-wrapper___TTRm3 .img-desc-wrapper___Ff0Ka .img-div___1FdYV,
    .segment-wrapper___TTRm3 .img-wrapper___3STv_ .img-div___1FdYV {
        padding-top: 56.5445%
    }
}

.segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-banner-wrapper___2iqlO .img-div___1FdYV {
        padding-top: 41.86047%
    }
}

.segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 63.125%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
        padding-top: 54.06977%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-banner-mb-landscape-wrapper___gCYEZ .img-div___1FdYV {
        padding-top: 41.86047%
    }
}

.segment-wrapper___TTRm3 .company-landing-img-desktop___2pFHA {
    margin-top: 48px
}

.segment-wrapper___TTRm3 .company-landing-left-eyebrow___ZBhus {
    margin-bottom: 48px
}

.segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ {
    margin-top: 32px;
    margin-bottom: 32px;
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
        padding-top: 131.50685%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
        padding-top: 131.2369%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
        padding-top: 131.3253%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
        padding-top: 131.27148%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-mobile___3iMDQ .img-div___1FdYV {
        padding-top: 131.38138%
    }
}

.segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
        padding-top: 131.50685%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
        padding-top: 131.2369%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
        padding-top: 131.3253%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
        padding-top: 131.27148%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-left___1f-q5 .img-div___1FdYV {
        padding-top: 131.38138%
    }
}

.segment-wrapper___TTRm3.img-company-right-wrapper___2hzhn {
    display: flex;
    flex-wrap: wrap
}

.segment-wrapper___TTRm3.img-company-right-wrapper___2hzhn .img-company-right-inner-wrapper___3Lv7e {
    flex: 1;
    margin-left: 20px;
    width: 100%
}

.segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
        padding-top: 84.76563%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
        padding-top: 84.7619%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
        padding-top: 84.78873%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
        padding-top: 84.81928%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-right___3q-Ti .img-div___1FdYV {
        padding-top: 84.84211%
    }
}

.segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
        padding-top: 41.62791%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
        padding-top: 41.93548%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-intro-keystone-full___3fs_h .img-div___1FdYV {
        padding-top: 41.86047%
    }
}

.segment-wrapper___TTRm3 .img-product-list___3uKYL {
    display: grid;
    overflow: hidden
}

.segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 63.125%
}

@media (min-width:768px) {
    .segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
        padding-top: 54.06977%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
        padding-top: 73%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
        padding-top: 72.72727%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
        padding-top: 73.18841%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
        padding-top: 72.83951%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___TTRm3 .img-product-list___3uKYL .img-div___1FdYV {
        padding-top: 73.11828%
    }
}

@media (max-width:1024px) {

    .segment-wrapper___TTRm3 .desktop___2Uwqy,
    .segment-wrapper___TTRm3.desktop___2Uwqy {
        display: none
    }

    .segment-wrapper___TTRm3 .subtitle_h6___2CU0j {
        margin-bottom: 48px
    }

    .segment-wrapper___TTRm3 .data-title-desc___1EOCg,
    .segment-wrapper___TTRm3 .desc___2hSRW,
    .segment-wrapper___TTRm3 .three-desc___16u2B {
        margin-top: 18px
    }

    .segment-wrapper___TTRm3 .media-desc___VKiOS {
        margin-bottom: 24px
    }

    .segment-wrapper___TTRm3 .media-boxed-desc___1mmjz {
        margin-top: 32px
    }

    .segment-wrapper___TTRm3 .prod-list-title___3uz6N {
        margin-top: 16px
    }
}

@media (min-width:1025px) {
    .segment-wrapper___TTRm3.segment-new___21Mxz {
        margin-top: 96px;
        padding-top: 48px
    }

    .segment-wrapper___TTRm3.segment-new-dt___cdhkC {
        margin-top: 96px;
        border-top: 2px solid #242a2e;
        padding-top: 48px
    }

    .segment-wrapper___TTRm3.segment-sub___1sgmX {
        margin-top: 64px;
        padding-top: 64px
    }

    .segment-wrapper___TTRm3.segment-sub-l___2uvaI {
        margin-top: 64px;
        padding-top: 48px
    }

    .segment-wrapper___TTRm3.segment-sub-m___2tahQ {
        margin-top: 48px;
        padding-top: 48px
    }

    .segment-wrapper___TTRm3.segment-sub-s___2EnlV {
        margin-top: 32px;
        padding-top: 32px
    }

    .segment-wrapper___TTRm3.segment-sub-new___3TIYK {
        margin-top: 64px;
        padding-top: 32px
    }

    .segment-wrapper___TTRm3.segment-cont-l___3mwiV,
    .segment-wrapper___TTRm3.segment-cont-m___2TtVS,
    .segment-wrapper___TTRm3.segment-cont___h7ga5 {
        margin-top: 64px
    }

    .segment-wrapper___TTRm3.segment-cont-l___3mwiV .data-title___GDXnK,
    .segment-wrapper___TTRm3.segment-cont-m___2TtVS .data-title___GDXnK,
    .segment-wrapper___TTRm3.segment-cont___h7ga5 .data-title___GDXnK {
        margin-top: 0
    }

    .segment-wrapper___TTRm3.segment-cont-s___1c6PV {
        margin-top: 32px
    }

    .segment-wrapper___TTRm3.segment-bottom___22ox- {
        margin-top: 96px;
        border-top: 2px solid #e5e9f0
    }

    .segment-wrapper___TTRm3 .text-wrapper___2sqh_ .desc___2hSRW:first-child,
    .segment-wrapper___TTRm3 .text-wrapper___2sqh_ .link___2GF4c:first-child {
        margin-top: 0
    }

    .segment-wrapper___TTRm3 .text-banner-media-desktop___2UbNy {
        margin-top: 64px
    }

    .segment-wrapper___TTRm3 .data-title-desc___1EOCg,
    .segment-wrapper___TTRm3 .three-desc___16u2B {
        margin-bottom: 64px
    }

    .segment-wrapper___TTRm3 .data-suffix___3BXlg {
        margin-top: 24px
    }

    .segment-wrapper___TTRm3 .ttm-name___3wEeY {
        margin-top: 0
    }

    .segment-wrapper___TTRm3 .ttm-quote___32DTq:before {
        
        position: absolute;
        margin-left: -17px
    }

    .segment-wrapper___TTRm3 .foot-wrapper___FUeq8 .foot-ind___3uzJi {
        min-width: 36px;
        max-width: 36px
    }

    .segment-wrapper___TTRm3 .mobile___2gjjh,
    .segment-wrapper___TTRm3 .tablet-only___2i3QS,
    .segment-wrapper___TTRm3.mobile___2gjjh,
    .segment-wrapper___TTRm3.tablet-only___2i3QS {
        display: none
    }
}

@media (max-width:767px) {

    .segment-wrapper___TTRm3 .desktop-all___2psZy,
    .segment-wrapper___TTRm3 .tablet-only___2i3QS,
    .segment-wrapper___TTRm3.desktop-all___2psZy,
    .segment-wrapper___TTRm3.tablet-only___2i3QS {
        display: none
    }
}

@media (min-width:768px) {

    .segment-wrapper___TTRm3 .mobile-only___360Gv,
    .segment-wrapper___TTRm3.mobile-only___360Gv {
        display: none
    }
}

.slider-mobile___3LgUw .uk-slider-items li {
    display: flex;
    flex-direction: column
}

.slider-mobile___3LgUw .slider-item___lbF4I {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100vw - 82px);
    margin-left: 20px;
    margin-right: 22px;
    max-width: 512px
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-h4___1bCBX {
    margin-top: 16px;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-cta___3x_C2 {
    margin-top: auto;
    width: 100%
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-p2___3-Urn {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.slider-mobile___3LgUw .slider-item___lbF4I .callout1 {
    color: #00804a
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji {
    margin-top: 16px;
    display: grid;
    overflow: hidden
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%;
    width: 112%;
    height: 112%
}

@media (min-width:768px) {
    .slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 48.4375%
    }
}

@media (min-width:1025px) {
    .slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 55.77889%
    }
}

@media (min-width:1280px) {
    .slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 55.89354%
    }
}

@media (min-width:1440px) {
    .slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 56%
    }
}

@media (min-width:1680px) {
    .slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 56.03715%
    }
}

@media (min-width:1920px) {
    .slider-mobile___3LgUw .slider-item___lbF4I .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 56.06469%
    }
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-pagi-div___2xK5x {
    height: 20px
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-pagi___28FK1 {
    display: none
}

.slider-mobile___3LgUw .slider-item___lbF4I .slider-content___SDEFz {
    margin-bottom: 24px
}

.slider-mobile___3LgUw .uk-active .slider-item___lbF4I .slider-pagi___28FK1 {
    display: block
}

.gr-gr21-default .slider-desktop___1IzXw {
    display: none
}

@media (min-width:768px) {
    .slider-mobile___3LgUw .slider-item___lbF4I {
        margin-left: calc(50vw - 344px);
        margin-right: 0
    }

    .slider-mobile___3LgUw .slider-item___lbF4I .slider-pagi___28FK1 {
        display: block
    }
}

@media (min-width:1025px) {
    .slider-actions___CCrQB {
        z-index: 10;
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 100.5px
    }

    .slider-actions___CCrQB a {
        width: 24px;
        float: left
    }

    .slider-actions___CCrQB .slider-right path {
        fill: #242a2e
    }

    .slider-actions___CCrQB .slider-left {
        margin-right: 4px
    }

    .slider-actions___CCrQB .slider-left path {
        fill: #bac0c9
    }

    .slider-actions-no-padding-right___1ebsi {
        padding-right: 0
    }

    .slider-actions-no-padding-right___1ebsi .slider-left {
        margin-right: 4px
    }

    .slider-mobile___3LgUw {
        display: none
    }

    .gr-gr21-default .slider-desktop___1IzXw {
        display: block
    }

    .slider-desktop___1IzXw {
        display: block;
        width: 950px
    }

    .slider-desktop___1IzXw p {
        margin: 0
    }

    .slider-desktop___1IzXw ul {
        margin-left: 0
    }

    .slider-desktop___1IzXw li {
        padding-left: 20px;
        display: flex;
        flex-direction: column
    }

    .slider-desktop___1IzXw li .slider-h4___1bCBX {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
    }

    .slider-desktop___1IzXw li .slider-p2___3-Urn {
        flex-grow: 1
    }

    .slider-desktop___1IzXw li .slider-pagi___28FK1 {
        font-size: 16px;
        line-height: 24px;
        display: block
    }

    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji {
        display: grid;
        overflow: hidden
    }

    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 56.11111%;
        width: 112%;
        height: 112%
    }

    .slider-desktop___1IzXw li .slider-content___SDEFz {
        display: flex;
        flex-grow: 1;
        flex-direction: column
    }

    .slider-desktop___1IzXw .default-item___1BlXV,
    .slider-desktop___1IzXw .slider-item___lbF4I {
        margin-left: 0
    }

    .slider-desktop___1IzXw .default-item___1BlXV {
        border-left: 2px solid #e5e9f0
    }

    .slider-desktop___1IzXw .default-item___1BlXV .slider-h4___1bCBX {
        margin-top: 24px;
        margin-bottom: 8px;
        width: 100%
    }

    .slider-desktop___1IzXw .default-item___1BlXV .slider-cta___3x_C2 {
        display: block;
        margin-top: 64px;
        width: 100%
    }

    .slider-desktop___1IzXw .default-item___1BlXV .slider-p2___3-Urn {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical
    }

    .slider-desktop___1IzXw .default-item___1BlXV .slider-image-wrapper___2x-ji {
        margin-top: 48px
    }

    .slider-desktop___1IzXw .slider-item___lbF4I {
        margin-right: 20px;
        border-left: 2px solid #e5e9f0
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-cta___3x_C2,
    .slider-desktop___1IzXw .slider-item___lbF4I .slider-p2___3-Urn {
        display: none
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-h4___1bCBX {
        margin-bottom: 0;
        width: 100%;
        margin-top: auto
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-image-wrapper___2x-ji {
        margin-top: 140px
    }

    .slider-desktop___1IzXw .current-item {
        cursor: pointer;
        width: 420px
    }

    .slider-desktop___1IzXw .current-item .slider-cta___3x_C2 {
        display: block;
        margin-top: 64px;
        width: 100%
    }

    .slider-desktop___1IzXw .current-item .text-p2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical
    }

    .slider-desktop___1IzXw .current-item .slider-h4___1bCBX {
        margin-top: 24px;
        margin-bottom: 8px;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        color: #242a2e
    }

    .slider-desktop___1IzXw .current-item .slider-image-wrapper___2x-ji {
        margin-top: 48px
    }

    .slider-desktop___1IzXw .current-item .slider-content___SDEFz {
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start
    }

    .slider-desktop___1IzXw .slider-item___lbF4I:not(.current-item) {
        cursor: pointer
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 48.4375%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 55.77889%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 55.89354%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 56%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 56.03715%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .slider-desktop___1IzXw li .slider-image-wrapper___2x-ji .slider-image___39SR7 {
        padding-top: 56.06469%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .slider-desktop___1IzXw .current-item .slider-h4___1bCBX {
        font-size: 1.75rem;
        line-height: 2.25rem
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .slider-desktop___1IzXw .current-item .slider-h4___1bCBX {
        font-size: 2rem;
        line-height: 2.5rem
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .slider-desktop___1IzXw .current-item .slider-h4___1bCBX {
        font-size: 2.25rem;
        line-height: 2.75rem
    }
}

@media (min-width:1280px) {
    .slider-desktop___1IzXw {
        width: 1238px
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-image-wrapper___2x-ji {
        margin-top: 168px
    }

    .slider-desktop___1IzXw .current-item {
        width: 548px
    }

    .slider-desktop___1IzXw .current-item .slider-h4___1bCBX {
        margin-top: 24px
    }

    .slider-desktop___1IzXw .current-item .slider-image-wrapper___2x-ji {
        margin-top: 48px
    }
}

@media (min-width:1440px) {
    .slider-actions___CCrQB {
        padding-right: 128px
    }

    .slider-actions-no-padding-right___1ebsi {
        padding-right: 0
    }

    .slider-desktop___1IzXw {
        width: 1292px
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-image-wrapper___2x-ji {
        margin-top: 172px
    }

    .slider-desktop___1IzXw .current-item {
        width: 572px
    }

    .slider-desktop___1IzXw .current-item .slider-image-wrapper___2x-ji {
        margin-top: 48px
    }
}

@media (min-width:1680px) {
    .slider-desktop___1IzXw {
        width: 1508px
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-image-wrapper___2x-ji {
        margin-top: 192px
    }

    .slider-desktop___1IzXw .current-item {
        width: 668px
    }

    .slider-desktop___1IzXw .current-item .slider-image-wrapper___2x-ji {
        margin-top: 48px
    }
}

@media (min-width:1920px) {
    .slider-desktop___1IzXw {
        width: 1724px
    }

    .slider-desktop___1IzXw .slider-item___lbF4I .slider-image-wrapper___2x-ji {
        margin-top: 212px
    }

    .slider-desktop___1IzXw .current-item {
        width: 764px
    }

    .slider-desktop___1IzXw .current-item .slider-h4___1bCBX {
        margin-top: 24px
    }

    .slider-desktop___1IzXw .current-item .slider-image-wrapper___2x-ji {
        margin-top: 48px
    }
}

.blog-item___o7H_A {
    width: 100%;
    height: auto;
    border-radius: 4px;
    border: none;
    width: calc(33.33333% - 8px);
    margin-right: 24px
}

.blog-item___o7H_A:hover {
    cursor: pointer
}

@media (min-width:768px) {
    .blog-item___o7H_A {
        width: 213.33333px
    }
}

@media (min-width:1025px) {
    .blog-item___o7H_A {
        width: 270.66667px
    }
}

@media (min-width:1280px) {
    .blog-item___o7H_A {
        width: 356px
    }
}

@media (min-width:1440px) {
    .blog-item___o7H_A {
        width: 372px
    }
}

@media (min-width:1680px) {
    .blog-item___o7H_A {
        width: 436px
    }
}

@media (min-width:1920px) {
    .blog-item___o7H_A {
        width: 500px
    }
}

.blog-item___o7H_A:nth-child(3n+3) {
    margin-right: 0
}

.blog-item___o7H_A .image___31pCK {
    width: 100%;
    padding-top: 48%;
    background-size: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-position: 50%
}

.blog-item___o7H_A .content___3xZid {
    border: 1px solid #efefef;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 16px;
    color: #697078
}

.blog-item___o7H_A .content___3xZid .categories___302A3 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #00804a
}

.blog-item___o7H_A .content___3xZid .categories___302A3 .category-link___3kMfX+.category-link___3kMfX {
    position: relative;
    margin-left: 15px
}

.blog-item___o7H_A .content___3xZid .categories___302A3 .category-link___3kMfX+.category-link___3kMfX:before {
    content: "";
    border-left: 1px solid #00804a;
    height: 14px;
    position: absolute;
    left: -8px;
    top: 1px
}

.blog-item___o7H_A .content___3xZid .title___E1hg7 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
    color: #002e27;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px
}

.blog-item___o7H_A .content___3xZid .title___E1hg7:hover {
    color: #00b14f
}

.blog-item___o7H_A .content___3xZid .description___22CY0 {
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #383e43;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 60px
}

.blog-item___o7H_A .content___3xZid .bottom-line___ZL1z1 {
    display: flex;
    justify-content: space-between
}

.blog-item___o7H_A .content___3xZid .bottom-line___ZL1z1 .date___1F1CD {
    width: 130px;
    font-size: 12px;
    margin-right: 10px
}

.blog-item___o7H_A .content___3xZid .bottom-line___ZL1z1 .countries___1D8Nc {
    flex: 1;
    font-size: 12px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media (max-width:1025px) {
    .blog-item___o7H_A {
        width: 100%;
        margin: 0 0 24px
    }

    .blog-item___o7H_A .content___3xZid .title___E1hg7 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 12px
    }

    .blog-item___o7H_A .content___3xZid .description___22CY0 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 12px
    }
}

.color-wrapper___1synS {
    padding-top: 64px;
    padding-bottom: 64px
}

.color-wrapper___1synS.evergreen___nkxi- {
    background-color: #3cb95a
}

.color-wrapper___1synS.blue-chip___1rHlE {
    background-color: #0b4446
}

.color-wrapper___1synS .content___3P1Rv .text___V6Hux {
    color: #fff
}

.color-wrapper___1synS .content___3P1Rv .text___V6Hux.margin-bottom___1VmqT {
    margin-bottom: 48px
}

@media (min-width:1025px) {
    .color-wrapper___1synS {
        padding-top: 72px;
        padding-bottom: 96px
    }

    .color-wrapper___1synS .content___3P1Rv .text___V6Hux.margin-bottom___1VmqT {
        margin-bottom: 0
    }
}

.faq-title___2Vlsv {
    padding-top: 64px;
    padding-bottom: 48px
}

.faq-title___2Vlsv.padding-m___1Kpk_ {
    padding-top: 32px
}

.faq-title___2Vlsv h6 {
    margin-bottom: 0
}

.faqs___1AADM {
    margin-top: 32px;
    padding-bottom: 64px
}

.faqs___1AADM .uk-accordion-title {
    display: flex;
    align-items: center
}

.faqs___1AADM .uk-accordion-title:before {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/grab21/icons/plus.svg);
    position: absolute;
    right: 0
}

.faqs___1AADM .uk-open>.uk-accordion-title:before {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/grab21/icons/minus.svg)
}

.faqs___1AADM .uk-open>.uk-accordion-content {
    position: relative
}

.faqs___1AADM .uk-open>.uk-accordion-content:before {
    background-image: url(https://assets.grab.com/wp-content/uploads/media/grab21/icons/union.svg);
    background-size: contain;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    content: ""
}

.faqs___1AADM .uk-accordion>:nth-child(n+2) {
    margin-top: 0
}

.faqs___1AADM .uk-accordion>li .uk-accordion-title {
    padding: 16px 0;
    font-weight: 400;
    position: relative
}

.faqs___1AADM .uk-accordion>li .uk-accordion-title>span {
    display: block;
    width: 88%
}

.faqs___1AADM .uk-accordion>li {
    border-top: 2px solid #e5e9f0
}

.faqs___1AADM .uk-accordion>li:last-child {
    border-bottom: 2px solid #e5e9f0
}

.faqs___1AADM .uk-accordion>li.uk-open+li {
    border-top: none
}

.faqs___1AADM .uk-accordion>li.uk-open {
    border-top: 2px solid #242a2e;
    border-bottom: 2px solid #242a2e
}

.faqs___1AADM .uk-accordion-content {
    padding: 16px 0 24px 28px;
    margin-top: 0
}

.faq-col1___GzcBu h3+p {
    margin-top: 18px
}

.faqs___1AADM>ul {
    margin-bottom: 0
}

@media (min-width:1025px) {
    .faq-title___2Vlsv {
        padding-top: 96px;
        padding-bottom: 64px
    }

    .faq-title___2Vlsv.padding-m___1Kpk_ {
        padding-top: 48px
    }

    .faqs___1AADM {
        margin-top: 0;
        padding-bottom: 96px
    }

    .faqs___1AADM .uk-accordion>li .uk-accordion-title {
        padding: 20px 0
    }

    .faqs___1AADM .uk-accordion-content {
        padding: 12px 72px 24px 40px;
        margin-top: 0
    }

    .faqs___1AADM .uk-open>.uk-accordion-content:before {
        width: 24px;
        height: 24px
    }
}

.feature-banner-mobile___2m1Vm {
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%
}

@media (min-width:768px) {
    .feature-banner-mobile___2m1Vm {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .feature-banner-mobile___2m1Vm {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .feature-banner-mobile___2m1Vm {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .feature-banner-mobile___2m1Vm {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .feature-banner-mobile___2m1Vm {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .feature-banner-mobile___2m1Vm {
        padding-top: 41.86047%
    }
}

.feature-banner-desktop___3gl0E {
    display: none
}

.feature-title___3uh6X {
    padding-top: 32px;
    padding-bottom: 16px
}

.feature-title___3uh6X h6 {
    margin-bottom: 0
}

.feature-banner___1nnAO {
    padding-top: 32px;
    padding-bottom: 64px
}

@media (min-width:768px) {
    .feature-banner-mobile___2m1Vm {
        display: none
    }

    .feature-banner-desktop___3gl0E {
        display: block;
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 131.25%
    }
}

@media (min-width:768px) and (min-width:768px) {
    .feature-banner-desktop___3gl0E {
        padding-top: 41.86047%
    }
}

@media (min-width:768px) and (min-width:1025px) {
    .feature-banner-desktop___3gl0E {
        padding-top: 41.86047%
    }
}

@media (min-width:768px) and (min-width:1280px) {
    .feature-banner-desktop___3gl0E {
        padding-top: 41.75627%
    }
}

@media (min-width:768px) and (min-width:1440px) {
    .feature-banner-desktop___3gl0E {
        padding-top: 41.75258%
    }
}

@media (min-width:768px) and (min-width:1680px) {
    .feature-banner-desktop___3gl0E {
        padding-top: 41.88791%
    }
}

@media (min-width:768px) and (min-width:1920px) {
    .feature-banner-desktop___3gl0E {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .feature-title___3uh6X {
        padding-top: 48px
    }

    .feature-banner___1nnAO {
        padding-top: 48px;
        padding-bottom: 96px
    }
}

.color-wrapper___23_UY {
    background-color: #00804a
}

.color-wrapper___23_UY .feature-banner-mobile___3GcdR {
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%
}

@media (min-width:768px) {
    .color-wrapper___23_UY .feature-banner-mobile___3GcdR {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .color-wrapper___23_UY .feature-banner-mobile___3GcdR {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .color-wrapper___23_UY .feature-banner-mobile___3GcdR {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .color-wrapper___23_UY .feature-banner-mobile___3GcdR {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .color-wrapper___23_UY .feature-banner-mobile___3GcdR {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .color-wrapper___23_UY .feature-banner-mobile___3GcdR {
        padding-top: 41.86047%
    }
}

.color-wrapper___23_UY .feature-banner-desktop___3yFiN {
    display: none
}

.color-wrapper___23_UY .feature-title___3cAc7 {
    padding-top: 32px;
    padding-bottom: 16px
}

.color-wrapper___23_UY .feature-title___3cAc7 h2 {
    color: #fff
}

.color-wrapper___23_UY .feature-banner___1R_Vi {
    padding-top: 32px;
    padding-bottom: 64px
}

.color-wrapper___23_UY .feature-row___3STHw {
    width: 100%;
    display: block;
    padding: 32px 0;
    border-bottom: 1px solid #e5e9f0
}

.color-wrapper___23_UY .feature-row___3STHw .feature-col1___2QiWy h3,
.color-wrapper___23_UY .feature-row___3STHw .feature-col1___2QiWy p,
.color-wrapper___23_UY .feature-row___3STHw .feature-col2___39IjN h3,
.color-wrapper___23_UY .feature-row___3STHw .feature-col2___39IjN p,
.color-wrapper___23_UY .feature-row___3STHw .feature-col3___3xUzt h3,
.color-wrapper___23_UY .feature-row___3STHw .feature-col3___3xUzt p {
    color: #fff
}

.color-wrapper___23_UY .feature-row___3STHw .feature-col1___2QiWy h3 {
    margin-bottom: 8px
}

.color-wrapper___23_UY .feature-row___3STHw .feature-col2___39IjN h3 {
    margin-bottom: 18px
}

.color-wrapper___23_UY .feature-row___3STHw .feature-col3___3xUzt h6 {
    margin-bottom: 0
}

.color-wrapper___23_UY .feature-row___3STHw:last-child {
    border-bottom: 0
}

@media (min-width:768px) {
    .feature-banner-mobile___3GcdR {
        display: none
    }

    .feature-banner-desktop___3yFiN {
        display: block;
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 131.25%
    }
}

@media (min-width:768px) and (min-width:768px) {
    .feature-banner-desktop___3yFiN {
        padding-top: 41.86047%
    }
}

@media (min-width:768px) and (min-width:1025px) {
    .feature-banner-desktop___3yFiN {
        padding-top: 41.86047%
    }
}

@media (min-width:768px) and (min-width:1280px) {
    .feature-banner-desktop___3yFiN {
        padding-top: 41.75627%
    }
}

@media (min-width:768px) and (min-width:1440px) {
    .feature-banner-desktop___3yFiN {
        padding-top: 41.75258%
    }
}

@media (min-width:768px) and (min-width:1680px) {
    .feature-banner-desktop___3yFiN {
        padding-top: 41.88791%
    }
}

@media (min-width:768px) and (min-width:1920px) {
    .feature-banner-desktop___3yFiN {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .color-wrapper___23_UY .feature-title___3cAc7 {
        padding-top: 48px
    }

    .color-wrapper___23_UY .feature-banner___1R_Vi {
        padding-top: 48px;
        padding-bottom: 96px
    }

    .color-wrapper___23_UY .feature-row___3STHw {
        display: flex;
        padding: 48px 0
    }

    .color-wrapper___23_UY .feature-row___3STHw .feature-col1___2QiWy h3,
    .color-wrapper___23_UY .feature-row___3STHw .feature-col2___39IjN h3,
    .color-wrapper___23_UY .feature-row___3STHw .feature-col3___3xUzt h6 {
        margin-bottom: 0
    }

    .color-wrapper___23_UY .feature-row___3STHw>div {
        display: flex;
        align-items: center
    }
}

.links___2bkTG {
    padding: 48px 0 32px;
    display: flex;
    align-items: center;
    max-width: 100%;
    justify-content: start;
    flex-wrap: wrap
}

.links___2bkTG .link___fsBVg {
    margin-right: 16px;
    margin-bottom: 16px
}

.card-title-wrapper___1b3uZ {
    flex-direction: column
}

.card-title-wrapper___1b3uZ .title___-EBkB {
    margin-bottom: 32px
}

.card-title-wrapper___1b3uZ .slogan___Opz_V {
    margin-bottom: 48px;
    margin-top: 0
}

.subpage-hero-wrapper___2eHjE {
    padding-top: 64px
}

.subpage-hero-wrapper___2eHjE .title-wrapper___3eHSc .subtitle___2qc4j,
.subpage-hero-wrapper___2eHjE .title-wrapper___3eHSc .title___-EBkB {
    margin-top: 24px
}

.subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i {
    display: grid;
    overflow: hidden;
    margin-top: 32px
}

.subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%;
    width: 112%;
    height: 112%
}

@media (min-width:768px) {
    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
        padding-top: 134.73054%
    }
}

@media (min-width:1025px) {
    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
        padding-top: 133.33333%
    }
}

@media (min-width:1280px) {
    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
        padding-top: 133.21168%
    }
}

@media (min-width:1440px) {
    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
        padding-top: 133.21678%
    }
}

@media (min-width:1680px) {
    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
        padding-top: 133.23353%
    }
}

@media (min-width:1920px) {
    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i .mobile-img___M2AR_ {
        padding-top: 133.24607%
    }
}

.subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD {
    display: none
}

.half-desc-spacer___CZhmv {
    height: 64px
}

@media (max-width:1024px) {
    .card-title-wrapper___1b3uZ .title___-EBkB {
        margin-bottom: 24px
    }

    .card-title-wrapper___1b3uZ .slogan___Opz_V {
        margin-bottom: 32px
    }
}

@media (min-width:1025px) {
    .subpage-hero-wrapper___2eHjE {
        padding-top: 96px
    }

    .subpage-hero-wrapper___2eHjE .title-wrapper___3eHSc {
        display: flex;
        align-items: flex-end;
        align-content: flex-end;
        overflow-wrap: anywhere
    }

    .subpage-hero-wrapper___2eHjE .title-wrapper___3eHSc .title___-EBkB {
        margin-top: 32px;
        margin-bottom: -14px
    }

    .subpage-hero-wrapper___2eHjE .title-wrapper___3eHSc .subtitle___2qc4j {
        margin-top: 62px;
        margin-bottom: -8px
    }

    .subpage-hero-wrapper___2eHjE .mobile-img-wrapper___2kn8i {
        display: none
    }

    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD {
        display: grid;
        overflow: hidden
    }

    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 131.25%;
        width: 112%;
        height: 112%
    }

    .half-desc-spacer___CZhmv {
        height: 96px
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        padding-top: 134.73054%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        padding-top: 133.33333%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        padding-top: 133.21168%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        padding-top: 133.21678%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        padding-top: 133.23353%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .subpage-hero-wrapper___2eHjE .desktop-img-wrapper___2GtwD .desktop-img___1p_Fi {
        padding-top: 133.24607%
    }
}

.links-list-wrapper___1QIeb .title___1qe6J {
    margin-bottom: 18px
}

.links-list-wrapper___1QIeb .subtitle___39cGs {
    margin-top: 0
}

.links-list-wrapper___1QIeb .desc___3D0BY,
.links-list-wrapper___1QIeb .subtitle___39cGs {
    margin-bottom: 48px
}

.links-list-wrapper___1QIeb .links___1jHUY {
    list-style: none;
    margin-top: 14px;
    margin-bottom: 0;
    padding-left: 0
}

.links-list-wrapper___1QIeb .links___1jHUY li {
    border-bottom: 2px solid #e5e9f0
}

.links-list-wrapper___1QIeb .links___1jHUY li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    text-transform: capitalize;
    color: #00804a !important
}

.links-list-wrapper___1QIeb .links___1jHUY li a svg path {
    fill: #00804a
}

.links-list-wrapper___1QIeb .links___1jHUY li:first-child {
    border-top: 2px solid #e5e9f0
}

@media (max-width:1024px) {
    .desktop___2Ajjz {
        display: none
    }
}

@media (min-width:1025px) {
    .mobile___1x9wQ {
        display: none
    }

    .links-list-wrapper___1QIeb .title___1qe6J {
        margin-bottom: 0
    }

    .links-list-wrapper___1QIeb .subtitle___39cGs {
        margin-top: 18px;
        margin-bottom: 0
    }

    .links-list-wrapper___1QIeb .desc___3D0BY {
        margin-bottom: 64px
    }

    .links-list-wrapper___1QIeb .links___1jHUY {
        margin-top: 0
    }
}

.container___14dI8 {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 72px !important;
    padding-bottom: 96px !important
}

@media (min-width:768px) {
    .container___14dI8 {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .container___14dI8 {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .container___14dI8 {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .container___14dI8 {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .container___14dI8 {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .container___14dI8 {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.container___14dI8>i {
    font-size: 64px
}

.container___14dI8>svg {
    width: 64px
}

.container___14dI8 .title___1-J3t {
    margin-top: 40px;
    margin-bottom: 64px
}

.container___14dI8 .title___1-J3t:first-child {
    margin-top: 28px
}

.features___LJCb6 {
    display: flex;
    justify-content: space-between
}

.features___LJCb6 .feature___3pJHK {
    padding-left: 25px;
    padding-bottom: 32px
}

.features___LJCb6 .feature___3pJHK h3 {
    margin-bottom: 8px
}

.features___LJCb6 .feature___3pJHK p {
    margin: 0
}

.container___14dI8.dark___24qEX,
.container___14dI8.dark___24qEX .feature___3pJHK,
.container___14dI8.dark___24qEX .features___LJCb6,
.container___14dI8.dark___24qEX .title___1-J3t,
.container___14dI8.dark___24qEX h3,
.container___14dI8.dark___24qEX i,
.container___14dI8.dark___24qEX p {
    color: #383e43
}

.container___14dI8.dark___24qEX .features___LJCb6 .feature___3pJHK {
    border-left: 1px solid #707070
}

.container___14dI8.light___55wHd,
.container___14dI8.light___55wHd .feature___3pJHK,
.container___14dI8.light___55wHd .features___LJCb6,
.container___14dI8.light___55wHd .title___1-J3t,
.container___14dI8.light___55wHd h3,
.container___14dI8.light___55wHd i,
.container___14dI8.light___55wHd p {
    color: #fff
}

.container___14dI8.light___55wHd .features___LJCb6 .feature___3pJHK {
    border-left: 1px solid #fff
}

@media (max-width:1024px) {
    .container___14dI8 {
        padding-top: 32px !important;
        padding-bottom: 32px !important
    }

    .container___14dI8 .title___1-J3t {
        margin-bottom: 16px
    }

    .container___14dI8 .title___1-J3t:first-child {
        margin-top: 20px
    }

    .features___LJCb6 {
        display: block
    }

    .features___LJCb6 .feature___3pJHK {
        width: 100%;
        padding: 32px 0;
        margin: 0;
        height: auto
    }

    .features___LJCb6 .feature___3pJHK h5 {
        font-size: 18px;
        line-height: 24px
    }

    .features___LJCb6 .feature___3pJHK p {
        font-size: 16px;
        line-height: 24px
    }

    .container___14dI8.dark___24qEX .features___LJCb6 .feature___3pJHK {
        border-bottom: 1px solid #707070;
        border-left: 0
    }

    .container___14dI8.dark___24qEX .features___LJCb6 .feature___3pJHK:last-child {
        border-bottom: none
    }

    .container___14dI8.light___55wHd .features___LJCb6 .feature___3pJHK {
        border-bottom: 1px solid #fff;
        border-left: 0
    }

    .container___14dI8.light___55wHd .features___LJCb6 .feature___3pJHK:last-child {
        border-bottom: none
    }
}

.newsroom___3xK54 {
    margin-bottom: 96px
}

.newsroom___3xK54 .mobile-newsroom___1bzBC {
    display: block
}

.newsroom___3xK54 .mobile-newsroom___1bzBC .news-index___7znDw,
.newsroom___3xK54 .mobile-newsroom___1bzBC .news-item___1aE16 {
    width: calc(100vw - 82px);
    margin-left: 20px;
    margin-right: 22px;
    max-width: 512px
}

.newsroom___3xK54 .mobile-newsroom___1bzBC .news-index___7znDw {
    margin-bottom: 16px
}

.newsroom___3xK54 .desktop-newsroom___d2STp {
    display: none
}

.newsroom___3xK54 hr {
    border-top: 2px solid #242a2e
}

.newsroom___3xK54 .news-heading___2PLDO {
    margin-top: 48px;
    margin-bottom: 48px
}

.newsroom___3xK54 .desktop-newsroom___d2STp>.uk-flex {
    width: 100%
}

.newsroom___3xK54 .news-date___3YTuf {
    color: #697078;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
    color: #242a2e;
    margin-top: 24px
}

@media (min-width:1025px) {
    .newsroom___3xK54 .news-date___3YTuf {
        font-size: .75rem;
        line-height: 1rem
    }
}

@media (min-width:1280px) {
    .newsroom___3xK54 .news-date___3YTuf {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

@media (min-width:1680px) {
    .newsroom___3xK54 .news-date___3YTuf {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

.newsroom___3xK54 .news-title___3_e9H {
    color: #161a1d;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #383e43;
    margin-top: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 60px
}

@media (min-width:1025px) {
    .newsroom___3xK54 .news-title___3_e9H {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1280px) {
    .newsroom___3xK54 .news-title___3_e9H {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

@media (min-width:1680px) {
    .newsroom___3xK54 .news-title___3_e9H {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.newsroom___3xK54 .news-readmore___2MuvE {
    color: #00804a;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin-bottom: 48px;
    margin-top: 16px
}

@media (min-width:1025px) {
    .newsroom___3xK54 .news-readmore___2MuvE {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1280px) {
    .newsroom___3xK54 .news-readmore___2MuvE {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {
    .newsroom___3xK54 .news-readmore___2MuvE {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

.newsroom___3xK54 .news-link___ky2P2 {
    max-height: 400px;
    display: block
}

.newsroom___3xK54 .news-item___1aE16 {
    width: 100%
}

.newsroom___3xK54 .news-image-conatiner____4vZE {
    width: 100%;
    height: 202px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover
}

.newsroom___3xK54 .news-image___1HRrf {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: transparent
}

.newsroom___3xK54 .newsroom-more-outter___3PP68 {
    display: flex;
    justify-content: center;
    align-items: center
}

.newsroom___3xK54 .newsroom-more___2OFdt {
    color: #001e1f;
    background-color: #fff;
    border: 1px solid rgba(2, 1, 1, .611764705882353);
    border-radius: 0 0 0 0;
    padding: 16px 48px;
    text-align: center
}

@media (min-width:768px) {
    .newsroom___3xK54 {
        margin-bottom: 196px
    }

    .newsroom___3xK54 .mobile-newsroom___1bzBC {
        display: none
    }

    .newsroom___3xK54 .desktop-newsroom___d2STp {
        display: flex;
        flex-wrap: wrap
    }

    .newsroom___3xK54 .news-link___ky2P2 {
        max-height: 380px
    }

    .newsroom___3xK54 .news-link___ky2P2:nth-child(4) {
        margin-right: auto
    }

    .newsroom___3xK54 .news-title___3_e9H {
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 56px
    }

    .newsroom___3xK54 .news-readmore___2MuvE {
        margin-bottom: 96px;
        margin-top: 24px
    }
}

.papers___MSDx7 {
    border-top: 2px solid #242a2e;
    padding-top: 64px;
    margin-bottom: 64px
}

.papers___MSDx7 ul {
    list-style: none;
    padding-left: 0;
    margin: 0
}

.papers___MSDx7 ul li {
    padding: 16px 0;
    border-top: 2px solid #e8e8e8
}

.papers___MSDx7 ul li a>span {
    float: right;
    margin-top: -4px
}

.papers___MSDx7 ul li a {
    display: block
}

.papers___MSDx7 .desc___sYXYG {
    margin-bottom: 32px
}

@media (min-width:1025px) {
    .papers___MSDx7 {
        padding-top: 48px;
        margin-bottom: 96px
    }

    .papers___MSDx7 .desc___sYXYG {
        margin-bottom: 48px
    }
}

.testimonial-wrapper___lHVpM dl,
.testimonial-wrapper___lHVpM h1,
.testimonial-wrapper___lHVpM h2,
.testimonial-wrapper___lHVpM h3,
.testimonial-wrapper___lHVpM h4,
.testimonial-wrapper___lHVpM h5,
.testimonial-wrapper___lHVpM h6,
.testimonial-wrapper___lHVpM ol,
.testimonial-wrapper___lHVpM p,
.testimonial-wrapper___lHVpM ul {
    margin-top: 0;
    margin-bottom: 0
}

.testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY {
    background: transparent;
    border-radius: 50%;
    padding: 4px;
    margin-bottom: 24px;
    margin-top: 24px
}

.testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    padding-top: 100%;
    width: 72px
}

@media (min-width:768px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
        width: 120px
    }
}

@media (min-width:1025px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
        width: 90px
    }
}

@media (min-width:1280px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
        width: 122px
    }
}

@media (min-width:1440px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
        width: 128px
    }
}

@media (min-width:1680px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
        width: 152px
    }
}

@media (min-width:1920px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
        width: 176px
    }
}

.testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY>div {
    cursor: pointer
}

.testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY.active___D4s1o {
    background: -webkit-linear-gradient(151.28deg, #e8fccf -4.8%, #b1eaba 94.32%)
}

@media (min-width:1025px) {
    .testimonial-wrapper___lHVpM .avatar-wrapper___WQIiY {
        padding: 8px;
        margin-bottom: 32px;
        margin-top: 32px
    }
}

.testimonial-wrapper___lHVpM .border-bottom___GUkEx {
    border-bottom: 2px solid #e5e9f0
}

.testimonial-wrapper___lHVpM .testimonial-content-desktop___16VRt {
    display: none
}

.testimonial-wrapper___lHVpM .testimonial-content-mobile___2vW4M {
    display: block
}

.testimonial-wrapper___lHVpM .testimonial-content-mobile___2vW4M .flex-justify___1wGc4 {
    justify-content: space-between
}

.testimonial-wrapper___lHVpM .img-div___33MEb {
    display: flex;
    justify-content: center;
    align-items: center
}

.testimonial-wrapper___lHVpM h2 {
    margin: 0
}

.testimonial-wrapper___lHVpM h2.text-h6___2E9pM {
    margin-bottom: 24px
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 {
    margin-top: 32px
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .gr21-boxed-content {
    display: none
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-title___1wsRf {
    margin-top: 32px;
    order: 2;
    padding-left: 0
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-title___1wsRf h5 {
    margin-bottom: 2px
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-title___1wsRf .photo-credits___27WUk {
    color: #9fa4ad;
    margin-top: 32px
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-desc___3tF7I {
    order: 1;
    padding-left: 0
}

.testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-desc___3tF7I p:before {
    
    position: absolute;
    margin-left: -13px
}

@media (max-width:1024px) {
    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9>div {
        padding-left: 15px
    }
}

@media (min-width:768px) {
    .testimonial-wrapper___lHVpM .testimonial-content-mobile___2vW4M .flex-justify___1wGc4 {
        justify-content: space-around
    }
}

@media (min-width:1025px) {
    .testimonial-wrapper___lHVpM h2.text-h6___2E9pM {
        margin-bottom: 32px
    }

    .testimonial-wrapper___lHVpM .testimonial-content-desktop___16VRt {
        display: block
    }

    .testimonial-wrapper___lHVpM .testimonial-content-mobile___2vW4M {
        display: none
    }

    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9 {
        margin-top: 48px
    }

    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .gr21-col-l-1 {
        order: 2
    }

    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-title___1wsRf {
        margin-top: 0;
        order: 1;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }

    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-title___1wsRf .photo-credits___27WUk {
        margin-top: 0
    }

    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-desc___3tF7I {
        order: 2;
        padding-left: 0
    }

    .testimonial-wrapper___lHVpM .testimonial-info___3hQd9 .testimonial-desc___3tF7I p:before {
        margin-left: -17px
    }
}

.gr-gr21-default .table-desktop___t2NuP .text-h4,
.gr-gr21-default .table-desktop___t2NuP .text-p2 {
    color: #242a2e
}

@media (min-width:768px) {
    .table-desktop___t2NuP .table-title___3yhbw .gr21-boxed-content {
        padding-top: 48px;
        padding-bottom: 64px;
        border-top: 2px solid #242a2e
    }

    .table-desktop___t2NuP .table-title___3yhbw h3 {
        margin-bottom: 0
    }

    .table-desktop___t2NuP .table-description___2WHDE {
        margin-top: 20px
    }

    .table-desktop___t2NuP .table-header___3ybLS {
        background-color: #e8e8e8;
        padding: 32px 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        transition: all .4s
    }

    .table-desktop___t2NuP .table-subheader___1TJ3e {
        background-color: #f5f5f5;
        padding: 32px 0
    }

    .table-desktop___t2NuP .table-body___8YpmU {
        padding-bottom: 64px
    }

    .table-desktop___t2NuP .table-body___8YpmU .table-row___3Ug1C {
        padding: 32px 0;
        border-top: 2px solid #e5e9f0
    }

    .table-desktop___t2NuP .table-body___8YpmU .table-row___3Ug1C:first-child {
        border-top: none
    }

    .table-desktop___t2NuP .table-body___8YpmU .table-row-hide___swyyl {
        border-top: 0;
        overflow: hidden
    }

    .table-desktop___t2NuP .table-body___8YpmU .table-cell___1IYLI .title___bF2Ce {
        color: #242a2e;
        padding-bottom: 8px
    }

    .table-desktop___t2NuP .table-body___8YpmU .table-cell___1IYLI .line___2_gcL {
        color: #383e43;
        padding-bottom: 8px
    }

    .table-desktop___t2NuP .table-body___8YpmU .table-cell___1IYLI .helper___fRulW {
        color: #697078;
        padding-bottom: 8px
    }

    .table-desktop___t2NuP .table-footer___2LgeZ {
        margin-bottom: 96px;
        text-align: center;
        border: 2px solid #e5e9f0;
        padding: 16px 0;
        cursor: pointer
    }

    .table-desktop___t2NuP .table-footer___2LgeZ svg {
        width: 20px;
        margin-bottom: 4px
    }

    .table-desktop___t2NuP .table-footer___2LgeZ .show-all___2D0ZR {
        display: flex;
        align-items: center;
        justify-content: center
    }

    .table-desktop___t2NuP .table-footer___2LgeZ .show-less___144PY {
        display: none;
        align-items: center;
        justify-content: center
    }

    .table-desktop___t2NuP .table-footer___2LgeZ svg {
        margin-left: 8px
    }
}

.table-mobile___1fGDL .col1___1XgJy {
    display: block
}

.table-mobile___1fGDL .col2___Nc1_K,
.table-mobile___1fGDL .col3___19taF,
.table-mobile___1fGDL .col4___nEkwz,
.table-mobile___1fGDL .col5___219WK {
    width: 100%;
    display: none
}

.table-mobile___1fGDL .table-title___2EHN6 .gr21-boxed-content {
    padding-top: 32px;
    padding-bottom: 32px;
    border-top: 2px solid #242a2e
}

.table-mobile___1fGDL .table-title___2EHN6 h3 {
    margin-bottom: 0
}

.table-mobile___1fGDL .table-description___1RtwF {
    margin-top: 8px
}

.table-mobile___1fGDL .nav-title___3Wrzm {
    width: 100%;
    padding: 24px 20px;
    color: #242a2e;
    display: flex;
    background-color: #e8e8e8;
    position: -webkit-sticky;
    position: sticky;
    transition: all .4s;
    top: 0;
    left: 0
}

@media (min-width:768px) {
    .table-mobile___1fGDL .nav-title___3Wrzm {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .table-mobile___1fGDL .nav-title___3Wrzm {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .table-mobile___1fGDL .nav-title___3Wrzm {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .table-mobile___1fGDL .nav-title___3Wrzm {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .table-mobile___1fGDL .nav-title___3Wrzm {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .table-mobile___1fGDL .nav-title___3Wrzm {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.table-mobile___1fGDL .nav-title___3Wrzm .see-all___iGNPI {
    align-self: center;
    color: #00804a
}

.table-mobile___1fGDL .menu___1WUXz {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(22, 26, 29, .7490196078431373)
}

.table-mobile___1fGDL .menu___1WUXz .title___13ogh {
    display: block;
    width: 100%;
    padding: 24px 20px;
    color: #242a2e;
    background: #fff
}

@media (min-width:768px) {
    .table-mobile___1fGDL .menu___1WUXz .title___13ogh {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .table-mobile___1fGDL .menu___1WUXz .title___13ogh {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .table-mobile___1fGDL .menu___1WUXz .title___13ogh {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .table-mobile___1fGDL .menu___1WUXz .title___13ogh {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .table-mobile___1fGDL .menu___1WUXz .title___13ogh {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .table-mobile___1fGDL .menu___1WUXz .title___13ogh {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.table-mobile___1fGDL .menu___1WUXz .title___13ogh:first-of-type {
    background: #e8e8e8
}

.table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
.table-mobile___1fGDL .header___1y5NI .title___13ogh {
    width: 100%;
    padding: 24px 20px;
    color: #242a2e
}

@media (min-width:768px) {

    .table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
    .table-mobile___1fGDL .header___1y5NI .title___13ogh {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {

    .table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
    .table-mobile___1fGDL .header___1y5NI .title___13ogh {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {

    .table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
    .table-mobile___1fGDL .header___1y5NI .title___13ogh {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {

    .table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
    .table-mobile___1fGDL .header___1y5NI .title___13ogh {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {

    .table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
    .table-mobile___1fGDL .header___1y5NI .title___13ogh {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {

    .table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK,
    .table-mobile___1fGDL .header___1y5NI .title___13ogh {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.table-mobile___1fGDL .header___1y5NI .title___13ogh {
    display: flex;
    background-color: #e8e8e8
}

.table-mobile___1fGDL .header___1y5NI .title___13ogh .see-all___iGNPI {
    align-self: center;
    color: #00804a
}

.table-mobile___1fGDL .header___1y5NI .subtitle___sZcjK {
    background-color: #f7f7f7
}

.table-mobile___1fGDL .body___tHl5X {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:768px) {
    .table-mobile___1fGDL .body___tHl5X {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .table-mobile___1fGDL .body___tHl5X {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .table-mobile___1fGDL .body___tHl5X {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .table-mobile___1fGDL .body___tHl5X {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .table-mobile___1fGDL .body___tHl5X {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .table-mobile___1fGDL .body___tHl5X {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.table-mobile___1fGDL .body___tHl5X .cell___BDpqD {
    padding-top: 24px;
    padding-bottom: 24px
}

.table-mobile___1fGDL .body___tHl5X .cell___BDpqD:not(:first-child) {
    border-color: currentcolor #e5e9f0 #e5e9f0;
    border-top: 2px solid #e5e9f0
}

.table-mobile___1fGDL .body___tHl5X .cell-line1-desc___6-2yA {
    color: #9fa4ad
}

.table-mobile___1fGDL .body___tHl5X .cell-line2___1zZzN {
    color: #697078
}

.table-mobile___1fGDL .body___tHl5X .cell-icon___1YI5o,
.table-mobile___1fGDL .body___tHl5X .cell-line1___2-Q98,
.table-mobile___1fGDL .body___tHl5X .cell-line2___1zZzN {
    margin-top: 8px
}

.logo-container___DNmSZ {
    display: flex;
    justify-content: space-around
}

.logo-container___DNmSZ .logo___22zey {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 48px;
    width: 72px;
    margin: 12px 12px 32px
}

@media (min-width:768px) {
    .logo-container___DNmSZ .logo___22zey {
        padding-top: 64;
        width: 74px;
        margin: 20px 20px 32px
    }
}

@media (min-width:1025px) {
    .logo-container___DNmSZ .logo___22zey {
        padding-top: 88px;
        width: 98px
    }
}

@media (min-width:1440px) {
    .logo-container___DNmSZ .logo___22zey {
        padding-top: 88px;
        width: 134px
    }
}

@media (min-width:1680px) {
    .logo-container___DNmSZ .logo___22zey {
        padding-top: 117px;
        widht: 161px
    }
}

.section-title___1maSz {
    border-top: 2px solid #242a2e
}

.section-title___1maSz h3 {
    padding-top: 32px;
    padding-bottom: 64px;
    margin-bottom: 0
}

@media (min-width:1025px) {
    .logo-container___DNmSZ .logo___22zey {
        margin-bottom: 0
    }

    .section-title___1maSz h3 {
        padding-top: 48px;
        padding-bottom: 96px
    }
}

.service-options-main___1hEFj {
    background-color: #f9f6f6
}

.service-options-title___2-dHr {
    padding-top: 48px;
    padding-left: 20px
}

.service-options-desktop___3RNZU {
    display: none
}

.service-options-desktop___3RNZU .option-row___3xH2R h3 {
    margin: 0 0 20px
}

.service-options-mobile___1CH0m {
    display: block;
    padding-bottom: 64px
}

@media (min-width:768px) {
    .service-options-title___2-dHr {
        padding-left: calc(50vw - 344px)
    }

    .service-options-mobile___1CH0m .option-item___wtmtv,
    .service-options-mobile___1CH0m .slider-index___hckPR {
        margin-left: calc(50vw - 344px);
        margin-right: 0
    }
}

@media (min-width:1025px) {
    .service-options-main___1hEFj {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px
    }

    .service-options-title___2-dHr {
        padding-top: 96px;
        padding-left: 0
    }

    .service-options-mobile___1CH0m {
        display: none
    }

    .service-options-desktop___3RNZU {
        display: block;
        padding-bottom: 32px
    }

    .service-options-desktop___3RNZU .option-row___3xH2R {
        display: flex;
        padding: 64px 0;
        border-bottom: 2px solid #e6d8d6
    }

    .service-options-desktop___3RNZU .option-row___3xH2R .gr21-col-8:first-child {
        padding-right: 4%
    }

    .service-options-desktop___3RNZU .option-row___3xH2R:last-child {
        border-bottom: none
    }

    .service-options-desktop___3RNZU .option-row___3xH2R .option-gif___1KEVF {
        width: 100%
    }

    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        background-color: #f0e5e4;
        width: 100%;
        height: 202px
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .service-options-main___1hEFj {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .service-options-main___1hEFj {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .service-options-main___1hEFj {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .service-options-main___1hEFj {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .service-options-main___1hEFj {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .service-options-main___1hEFj {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        height: 372px
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        height: 236px
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        height: 308px
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        height: 320px
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        height: 380px
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .service-options-desktop___3RNZU .option-row___3xH2R .img-wrapper___2rshg {
        height: 432px
    }
}

.section-title___rdFYJ {
    padding-top: 32px;
    padding-bottom: 8px
}

.services-section-wrapper___2nfgK .service-content-wrapper___BLNpk .service-content___SMgwd:not(:first-child),
.services-section-wrapper___2nfgK .services-section___3kcVY:not(:first-child) {
    border-top: 2px solid #e5e9f0
}

.services-section-wrapper___2nfgK .service-title-wrapper___1L66e {
    padding-top: 32px
}

.services-section-wrapper___2nfgK .service-title-wrapper___1L66e>h3 {
    margin-bottom: 8px
}

.services-section-wrapper___2nfgK .service-content___SMgwd {
    padding: 32px 0;
    display: flex
}

.services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical
}

.services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw>h5 {
    margin-bottom: 8px;
    color: #242a2e
}

.services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw>p {
    margin: 0
}

.services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw .desktop___3pZIc {
    display: none
}

.services-section-wrapper___2nfgK .service-icon___2a4lq {
    margin-right: 28px
}

.services-section-wrapper___2nfgK .service-icon___2a4lq,
.services-section-wrapper___2nfgK .service-icon___2a4lq svg {
    height: 56px;
    width: 56px;
    max-width: 56px
}

.services-section-wrapper___2nfgK .services-section___3kcVY.services-content-more___22W0Z {
    display: none
}

.expand-collapse-btn___WiuPO {
    cursor: pointer;
    margin-top: 16px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 2px solid #e5e9f0;
    border-bottom: 2px solid #e5e9f0
}

@media (min-width:768px) {
    .expand-collapse-btn___WiuPO {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .expand-collapse-btn___WiuPO {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .expand-collapse-btn___WiuPO {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .expand-collapse-btn___WiuPO {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .expand-collapse-btn___WiuPO {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .expand-collapse-btn___WiuPO {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.expand-collapse-btn___WiuPO .btn-collapse___2jfDH {
    display: none
}

.expand-collapse-btn___WiuPO .btn-expand___2WwFb {
    display: block
}

.expand-collapse-btn___WiuPO .btn-content___2WXN_ {
    padding-top: 16px;
    padding-bottom: 16px
}

.expand-collapse-btn___WiuPO .btn-content___2WXN_ h5 {
    margin: 0;
    color: #002e27
}

.expand-collapse-btn___WiuPO .btn-content___2WXN_ img {
    width: 20px;
    vertical-align: middle
}

@media (min-width:1025px) {
    .section-title___rdFYJ {
        padding-top: 48px;
        padding-bottom: 32px
    }

    .section-title___rdFYJ h6 {
        margin-bottom: 0
    }

    .services-section-wrapper___2nfgK .service-content-wrapper___BLNpk {
        padding-left: 22px
    }

    .services-section-wrapper___2nfgK .service-content___SMgwd,
    .services-section-wrapper___2nfgK .service-title-wrapper___1L66e {
        padding: 32px 0
    }

    .services-section-wrapper___2nfgK .service-icon___2a4lq {
        margin-right: 54px
    }

    .services-section-wrapper___2nfgK .service-icon___2a4lq,
    .services-section-wrapper___2nfgK .service-icon___2a4lq svg {
        height: 72px;
        width: 72px;
        max-width: 72px
    }

    .services-section-wrapper___2nfgK .service-text___2WFEw {
        width: calc(100% - 126px)
    }

    .services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw {
        -webkit-line-clamp: 5
    }

    .services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw .desktop___3pZIc {
        display: block
    }

    .services-section-wrapper___2nfgK .service-content___SMgwd .service-text___2WFEw .mobile___37kBF {
        display: none
    }

    .expand-collapse-btn___WiuPO .btn-content___2WXN_ img {
        width: 24px
    }
}

@media (max-width:767px) {
    .elementor-section .section-tabs___2QLcb {
        margin: 0
    }
}

.section-body-main___1ewuP {
    margin-top: 64px;
    margin-bottom: 64px
}

.section-body-main___1ewuP dl,
.section-body-main___1ewuP h1,
.section-body-main___1ewuP h2,
.section-body-main___1ewuP h3,
.section-body-main___1ewuP h4,
.section-body-main___1ewuP h5,
.section-body-main___1ewuP h6,
.section-body-main___1ewuP ol,
.section-body-main___1ewuP p,
.section-body-main___1ewuP ul {
    margin-top: 0;
    margin-bottom: 0
}

.section-body-main___1ewuP .section-hr-light___2fNFw,
.section-body-main___1ewuP .section-hr___1-J5X {
    height: 2px;
    width: 100%
}

.section-body-main___1ewuP .section-hr___1-J5X {
    background-color: #242a2e
}

.section-body-main___1ewuP .section-hr-light___2fNFw {
    background-color: #e5e9f0
}

.section-body-main___1ewuP .section-body-content-one___3iKK_ {
    margin-bottom: 32px
}

.section-body-main___1ewuP .section-body-content-one___3iKK_ .section-body-dropcap___1CIiB:first-letter {
    float: left;
    font-size: 3.5rem;
    line-height: 4rem;
    margin-top: 4px;
    margin-right: 16px;
    margin-bottom: 8px;
    background-color: #00b14f;
    padding: 12px 24px;
    color: #fff;
    font-weight: 800
}

.section-body-main___1ewuP .section-body-eyebrow___2eLWt {
    margin-bottom: 16px
}

.section-body-main___1ewuP .section-body-title___2odZe {
    margin-top: 32px;
    margin-bottom: 48px
}

.section-body-main___1ewuP .section-body-content-two___kHJrC {
    margin-bottom: 56px
}

.section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk {
    display: none
}

.section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
.section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%
}

@media (min-width:768px) {

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        padding-top: 42.38281%
    }
}

@media (min-width:1280px) {

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        padding-top: 41.71875%
    }
}

@media (min-width:1440px) {

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        padding-top: 41.80556%
    }
}

@media (min-width:1680px) {

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        padding-top: 41.78571%
    }
}

@media (min-width:1920px) {

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk,
    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        padding-top: 41.77083%
    }
}

@media (min-width:1025px) {
    .section-body-main___1ewuP {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .section-body-main___1ewuP .section-body-content-one___3iKK_ {
        margin-bottom: 48px
    }

    .section-body-main___1ewuP .section-body-content-one___3iKK_ .section-body-dropcap___1CIiB:first-letter {
        font-size: 5rem;
        line-height: 5.5rem;
        margin-right: 24px;
        padding: 20px 28px 16px
    }

    .section-body-main___1ewuP .section-body-title___2odZe {
        margin-bottom: 64px
    }

    .section-body-main___1ewuP .section-body-content-two___kHJrC {
        margin-bottom: 96px
    }

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-desktop___2_Odk {
        display: block
    }

    .section-body-main___1ewuP .section-body-images___hza3P .section-body-mobile___1Mn0d {
        display: none
    }
}

.featured-article___3bc9C {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    padding: 24px
}

.featured-article___3bc9C .featured-article-title___2qSLW {
    color: #242a2e;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px
}

.featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi {
    padding: 16px 0
}

.featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi .article-title___3NVse {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #002e27
}

.featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi .article-title___3NVse:hover {
    color: #00b14f
}

.featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi .article-desc___g-b4u {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #383e43
}

.featured-article___3bc9C .article-container___1VHFy .article-item-wrapper___l4INS+.article-item-wrapper___l4INS {
    position: relative
}

.featured-article___3bc9C .article-container___1VHFy .article-item-wrapper___l4INS+.article-item-wrapper___l4INS:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5)
}

@media (max-width:1279px) {
    .featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi {
        padding: 20px 0
    }

    .featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi .article-title___3NVse {
        font-size: 18px;
        line-height: 24px
    }

    .featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi .article-desc___g-b4u {
        font-size: 14px;
        line-height: 20px
    }
}

@media (max-width:1919px) {
    .featured-article___3bc9C .article-container___1VHFy .article-item___JyXQi:nth-child(4) {
        display: none
    }
}

.top-article___d9nR0 {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    overflow: hidden;
    height: 100%
}

.top-article___d9nR0 .main-image___1uUBr {
    width: 100%;
    padding-top: 48%;
    background-size: cover
}

.top-article___d9nR0 .content___3vB4A {
    padding: 20px
}

.top-article___d9nR0 .content___3vB4A .categories___3oV9u {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #00804a
}

.top-article___d9nR0 .content___3vB4A .categories___3oV9u .category-link___Q9VCY+.category-link___Q9VCY {
    position: relative;
    margin-left: 15px
}

.top-article___d9nR0 .content___3vB4A .categories___3oV9u .category-link___Q9VCY+.category-link___Q9VCY:before {
    content: "";
    border-left: 1px solid #00804a;
    height: 14px;
    position: absolute;
    left: -8px;
    top: 1px
}

.top-article___d9nR0 .content___3vB4A .title___1kbHr {
    color: #002e27;
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    word-break: break-word
}

.top-article___d9nR0 .content___3vB4A .title___1kbHr:hover {
    color: #00b14f
}

.top-article___d9nR0 .content___3vB4A .desc___38TPM {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #383e43
}

.top-article___d9nR0 .content___3vB4A .country-and-date___1MDo_ {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #697078
}

@media (max-width:1279px) {
    .top-article___d9nR0 {
        margin-bottom: 48px
    }

    .top-article___d9nR0 .content___3vB4A .title___1kbHr {
        font-size: 16px;
        line-height: 24px;
        display: block
    }

    .top-article___d9nR0 .content___3vB4A .desc___38TPM {
        font-size: 14px;
        line-height: 20px;
        display: block
    }
}

.footer___3cUq2 {
    padding: 64px 0
}

.footer___3cUq2 .category___1CxtP {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 64px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500
}

.footer___3cUq2 .category___1CxtP .category-button___2Qa4F {
    display: block;
    padding: 8px 12px;
    color: #00804a;
    border: 1px solid #00804a;
    border-radius: 5px;
    margin: 0 8px
}

.footer___3cUq2 .category___1CxtP .category-button___2Qa4F:hover {
    color: #00b14f;
    border: 1px solid #00b14f
}

.footer___3cUq2 .divider___2B9Jo {
    width: 100%;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5);
    margin-bottom: 64px
}

.footer___3cUq2 .related-articles___2pba8 {
    width: 100%
}

.footer___3cUq2 .related-articles___2pba8 .title___3dxaJ {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #242a2e;
    margin-bottom: 44px
}

.footer___3cUq2 .related-articles___2pba8 .cards___Q0kM4 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.footer___3cUq2 .related-articles___2pba8 .cards___Q0kM4 a {
    margin-bottom: 0 !important
}

@media (max-width:1279px) {
    .footer___3cUq2 {
        padding: 48px 0
    }

    .footer___3cUq2 .category___1CxtP {
        margin-bottom: 16px
    }

    .footer___3cUq2 .related-articles___2pba8 .title___3dxaJ {
        text-align: center;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px
    }

    .footer___3cUq2 .related-articles___2pba8 .cards___Q0kM4 a {
        margin-bottom: 24px !important
    }
}

.gr-gr21-default .header___nbJOC {
    display: block !important;
    padding: 64px 10% 48px
}

.gr-gr21-default .header___nbJOC .title___L_2dC {
    margin: 0 0 24px;
    text-align: center;
    line-height: 40px;
    color: #242a2e;
    font-size: 32px;
    font-weight: 500;
    word-break: break-word
}

.gr-gr21-default .header___nbJOC .post-info___2HXyN {
    margin-bottom: 36px;
    color: #697078;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center
}

.gr-gr21-default .header___nbJOC .post-info___2HXyN .splitter___3VD88 {
    margin: 0 8px;
    position: relative;
    bottom: 3px
}

.gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV {
    display: flex;
    justify-content: center;
    align-items: center
}

.gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV .author-avatar___2fGwc {
    display: inline-block;
    height: 36px;
    width: 36px;
    background-size: cover;
    border-radius: 50%
}

.gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV .author-name___3rAZz {
    display: inline-block;
    margin-left: 8px;
    color: #697078;
    font-size: 14px
}

.gr-gr21-default .header___nbJOC .main-image___2x19a {
    margin: 0 auto;
    width: 100%;
    padding-top: 48%;
    background-size: cover
}

.gr-gr21-default .header___nbJOC .main-image-figcaption___22KDI {
    margin-top: 3px;
    color: #697078;
    font-size: 14px;
    word-wrap: break-word;
    text-align: center
}

@media (max-width:1279px) {
    .gr-gr21-default .header___nbJOC {
        display: block !important;
        padding: 20px 0 24px
    }

    .gr-gr21-default .header___nbJOC .post-info___2HXyN {
        margin-bottom: 16px;
        color: #697078;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap
    }

    .gr-gr21-default .header___nbJOC .post-info___2HXyN .splitter___3VD88 {
        margin: 0 8px;
        position: relative;
        bottom: 3px
    }

    .gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px
    }

    .gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV .author-avatar___2fGwc {
        height: 28px;
        width: 28px
    }

    .gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV .author-name___3rAZz {
        margin-left: 8px
    }

    .gr-gr21-default .header___nbJOC .post-info___2HXyN .author___1rrbV+.splitter___3VD88 {
        display: none
    }

    .gr-gr21-default .header___nbJOC .title___L_2dC {
        margin: 0 0 20px;
        line-height: 32px;
        font-size: 24px
    }
}

.campaign-gr21-boxed-content {
    padding: 0 10%
}

@media (max-width:1279px) {
    .campaign-gr21-boxed-content {
        padding: 0
    }
}

.large-screen___QWL7E {
    display: block
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU {
    position: relative
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5)
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P {
    display: flex;
    align-items: center;
    height: 72px
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .icon___1VHay {
    margin-right: 28px;
    display: flex;
    align-items: center;
    height: 72px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .nav-item___1pwCk {
    height: 72px;
    display: flex;
    align-items: center
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .drop-down-trigger___1aY8v {
    margin-right: 28px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #002e27;
    height: 72px;
    line-height: 20px
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .drop-down-trigger___1aY8v .category-title___ROiN7 {
    display: flex;
    align-items: center;
    height: 72px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .drop-down-trigger___1aY8v svg {
    width: 20px;
    cursor: pointer
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .option-container___mV-UW {
    padding: 0;
    width: 327px
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ {
    position: relative
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ .name___1rrL_ {
    font-weight: 500;
    font-size: 14px;
    padding: 16px 28px;
    color: #002e27 !important
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ .name___1rrL_ svg {
    display: inline-block;
    margin-right: 10px
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ .all-story___2eVax {
    font-weight: 600;
    font-size: 16px
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #ececec;
    transform: scaleY(.5)
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6:hover,
.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:hover {
    background: rgba(229, 233, 240, .34901960784313724)
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6:hover .name___1rrL_,
.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:hover .name___1rrL_ {
    color: #00b14f !important
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6:hover .name___1rrL_ svg path,
.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:hover .name___1rrL_ svg path {
    fill: #00b14f !important
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .category-desc___CRRLc {
    font-weight: 400;
    font-size: 14px;
    color: #383e43 !important;
    word-break: break-word;
    white-space: normal
}

.large-screen___QWL7E .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-title-li___1C4Ur {
    padding: 0 28px;
    font-weight: 500;
    font-size: 10px;
    color: #697078 !important
}

.mobile___1fo1y {
    display: none
}

@media (max-width:1279px) {
    .large-screen___QWL7E {
        display: none
    }

    .mobile___1fo1y {
        display: block
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU {
        position: relative
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #d5d5d5;
        transform: scaleY(.5)
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #d5d5d5;
        transform: scaleY(.5)
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .campaign-category-fold {
        display: none;
        flex-grow: 1;
        text-align: right
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .campaign-category-expand {
        display: inline-block;
        flex-grow: 1;
        text-align: right
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .campaign-category-desc___3cym- {
        display: none
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .uk-nav .uk-open .campaign-category-fold {
        display: inline-block
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .uk-nav .uk-open .campaign-category-expand {
        display: none
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .uk-nav .uk-open+.campaign-category-desc___3cym- {
        display: block
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .icon___1VHay {
        line-height: 1;
        width: 105px
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .drop-down-trigger___1aY8v {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        color: #002e27;
        height: 24px;
        line-height: 24px
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .drop-down-trigger___1aY8v .category-title___ROiN7 {
        display: inline-block
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .option-container___mV-UW {
        padding: 0 !important;
        width: 239px
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6 {
        padding: 16px;
        position: relative
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6 .category-main-info___n2y3R {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6 .category-main-info___n2y3R .name___1rrL_ {
        font-size: 16px;
        padding: 0;
        font-weight: 500;
        color: #002e27;
        flex-grow: 0
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .parent-category-li___318a6:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #ececec;
        transform: scaleY(.5)
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .uk-nav-sub {
        padding: 0
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ {
        position: relative
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ .name___1rrL_ {
        font-weight: 500;
        font-size: 14px;
        padding: 16px;
        line-height: 17px;
        color: #002e27 !important
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_ .all-story___2eVax {
        font-weight: 600;
        font-size: 14px
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #ececec;
        transform: scaleY(.5)
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P [aria-expanded=true] .name___1rrL_ {
        color: #00b14f !important
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P [aria-expanded=true] .name___1rrL_ svg path {
        fill: #00b14f !important
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:hover {
        background: rgba(229, 233, 240, .34901960784313724)
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:hover .name___1rrL_ {
        color: #00b14f !important
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-li___1OVI_:hover .name___1rrL_ svg path {
        fill: #00b14f !important
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .category-desc___CRRLc {
        font-weight: 400;
        font-size: 12px;
        color: #383e43 !important;
        word-break: break-word;
        white-space: normal
    }

    .mobile___1fo1y .grab21-campaign-top-nav___1_IoU .header___2Fu3P .sub-category-title-li___1C4Ur {
        padding: 16px 16px 0;
        font-weight: 500;
        font-size: 9px;
        color: #697078 !important
    }
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH {
    display: block;
    position: relative
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .next-btn___2jUsM,
.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .previous-btn___2y8XJ {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 15px 1px #ececec;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    line-height: 56px;
    padding: 0;
    text-align: center;
    color: #002e27;
    top: 50%;
    transform: translateY(-50%)
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .next-btn___2jUsM svg,
.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .previous-btn___2y8XJ svg {
    width: 12px;
    height: 24px
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .next-btn___2jUsM svg polyline,
.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .previous-btn___2y8XJ svg polyline {
    stroke-width: 2.5
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .next-btn___2jUsM :hover,
.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .previous-btn___2y8XJ :hover {
    color: #00b14f
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .previous-btn___2y8XJ {
    left: -76px
}

.grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH .next-btn___2jUsM {
    right: -76px
}

.grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq {
    display: none
}

.grab21-campaign-slider___1Yn1E .item___3bD4t {
    width: 33.33333%;
    display: inline-block;
    margin-bottom: 1px
}

@media (max-width:1279px) {
    .grab21-campaign-slider___1Yn1E .slider-large-screen___2w4zH {
        display: none
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq {
        display: block;
        position: relative
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F {
        margin-top: 48%;
        position: absolute;
        top: 0;
        width: 100%
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .next-btn___2jUsM,
    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .previous-btn___2y8XJ {
        position: absolute;
        background-color: #fff;
        border: 1px solid #efefef;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        line-height: 36px;
        padding: 0;
        text-align: center;
        color: #002e27;
        top: 100%;
        transform: translateY(-50%)
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .next-btn___2jUsM svg,
    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .previous-btn___2y8XJ svg {
        width: 8px;
        height: 16px
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .next-btn___2jUsM svg polyline,
    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .previous-btn___2y8XJ svg polyline {
        stroke-width: 1.5
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .next-btn___2jUsM :hover,
    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .previous-btn___2y8XJ :hover {
        color: #00b14f
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .previous-btn___2y8XJ {
        left: -11px
    }

    .grab21-campaign-slider___1Yn1E .slider-mobile___X8fnq .button-wrapper___1C98F .next-btn___2jUsM {
        right: -11px
    }
}

.subcategory-section___1y4JR {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px
}

.subcategory-section___1y4JR .main-info___21cfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px
}

.subcategory-section___1y4JR .main-info___21cfo .left-text___3L9Zi {
    width: 70%
}

.subcategory-section___1y4JR .main-info___21cfo .left-text___3L9Zi .title___24bfN {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #242a2e;
    margin: 0 0 12px;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.subcategory-section___1y4JR .main-info___21cfo .left-text___3L9Zi .description___F93tK {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #383e43;
    margin: 0;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG,
.subcategory-section___1y4JR .view-more-btn-mobile___1kXug {
    font-weight: 500;
    font-size: 14px;
    color: #00804a
}

.subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG:hover,
.subcategory-section___1y4JR .view-more-btn-mobile___1kXug:hover {
    color: #00b14f
}

.subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG svg,
.subcategory-section___1y4JR .view-more-btn-mobile___1kXug svg {
    width: 18px
}

.subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG svg path,
.subcategory-section___1y4JR .view-more-btn-mobile___1kXug svg path {
    fill: #00804a
}

.subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG svg path:hover,
.subcategory-section___1y4JR .view-more-btn-mobile___1kXug svg path:hover {
    color: #00b14f
}

.subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG {
    display: block
}

.subcategory-section___1y4JR .view-more-btn-mobile___1kXug {
    display: none
}

@media (max-width:1279px) {
    .subcategory-section___1y4JR {
        margin-bottom: 48px
    }

    .subcategory-section___1y4JR .main-info___21cfo {
        margin-bottom: 16px
    }

    .subcategory-section___1y4JR .main-info___21cfo .left-text___3L9Zi {
        width: 100%
    }

    .subcategory-section___1y4JR .main-info___21cfo .left-text___3L9Zi .title___24bfN {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        height: 24px;
        white-space: noraml;
        word-break: break-word
    }

    .subcategory-section___1y4JR .main-info___21cfo .left-text___3L9Zi .description___F93tK {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        white-space: noraml;
        word-break: break-word
    }

    .subcategory-section___1y4JR .view-more-btn-large-screen___1ADsG {
        display: none
    }

    .subcategory-section___1y4JR .view-more-btn-mobile___1kXug {
        font-size: 14px;
        margin-top: 20px;
        display: block
    }
}

.subcat-wrapper___1_7Q8 {
    padding-top: 20px
}

.subcat-wrapper___1_7Q8 .header___2_2ow {
    margin-bottom: 24px
}

.subcat-wrapper___1_7Q8 .header___2_2ow dl,
.subcat-wrapper___1_7Q8 .header___2_2ow h1,
.subcat-wrapper___1_7Q8 .header___2_2ow h2,
.subcat-wrapper___1_7Q8 .header___2_2ow h3,
.subcat-wrapper___1_7Q8 .header___2_2ow h4,
.subcat-wrapper___1_7Q8 .header___2_2ow h5,
.subcat-wrapper___1_7Q8 .header___2_2ow h6,
.subcat-wrapper___1_7Q8 .header___2_2ow ol,
.subcat-wrapper___1_7Q8 .header___2_2ow p,
.subcat-wrapper___1_7Q8 .header___2_2ow ul {
    margin-top: 0;
    margin-bottom: 0
}

.subcat-wrapper___1_7Q8 .header___2_2ow h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #00804a
}

.subcat-wrapper___1_7Q8 .header___2_2ow h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 8px
}

.subcat-wrapper___1_7Q8 .header___2_2ow p {
    font-size: 14px;
    line-height: 22px
}

.subcat-wrapper___1_7Q8 .cards___sE2qY>* {
    margin-bottom: 24px
}

.subcat-wrapper___1_7Q8 .pagi___Pmfh4 {
    margin: 48px auto;
    width: 100%;
    justify-content: center
}

.subcat-wrapper___1_7Q8 .pagi___Pmfh4 ul {
    list-style: none
}

.subcat-wrapper___1_7Q8 .pagi___Pmfh4 ul li {
    float: left;
    padding: 0 6px
}

.subcat-wrapper___1_7Q8 .pagi___Pmfh4 ul li a,
.subcat-wrapper___1_7Q8 .pagi___Pmfh4 ul li span {
    padding: 2px 8px
}

.subcat-wrapper___1_7Q8 .pagi___Pmfh4 ul li.active span {
    border-bottom: 2px solid green
}

@media (min-width:1025px) {
    .subcat-wrapper___1_7Q8 {
        margin-top: 64px
    }

    .subcat-wrapper___1_7Q8 .header___2_2ow {
        margin-bottom: 64px
    }

    .subcat-wrapper___1_7Q8 .header___2_2ow h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        color: #00804a
    }

    .subcat-wrapper___1_7Q8 .header___2_2ow h3 {
        font-weight: 500;
        margin-bottom: 16px;
        font-size: 32px;
        line-height: 38px
    }

    .subcat-wrapper___1_7Q8 .header___2_2ow p {
        font-size: 16px;
        line-height: 20px
    }

    .subcat-wrapper___1_7Q8 .cards___sE2qY>* {
        margin-bottom: 60px
    }

    .subcat-wrapper___1_7Q8 .pagi___Pmfh4 {
        margin: 64px auto;
        width: 40%
    }
}

.featured-article___1WA2k {
    width: 100%;
    margin-bottom: 64px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.featured-article___1WA2k .main-image___1OaU7 {
    width: 507px
}

.featured-article___1WA2k .main-image___1OaU7 div {
    padding-top: 48%;
    background-size: cover
}

.featured-article___1WA2k .content___1mdFI {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column
}

.featured-article___1WA2k .content___1mdFI .categories___3e9ub {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #00804a
}

.featured-article___1WA2k .content___1mdFI .categories___3e9ub .category-link___2JO8A+.category-link___2JO8A {
    position: relative;
    margin-left: 15px
}

.featured-article___1WA2k .content___1mdFI .categories___3e9ub .category-link___2JO8A+.category-link___2JO8A:before {
    content: "";
    border-left: 1px solid #00804a;
    height: 14px;
    position: absolute;
    left: -8px;
    top: 1px
}

.featured-article___1WA2k .content___1mdFI .title___63MYI {
    color: #002e27;
    margin: 0 0 16px;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.featured-article___1WA2k .content___1mdFI .title___63MYI:hover {
    color: #00b14f
}

.featured-article___1WA2k .content___1mdFI .desc___2uYDt {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #383e43
}

.featured-article___1WA2k .content___1mdFI .country-and-date___18Fy2 {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: #697078
}

@media (max-width:1279px) {
    .featured-article___1WA2k {
        margin-bottom: 48px
    }

    .featured-article___1WA2k .content___1mdFI,
    .featured-article___1WA2k .main-image___1OaU7 {
        width: 100%
    }

    .featured-article___1WA2k .content___1mdFI .title___63MYI {
        font-size: 16px;
        line-height: 24px;
        display: block
    }

    .featured-article___1WA2k .content___1mdFI .desc___2uYDt {
        font-size: 14px;
        line-height: 20px;
        display: block
    }
}

.img-wrapper___1kO9j {
    margin-top: 32px
}

.img-wrapper___1kO9j .img-banner___bkFu9 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.25%
}

@media (min-width:768px) {
    .img-wrapper___1kO9j .img-banner___bkFu9 {
        padding-top: 56.39535%
    }
}

@media (min-width:1025px) {
    .img-wrapper___1kO9j .img-banner___bkFu9 {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .img-wrapper___1kO9j .img-banner___bkFu9 {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .img-wrapper___1kO9j .img-banner___bkFu9 {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .img-wrapper___1kO9j .img-banner___bkFu9 {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .img-wrapper___1kO9j .img-banner___bkFu9 {
        padding-top: 41.86047%
    }
}

@media (max-width:1024px) {
    .desktop___215wn {
        display: none
    }
}

@media (min-width:1025px) {
    .img-wrapper___1kO9j {
        margin-top: 64px
    }

    .mobile___uzSXx {
        display: none
    }
}

.featured-article___22-a- {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    padding: 24px
}

.featured-article___22-a- .featured-article-title___2FITC {
    color: #242a2e;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL {
    padding: 16px 0
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL .article-title___YSFTq {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #002e27
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL .article-title___YSFTq:hover {
    color: #00b14f
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL .article-desc___1z0ZY {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #383e43;
    margin-bottom: 8px
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL .country-and-date___wIpy9 {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #697078
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL .country-and-date___wIpy9 .date___27e9Y {
    margin-right: 60px;
    flex-shrink: 0
}

.featured-article___22-a- .article-container___2snuW .article-item___3DCYL .country-and-date___wIpy9 .country___2NLfO {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.featured-article___22-a- .article-container___2snuW .article-item-wrapper___2ABJr+.article-item-wrapper___2ABJr {
    position: relative
}

.featured-article___22-a- .article-container___2snuW .article-item-wrapper___2ABJr+.article-item-wrapper___2ABJr:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5)
}

@media (max-width:1025px) {
    .featured-article___22-a- .article-container___2snuW .article-item___3DCYL {
        padding: 20px 0
    }

    .featured-article___22-a- .article-container___2snuW .article-item___3DCYL .article-title___YSFTq {
        font-size: 18px;
        line-height: 24px;
        display: block
    }

    .featured-article___22-a- .article-container___2snuW .article-item___3DCYL .article-desc___1z0ZY {
        font-size: 14px;
        line-height: 20px;
        display: block
    }
}

@media (max-width:1680px) {
    .featured-article___22-a- .article-container___2snuW .article-item___3DCYL:nth-child(4) {
        display: none
    }
}

.subcategory-section___3WeIn {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px
}

.subcategory-section___3WeIn .main-info___1w41J {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px
}

.subcategory-section___3WeIn .main-info___1w41J .left-text___3ztco {
    width: 70%
}

.subcategory-section___3WeIn .main-info___1w41J .left-text___3ztco .title___Dn2eO {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #242a2e;
    margin: 0 0 12px;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.subcategory-section___3WeIn .main-info___1w41J .left-text___3ztco .description___1Ba40 {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #383e43;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli,
.subcategory-section___3WeIn .view-more-btn-mobile___oEk0y {
    font-weight: 500;
    font-size: 14px;
    color: #00804a
}

.subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli:hover,
.subcategory-section___3WeIn .view-more-btn-mobile___oEk0y:hover {
    color: #00b14f
}

.subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli svg,
.subcategory-section___3WeIn .view-more-btn-mobile___oEk0y svg {
    width: 18px
}

.subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli svg path,
.subcategory-section___3WeIn .view-more-btn-mobile___oEk0y svg path {
    fill: #00804a
}

.subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli svg path:hover,
.subcategory-section___3WeIn .view-more-btn-mobile___oEk0y svg path:hover {
    color: #00b14f
}

.subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli {
    display: block
}

.subcategory-section___3WeIn .view-more-btn-mobile___oEk0y {
    display: none
}

@media (max-width:1025px) {
    .subcategory-section___3WeIn {
        margin-bottom: 48px
    }

    .subcategory-section___3WeIn .main-info___1w41J {
        margin-bottom: 16px
    }

    .subcategory-section___3WeIn .main-info___1w41J .left-text___3ztco {
        width: 100%
    }

    .subcategory-section___3WeIn .main-info___1w41J .left-text___3ztco .title___Dn2eO {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        height: 24px;
        white-space: noraml;
        word-break: break-word
    }

    .subcategory-section___3WeIn .main-info___1w41J .left-text___3ztco .description___1Ba40 {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        white-space: noraml;
        word-break: break-word
    }

    .subcategory-section___3WeIn .view-more-btn-large-screen___v9Xli {
        display: none
    }

    .subcategory-section___3WeIn .view-more-btn-mobile___oEk0y {
        font-size: 14px;
        margin-top: 20px;
        display: block
    }
}

.top-article___kpT-Y {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    overflow: hidden;
    height: 100%
}

.top-article___kpT-Y .main-image___1HuKx {
    width: 100%;
    padding-top: 48%;
    background-size: cover
}

.top-article___kpT-Y .content___HasXS {
    padding: 20px
}

.top-article___kpT-Y .content___HasXS .categories___8kYAH {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #00804a
}

.top-article___kpT-Y .content___HasXS .categories___8kYAH .category-link___2MV56+.category-link___2MV56 {
    position: relative;
    margin-left: 15px
}

.top-article___kpT-Y .content___HasXS .categories___8kYAH .category-link___2MV56+.category-link___2MV56:before {
    content: "";
    border-left: 1px solid #00804a;
    height: 14px;
    position: absolute;
    left: -8px;
    top: 1px
}

.top-article___kpT-Y .content___HasXS .title___17ejq {
    color: #002e27;
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    word-break: break-word
}

.top-article___kpT-Y .content___HasXS .title___17ejq:hover {
    color: #00b14f
}

.top-article___kpT-Y .content___HasXS .desc___2BGaF {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #383e43
}

.top-article___kpT-Y .content___HasXS .country-and-date___2qeCy {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #697078
}

@media (max-width:1025px) {
    .top-article___kpT-Y {
        margin-bottom: 48px
    }

    .top-article___kpT-Y .content___HasXS .title___17ejq {
        font-size: 16px;
        line-height: 24px;
        display: block
    }

    .top-article___kpT-Y .content___HasXS .desc___2BGaF {
        font-size: 14px;
        line-height: 20px;
        display: block
    }
}

.footer___1rBe9 {
    padding: 64px 0
}

.footer___1rBe9 .author-section___3g2WK {
    margin: 64px 0
}

.footer___1rBe9 .author-section___3g2WK .author-section-label___EOZkX {
    color: #242a2e;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 28px
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 {
    display: flex;
    align-items: center
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-avatar___3CAji {
    display: inline-block;
    height: 92px;
    width: 92px;
    background-size: cover;
    border-radius: 50%;
    margin-right: 28px
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 {
    flex: 1
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-name___CPefe {
    color: #242a2e;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8px
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-job-title___lLaw8 {
    color: #697078;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-page-link___yaMHJ {
    color: #00804a;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-page-link___yaMHJ:hover {
    color: #00b14f
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-page-link___yaMHJ svg {
    width: 18px
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-page-link___yaMHJ svg path {
    fill: #00804a
}

.footer___1rBe9 .author-section___3g2WK .author-section-content___22Cv9 .author-info___3xp02 .author-page-link___yaMHJ svg path:hover {
    color: #00b14f
}

.footer___1rBe9 .category___2YZZB {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 64px 0;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500
}

.footer___1rBe9 .category___2YZZB .category-button___1IKRf {
    display: block;
    padding: 8px 12px;
    color: #00804a;
    border: 1px solid #00804a;
    border-radius: 5px;
    margin: 0 8px
}

.footer___1rBe9 .category___2YZZB .category-button___1IKRf:hover {
    color: #00b14f;
    border: 1px solid #00b14f
}

.footer___1rBe9 .divider___3wTxb {
    width: 100%;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5)
}

.footer___1rBe9 .related-articles___1Qikv {
    margin: 64px 0;
    width: 100%
}

.footer___1rBe9 .related-articles___1Qikv .title___2_imk {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #242a2e;
    margin-bottom: 44px
}

.footer___1rBe9 .related-articles___1Qikv .cards___2b1ff {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.footer___1rBe9 .related-articles___1Qikv .cards___2b1ff a {
    margin-bottom: 0 !important
}

@media (max-width:1025px) {
    .footer___1rBe9 {
        padding: 48px 0
    }

    .footer___1rBe9 .category___2YZZB {
        margin-bottom: 16px
    }

    .footer___1rBe9 .related-articles___1Qikv .title___2_imk {
        text-align: center;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px
    }

    .footer___1rBe9 .related-articles___1Qikv .cards___2b1ff a {
        margin-bottom: 24px !important
    }
}

.gr-gr21-default .header___1YRNw {
    display: block !important;
    padding: 64px 10% 48px
}

.gr-gr21-default .header___1YRNw .title___1kgcR {
    margin: 0 0 24px;
    text-align: center;
    line-height: 40px;
    color: #242a2e;
    font-size: 32px;
    font-weight: 500;
    word-break: break-word
}

.gr-gr21-default .header___1YRNw .post-info___1r5rz {
    margin-bottom: 36px;
    color: #697078;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center
}

.gr-gr21-default .header___1YRNw .post-info___1r5rz .splitter___iYXfg {
    margin: 0 8px;
    position: relative;
    bottom: 3px
}

.gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR- {
    display: flex;
    justify-content: center;
    align-items: center
}

.gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR- .author-avatar___1OIaF {
    display: inline-block;
    height: 36px;
    width: 36px;
    background-size: cover;
    border-radius: 50%
}

.gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR- .author-name___L1cLA {
    display: inline-block;
    margin-left: 8px;
    color: #697078;
    font-size: 14px
}

.gr-gr21-default .header___1YRNw .main-image___3IfiX {
    margin: 0 auto;
    width: 100%;
    padding-top: 48%;
    background-size: cover
}

.gr-gr21-default .header___1YRNw .main-image-figcaption___32Lln {
    margin-top: 3px;
    color: #697078;
    font-size: 14px;
    word-wrap: break-word;
    text-align: center
}

@media (max-width:1025px) {
    .gr-gr21-default .header___1YRNw {
        display: block !important;
        padding: 20px 0 24px
    }

    .gr-gr21-default .header___1YRNw .post-info___1r5rz {
        margin-bottom: 16px;
        color: #697078;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap
    }

    .gr-gr21-default .header___1YRNw .post-info___1r5rz .splitter___iYXfg {
        margin: 0 8px;
        position: relative;
        bottom: 3px
    }

    .gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR- {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px
    }

    .gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR- .author-avatar___1OIaF {
        height: 28px;
        width: 28px
    }

    .gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR- .author-name___L1cLA {
        margin-left: 8px
    }

    .gr-gr21-default .header___1YRNw .post-info___1r5rz .author___2lgR-+.splitter___iYXfg {
        display: none
    }

    .gr-gr21-default .header___1YRNw .title___1kgcR {
        margin: 0 0 20px;
        line-height: 32px;
        font-size: 24px
    }
}

.editorial-gr21-boxed-content {
    padding: 0 10%
}

@media (max-width:767px) {
    .editorial-gr21-boxed-content {
        padding: 0
    }
}

.large-screen___11UUM {
    display: block
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 {
    position: relative
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5)
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 {
    display: flex;
    align-items: center;
    height: 72px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .icon___3609h {
    margin-right: 28px;
    line-height: 1;
    position: relative;
    width: 105px;
    bottom: 2px;
    flex: 0 1 105px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .category-selectors___2PTRd {
    flex: 1
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .media-icons___3LKE1 {
    flex: 0 1 auto
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .media-icons___3LKE1 .media-icon___jAAzk {
    border-radius: 50%;
    background-color: #363a45;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .media-icons___3LKE1 .tiktok-logo___3LxJE {
    font-family: Font Awesome\ 5 Brands;
    font-weight: 400
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .drop-down-trigger___HB66x {
    margin-right: 28px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    color: #002e27;
    height: 20px;
    line-height: 20px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .drop-down-trigger___HB66x .category-title___3DsBV {
    display: inline-block;
    cursor: pointer
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .drop-down-trigger___HB66x svg {
    width: 20px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .option-container___5Qy9- {
    padding: 0;
    width: 327px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_ {
    padding: 16px 20px;
    position: relative
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_ .name___3bwN7 {
    font-size: 14px;
    padding: 0;
    line-height: 17px;
    font-weight: 600;
    color: #002e27 !important
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #ececec;
    transform: scaleY(.5)
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt {
    padding: 16px 28px;
    position: relative
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt .name___3bwN7 {
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    color: #002e27 !important
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt .name___3bwN7 svg {
    display: inline-block;
    margin-right: 10px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt .all-story___5IT_1 {
    font-weight: 600;
    font-size: 16px
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #ececec;
    transform: scaleY(.5)
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_:hover,
.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:hover {
    background: rgba(229, 233, 240, .34901960784313724)
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_:hover .name___3bwN7,
.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:hover .name___3bwN7 {
    color: #00b14f !important
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_:hover .name___3bwN7 svg path,
.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:hover .name___3bwN7 svg path {
    fill: #00b14f !important
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .category-desc___2O6MA {
    font-weight: 400;
    font-size: 14px;
    color: #383e43 !important;
    word-break: break-word;
    white-space: normal
}

.large-screen___11UUM .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-title-li___21s0W {
    padding: 0 28px;
    font-weight: 500;
    font-size: 10px;
    color: #697078 !important
}

.mobile___2a4A4 {
    display: none
}

@media (max-width:1025px) {
    .large-screen___11UUM {
        display: none
    }

    .mobile___2a4A4 {
        display: block
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 {
        position: relative
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #d5d5d5;
        transform: scaleY(.5)
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #d5d5d5;
        transform: scaleY(.5)
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .editorial-category-fold {
        display: none;
        flex-grow: 1;
        text-align: right
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .editorial-category-expand {
        display: inline-block;
        flex-grow: 1;
        text-align: right
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .editorial-category-desc___3LsEc {
        display: none
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .uk-nav .uk-open .editorial-category-fold {
        display: inline-block
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .uk-nav .uk-open .editorial-category-expand {
        display: none
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .uk-nav .uk-open+.editorial-category-desc___3LsEc {
        display: block
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .icon___3609h {
        line-height: 1;
        width: 105px
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .drop-down-trigger___HB66x {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        color: #002e27;
        height: 24px;
        line-height: 24px
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .drop-down-trigger___HB66x .category-title___3DsBV {
        display: inline-block
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .option-container___5Qy9- {
        padding: 0 !important;
        width: 239px
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_ {
        padding: 16px;
        position: relative
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_ .category-main-info___E5His {
        display: flex;
        justify-content: space-between
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_ .category-main-info___E5His .name___3bwN7 {
        font-size: 16px;
        padding: 0;
        font-weight: 500;
        color: #002e27;
        flex-grow: 0
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .parent-category-li___TXbC_:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #ececec;
        transform: scaleY(.5)
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .uk-nav-sub {
        padding: 0
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt {
        padding: 16px;
        position: relative
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt .name___3bwN7 {
        font-weight: 500;
        font-size: 14px;
        padding: 0;
        line-height: 17px;
        color: #002e27 !important
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt .all-story___5IT_1 {
        font-weight: 600;
        font-size: 14px
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #ececec;
        transform: scaleY(.5)
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 [aria-expanded=true] .name___3bwN7 {
        color: #00b14f !important
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 [aria-expanded=true] .name___3bwN7 svg path {
        fill: #00b14f !important
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:hover {
        background: rgba(229, 233, 240, .34901960784313724)
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:hover .name___3bwN7 {
        color: #00b14f !important
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-li___2Y8Tt:hover .name___3bwN7 svg path {
        fill: #00b14f !important
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .category-desc___2O6MA {
        font-weight: 400;
        font-size: 12px;
        color: #383e43 !important;
        word-break: break-word;
        white-space: normal
    }

    .mobile___2a4A4 .grab21-editorial-top-nav___1xhg0 .header___3gP59 .sub-category-title-li___21s0W {
        padding: 16px 16px 0;
        font-weight: 500;
        font-size: 9px;
        color: #697078 !important
    }

    .mobile___2a4A4 .media-icons___3LKE1 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 15px
    }

    .mobile___2a4A4 .media-icons___3LKE1 .media-icon___jAAzk {
        border-radius: 50%;
        background-color: #363a45;
        width: 20px;
        height: 20px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        margin-right: 5px
    }

    .mobile___2a4A4 .media-icons___3LKE1 .tiktok-logo___3LxJE {
        font-family: Font Awesome\ 5 Brands;
        font-weight: 400
    }
}

.authorpage-wrapper___UyeYO {
    padding-top: 20px
}

.authorpage-wrapper___UyeYO .header___1LPu- {
    width: 100%;
    margin-bottom: 64px;
    display: flex;
    justify-content: center;
    align-items: center
}

.authorpage-wrapper___UyeYO .header___1LPu- .author-avatar___1dk36 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px
}

.authorpage-wrapper___UyeYO .header___1LPu- .author-info___llW8t {
    width: 50%
}

.authorpage-wrapper___UyeYO .header___1LPu- .author-info___llW8t .author-name___FylIq {
    color: #242a2e;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 8px
}

.authorpage-wrapper___UyeYO .header___1LPu- .author-info___llW8t .job-title___1PGAJ {
    color: #697078;
    font-size: 14px;
    margin-bottom: 28px
}

.authorpage-wrapper___UyeYO .header___1LPu- .author-info___llW8t .biography___13QWk {
    color: #383e43;
    font-size: 14px;
    line-height: 22px
}

.authorpage-wrapper___UyeYO .divider___IyMUW {
    width: 100%;
    border-top: 1px solid #d5d5d5;
    transform: scaleY(.5)
}

.authorpage-wrapper___UyeYO .cards-title___3GFnl {
    width: 100%;
    margin: 50px 0;
    font-size: 32px;
    font-weight: 500;
    color: #242a2e
}

.authorpage-wrapper___UyeYO .cards___1RYmo {
    width: 100%;
    display: flex;
    flex-wrap: wrap
}

.authorpage-wrapper___UyeYO .cards___1RYmo>* {
    margin-bottom: 24px
}

.authorpage-wrapper___UyeYO .pagi___SyUzZ {
    margin: 48px auto;
    justify-content: center
}

.authorpage-wrapper___UyeYO .pagi___SyUzZ ul {
    list-style: none
}

.authorpage-wrapper___UyeYO .pagi___SyUzZ ul li {
    float: left;
    padding: 0 6px
}

.authorpage-wrapper___UyeYO .pagi___SyUzZ ul li a,
.authorpage-wrapper___UyeYO .pagi___SyUzZ ul li span {
    padding: 2px 8px
}

.authorpage-wrapper___UyeYO .pagi___SyUzZ ul li.active span {
    border-bottom: 2px solid green
}

@media (min-width:1025px) {
    .authorpage-wrapper___UyeYO {
        margin-top: 64px
    }

    .authorpage-wrapper___UyeYO .header___1LPu- .author-avatar___1dk36 {
        width: 274px;
        height: 274px;
        border-radius: 50%;
        margin-right: 40px
    }

    .authorpage-wrapper___UyeYO .cards___1RYmo>* {
        margin-bottom: 60px
    }

    .authorpage-wrapper___UyeYO .pagi___SyUzZ {
        margin: 64px auto
    }
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r {
    display: block;
    position: relative
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .next-btn___1ZVhr,
.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .previous-btn___2P4pv {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 15px 1px #ececec;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    line-height: 56px;
    padding: 0;
    text-align: center;
    color: #002e27;
    top: 50%;
    transform: translateY(-50%)
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .next-btn___1ZVhr svg,
.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .previous-btn___2P4pv svg {
    width: 12px;
    height: 24px
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .next-btn___1ZVhr svg polyline,
.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .previous-btn___2P4pv svg polyline {
    stroke-width: 2.5
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .next-btn___1ZVhr :hover,
.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .previous-btn___2P4pv :hover {
    color: #00b14f
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .previous-btn___2P4pv {
    left: -76px
}

.grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r .next-btn___1ZVhr {
    right: -76px
}

.grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek {
    display: none
}

.grab21-editorial-slider___3ka6Z .item___23JBf {
    width: 33.33333%;
    display: inline-block;
    margin-bottom: 1px
}

.uk-slider-items a {
    margin-right: 0 !important;
    width: 100% !important
}

@media (max-width:1025px) {
    .grab21-editorial-slider___3ka6Z .slider-large-screen___z7V6r {
        display: none
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek {
        display: block;
        position: relative
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 {
        margin-top: 48%;
        position: absolute;
        top: 0;
        width: 100%
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .next-btn___1ZVhr,
    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .previous-btn___2P4pv {
        position: absolute;
        background-color: #fff;
        border: 1px solid #efefef;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        line-height: 36px;
        padding: 0;
        text-align: center;
        color: #002e27;
        top: 100%;
        transform: translateY(-50%)
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .next-btn___1ZVhr svg,
    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .previous-btn___2P4pv svg {
        width: 8px;
        height: 16px
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .next-btn___1ZVhr svg polyline,
    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .previous-btn___2P4pv svg polyline {
        stroke-width: 1.5
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .next-btn___1ZVhr :hover,
    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .previous-btn___2P4pv :hover {
        color: #00b14f
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .previous-btn___2P4pv {
        left: -11px
    }

    .grab21-editorial-slider___3ka6Z .slider-mobile___3Vwek .button-wrapper___2d7L4 .next-btn___1ZVhr {
        right: -11px
    }
}

.subcat-wrapper___QKlfS {
    padding-top: 20px
}

.subcat-wrapper___QKlfS .header___3bPP0 {
    margin-bottom: 24px
}

.subcat-wrapper___QKlfS .header___3bPP0 dl,
.subcat-wrapper___QKlfS .header___3bPP0 h1,
.subcat-wrapper___QKlfS .header___3bPP0 h2,
.subcat-wrapper___QKlfS .header___3bPP0 h3,
.subcat-wrapper___QKlfS .header___3bPP0 h4,
.subcat-wrapper___QKlfS .header___3bPP0 h5,
.subcat-wrapper___QKlfS .header___3bPP0 h6,
.subcat-wrapper___QKlfS .header___3bPP0 ol,
.subcat-wrapper___QKlfS .header___3bPP0 p,
.subcat-wrapper___QKlfS .header___3bPP0 ul {
    margin-top: 0;
    margin-bottom: 0
}

.subcat-wrapper___QKlfS .header___3bPP0 h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #00804a
}

.subcat-wrapper___QKlfS .header___3bPP0 h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 8px
}

.subcat-wrapper___QKlfS .header___3bPP0 p {
    font-size: 14px;
    line-height: 22px
}

.subcat-wrapper___QKlfS .cards___2q7Ar>* {
    margin-bottom: 24px
}

.subcat-wrapper___QKlfS .pagi___3FxS9 {
    margin: 48px auto;
    width: 100%;
    justify-content: center
}

.subcat-wrapper___QKlfS .pagi___3FxS9 ul {
    list-style: none
}

.subcat-wrapper___QKlfS .pagi___3FxS9 ul li {
    float: left;
    padding: 0 6px
}

.subcat-wrapper___QKlfS .pagi___3FxS9 ul li a,
.subcat-wrapper___QKlfS .pagi___3FxS9 ul li span {
    padding: 2px 8px
}

.subcat-wrapper___QKlfS .pagi___3FxS9 ul li.active span {
    border-bottom: 2px solid green
}

@media (min-width:1025px) {
    .subcat-wrapper___QKlfS {
        margin-top: 64px
    }

    .subcat-wrapper___QKlfS .header___3bPP0 {
        margin-bottom: 64px
    }

    .subcat-wrapper___QKlfS .header___3bPP0 h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        color: #00804a
    }

    .subcat-wrapper___QKlfS .header___3bPP0 h3 {
        font-weight: 500;
        margin-bottom: 16px;
        font-size: 32px;
        line-height: 38px
    }

    .subcat-wrapper___QKlfS .header___3bPP0 p {
        font-size: 16px;
        line-height: 20px
    }

    .subcat-wrapper___QKlfS .cards___2q7Ar>* {
        margin-bottom: 60px
    }

    .subcat-wrapper___QKlfS .pagi___3FxS9 {
        margin: 64px auto;
        width: 40%
    }
}

.featured-article___SmPsJ {
    width: 100%;
    margin-bottom: 64px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.featured-article___SmPsJ .main-image___12yge {
    width: 507px
}

.featured-article___SmPsJ .main-image___12yge div {
    padding-top: 48%;
    background-size: cover
}

.featured-article___SmPsJ .content___1Eawf {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column
}

.featured-article___SmPsJ .content___1Eawf .categories___11fMG {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #00804a
}

.featured-article___SmPsJ .content___1Eawf .categories___11fMG .category-link___1nlmf+.category-link___1nlmf {
    position: relative;
    margin-left: 15px
}

.featured-article___SmPsJ .content___1Eawf .categories___11fMG .category-link___1nlmf+.category-link___1nlmf:before {
    content: "";
    border-left: 1px solid #00804a;
    height: 14px;
    position: absolute;
    left: -8px;
    top: 1px
}

.featured-article___SmPsJ .content___1Eawf .title___1_EW_ {
    color: #002e27;
    margin: 0 0 16px;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.featured-article___SmPsJ .content___1Eawf .title___1_EW_:hover {
    color: #00b14f
}

.featured-article___SmPsJ .content___1Eawf .desc___3DD1L {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #383e43
}

.featured-article___SmPsJ .content___1Eawf .country-and-date___2ZBmV {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: #697078
}

@media (max-width:1025px) {
    .featured-article___SmPsJ {
        margin-bottom: 48px
    }

    .featured-article___SmPsJ .content___1Eawf,
    .featured-article___SmPsJ .main-image___12yge {
        width: 100%
    }

    .featured-article___SmPsJ .content___1Eawf .title___1_EW_ {
        font-size: 16px;
        line-height: 24px;
        display: block
    }

    .featured-article___SmPsJ .content___1Eawf .desc___3DD1L {
        font-size: 14px;
        line-height: 20px;
        display: block
    }
}

.subcategory-section___9rfdt {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px
}

.subcategory-section___9rfdt .main-info___2GNkJ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px
}

.subcategory-section___9rfdt .main-info___2GNkJ .left-text___1tuRl {
    width: 70%
}

.subcategory-section___9rfdt .main-info___2GNkJ .left-text___1tuRl .title___14Xjg {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #242a2e;
    margin: 0 0 12px;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.subcategory-section___9rfdt .main-info___2GNkJ .left-text___1tuRl .description___QU8yu {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #383e43;
    margin: 0;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE,
.subcategory-section___9rfdt .view-more-btn-mobile___1p-h3 {
    font-weight: 500;
    font-size: 14px;
    color: #00804a
}

.subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE:hover,
.subcategory-section___9rfdt .view-more-btn-mobile___1p-h3:hover {
    color: #00b14f
}

.subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE svg,
.subcategory-section___9rfdt .view-more-btn-mobile___1p-h3 svg {
    width: 18px
}

.subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE svg path,
.subcategory-section___9rfdt .view-more-btn-mobile___1p-h3 svg path {
    fill: #00804a
}

.subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE svg path:hover,
.subcategory-section___9rfdt .view-more-btn-mobile___1p-h3 svg path:hover {
    color: #00b14f
}

.subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE {
    display: block
}

.subcategory-section___9rfdt .view-more-btn-mobile___1p-h3 {
    display: none
}

@media (max-width:1025px) {
    .subcategory-section___9rfdt {
        margin-bottom: 48px
    }

    .subcategory-section___9rfdt .main-info___2GNkJ {
        margin-bottom: 16px
    }

    .subcategory-section___9rfdt .main-info___2GNkJ .left-text___1tuRl {
        width: 100%
    }

    .subcategory-section___9rfdt .main-info___2GNkJ .left-text___1tuRl .title___14Xjg {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        height: 24px;
        white-space: noraml;
        word-break: break-word
    }

    .subcategory-section___9rfdt .main-info___2GNkJ .left-text___1tuRl .description___QU8yu {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        white-space: noraml;
        word-break: break-word
    }

    .subcategory-section___9rfdt .view-more-btn-large-screen___3H-fE {
        display: none
    }

    .subcategory-section___9rfdt .view-more-btn-mobile___1p-h3 {
        font-size: 14px;
        margin-top: 20px;
        display: block
    }
}

.elementor-section .hide___352VF {
    display: none !important
}

.heading-tabs-wrapper___2fTuA {
    padding-top: 32px;
    padding-bottom: 32px
}

.heading-tabs-wrapper___2fTuA.style-underline___3pDuq {
    display: flex;
    border-bottom: 2px solid #dbdbdb;
    justify-content: space-between
}

.heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl {
    font-weight: 600;
    cursor: pointer;
    padding-bottom: 4px
}

.heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    position: relative;
    left: 0;
    bottom: -6px;
    background-color: #161a1d;
    transition: width .3s
}

.heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl.active___F7CBb:after,
.heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl:hover:after {
    width: 100%
}

@media (min-width:768px) {
    .heading-tabs-wrapper___2fTuA.style-underline___3pDuq {
        justify-content: start
    }

    .heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl {
        padding-bottom: 2px
    }

    .heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl:after {
        bottom: -4px
    }

    .heading-tabs-wrapper___2fTuA.style-underline___3pDuq .tabs___3m3vl:not(:first-child) {
        margin-left: 56px
    }
}

.heading-tabs-wrapper___2fTuA.style-button-round___3kB9c {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.heading-tabs-wrapper___2fTuA.style-button-round___3kB9c::-webkit-scrollbar {
    display: none
}

.heading-tabs-wrapper___2fTuA.style-button-round___3kB9c .tabs___3m3vl {
    font-weight: 600;
    cursor: pointer;
    border-radius: 20px;
    padding: 8px 16px;
    color: #697078
}

.heading-tabs-wrapper___2fTuA.style-button-round___3kB9c .tabs___3m3vl:hover {
    color: #001e1f
}

.heading-tabs-wrapper___2fTuA.style-button-round___3kB9c .tabs___3m3vl.active___F7CBb {
    background-color: #001e1f;
    color: #f3f6fb
}

@media (min-width:768px) {
    .heading-tabs-wrapper___2fTuA.style-button-round___3kB9c .tabs___3m3vl {
        border-radius: 56px;
        padding: 12px 32px
    }
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK::-webkit-scrollbar {
    display: none
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
    width: calc(25vw - 10px);
    padding-bottom: 4px;
    border-bottom: 2px solid #dbdbdb;
    text-align: center;
    white-space: nowrap;
    font-weight: 600;
    cursor: pointer
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    position: relative;
    left: 0;
    bottom: -6px;
    background-color: #161a1d;
    transition: width .3s
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl.active___F7CBb:after,
.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl:hover:after {
    width: 100%
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl .icon-wrapper___2EW2o {
    margin-bottom: 12px
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl .icon-wrapper___2EW2o svg {
    width: calc(25vw - 10px)
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs-spacer___12szJ {
    flex-shrink: 0;
    width: 24px;
    border-bottom: 2px solid #dbdbdb
}

.heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs-spacer___12szJ:last-child {
    display: none
}

@media (min-width:768px) {
    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl {
        width: 115px
    }

    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl .icon-wrapper___2EW2o {
        margin-bottom: 16px
    }

    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs___3m3vl .icon-wrapper___2EW2o svg {
        width: 115px
    }

    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs-spacer___12szJ {
        width: calc(18.75% - 111.25px)
    }

    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK .tabs-spacer___12szJ:last-child {
        display: inherit;
        border-bottom: 2px solid #dbdbdb;
        flex-grow: 1
    }
}

@media (max-width:767px) {

    .heading-tabs-wrapper___2fTuA.style-button-round___3kB9c:after,
    .heading-tabs-wrapper___2fTuA.style-button-round___3kB9c:before {
        content: "";
        width: 20px;
        min-width: 20px
    }

    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK:after,
    .heading-tabs-wrapper___2fTuA.style-icon-text___3NLCK:before {
        content: "";
        width: 20px;
        min-width: 20px;
        border-bottom: 2px solid #dbdbdb
    }
}

@media (min-width:768px) {
    .heading-tabs-wrapper___2fTuA {
        padding-top: 64px;
        padding-bottom: 64px
    }
}

[class*=gr21-page-home___] [class*=services-section-wrapper___] .service-vertical[class*=services-section___]:first-child {
    border-top: 2px solid #e5e9f0 !important
}

.vertical-expand-collapse-btn___1i32P {
    text-align: center;
    cursor: pointer;
    margin-top: 16px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 2px solid #e5e9f0
}

@media (min-width:768px) {
    .vertical-expand-collapse-btn___1i32P {
        padding-left: calc(50% - 344px);
        padding-right: calc(50% - 344px)
    }
}

@media (min-width:1025px) {
    .vertical-expand-collapse-btn___1i32P {
        padding-left: calc(50% - 430px);
        padding-right: calc(50% - 430px)
    }
}

@media (min-width:1280px) {
    .vertical-expand-collapse-btn___1i32P {
        padding-left: calc(50% - 558px);
        padding-right: calc(50% - 558px)
    }
}

@media (min-width:1440px) {
    .vertical-expand-collapse-btn___1i32P {
        padding-left: calc(50% - 582px);
        padding-right: calc(50% - 582px)
    }
}

@media (min-width:1680px) {
    .vertical-expand-collapse-btn___1i32P {
        padding-left: calc(50% - 678px);
        padding-right: calc(50% - 678px)
    }
}

@media (min-width:1920px) {
    .vertical-expand-collapse-btn___1i32P {
        padding-left: calc(50% - 774px);
        padding-right: calc(50% - 774px)
    }
}

.vertical-expand-collapse-btn___1i32P .vertical-btn-collapse___1fU2O {
    display: none
}

.vertical-expand-collapse-btn___1i32P .vertical-btn-expand___KuMic {
    display: block
}

.vertical-expand-collapse-btn___1i32P .vertical-btn-content___35Pdn {
    padding-top: 16px;
    padding-bottom: 16px
}

.vertical-expand-collapse-btn___1i32P .vertical-btn-content___35Pdn h5 {
    margin: 0;
    color: #002e27
}

.vertical-expand-collapse-btn___1i32P .vertical-btn-content___35Pdn img {
    width: 20px;
    vertical-align: middle
}

@media (min-width:1025px) {
    .vertical-expand-collapse-btn___1i32P .vertical-btn-content___35Pdn img {
        width: 24px
    }
}

.section-requirement-hr___vjMyt {
    border-top: 2px solid #e5e9f0;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%
}

.section-faq-hr___DZkcP {
    border-top: 2px solid #242a2e;
    margin-top: 64px;
    width: 100%
}

.section-spacer___1szNN {
    height: 32px
}

.section-safety___3umaw [class*=container___] [class*=title___] {
    margin-top: 40px
}

.section-requirement___30AXS {
    margin-bottom: 32px
}

.tabs-wrapper___2CsFv {
    margin-top: 32px
}

@media (max-width:1024px) {
    .tabs-wrapper___2CsFv [class*=style-button-round___] [class*=tabs___] {
        padding: 8px 32px
    }
}

@media (min-width:1025px) {
    .section-requirement-hr___vjMyt {
        margin-top: 64px;
        margin-bottom: 48px
    }

    .section-faq-hr___DZkcP {
        margin-top: 96px
    }

    .section-spacer___1szNN {
        height: 48px
    }

    .section-requirement___30AXS {
        margin-bottom: 48px
    }
}

[class^=gr21-product-acquisition] .requirements-wrapper___3vwHy p {
    margin-bottom: 16px;
    margin-top: 8px
}

[class^=gr21-product-acquisition] .requirements-wrapper___3vwHy p:first-child {
    margin-top: 0
}

[class^=gr21-product-acquisition] .requirements-wrapper___3vwHy p:last-child {
    margin-bottom: 0;
    margin-top: 0
}

[class^=gr21-product-acquisition] .requirements-wrapper___3vwHy ul {
    margin-bottom: 24px;
    padding-left: 22px
}

[class^=gr21-product-acquisition] .requirements-wrapper___3vwHy ul:last-child {
    margin-bottom: 0
}

[class^=gr21-product-acquisition] .requirements-wrapper___3vwHy h3 {
    margin-bottom: 18px
}

.requirements-upper___AtP-w {
    padding-bottom: 32px
}

.requirements-image___rCcJk {
    max-width: 100%
}

.requirements-image___rCcJk .mobile-img-wrapper___3HXIZ {
    width: 100%;
    display: grid;
    overflow: hidden
}

.requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
    flex: 1;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%;
    height: 112%;
    width: 112%
}

@media (min-width:768px) {
    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        padding-top: 41.86047%
    }
}

.requirements-image___rCcJk .desktop-img-wrapper___v_icJ {
    width: 100%;
    display: grid;
    overflow: hidden
}

.requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
    display: none;
    height: 112%;
    width: 112%
}

.requirements-features___1pOM4 {
    padding-top: 64px
}

.requirements-features___1pOM4 .requirements-eyebrow___tQkZm {
    margin-bottom: 48px
}

.requirements-features___1pOM4 .requirements-brown___3SFq0 {
    background-color: #6b514c;
    padding: 24px 20px;
    color: #fff;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: 48px
}

.requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid #dbdbdb
}

.requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP h4 {
    margin-bottom: 16px
}

.requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP:first-child {
    padding-top: 0
}

.requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP:last-child {
    border-bottom: 0;
    padding-bottom: 0
}

@media (min-width:767px) and (max-width:1024px) {
    .requirements-features___1pOM4 .requirements-brown___3SFq0 {
        padding-left: calc(50vw - 344px)
    }
}

@media (min-width:1025px) {
    .requirements-upper___AtP-w {
        padding-bottom: 64px
    }

    .requirements-image___rCcJk .mobile-img-wrapper___3HXIZ .requirements-image-mobile___3-qs8 {
        display: none
    }

    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        display: inherit;
        flex: 1;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 131.25%
    }

    .requirements-features___1pOM4 {
        padding-top: 96px
    }

    .requirements-features___1pOM4 .requirements-brown___3SFq0 {
        padding: 24px 32px;
        width: auto;
        position: relative;
        left: auto;
        right: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 64px
    }

    .requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP h3 {
        margin-bottom: 16px
    }

    .requirements-features___1pOM4 .requirements-content-repeater-item___1t9UP:first-child {
        padding-top: 0
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        padding-top: 41.75627%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        padding-top: 41.75258%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        padding-top: 41.88791%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .requirements-image___rCcJk .desktop-img-wrapper___v_icJ .requirements-image-desktop___1rVyv {
        padding-top: 41.86047%
    }
}

.tabs-content-wrapper___3-WOX {
    margin-top: 48px
}

.tabs-content-wrapper___3-WOX .tabs-eyebrow___3booO {
    margin-bottom: 48px
}

.tabs-content-wrapper___3-WOX .tab-item___2yxDn:not(:last-child) {
    padding-bottom: 16px
}

.tabs-content-wrapper___3-WOX .tab-item___2yxDn h3 {
    margin-bottom: 8px
}

.tabs-content-wrapper___3-WOX .tab-item___2yxDn h4 {
    margin-bottom: 16px
}

.tabs-content-wrapper___3-WOX .tab-item___2yxDn ul {
    margin-bottom: 32px
}

.tabs-content-wrapper___3-WOX .cta-wrapper___3wR5g {
    color: #00804a;
    border-top: 2px solid #00804a;
    border-bottom: 2px solid #00804a;
    padding: 16px 0;
    margin-bottom: 32px;
    text-transform: capitalize
}

.tabs-content-wrapper___3-WOX .hr-light___vAv-h {
    border-top: 2px solid #e5e9f0;
    width: 100%;
    margin-bottom: 16px
}

@media (min-width:1025px) {
    .tabs-content-wrapper___3-WOX {
        margin-top: 64px
    }

    .tabs-content-wrapper___3-WOX .tabs-eyebrow___3booO {
        margin-bottom: 0
    }

    .tabs-content-wrapper___3-WOX .tab-item___2yxDn:not(:last-child) {
        padding-bottom: 32px
    }

    .tabs-content-wrapper___3-WOX .tab-item___2yxDn h3 {
        margin-bottom: 16px
    }

    .tabs-content-wrapper___3-WOX .hr-light___vAv-h {
        margin-bottom: 0
    }
}

.slider-wrapper___2cXX3 {
    padding: 64px 0
}

.slider-wrapper___2cXX3 .slider-info___FmB1u {
    margin-bottom: 56px
}

.slider-wrapper___2cXX3 .slider-info___FmB1u h3 {
    margin-bottom: 18px
}

.slider-wrapper___2cXX3 .slider-info___FmB1u p {
    margin-bottom: 0
}

@media (min-width:768px) {
    .slider-wrapper___2cXX3 .slider-mobile___3XoT0 .slider-item___1n30k {
        margin-left: calc(50vw - 344px);
        margin-right: 0
    }

    .slider-wrapper___2cXX3 .slider-mobile___3XoT0 .slider-item___1n30k .slider-pagi___2PQb2 {
        display: block
    }
}

@media (min-width:1025px) {
    .slider-wrapper___2cXX3 {
        padding: 96px 0
    }

    .slider-wrapper___2cXX3 .slider-info___FmB1u {
        margin-bottom: 96px
    }
}

.video-wrapper___344cn {
    background-color: #fff4eb;
    padding-top: 64px;
    padding-bottom: 64px
}

.video-wrapper___344cn h3,
.video-wrapper___344cn h5,
.video-wrapper___344cn p {
    margin: 0
}

.video-wrapper___344cn .video-info___28XAH {
    padding-bottom: 32px
}

.video-wrapper___344cn .video-info___28XAH h3 {
    margin-bottom: 32px
}

.video-wrapper___344cn .video-info___28XAH .video-info-title___YrtLg {
    order: 2;
    padding-left: 12px
}

.video-wrapper___344cn .video-info___28XAH .video-info-desc___3SvNN {
    order: 1;
    padding-left: 14px
}

.video-wrapper___344cn .video-info___28XAH .video-info-desc___3SvNN h3 {
    position: relative
}

.video-wrapper___344cn .video-info___28XAH .video-info-desc___3SvNN .ttm-quote___2iYTK:before {
    content: "“";
    position: absolute;
    margin-left: -14px
}

@media (min-width:1025px) {
    .video-wrapper___344cn {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .video-wrapper___344cn .video-info___28XAH {
        padding-bottom: 48px
    }

    .video-wrapper___344cn .video-info___28XAH h3 {
        margin-bottom: 0
    }

    .video-wrapper___344cn .video-info___28XAH h5 {
        margin-bottom: 2px
    }

    .video-wrapper___344cn .video-info___28XAH .video-info-title___YrtLg {
        order: 1;
        padding-left: 0
    }

    .video-wrapper___344cn .video-info___28XAH .gr21-col-l-1 {
        order: 2
    }

    .video-wrapper___344cn .video-info___28XAH .video-info-desc___3SvNN {
        order: 2;
        padding-left: 0
    }

    .video-wrapper___344cn .video-info___28XAH .video-info-desc___3SvNN h3 {
        position: relative
    }

    .video-wrapper___344cn .video-info___28XAH .video-info-desc___3SvNN .ttm-quote___2iYTK:before {
        margin-left: -20px
    }
}

.mission-wrapper___n950f {
    background-color: #f9f6f6;
    padding-top: 64px;
    padding-bottom: 64px
}

.mission-wrapper___n950f h4 {
    margin-bottom: 48px;
    color: #242a2e
}

.mission-wrapper___n950f ul {
    list-style: none;
    padding-left: 0;
    margin: 0
}

.mission-wrapper___n950f ul li {
    padding: 16px 0;
    border-bottom: 2px solid #e6d8d6;
    color: #242a2e
}

.mission-wrapper___n950f ul li:first-child {
    border-top: 2px solid #e6d8d6
}

.mission-wrapper___n950f .icon-wrapper___2x7xx {
    display: flex;
    align-items: flex-end;
    align-content: flex-end
}

.mission-wrapper___n950f .icon-wrapper___2x7xx .icon___2eDxZ {
    margin-bottom: 24px
}

.mission-wrapper___n950f .icon-wrapper___2x7xx .icon___2eDxZ svg {
    width: 86px;
    height: 72px;
    max-width: 86px
}

@media (min-width:1025px) {
    .mission-wrapper___n950f {
        background-color: #f9f6f6;
        padding-top: 72px;
        padding-bottom: 96px
    }

    .mission-wrapper___n950f ul li {
        padding: 20px 0
    }

    .mission-wrapper___n950f .icon-wrapper___2x7xx .icon___2eDxZ {
        margin-bottom: 0
    }

    .mission-wrapper___n950f .icon-wrapper___2x7xx .icon___2eDxZ svg {
        width: 112px;
        height: 94px;
        max-width: 112px
    }
}

.si-slider {
    position: relative
}

.si-slider>.uk-slider-items {
    align-items: flex-start
}

.si-slider>.uk-slider-items>li {
    min-height: 100vh;
    width: calc(100% - 128px);
    background: #f9f6f6;
    transition: background-color .5s;
    padding-top: 161px
}

.si-slider>.uk-slider-items>li.uk-active {
    background: #fff
}

.si-slider .uk-slidenav-container {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    z-index: 1;
    flex-direction: column;
    display: flex
}

.si-slider .uk-slidenav-container .slidenav-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.si-slider .uk-slidenav-container .slidenav-title-row a {
    padding: 4.5px 3px
}

.si-slider .uk-slidenav-container .slidenav-title-row a svg {
    fill: #383e43
}

.si-slider .uk-slidenav-container .slidenav-title-row .uk-invisible {
    visibility: visible !important;
    cursor: inherit
}

.si-slider .uk-slidenav-container .slidenav-title-row .uk-invisible svg {
    fill: #bac0c9
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin: 0 11px
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list ul {
    height: 28px;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    transition: all .5s ease
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li {
    list-style: none;
    flex: 0 0 33.33%;
    position: relative;
    overflow: hidden
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li div {
    position: absolute;
    white-space: nowrap;
    transition: all .5s ease;
    line-height: 28px;
    font-weight: 400;
    left: 0;
    transform: translateX(0);
    opacity: 0
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li.center div,
.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li.left div,
.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li.right div {
    opacity: 1;
    cursor: pointer
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li.center div {
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    cursor: inherit
}

.si-slider .uk-slidenav-container .slidenav-title-row .slidenav-title-list li.center~li>div {
    left: 100%;
    transform: translateX(-100%)
}

.si-slider .uk-slidenav-container .slidenav-page-number {
    font-size: 16px;
    color: #383e43;
    margin-bottom: 25px;
    text-align: center
}

.si-slider .side-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 64px;
    display: block
}

.si-slider .side-nav.nav-prev {
    left: 0
}

.si-slider .side-nav.nav-next {
    right: 0
}

.si-slider-modal {
    display: none;
    z-index: 1030
}

.si-slider-modal.uk-modal {
    padding: 0;
    background: #f9f6f6
}

.si-slider-modal .uk-modal-full {
    height: 100vh
}

.si-slider-modal .uk-modal-close {
    position: fixed;
    top: 48px;
    left: 36px;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    z-index: 2;
    background: #fff;
    cursor: pointer
}

.si-slider-modal .uk-modal-close>svg {
    position: absolute;
    left: 18px;
    top: 18px
}

.si-bottom-slider {
    display: block;
    position: relative;
    margin-top: 96px;
    padding-bottom: 196px
}

.si-bottom-slider .eyebrow {
    margin-bottom: 64px
}

.si-bottom-slider .uk-slidenav-container {
    position: absolute;
    top: 48px;
    right: 20px;
    z-index: 1
}

.si-bottom-slider .uk-slidenav-container a {
    margin-left: 10px
}

.si-bottom-slider .uk-slider-items>li {
    cursor: pointer;
    border-left: 2px solid #e6d8d6;
    padding: 0 20px
}

.si-bottom-slider .uk-slider-items>li a {
    margin-top: 48px;
    display: block
}

.si-bottom-slider .uk-invisible {
    visibility: visible !important;
    cursor: inherit
}

.si-bottom-slider .uk-invisible svg path {
    fill: #bac0c9
}

.si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
    width: calc(50% - 10px)
}

@media (min-width:768px) {
    .si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
        width: 344px
    }
}

@media (min-width:1025px) {
    .si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
        width: 430px
    }
}

@media (min-width:1280px) {
    .si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
        width: 558px
    }
}

@media (min-width:1440px) {
    .si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
        width: 582px
    }
}

@media (min-width:1680px) {
    .si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
        width: 678px
    }
}

@media (min-width:1920px) {
    .si-bottom-slider [class*=slider-desktop___] [class*=default-item___] {
        width: 774px
    }
}

@media (max-width:1279px) {
    .si-slider .uk-slidenav-container {
        top: 32px;
        left: 0;
        right: 0;
        margin: 0
    }

    .si-slider .side-nav {
        display: none
    }

    .si-slider .uk-slider-items>li {
        width: 100%
    }

    .si-slider .slidenav-title-list li {
        list-style: none;
        flex: 1 0 33.33%;
        position: relative;
        overflow: visible !important
    }

    .si-slider .slidenav-title-list li div {
        display: none
    }

    .si-slider .slidenav-title-list li.center div {
        display: block
    }

    .si-slider-modal .uk-modal-close {
        position: fixed;
        bottom: 24px;
        top: unset;
        left: calc(50% - 24px);
        transform: scale(.857)
    }

    .si-bottom-slider {
        margin-top: 48px;
        padding-bottom: 120px
    }

    .si-bottom-slider .eyebrow {
        margin-bottom: 48px
    }

    .si-bottom-slider .uk-slider-items>li {
        border-left: 0;
        padding: 0
    }
}

.spacer-bottom___2Y2eW {
    height: 64px
}

.section-color-banner___1KprA {
    margin-top: 32px;
    margin-bottom: 16px
}

@media (min-width:1025px) {
    .spacer-bottom___2Y2eW {
        height: 96px
    }

    .section-color-banner___1KprA {
        margin-top: 96px;
        margin-bottom: 32px
    }
}

[class*=section-content___] [class*=section-color-banner___] [class*=container___] {
    background-color: transparent !important
}

.uk-active [class*=section-content___] [class*=section-color-banner___] [class*=container___] {
    background-color: #fff4eb !important
}

.separator___3pKtJ {
    margin-top: 64px;
    margin-bottom: 32px;
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

@media (min-width:1025px) {
    .separator___3pKtJ {
        margin-top: 96px;
        margin-bottom: 48px
    }
}

.img-wrapper___1zNrD {
    margin-top: 32px
}

.img-wrapper___1zNrD .img-banner___36FSh {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.25%
}

@media (min-width:768px) {
    .img-wrapper___1zNrD .img-banner___36FSh {
        padding-top: 56.39535%
    }
}

@media (min-width:1025px) {
    .img-wrapper___1zNrD .img-banner___36FSh {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .img-wrapper___1zNrD .img-banner___36FSh {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .img-wrapper___1zNrD .img-banner___36FSh {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .img-wrapper___1zNrD .img-banner___36FSh {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .img-wrapper___1zNrD .img-banner___36FSh {
        padding-top: 41.86047%
    }
}

@media (max-width:1024px) {
    .desktop___2nD7Z {
        display: none
    }
}

@media (min-width:1025px) {
    .img-wrapper___1zNrD {
        margin-top: 64px
    }

    .mobile___2SblC {
        display: none
    }
}

.award-wrapper___3FyK_ .title___1-njT {
    margin-bottom: 56px
}

.award-wrapper___3FyK_ .item___2lyo3 {
    border-bottom: 2px solid #e5e9f0
}

.award-wrapper___3FyK_ .item___2lyo3:not(:first-child) {
    margin-top: 56px
}

.award-wrapper___3FyK_ .item___2lyo3 h4,
.award-wrapper___3FyK_ .item___2lyo3 h5 {
    margin-bottom: 16px
}

@media (min-width:1025px) {
    .award-wrapper___3FyK_ .title___1-njT {
        margin-bottom: 0
    }

    .award-wrapper___3FyK_ .item___2lyo3:not(:first-child) {
        margin-top: 64px
    }

    .award-wrapper___3FyK_ .item___2lyo3 h4,
    .award-wrapper___3FyK_ .item___2lyo3 h5 {
        margin-bottom: 20px
    }
}

.segment-wrapper___LalZY .content-desktop___yRe6m {
    display: none
}

.segment-wrapper___LalZY .content-mobile___eZIdM {
    display: block
}

.segment-wrapper___LalZY .img-wrapper___24_tU {
    display: grid;
    overflow: hidden;
    margin-top: 64px
}

.segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
    background-color: #f0e5e4;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
        padding-top: 56.19048%
    }
}

@media (min-width:1280px) {
    .segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
        padding-top: 56.20438%
    }
}

@media (min-width:1440px) {
    .segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
        padding-top: 55.94406%
    }
}

@media (min-width:1680px) {
    .segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
        padding-top: 56.88623%
    }
}

@media (min-width:1920px) {
    .segment-wrapper___LalZY .img-wrapper___24_tU .img-div___3naWV {
        padding-top: 56.5445%
    }
}

.segment-wrapper___LalZY .text-wrapper___uI57- h4 {
    margin-top: 24px
}

.segment-wrapper___LalZY .text-wrapper___uI57- p {
    margin-top: 8px
}

@media (min-width:1025px) {
    .segment-wrapper___LalZY .content-desktop___yRe6m {
        display: flex;
        flex-wrap: wrap
    }

    .segment-wrapper___LalZY .content-mobile___eZIdM {
        display: none
    }
}

.natural_top_margin___1szfY {
    margin-top: 96px
}

.natural_bottom_margin___pDaad {
    margin-bottom: 96px
}

#overview-section___2EZIN {
    background-size: cover;
    height: 590px;
    display: flex
}

#overview-section___2EZIN .header-text___2N7Hx {
    width: 50%
}

#overview-section___2EZIN p {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #fff
}

.grab-container___2UtgK {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

#overview-section___2EZIN .header-text___2N7Hx {
    margin-bottom: 20px;
    font-family: Inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    color: #fff
}

#overview-section___2EZIN .button___2WP6a {
    margin: 20px 0;
    color: #184440;
    background-color: #eef9f9
}

#overview-section___2EZIN .button-green___1V5CN,
#overview-section___2EZIN .button___2WP6a {
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize
}

#overview-section___2EZIN .button-green___1V5CN {
    color: #fff;
    background-color: #00b14f;
    margin: 20px 8px 20px 0
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___1szfY {
        margin-top: 64px !important
    }

    .natural_bottom_margin___pDaad {
        margin-bottom: 64px !important
    }

    .grab-container___2UtgK {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___2IcZN {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2G4w1 {
        display: none
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        width: 70%
    }

    #overview-section___2EZIN {
        background-size: cover;
        height: 504px;
        width: 100vw;
        margin-top: 0
    }

    #overview-section___2EZIN p {
        padding: 0 10px
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        margin-bottom: 20px;
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        padding: 0 10px
    }

    #overview-section___2EZIN .button-green___1V5CN {
        width: 100%;
        text-align: center;
        border: none
    }

    #overview-section___2EZIN .button___2WP6a {
        border: none;
        width: 100%;
        text-align: center;
        margin: 0
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    .natural_top_margin___1szfY {
        margin-top: 64px !important
    }

    .natural_bottom_margin___pDaad {
        margin-bottom: 64px !important
    }

    .grab-container___2UtgK {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___2IcZN {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2G4w1 {
        display: none
    }

    .bannerBtn___3j8nr {
        display: flex;
        flex-direction: column
    }

    #overview-section___2EZIN {
        background-size: cover;
        height: 504px;
        width: 100vw;
        margin-top: 0
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        width: 100%
    }

    #overview-section___2EZIN p {
        padding: 0 10px
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        margin-bottom: 20px;
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        padding: 0 10px
    }

    #overview-section___2EZIN .button-green___1V5CN {
        width: 100%;
        text-align: center;
        border: none
    }

    #overview-section___2EZIN .button___2WP6a {
        border: none;
        width: 100%;
        text-align: center;
        margin: 0
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .bannerBtn___3j8nr {
        display: flex;
        flex-direction: column
    }

    .header___3NQKP .grab_sidenav___3JOEZ {
        display: none
    }

    .natural_top_margin___1szfY {
        margin-top: 64px !important
    }

    .natural_bottom_margin___pDaad {
        margin-bottom: 64px !important
    }

    .grab-container___2UtgK {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___2IcZN {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2G4w1 {
        display: none
    }

    #overview-section___2EZIN {
        background-size: cover;
        height: 504px;
        width: 100vw;
        margin-top: 0
    }

    #overview-section___2EZIN p {
        padding: 0 10px
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        width: 100%;
        margin-bottom: 20px;
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        padding: 0 10px
    }

    #overview-section___2EZIN .button-green___1V5CN {
        width: 100%;
        text-align: center;
        border: none
    }

    #overview-section___2EZIN .button___2WP6a {
        border: none;
        width: 100%;
        text-align: center;
        margin: 0
    }
}

@media screen and (max-width:1272px) and (min-width:1024px) {
    .natural_top_margin___1szfY {
        margin-top: 64px !important
    }

    .natural_bottom_margin___pDaad {
        margin-bottom: 64px !important
    }

    .grab-container___2UtgK {
        width: calc(100vw - 32px);
        padding: 0 40px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___2IcZN {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2G4w1 {
        display: none
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        width: 70%
    }

    #overview-section___2EZIN {
        background-size: cover;
        height: 504px;
        width: 100vw;
        margin-top: 0
    }

    #overview-section___2EZIN p {
        padding: 0 10px
    }

    #overview-section___2EZIN .header-text___2N7Hx {
        margin-bottom: 20px;
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        padding: 0 10px
    }

    #overview-section___2EZIN .button-green___1V5CN {
        width: 100%;
        text-align: center;
        border: none
    }

    #overview-section___2EZIN .button___2WP6a {
        border: none;
        width: 100%;
        text-align: center;
        margin: 0
    }
}

@media screen and (min-width:1273px) {
    #overview-section___2EZIN {
        background-size: cover;
        height: 70vh
    }
}

@media only screen and (min-width:769px) {
    .bannerBtn___3j8nr {
        margin-top: 32px
    }
}

.women-carousel-gen-margin___1tw7n {
    margin-top: 30px !important
}

.natural_top_margin___3-lBN {
    margin-top: 96px
}

.natural_bottom_margin___2IB1w {
    margin-bottom: 96px
}

.grab-container___27OWz {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

#women-section1___26ImA {
    background-size: cover;
    height: 70vh
}

#women-section___3eKAX .grab-container___27OWz {
    margin-top: -14vh
}

#women-section___3eKAX .main-header-text___poFlb {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px
}

#women-section___3eKAX .main-header-subtext___7pcUq {
    padding-top: 10px;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

#women-section___3eKAX .slider-data-div___1xv17 {
    padding: 10px;
    background-color: #fff
}

#women-section___3eKAX .slider-data-div-tittle___2J0s5 {
    color: #242a2e;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px
}

#women-section___3eKAX .slider-data-div-description___1lSAK {
    color: #383e43;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

#women-section___3eKAX .arrowDiv___Txqsl:first-child {
    background: #f5f5f5
}

#women-section___3eKAX .arrowDiv___Txqsl:last-child {
    background: #eef9f9
}

#women-section___3eKAX .arrowDiv___Txqsl:first-child span {
    color: #bfbfbf
}

#women-section___3eKAX .arrowDiv___Txqsl:last-child span {
    color: #184440
}

#women-section___3eKAX .controls___2wDqB {
    color: #000
}

#women-section___3eKAX .slider-heading___zgDWn {
    color: var(--text-default-secondary, #fff);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 32px
}

#women-section___3eKAX .arrowDiv___Txqsl {
    width: 50px;
    height: 50px;
    margin: 0;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer
}

#women-section___3eKAX .arrowImg___2BJpy {
    margin-left: 12px;
    margin-top: 12px;
    width: 25px;
    height: 25px;
    font-size: 27px;
    font-weight: lighter;
    color: #184440
}

#women-section___3eKAX .carousel-main___2mRcB {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc(25% - 5px);
    overflow-x: auto;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    gap: 16px;
    scroll-behavior: smooth;
    scrollbar-width: none
}

#women-section___3eKAX .carousel-main___2mRcB::-webkit-scrollbar {
    display: none
}

#women-section___3eKAX .carousel-main___2mRcB.no-transition___a02Am {
    scroll-behavior: auto
}

#women-section___3eKAX .carousel-main___2mRcB.dragging___3fjRm {
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    scroll-behavior: auto
}

#women-section___3eKAX .carousel-main___2mRcB.dragging___3fjRm .card___pRTop {
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#women-section___3eKAX .carousel-main___2mRcB .card___pRTop {
    scroll-snap-align: start;
    overflow: hidden;
    list-style: none;
    cursor: pointer;
    flex-direction: column;
    border-radius: 16px;
    margin-bottom: 25px;
    box-shadow: 0 6px 40px 0 rgba(53, 78, 64, .05)
}

#women-section___3eKAX .carousel-main___2mRcB .card___pRTop img {
    width: 100%;
    height: 140px;
    -o-object-fit: cover;
    object-fit: cover
}

#women-section___3eKAX .grab-container___27OWz ul {
    margin: 0
}

.bottom-green-text___2zmAv a {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: Inter;
    font-size: 16px;
    font-style: semibold;
    font-weight: 600;
    line-height: 24px;
    color: #00b14f !important
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___3-lBN {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2IB1w {
        margin-bottom: 64px !important
    }

    .grab-container___27OWz {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___1pHxO {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___10wbQ {
        display: none
    }

    #women-section1___26ImA {
        background-size: cover;
        height: 362px
    }

    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -140px
    }

    #women-section___3eKAX .controls___2wDqB,
    #women-section___3eKAX .main-header-div___2Uj22 #desktopControl___33_Kr {
        display: none
    }

    #women-section___3eKAX .main-header-text___poFlb {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px
    }

    #women-section___3eKAX .carousel-main___2mRcB {
        grid-auto-columns: calc(33.33333% - 5px) !important
    }

    #women-section___3eKAX .slider-data-div-tittle___2J0s5 {
        color: #242a2e;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px
    }

    #women-section___3eKAX .slider-data-div-description___1lSAK {
        color: #383e43;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    .women-carousel-gen-margin___1tw7n {
        margin-top: 10px !important
    }

    .natural_top_margin___3-lBN {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2IB1w {
        margin-bottom: 64px !important
    }

    .grab-container___27OWz {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___1pHxO {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___10wbQ {
        display: none
    }

    #women-section1___26ImA {
        background-size: cover;
        height: 362px
    }

    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -24vh
    }

    #women-section___3eKAX .controls___2wDqB,
    #women-section___3eKAX .main-header-div___2Uj22 #desktopControl___33_Kr {
        display: none
    }

    #women-section___3eKAX .main-header-text___poFlb {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px
    }

    #women-section___3eKAX .carousel-main___2mRcB {
        grid-auto-columns: calc(50% - 5px) !important
    }

    #women-section___3eKAX .slider-data-div-tittle___2J0s5 {
        color: #242a2e;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px
    }

    #women-section___3eKAX .slider-data-div-description___1lSAK {
        color: #383e43;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .header___2ncTy .grab_sidenav___7mLjG {
        display: none
    }

    .natural_top_margin___3-lBN {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2IB1w {
        margin-bottom: 64px !important
    }

    .grab-container___27OWz {
        width: 100vw;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___1pHxO {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___10wbQ {
        display: none
    }

    #women-section1___26ImA {
        background-size: cover;
        height: 362px
    }

    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -190px
    }

    #women-section___3eKAX .controls___2wDqB,
    #women-section___3eKAX .main-header-div___2Uj22 #desktopControl___33_Kr {
        display: none
    }

    #women-section___3eKAX .main-header-text___poFlb {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px
    }

    #women-section___3eKAX .carousel-main___2mRcB {
        grid-auto-columns: calc(80% - 5px) !important
    }

    #women-section___3eKAX .slider-data-div-tittle___2J0s5 {
        color: #242a2e;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px
    }

    #women-section___3eKAX .slider-data-div-description___1lSAK {
        color: #383e43;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }
}

@media screen and (max-width:1272px) and (min-width:1024px) {
    .natural_top_margin___3-lBN {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2IB1w {
        margin-bottom: 64px !important
    }

    .grab-container___27OWz {
        width: calc(100vw - 32px);
        padding: 0 40px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___1pHxO {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___10wbQ {
        display: none
    }

    #women-section1___26ImA {
        background-size: cover;
        height: 362px
    }

    #women-section___3eKAX .controls___2wDqB,
    #women-section___3eKAX .main-header-div___2Uj22 #desktopControl___33_Kr {
        display: none
    }

    #women-section___3eKAX .main-header-text___poFlb {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px
    }

    #women-section___3eKAX .carousel-main___2mRcB {
        grid-auto-columns: calc(33.33333% - 5px) !important
    }

    #women-section___3eKAX .slider-data-div-tittle___2J0s5 {
        color: #242a2e;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px
    }

    #women-section___3eKAX .slider-data-div-description___1lSAK {
        color: #383e43;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }
}

@media screen and (max-width:320px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -190px
    }
}

@media screen and (max-width:280px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -270px
    }
}

@media screen and (max-width:425px) and (min-width:361px) {
    #women-section___3eKAX .carousel-main___2mRcB {
        grid-auto-columns: calc(80% - 5px) !important
    }
}

@media screen and (min-width:361px) and (max-width:460px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -180px
    }
}

@media screen and (min-width:461px) and (max-width:560px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -180px
    }
}

@media screen and (min-width:561px) and (max-width:660px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -170px
    }
}

@media screen and (min-width:661px) and (max-width:768px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -150px
    }
}

@media screen and (min-width:1025px) and (max-width:1440px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -170px
    }
}

@media screen and (min-width:1441px) {
    #women-section___3eKAX .grab-container___27OWz {
        margin-top: -170px
    }
}

.card1___3oRrU,
.carousel-main1___2P8G8,
.slide-controls-main___1awJq,
.women-section_id___1J4yU {
    outline: none
}

.natural_top_margin___3ZN-p {
    margin-top: 96px
}

.natural_bottom_margin___2viJo {
    margin-bottom: 96px
}

.grab-container___1_ESY {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV {
    display: flex;
    flex-direction: row
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa {
    flex: 3
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-0___2lAxU {
    flex: 1
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-2___3UKP0 {
    flex: 4
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .tittle-text___2oy9P {
    color: #383e43;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 15px
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .sub-tittle-text___2bcG2 p {
    color: #242a2e;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

#FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .sub-tittle-text-green___l44G3 {
    color: #00b14f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
}

#FAQs-section___1SzVm #FAQ-img___2oPCB {
    background-size: cover;
    height: 464px;
    margin-top: 96px;
    position: relative
}

#FAQs-section___1SzVm #FAQ-img___2oPCB img {
    position: absolute;
    width: 9vw;
    left: 40px;
    bottom: 39px
}

#FAQs-section___1SzVm #FAQ-img___2oPCB .banner-tittle___2SbGG {
    padding: 32px 0 0 32px;
    color: #242a2e;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    width: 50%;
    margin-bottom: 29px
}

#FAQs-section___1SzVm #FAQ-img___2oPCB p {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0 32px
}

#FAQs-section___1SzVm #FAQ-img___2oPCB .download-btn___Fbisb {
    display: none
}

.grab-accordion____Cw1b {
    cursor: pointer;
    transition: .4s;
    border-color: #dbdbdb;
    border-style: solid;
    border-width: 1px 0;
    background: #fff;
    padding: 24px 32px;
    text-align: left;
    color: #242a2e;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.grab-accordion-active___zgyCR {
    border-top: 2px solid #000;
    border-bottom: none
}

.grab-accordion-panel-active___2NsFr {
    border-bottom: 1px solid #000
}

.grab-accordion____Cw1b:after {
    color: #000;
    font-weight: 700;
    float: right;
    margin-left: 20px;
    font-size: 35px
}

.grab-accordion-active___zgyCR:after {
   
}

.grab-accordion-panel___15BQt {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out;
    padding: 0 64px 24px 32px
}

.grab-accordion-panel___15BQt,
.grab-accordion-panel___15BQt p span,
.grab-accordion-panel___15BQt ul li {
    color: #242a2e;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.grab-accordion-panel___15BQt .col-01___2yrlr {
    flex: 0.5
}

.grab-accordion-panel___15BQt .col-02___2zvu6 {
    flex: 5
}

.display-none___30riY {
    display: none !important
}

.vector-arrow___30m31 {
    padding-right: 20px
}

.mobile-app-section___3puMu {
    display: none
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___3ZN-p {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2viJo {
        margin-bottom: 64px !important
    }

    .grab-container___1_ESY {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___38_PU {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___eXPpF {
        display: none
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV {
        display: flex;
        flex-direction: column
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .tittle-text___2oy9P {
        color: #242a2e;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .sub-tittle-text___2bcG2,
    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-panel___15BQt {
        color: #242a2e;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-panel___15BQt {
        padding: 0 0 12px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB {
        padding: 225px 0 24px;
        background-size: contain;
        background-repeat: no-repeat;
        height: auto
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-active___zgyCR {
        border-top: 1px solid #000;
        border-bottom: none
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion____Cw1b {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .green-text___1WAf_ {
        color: #00b14f;
        font-family: Inter;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 20px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB .banner-tittle___2SbGG {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 16px 0 0 16px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB p {
        display: none
    }

    .mobile-app-section___3puMu .download-btn___Fbisb {
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        gap: 16px;
        align-self: stretch;
        border-radius: 50px;
        background: #00b14f;
        width: calc(100% - 128px);
        display: block;
        margin: 35px auto 16px
    }

    .desktop-app-section___2i7qO {
        display: none
    }

    .mobile-app-section___3puMu {
        display: block;
        background-color: #fff4eb;
        padding: 20px;
        border-radius: 20px
    }

    .mobile-app-section___3puMu .banner-tittle___2SbGG {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 16px 0 0 16px
    }
}

@media screen and (min-width:361px) and (max-width:768px) {

    .grab-accordion-panel___15BQt p span,
    .grab-accordion-panel___15BQt ul li {
        font-size: 14px !important
    }

    .natural_top_margin___3ZN-p {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2viJo {
        margin-bottom: 64px !important
    }

    .grab-container___1_ESY {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___38_PU {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___eXPpF {
        display: none
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV {
        display: flex;
        flex-direction: column
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .tittle-text___2oy9P {
        color: #242a2e;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .sub-tittle-text___2bcG2,
    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-panel___15BQt {
        color: #242a2e;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-panel___15BQt {
        padding: 0 16px 12px 0
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB {
        padding: 225px 0 24px;
        background-size: contain;
        background-repeat: no-repeat;
        height: auto
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-active___zgyCR {
        border-top: 1px solid #000;
        border-bottom: none
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion____Cw1b {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .green-text___1WAf_ {
        color: #00b14f;
        font-family: Inter;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 20px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB .banner-tittle___2SbGG {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 16px 0 0 16px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB .download-btn___Fbisb {
        padding: 19px 50px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        gap: 16px;
        align-self: stretch;
        border-radius: 50px;
        background: #00b14f;
        width: calc(100% - 128px);
        display: block;
        margin: 16px auto
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB img,
    .desktop-app-section___2i7qO {
        display: none
    }

    .mobile-app-section___3puMu {
        display: block;
        background-color: #fff4eb;
        padding: 24px;
        border-radius: 20px
    }

    .mobile-app-section___3puMu .download-btn___Fbisb {
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        gap: 16px;
        align-self: stretch;
        border-radius: 50px;
        background: #00b14f;
        width: calc(100% - 128px);
        display: block;
        margin: 35px auto 16px
    }

    .mobile-app-section___3puMu .banner-tittle___2SbGG {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 16px 0 0 16px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .header___N2Gwy .grab_sidenav___GmRuY {
        display: none
    }

    .grab-accordion-panel___15BQt p span,
    .grab-accordion-panel___15BQt ul li {
        font-size: 14px !important
    }

    .natural_top_margin___3ZN-p {
        margin-top: 64px !important
    }

    .natural_bottom_margin___2viJo {
        margin-bottom: 64px !important
    }

    .grab-container___1_ESY {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___38_PU {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___eXPpF {
        display: none
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV {
        display: flex;
        flex-direction: column
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .tittle-text___2oy9P {
        color: #242a2e;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .col-1___3PuFa .sub-tittle-text___2bcG2,
    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-panel___15BQt {
        color: #242a2e;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-panel___15BQt {
        padding: 0 16px 12px 0
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB {
        padding: 225px 0 24px;
        background-size: contain;
        background-repeat: no-repeat;
        height: auto
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion-active___zgyCR {
        border-top: 1px solid #000;
        border-bottom: none
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .grab-accordion____Cw1b {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0
    }

    #FAQs-section___1SzVm .FAQs-section-wrapper___2SHbV .green-text___1WAf_ {
        color: #00b14f;
        font-family: Inter;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 20px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB .banner-tittle___2SbGG {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 16px 0 0 16px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB p {
        display: none
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB .download-btn___Fbisb {
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        gap: 16px;
        align-self: stretch;
        border-radius: 50px;
        background: #00b14f;
        width: calc(100% - 32px);
        display: block;
        margin: 16px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB img {
        display: none
    }

    .mobile-app-section___3puMu .download-btn___Fbisb {
        padding: 15px 27px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        gap: 16px;
        align-self: stretch;
        border-radius: 50px;
        background: #00b14f;
        width: 100%;
        display: block;
        margin: 35px auto 16px
    }

    .mobile-app-section___3puMu .banner-tittle___2SbGG {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 16px 0 0 16px
    }

    .desktop-app-section___2i7qO {
        display: none
    }

    .mobile-app-section___3puMu {
        display: block;
        background-color: #fff4eb;
        padding: 14px;
        border-radius: 20px
    }

    .grab-accordion-panel___15BQt .col-01___2yrlr {
        flex: 1
    }
}

@media screen and (min-width:375px) and (max-width:425px) {
    .mobile-app-section___3puMu .download-btn___Fbisb {
        padding: 16px 50px;
        width: calc(100% - 15px)
    }

    .grab-accordion-panel___15BQt .col-01___2yrlr {
        flex: 1
    }
}

@media screen and (max-width:1272px) and (min-width:1024px) {
    .grab-container___1_ESY {
        width: calc(100vw - 32px);
        padding: 0 40px
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB {
        background-size: contain;
        height: 375px
    }

    .desktop-app-section___2i7qO {
        width: 50%
    }

    #FAQs-section___1SzVm #FAQ-img___2oPCB .banner-tittle___2SbGG {
        width: 100%
    }
}

.hidden___1RW2m {
    display: none !important
}

.faq_tab___Q4gRu {
    display: block
}

.natural_top_margin___24yW7 {
    margin-top: 96px
}

.natural_bottom_margin___10mh4 {
    margin-bottom: 96px
}

.grab-container___13nZA {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

.fixed___10M8t {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    width: 100%;
    background-color: #fff;
    z-index: 999
}

.text-caps___2-zL1 {
    text-transform: uppercase
}

.pointer___1YXXI {
    cursor: pointer
}

.navigation-left___3xTP_ li a {
    text-align: center
}

.mobile-dropdown-overview___2B2Yo a,
.navigation-left___3xTP_ li a {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px
}

.mobile-navigation___34E14 .arrow-icon___1smCN {
    align-items: center;
    margin-left: 7px
}

.header___2K1tO {
    height: 72px;
    width: 100%;
    border-bottom: 1px solid #e5e9f0
}

.header___2K1tO .grab_menu___1EN2Q {
    width: 32px;
    height: 32px;
    position: relative;
    top: 20px;
    left: 32px
}

.header___2K1tO .grab_logo___1prBd {
    position: absolute;
    width: 75px;
    height: 32px;
    top: 20px;
    left: 88px;
    gap: 1px
}

.header___2K1tO .grab_sidenav___1Eb0R {
    color: #242a2e;
    font-family: Inter;
    float: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: -10px;
    padding-right: 32px
}

.header___2K1tO .grab_sidenav___1Eb0R a img {
    width: 16px;
    height: 16px
}

.navigation___3NjrG ul {
    margin: 0
}

.navigation___3NjrG .dropdown___2zCBv ul {
    list-style: none;
    padding: 0
}

.mobile-navigation___34E14 .mobile-locale-dropdown___1ilro ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.navigation___3NjrG {
    height: 55px;
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #e5e9f0
}

.navigation___3NjrG .navigation-left___3xTP_ ul li {
    display: inline-flex;
    height: 50px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    font-style: medium;
    font-weight: 500;
    line-height: 26px
}

.mobile-navigation___34E14 {
    display: none
}

.navigation___3NjrG .navigation-left___3xTP_ {
    width: 90%;
    float: left
}

.navigation___3NjrG .dropdown___2zCBv li:hover {
    background: #d9fcde
}

.navigation___3NjrG .dropdown___2zCBv a {
    color: #242a2e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.navigation___3NjrG .dropdown___2zCBv .selected___3U3f9 a {
    color: #03633e
}

.navigation___3NjrG .dropdown___2zCBv .selected___3U3f9 {
    background: #c6f4cc
}

.navigation___3NjrG .dropdown___2zCBv {
    list-style: none;
    width: 200px;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 55px
}

.navigation___3NjrG .dropdown___2zCBv li a {
    padding: 12px 20px;
    cursor: pointer;
    display: block
}

.navigation___3NjrG .navigation-right___3xdEC .localeSelector___WSB6W {
    width: 10%;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center
}

.navigation___3NjrG .navigation-right___3xdEC .localeSelector___WSB6W img {
    max-width: unset
}

.navigation___3NjrG .navigation-right___3xdEC .localeSelector___WSB6W div {
    flex: 0.1;
    padding-top: 12px;
    margin: 0 2px;
    align-items: center;
    display: flex
}

.navigation___3NjrG .navigation-left___3xTP_ li:has(>a.active___2UUiR) {
    border-bottom: 6px solid #00b14f
}

.display-none___1bYx7 {
    display: none !important
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___24yW7 {
        margin-top: 64px !important
    }

    .natural_bottom_margin___10mh4 {
        margin-bottom: 64px !important
    }

    .grab-container___13nZA {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3ZH88 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2bLAF,
    .navigation___3NjrG {
        display: none
    }

    .mobile-navigation___34E14 {
        width: 100%;
        height: 40px;
        display: block;
        border-bottom: 1px solid #e5e9f0;
        background: #fff;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ {
        float: left;
        padding: 8px 16px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 7px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ div .arrow-icon___1smCN {
        padding: 0 5px
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro ul {
        list-style: none;
        padding: 0
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro {
        list-style: none;
        width: 200px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 40px
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro li:hover {
        background: #d9fcde
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro .selected___3U3f9 {
        background: #c6f4cc
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo {
        list-style: none;
        width: 100%;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 41px;
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 0 0 8px 8px
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo li {
        padding: 12px 20px;
        cursor: pointer;
        margin-bottom: 5px
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo li:has(>a.active___2UUiR) {
        border-left: 5px solid #00b14f
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo a {
        display: block
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro li a {
        padding: 12px 20px;
        cursor: pointer;
        display: block
    }

    .mobile-navigation___34E14 .navigation-right___3xdEC div {
        display: flex;
        flex-direction: row;
        padding: 0 2px;
        align-items: center
    }

    .mobile-navigation___34E14 .navigation-right___3xdEC {
        float: right;
        padding: 8px 16px
    }

    .fixed___10M8t {
        top: 70px
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    .natural_top_margin___24yW7 {
        margin-top: 64px !important
    }

    .natural_bottom_margin___10mh4 {
        margin-bottom: 64px !important
    }

    .grab-container___13nZA {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3ZH88 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2bLAF,
    .header___2K1tO .grab_sidenav___1Eb0R,
    .navigation___3NjrG {
        display: none
    }

    .mobile-navigation___34E14 {
        width: 100%;
        height: 40px;
        display: block;
        border-bottom: 1px solid #e5e9f0;
        background: #fff;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ {
        float: left;
        padding: 8px 16px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 7px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ div .arrow-icon___1smCN {
        padding: 0 5px
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro {
        list-style: none;
        width: 200px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 40px
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro li:hover {
        background: #d9fcde
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro .selected___3U3f9 {
        background: #c6f4cc
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo {
        list-style: none;
        width: 100%;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 41px;
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 0 0 8px 8px
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo li {
        padding: 12px 20px;
        cursor: pointer;
        margin-bottom: 5px
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo li:has(>a.active___2UUiR) {
        border-left: 5px solid #00b14f
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo a {
        display: block
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro li a {
        padding: 12px 20px;
        cursor: pointer;
        display: block
    }

    .mobile-navigation___34E14 .navigation-right___3xdEC div {
        display: flex;
        flex-direction: row;
        padding: 0 2px;
        align-items: center
    }

    .mobile-navigation___34E14 .navigation-right___3xdEC {
        float: right;
        padding: 8px 16px
    }

    .navigation-left___3xTP_ li a {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .header___2K1tO .grab_sidenav___1Eb0R {
        display: none
    }

    .natural_top_margin___24yW7 {
        margin-top: 64px !important
    }

    .natural_bottom_margin___10mh4 {
        margin-bottom: 64px !important
    }

    .grab-container___13nZA {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3ZH88 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2bLAF,
    .navigation___3NjrG {
        display: none
    }

    .fixed___10M8t {
        position: -webkit-sticky;
        position: sticky;
        top: 50px
    }

    .mobile-navigation___34E14 {
        width: 100%;
        height: 40px;
        display: block;
        border-bottom: 1px solid #e5e9f0;
        background: #fff;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ {
        float: left;
        padding: 8px 16px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 7px
    }

    .mobile-navigation___34E14 .navigation-left___3xTP_ div .arrow-icon___1smCN {
        padding: 0 5px
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro {
        list-style: none;
        width: 200px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 40px
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro li:hover {
        background: #d9fcde
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro .selected___3U3f9 {
        background: #c6f4cc
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo {
        list-style: none;
        width: 100%;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 41px;
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 0 0 8px 8px
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo li {
        padding: 12px 20px;
        cursor: pointer;
        margin-bottom: 5px
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo li:has(>a.active___2UUiR) {
        border-left: 5px solid #00b14f
    }

    .mobile-navigation___34E14 .mobile-dropdown-overview___2B2Yo a {
        display: block
    }

    .mobile-navigation___34E14 .mobile-locale-dropdown___1ilro li {
        padding: 12px 20px;
        cursor: pointer;
        display: block
    }

    .mobile-navigation___34E14 .navigation-right___3xdEC div {
        display: flex;
        flex-direction: row;
        padding: 0 2px;
        align-items: center
    }

    .mobile-navigation___34E14 .navigation-right___3xdEC {
        float: right;
        padding: 8px 16px
    }

    .navigation-left___3xTP_ li a {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px
    }
}

@media screen and (min-width:375px) and (max-width:425px) {
    .fixed___10M8t {
        top: 50px
    }

    .navigation-left___3xTP_ li a {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px
    }
}

@media screen and (min-width:1025px) and (max-width:1163px) {
    .navigation___3NjrG .navigation-left___3xTP_ {
        width: 87%
    }
}

.arrow-icon1___39Fl7,
.arrow-icon___1smCN,
.deskhideshow___1MQmL,
.desktop-link-anchor___H4it8,
.desktopnav___24jo1,
.eicon-globe___3cRhz,
.language_icon___1V9ZK,
.mobile-dropdown-overview-anchor___3akRq,
.mobile-nav-root___JMZUX,
.mobilehideshow___1z4XJ,
.mobilehideshowover___3wkwk {
    outline: none
}

.natural_top_margin___1zntq {
    margin-top: 96px
}

.natural_bottom_margin___xrA_Q {
    margin-bottom: 96px
}

.grab-container___1HMGf {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

#requirement-section___1zCPQ .tittle-text___Ze5lu {
    color: #383e43;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px
}

#requirement-section___1zCPQ .accordion-container___NXB7G {
    margin-top: 24px
}

#requirement-section___1zCPQ .grab-accordion-main___1cYw3 {
    width: 100%;
    padding: 24px 32px;
    cursor: pointer;
    color: #242a2e;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 16px;
    border: 1px solid #dbdbdb;
    background: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between
}

#requirement-section___1zCPQ button:focus {
    outline-offset: -6px;
    outline: none
}

#requirement-section___1zCPQ .grab-accordion-main___1cYw3:not(:last-child) {
    margin-bottom: 24px
}

#requirement-section___1zCPQ .grab-accordion-main-active___1uI9N {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #000;
    border-bottom: none;
    margin: 0 !important
}

#requirement-section___1zCPQ .grab-accordion-main-panel-active___3OM_U {
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 25px;
    padding-right: 11px;
    font-size: 16px
}

#requirement-section___1zCPQ .grab-accordion-main___1cYw3:after {
    color: #000;
    font-weight: 700;
    float: right;
    margin-left: 5px;
    font-size: 35px
}

#requirement-section___1zCPQ .grab-accordion-main-active___1uI9N:after {
    
}

#requirement-section___1zCPQ .grab-accordion-main-panel___1U6CV {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out;
    padding: 0 24px;
    color: #242a2e;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px
}

.grab-accordion-main-panel___1U6CV .col-01___2DIhX {
    flex: 0.5
}

.grab-accordion-main-panel___1U6CV .col-02___9NU8y {
    flex: 5
}

.display-none___3hfnm {
    display: none !important
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___1zntq {
        margin-top: 64px !important
    }

    .natural_bottom_margin___xrA_Q {
        margin-bottom: 64px !important
    }

    .grab-container___1HMGf {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___q3zXM {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break____73h2 {
        display: none
    }

    #requirement-section___1zCPQ .grab-accordion-main___1cYw3 {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    #requirement-section___1zCPQ .grab-accordion-main-panel___1U6CV {
        padding: 0 20px 20px
    }

    #requirement-section___1zCPQ .grab-accordion-main-panel___1U6CV ul {
        padding-left: 20px
    }

    #requirement-section___1zCPQ .grab-accordion-main___1cYw3 {
        padding: 24px 16px
    }

    #requirement-section___1zCPQ .grab-accordion-main___1cYw3:after {
        margin-left: 25px
    }

    .natural_top_margin___1zntq {
        margin-top: 64px !important
    }

    .natural_bottom_margin___xrA_Q {
        margin-bottom: 64px !important
    }

    .grab-container___1HMGf {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___q3zXM {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break____73h2 {
        display: none
    }

    #requirement-section___1zCPQ .grab-accordion-main___1cYw3 {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .header___3HPsa .grab_sidenav___2woTo {
        display: none
    }

    .accordion-main-panel-active___cWfVX {
        padding-left: 40px;
        padding-right: 10px
    }

    .natural_top_margin___1zntq {
        margin-top: 64px !important
    }

    .natural_bottom_margin___xrA_Q {
        margin-bottom: 64px !important
    }

    .grab-container___1HMGf {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___q3zXM {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break____73h2 {
        display: none
    }

    #requirement-section___1zCPQ .grab-accordion-main___1cYw3 {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }

    #requirement-section___1zCPQ .tittle-text___Ze5lu {
        color: #000;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-transform: capitalize
    }
}

@media screen and (min-width:375px) and (max-width:425px) {
    .fixed___2RAt3 {
        top: 50px
    }
}

@media screen and (max-width:1272px) and (min-width:1024px) {
    .grab-container___1HMGf {
        width: calc(100vw - 32px);
        padding: 0 40px
    }

    .natural_top_margin___1zntq {
        margin: 64px 0 0 !important
    }
}

.hidden___1Ge1o {
    display: none
}

.jsreqrun___1tL0I,
.req_tab___kFUBn {
    outline: none
}

.sign-up-button-div___19T62 {
    width: 400px;
    margin: 20px auto;
    text-align: center
}

.sign-up-wrapper___1Dpts {
    width: 100%;
    border-top: 1px solid #e5e9f0;
    display: none;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding: 10px 0
}

.sign-up-button-div___19T62 .sign-up-button___8kuq6 {
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    border: 1px solid #e5e9f0;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    background-color: #00b14f;
    padding: 12px 75px
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .sign-up-button-div___19T62 {
        width: 300px;
        margin: 10px auto
    }

    .sign-up-button-div___19T62 .sign-up-button___8kuq6 {
        width: 300px;
        margin: 0 auto;
        color: #fff;
        background-color: #00b14f;
        padding: 12px 75px;
        border-radius: 56px
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    .natural_top_margin___RPqrL {
        margin-top: 64px !important
    }

    .natural_bottom_margin___3Cfcl {
        margin-bottom: 64px !important
    }

    .grab-container___26xem {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___1Dpts {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2BIfN {
        display: none
    }

    .sign-up-button-div___19T62 {
        width: 300px;
        margin: 10px auto
    }

    .sign-up-button-div___19T62 .sign-up-button___8kuq6 {
        width: 300px;
        margin: 0 auto;
        background-color: #00b14f;
        padding: 12px 75px;
        border-radius: 56px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .header___1bXC7 .grab_sidenav___OcUSI {
        display: none
    }

    .natural_top_margin___RPqrL {
        margin-top: 64px !important
    }

    .natural_bottom_margin___3Cfcl {
        margin-bottom: 64px !important
    }

    .grab-container___26xem {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___1Dpts {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2BIfN {
        display: none
    }

    .sign-up-button-div___19T62 {
        width: 300px;
        margin: 10px auto
    }

    .sign-up-button-div___19T62 .sign-up-button___8kuq6 {
        width: 300px;
        margin: 0 auto;
        background-color: #00b14f;
        padding: 9px 50px;
        border-radius: 56px
    }
}

.natural_top_margin___3DsZF {
    margin-top: 96px
}

.natural_bottom_margin___1pRlC {
    margin-bottom: 96px
}

.grab-container___3Ni-p {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

#stories-section___2EgaR .main-header-text___V5OJh {
    color: #383e43;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 10px
}

#stories-section___2EgaR #desktopControl___3SXHV {
    display: block
}

#stories-section___2EgaR #mobileControl___3GH2b {
    display: none
}

#stories-section___2EgaR .stories___1zHpD {
    padding: 0
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr {
    border-radius: 16px;
    padding: 24px;
    border: 1px solid #dbdbdb;
    background: #fff
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN {
    color: #242a2e;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 9px
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN:last-child {
    padding-bottom: 0
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-description___2fqqy {
    color: #383e43;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 10px
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-description-green___1FtuE {
    color: #00b14f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN {
    display: flex;
    flex-direction: row
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN .story-photo___2W1OD img {
    width: 48px;
    height: 48px
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN .story-name-details___14cxl {
    flex: 5;
    padding: 0 20px
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name-details___14cxl .story-name-details-date___5v1Pr {
    color: #242a2e;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-top: 5px
}

#stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name-details___14cxl .story-name-details-name___9YWXm {
    color: #242a2e;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px
}

#grab-stories-footer___14tzT {
    display: flex;
    flex-direction: row
}

#grab-stories-footer___14tzT .story-name-details___14cxl {
    flex: 4 !important;
    padding: 0 0 0 10px !important
}

#grab-stories-footer___14tzT .story___h0dNr {
    flex: 1
}

#grab-stories-footer___14tzT .story___h0dNr:not(:last-child) {
    margin-right: 16px
}

.controls___2wp8J {
    color: #000
}

.slider-heading___3pAqq {
    color: var(--text-default-secondary, #fff);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 32px
}

.arrowDiv___tZqfI {
    width: 50px;
    height: 50px;
    margin: 0;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer
}

.arrowDiv___tZqfI:first-child {
    background: #f5f5f5
}

.arrowDiv___tZqfI:last-child {
    background: #eef9f9
}

.arrowDiv___tZqfI:first-child span {
    color: #bfbfbf
}

.arrowDiv___tZqfI:last-child span {
    color: #184440
}

.arrowDiv___tZqfI span {
    font-size: 30px;
    margin-left: 17%;
    margin-top: 20%;
    font-weight: lighter
}

.slide-controls___37kYX i:last-child span {
    font-size: 30px;
    margin-left: 19%;
    margin-top: 20%;
    font-weight: lighter
}

.arrowImg___2XhNS {
    margin-left: 12px;
    margin-top: 12px;
    width: 25px;
    height: 25px
}

.wrapper___3Ceu8 .carousel___K0kuO {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc(33.33333% - 16px);
    overflow-x: auto;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    gap: 16px;
    scroll-behavior: smooth;
    scrollbar-width: none
}

.carousel___K0kuO::-webkit-scrollbar {
    display: none
}

.carousel___K0kuO.no-transition___1bQXP {
    scroll-behavior: auto
}

.carousel___K0kuO.dragging___1gnFY {
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    scroll-behavior: auto
}

.carousel___K0kuO.dragging___1gnFY .card___1AY9h {
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.carousel___K0kuO .card___1AY9h {
    scroll-snap-align: start;
    overflow: hidden;
    list-style: none;
    cursor: pointer;
    flex-direction: column
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___3DsZF {
        margin-top: 64px !important
    }

    .natural_bottom_margin___1pRlC {
        margin-bottom: 64px !important
    }

    .grab-container___3Ni-p {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3HVlt {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2DgrR {
        display: none
    }

    #grab-stories-footer___14tzT {
        display: flex;
        flex-direction: column
    }

    #grab-stories-footer___14tzT .story___h0dNr:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px
    }

    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN .story-name-details___14cxl {
        flex: 10
    }

    #stories-section___2EgaR #desktopControl___3SXHV {
        display: none
    }

    #stories-section___2EgaR #mobileControl___3GH2b {
        display: block
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX {
        display: flex;
        flex-direction: row
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX div {
        flex: 1;
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    #stories-section___2EgaR .wrapper___3Ceu8 .carousel___K0kuO {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 100% !important;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        gap: 16px;
        scroll-behavior: smooth;
        scrollbar-width: none
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name-details___14cxl .story-name-details-date___5v1Pr {
        font-size: 16px
    }

    .natural_top_margin___3DsZF {
        margin-top: 64px !important
    }

    .natural_bottom_margin___1pRlC {
        margin-bottom: 64px !important
    }

    .grab-container___3Ni-p {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3HVlt {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2DgrR {
        display: none
    }

    #grab-stories-footer___14tzT {
        display: flex;
        flex-direction: column
    }

    #grab-stories-footer___14tzT .story___h0dNr:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px
    }

    #stories-section___2EgaR #desktopControl___3SXHV {
        display: none
    }

    #stories-section___2EgaR #mobileControl___3GH2b {
        display: block
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX {
        display: flex;
        flex-direction: row
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX div {
        flex: 1;
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    #stories-section___2EgaR .wrapper___3Ceu8 .carousel___K0kuO {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 100% !important;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        gap: 16px;
        scroll-behavior: smooth;
        scrollbar-width: none
    }

    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN {
        flex-wrap: wrap
    }

    #grab-stories-footer___14tzT .story-name___3etVN .arrowDiv___tZqfI {
        margin-top: 12px
    }

    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN .story-name-details___14cxl {
        flex: none;
        margin-right: 20px
    }

    #stories-section___2EgaR .grab-container___3Ni-p {
        padding: 0
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name-details___14cxl .story-name-details-date___5v1Pr {
        font-size: 16px
    }

    .natural_top_margin___3DsZF {
        margin-top: 64px !important
    }

    .natural_bottom_margin___1pRlC {
        margin-bottom: 64px !important
    }

    .grab-container___3Ni-p {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3HVlt {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___2DgrR {
        display: none
    }

    #grab-stories-footer___14tzT {
        display: flex;
        flex-direction: column
    }

    #grab-stories-footer___14tzT .story___h0dNr:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px
    }

    #stories-section___2EgaR #desktopControl___3SXHV {
        display: none
    }

    #stories-section___2EgaR #mobileControl___3GH2b {
        display: block
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX {
        display: flex;
        flex-direction: row
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX div:nth-child(2) .arrowDiv___tZqfI {
        background: #f5f5f5
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX div:nth-child(2) .arrowDiv___tZqfI span {
        color: #bfbfbf
    }

    #stories-section___2EgaR #mobileControl___3GH2b .slide-controls___37kYX div {
        flex: 1;
        color: #242a2e;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    #stories-section___2EgaR .wrapper___3Ceu8 .carousel___K0kuO {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 100% !important;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        gap: 16px;
        scroll-behavior: smooth;
        scrollbar-width: none
    }

    #stories-section___2EgaR .stories___1zHpD .card___1AY9h .story-name___3etVN {
        flex-wrap: nowrap
    }

    #grab-stories-footer___14tzT .story-name___3etVN .arrowDiv___tZqfI {
        margin-top: 12px
    }

    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-description___2fqqy p {
        font-size: 14px
    }

    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN .story-name-details___14cxl {
        flex: none
    }

    #grab-stories-footer___14tzT .story-photo___2W1OD {
        grid-area: logo
    }

    #grab-stories-footer___14tzT .story-name-details___14cxl {
        grid-area: que
    }

    #grab-stories-footer___14tzT .arrowDiv___tZqfI {
        grid-area: link
    }

    #stories-section___2EgaR .stories___1zHpD .story___h0dNr .story-name___3etVN {
        display: grid;
        grid-template-areas: "logo logo que que " "logo logo link link ";
        gap: 10px
    }
}

@media screen and (max-width:1272px) and (min-width:1024px) {
    .grab-container___3Ni-p {
        width: calc(100vw - 32px);
        padding: 0 40px
    }
}

.card1___21vf6,
.carousel1___1jIX6,
.slider-mobile-end___2yCW6,
.slider-mobile-start___1cUi1,
.slider-mobile___3Gtz4,
.wrapper1___2QLR9 {
    outline: none
}

.natural_top_margin___3XpGT {
    margin-top: 96px
}

.natural_bottom_margin___Av4rh {
    margin-bottom: 96px
}

.grab-container___gY9XG {
    width: 1192px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column
}

#why-join-us-section___Yw9kz .tab-img___10WSj {
    width: 46px;
    height: 20px;
    padding-left: 10px
}

#why-join-us-section___Yw9kz ul {
    margin: 0;
    padding: 0
}

#why-join-us-section___Yw9kz ul li {
    text-decoration: none;
    list-style-type: none;
    display: inline-block;
    padding: 16px 24px;
    align-items: center;
    border-radius: 50px;
    background-color: #eef9f9;
    color: #184440;
    cursor: pointer;
    margin-right: 20px;
    transition-duration: .5s;
    transition-timing-function: linear
}

#why-join-us-section___Yw9kz .active___2u1Ie,
#why-join-us-section___Yw9kz ul li {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize
}

#why-join-us-section___Yw9kz .active___2u1Ie {
    background-color: #184440 !important;
    color: #f3f6fb;
    width: auto
}

#why-join-us-section___Yw9kz .hidden___2HHBB {
    display: none
}

#why-join-us-section___Yw9kz .tittle-text___22rM8 {
    margin-bottom: 40px;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500 !important;
    font-weight: 700;
    line-height: 40px
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC {
    margin-top: 50px
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .grid___4FX34 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around
}

.learn-more___2Lfcj {
    width: 99%;
    margin-top: 10px
}

.learn-more___2Lfcj a {
    color: #00b14f
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tiles___gfSmB {
    flex: 0 0 32%;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #dbdbdb;
    background: #fff;
    margin: 10px 0
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tile___aPfZR {
    padding: 24px;
    display: flex;
    flex-direction: row;
    gap: 24px
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tile___aPfZR .partA___zKUop {
    width: 20%;
    padding: 30px;
    display: inline-block;
    position: relative
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tile___aPfZR .partA___zKUop img {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tile___aPfZR .partB___1L_Pj {
    width: 80%;
    display: flex;
    gap: 8px;
    flex-direction: column
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tile___aPfZR .partB___1L_Pj .partB-tittle___cXGZ7 {
    color: #242a2e;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px
}

#why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tile___aPfZR .partB___1L_Pj .partB-sub-tittle___36a94 {
    color: #383e43;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

#why-join-us-section___Yw9kz {
    margin-bottom: 48px !important
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .natural_top_margin___3XpGT {
        margin-top: 64px !important
    }

    .grab-container___gY9XG {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3Z648 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___GXUhb {
        display: none
    }

    #why-join-us-section___Yw9kz {
        margin-bottom: 32px !important
    }

    #why-join-us-section___Yw9kz ul {
        display: flex;
        grid-auto-flow: column;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz ul li {
        scroll-snap-align: start;
        overflow: hidden;
        list-style: none;
        cursor: pointer;
        flex-direction: column;
        text-align: center
    }

    #why-join-us-section___Yw9kz .active___2u1Ie,
    #why-join-us-section___Yw9kz ul li {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: capitalize
    }

    #why-join-us-section___Yw9kz .active___2u1Ie {
        background-color: #184440 !important;
        color: #f3f6fb;
        padding-top: 15px
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5::-webkit-scrollbar {
        display: none
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5 {
        -ms-overflow-style: none;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tiles___gfSmB {
        flex: 0 0 100%;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #dbdbdb;
        background: #fff;
        margin: 10px 16px
    }

    #why-join-us-section___Yw9kz .tittle-text___22rM8 {
        margin-bottom: 24px
    }
}

@media screen and (min-width:361px) and (max-width:768px) {
    .natural_top_margin___3XpGT {
        margin-top: 64px !important
    }

    .natural_bottom_margin___Av4rh {
        margin-bottom: 64px !important
    }

    .grab-container___gY9XG {
        width: calc(100vw - 32px);
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3Z648 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___GXUhb {
        display: none
    }

    #why-join-us-section___Yw9kz {
        margin-bottom: 32px !important
    }

    #why-join-us-section___Yw9kz ul {
        display: flex;
        grid-auto-flow: column;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz ul li {
        scroll-snap-align: start;
        overflow: hidden;
        list-style: none;
        cursor: pointer;
        flex-direction: column;
        padding-top: 22px;
        text-align: center;
        margin-right: 8px
    }

    #why-join-us-section___Yw9kz .active___2u1Ie,
    #why-join-us-section___Yw9kz ul li {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: capitalize
    }

    #why-join-us-section___Yw9kz .active___2u1Ie {
        background-color: #184440 !important;
        color: #f3f6fb;
        padding-top: 20px
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5::-webkit-scrollbar {
        display: none
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5 {
        -ms-overflow-style: none;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tiles___gfSmB {
        flex: 0 0 100%;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #dbdbdb;
        background: #fff
    }

    #why-join-us-section___Yw9kz .tittle-text___22rM8 {
        margin-bottom: 24px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .header___2jPVc .grab_sidenav___DAVBw {
        display: none
    }

    .natural_top_margin___3XpGT {
        margin-top: 64px !important
    }

    .natural_bottom_margin___Av4rh {
        margin-bottom: 64px !important
    }

    .grab-container___gY9XG {
        width: calc(100vw - 32px);
        padding: 0 16px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3Z648 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___GXUhb {
        display: none
    }

    #why-join-us-section___Yw9kz {
        margin-bottom: 32px !important
    }

    #why-join-us-section___Yw9kz ul {
        display: grid;
        grid-auto-flow: column;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz ul li {
        scroll-snap-align: start;
        overflow: hidden;
        list-style: none;
        cursor: pointer;
        flex-direction: column;
        padding-top: 22px;
        text-align: center
    }

    #why-join-us-section___Yw9kz .active___2u1Ie,
    #why-join-us-section___Yw9kz ul li {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: capitalize
    }

    #why-join-us-section___Yw9kz .active___2u1Ie {
        background-color: #184440 !important;
        color: #f3f6fb
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5::-webkit-scrollbar {
        display: none
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5 {
        -ms-overflow-style: none;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tiles___gfSmB {
        flex: 0 0 100%;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #dbdbdb;
        background: #fff
    }

    #why-join-us-section___Yw9kz .tittle-text___22rM8 {
        margin-bottom: 24px
    }
}

@media screen and (max-width:1272px) and (min-width:1024px) {
    .natural_top_margin___3XpGT {
        margin-top: 64px !important
    }

    .natural_bottom_margin___Av4rh {
        margin-bottom: 64px !important
    }

    .grab-container___gY9XG {
        width: calc(100vw - 32px);
        padding: 0 40px;
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .sign-up-wrapper___3Z648 {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999
    }

    .break___GXUhb {
        display: none
    }

    #why-join-us-section___Yw9kz {
        margin-bottom: 32px !important
    }

    #why-join-us-section___Yw9kz ul {
        display: flex;
        grid-auto-flow: column;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz ul li {
        scroll-snap-align: start;
        overflow: hidden;
        list-style: none;
        cursor: pointer;
        flex-direction: column;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        text-align: center
    }

    #why-join-us-section___Yw9kz .active___2u1Ie,
    #why-join-us-section___Yw9kz ul li {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: capitalize
    }

    #why-join-us-section___Yw9kz .active___2u1Ie {
        background-color: #184440 !important;
        color: #f3f6fb;
        width: 230px;
        padding-top: 15px
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5::-webkit-scrollbar {
        display: none
    }

    #why-join-us-section___Yw9kz .tabs___3fPj5 {
        -ms-overflow-style: none;
        scrollbar-width: none
    }

    #why-join-us-section___Yw9kz .why-join-us-section-container___1x7kC .tiles___gfSmB {
        flex: 0 0 100%;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #dbdbdb;
        background: #fff;
        margin: 10px 16px
    }

    #why-join-us-section___Yw9kz .tittle-text___22rM8 {
        margin-bottom: 24px
    }
}

@media screen and (min-width:361px) and (max-width:515px) {
    #why-join-us-section___Yw9kz ul {
        display: grid;
        grid-auto-flow: column;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none
    }
}

.whyjoinus-container___sK94d {
    outline: none
}

.sign-up-wrapper___8L5gN {
    width: 100%;
    border-top: 1px solid #e5e9f0;
    display: none;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0
}

.green-text___1-Gum {
    color: #00b14f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .header___3IKLn .grab_sidenav___2P05B {
        display: none
    }
}

.gr21-page-airport___1v3Nj p:empty {
    display: none
}

.gr21-page-airport___1v3Nj .top-padding {
    padding-top: 64px
}

.gr21-page-airport___1v3Nj .uk-accordion img {
    width: 100%
}

.gr21-page-airport___1v3Nj .uk-accordion img:not(:first-child) {
    margin-bottom: 8px;
    margin-top: 32px
}

.gr21-page-airport___1v3Nj .section-features___2ePNp {
    margin-top: 32px;
    margin-bottom: 32px
}

.gr21-page-airport___1v3Nj .section-services___3XwBh {
    margin-top: 32px
}

.gr21-page-airport___1v3Nj .section-faq___1Z5u9 {
    margin-bottom: 64px
}

.gr21-page-airport___1v3Nj .section-slider___iLaeC {
    padding-top: 64px;
    padding-bottom: 64px
}

.gr21-page-airport___1v3Nj .section-slider___iLaeC .uk-slider-items>li {
    padding: 0 20px
}

.gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
    width: calc(50% - 10px)
}

@media (min-width:768px) {
    .gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
        width: 344px
    }
}

@media (min-width:1025px) {
    .gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
        width: 430px
    }
}

@media (min-width:1280px) {
    .gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
        width: 558px
    }
}

@media (min-width:1440px) {
    .gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
        width: 582px
    }
}

@media (min-width:1680px) {
    .gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
        width: 678px
    }
}

@media (min-width:1920px) {
    .gr21-page-airport___1v3Nj .section-slider___iLaeC [class*=slider-desktop___] [class*=default-item___] {
        width: 774px
    }
}

.gr21-page-airport___1v3Nj .section-slider___iLaeC .slider-title {
    margin-bottom: 48px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R {
    padding-top: 64px;
    padding-bottom: 32px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R .section-tabs-title___3kWao {
    margin-bottom: 48px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R .tabs-title-wrapper___3Fmyu {
    padding-bottom: 0
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R .tabs-title-wrapper___3Fmyu h3 {
    margin-bottom: 0
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faq-title___] {
    display: none
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faq-col1___] h3 {
    margin-bottom: 0
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faqs__] {
    padding-bottom: 0 !important
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faqs__] .uk-accordion>li:last-child {
    border-bottom: none
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faqs__] .uk-open>.uk-accordion-content {
    padding-bottom: 32px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faqs__] .uk-open>.uk-accordion-content .faq_cta {
    display: flex
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faqs__] .uk-open>.uk-accordion-content .faq_cta a {
    color: #fff;
    margin-top: 24px;
    padding: 12px 24px;
    background: #00b14f;
    border-radius: 56px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=tabs-general] {
    margin-bottom: 32px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=tabs-general] [class*=gr21-col-] {
    margin-left: 0
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=tabs-general] a {
    display: block
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R .content-box___1J3k5 {
    margin-bottom: 32px
}

.gr21-page-airport___1v3Nj .section-tabs___1qT4R .content-box___1J3k5 p {
    margin-bottom: 2px
}

.gr21-page-airport___1v3Nj .section-hr-light___17yJJ,
.gr21-page-airport___1v3Nj .section-hr___1pey3 {
    height: 2px;
    width: 100%
}

.gr21-page-airport___1v3Nj .section-hr___1pey3 {
    background-color: #242a2e
}

.gr21-page-airport___1v3Nj .section-hr-light___17yJJ {
    background-color: #e5e9f0
}

.gr21-page-airport___1v3Nj .tabs-wrapper___2OOhn {
    margin-top: 32px
}

.gr21-page-airport___1v3Nj .section-spacer___3EPqy {
    height: 48px
}

.gr21-page-airport___1v3Nj.gr21-page-airport-child___2_iYm .section-tabs___1qT4R .tabs-title-wrapper___3Fmyu {
    padding-bottom: 0
}

.gr21-page-airport___1v3Nj.gr21-page-airport-child___2_iYm .section-tabs___1qT4R .tabs-wrapper___2OOhn {
    margin-top: 0;
    padding-bottom: 16px
}

@media (max-width:1024px) {
    .tabs-wrapper___2OOhn [class*=style-button-round___] [class*=tabs___] {
        padding: 8px 32px
    }

    .gr21-page-airport___1v3Nj .section-tabs___1qT4R .section-services___3XwBh .gr21-boxed-content {
        margin-left: 0;
        margin-right: 0
    }
}

@media (min-width:767px) and (max-width:1279px) {
    .gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=heading-tabs-wrapper___] {
        display: block;
        overflow-x: unset
    }

    .gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=heading-tabs-wrapper___] [class*=tabs__] {
        width: 136px;
        float: left
    }
}

@media (min-width:1025px) {
    .gr21-page-airport___1v3Nj .top-padding {
        padding-top: 96px
    }

    .gr21-page-airport___1v3Nj .section-features___2ePNp {
        margin-top: 48px;
        margin-bottom: 48px
    }

    .gr21-page-airport___1v3Nj .section-services___3XwBh {
        margin-top: 48px
    }

    .gr21-page-airport___1v3Nj .section-slider___iLaeC {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .gr21-page-airport___1v3Nj .section-slider___iLaeC .slider-title {
        margin-bottom: 64px
    }

    .gr21-page-airport___1v3Nj .section-tabs___1qT4R {
        padding-top: 96px;
        padding-bottom: 64px
    }

    .gr21-page-airport___1v3Nj .section-tabs___1qT4R .section-tabs-title___3kWao {
        margin-bottom: 64px
    }

    .gr21-page-airport___1v3Nj .section-tabs___1qT4R .tabs-title-wrapper___3Fmyu {
        padding-bottom: 32px
    }

    .gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=faqs__] .uk-open>.uk-accordion-content {
        padding-bottom: 48px
    }

    .gr21-page-airport___1v3Nj .section-faq___1Z5u9,
    .gr21-page-airport___1v3Nj .section-tabs___1qT4R [class*=tabs-general] {
        margin-bottom: 96px
    }

    .gr21-page-airport___1v3Nj .section-spacer___3EPqy {
        height: 64px
    }
}

.grab21-page-campaign-category___msH-f {
    padding-top: 64px
}

.grab21-page-campaign-category___msH-f .title___2yQs8 {
    width: 100%;
    line-height: 1;
    font-weight: 500;
    font-size: 32px;
    margin: 0 0 16px
}

.grab21-page-campaign-category___msH-f .description___DleYg {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 48px
}

@media (max-width:1025px) {
    .grab21-page-campaign-category___msH-f {
        padding-top: 20px
    }

    .grab21-page-campaign-category___msH-f .title___2yQs8 {
        font-size: 24px;
        margin-bottom: 8px
    }

    .grab21-page-campaign-category___msH-f .description___DleYg {
        font-size: 14px;
        margin-bottom: 40px
    }
}

.grab21-page-campaign-homepage___2Jx4t {
    padding-top: 64px
}

.grab21-page-campaign-homepage___2Jx4t .top-and-featured-articles___1YqJU {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 64px
}

.grab21-page-campaign-homepage___2Jx4t .top-and-featured-articles___1YqJU .top-article___3XHfj {
    width: 65%;
    margin-right: 24px
}

.grab21-page-campaign-homepage___2Jx4t .top-and-featured-articles___1YqJU .featured-article___3v646 {
    flex: 1
}

@media (max-width:1025px) {
    .grab21-page-campaign-homepage___2Jx4t {
        padding-top: 20px
    }

    .grab21-page-campaign-homepage___2Jx4t .top-and-featured-articles___1YqJU {
        margin-bottom: 48px;
        align-items: flex-start
    }

    .grab21-page-campaign-homepage___2Jx4t .top-and-featured-articles___1YqJU .top-article___3XHfj {
        width: 100%;
        margin-right: 0
    }

    .grab21-page-campaign-homepage___2Jx4t .top-and-featured-articles___1YqJU .featured-article___3v646 {
        width: 100%
    }
}

.gr21-company-landing___1JxtL dl,
.gr21-company-landing___1JxtL h1,
.gr21-company-landing___1JxtL h2,
.gr21-company-landing___1JxtL h3,
.gr21-company-landing___1JxtL h4,
.gr21-company-landing___1JxtL h5,
.gr21-company-landing___1JxtL h6,
.gr21-company-landing___1JxtL ol,
.gr21-company-landing___1JxtL p,
.gr21-company-landing___1JxtL ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-company-landing___1JxtL .section-hr-light___1ILO9,
.gr21-company-landing___1JxtL .section-hr___1pZSR {
    height: 2px;
    width: 100%
}

.gr21-company-landing___1JxtL .section-hr___1pZSR {
    background-color: #242a2e
}

.gr21-company-landing___1JxtL .section-hr-light___1ILO9 {
    background-color: #e5e9f0
}

.gr21-company-landing___1JxtL .section-header___23bm3 {
    margin-top: 64px
}

.gr21-company-landing___1JxtL .section-body___3v11s {
    margin-top: 64px;
    margin-bottom: 64px
}

.gr21-company-landing___1JxtL .section-careers___3i9F1 {
    margin-top: 64px;
    margin-bottom: 96px
}

@media (min-width:1025px) {
    .gr21-company-landing___1JxtL .section-header___23bm3 {
        margin-top: 96px
    }

    .gr21-company-landing___1JxtL .section-body___3v11s {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .gr21-company-landing___1JxtL .section-careers___3i9F1 {
        margin-top: 96px;
        margin-bottom: 192px
    }
}

.gr21-covid___3WMst .section-segment-spacer___4ZCFv {
    height: 96px
}

.gr21-covid___3WMst dl,
.gr21-covid___3WMst h1,
.gr21-covid___3WMst h2,
.gr21-covid___3WMst h3,
.gr21-covid___3WMst h4,
.gr21-covid___3WMst h5,
.gr21-covid___3WMst h6,
.gr21-covid___3WMst ol,
.gr21-covid___3WMst p,
.gr21-covid___3WMst ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-covid___3WMst .section-hr___3AyYf {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

@media (min-width:1025px) {
    .gr21-covid___3WMst .section-segment-spacer___4ZCFv {
        height: 192px
    }
}

.gr21-download___1PYGW {
    display: flex;
    flex-direction: column;
    width: 100%
}

.gr21-download___1PYGW dl,
.gr21-download___1PYGW h1,
.gr21-download___1PYGW h2,
.gr21-download___1PYGW h3,
.gr21-download___1PYGW h4,
.gr21-download___1PYGW h5,
.gr21-download___1PYGW h6,
.gr21-download___1PYGW ol,
.gr21-download___1PYGW p,
.gr21-download___1PYGW ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-download___1PYGW .section-hr___1F6Vd {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-download___1PYGW .title-wrapper___v09rE {
    padding: 32px 20px
}

.gr21-download___1PYGW .title-wrapper___v09rE .title___1Y45l {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500
}

.gr21-download___1PYGW .title-wrapper___v09rE .subtitle___2j-rM {
    margin-top: 6px;
    font-size: 14px;
    line-height: 20px
}

.gr21-download___1PYGW .cta-wrapper___20j2k {
    padding-left: 20px;
    padding-right: 20px
}

.gr21-download___1PYGW .cta-wrapper___20j2k .cta-title___2SMlY {
    display: none
}

.gr21-download___1PYGW .cta-row___gvYdA {
    display: flex;
    justify-content: space-between;
    margin-top: 32px
}

.gr21-download___1PYGW .cta-row___gvYdA .col1___39fN5,
.gr21-download___1PYGW .cta-row___gvYdA .col2___1CvLE {
    display: flex;
    align-items: center
}

.gr21-download___1PYGW .cta-row___gvYdA .cta-icon___1YEMH {
    width: 38px;
    height: 38px;
    margin-right: 20px
}

.gr21-download___1PYGW .cta-row___gvYdA .cta-name___oUzOZ {
    font-weight: 600
}

.gr21-download___1PYGW .cta-row___gvYdA .cta-button___SMWLP {
    background-color: #eef9f9;
    font-weight: 600;
    padding: 8px 22px;
    display: block;
    border-radius: 27px
}

.gr21-download___1PYGW .cta-row___gvYdA .cta-qr___324tl {
    display: none
}

.gr21-download___1PYGW .image-wrapper___3d-ys {
    margin-top: 48px;
    width: 100%
}

@media (min-width:1025px) {
    .gr21-download___1PYGW {
        flex-direction: row
    }

    .gr21-download___1PYGW .section-segment-spacer___3cdgj {
        height: 192px
    }

    .gr21-download___1PYGW .title-wrapper___v09rE {
        width: 50%;
        padding: 192px 48px 0
    }

    .gr21-download___1PYGW .title-wrapper___v09rE .title___1Y45l {
        font-size: 80px;
        line-height: 88px
    }

    .gr21-download___1PYGW .title-wrapper___v09rE .subtitle___2j-rM {
        margin-top: 16px;
        font-size: 20px;
        line-height: 28px
    }

    .gr21-download___1PYGW .content-wrapper___gWBRU {
        width: 50%
    }

    .gr21-download___1PYGW .cta-wrapper___20j2k {
        padding: 64px 32px 0
    }

    .gr21-download___1PYGW .cta-wrapper___20j2k .cta-title___2SMlY {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        line-height: 28px
    }

    .gr21-download___1PYGW .cta-wrapper___20j2k .cta-title___2SMlY .title-qr___1cO-d {
        text-align: center;
        width: 90px
    }

    .gr21-download___1PYGW .cta-row___gvYdA .cta-qr___324tl {
        display: block;
        margin-left: 32px;
        width: 90px;
        height: 90px
    }

    .gr21-download___1PYGW .image-wrapper___3d-ys {
        margin-top: 96px
    }
}

@media (min-width:1280px) {
    .gr21-download___1PYGW .title-wrapper___v09rE {
        padding: 192px 96px 0
    }

    .gr21-download___1PYGW .cta-wrapper___20j2k {
        padding: 64px 64px 0
    }

    .gr21-download___1PYGW .cta-row___gvYdA .cta-qr___324tl {
        margin-left: 80px
    }
}

.grab21-page-editorial-categorypage___1jk0q {
    padding-top: 64px
}

.grab21-page-editorial-categorypage___1jk0q .title___2Ts12 {
    width: 100%;
    line-height: 1;
    font-weight: 500;
    font-size: 32px;
    margin: 0 0 16px
}

.grab21-page-editorial-categorypage___1jk0q .description___2uFHw {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 48px
}

@media (max-width:1025px) {
    .grab21-page-editorial-categorypage___1jk0q {
        padding-top: 20px
    }

    .grab21-page-editorial-categorypage___1jk0q .title___2Ts12 {
        font-size: 24px;
        margin-bottom: 8px
    }

    .grab21-page-editorial-categorypage___1jk0q .description___2uFHw {
        font-size: 14px;
        margin-bottom: 40px
    }
}

.grab21-page-editorial-homepage___2_3F9 {
    padding-top: 64px
}

.grab21-page-editorial-homepage___2_3F9 .top-and-featured-articles___2soEq {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 64px
}

.grab21-page-editorial-homepage___2_3F9 .top-and-featured-articles___2soEq .top-article___3AGXV {
    width: 65%;
    margin-right: 24px
}

.grab21-page-editorial-homepage___2_3F9 .top-and-featured-articles___2soEq .featured-article___1Z3Uy {
    width: calc(35% - 24px)
}

.grab21-page-editorial-homepage___2_3F9 #sb_instagram {
    padding-bottom: 64px !important
}

.grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images {
    padding: 0 !important
}

.grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images .sbi_item {
    padding: 0 8px !important
}

.grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images .sbi_item:first-child {
    padding: 0 8px 0 0 !important
}

.grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images .sbi_item:last-child {
    padding: 0 0 0 8px !important
}

@media (max-width:1025px) {
    .grab21-page-editorial-homepage___2_3F9 {
        padding-top: 20px
    }

    .grab21-page-editorial-homepage___2_3F9 .top-and-featured-articles___2soEq {
        margin-bottom: 48px;
        align-items: flex-start
    }

    .grab21-page-editorial-homepage___2_3F9 .top-and-featured-articles___2soEq .top-article___3AGXV {
        width: 100%;
        margin-right: 0
    }

    .grab21-page-editorial-homepage___2_3F9 .top-and-featured-articles___2soEq .featured-article___1Z3Uy {
        width: 100%
    }

    .grab21-page-editorial-homepage___2_3F9 #sb_instagram {
        padding-bottom: 48px !important
    }

    .grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images {
        padding: 0 !important
    }

    .grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images .sbi_item {
        width: 100% !important;
        padding: 0 !important
    }

    .grab21-page-editorial-homepage___2_3F9 #sb_instagram #sbi_images .sbi_item+.sbi_item {
        padding: 16px 0 0 !important
    }
}

.gr21-fare-check___wkwWF dl,
.gr21-fare-check___wkwWF h1,
.gr21-fare-check___wkwWF h2,
.gr21-fare-check___wkwWF h3,
.gr21-fare-check___wkwWF h4,
.gr21-fare-check___wkwWF h5,
.gr21-fare-check___wkwWF h6,
.gr21-fare-check___wkwWF ol,
.gr21-fare-check___wkwWF p,
.gr21-fare-check___wkwWF ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-fare-check___wkwWF .title___3xKEJ {
    margin-top: 64px;
    margin-bottom: 64px
}

.gr21-fare-check___wkwWF .title___3xKEJ .desktop___1-SYs {
    display: none
}

.gr21-fare-check___wkwWF .title___3xKEJ .mobile___3vOaP {
    display: block
}

.gr21-fare-check___wkwWF .fare-check-widget___34dlZ {
    margin-bottom: 128px
}

@media (min-width:1025px) {
    .gr21-fare-check___wkwWF .title___3xKEJ {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .gr21-fare-check___wkwWF .title___3xKEJ .desktop___1-SYs {
        display: none
    }

    .gr21-fare-check___wkwWF .title___3xKEJ .mobile___3vOaP {
        display: block
    }

    .gr21-fare-check___wkwWF .fare-check-widget___34dlZ {
        margin-bottom: 196px
    }
}

.gr21-financial-inclusion___3rsFF dl,
.gr21-financial-inclusion___3rsFF h1,
.gr21-financial-inclusion___3rsFF h2,
.gr21-financial-inclusion___3rsFF h3,
.gr21-financial-inclusion___3rsFF h4,
.gr21-financial-inclusion___3rsFF h5,
.gr21-financial-inclusion___3rsFF h6,
.gr21-financial-inclusion___3rsFF ol,
.gr21-financial-inclusion___3rsFF p,
.gr21-financial-inclusion___3rsFF ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-financial-inclusion___3rsFF .section-hr___3b2ga {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-financial-inclusion___3rsFF .section-hero-hr___e1g2E {
    margin-top: 64px
}

.gr21-financial-inclusion___3rsFF .section-commitment___GOlh3 {
    margin-top: 32px
}

@media (min-width:1025px) {
    .gr21-financial-inclusion___3rsFF .section-hero-hr___e1g2E {
        margin-top: 96px
    }

    .gr21-financial-inclusion___3rsFF .section-commitment___GOlh3 {
        margin-top: 48px
    }

    .gr21-financial-inclusion___3rsFF .section-segments___29C3e {
        margin-bottom: 192px
    }
}

.gr21-grab-for-good___3wvYU .section-header___2--c9 {
    margin-bottom: 48px
}

.gr21-grab-for-good___3wvYU .section-banner___x2Fbc [class*=segment-cont___] {
    margin-top: 32px
}

.gr21-grab-for-good___3wvYU .section-banner___x2Fbc h3,
.gr21-grab-for-good___3wvYU .section-banner___x2Fbc p {
    margin-bottom: 0
}

.gr21-grab-for-good___3wvYU .section-body___vMBAR {
    margin-bottom: 64px
}

.gr21-grab-for-good___3wvYU .section-body___vMBAR [class*=link___] {
    margin-top: 0
}

.gr21-grab-for-good___3wvYU .section-body___vMBAR [class*=desc___] {
    margin-bottom: 0
}

.gr21-grab-for-good___3wvYU .section-hr___eWPZP {
    background-color: #242a2e
}

.gr21-grab-for-good___3wvYU .section-focus-areas___2Cig3 {
    margin-top: 32px;
    margin-bottom: 0
}

.gr21-grab-for-good___3wvYU .section-inner-page-header___2KUqe {
    margin-bottom: 0
}

.gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 {
    margin-top: 0;
    padding-top: 0 !important
}

.gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 h3,
.gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 p {
    margin-bottom: 0
}

.gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 [class*=segment-new___]:last-child {
    margin-bottom: 64px
}

@media (min-width:1025px) {
    .gr21-grab-for-good___3wvYU .section-header___2--c9 {
        margin-bottom: 0
    }

    .gr21-grab-for-good___3wvYU .section-banner___x2Fbc [class*=segment-cont___] {
        margin-top: 48px
    }

    .gr21-grab-for-good___3wvYU .section-body___vMBAR {
        margin-bottom: 96px
    }

    .gr21-grab-for-good___3wvYU .section-body___vMBAR [class*=link___] {
        margin-top: 0
    }

    .gr21-grab-for-good___3wvYU .section-body___vMBAR [class*=desc___] {
        margin-bottom: 0
    }

    .gr21-grab-for-good___3wvYU .section-body___vMBAR [class*=segment-new___] {
        margin-top: 0;
        border-top: 0
    }

    .gr21-grab-for-good___3wvYU .section-hr___eWPZP {
        background-color: #242a2e
    }

    .gr21-grab-for-good___3wvYU .section-focus-areas___2Cig3 {
        margin-top: 32px;
        margin-bottom: 64px
    }

    .gr21-grab-for-good___3wvYU .section-inner-page-header___2KUqe {
        margin-bottom: 0
    }

    .gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 {
        margin-top: 0;
        padding-top: 0 !important
    }

    .gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 p {
        margin-bottom: 0
    }

    .gr21-grab-for-good___3wvYU .section-inner-page-projects___3tr65 [class*=segment-new___]:last-child {
        margin-bottom: 96px
    }
}

.gr21-guiding-principles___30G7P dl,
.gr21-guiding-principles___30G7P h1,
.gr21-guiding-principles___30G7P h2,
.gr21-guiding-principles___30G7P h3,
.gr21-guiding-principles___30G7P h4,
.gr21-guiding-principles___30G7P h5,
.gr21-guiding-principles___30G7P h6,
.gr21-guiding-principles___30G7P ol,
.gr21-guiding-principles___30G7P p,
.gr21-guiding-principles___30G7P ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-guiding-principles___30G7P .section-segment-spacer___3c9h7 {
    height: 64px
}

@media (min-width:1025px) {
    .gr21-guiding-principles___30G7P .section-segment-spacer___3c9h7 {
        height: 96px
    }
}

.gr21-page-home___iLWks #hero-video-container {
    height: 75vw;
    background: url(https://assets.grab.com/wp-content/uploads/sites/4/2021/04/02144839/thumbnail_hero_banner_singapore.jpg) no-repeat;
    background-size: cover
}

.gr21-page-home___iLWks .section-header-title___1IXJ3 {
    color: #333;
    padding: 24px 0
}

.gr21-page-home___iLWks .section-newsroom___7JMWn {
    border-top: 2px solid #e5e9f0;
    padding-top: 64px
}

.gr21-page-home___iLWks [class*=services-section-wrapper___] {
    display: flex;
    flex-direction: column
}

.gr21-page-home___iLWks [class*=services-section-wrapper___] .service-vertical-btn {
    order: 1
}

@media (min-width:1025px) {
    .gr21-page-home___iLWks #hero-video-container {
        height: 602px
    }

    .gr21-page-home___iLWks .section-header-title___1IXJ3 {
        position: absolute;
        top: 320px;
        color: #fff;
        padding: auto
    }

    .gr21-page-home___iLWks .section-newsroom___7JMWn {
        padding-top: 96px
    }
}

.gr21-our-mission___3XPAo .section-4h___2r6aK {
    background-color: #f9f6f6;
    margin-top: 64px;
    padding-top: 64px
}

.gr21-our-mission___3XPAo .section-segment-spacer___1AYPa {
    background-color: #f9f6f6;
    height: 96px
}

.gr21-our-mission___3XPAo dl,
.gr21-our-mission___3XPAo h1,
.gr21-our-mission___3XPAo h2,
.gr21-our-mission___3XPAo h3,
.gr21-our-mission___3XPAo h4,
.gr21-our-mission___3XPAo h5,
.gr21-our-mission___3XPAo h6,
.gr21-our-mission___3XPAo ol,
.gr21-our-mission___3XPAo p,
.gr21-our-mission___3XPAo ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-our-mission___3XPAo .grey-bckg___3R3J2 {
    background-color: #f9f6f6
}

@media (min-width:1025px) {
    .gr21-our-mission___3XPAo .section-4h___2r6aK {
        margin-top: 96px;
        padding-top: 96px
    }

    .gr21-our-mission___3XPAo .section-segment-spacer___1AYPa {
        height: 192px
    }
}

.gr21-product-acquisition___3IetT dl,
.gr21-product-acquisition___3IetT h1,
.gr21-product-acquisition___3IetT h2,
.gr21-product-acquisition___3IetT h3,
.gr21-product-acquisition___3IetT h4,
.gr21-product-acquisition___3IetT h5,
.gr21-product-acquisition___3IetT h6,
.gr21-product-acquisition___3IetT ol,
.gr21-product-acquisition___3IetT p,
.gr21-product-acquisition___3IetT ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-product-acquisition___3IetT .section-hr-light___1ZTnX,
.gr21-product-acquisition___3IetT .section-hr___Hgc9y {
    height: 2px;
    width: 100%
}

.gr21-product-acquisition___3IetT .section-hr___Hgc9y {
    background-color: #242a2e
}

.gr21-product-acquisition___3IetT .section-hr-light___1ZTnX {
    background-color: #e5e9f0
}

.gr21-product-acquisition___3IetT .section-header___3tELl {
    margin-top: 64px
}

.gr21-product-acquisition___3IetT .section-features___7JrLv {
    margin-top: 32px
}

.gr21-product-acquisition___3IetT .section-features___7JrLv.experiment___pUte_.exp-subtitle___9SN0t {
    margin-top: 0
}

.gr21-product-acquisition___3IetT .section-features___7JrLv.experiment___pUte_.exp-subtitle___9SN0t>div:first-child {
    border-top: 2px solid #242a2e
}

.gr21-product-acquisition___3IetT .section-testimonial___1CpV6 {
    margin-top: 64px;
    margin-bottom: 64px
}

.gr21-product-acquisition___3IetT .section-tabs___Zj5W3 {
    margin-top: 32px;
    margin-bottom: 32px
}

.gr21-product-acquisition___3IetT .section-requirements___1IDWn {
    margin-top: 32px;
    margin-bottom: 64px
}

.gr21-product-acquisition___3IetT .section-faq___26d0Q {
    margin-bottom: 64px
}

.gr21-product-acquisition___3IetT .section-logo-partners___2zrLb {
    padding-top: 64px;
    padding-bottom: 96px
}

.gr21-product-acquisition___3IetT .section-logo-brands___2cFb2 {
    padding-bottom: 64px
}

@media (min-width:1025px) {
    .gr21-product-acquisition___3IetT .section-header___3tELl {
        margin-top: 96px
    }

    .gr21-product-acquisition___3IetT .section-header___3tELl.experiment___pUte_ {
        margin-top: 0
    }

    .gr21-product-acquisition___3IetT .section-features___7JrLv {
        margin-top: 48px
    }

    .gr21-product-acquisition___3IetT .section-features___7JrLv.experiment___pUte_ {
        margin-top: 64px
    }

    .gr21-product-acquisition___3IetT .section-features___7JrLv.experiment___pUte_>div:first-child {
        border-top: 2px solid #242a2e
    }

    .gr21-product-acquisition___3IetT .section-features___7JrLv.experiment___pUte_.exp-subtitle___9SN0t {
        margin-top: 0
    }

    .gr21-product-acquisition___3IetT .section-testimonial___1CpV6 {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .gr21-product-acquisition___3IetT .section-requirements___1IDWn,
    .gr21-product-acquisition___3IetT .section-tabs___Zj5W3 {
        margin-top: 48px;
        margin-bottom: 96px
    }

    .gr21-product-acquisition___3IetT .section-faq___26d0Q {
        margin-bottom: 96px
    }

    .gr21-product-acquisition___3IetT .section-logo-partners___2zrLb {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .gr21-product-acquisition___3IetT .section-logo-brands___2cFb2 {
        padding-bottom: 96px
    }
}

.gr21-product-detail___2Jro7 dl,
.gr21-product-detail___2Jro7 h1,
.gr21-product-detail___2Jro7 h2,
.gr21-product-detail___2Jro7 h3,
.gr21-product-detail___2Jro7 h4,
.gr21-product-detail___2Jro7 h5,
.gr21-product-detail___2Jro7 h6,
.gr21-product-detail___2Jro7 ol,
.gr21-product-detail___2Jro7 p,
.gr21-product-detail___2Jro7 ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-product-detail___2Jro7 .section-hr___1v2Kb {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-product-detail___2Jro7 .section-header___387wl {
    margin-top: 64px
}

.gr21-product-detail___2Jro7 .section-table___1ZDL9 {
    padding-top: 64px;
    padding-bottom: 32px
}

@media (min-width:1025px) {
    .gr21-product-detail___2Jro7 .section-header___387wl {
        margin-top: 96px
    }

    .gr21-product-detail___2Jro7 .section-table___1ZDL9 {
        padding-top: 96px;
        padding-bottom: 48px
    }
}

.gr21-product-enterprise___3XD-W .section-hr___1RM52 {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-product-enterprise___3XD-W .section-header___2Jnvb {
    margin-top: 64px
}

.gr21-product-enterprise___3XD-W .section-features-bottom___27c1l,
.gr21-product-enterprise___3XD-W .section-testimonial___2dQ8z {
    margin-bottom: 64px
}

.gr21-product-enterprise___3XD-W .section-hr-light___PHP4e {
    height: 2px;
    width: 100%;
    background-color: #e5e9f0
}

@media (min-width:1025px) {
    .gr21-product-enterprise___3XD-W .section-header___2Jnvb {
        margin-top: 96px
    }

    .gr21-product-enterprise___3XD-W .section-features-bottom___27c1l,
    .gr21-product-enterprise___3XD-W .section-testimonial___2dQ8z {
        margin-bottom: 96px
    }
}

.gr21-product-gfg___1kXCa p:empty {
    display: none
}

.gr21-product-gfg___1kXCa .section-heading-and-body___34M_v div[class^=subtitle-wrapper] {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.gr21-product-gfg___1kXCa .section-features___24Vl-,
.gr21-product-gfg___1kXCa .section-image-zone___1sOyV,
.gr21-product-gfg___1kXCa .section-image___2-PSP,
.gr21-product-gfg___1kXCa .section-logo___3WucG,
.gr21-product-gfg___1kXCa .section-table___3h30o,
.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5,
.gr21-product-gfg___1kXCa .section-testimonial___1s0Jl,
.gr21-product-gfg___1kXCa .section-video___2_1Ef {
    padding-top: 64px
}

.gr21-product-gfg___1kXCa .section-video___2_1Ef .section-hr-light___3J2Nn {
    margin-bottom: 32px
}

.gr21-product-gfg___1kXCa .text-module-v0___chsqs p {
    margin-bottom: 0
}

.gr21-product-gfg___1kXCa .section-heading-and-body___34M_v {
    padding-top: 64px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-desc {
    order: 1;
    padding-left: 14px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-empty {
    order: 2
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-title {
    order: 2;
    padding-left: 12px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-title h5 {
    margin-bottom: 2px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-title p {
    margin-top: 0;
    margin-bottom: 32px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-desc h3 {
    margin-bottom: 32px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-desc h3:before {
    content: "“";
    position: absolute;
    margin-left: -20px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-background {
    margin-bottom: 64px
}

.gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.11111%
}

@media (min-width:768px) {
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
        padding-top: 56.25%
    }
}

@media (min-width:1025px) {
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
        padding-top: 56.19048%
    }
}

@media (min-width:1280px) {
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
        padding-top: 56.20438%
    }
}

@media (min-width:1440px) {
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
        padding-top: 55.94406%
    }
}

@media (min-width:1680px) {
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
        padding-top: 56.88623%
    }
}

@media (min-width:1920px) {
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .bg-image {
        padding-top: 56.5445%
    }
}

.gr21-product-gfg___1kXCa .section-about___y8Kxq {
    background-color: #f5f5f5;
    padding-top: 64px
}

.gr21-product-gfg___1kXCa .section-about___y8Kxq [class*=faq-col1___],
.gr21-product-gfg___1kXCa .section-about___y8Kxq [class*=faq-title___] {
    display: none !important
}

.gr21-product-gfg___1kXCa .section-about___y8Kxq [class*=faqs___] .uk-open>.uk-accordion-content:before {
    display: none
}

.gr21-product-gfg___1kXCa .section-about___y8Kxq [class*=faqs___] .uk-accordion-content {
    padding-left: 0 !important
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC {
    padding-top: 48px;
    margin-top: 64px;
    padding-bottom: 96px;
    background-color: #f5f5f5
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .gr21-boxed-content .uk-accordion-title {
    color: #707070;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #383e43
}

@media (min-width:1025px) {
    .gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .gr21-boxed-content .uk-accordion-title {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1280px) {
    .gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .gr21-boxed-content .uk-accordion-title {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

@media (min-width:1680px) {
    .gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .gr21-boxed-content .uk-accordion-title {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .gr21-boxed-content .uk-accordion-title h5 {
    margin-bottom: 0
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .gr21-boxed-content .uk-accordion li {
    padding-bottom: 20px;
    border-bottom: 2px solid #e5e9f0
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .disclaimer-v1 {
    border-top: 2px solid #e5e9f0;
    padding-top: 16px;
    padding-bottom: 64px
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .disclaimer-v1 .uk-accordion li {
    border-bottom: none
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .disclaimer-v2 {
    padding-top: 64px
}

.gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .disclaimer-v2 .uk-accordion li:first-child {
    border-top: 2px solid #e5e9f0;
    padding-top: 20px
}

.gr21-product-gfg___1kXCa .section-body-hr___31huK,
.gr21-product-gfg___1kXCa .section-reasons-hr___3Hutr {
    padding-top: 64px
}

.gr21-product-gfg___1kXCa .section-reasons___2dDCT [class*=feature-row___]:last-child {
    padding-bottom: 0
}

.gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%
}

@media (min-width:768px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        padding-top: 41.86047%
    }
}

.gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
    display: none
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV p {
    margin-bottom: 0
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .section-hr-light___3J2Nn,
.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .section-hr___RIdjI {
    margin-bottom: 32px
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
    margin-top: 32px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 131.25%
}

@media (min-width:768px) {
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) {
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        padding-top: 41.86047%
    }
}

@media (min-width:1280px) {
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        padding-top: 41.75627%
    }
}

@media (min-width:1440px) {
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        padding-top: 41.75258%
    }
}

@media (min-width:1680px) {
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        padding-top: 41.88791%
    }
}

@media (min-width:1920px) {
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        padding-top: 41.86047%
    }
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .para {
    margin-bottom: 32px;
    border-top: 2px solid #e5e9f0;
    padding-top: 32px
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .para:first-child {
    border-top: 0;
    padding-top: 48px
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .para:last-child {
    margin-bottom: 0
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .table-content .col1 {
    width: 60%;
    float: left;
    margin-bottom: 20px;
    margin-top: 0
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .table-content .col2 {
    width: 40%;
    float: right;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 0
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .desc {
    float: left;
    width: 100%;
    margin-top: 12px;
    border-top: 2px solid #e5e9f0;
    padding: 32px 0
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV ul {
    padding-left: 20px
}

.gr21-product-gfg___1kXCa .section-image-zone___1sOyV .para2 {
    border-top: 2px solid #e5e9f0;
    margin-top: 32px;
    padding-top: 32px
}

.gr21-product-gfg___1kXCa .section-slider___3ZBbH {
    padding-top: 64px;
    padding-bottom: 64px
}

.gr21-product-gfg___1kXCa .section-slider___3ZBbH .uk-slider-items>li {
    padding: 0 20px
}

.gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
    width: calc(50% - 10px)
}

@media (min-width:768px) {
    .gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
        width: 344px
    }
}

@media (min-width:1025px) {
    .gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
        width: 430px
    }
}

@media (min-width:1280px) {
    .gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
        width: 558px
    }
}

@media (min-width:1440px) {
    .gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
        width: 582px
    }
}

@media (min-width:1680px) {
    .gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
        width: 678px
    }
}

@media (min-width:1920px) {
    .gr21-product-gfg___1kXCa .section-slider___3ZBbH [class*=slider-desktop___] [class*=default-item___] {
        width: 774px
    }
}

.gr21-product-gfg___1kXCa .section-slider___3ZBbH .slider-title {
    margin-bottom: 48px
}

.gr21-product-gfg___1kXCa .section-evergreen___3gOzd .top-padding {
    padding-top: 64px
}

.gr21-product-gfg___1kXCa .section-hr-light___3J2Nn,
.gr21-product-gfg___1kXCa .section-hr___RIdjI {
    height: 2px;
    width: 100%
}

.gr21-product-gfg___1kXCa .section-hr___RIdjI {
    background-color: #242a2e
}

.gr21-product-gfg___1kXCa .section-hr-light___3J2Nn {
    background-color: #e5e9f0
}

@media (min-width:1025px) {
    .gr21-product-gfg___1kXCa [class*=subtitle-wrapper___] {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .gr21-product-gfg___1kXCa .section-features___24Vl-,
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV,
    .gr21-product-gfg___1kXCa .section-image___2-PSP,
    .gr21-product-gfg___1kXCa .section-logo___3WucG,
    .gr21-product-gfg___1kXCa .section-table___3h30o,
    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5,
    .gr21-product-gfg___1kXCa .section-testimonial___1s0Jl,
    .gr21-product-gfg___1kXCa .section-video___2_1Ef {
        padding-top: 96px
    }

    .gr21-product-gfg___1kXCa .section-disclaimer___i_XyC {
        margin-top: 96px;
        padding-bottom: 192px
    }

    .gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .disclaimer-v1 {
        padding-bottom: 96px
    }

    .gr21-product-gfg___1kXCa .section-disclaimer___i_XyC .disclaimer-v2 {
        padding-top: 96px
    }

    .gr21-product-gfg___1kXCa .section-video___2_1Ef .section-hr-light___3J2Nn {
        margin-bottom: 48px
    }

    .gr21-product-gfg___1kXCa .section-heading-and-body___34M_v {
        padding-top: 96px
    }

    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-desc {
        order: 2;
        padding-left: 0
    }

    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-title {
        order: 1;
        padding-left: 0
    }

    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-emty {
        order: 2
    }

    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-desc h3 {
        margin-bottom: 48px
    }

    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-desc h3:before {
        margin-left: -20px
    }

    .gr21-product-gfg___1kXCa .section-testimonial-v2___1Bnh5 .section-background {
        margin-bottom: 96px
    }

    .gr21-product-gfg___1kXCa .section-about___y8Kxq {
        padding-top: 96px
    }

    .gr21-product-gfg___1kXCa .section-about___y8Kxq [class*=faq-col1___] {
        display: block !important
    }

    .gr21-product-gfg___1kXCa .section-body-hr___31huK,
    .gr21-product-gfg___1kXCa .section-reasons-hr___3Hutr {
        padding-top: 96px
    }

    .gr21-product-gfg___1kXCa .section-image___2-PSP .mobile-image {
        display: none
    }

    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        display: block;
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 131.25%
    }

    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .section-hr-light___3J2Nn,
    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .section-hr___RIdjI {
        margin-bottom: 48px
    }

    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .bg-image {
        margin-top: 64px
    }

    .gr21-product-gfg___1kXCa .section-image-zone___1sOyV .para:first-child {
        padding-top: 64px
    }

    .gr21-product-gfg___1kXCa .section-slider___3ZBbH {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .gr21-product-gfg___1kXCa .section-slider___3ZBbH .slider-title {
        margin-bottom: 64px
    }

    .gr21-product-gfg___1kXCa .section-evergreen___3gOzd .top-padding {
        padding-top: 96px
    }
}

@media (min-width:1025px) and (min-width:768px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) and (min-width:1025px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        padding-top: 41.86047%
    }
}

@media (min-width:1025px) and (min-width:1280px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        padding-top: 41.75627%
    }
}

@media (min-width:1025px) and (min-width:1440px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        padding-top: 41.75258%
    }
}

@media (min-width:1025px) and (min-width:1680px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        padding-top: 41.88791%
    }
}

@media (min-width:1025px) and (min-width:1920px) {
    .gr21-product-gfg___1kXCa .section-image___2-PSP .desktop-image {
        padding-top: 41.86047%
    }
}

.gr21-product-landing___p7Tuf dl,
.gr21-product-landing___p7Tuf h1,
.gr21-product-landing___p7Tuf h2,
.gr21-product-landing___p7Tuf h3,
.gr21-product-landing___p7Tuf h4,
.gr21-product-landing___p7Tuf h5,
.gr21-product-landing___p7Tuf h6,
.gr21-product-landing___p7Tuf ol,
.gr21-product-landing___p7Tuf p,
.gr21-product-landing___p7Tuf ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-product-landing___p7Tuf .section-hr___2Wa1w {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-product-landing___p7Tuf .section-color-banner___2Zbt3,
.gr21-product-landing___p7Tuf .section-header___14BCK {
    margin-top: 64px
}

.gr21-product-landing___p7Tuf .section-features___CwNsK {
    margin-top: 32px
}

.gr21-product-landing___p7Tuf .section-features___CwNsK.experiment___3l8f9.exp-subtitle___aXAzU {
    margin-top: 0
}

.gr21-product-landing___p7Tuf .section-features___CwNsK.experiment___3l8f9.exp-subtitle___aXAzU>div:first-child {
    border-top: 2px solid #242a2e
}

.gr21-product-landing___p7Tuf .section-faq___1mvSs {
    margin-bottom: 64px
}

@media (min-width:1025px) {
    .gr21-product-landing___p7Tuf .section-header___14BCK {
        margin-top: 96px
    }

    .gr21-product-landing___p7Tuf .section-header___14BCK.experiment___3l8f9 {
        margin-top: 0
    }

    .gr21-product-landing___p7Tuf .section-color-banner___2Zbt3 {
        margin-top: 96px
    }

    .gr21-product-landing___p7Tuf .section-features___CwNsK {
        margin-top: 48px
    }

    .gr21-product-landing___p7Tuf .section-features___CwNsK.experiment___3l8f9 {
        margin-top: 64px
    }

    .gr21-product-landing___p7Tuf .section-features___CwNsK.experiment___3l8f9>div:first-child {
        border-top: 2px solid #242a2e
    }

    .gr21-product-landing___p7Tuf .section-features___CwNsK.experiment___3l8f9.exp-subtitle___aXAzU {
        margin-top: 0
    }

    .gr21-product-landing___p7Tuf .section-faq___1mvSs {
        margin-bottom: 96px
    }
}

.gr21-si-landing___2oe0q .section-hr___1ATLI {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-si-landing___2oe0q .section-report___2Vj7v {
    padding-top: 64px;
    margin-bottom: 128px
}

.gr21-si-landing___2oe0q .section-segment-spacer___1Famz {
    height: 96px
}

@media (min-width:1025px) {
    .gr21-si-landing___2oe0q .section-report___2Vj7v {
        padding-top: 96px;
        margin-bottom: 192px
    }

    .gr21-si-landing___2oe0q .section-segment-spacer___1Famz {
        height: 192px
    }
}

.gr21-superapp___2gRU0 dl,
.gr21-superapp___2gRU0 h1,
.gr21-superapp___2gRU0 h2,
.gr21-superapp___2gRU0 h3,
.gr21-superapp___2gRU0 h4,
.gr21-superapp___2gRU0 h5,
.gr21-superapp___2gRU0 h6,
.gr21-superapp___2gRU0 ol,
.gr21-superapp___2gRU0 p,
.gr21-superapp___2gRU0 ul,
.section-content___vNYi0 dl,
.section-content___vNYi0 h1,
.section-content___vNYi0 h2,
.section-content___vNYi0 h3,
.section-content___vNYi0 h4,
.section-content___vNYi0 h5,
.section-content___vNYi0 h6,
.section-content___vNYi0 ol,
.section-content___vNYi0 p,
.section-content___vNYi0 ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-superapp___2gRU0 .section-hr-light___dhSb6,
.gr21-superapp___2gRU0 .section-hr___D_DmS {
    height: 2px;
    width: 100%
}

.gr21-superapp___2gRU0 .section-hr___D_DmS {
    background-color: #242a2e
}

.gr21-superapp___2gRU0 .section-hr-light___dhSb6 {
    background-color: #e5e9f0
}

.gr21-superapp___2gRU0 .section-header___13Gqt {
    margin-bottom: 64px
}

.gr21-superapp___2gRU0 .section-deliveries___6de0q {
    margin-top: 32px;
    margin-bottom: 64px
}

.gr21-superapp___2gRU0 .section-services___2LQM_ {
    margin-bottom: 56px
}

.gr21-superapp___2gRU0 .section-features___HlSBu {
    margin-top: 56px;
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #f9f6f6
}

.gr21-superapp___2gRU0 .section-last___2tkgz {
    margin-top: 64px
}

.gr21-superapp___2gRU0 .section-segment-spacer___YqQiw {
    height: 96px
}

@media (min-width:1025px) {
    .gr21-superapp___2gRU0 .section-header___13Gqt {
        margin-bottom: 96px
    }

    .gr21-superapp___2gRU0 .section-deliveries___6de0q {
        margin-top: 48px;
        margin-bottom: 96px
    }

    .gr21-superapp___2gRU0 .section-services___2LQM_ {
        margin-bottom: 96px
    }

    .gr21-superapp___2gRU0 .section-features___HlSBu {
        margin-top: 96px;
        padding-top: 96px;
        padding-bottom: 96px
    }

    .gr21-superapp___2gRU0 .section-last___2tkgz {
        margin-top: 96px
    }

    .gr21-superapp___2gRU0 .section-segment-spacer___YqQiw {
        height: 192px
    }
}

.gr21-trust-safety___2PXrS dl,
.gr21-trust-safety___2PXrS h1,
.gr21-trust-safety___2PXrS h2,
.gr21-trust-safety___2PXrS h3,
.gr21-trust-safety___2PXrS h4,
.gr21-trust-safety___2PXrS h5,
.gr21-trust-safety___2PXrS h6,
.gr21-trust-safety___2PXrS ol,
.gr21-trust-safety___2PXrS p,
.gr21-trust-safety___2PXrS ul {
    margin-top: 0;
    margin-bottom: 0
}

.gr21-trust-safety___2PXrS .section-hr___1nm6u {
    background-color: #242a2e;
    height: 2px;
    width: 100%
}

.gr21-trust-safety___2PXrS .section-hr___1nm6u.last___Ck85p {
    margin-top: 64px
}

.gr21-trust-safety___2PXrS .section-our-policies___1Rpyb {
    margin-top: 48px;
    margin-bottom: 48px
}

.gr21-trust-safety___2PXrS .section-award___2yUHo {
    margin-top: 32px
}

.gr21-trust-safety___2PXrS .section-segment-spacer___22uWK {
    height: 96px
}

@media (min-width:1025px) {
    .gr21-trust-safety___2PXrS .section-hr___1nm6u.last___Ck85p {
        margin-top: 96px
    }

    .gr21-trust-safety___2PXrS .section-our-policies___1Rpyb {
        margin-top: 64px;
        margin-bottom: 96px
    }

    .gr21-trust-safety___2PXrS .section-award___2yUHo {
        margin-top: 48px
    }

    .gr21-trust-safety___2PXrS .section-segment-spacer___22uWK {
        height: 192px
    }
}

.sign-up-wrapper___nxvX7 {
    width: 100%;
    border-top: 1px solid #e5e9f0;
    display: none;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 11px 0
}

.green-text___30K-h {
    color: #00b14f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
}

.fixed___2xRgi {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    width: 100%;
    background-color: #fff;
    z-index: 999
}

@media screen and (min-width:361px) and (max-width:767px) {
    .fixed___2xRgi {
        top: 50px
    }
}

@media screen and (max-width:360px) and (min-width:0px) {
    .fixed___2xRgi {
        position: -webkit-sticky;
        position: sticky;
        top: 50px
    }
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .header___1LA-V .grab_sidenav___2C94S {
        display: none
    }
}

@media screen and (min-width:375px) and (max-width:425px) {
    .fixed___2xRgi {
        top: 50px
    }
}

.gr21-table___38j8s .table-desktop___2wGgK {
    display: none
}

@media (min-width:1025px) {
    .gr21-table___38j8s .table-mobile___1-L0k {
        display: none
    }

    .gr21-table___38j8s .table-desktop___2wGgK {
        display: block
    }
}

.gr21-iframe-modal {
    display: none;
    z-index: 1030
}

.gr21-iframe-modal.uk-modal {
    padding: 0;
    background: #f9f6f6
}

.gr21-iframe-modal .uk-modal-full {
    height: 100vh
}

.gr21-iframe-modal .uk-modal-close {
    position: fixed;
    top: 48px;
    left: 36px;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    z-index: 2;
    background: #fff;
    cursor: pointer
}

.gr21-iframe-modal .uk-modal-close>svg {
    position: absolute;
    left: 18px;
    top: 18px
}

@media (max-width:1279px) {
    .gr21-iframe-modal .uk-modal-close {
        position: fixed;
        bottom: 24px;
        top: unset;
        left: calc(50% - 24px);
        transform: scale(.857)
    }
}`}</style>
      <div class="gr-g-wrapper">
        <div class="gr-g-wrapper-inner" data-scroll-container>
          <div
            data-elementor-type="wp-post"
            data-elementor-id="59985"
            class="elementor elementor-59985"
            data-elementor-post-type="page"
          >
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-5eeda86 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5eeda86"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eb5188a"
                  data-id="eb5188a"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-92718a4 elementor-widget elementor-widget-grab21_page_product_landing"
                      data-id="92718a4"
                      data-element_type="widget"
                      data-widget_type="grab21_page_product_landing.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="gr21-product-landing___p7Tuf">
                          <div class="section-header___14BCK experiment___3l8f9">
                            <div></div>

                            <div class="hero-wrapper___20BVa">
                              <div class="gr21-h-margin-left gr21-col-l-8 gr21-col-4 title-wrapper___2mGBF">
                                <div>
                                  <h6
                                    data-gr21-anim="slideInLeft"
                                    data-gr21-delay=".1s"
                                    class="gr21-aol"
                                  >
                                    Affordable and Reliable Transport for
                                    Students
                                  </h6>
                                  <h1
                                    data-gr21-anim="slideInUp"
                                    data-gr21-delay=".4s"
                                    class="gr21-aol title-h1___2A0ZC"
                                  >
                                    From campus to home, JAES offers reliable,
                                    student-focused transport to major
                                    universities in Durban.
                                  </h1>
                                </div>
                                <div class="hero-cta___Sfjzc">
                                  <a
                                    onClick={handleJoinClick}
                                    target="_blank"
                                    style={{ background: "#ff5a5f" }}
                                    data-grab-tracking='{"event":"grabcom_wp","category":"cta","action":"click","tracking_name":"hero_cta2","label":"Book A Ride"}'
                                    class="round-button___2cysa cta-primary___3ikdi"
                                  >
                                    <p class="callout1 text___2QfwM mobile___H3Ghy">
                                      Book A Ride
                                    </p>
                                    <p class="callout1 text___2QfwM desktop___3_fZQ">
                                      Book A Ride
                                    </p>
                                  </a>

                                  <a
                                    href="/Login"
                                    data-grab-tracking='{"event":"grabcom_wp","category":"cta","action":"click","tracking_name":"hero_cta1","label":"Download App"}'
                                    class="round-button___2cysa cta-secondary___zVgLV"
                                  >
                                    <p class="callout1 text___2QfwM mobile___H3Ghy">
                                      Login
                                    </p>
                                    <p class="callout1 text___2QfwM desktop___3_fZQ">
                                      Login
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <div class="img-wrapper___1oG4K">
                                <div
                                  class="desktop-img-wrapper___1fuZD"
                                  style={{
                                    backgroundImage: `url(${exp})`,
                                  }}
                                ></div>
                                <div
                                  class="mobile-img-wrapper___2JOLr"
                                  style={{
                                    backgroundImage: `url(${m402x})`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div
                              style={{ marginTop: "64px;" }}
                              class="gr21-boxed-content desktop___2OvF5"
                            >
                              <div class="section-hr___2HLly"></div>
                            </div>
                            <div class="gr21-boxed-content subtitle-wrapper___y-82B half-button-exp___QFhPP">
                              <div class="gr21-col-l-8 gr21-col-4 gr21-col-no-margin-m">
                                <p class="text-h6">Overview</p>
                              </div>
                              <div class="gr21-col-l-8 gr21-col-4 gr21-col-no-margin-m">
                                <p class="text-h4 subtitle-h4___lxLfY">
                                  From Campus to Comfort Whether you need a ride
                                  to university or back home, JAES provides
                                  reliable, student-focused transport services
                                  to all major universities in KZN and Durban,
                                  including: <br />
                                  UKZN Howard Campus
                                  <br />
                                  UKZN Westville Campus
                                  <br />
                                  Umbilo Technical College
                                  <br />
                                  Costal College
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="section-features___CwNsK experiment___3l8f9 exp-subtitle___aXAzU">
                            <div></div>
                            <div class="gr21-boxed-content feature-title___3uh6X">
                              <h2 class="text-h6">Why ride with us</h2>
                            </div>
                            <div class="gr21-boxed-content">
                              <div class="feature-row___kJReD">
                                <div class="gr21-col-1 feature-col1___3jwE-">
                                  <p class="text-h3">1</p>
                                </div>
                                <div class="gr21-col-7  gr21-col-no-margin-m feature-col2___TVmDM">
                                  <h3>Safety First</h3>
                                </div>
                                <div class="gr21-col-8  gr21-col-no-margin-m feature-col3___2U73l">
                                  <p class="text-p2">
                                    Ride with peace of mind knowing that your
                                    safety is our top priority.
                                  </p>
                                </div>
                              </div>
                              <div class="feature-row___kJReD">
                                <div class="gr21-col-1 feature-col1___3jwE-">
                                  <p class="text-h3">2</p>
                                </div>
                                <div class="gr21-col-7  gr21-col-no-margin-m feature-col2___TVmDM">
                                  <h3>NSFAS Support</h3>
                                </div>
                                <div class="gr21-col-8  gr21-col-no-margin-m feature-col3___2U73l">
                                  <p class="text-p2">
                                    
                                    No financial stress for NSFAS beneficiaries
                                    — your transport is fully covered.
                                  </p>
                                </div>
                              </div>
                              <div class="feature-row___kJReD">
                                <div class="gr21-col-1 feature-col1___3jwE-">
                                  <p class="text-h3">3</p>
                                </div>
                                <div class="gr21-col-7  gr21-col-no-margin-m feature-col2___TVmDM">
                                  <h3>Best pricing</h3>
                                </div>
                                <div class="gr21-col-8  gr21-col-no-margin-m feature-col3___2U73l">
                                  <p class="text-p2">
                                    Affordable for Students Competitive pricing
                                    with flexible options tailored for both JAES
                                    residents and non-residents.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="gr21-boxed-content feature-banner___1nnAO">
                              <div
                                class="feature-banner-desktop___3gl0E"
                                style={{ backgroundImage: `url(${sectiond})` }}
                              ></div>
                              <div
                                class="feature-banner-mobile___2m1Vm"
                                style={{ backgroundImage: `url(${sectionm})` }}
                              ></div>
                            </div>
                          </div>
                          <div class="gr21-boxed-content">
                            <div class="section-hr___2Wa1w"></div>
                          </div>
                          <div>
                            <div class="gr21-boxed-content section-title___rdFYJ">
                              <h2 class="text-h6">
                                We believe in clear and fair pricing tailored to
                                your needs:
                              </h2>
                            </div>
                            <div class="services-section-wrapper___2nfgK">
                              <div class="gr21-boxed-content services-section___3kcVY">
                                <div class="gr21-col-l-7 gr21-col-4 service-title-wrapper___1L66e">
                                  <h3>Everyday</h3>
                                </div>
                                <div class="gr21-col-l-9 gr21-col-4 gr21-col-no-margin-m service-content-wrapper___BLNpk">
                                  <div class="service-content___SMgwd">
                                    <div class="service-icon___2a4lq">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="72"
                                        viewBox="0 0 72 72"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M40.7009 54.64C55.7893 54.64 68.0209 42.4084 68.0209 27.32C68.0209 12.2316 55.7893 0 40.7009 0C25.6124 0 13.3809 12.2316 13.3809 27.32C13.3809 42.4084 25.6124 54.64 40.7009 54.64Z"
                                            fill="#CCEFDC"
                                          ></path>
                                          <path
                                            opacity="0.15"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M13.2992 44.2197H70.6992C71.0256 44.2483 71.3294 44.3982 71.5507 44.6398C71.772 44.8813 71.8948 45.1971 71.8948 45.5247C71.8948 45.8524 71.772 46.1681 71.5507 46.4097C71.3294 46.6513 71.0256 46.8011 70.6992 46.8297H13.2992C12.9728 46.8011 12.669 46.6513 12.4476 46.4097C12.2263 46.1681 12.1035 45.8524 12.1035 45.5247C12.1035 45.1971 12.2263 44.8813 12.4476 44.6398C12.669 44.3982 12.9728 44.2483 13.2992 44.2197Z"
                                            fill="#1C1C1C"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M65.4797 42.8102H22.4297V39.2002H68.0897C68.0897 41.6502 66.9197 42.8102 65.4797 42.8102Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M62.8791 33.2598H58.9991C56.8391 33.2598 55.0791 35.8498 55.0691 39.0598C55.0514 40.3254 55.3469 41.5758 55.9291 42.6998L55.9791 42.7898C55.9987 42.8318 56.0221 42.872 56.0491 42.9098C56.3192 43.4688 56.7307 43.9475 57.243 44.2983C57.7552 44.6492 58.3503 44.86 58.9691 44.9098C59.6891 44.9098 62.1691 44.9098 62.8891 44.9098C63.5117 44.8643 64.1114 44.6555 64.6276 44.3044C65.1437 43.9533 65.5582 43.4722 65.8291 42.9098L65.8891 42.8098C65.8929 42.7732 65.8929 42.7363 65.8891 42.6998C66.4748 41.5803 66.7737 40.3331 66.7591 39.0698C66.7591 35.8698 65.0191 33.2598 62.8591 33.2598"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M71.6809 34.5601L70.0909 31.4101C69.8086 30.8545 69.3368 30.4182 68.7609 30.1801L61.8309 27.3201C57.6339 23.1817 51.975 20.8643 46.0809 20.8701H27.1309C29.3518 20.8658 31.5364 21.4341 33.4737 22.5201C35.411 23.6061 37.0357 25.1732 38.1909 27.0701L38.3509 27.3201L45.2809 30.1801C45.8583 30.4159 46.3308 30.8529 46.6109 31.4101L48.2009 34.5601C48.3835 34.9178 48.4787 35.3136 48.4787 35.7151C48.4787 36.1167 48.3835 36.5125 48.2009 36.8701L46.8909 39.4501C46.8266 39.5958 46.7981 39.7547 46.8076 39.9136C46.8171 40.0725 46.8644 40.2268 46.9456 40.3637C47.0268 40.5007 47.1395 40.6162 47.2743 40.7008C47.4092 40.7854 47.5623 40.8366 47.7209 40.8501H68.0009C68.4827 40.8526 68.9557 40.7211 69.3671 40.4704C69.7785 40.2196 70.1122 39.8595 70.3309 39.4301L71.6409 36.8501C71.8181 36.4909 71.9103 36.0957 71.9103 35.6951C71.9103 35.2946 71.8181 34.8994 71.6409 34.5401"
                                            fill="#70C391"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M26.8398 23.4502V27.3202H61.8398C60.2784 25.7748 58.4955 24.4705 56.5498 23.4502H26.8398Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M21.58 34.4199H18.1C16.18 34.4199 14.62 36.7299 14.61 39.5799C14.5948 40.7058 14.8557 41.8182 15.37 42.8199V42.8899L15.44 42.9999C15.6821 43.4918 16.0484 43.9119 16.5026 44.2188C16.9569 44.5258 17.4833 44.7088 18.03 44.7499H21.51C22.0683 44.7188 22.6085 44.5405 23.0757 44.2331C23.5428 43.9258 23.9204 43.5003 24.17 42.9999C24.1667 42.97 24.1667 42.9398 24.17 42.9099L24.22 42.8199C24.7412 41.8236 25.009 40.7143 25 39.5899C25 36.7399 23.46 34.4199 21.54 34.4199"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M41.9996 33.7803H38.0996C35.9396 33.7803 34.1796 36.3703 34.1696 39.5803C34.1439 40.8433 34.4289 42.0933 34.9996 43.2203V43.3003C35.019 43.3456 35.0424 43.3891 35.0696 43.4303C35.3372 43.9912 35.7481 44.4715 36.2608 44.8227C36.7735 45.1738 37.3699 45.3834 37.9896 45.4303H41.9996C42.6219 45.3839 43.2211 45.1746 43.7372 44.8236C44.2532 44.4726 44.6678 43.9921 44.9396 43.4303L44.9996 43.3203C45.0031 43.287 45.0031 43.2535 44.9996 43.2203C45.5852 42.1008 45.8842 40.8536 45.8696 39.5903C45.8696 36.3803 44.1296 33.7803 41.9696 33.7803"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M48.2 34.5601L46.61 31.4101C46.3299 30.8529 45.8574 30.4159 45.28 30.1801L38.35 27.3201L38.19 27.0701C37.0348 25.1732 35.4102 23.6061 33.4729 22.5201C31.5355 21.4341 29.351 20.8658 27.13 20.8701H26.47C24.4511 20.867 22.4653 21.3832 20.7035 22.3692C18.9417 23.3551 17.4632 24.7777 16.41 26.5001C16.0904 27.0313 15.5897 27.4291 15 27.6201L14.68 27.7301C13.9042 27.9824 13.2276 28.4729 12.7465 29.1317C12.2654 29.7906 12.0042 30.5843 12 31.4001V36.1601C12.0077 37.1076 12.36 38.0199 12.9913 38.7266C13.6225 39.4333 14.4894 39.886 15.43 40.0001L22.43 40.8601H44.56C45.0418 40.8626 45.5148 40.7311 45.9262 40.4804C46.3377 40.2296 46.6713 39.8695 46.89 39.4401L48.2 36.8601C48.3826 36.5025 48.4778 36.1067 48.4778 35.7051C48.4778 35.3036 48.3826 34.9078 48.2 34.5501"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M38.0996 33.7803C35.9396 33.7803 34.1796 36.3703 34.1696 39.5803C34.1439 40.8433 34.4289 42.0933 34.9996 43.2203V43.3003C35.019 43.3456 35.0424 43.3891 35.0696 43.4303C35.3372 43.9912 35.7481 44.4715 36.2608 44.8227C36.7735 45.1738 37.3699 45.3834 37.9896 45.4303C38.6119 45.3839 39.2111 45.1746 39.7271 44.8236C40.2432 44.4726 40.6578 43.9921 40.9296 43.4303L40.9996 43.3203C41.0122 43.2851 41.029 43.2515 41.0496 43.2203C41.6631 42.1081 41.9896 40.8604 41.9996 39.5903C41.9996 36.3803 40.2596 33.7803 38.0996 33.7803Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M37.9991 36.3598C37.0691 36.3598 36.2991 37.7998 36.2991 39.5798C36.2926 40.2641 36.4183 40.9431 36.6691 41.5798V41.6498C36.9791 42.3198 37.4391 42.7398 37.9391 42.7398C38.4391 42.7398 38.9391 42.3198 39.2091 41.6498V41.5898V41.5298C39.4604 40.8933 39.586 40.2141 39.5791 39.5298C39.5791 37.7498 38.8291 36.2998 37.8891 36.2998"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.1 34.4199C16.18 34.4199 14.62 36.7299 14.61 39.5799C14.5948 40.7058 14.8557 41.8182 15.37 42.8199V42.8899L15.44 42.9999C15.6821 43.4918 16.0484 43.9119 16.5026 44.2188C16.9569 44.5258 17.4833 44.7088 18.03 44.7499C18.5883 44.7188 19.1285 44.5405 19.5957 44.2331C20.0628 43.9258 20.4404 43.5003 20.69 42.9999C20.74 42.91 20.74 42.91 20.7901 42.8198C21.3077 41.8227 21.5721 40.7134 21.56 39.5899C21.56 36.7399 20.02 34.4199 18.1 34.4199Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.0009 36.7197C17.1709 36.7197 16.4909 37.9997 16.4909 39.5797C16.4808 40.1956 16.593 40.8074 16.8209 41.3797C16.8252 41.3995 16.8252 41.42 16.8209 41.4397C17.1009 42.0297 17.5009 42.4397 17.9509 42.4397C18.4009 42.4397 18.8109 42.0697 19.0809 41.4397C19.3087 40.8709 19.4209 40.2624 19.4109 39.6497C19.4109 38.0597 18.7409 36.7797 17.9109 36.7797"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14.91 32.8896L15.91 29.8896H12.31C12.1057 30.3669 12.0002 30.8805 12 31.3996V33.7796H13.67C13.9438 33.7795 14.2106 33.6935 14.433 33.5339C14.6554 33.3743 14.8222 33.149 14.91 32.8896Z"
                                            fill="#ED6150"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M19.7401 27.3202H38.3501L38.1901 27.0702C37.3423 25.6619 36.2282 24.4323 34.9101 23.4502H23.2201C22.5842 23.455 21.9591 23.614 21.3982 23.9136C20.8374 24.2132 20.3576 24.6444 20.0001 25.1702L19.2301 26.3202C19.1641 26.4172 19.126 26.5304 19.12 26.6475C19.114 26.7647 19.1403 26.8812 19.196 26.9844C19.2517 27.0876 19.3347 27.1735 19.436 27.2327C19.5372 27.2919 19.6528 27.3222 19.7701 27.3202"
                                            fill="#70C391"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M56.7395 33.1298L64.5595 35.7098L68.7395 33.1298V31.8398H55.4395L56.7395 33.1298Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M58.5891 33.7298L56.5891 31.8398H46.7891L48.1791 34.5898C48.2697 34.7716 48.3401 34.9627 48.3891 35.1598L55.6791 36.5198L58.5891 33.7298Z"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M48.1995 34.5596L46.8095 31.8096H42.5195L44.6095 34.4296L48.4095 35.1396C48.3613 34.939 48.2908 34.7445 48.1995 34.5596Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M70.8298 31.8398H68.7398L68.0898 33.5198L69.9099 36.2298L71.6098 33.1298L70.8298 31.8398Z"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M69.29 40.56L68.38 37.88C68.2924 37.6234 68.1267 37.4005 67.9061 37.2427C67.6856 37.0849 67.4212 37.0001 67.15 37H58.35C58.136 36.9995 57.9252 37.0523 57.7367 37.1535C57.5482 37.2548 57.3878 37.4013 57.27 37.58L55 40.87H68C68.4356 40.8694 68.8646 40.763 69.25 40.56"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            d="M18.3898 38.9897C28.4967 38.9897 36.6898 30.7965 36.6898 20.6896C36.6898 10.5828 28.4967 2.38965 18.3898 2.38965C8.28303 2.38965 0.0898438 10.5828 0.0898438 20.6896C0.0898438 30.7965 8.28303 38.9897 18.3898 38.9897Z"
                                            fill="#43B771"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M20.3492 11.5703L18.1992 18.6103H21.2692H25.5792L16.4292 29.8803L18.5792 22.8303H16.9492V22.6903L16.9092 22.8303H11.1992L20.3492 11.5703Z"
                                            fill="white"
                                          ></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="72"
                                              height="72"
                                              fill="white"
                                            ></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <div class="service-text___2WFEw">
                                      <h4 class="text-h5">First Payment </h4>
                                      <div class="desktop___3pZIc">
                                        <p class="text-p2">
                                          •<strong>R450</strong> for students
                                          not residing in JAES accommodation.
                                          <br />
                                          •<strong>R250</strong> for
                                          residents residing in JAES
                                          accommodation
                                        </p>
                                      </div>
                                      <div class="mobile___37kBF">
                                        <p class="label">
                                          •<strong>R450</strong> for non-JAES accommodation students.
                                          <br />
                                        <span> •<strong>R250</strong> for
                                           JAES accommodation students.</span> 
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="service-content___SMgwd">
                                    <div class="service-icon___2a4lq">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="72"
                                        viewBox="0 0 72 72"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M40.7009 54.64C55.7893 54.64 68.0209 42.4084 68.0209 27.32C68.0209 12.2316 55.7893 0 40.7009 0C25.6124 0 13.3809 12.2316 13.3809 27.32C13.3809 42.4084 25.6124 54.64 40.7009 54.64Z"
                                            fill="#CCEFDC"
                                          ></path>
                                          <path
                                            opacity="0.15"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M13.2992 44.2197H70.6992C71.0256 44.2483 71.3294 44.3982 71.5507 44.6398C71.772 44.8813 71.8948 45.1971 71.8948 45.5247C71.8948 45.8524 71.772 46.1681 71.5507 46.4097C71.3294 46.6513 71.0256 46.8011 70.6992 46.8297H13.2992C12.9728 46.8011 12.669 46.6513 12.4476 46.4097C12.2263 46.1681 12.1035 45.8524 12.1035 45.5247C12.1035 45.1971 12.2263 44.8813 12.4476 44.6398C12.669 44.3982 12.9728 44.2483 13.2992 44.2197Z"
                                            fill="#1C1C1C"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M65.4797 42.8102H22.4297V39.2002H68.0897C68.0897 41.6502 66.9197 42.8102 65.4797 42.8102Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M62.8791 33.2598H58.9991C56.8391 33.2598 55.0791 35.8498 55.0691 39.0598C55.0514 40.3254 55.3469 41.5758 55.9291 42.6998L55.9791 42.7898C55.9987 42.8318 56.0221 42.872 56.0491 42.9098C56.3192 43.4688 56.7307 43.9475 57.243 44.2983C57.7552 44.6492 58.3503 44.86 58.9691 44.9098C59.6891 44.9098 62.1691 44.9098 62.8891 44.9098C63.5117 44.8643 64.1114 44.6555 64.6276 44.3044C65.1437 43.9533 65.5582 43.4722 65.8291 42.9098L65.8891 42.8098C65.8929 42.7732 65.8929 42.7363 65.8891 42.6998C66.4748 41.5803 66.7737 40.3331 66.7591 39.0698C66.7591 35.8698 65.0191 33.2598 62.8591 33.2598"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M71.6809 34.5601L70.0909 31.4101C69.8086 30.8545 69.3368 30.4182 68.7609 30.1801L61.8309 27.3201C57.6339 23.1817 51.975 20.8643 46.0809 20.8701H27.1309C29.3518 20.8658 31.5364 21.4341 33.4737 22.5201C35.411 23.6061 37.0357 25.1732 38.1909 27.0701L38.3509 27.3201L45.2809 30.1801C45.8583 30.4159 46.3308 30.8529 46.6109 31.4101L48.2009 34.5601C48.3835 34.9178 48.4787 35.3136 48.4787 35.7151C48.4787 36.1167 48.3835 36.5125 48.2009 36.8701L46.8909 39.4501C46.8266 39.5958 46.7981 39.7547 46.8076 39.9136C46.8171 40.0725 46.8644 40.2268 46.9456 40.3637C47.0268 40.5007 47.1395 40.6162 47.2743 40.7008C47.4092 40.7854 47.5623 40.8366 47.7209 40.8501H68.0009C68.4827 40.8526 68.9557 40.7211 69.3671 40.4704C69.7785 40.2196 70.1122 39.8595 70.3309 39.4301L71.6409 36.8501C71.8181 36.4909 71.9103 36.0957 71.9103 35.6951C71.9103 35.2946 71.8181 34.8994 71.6409 34.5401"
                                            fill="#70C391"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M26.8398 23.4502V27.3202H61.8398C60.2784 25.7748 58.4955 24.4705 56.5498 23.4502H26.8398Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M21.58 34.4199H18.1C16.18 34.4199 14.62 36.7299 14.61 39.5799C14.5948 40.7058 14.8557 41.8182 15.37 42.8199V42.8899L15.44 42.9999C15.6821 43.4918 16.0484 43.9119 16.5026 44.2188C16.9569 44.5258 17.4833 44.7088 18.03 44.7499H21.51C22.0683 44.7188 22.6085 44.5405 23.0757 44.2331C23.5428 43.9258 23.9204 43.5003 24.17 42.9999C24.1667 42.97 24.1667 42.9398 24.17 42.9099L24.22 42.8199C24.7412 41.8236 25.009 40.7143 25 39.5899C25 36.7399 23.46 34.4199 21.54 34.4199"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M41.9996 33.7803H38.0996C35.9396 33.7803 34.1796 36.3703 34.1696 39.5803C34.1439 40.8433 34.4289 42.0933 34.9996 43.2203V43.3003C35.019 43.3456 35.0424 43.3891 35.0696 43.4303C35.3372 43.9912 35.7481 44.4715 36.2608 44.8227C36.7735 45.1738 37.3699 45.3834 37.9896 45.4303H41.9996C42.6219 45.3839 43.2211 45.1746 43.7372 44.8236C44.2532 44.4726 44.6678 43.9921 44.9396 43.4303L44.9996 43.3203C45.0031 43.287 45.0031 43.2535 44.9996 43.2203C45.5852 42.1008 45.8842 40.8536 45.8696 39.5903C45.8696 36.3803 44.1296 33.7803 41.9696 33.7803"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M48.2 34.5601L46.61 31.4101C46.3299 30.8529 45.8574 30.4159 45.28 30.1801L38.35 27.3201L38.19 27.0701C37.0348 25.1732 35.4102 23.6061 33.4729 22.5201C31.5355 21.4341 29.351 20.8658 27.13 20.8701H26.47C24.4511 20.867 22.4653 21.3832 20.7035 22.3692C18.9417 23.3551 17.4632 24.7777 16.41 26.5001C16.0904 27.0313 15.5897 27.4291 15 27.6201L14.68 27.7301C13.9042 27.9824 13.2276 28.4729 12.7465 29.1317C12.2654 29.7906 12.0042 30.5843 12 31.4001V36.1601C12.0077 37.1076 12.36 38.0199 12.9913 38.7266C13.6225 39.4333 14.4894 39.886 15.43 40.0001L22.43 40.8601H44.56C45.0418 40.8626 45.5148 40.7311 45.9262 40.4804C46.3377 40.2296 46.6713 39.8695 46.89 39.4401L48.2 36.8601C48.3826 36.5025 48.4778 36.1067 48.4778 35.7051C48.4778 35.3036 48.3826 34.9078 48.2 34.5501"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M38.0996 33.7803C35.9396 33.7803 34.1796 36.3703 34.1696 39.5803C34.1439 40.8433 34.4289 42.0933 34.9996 43.2203V43.3003C35.019 43.3456 35.0424 43.3891 35.0696 43.4303C35.3372 43.9912 35.7481 44.4715 36.2608 44.8227C36.7735 45.1738 37.3699 45.3834 37.9896 45.4303C38.6119 45.3839 39.2111 45.1746 39.7271 44.8236C40.2432 44.4726 40.6578 43.9921 40.9296 43.4303L40.9996 43.3203C41.0122 43.2851 41.029 43.2515 41.0496 43.2203C41.6631 42.1081 41.9896 40.8604 41.9996 39.5903C41.9996 36.3803 40.2596 33.7803 38.0996 33.7803Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M37.9991 36.3598C37.0691 36.3598 36.2991 37.7998 36.2991 39.5798C36.2926 40.2641 36.4183 40.9431 36.6691 41.5798V41.6498C36.9791 42.3198 37.4391 42.7398 37.9391 42.7398C38.4391 42.7398 38.9391 42.3198 39.2091 41.6498V41.5898V41.5298C39.4604 40.8933 39.586 40.2141 39.5791 39.5298C39.5791 37.7498 38.8291 36.2998 37.8891 36.2998"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.1 34.4199C16.18 34.4199 14.62 36.7299 14.61 39.5799C14.5948 40.7058 14.8557 41.8182 15.37 42.8199V42.8899L15.44 42.9999C15.6821 43.4918 16.0484 43.9119 16.5026 44.2188C16.9569 44.5258 17.4833 44.7088 18.03 44.7499C18.5883 44.7188 19.1285 44.5405 19.5957 44.2331C20.0628 43.9258 20.4404 43.5003 20.69 42.9999C20.74 42.91 20.74 42.91 20.7901 42.8198C21.3077 41.8227 21.5721 40.7134 21.56 39.5899C21.56 36.7399 20.02 34.4199 18.1 34.4199Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.0009 36.7197C17.1709 36.7197 16.4909 37.9997 16.4909 39.5797C16.4808 40.1956 16.593 40.8074 16.8209 41.3797C16.8252 41.3995 16.8252 41.42 16.8209 41.4397C17.1009 42.0297 17.5009 42.4397 17.9509 42.4397C18.4009 42.4397 18.8109 42.0697 19.0809 41.4397C19.3087 40.8709 19.4209 40.2624 19.4109 39.6497C19.4109 38.0597 18.7409 36.7797 17.9109 36.7797"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14.91 32.8896L15.91 29.8896H12.31C12.1057 30.3669 12.0002 30.8805 12 31.3996V33.7796H13.67C13.9438 33.7795 14.2106 33.6935 14.433 33.5339C14.6554 33.3743 14.8222 33.149 14.91 32.8896Z"
                                            fill="#ED6150"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M19.7401 27.3202H38.3501L38.1901 27.0702C37.3423 25.6619 36.2282 24.4323 34.9101 23.4502H23.2201C22.5842 23.455 21.9591 23.614 21.3982 23.9136C20.8374 24.2132 20.3576 24.6444 20.0001 25.1702L19.2301 26.3202C19.1641 26.4172 19.126 26.5304 19.12 26.6475C19.114 26.7647 19.1403 26.8812 19.196 26.9844C19.2517 27.0876 19.3347 27.1735 19.436 27.2327C19.5372 27.2919 19.6528 27.3222 19.7701 27.3202"
                                            fill="#70C391"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M56.7395 33.1298L64.5595 35.7098L68.7395 33.1298V31.8398H55.4395L56.7395 33.1298Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M58.5891 33.7298L56.5891 31.8398H46.7891L48.1791 34.5898C48.2697 34.7716 48.3401 34.9627 48.3891 35.1598L55.6791 36.5198L58.5891 33.7298Z"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M48.1995 34.5596L46.8095 31.8096H42.5195L44.6095 34.4296L48.4095 35.1396C48.3613 34.939 48.2908 34.7445 48.1995 34.5596Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M70.8298 31.8398H68.7398L68.0898 33.5198L69.9099 36.2298L71.6098 33.1298L70.8298 31.8398Z"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M69.29 40.56L68.38 37.88C68.2924 37.6234 68.1267 37.4005 67.9061 37.2427C67.6856 37.0849 67.4212 37.0001 67.15 37H58.35C58.136 36.9995 57.9252 37.0523 57.7367 37.1535C57.5482 37.2548 57.3878 37.4013 57.27 37.58L55 40.87H68C68.4356 40.8694 68.8646 40.763 69.25 40.56"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            d="M18.2492 38.9897C28.356 38.9897 36.5492 30.7965 36.5492 20.6896C36.5492 10.5828 28.356 2.38965 18.2492 2.38965C8.14241 2.38965 -0.0507812 10.5828 -0.0507812 20.6896C-0.0507812 30.7965 8.14241 38.9897 18.2492 38.9897Z"
                                            fill="#43B771"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M26.2307 20.1798C25.3198 19.7574 24.3245 19.5488 23.3207 19.5698C22.3202 19.5496 21.3283 19.7583 20.4207 20.1798C20.1345 20.3223 19.8925 20.5401 19.7207 20.8098C20.1779 20.9428 20.6256 21.1065 21.0607 21.2998C21.7819 21.6152 22.4155 22.1014 22.9069 22.7164C23.3982 23.3314 23.7324 24.0567 23.8807 24.8298H27.2707V21.9998C27.2827 21.6299 27.1919 21.264 27.0082 20.9426C26.8246 20.6213 26.5555 20.3572 26.2307 20.1798Z"
                                            fill="white"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M23.3192 18.26C23.8398 18.262 24.3493 18.1094 24.7831 17.8216C25.2169 17.5338 25.5555 17.1238 25.7561 16.6434C25.9567 16.163 26.0102 15.6339 25.9099 15.1231C25.8095 14.6122 25.5599 14.1427 25.1924 13.7739C24.825 13.405 24.3564 13.1536 23.846 13.0513C23.3356 12.949 22.8062 13.0005 22.3251 13.1992C21.8439 13.398 21.4326 13.7351 21.1432 14.1678C20.8537 14.6005 20.6992 15.1094 20.6992 15.63C20.6992 16.3258 20.9749 16.9932 21.466 17.4861C21.957 17.9791 22.6234 18.2573 23.3192 18.26Z"
                                            fill="white"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M15.4407 20.8902C16.2245 20.8901 16.9905 20.6569 17.6413 20.2201C18.2921 19.7834 18.7983 19.1629 19.0954 18.4377C19.3925 17.7124 19.4672 16.9151 19.3098 16.1473C19.1525 15.3795 18.7703 14.6758 18.2118 14.1259C17.6534 13.5759 16.944 13.2045 16.1739 13.059C15.4037 12.9134 14.6077 13.0002 13.8871 13.3084C13.1664 13.6166 12.5538 14.1322 12.127 14.7896C11.7003 15.447 11.4788 16.2166 11.4907 17.0002C11.5039 18.0382 11.9261 19.0291 12.6658 19.7575C13.4054 20.4859 14.4026 20.893 15.4407 20.8902Z"
                                            fill="white"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M20.2792 23.1102C18.7467 22.4735 17.0983 22.1636 15.4392 22.2002C13.7812 22.165 12.1338 22.4714 10.5992 23.1002C10.0788 23.3433 9.63913 23.7308 9.33245 24.2165C9.02578 24.7022 8.865 25.2658 8.86922 25.8402V30.0902H21.9992V25.8402C22.0099 25.267 21.8528 24.7032 21.5472 24.2181C21.2416 23.7331 20.8009 23.348 20.2792 23.1102Z"
                                            fill="white"
                                          ></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="72"
                                              height="72"
                                              fill="white"
                                            ></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <div class="service-text___2WFEw">
                                      <h4 class="text-h5">Monthly payment</h4>
                                      <div class="desktop___3pZIc">
                                        <p class="text-p2">
                                        •<strong>R550</strong>  per month For all students 
                                        </p>
                                      </div>
                                      <div class="mobile___37kBF">
                                        <p class="label">
                                        •<strong>R550</strong>  per month For all students 
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="service-content___SMgwd">
                                    <div class="service-icon___2a4lq">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="72"
                                        viewBox="0 0 72 72"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M40.7009 54.64C55.7893 54.64 68.0209 42.4084 68.0209 27.32C68.0209 12.2316 55.7893 0 40.7009 0C25.6124 0 13.3809 12.2316 13.3809 27.32C13.3809 42.4084 25.6124 54.64 40.7009 54.64Z"
                                            fill="#CCEFDC"
                                          ></path>
                                          <path
                                            opacity="0.15"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M13.2992 44.2197H70.6992C71.0256 44.2483 71.3294 44.3982 71.5507 44.6398C71.772 44.8813 71.8948 45.1971 71.8948 45.5247C71.8948 45.8524 71.772 46.1681 71.5507 46.4097C71.3294 46.6513 71.0256 46.8011 70.6992 46.8297H13.2992C12.9728 46.8011 12.669 46.6513 12.4476 46.4097C12.2263 46.1681 12.1035 45.8524 12.1035 45.5247C12.1035 45.1971 12.2263 44.8813 12.4476 44.6398C12.669 44.3982 12.9728 44.2483 13.2992 44.2197Z"
                                            fill="#1C1C1C"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M65.4797 42.8102H22.4297V39.2002H68.0897C68.0897 41.6502 66.9197 42.8102 65.4797 42.8102Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M62.8791 33.2598H58.9991C56.8391 33.2598 55.0791 35.8498 55.0691 39.0598C55.0514 40.3254 55.3469 41.5758 55.9291 42.6998L55.9791 42.7898C55.9987 42.8318 56.0221 42.872 56.0491 42.9098C56.3192 43.4688 56.7307 43.9475 57.243 44.2983C57.7552 44.6492 58.3503 44.86 58.9691 44.9098C59.6891 44.9098 62.1691 44.9098 62.8891 44.9098C63.5117 44.8643 64.1114 44.6555 64.6276 44.3044C65.1437 43.9533 65.5582 43.4722 65.8291 42.9098L65.8891 42.8098C65.8929 42.7732 65.8929 42.7363 65.8891 42.6998C66.4748 41.5803 66.7737 40.3331 66.7591 39.0698C66.7591 35.8698 65.0191 33.2598 62.8591 33.2598"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M71.6809 34.5601L70.0909 31.4101C69.8086 30.8545 69.3368 30.4182 68.7609 30.1801L61.8309 27.3201C57.6339 23.1817 51.975 20.8643 46.0809 20.8701H27.1309C29.3518 20.8658 31.5364 21.4341 33.4737 22.5201C35.411 23.6061 37.0357 25.1732 38.1909 27.0701L38.3509 27.3201L45.2809 30.1801C45.8583 30.4159 46.3308 30.8529 46.6109 31.4101L48.2009 34.5601C48.3835 34.9178 48.4787 35.3136 48.4787 35.7151C48.4787 36.1167 48.3835 36.5125 48.2009 36.8701L46.8909 39.4501C46.8266 39.5958 46.7981 39.7547 46.8076 39.9136C46.8171 40.0725 46.8644 40.2268 46.9456 40.3637C47.0268 40.5007 47.1395 40.6162 47.2743 40.7008C47.4092 40.7854 47.5623 40.8366 47.7209 40.8501H68.0009C68.4827 40.8526 68.9557 40.7211 69.3671 40.4704C69.7785 40.2196 70.1122 39.8595 70.3309 39.4301L71.6409 36.8501C71.8181 36.4909 71.9103 36.0957 71.9103 35.6951C71.9103 35.2946 71.8181 34.8994 71.6409 34.5401"
                                            fill="#70C391"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M26.8398 23.4502V27.3202H61.8398C60.2784 25.7748 58.4955 24.4705 56.5498 23.4502H26.8398Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M21.58 34.4199H18.1C16.18 34.4199 14.62 36.7299 14.61 39.5799C14.5948 40.7058 14.8557 41.8182 15.37 42.8199V42.8899L15.44 42.9999C15.6821 43.4918 16.0484 43.9119 16.5026 44.2188C16.9569 44.5258 17.4833 44.7088 18.03 44.7499H21.51C22.0683 44.7188 22.6085 44.5405 23.0757 44.2331C23.5428 43.9258 23.9204 43.5003 24.17 42.9999C24.1667 42.97 24.1667 42.9398 24.17 42.9099L24.22 42.8199C24.7412 41.8236 25.009 40.7143 25 39.5899C25 36.7399 23.46 34.4199 21.54 34.4199"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M41.9996 33.7803H38.0996C35.9396 33.7803 34.1796 36.3703 34.1696 39.5803C34.1439 40.8433 34.4289 42.0933 34.9996 43.2203V43.3003C35.019 43.3456 35.0424 43.3891 35.0696 43.4303C35.3372 43.9912 35.7481 44.4715 36.2608 44.8227C36.7735 45.1738 37.3699 45.3834 37.9896 45.4303H41.9996C42.6219 45.3839 43.2211 45.1746 43.7372 44.8236C44.2532 44.4726 44.6678 43.9921 44.9396 43.4303L44.9996 43.3203C45.0031 43.287 45.0031 43.2535 44.9996 43.2203C45.5852 42.1008 45.8842 40.8536 45.8696 39.5903C45.8696 36.3803 44.1296 33.7803 41.9696 33.7803"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M48.2 34.5601L46.61 31.4101C46.3299 30.8529 45.8574 30.4159 45.28 30.1801L38.35 27.3201L38.19 27.0701C37.0348 25.1732 35.4102 23.6061 33.4729 22.5201C31.5355 21.4341 29.351 20.8658 27.13 20.8701H26.47C24.4511 20.867 22.4653 21.3832 20.7035 22.3692C18.9417 23.3551 17.4632 24.7777 16.41 26.5001C16.0904 27.0313 15.5897 27.4291 15 27.6201L14.68 27.7301C13.9042 27.9824 13.2276 28.4729 12.7465 29.1317C12.2654 29.7906 12.0042 30.5843 12 31.4001V36.1601C12.0077 37.1076 12.36 38.0199 12.9913 38.7266C13.6225 39.4333 14.4894 39.886 15.43 40.0001L22.43 40.8601H44.56C45.0418 40.8626 45.5148 40.7311 45.9262 40.4804C46.3377 40.2296 46.6713 39.8695 46.89 39.4401L48.2 36.8601C48.3826 36.5025 48.4778 36.1067 48.4778 35.7051C48.4778 35.3036 48.3826 34.9078 48.2 34.5501"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M38.0996 33.7803C35.9396 33.7803 34.1796 36.3703 34.1696 39.5803C34.1439 40.8433 34.4289 42.0933 34.9996 43.2203V43.3003C35.019 43.3456 35.0424 43.3891 35.0696 43.4303C35.3372 43.9912 35.7481 44.4715 36.2608 44.8227C36.7735 45.1738 37.3699 45.3834 37.9896 45.4303C38.6119 45.3839 39.2111 45.1746 39.7271 44.8236C40.2432 44.4726 40.6578 43.9921 40.9296 43.4303L40.9996 43.3203C41.0122 43.2851 41.029 43.2515 41.0496 43.2203C41.6631 42.1081 41.9896 40.8604 41.9996 39.5903C41.9996 36.3803 40.2596 33.7803 38.0996 33.7803Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M37.9991 36.3598C37.0691 36.3598 36.2991 37.7998 36.2991 39.5798C36.2926 40.2641 36.4183 40.9431 36.6691 41.5798V41.6498C36.9791 42.3198 37.4391 42.7398 37.9391 42.7398C38.4391 42.7398 38.9391 42.3198 39.2091 41.6498V41.5898V41.5298C39.4604 40.8933 39.586 40.2141 39.5791 39.5298C39.5791 37.7498 38.8291 36.2998 37.8891 36.2998"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.1 34.4199C16.18 34.4199 14.62 36.7299 14.61 39.5799C14.5948 40.7058 14.8557 41.8182 15.37 42.8199V42.8899L15.44 42.9999C15.6821 43.4918 16.0484 43.9119 16.5026 44.2188C16.9569 44.5258 17.4833 44.7088 18.03 44.7499C18.5883 44.7188 19.1285 44.5405 19.5957 44.2331C20.0628 43.9258 20.4404 43.5003 20.69 42.9999C20.74 42.91 20.74 42.91 20.7901 42.8198C21.3077 41.8227 21.5721 40.7134 21.56 39.5899C21.56 36.7399 20.02 34.4199 18.1 34.4199Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.0009 36.7197C17.1709 36.7197 16.4909 37.9997 16.4909 39.5797C16.4808 40.1956 16.593 40.8074 16.8209 41.3797C16.8252 41.3995 16.8252 41.42 16.8209 41.4397C17.1009 42.0297 17.5009 42.4397 17.9509 42.4397C18.4009 42.4397 18.8109 42.0697 19.0809 41.4397C19.3087 40.8709 19.4209 40.2624 19.4109 39.6497C19.4109 38.0597 18.7409 36.7797 17.9109 36.7797"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14.91 32.8896L15.91 29.8896H12.31C12.1057 30.3669 12.0002 30.8805 12 31.3996V33.7796H13.67C13.9438 33.7795 14.2106 33.6935 14.433 33.5339C14.6554 33.3743 14.8222 33.149 14.91 32.8896Z"
                                            fill="#ED6150"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M19.7401 27.3202H38.3501L38.1901 27.0702C37.3423 25.6619 36.2282 24.4323 34.9101 23.4502H23.2201C22.5842 23.455 21.9591 23.614 21.3982 23.9136C20.8374 24.2132 20.3576 24.6444 20.0001 25.1702L19.2301 26.3202C19.1641 26.4172 19.126 26.5304 19.12 26.6475C19.114 26.7647 19.1403 26.8812 19.196 26.9844C19.2517 27.0876 19.3347 27.1735 19.436 27.2327C19.5372 27.2919 19.6528 27.3222 19.7701 27.3202"
                                            fill="#70C391"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M56.7395 33.1298L64.5595 35.7098L68.7395 33.1298V31.8398H55.4395L56.7395 33.1298Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M58.5891 33.7298L56.5891 31.8398H46.7891L48.1791 34.5898C48.2697 34.7716 48.3401 34.9627 48.3891 35.1598L55.6791 36.5198L58.5891 33.7298Z"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M48.1995 34.5596L46.8095 31.8096H42.5195L44.6095 34.4296L48.4095 35.1396C48.3613 34.939 48.2908 34.7445 48.1995 34.5596Z"
                                            fill="#364F40"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M70.8298 31.8398H68.7398L68.0898 33.5198L69.9099 36.2298L71.6098 33.1298L70.8298 31.8398Z"
                                            fill="#F3F9F6"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M69.29 40.56L68.38 37.88C68.2924 37.6234 68.1267 37.4005 67.9061 37.2427C67.6856 37.0849 67.4212 37.0001 67.15 37H58.35C58.136 36.9995 57.9252 37.0523 57.7367 37.1535C57.5482 37.2548 57.3878 37.4013 57.27 37.58L55 40.87H68C68.4356 40.8694 68.8646 40.763 69.25 40.56"
                                            fill="#364F40"
                                          ></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="72"
                                              height="72"
                                              fill="white"
                                            ></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <div class="service-text___2WFEw">
                                      <h4 class="text-h5">Discounts </h4>
                                      <div class="desktop___3pZIc">
                                        <p class="text-p2">
                                          Everyday car for your everyday needs
                                        </p>
                                      </div>
                                      <div class="mobile___37kBF">
                                        <p class="label">
                                          Everyday car for your everyday needs
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="section-color-banner___2Zbt3">
                            <div>
                              <div
                                class="container___14dI8 light___55wHd"
                                style={{ background: "#ff5a5f" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="64"
                                  height="64"
                                  viewBox="0 0 64 64"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M56 31.2C56 52 32 60 32 60C32 60 8 52 8 31.2V10.6035C8 10.6035 18 4 32 4C46 4 56 10.6035 56 10.6035V31.2ZM16.1983 11.0303C14.4427 11.7123 13.021 12.3908 12 12.9233V31.2C12 39.8652 16.9525 46.0233 22.4337 50.2256C25.156 52.3127 27.9009 53.8251 29.9744 54.8158C30.7706 55.1962 31.4597 55.4958 32 55.7184C32.5403 55.4958 33.2294 55.1962 34.0256 54.8158C36.0991 53.8251 38.844 52.3127 41.5663 50.2256C47.0475 46.0233 52 39.8652 52 31.2V12.9233C50.979 12.3908 49.5573 11.7123 47.8017 11.0303C43.8807 9.50728 38.3797 8 32 8C25.6203 8 20.1193 9.50728 16.1983 11.0303ZM16.4004 16.2451C16.4004 16.2451 22.0004 12 32.0003 12V50.8C32.0003 50.8 16.4004 43.6 16.4004 30V16.2451Z"
                                    fill="white"
                                  ></path>
                                </svg>
                                <h2 class="title___1-J3t text-h3">
                                  How It Works
                                </h2>
                                <div class="features___LJCb6">
                                  <div class="gr21-col-5 feature___3pJHK">
                                    <h3 class="text-h5">1. Register and Pay</h3>
                                    <p>
                                    Getting started is simple! Sign up and make your payment using EFT or our secure online payment system. It's quick, safe, and convenient.
                                    </p>
                                  </div>
                                  <div class="gr21-col-5 feature___3pJHK">
                                    <h3 class="text-h5">2. Book Your Ride</h3>
                                    <p>
                                    Booking your trip is a breeze. Select your preferred date and time with just a few clicks, and your ride is confirmed. No hassle, no fuss!
                                    </p>
                                  </div>
                                  <div class="gr21-col-5 feature___3pJHK">
                                    <h3 class="text-h5">3. Hop On and Go</h3>
                                    <p>
                                    Whether it’s a morning ride to campus or a pick-up after class, we’ve got you covered. Our reliable drivers will get you to your destination comfortably and on time.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <a
                                href=""
                                target="_blank"
                                style={{ color: "#ff5a5f" }}
                                data-grab-tracking='{"event":"grabcom_wp","category":"cta","action":"click","tracking_name":"safety_cta","label":"Learn more"}'
                                class="fw-button___B0CJs"
                              >
                                <p class="callout1 text___1IRRf mobile___3d3QE">
                                  Learn more
                                </p>
                                <p class="callout1 text___1IRRf desktop___Bg3MA">
                                  Learn more
                                </p>
                                <div class="background___14xZF safety___10U_W"></div>
                              </a>
                            </div>
                          </div>
                          <div class="section-faq___1mvSs">
                            <div class="">
                              <div class="gr21-boxed-content faq-title___2Vlsv ">
                                <h2 class="text-h6">FAQs</h2>
                              </div>
                              <div class="gr21-boxed-content ">
                                <div class="gr21-col-4 gr21-col-m-7 faq-col1___GzcBu">
                                  <h3>Got questions? We’ve got answers.</h3>
                               {/*    <p>
                                    For more questions, please visit our
                                    <a
                                      target="blank"
                                      href="https://help.grab.com/passenger/en-sg/115001244167-Transport/"
                                    >
                                      Help Centre
                                    </a> 
                                  </p>*/}
                                    .
                                </div>
                                <div class="gr21-col-m-1"></div>
                                <div class="gr21-col-4 gr21-col-m-8 gr21-col-no-margin-m  faqs___1AADM">
                                  <div
                                    style={{
                                      maxWidth: "700px",
                                      margin: "50px auto",
                                      fontFamily: "Arial, sans-serif",
                                    }}
                                  >
                                    <div
                                      className="faq-container"
                                      style={{ borderRadius: "5px" }}
                                    >
                                      {faqData.map((faq, index) => (
                                        <div
                                          key={index}
                                          className={`faq-item ${
                                            activeIndex === index
                                              ? "active"
                                              : ""
                                          }`}
                                          style={{
                                            borderTop: "2px solid #ddd",
                                          }}
                                        >
                                          <div
                                            className="faq-question"
                                            onClick={() => toggleFAQ(index)}
                                            style={{
                                              padding: "15px 20px",
                                              cursor: "pointer",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              backgroundColor:
                                                activeIndex === index
                                                  ? "white"
                                                  : "white",
                                            }}
                                          >
                                            {faq.question}
                                            <span
                                              className="toggle-icon"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                                color: "#333",
                                              }}
                                            >
                                              {activeIndex === index
                                                ? "−"
                                                : "+"}
                                            </span>
                                          </div>
                                          {activeIndex === index && (
                                            <div
                                              className="faq-answer"
                                              style={{
                                                padding: "15px 20px",
                                                fontSize: "14px",
                                                color: "#666",
                                                lineHeight: "1.6",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <p>{faq.answer}</p>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div
                                class="color-wrapper___1synS evergreen___nkxi-"
                                style={{ backgroundColor: "#ff5a5f" }}
                              >
                                <div class="gr21-boxed-content content___3P1Rv">
                                  <div class="gr21-col-l-7 gr21-col-4 gr21-col-no-margin-m">
                                    <h2 class="text___V6Hux margin-bottom___1VmqT text-h6">
                                      Mobility
                                    </h2>
                                  </div>
                                  <div class="gr21-col-l-1"></div>
                                  <div class="gr21-col-l-8 gr21-col-4 gr21-col-no-margin-m">
                                    <h3 class="text___V6Hux">
                                      Safe and reliable rides to get you where
                                      you need to go.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <a
                                onClick={handleJoinClick}
                                style={{ backgroundColor: "#ff5a52" }}
                                data-grab-tracking='{"event":"grabcom_wp","category":"cta","action":"click","tracking_name":"bottom_cta","label":"Book Now"}'
                                class="fw-button___B0CJs"
                              >
                                <p class="callout1 text___1IRRf mobile___3d3QE">
                                  Book Now
                                </p>
                                <p class="callout1 text___1IRRf desktop___Bg3MA">
                                  Book Now
                                </p>
                                <div class="background___14xZF cta-primary___xMeog"></div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      class="elementor-element elementor-element-4075aa5 elementor-widget elementor-widget-grab21_page_product_gfg"
                      data-id="4075aa5"
                      data-element_type="widget"
                      data-widget_type="grab21_page_product_gfg.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="tmp gr21-product-gfg___1kXCa">
                          <div class="section-slider___3ZBbH">
                            <div class="gr21-boxed-content">
                              <h5 class="slider-title">May we sugest</h5>
                            </div>
                            <div class="gr21-boxed-content slider-desktop___1IzXw">
                              <div
                                id="slider--4075aa5"
                                class="uk-position-relative uk-visible-toggle uk-light slider-gr21"
                                tabindex="-1"
                                uk-slider="left:true;finite:true;draggable:false"
                                data-count="2"
                              >
                                <div class="slider-actions___CCrQB slider-actions-no-padding-right___1ebsi">
                                  <a uk-slider-item="previous">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10.4339 5.8463C10.7415 5.53876 10.7415 5.04014 10.4339 4.73261C10.1264 4.42507 9.62776 4.42507 9.32022 4.73261L4.28514 9.76768L4.28503 9.76779C4.0062 10.0466 3.76411 10.2887 3.5807 10.5047C3.38667 10.7333 3.21252 10.984 3.11196 11.2935C3.03732 11.5232 3 11.7617 3 12.0003C3 12.2389 3.03732 12.4775 3.11196 12.7072C3.21252 13.0167 3.38667 13.2674 3.5807 13.4959C3.76412 13.712 4.00624 13.9541 4.2851 14.2329L4.28513 14.233L9.32022 19.268C9.62776 19.5756 10.1264 19.5756 10.4339 19.268C10.7415 18.9605 10.7415 18.4619 10.4339 18.1543L5.42008 13.1405C5.26514 12.9856 5.13849 12.8587 5.03345 12.7503H20.25C20.6642 12.7503 21 12.4145 21 12.0003C21 11.5861 20.6642 11.2503 20.25 11.2503H5.03348C5.13851 11.1419 5.26516 11.015 5.42008 10.8601L10.4339 5.8463Z"
                                        fill="#242A2E"
                                      ></path>
                                    </svg>
                                  </a>
                                  <a uk-slider-item="next">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M13.5661 18.1524C13.2585 18.4599 13.2585 18.9585 13.5661 19.2661C13.8736 19.5736 14.3722 19.5736 14.6798 19.2661L19.7149 14.231L19.715 14.2309C19.9938 13.9521 20.2359 13.71 20.4193 13.494C20.6133 13.2654 20.7875 13.0147 20.888 12.7052C21.0373 12.2458 21.0373 11.7509 20.888 11.2915C20.7875 10.982 20.6133 10.7313 20.4193 10.5027C20.2359 10.2867 19.9938 10.0446 19.7149 9.76577L19.7149 9.76571L14.6798 4.73065C14.3722 4.42312 13.8736 4.42312 13.5661 4.73065C13.2585 5.03819 13.2585 5.53681 13.5661 5.84435L18.5799 10.8581C18.7349 11.0131 18.8615 11.14 18.9665 11.2484H3.75C3.33579 11.2484 3 11.5842 3 11.9984C3 12.4126 3.33579 12.7484 3.75 12.7484H18.9665C18.8615 12.8568 18.7348 12.9836 18.5799 13.1386L13.5661 18.1524Z"
                                        fill="#242A2E"
                                      ></path>
                                    </svg>
                                  </a>
                                </div>
                                <ul
                                  class="uk-slider-items uk-width-1-1 uk-grid"
                                  uk-height-match
                                >
                                  <li
                                    data-index="0"
                                    id="item--4075aa5-1"
                                    class="default-item___1BlXV  uk-active gr21-col-l-8"
                                  >
                                    <span class="slider-pagi___28FK1">1/2</span>
                                    <div
                                      class="slider-image-wrapper___2x-ji"
                                      style={{ borderRadius: "10px" }}
                                    >
                                      <div
                                        class="slider-image___39SR7"
                                        style={{
                                          backgroundImage: `url(${minscaled})`,
                                        }}
                                      ></div>
                                    </div>
                                    <div class="slider-content___SDEFz">
                                      <h4 class="slider-h4___1bCBX ">
                                        Advance Booking
                                      </h4>
                                      <p class="text-p2 slider-p2___3-Urn">
                                        Pre-book a guaranteed, stress-free ride
                                        to the airport.
                                      </p>
                                      <a
                                        id="cta--4075aa5-1"
                                        class="slider-cta___3x_C2"
                                        href="advance-booking/index.html"
                                        data-grab-tracking='{"event":"grabcom_wp","category":"cta","action":"click","tracking_name":"0","label":"Advance Booking: Pre-book a guaranteed, stress-free ride to the airport."}'
                                      >
                                        Learn more
                                      </a>
                                    </div>
                                  </li>
                                  <li
                                    data-index="1"
                                    id="item--4075aa5-2"
                                    class="default-item___1BlXV  uk-active gr21-col-l-8"
                                  >
                                    <span class="slider-pagi___28FK1">2/2</span>
                                    <div
                                      class="slider-image-wrapper___2x-ji"
                                      style={{ borderRadius: "10px" }}
                                    >
                                      <div
                                        class="slider-image___39SR7"
                                        style={{
                                          backgroundImage: `url(${ridecoversection})`,
                                        }}
                                      ></div>
                                    </div>
                                    <div class="slider-content___SDEFz">
                                      <h4 class="slider-h4___1bCBX ">
                                        Ride Cover
                                      </h4>
                                      <p class="text-p2 slider-p2___3-Urn">
                                        Upsize your protection for your Jaes
                                        rides
                                      </p>
                                      <a
                                        id="cta--4075aa5-2"
                                        class="slider-cta___3x_C2"
                                        href="https://www.grab.com/sg/consumer/finance/insurance/ride-cover/"
                                        data-grab-tracking='{"event":"grabcom_wp","category":"cta","action":"click","tracking_name":"1","label":"Ride Cover: Upsize your protection for your Jaes rides"}'
                                      >
                                        Learn more
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="slider-mobile___3LgUw">
                              <div class="" uk-slider="finite: true">
                                <div id="slider--4075aa5" class=""></div>
                                <ul
                                  class="uk-slider-items gr21-mobile-slider"
                                  uk-height-match
                                >
                                  <li>
                                    <div class="slider-item___lbF4I">
                                      <div class="slider-pagi-div___2xK5x">
                                        <span class="text-p3 slider-pagi___28FK1">
                                          1/2
                                        </span>
                                      </div>
                                      <div
                                        class="slider-image-wrapper___2x-ji"
                                        style={{ borderRadius: "10px" }}
                                      >
                                        <div
                                          class="slider-image___39SR7"
                                          style={{
                                            backgroundImage: `url(${minscaled})`,
                                          }}
                                        ></div>
                                      </div>
                                      <div class="slider-content___SDEFz">
                                        <h4 class="slider-h4___1bCBX">
                                          Advance Booking
                                        </h4>
                                        <p class="text-p2 slider-p2___3-Urn">
                                          Pre-book a guaranteed, stress-free
                                          ride to the airport.
                                        </p>
                                      </div>
                                      <a
                                        id="-4075aa5-1"
                                        class="slider-cta___3x_C2"
                                        href="advance-booking/index.html"
                                      >
                                        Learn more
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="slider-item___lbF4I">
                                      <div class="slider-pagi-div___2xK5x">
                                        <span class="text-p3 slider-pagi___28FK1">
                                          2/2
                                        </span>
                                      </div>
                                      <div
                                        class="slider-image-wrapper___2x-ji"
                                        style={{ borderRadius: "10px" }}
                                      >
                                        <div
                                          class="slider-image___39SR7"
                                          style={{
                                            backgroundImage: `url(${ridecoversection})`,
                                          }}
                                        ></div>
                                      </div>
                                      <div class="slider-content___SDEFz">
                                        <h4 class="slider-h4___1bCBX">
                                          Ride Cover
                                        </h4>
                                        <p class="text-p2 slider-p2___3-Urn">
                                          Upsize your protection for your Jaes
                                          rides
                                        </p>
                                      </div>
                                      <a
                                        id="-4075aa5-2"
                                        class="slider-cta___3x_C2"
                                        href="https://www.grab.com/sg/consumer/finance/insurance/ride-cover/"
                                      >
                                        Learn more
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-c0c4eca elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="c0c4eca"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-65306be"
                  data-id="65306be"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {showRegistrationModal && (
        <RegistrationModal onClose={() => setShowRegistrationModal(false)} />
      )}
    </body>
  );
};

export default IntroTransport;
