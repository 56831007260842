import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './TransportHistory.css'; // Optional: Add custom styling here

const TransportHistory = () => {
    const { userId } = useParams(); // Extract UserId from URL params
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Replace this with your API endpoint
    const apiEndpoint = `https://backendjaes.passiontrials.co.za/transport_monthly_payment/all/${userId}`;

    useEffect(() => {
        // Fetch transport payment history for the specified user
        const fetchHistory = async () => {
            try {
                const response = await fetch(apiEndpoint);
                if (!response.ok) {
                    throw new Error('Failed to fetch history.');
                }
                const data = await response.json();
                setHistory(data); // Set fetched data to state
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchHistory();
    }, [apiEndpoint]);

    return (
        <div className="transport-history-container">
            <h2>Transport Payment History</h2>
            <p><strong>User ID:</strong> {userId}</p>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {!loading && !error && history.length > 0 ? (
                <table className="transport-history-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Month</th>
                            <th>Price</th>
                            <th>January</th>
                            <th>February</th>
                            <th>March</th>
                            <th>April</th>
                            <th>May</th>
                            <th>June</th>
                            <th>July</th>
                            <th>August</th>
                            <th>September</th>
                            <th>October</th>
                            <th>November</th>
                            <th>December</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.map((record, index) => (
                            <tr key={record.TMP_Id}>
                                <td>{index + 1}</td>
                                <td>{record.Month}</td>
                                <td>{`R${record.Price.toFixed(2)}`}</td>
                                <td>{record.January === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.February === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.March === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.April === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.May === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.June === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.July === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.August === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.September === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.October === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.November === "Paid" ? "✔️" : "❌"}</td>
                                <td>{record.December === "Paid" ? "✔️" : "❌"}</td>
                                <td>{new Date(record.CreatedAt).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No records found for User ID {userId}.</p>
            )}
        </div>
    );
};

export default TransportHistory;
