import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './AuthProvider';

function RecurringTransport() {
    const location = useLocation();
    const { userId: authUserId } = useAuth();
    const navigate = useNavigate();
    const { apartment, amount, address } = location.state || {}; // Include address in state
    const checker = useRef(false);

    // Determine the userId
    const userId = apartment?.UserId || authUserId;
    const recurringAmount = parseFloat(amount || 0); // Default to 0 if amount is undefined

    useEffect(() => {
        if (!userId || !recurringAmount) {
            console.warn("Missing necessary information.");
            return;
        }
        if (!checker.current) {
            console.log("Processing payment for user:", userId);
            createYocoPayment();
            checker.current = true;
        }
    }, [userId, recurringAmount]);

    const createYocoPayment = async () => {
        try {
            const amountInCents = Math.round(recurringAmount * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/transport/api/pay', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/transportWallet?status=success&user=${userId}&amount=${recurringAmount}&address=${encodeURIComponent(address)}`, // Pass address in URL
                    cancelUrl: 'https://jaesstudenthomes.co.za/transportCancel?status=cancel',
                    room: `${apartment?.IdNumber || 'No-ID'}-${apartment?.Email || 'No-Email'}`, // Default if apartment is null
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log("Payment response:", data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (error) {
            console.error("Payment error:", error);
        }
    };

    if (!userId) {
        return (
            <div style={{ marginTop: "149px" }}>
                Error: Missing information. Please ensure all required data is provided.
            </div>
        );
    }

    return (
        <div style={{ marginTop: "149px" }}>
            <h1>Processing your transport payment...</h1>
            <p>If you are not automatically redirected, please click submit.</p>
        </div>
    );
}

export default RecurringTransport;
