// import React, { useState, useEffect } from 'react';
// import { useAuth } from './AuthProvider';
// import './AdminuserPayment.css';
// import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf'; // For PDF
// import autoTable from 'jspdf-autotable'; // For PDF Table
// import * as XLSX from 'xlsx'; // For Excel
// import { saveAs } from 'file-saver'; // For saving files

// function UserPayment() {
//     const [groupedData, setGroupedData] = useState({});
//     const { userId } = useAuth();
//     const [error, setError] = useState(null);
//     const [totalAmountAllBuildings, setTotalAmountAllBuildings] = useState(0);
//     const navigate = useNavigate();
//     const [rawData, setRawData] = useState([]);

//     useEffect(() => {
//         if (userId) {
//             fetch(`https://backendjaes.passiontrials.co.za/groupedWalletByBuilding`, {
//                 headers: { 'Content-Type': 'application/json' }
//             })
//                 .then(res => {
//                     if (!res.ok) { throw new Error('Failed to get wallet data'); }
//                     return res.json();
//                 })
//                 .then(data => {
//                     setRawData(data); // Save the raw data in state

//                     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

//                     // Group the data by building
//                     const grouped = data.reduce((acc, current) => {
//                         const buildingName = current.BuildingName;

//                         if (!acc[buildingName]) {
//                             acc[buildingName] = {
//                                 totalAmount: 0,
//                                 payments: {}
//                             };
//                         }

//                         const userId = current.UserId;

//                         // Initialize user's payment status for all months
//                         if (!acc[buildingName].payments[userId]) {
//                             acc[buildingName].payments[userId] = months.map(month => ({
//                                 Month: month,
//                                 Status: 'Not Paid'
//                             }));
//                         }

//                         // Mark specific months as Paid
//                         const monthYear = current.MonthYear; // e.g., 'January 2025'
//                         const [month] = monthYear.split(' '); // Extract the month
//                         acc[buildingName].payments[userId] = acc[buildingName].payments[userId].map(payment => {
//                             if (payment.Month === month) {
//                                 return { ...payment, Status: 'Paid' };
//                             }
//                             return payment;
//                         });

//                         acc[buildingName].totalAmount += current.AmountPaid;
//                         return acc;
//                     }, {});

//                     const totalAllBuildings = Object.values(grouped).reduce((total, building) => total + building.totalAmount, 0);
//                     setTotalAmountAllBuildings(totalAllBuildings);
//                     setGroupedData(grouped);
//                 })
//                 .catch(err => {
//                     console.error('Error:', err);
//                     setError(err.message);
//                 });
//         }
//     }, [userId]);

//     return (
//         <>
//             <div className="husky-container">
//                 <h2 className="husky-title">Statement by Building</h2>
//                 <h3 className="husky-subtitle">Total Amount Paid Across All Buildings: R{totalAmountAllBuildings.toFixed(2)}</h3>

//                 <div className="husky-container">
//                     {error && <p className="husky-error">{error}</p>}

//                     {/* Display buildings */}
//                     {Object.keys(groupedData).length > 0 ? (
//                         Object.keys(groupedData).map(buildingName => (
//                             <div key={buildingName} className="husky-building">
//                                 <h3>{buildingName}</h3>
//                                 <p>Total Amount Paid: R{groupedData[buildingName].totalAmount.toFixed(2)}</p>

//                                 <table className="husky-table">
//                                     <thead>
//                                         <tr>
//                                             <th>Tenant</th>
//                                             <th>Apartment Number</th>
//                                             {['January', 'February', 'March', 'April', 'May', 'June', 'July'].map(month => (
//                                                 <th key={month}>{month}</th>
//                                             ))}
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {Object.keys(groupedData[buildingName].payments).map((userId, index) => {
//                                             const userPayments = groupedData[buildingName].payments[userId];
//                                             const userDetails = rawData.find(payment => payment.UserId === parseInt(userId));
//                                             if (!userDetails) return null; // Ensure userDetails exists
//                                             return (
//                                                 <tr key={index}>
//                                                     <td>{`${userDetails.UserName} ${userDetails.UserSurname}`}</td>
//                                                     <td>{userDetails.ApartmentNumber}</td>
//                                                     {userPayments.map((payment, monthIndex) => (
//                                                         <td key={monthIndex} className={payment.Status === 'Paid' ? 'paid' : 'not-paid'}>
//                                                             {payment.Status}
//                                                         </td>
//                                                     ))}
//                                                 </tr>
//                                             );
//                                         })}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         ))
//                     ) : (
//                         <p>No records found.</p>
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// }

// export default UserPayment;
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import './AdminuserPayment.css';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf'; // For PDF
import autoTable from 'jspdf-autotable'; // For PDF Table
import * as XLSX from 'xlsx'; // For Excel
import { saveAs } from 'file-saver'; // For saving files

function UserPayment() {
    const [groupedData, setGroupedData] = useState({});
    const { userId } = useAuth();
    const [error, setError] = useState(null);
    const [totalAmountAllBuildings, setTotalAmountAllBuildings] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const navigate = useNavigate();

    useEffect(() => {
        if (userId) {
            fetch(`https://backendjaes.passiontrials.co.za/groupedWalletByBuilding`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(res => {
                    if (!res.ok) { throw new Error('Failed to get wallet data'); }
                    return res.json();
                })
                .then(data => {
                    // Group the data by building and calculate the total for each building
                    const grouped = data.reduce((acc, current) => {
                        const buildingName = current.BuildingName;
                        if (!acc[buildingName]) {
                            acc[buildingName] = {
                                totalAmount: 0,
                                payments: []
                            };
                        }
                        acc[buildingName].totalAmount += current.AmountPaid; // Sum the AmountPaid for each building
                        acc[buildingName].payments.push(current);
                        return acc;
                    }, {});

                    // Calculate total across all buildings
                    const totalAllBuildings = Object.values(grouped).reduce((total, building) => total + building.totalAmount, 0);
                    setTotalAmountAllBuildings(totalAllBuildings); // Set the total amount for all buildings
                    setGroupedData(grouped);
                })
                .catch(err => {
                    console.error('Error:', err);
                    setError(err.message);
                });
        }
    }, [userId]);

    // Function to format the date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // Example: '23 July 2024'
    };

    // Function to filter payments based on the search term
    const filterPayments = (payments) => {
        return payments.filter((payment) => {
            const searchTermLower = searchTerm.toLowerCase();
            const paymentDate = formatDate(payment.PaymentDate).toLowerCase();
            const matchesBuildingName = payment.BuildingName.toLowerCase().includes(searchTermLower);
            const matchesUserName = payment.UserName.toLowerCase().includes(searchTermLower);
            const matchesUserSurname = payment.UserSurname.toLowerCase().includes(searchTermLower);
            const matchesApartmentNumber = payment.ApartmentNumber.toLowerCase().includes(searchTermLower);
            const matchesDate = paymentDate.includes(searchTermLower);

            // Match any of the conditions
            return matchesBuildingName || matchesUserName || matchesUserSurname || matchesApartmentNumber || matchesDate;
        });
    };

    // Filtered data based on the search term
    const filteredGroupedData = Object.keys(groupedData).reduce((acc, buildingName) => {
        const filteredPayments = filterPayments(groupedData[buildingName].payments);
        if (filteredPayments.length > 0) {
            acc[buildingName] = {
                ...groupedData[buildingName],
                payments: filteredPayments
            };
        }
        return acc;
    }, {});

    // Function to download all data as Excel
    const downloadAllExcel = () => {
        const ws = XLSX.utils.json_to_sheet(
            Object.keys(filteredGroupedData).flatMap(buildingName =>
                filteredGroupedData[buildingName].payments.map(pay => ({
                    Building: buildingName,
                    Tenant: `${pay.UserName} ${pay.UserSurname}`,
                    ApartmentNumber: pay.ApartmentNumber,
                    PaymentDate: formatDate(pay.PaymentDate),
                    Amount: pay.AmountPaid,
                    PaymentMethod: pay.PaymentMethod || 'N/A',
                    MonthYear: pay.MonthYear || 'N/A'
                }))
            )
        );

        // Add a row for total amount
        XLSX.utils.sheet_add_aoa(ws, [['', '', '', 'Total:', totalAmountAllBuildings]], { origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'All Payments');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'All_Payments.xlsx');
    };


    // Function to download all data as PDF
    const downloadAllPDF = () => {
        const doc = new jsPDF();
        Object.keys(filteredGroupedData).forEach((buildingName, index) => {
            if (index !== 0) doc.addPage();
            doc.text(buildingName, 10, 10);

            const payments = filteredGroupedData[buildingName].payments.map(pay => [
                `${pay.UserName} ${pay.UserSurname}`,
                pay.ApartmentNumber,
                formatDate(pay.PaymentDate),
                `R${pay.AmountPaid}`,
                pay.PaymentMethod || 'N/A',
                pay.MonthYear || 'N/A'
            ]);

            autoTable(doc, {
                head: [['Tenant', 'Apartment Number', 'Payment Date', 'Amount', 'Payment Method', 'Month']],
                body: payments,
                startY: 20
            });

            // Add total amount for the building
            doc.text(`Total: R${filteredGroupedData[buildingName].totalAmount.toFixed(2)}`, 10, doc.previousAutoTable.finalY + 10);
        });

        // Add total across all buildings
        doc.text(`Total Across All Buildings: R${totalAmountAllBuildings.toFixed(2)}`, 10, doc.previousAutoTable.finalY + 20);

        doc.save('All_Payments.pdf');
    };



    // Function to download a specific building's data as Excel
    const downloadBuildingExcel = (buildingName) => {
        const totalAmount = filteredGroupedData[buildingName].totalAmount;

        const ws = XLSX.utils.json_to_sheet(
            filteredGroupedData[buildingName].payments.map(pay => ({
                Building: buildingName,
                Tenant: `${pay.UserName} ${pay.UserSurname}`,
                ApartmentNumber: pay.ApartmentNumber,
                PaymentDate: formatDate(pay.PaymentDate),
                Amount: pay.AmountPaid,
                PaymentMethod: pay.PaymentMethod || 'N/A',
                MonthYear: pay.MonthYear || 'N/A'
            }))
        );

        // Add a row for total amount
        XLSX.utils.sheet_add_aoa(ws, [['', '', '', 'Total:', totalAmount]], { origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `${buildingName} Payments`);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${buildingName}_Payments.xlsx`);
    };


    // Function to download a specific building's data as PDF
    const downloadBuildingPDF = (buildingName) => {
        const doc = new jsPDF();
        doc.text(buildingName, 10, 10);

        const payments = filteredGroupedData[buildingName].payments.map(pay => [
            `${pay.UserName} ${pay.UserSurname}`,
            pay.ApartmentNumber,
            formatDate(pay.PaymentDate),
            `R${pay.AmountPaid}`,
            pay.PaymentMethod || 'N/A',
            pay.MonthYear || 'N/A'
        ]);

        autoTable(doc, {
            head: [['Tenant', 'Apartment Number', 'Payment Date', 'Amount', 'Payment Method', 'Month']],
            body: payments,
            startY: 20
        });

        // Add total amount for the building
        doc.text(`Total: R${filteredGroupedData[buildingName].totalAmount}`, 10, doc.previousAutoTable.finalY + 10);

        doc.save(`${buildingName}_Payments.pdf`);
    };


    // return (
    //     <>
    //         <div className="husky-container">
    //             <h2 className="husky-title">Statement by Building</h2>
    //             <h3 className="husky-subtitle">Total Amount Paid Across All Buildings: R{totalAmountAllBuildings.toFixed(2)}</h3>

    //             {/* Search input field */}
    //             <div className="husky-search">
    //                 <label htmlFor="search">Search: </label>
    //                 <input
    //                     type="text"
    //                     id="search"
    //                     value={searchTerm}
    //                     onChange={(e) => setSearchTerm(e.target.value)}
    //                     placeholder="Enter search term"
    //                 />
    //             </div>

    //             {/* Global Download Buttons */}
    //             <div className="husky-button-group">
    //                 <button onClick={downloadAllExcel}>Download All Data as Excel</button>
    //                 <button onClick={downloadAllPDF}>Download All Data as PDF</button>
    //             </div>

    //             <div className="husky-container">
    //                 {error && <p className="husky-error">{error}</p>}

    //                 {/* Display buildings matching the search term */}
    //                 {Object.keys(filteredGroupedData).length > 0 ? (
    //                     Object.keys(filteredGroupedData).map(buildingName => (
    //                         <div key={buildingName} className="husky-building">
    //                             <h3>{buildingName}</h3>
    //                             <p>Total Amount Paid: R{filteredGroupedData[buildingName].totalAmount.toFixed(2)}</p>

    //                             {/* Download buttons */}
    //                             <div className="husky-button-group">
    //                                 <button onClick={() => downloadBuildingExcel(buildingName)}>
    //                                     Download {buildingName} Excel
    //                                 </button>
    //                                 <button onClick={() => downloadBuildingPDF(buildingName)}>
    //                                     Download {buildingName} PDF
    //                                 </button>
    //                             </div>

    //                             <table className="husky-table">
    //                                 <thead>
    //                                     <tr>
    //                                         <th>Tenant</th>
    //                                         <th>Apartment Number</th>
    //                                         <th>Payment Date</th>
    //                                         <th>Amount</th>
    //                                         <th>Payment Method</th>
    //                                         <th>Month</th>
    //                                     </tr>
    //                                 </thead>
    //                                 <tbody>
    //                                     {filteredGroupedData[buildingName].payments.map((pay, index) => (
    //                                         <tr key={index}>
    //                                             <td>{pay.UserName} {pay.UserSurname}</td>
    //                                             <td>{pay.ApartmentNumber}</td>
    //                                             <td className="date">{formatDate(pay.PaymentDate)}</td>
    //                                             <td className="amount">R{pay.AmountPaid}</td>
    //                                             <td>{pay.PaymentMethod || 'N/A'}</td>
    //                                             <td>{pay.MonthYear || 'N/A'}</td>
    //                                         </tr>
    //                                     ))}
    //                                 </tbody>
    //                             </table>
    //                         </div>
    //                     ))
    //                 ) : (
    //                     <p>No records found matching the search criteria.</p>
    //                 )}
    //             </div>


    //         </div>
    //     </>
    // );
    return (
        <>
            <div className="husky-container">
                <h2 className="husky-title">Statement by Building</h2>
                <h3 className="husky-subtitle">Total Amount Paid Across All Buildings: R{totalAmountAllBuildings.toFixed(2)}</h3>

                <div className="husky-search">
                    <label htmlFor="search">Search: </label>
                    <input
                        type="text"
                        id="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Enter search term"
                    />
                </div>

                <div className="husky-button-group">
                    <button onClick={downloadAllExcel}>Download All Data as Excel</button>
                    <button onClick={downloadAllPDF}>Download All Data as PDF</button>
                </div>

                {error && <p className="husky-error">{error}</p>}

                {Object.keys(filteredGroupedData).length > 0 ? (
                    Object.keys(filteredGroupedData).map(buildingName => (
                        <div key={buildingName} className="husky-building">
                            <h3>{buildingName}</h3>
                            <p>Total Amount Paid: R{filteredGroupedData[buildingName].totalAmount.toFixed(2)}</p>

                            <div className="husky-button-group">
                                <button onClick={() => downloadBuildingExcel(buildingName)}>Download {buildingName} Excel</button>
                                <button onClick={() => downloadBuildingPDF(buildingName)}>Download {buildingName} PDF</button>
                            </div>

                            <table className="husky-table">
                                <thead>
                                    <tr>
                                        <th>Tenant</th>
                                        <th>Apartment Number</th>
                                        <th>Payment Date</th>
                                        <th>Amount</th>
                                        <th>Payment Method</th>
                                        <th>Month</th>
                                    </tr>
                                </thead>
                                {/* <tbody>
                                    {filteredGroupedData[buildingName].payments
                                        .sort((a, b) => new Date(a.PaymentDate) - new Date(b.PaymentDate))
                                        .map((pay, index, arr) => {
                                            const previousPay = arr[index - 1];
                                            const isNewMonth = previousPay && new Date(pay.PaymentDate).getMonth() !== new Date(previousPay.PaymentDate).getMonth();
                                            const isNewYear = previousPay && new Date(pay.PaymentDate).getFullYear() !== new Date(previousPay.PaymentDate).getFullYear();
                                            const monthYear = new Date(pay.PaymentDate).toLocaleString('default', { month: 'long', year: 'numeric' });
                                            return (
                                                <>
                                                    {(isNewMonth || isNewYear) && (
                                                        <tr className="month-year-header">
                                                            <td colSpan="6" style={{ marginLeft: 'auto', marginRight: 'auto' }}>{monthYear}</td>
                                                        </tr>
                                                    )}
                                                    <tr key={index}>
                                                        <td>{pay.UserName} {pay.UserSurname}</td>
                                                        <td>{pay.ApartmentNumber}</td>
                                                        <td className="date">{formatDate(pay.PaymentDate)}</td>
                                                        <td className="amount">R{pay.AmountPaid}</td>
                                                        <td>{pay.PaymentMethod || 'N/A'}</td>
                                                        <td>{pay.MonthYear || 'N/A'}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </tbody> */}
                                <tbody>
                                    {Object.keys(filteredGroupedData).map((buildingName, idx) => (
                                        <React.Fragment key={idx}>
                                            {filteredGroupedData[buildingName].payments
                                                .sort((a, b) => new Date(a.PaymentDate) - new Date(b.PaymentDate))
                                                .map((payment, index, arr) => {
                                                    const isNewMonth = index === 0 || new Date(payment.PaymentDate).getMonth() !== new Date(arr[index - 1].PaymentDate).getMonth();
                                                    const monthYear = isNewMonth ? new Date(payment.PaymentDate).toLocaleString('default', { month: 'long', year: 'numeric' }) : null;

                                                    return (
                                                        <React.Fragment key={payment.PaymentId}>
                                                            {isNewMonth && (
                                                                <tr className="month-year-header" style={{ backgroundColor: "#2f7fb4" }}>
                                                                    <th colSpan="6" style={{ textAlign: 'center' }}>{monthYear}</th>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td>{payment.UserName} {payment.UserSurname}</td>
                                                                <td>{payment.ApartmentNumber}</td>
                                                                <td>{formatDate(payment.PaymentDate)}</td>
                                                                <td>R{payment.AmountPaid.toFixed(2)}</td>
                                                                <td>{payment.PaymentMethod}</td>
                                                                <td>{new Date(payment.PaymentDate).toLocaleString('default', { month: 'short' })}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </React.Fragment>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    ))
                ) : (
                    <p>No records found matching the search criteria.</p>
                )}
            </div>
        </>
    );



}

export default UserPayment;
