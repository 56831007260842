import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import defaults from "./default_room_room.jpg";
import { useAuth } from './AuthProvider';
import Apartments from './Apartments';
import './invoice.css'
import './loaders.css'
import jsPDF from 'jspdf';
import { PDFDocument, rgb } from 'pdf-lib'; // `pdf-lib` for browser-compatible PDF manipulation

import RegistrationModal from './components/ReserveRegistrationModal.js'; // Adjust the path based on your folder structure
//import './bookingForm.css'; 
import logo from './logoblack.png';
function Reserve() {
    const location = useLocation();
    const { userId } = useAuth(); // Get userId from AuthContext
    const [userData, setUserData] = useState(null);
    const [downloadAfterLogin, setDownloadAfterLogin] = useState(false);
    const [clickedAction, setClickedAction] = useState(null); // Track the action button pressed



    const { date, apartment, apartmentandbuilding } = location.state || {}; // Destructure with default empty object to prevent errors
    const [loading, setLoading] = React.useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false); // State to show the registration modal
    const navigate = useNavigate();
    const { isLoggedIn, role } = useAuth();
    const [selectedPlan, setSelectedPayment] = React.useState('');

    let [totalAmount, setTotalAmount] = React.useState(null);
    let [serviceFee, setServiceFee] = React.useState(null);
    let [chosenPlan, setChosenPlan] = React.useState(null);
    let amount = null;
    const [thePrice, setThePrice] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const [isEmergencyChecked, setIsEmergencyChecked] = useState(false);
    const [isHouseRulesChecked, setIsHouseRulesChecked] = useState(false);
    const [isIncidentFormChecked, setIsIncidentFormChecked] = useState(false);
    const [isLeaseClauseChecked, setIsLeaseClauseChecked] = useState(false);

    const [selectedFunding, setSelectedFunding] = useState(''); // New state for funding option
    const [selectedRadioAmount, setSelectedRadioAmount] = useState(null); // State for radio button selection
    const [selectedTransport, setSelectedTransport] = useState(''); // State for transport selection
    const [isAccomodationChecked, setIsAccomodationChecked] = useState(false);
    const [isTransportChecked, setIsTransportChecked] = useState(false);

    useEffect(() => {
        if (isLoggedIn && userId) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    
                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }

                    const data = await response.json();
                    setUserData(data.length ? data[0] : data);
                    console.log("dsdasdasadasd", data)
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
    }, [isLoggedIn, userId]);

    const generatePDF = async () => {
        setClickedAction('downloadPDF'); // Set action as 'downloadPDF'
        if (!isEmergencyChecked || !isHouseRulesChecked || !isIncidentFormChecked || !isLeaseClauseChecked) {
            alert('Please agree to all the terms by checking the boxes.');
            return;
        }
        if (!isLoggedIn) {
            // If the user is not logged in, set the flag and show the registration modal
            setDownloadAfterLogin(true);
            setShowRegistrationModal(true);
            return;
        }
    
        // If the user is logged in, proceed with generating the PDF
        if (userData) {
            try {
                // Fetch the PDF file as an array buffer
                const response = await fetch('https://jaesstudenthomes.co.za/STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf');
                const pdfBytes = await response.arrayBuffer();
    
                // Load PDF using pdf-lib
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const firstPage = pdfDoc.getPages()[0];
    
                // Add user data to the PDF
                firstPage.drawText(`${userData.Name} ${userData.Surname}`, { x: 230, y: 535, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.IdNumber || userData.PassportNumber, { x: 230, y: 510, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(apartmentandbuilding.RoomIdentifier, { x: 180, y: 790, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(selectedTransport === 'Yes' ? 'X' : '', { x: 385, y: 490, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 230, y: 490, size: 12, color: rgb(0, 0, 0) });

                firstPage.drawText(apartmentandbuilding.BuildingLocation || 'N/A', { x: 230, y: 465, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.Email, { x: 265, y: 440, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.PhoneNumber, { x: 255, y: 415, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(new Date().toLocaleDateString('en-GB'), { x: 170, y: 85, size: 12, color: rgb(0, 0, 0) });
    
                // Save the modified PDF to a blob and trigger download
                const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
                const link = document.createElement('a');
                link.href = pdfDataUri;
                link.download = `Booking_Form_${Date.now()}.pdf`;
                document.body.appendChild(link); // Append link to the DOM for Firefox support
                link.click();
                document.body.removeChild(link); // Remove link after download
    
                console.log('PDF successfully created and downloaded.');
            } catch (error) {
                console.error('Error creating PDF:', error.message);
            }
        } else {
            console.error("User data is not available for generating PDF");
        }
    };
    
    
    
    

    const handleSelectedFunding = (e) => {
        const value = e.target.value;
        setSelectedFunding(value);
    
        if (value === 'NSFAS') {
            setSelectedRadioAmount("900");
            setSelectedTransport("Yes");
            setChosenPlan("Plan A");
            setThePrice(900); 
            setTotalAmount(900);
        } else if (value === 'Bursary' || value === 'Government') {
            setSelectedRadioAmount("1850");
            setSelectedTransport(""); // Clear transport selection for user choice
            setChosenPlan("Plan A");
            setThePrice(1850); 
            setTotalAmount(1850);
        } else if (value === 'Self Funded') {
            // Reset selections for Self Funded, allowing choice of any plan and transport option
            setSelectedRadioAmount(null);
            setSelectedTransport('');
            setChosenPlan(null);
            setThePrice(0); 
            setTotalAmount(0);
        }
    };
    
    
    const handleTransportSelection = (e) => {
        const value = e.target.value;
        setSelectedTransport(value);
    
        let baseAmount = selectedRadioAmount ? parseInt(selectedRadioAmount) : 0;
    
        if (selectedFunding === 'Bursary' || selectedFunding === 'Government') {
            if (value === "Yes") {
                setTotalAmount(1850 + 250); // Add R250 if transport is Yes
            } else if (value === "No") {
                setTotalAmount(1850); // No change if transport is No
            } else if (value === "Maybe") {
                setTotalAmount(1850); // Keep total due the same
              //  alert("You will need to pay R450 next year for transport.");
            }
        } else if (selectedFunding === 'Self Funded') {
            if (value === "Yes") {
                setTotalAmount(baseAmount + 250); // Add R250 for transport
            } else if (value === "No") {
                setTotalAmount(baseAmount); // Base amount only
            } else if (value === "Maybe") {
                setTotalAmount(baseAmount); // No additional cost for Maybe
                //alert("You will need to pay R450 next year for transport.");
            }
        }
    };
    
    const handleAmountSelection = (e) => {
        const amount = e.target.value;
        setSelectedRadioAmount(amount);
    
        let planAmount = chosenPlan === "Plan B" ? 0 : 0;
        let transportFee = selectedTransport === "Yes" ? 250 : 0;
    
        const newTotal = parseInt(amount) + planAmount + transportFee;
        setTotalAmount(newTotal);
        setServiceFee(newTotal); // Set serviceFee to match totalAmount
    };
    

    const allCheckboxesChecked = isEmergencyChecked && isHouseRulesChecked && isIncidentFormChecked && isLeaseClauseChecked;
    React.useEffect(() => {
        // Check if all required data is present before trying to access it
        if (!date || !apartment || !apartmentandbuilding) {
            console.error("Missing necessary data from location.state", date,apartment,apartmentandbuilding);
            setLoading(false); // Set loading false to show error or missing data message
            return; // Prevent further execution
        }
        setLoading(false);
        window.scrollTo(0, 0); // Data is present, so you can turn off loading
    }, [date, apartment, apartmentandbuilding]); // Ensure useEffect runs when any of these dependencies update

    const handlePayment2 = async () => {
        try {
            const amountInCents = Math.round(parseFloat(totalAmount) * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/Payment?status=success&user=50`,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel'
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (error) {
            console.error('Payment error:', error);
        }
    };
    // Function to handle payment
    const handlePayment = () => {
        setClickedAction('pay'); // Set action as 'pay'
        console.log("handlePayment triggered");
        
        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }
        if (!selectedRadioAmount) {
            alert('Please select an amount.');
            return;
        }
        if (!selectedFunding) {
            alert('Please select a funding option.');
            return;
        }
        if (!allCheckboxesChecked) {
            alert('Please agree to all the terms by checking the boxes.');
            return;
        }
    
        if (!isLoggedIn) {
            console.log("User not logged in, opening registration modal");
            setShowRegistrationModal(true);
            return;
        }
    
        // Proceed with payment if all checks are passed
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            console.log("Navigating to Reservepayment with the following state data:");
            console.log("Date:", date);
            console.log("Apartment:", apartment);
            console.log("Apartment and Building ID:", apartmentandbuilding.BuildingId);
            console.log("Total Amount:", totalAmount);
            console.log("Service Fee:", serviceFee);
            console.log("Chosen Plan:", chosenPlan);
            console.log("selectedTransport:", selectedTransport);
            console.log("Next Payment Date:", nextPaymentDate);
            
            navigate('/ReserveYocoPayment', { 
                state: { 
                    date, 
                    apartment, 
                    apartmentandbuilding: apartmentandbuilding.BuildingId, 
                    totalAmount, 
                    serviceFee, 
                    chosenPlan, 
                    nextPaymentDate ,
                    selectedTransport,
                    selectedFunding,
                    Email: userData.Email
                } 
            });
              }, 5000);
    };
    

    
  // Update handleSelectedPlan to allow plan selection for Self Funded
const handleSelectedPlan = (e) => {
    const plan = e.target.value;
    setChosenPlan(plan);

    let planAmount = 0;
    if (plan === 'Plan A') {
        planAmount = 0; // No additional cost for Plan A
    } else if (plan === 'Plan B') {
        planAmount = 0; // Example cost for Plan B
    }

    let baseAmount = selectedRadioAmount ? parseInt(selectedRadioAmount) : 0;
    let transportFee = selectedTransport === "Yes" ? 250 : 0;

    // Calculate total based on selected plan, base amount, and transport fee
    setTotalAmount(baseAmount + planAmount + transportFee);
};

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
           <div class="loader">
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
</div>;     </div>// Show loading while checking for data
    }

    if (!date || !apartment || !apartmentandbuilding) {
        return <div>Error: Missing invoice data. Please ensure all data is provided.</div>; // Show error if data is missing
    }
    // Callback function after successful registration
    const handleRegistrationSuccess = () => {
        setShowRegistrationModal(false); // Close the modal
    
        // Debug log to check which action is triggered
        console.log("Registration Success:", clickedAction);
    
        if (downloadAfterLogin || clickedAction === 'downloadPDF') {
            console.log("Downloading PDF after login..."); // Check if this line is reached
            generatePDF(); // Call `generatePDF()` to download the PDF
            setDownloadAfterLogin(false); // Reset the flag
        } else if (clickedAction === 'pay') {
            console.log("Redirecting to YocoPayment...");
            navigate('/ReserveYocoPayment', { state: { /* your existing parameters */ } });
        }
    };
    
    
    
    // Format dates
    const pickedDate = new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }); //current date no picking
    const nextPaymentDate = new Date(date);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);

    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    
    return (
        <>
            <div class="containerinvoice" >
            <div class="booking-form">

<>
    <div class="step2">JAES STUDENT HOMES - GENERAL HOUSE RULES</div>
  
    <p>The following is the general house rules for all properties. Each property has specific added terms that are to be adhered to in addition to the following:</p>


    <ul class='bullet-list'>
        <li>&#8226; Communal areas should be kept clean at all times.</li>
        <li> &#8226; Gates and house entrances should be locked AT ALL TIMES for tenants’ safety (fine of the services deposit is applicable for constant offenders).</li>
        <li>&#8226; Rooms should be kept locked when tenant is not on the premises. Tenant is not permitted to lend his/her keys to any individuals. No sharing of the rooms unless approved of by the lessor/owner.</li>
        <li>&#8226; Every tenant’s privacy should be respected. Noise and music should be kept at a tolerable level for everyone.</li>
        <li>&#8226; No smoking permitted inside the premises (only outside in allocated areas) and no DRUGS in the premises. No parties allowed.</li>
        <li>&#8226; No washing is to be hanged in the bathroom.</li>
        <li>&#8226; No more than 2 visitors per lessee allowed in the premises at a time. Visitors are the tenant’s full responsibility. No visitors are permitted to use additional services.</li>
        <li>&#8226; No visitors allowed before 10am and after 21:00 pm. NO SLEEPOVERS.</li>
        <li>&#8226; Down time after 21:30 pm.</li>
    </ul>

    <div class="step3">Fines (of up to R950) will be charged to tenant account for each rule break. In extreme cases termination of lease will take effect.</div>





    <hr />

    <div class="step">EMERGENCY PROTOCOLS</div>
    <div className='emerg-row'>
        <div>
            <h4>FIRE</h4>
            <p className='emergp'>
                &#8226; CALL FIRE STATION - 10111<br />
                &#8226;  CALL ADMIN - 081 500 6508
            </p>
        </div>
        <div>
            <h4>MEDICAL</h4>
            <p className='emergp'>
                &#8226; CALL ADMIN - 081 500 6508<br />
                &#8226;  CALL NEXT OF KIN<br />
                &#8226;   CALL AMBULANCE - 10177 / 082 911
            </p>
        </div>
    </div>

    <div className='emerg-row'>
        <div>
            <h4>SECURITY</h4>
            <p className='emergp'>
                &#8226; CALL ADMIN - 081 500 6508<br />
                &#8226; CALL SECURITY - 031 717 5000<br />
                &#8226; CALL POLICE - 10111
            </p>
        </div>
        <div>
            <h4>NATURAL DISASTER</h4>
            <p className='emergp'>
                &#8226; CALL ADMIN - 081 500 6508<br />
                &#8226; CALL LANDLORD<br />
                &#8226;   CALL AMBULANCE - 10177 / 082 911
            </p>
        </div>
    </div>

    <div className='emerg-row'>
        <div>
            <h4>OTHER</h4>
            <p className='emergp'>&#8226; PRAY</p>
        </div>
        <div>
        </div>
    </div>

</>
</div>
                <div class="booking-summary">
                <div class="header">
             
                    <img    src={
                                  apartment.ApartmentRoomImage
                                    ? `https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage}`
                                    : defaults
                                }
                        alt="Apartment Image" class="image" />
                        <div class="title">
                            {/* <span class="badge">Male</span> */}
                            <h2>{apartmentandbuilding.BuildingName} <br /> {apartment.ApartmentNumber}</h2>
                        </div>
                    </div>
                    <div class="check-in-out">
                        <div class="check">
                            <p>Check In</p>
                            <strong>{pickedDate}</strong>
                        </div>
                        <div class="check-arrow">
                            <p>&gt;</p>
                        </div>
                        <div class="check">
                            <p>Check Out</p>
                            <strong> Nov 30, 2025</strong>
                        </div>
                    </div>

                    <div class="details">
                        <div class="detail-row">
                            <span class="label">Building:</span>
                            <span class="value">{apartmentandbuilding.BuildingName}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Suburb:</span>
                            <span class="value">{apartmentandbuilding.Suburb}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Apartment Type:</span>
                            <span class="value">{apartment.LivingArrangement}</span>
                        </div>
                        
                    </div>
                    {/* Funding Option Select */}
<div className="detail-row">
    <select
        id="funding-option"
        value={selectedFunding}
        onChange={handleSelectedFunding}
        required
    >
        <option value="">Select Funding Option</option>
        <option value="Self Funded">Self Funded</option>
        <option value="NSFAS">NSFAS</option>
        <option value="Bursary">Bursary</option>
        <option value="Government">Government</option>
    </select>
</div>
<div className="detail-row">
<label className="transport-label">Reserve fee</label>
    </div>
{/* Radio Button Options for Amount */}
<div className="detail-row" style={{ marginBottom: '15px' }}>
    <div>
        <input
            type="radio"
            id="amount900"
            value="900"
            checked={selectedRadioAmount === "900"}
            onChange={handleAmountSelection}
            disabled={selectedFunding === 'Bursary' || selectedFunding === 'Government' || selectedFunding === 'NSFAS'}
            style={{ display: 'none' }} // Hide the radio button
        />
        <label
            htmlFor="amount900"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                marginRight: '10px',
                border: selectedRadioAmount === "900" ? '2px solid #ff5a5f' : '2px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                backgroundColor: selectedRadioAmount === "900" ? '#ff5a5f' : 'transparent',
                color: selectedRadioAmount === "900" ? 'white' : 'black'
            }}
        >
            R900
        </label>

        <input
            type="radio"
            id="amount1200"
            value="1200"
            checked={selectedRadioAmount === "1200"}
            onChange={handleAmountSelection}
            disabled={selectedFunding === 'Bursary' || selectedFunding === 'Government' || selectedFunding === 'NSFAS'}
            style={{ display: 'none' }}
        />
        <label
            htmlFor="amount1200"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                marginRight: '10px',
                border: selectedRadioAmount === "1200" ? '2px solid #ff5a5f' : '2px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                backgroundColor: selectedRadioAmount === "1200" ? '#ff5a5f' : 'transparent',
                color: selectedRadioAmount === "1200" ? 'white' : 'black'
            }}
        >
            R1200
        </label>

        <input
            type="radio"
            id="amount1850"
            value="1850"
            checked={selectedRadioAmount === "1850"}
            onChange={handleAmountSelection}
            disabled={selectedFunding === 'NSFAS'}
            style={{ display: 'none' }}
        />
        <label
            htmlFor="amount1850"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                marginRight: '10px',
                border: selectedRadioAmount === "1850" ? '2px solid #ff5a5f' : '2px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                backgroundColor: selectedRadioAmount === "1850" ? '#ff5a5f' : 'transparent',
                color: selectedRadioAmount === "1850" ? 'white' : 'black'
            }}
        >
            R1850
        </label>
    </div>
</div>

<div className="detail-row">
<div className="amount-message">
        {selectedRadioAmount === "900" && <p> 	⚠️ this option is available until 15 Dec 2024</p>}
        {selectedRadioAmount === "1200" && <p>	⚠️ this option is available until 15 Jan 2025</p>}
        {selectedRadioAmount === "1850" && <p>	⚠️ this option is available until 15 Feb 2025</p>}
    </div>
</div>
{/* Transport Option Radio Buttons */}
{/* Transport Option Radio Buttons */} 
<div className="detail-row">
<label className="transport-label">Transport Options</label>
    </div>
    <div
    className="detail-row"
    style={{
        marginBottom: '15px'
    }}
>
    <div>
        <input
            type="radio"
            id="transportYes"
            value="Yes"
            checked={selectedTransport === "Yes"}
            onChange={handleTransportSelection}
            disabled={selectedFunding === 'NSFAS' && selectedTransport !== 'Yes'}
            style={{
                display: 'none' // Hide the default radio button
            }}
        />
        <label
            htmlFor="transportYes"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                marginRight: '10px',
                border: selectedTransport === "Yes" ? '2px solid #ff5a5f' : '2px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                backgroundColor: selectedTransport === "Yes" ? '#ff5a5f' : 'transparent',
                color: selectedTransport === "Yes" ? 'white' : 'black'
            }}
        >
            Yes, R250 (<span style={{ textDecoration: "line-through" }}>R450</span>)
        </label>

        <input
            type="radio"
            id="transportMaybe"
            value="Maybe"
            checked={selectedTransport === "Maybe"}
            onChange={handleTransportSelection}
            disabled={selectedFunding === 'NSFAS'}
            style={{
                display: 'none'
            }}
        />
        <label
            htmlFor="transportMaybe"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                marginRight: '10px',
                border: selectedTransport === "Maybe" ? '2px solid #ff5a5f' : '2px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                backgroundColor: selectedTransport === "Maybe" ? '#ff5a5f' : 'transparent',
                color: selectedTransport === "Maybe" ? 'white' : 'black'
            }}
        >
            Maybe
        </label>

        <input
            type="radio"
            id="transportNo"
            value="No"
            checked={selectedTransport === "No"}
            onChange={handleTransportSelection}
            disabled={selectedFunding === 'NSFAS'}
            style={{
                display: 'none'
            }}
        />
        <label
            htmlFor="transportNo"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                marginRight: '10px',
                border: selectedTransport === "No" ? '2px solid #ff5a5f' : '2px solid #ddd',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                backgroundColor: selectedTransport === "No" ? '#ff5a5f' : 'transparent',
                color: selectedTransport === "No" ? 'white' : 'black'
            }}
        >
            No
        </label>

        <div
            className="amount-message"
            style={{
                marginTop: '10px',
                textAlign: 'left'
            }}
        >
            {selectedTransport === "Yes" && <p style={{ textAlign: 'left' }}>😄 You have saved R200</p>}
            {selectedTransport === "Maybe" && <p style={{ textAlign: 'left' }}>⚠️ You will pay R450 in 2025</p>}
            {selectedTransport === "No" && <p style={{ textAlign: 'left' }}>⚠️ You will pay R450 in 2025</p>}
        </div>
    </div>
</div>







{/* Payment Plan Selection */}
{/* Payment Plan Selection */}
<select
    name='selectedPlan'
    onChange={handleSelectedPlan}
    value={chosenPlan || ''}
    disabled={selectedFunding === 'NSFAS' || selectedFunding === 'Bursary' || selectedFunding === 'Government'}
>
    <option value=''>Select Payment Plan</option>
    <option value="Plan A">Plan A</option>
    {selectedFunding === 'Self Funded' && <option value="Plan B">Plan B</option>}
</select>



<div class="cost">
    <p> </p>
   
    <p><span style={{ color: 'green' }}> </span></p>
    <div class="cost-row">
        <span class="label">Total Due:</span>
        <span class="value">R {totalAmount !== null ? totalAmount : '0.00'}</span>
    </div>
    <div class="checkbox-section">
        <div class="checkbox-item">
            <input type="checkbox" id="emergency-protocol" name="emergency-protocol" checked={isEmergencyChecked} onChange={() => setIsEmergencyChecked(!isEmergencyChecked)} />
            <label htmlFor="emergency-protocol">Emergency Protocol</label>
        </div>
        <div class="checkbox-item">
            <input type="checkbox" id="house-rules" name="house-rules" checked={isHouseRulesChecked} onChange={() => setIsHouseRulesChecked(!isHouseRulesChecked)} />
            <label htmlFor="house-rules">House Rules</label>
        </div>
        <div class="checkbox-item">
            <input type="checkbox" id="incident-form" name="incident-form" checked={isIncidentFormChecked} onChange={() => setIsIncidentFormChecked(!isIncidentFormChecked)} />
            <label htmlFor="incident-form">Incident Form</label>
        </div>
        <div class="checkbox-item">
            <input type="checkbox" id="lease-clause" name="lease-clause" checked={isLeaseClauseChecked} onChange={() => setIsLeaseClauseChecked(!isLeaseClauseChecked)} />
            <label htmlFor="lease-clause">Lease Clause</label>
        </div>
        {/* <ul class='bullet-list'>
            <li>&#8226; I confirm that I have read, consent, and agree to Jaes Homes' Emergency Protocol, House Rules, Incident Form, and Lease Clause (including the processing and disclosing of my personal data), and I am of legal age. I understand that I can update my preferences or submit incident reports at any time. For more information about Jaes Homes.</li>
        </ul> */}
    </div>
</div>

<div class="due-today">
    <button 
        onClick={handlePayment} 
        disabled={loading} 
        style={{
            fontSize: '19px',
            padding: '10px 20px',
            border: '2px solid #ffffff',
            borderRadius: '6px',
            cursor: loading ? 'not-allowed' : 'pointer',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            marginBottom: '15px',
        }}
    >
        {loading ? 'Processing...' : `Pay R${totalAmount !== null ? totalAmount : '0.00'}`}
    </button>

    <button 
        onClick={generatePDF} 
        style={{
            fontSize: '19px',
            padding: '10px 20px',
            border: '2px solid #ffffff',
            borderRadius: '6px',
            cursor: 'pointer',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
    >
        Pay via EFT
    </button>
</div>


                </div>
                {/* Render the registration modal */}
                {showRegistrationModal && (
                <RegistrationModal
                    // Pass down clickedAction
                    clickedAction={clickedAction}
                    onSuccess={handleRegistrationSuccess}
                    onClose={() => setShowRegistrationModal(false)}
                    date={date}
                    apartment={apartment}
                    apartmentandbuilding={apartmentandbuilding}
                    totalAmount={totalAmount}
                    serviceFee={serviceFee}
                    chosenPlan={chosenPlan}
                    nextPaymentDate={nextPaymentDate}
                    selectedTransport={selectedTransport}
                    selectedFunding={selectedFunding}
                />
            )}
            </div>
     

        </>
    );
}

export default Reserve;
