import { useEffect, useRef } from 'react';
import L from 'leaflet';
import walking from "./icons8-walking.gif";
import bus from "./icons8-bus.gif";
import car from "./icons8-car.gif";
import starIcon from "./icons8-star-48.png";
import pin from "./icons8-place-marker.gif";

import './LeafletMap.css'; // Import custom CSS for additional styling

// Define the custom icon for university markers
const customUniversityIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/3311/3311565.png",
  iconSize: [42, 42],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

// Hardcoded university data with coordinates
const universities = [
  {
    name: 'UKZN - Howard College',
    latitude: -29.8670357562884,
    longitude: 30.979214429421944
  },
  
  {
    name: 'UKZN Medical School',
    latitude: -29.88111287258216, 
    longitude: 30.990163472018164
  },
  {
    name: 'Coastal College',
    latitude: -29.874163566476255,
    longitude: 30.991915552325853
  }, 
  {
    name: 'Umbilo Technical College',
    latitude: -29.876133578950917, 
    longitude: 30.99118913112159
  },
  {
    name: 'UKZN - Westville Campus',
    latitude: -29.817108,
    longitude: 30.942235
  }
];


function LeafletMap({ apartments }) {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  useEffect(() => {
    if (mapInstance.current) return;
    if (mapRef.current) {
      mapInstance.current = L.map(mapRef.current).setView([-29.8587, 31.0218], 12);

      L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGF3YW5kYS1kYXNoMTIzIiwiYSI6ImNtMjhxcTd4MjBtaXYyanNkeWQ4eHBvOXMifQ.e1VwrG0sIh6cHzZ4R49oTA', {
        attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>',
        tileSize: 512,
        zoomOffset: -1
      }).addTo(mapInstance.current);

      const bounds = [];

      // Filter out the building with BuildingId 49
      const filteredApartments = apartments.filter(apartment => apartment.BuildingId !== 49);

      // Add markers for each apartment
      filteredApartments.forEach(apartment => {
        if (apartment.Latitude && apartment.Longitude && apartment.Best_Price) {
          const priceIcon = L.divIcon({
            className: 'price-icon',
            html: `
              <div 
                style="
                  background-color: #ff5a5f; 
                  color: white; 
                  font-size: 11px; 
                  font-weight: bold; 
                  text-align: center; 
                  border-radius: 15px; 
                  white-space: nowrap;
                  width: 60px;
                  height: 36px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                R${apartment.Best_Price}
              </div>
            `,
            iconSize: [80, 42],
            iconAnchor: [40, 42],
            popupAnchor: [0, -42]
          });

          const popupContent = `
          <div class="mack-popup-card">
            <div class="mack-image-wrapper">
              <img 
                src="https://backendjaes.passiontrials.co.za/src${apartment.BuildingInteriorImage1}" 
                alt="${apartment.BuildingName}" 
                class="mack-popup-image" 
              />
              <div class="mack-rating-overlay">
                <span class="mack-rating">
                  <img src="${starIcon}" height="18" 
                  width="18" alt="star" class="mack-star-icon" />
                  5.0 (1.2k)
                </span>
              </div>
            </div>
            <div class="volvo-popup-content">
              <h4 class="volvo-popup-title">${apartment.BuildingName}</h4>
              <div class="scania-distance-from-center">
                <img src="${pin}" height="18" 
                width="18" />  0.6 mi from City Center
              </div>
              <div class="freightliner-distance-wrapper">
                <span class="freightliner-distance-item">
                  <img src="${walking}" height="18" 
                  width="18" />
                  <span>25m</span>
                </span>
                <span class="mack-dot-separator">.</span>
                <span class="freightliner-distance-item">
                  <img src="${bus}" height="18" 
                  width="18" />
                  <span>19m</span>
                </span>
                <span class="mack-dot-separator">.</span>
                <span class="freightliner-distance-item">
                   <img src="${car}" height="19" 
                  width="19" />
                  <span>12m</span>
                </span>
              </div>
              <p class="volvo-popup-price">From: <strong>R${apartment.Best_Price}/pm</strong></p>
            </div>
          </div>
        `;

          const marker = L.marker([parseFloat(apartment.Latitude), parseFloat(apartment.Longitude)], { icon: priceIcon })
            .addTo(mapInstance.current)
            .bindPopup(popupContent, { className: 'custom-popup' });

          bounds.push([parseFloat(apartment.Latitude), parseFloat(apartment.Longitude)]);
        }
      });

      universities.forEach(university => {
        const marker = L.marker([university.latitude, university.longitude], { icon: customUniversityIcon })
          .addTo(mapInstance.current)
          .bindPopup(`<h4>${university.name}</h4>`);

        bounds.push([university.latitude, university.longitude]);
      });

      if (bounds.length > 0) {
        mapInstance.current.fitBounds(bounds, { padding: [50, 50], maxZoom: 19 });
      }
    }
  }, [apartments]);

  return <div ref={mapRef} style={{ width: '100%', height: '400px', borderRadius:'20px' }} />;
}

export default LeafletMap;

