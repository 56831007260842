import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';

function SingleUserPayment() {
    const [walletData, setWalletData] = useState([]);
    const [transportData, setTransportData] = useState([]);
    const [reserveData, setReserveData] = useState([]);
    const { userId } = useAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId) {
            fetch(`https://backendjaes.passiontrials.co.za/user-info/${userId}`, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Failed to fetch user payment data');
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data.wallet && data.wallet.length > 0) {
                        setWalletData(
                            data.wallet.map((item) => ({
                                Date: item.CreatedAt || item.PaymentDate,
                                Service: item.Notes || 'Wallet Service',
                                Amount: item.AmountPaid,
                                Month: item.MonthYear || 'N/A',
                            }))
                        );
                    }
                    if (data.transport && data.transport.length > 0) {
                        setTransportData(
                            data.transport.map((item) => ({
                                Date: item.CreatedAt,
                                Service: item.Notes || 'Transport Payment',
                                Amount: item.Price,
                                Month: item.Month || 'N/A',
                            }))
                        );
                    }
                    if (data.reserve && data.reserve.length > 0) {
                        setReserveData(
                            data.reserve.map((item) => ({
                                Date: item.CreatedAt,
                                Service: item.Notes || 'Reservation Payment',
                                Amount: item.RentAmount,
                                Month: 'N/A',
                            }))
                        );
                    }
                })
                .catch((err) => {
                    console.error('Error:', err);
                    setError(err.message);
                });
        }
    }, [userId]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <div className="payment-container">
            <h2>Statement</h2>

            {error && <p className="error">{error}</p>}

            {/* Wallet Section */}
            {walletData.length > 0 && (
                <div className="wallet-section">
                    <h3>RENTAL</h3>
                    <table className="payment-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Service</th>
                                <th>Amount</th>
                                <th>Month</th>
                            </tr>
                        </thead>
                        <tbody>
                            {walletData.map((pay, index) => (
                                <tr key={index}>
                                    <td>{formatDate(pay.Date)}</td>
                                    <td>{pay.Service}</td>
                                    <td>R{pay.Amount}</td>
                                    <td>{pay.Month}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Transport Section */}
            {transportData.length > 0 && (
                <div className="transport-section">
                    <h3>TRANSPORT</h3>
                    <table className="payment-table">
                        <thead>
                            <tr>
                                <th>Date of the Record</th>
                                <th>Service</th>
                                <th>Amount</th>
                                <th>Month of the Record</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transportData.map((pay, index) => (
                                <tr key={index}>
                                    <td>{formatDate(pay.Date)}</td>
                                    <td>{pay.Service}</td>
                                    <td>R{pay.Amount}</td>
                                    <td>{pay.Month}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Reserve Section */}
            {reserveData.length > 0 && (
                <div className="reserve-section">
                    <h3>RESERVATION</h3>
                    <table className="payment-table">
                        <thead>
                            <tr>
                                <th>Date of the Record</th>
                                <th>Service</th>
                                <th>Amount</th>
                                <th>Month of the Record</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reserveData.map((pay, index) => (
                                <tr key={index}>
                                    <td>{formatDate(pay.Date)}</td>
                                    <td>{pay.Service}</td>
                                    <td>R{pay.Amount}</td>
                                    <td>{pay.Month}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <style>{`
                .payment-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    text-align: center;
                }

                h2 {
                    margin-bottom: 30px;
                }

                h3 {
                    margin: 40px 0 20px;
                }

                .error {
                    color: red;
                    font-weight: bold;
                }

                .payment-table {
                    width: 80%;
                    border-collapse: collapse;
                    margin: 0 auto;
                }

                .payment-table th,
                .payment-table td {
                    border: 1px solid #ddd;
                    padding: 12px;
                    text-align: left;
                }

                .payment-table th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                    font-size: 18px;
                }

                .payment-table td {
                    font-size: 16px;
                }

                .payment-table tr:nth-child(even) {
                    background-color: #f9f9f9;
                }

                .payment-table tr:hover {
                    background-color: #f1f1f1;
                }

                .wallet-section,
                .transport-section,
                .reserve-section {
                    width: 100%;
                    margin-bottom: 40px;
                }
            `}</style>
        </div>
    );
}

export default SingleUserPayment;
