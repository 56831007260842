// import React, { useState, useEffect } from 'react';
// import { useAuth } from './AuthProvider';
// import './userPayment.css';
// import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf'; // For PDF
// import autoTable from 'jspdf-autotable'; // For PDF Table
// import * as XLSX from 'xlsx'; // For Excel
// import { saveAs } from 'file-saver'; // For saving files

// function RegularAdminPay() {
//   const [payment, setPayment] = useState([]);
//   const [info, setInfo] = useState([]);
//   const [groupedData, setGroupedData] = useState({});
//   const { userId } = useAuth();
//   const [error, setError] = useState(null);
//   const [totalAmountAllBuildings, setTotalAmountAllBuildings] = useState(0);
//   const [landLord, setLandLord] = useState();
//   const [owner, setOwner] = useState();
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate();

//   const fetchGroupedWalletData = () => {
//     if (userId) {
//       fetch(`https://backendjaes.passiontrials.co.za/groupedWalletByBuilding`, {
//         headers: { 'Content-Type': 'application/json' }
//       })
//         .then(res => {
//           if (!res.ok) { throw new Error('Failed to get wallet data'); }
//           return res.json();
//         })
//         .then(data => {
//           if (owner?.AdminEmail) {
//             const filtered = data.filter(payment => payment.CreatedBy === owner.AdminEmail);
//             const grouped = filtered.reduce((acc, current) => {
//               const buildingName = current.BuildingName;
//               if (!acc[buildingName]) {
//                 acc[buildingName] = {
//                   totalAmount: 0,
//                   payments: [],
//                 };
//               }
//               acc[buildingName].totalAmount += current.AmountPaid;
//               acc[buildingName].payments.push(current);
//               return acc;
//             }, {});

//             const totalAllBuildings = Object.values(grouped).reduce(
//               (total, building) => total + building.totalAmount,
//               0
//             );

//             setTotalAmountAllBuildings(totalAllBuildings);
//             setGroupedData(grouped);
//           }
//         })
//         .catch(err => {
//           console.error('Error:', err);
//           setError('Failed to fetch payment data');
//         });
//     }
//   };

//   // Fetch data when the component mounts and refresh every 5 minutes
//   useEffect(() => {
//     fetchGroupedWalletData(); // Initial fetch

//     const intervalId = setInterval(fetchGroupedWalletData, 300000); // Fetch every 5 minutes (300000 ms)

//     return () => clearInterval(intervalId); // Cleanup interval on component unmount
//   }, [userId, owner]);

//   useEffect(() => {
//     if (userId) {
//       fetch('https://backendjaes.passiontrials.co.za/admin_tb')
//         .then(res => res.json())
//         .then(data => setInfo(data))
//         .catch(err => console.log('An error occurred:', err));
//     }
//   }, [userId]);

//   useEffect(() => {
//     if (userId && info.length > 0) {
//       const filtered = info.filter((admin) => admin.AdminId == userId);
//       setOwner(filtered[0]);
//     }
//   }, [userId, info]);

//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: 'long', day: 'numeric' };
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-GB', options);
//   };

//   const filterPayments = (payments) => {
//     return payments.filter((payment) => {
//       const searchTermLower = searchTerm.toLowerCase();
//       const paymentDate = formatDate(payment.PaymentDate).toLowerCase();
//       const matchesBuildingName = payment.BuildingName.toLowerCase().includes(searchTermLower);
//       const matchesUserName = payment.UserName.toLowerCase().includes(searchTermLower);
//       const matchesUserSurname = payment.UserSurname.toLowerCase().includes(searchTermLower);
//       const matchesApartmentNumber = payment.ApartmentNumber.toLowerCase().includes(searchTermLower);
//       const matchesDate = paymentDate.includes(searchTermLower);

//       return matchesBuildingName || matchesUserName || matchesUserSurname || matchesApartmentNumber || matchesDate;
//     });
//   };

//   const filteredGroupedData = Object.keys(groupedData).reduce((acc, buildingName) => {
//     const filteredPayments = filterPayments(groupedData[buildingName].payments);
//     if (filteredPayments.length > 0) {
//       acc[buildingName] = {
//         ...groupedData[buildingName],
//         payments: filteredPayments
//       };
//     }
//     return acc;
//   }, {});

//   const downloadAllExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(
//       Object.keys(filteredGroupedData).flatMap(buildingName =>
//         filteredGroupedData[buildingName].payments.map(pay => ({
//           Building: buildingName,
//           Tenant: `${pay.UserName} ${pay.UserSurname}`,
//           ApartmentNumber: pay.ApartmentNumber,
//           PaymentDate: formatDate(pay.PaymentDate),
//           Amount: pay.AmountPaid,
//           PaymentMethod: pay.PaymentMethod || 'N/A',
//           MonthYear: pay.MonthYear || 'N/A'
//         }))
//       )
//     );

//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'All Payments');
//     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
//     saveAs(blob, 'All_Payments.xlsx');
//   };

//   const downloadAllPDF = () => {
//     const doc = new jsPDF();
//     Object.keys(filteredGroupedData).forEach((buildingName, index) => {
//       if (index !== 0) doc.addPage();
//       doc.text(buildingName, 10, 10);

//       const payments = filteredGroupedData[buildingName].payments.map(pay => [
//         `${pay.UserName} ${pay.UserSurname}`,
//         pay.ApartmentNumber,
//         formatDate(pay.PaymentDate),
//         `R${pay.AmountPaid}`,
//         pay.PaymentMethod || 'N/A',
//         pay.MonthYear || 'N/A'
//       ]);

//       autoTable(doc, {
//         head: [['Tenant', 'Apartment Number', 'Payment Date', 'Amount', 'Payment Method', 'Month']],
//         body: payments,
//         startY: 20
//       });
//     });

//     doc.save('All_Payments.pdf');
//   };

//   const downloadBuildingExcel = (buildingName) => {
//     const ws = XLSX.utils.json_to_sheet([
//       ...filteredGroupedData[buildingName].payments.map(pay => ({
//         Building: buildingName,
//         Tenant: `${pay.UserName} ${pay.UserSurname}`,
//         ApartmentNumber: pay.ApartmentNumber,
//         PaymentDate: formatDate(pay.PaymentDate),
//         Amount: pay.AmountPaid,
//         PaymentMethod: pay.PaymentMethod || 'N/A',
//         MonthYear: pay.MonthYear || 'N/A'
//       })),
//       {},
//       { Building: buildingName, Tenant: 'Total', Amount: filteredGroupedData[buildingName].totalAmount }
//     ]);

//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, `${buildingName} Payments`);
//     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
//     saveAs(blob, `${buildingName}_Payments.xlsx`);
//   };

//   const downloadBuildingPDF = (buildingName) => {
//     const doc = new jsPDF();
//     doc.text(buildingName, 10, 10);

//     const payments = filteredGroupedData[buildingName].payments.map(pay => [
//       `${pay.UserName} ${pay.UserSurname}`,
//       pay.ApartmentNumber,
//       formatDate(pay.PaymentDate),
//       `R${pay.AmountPaid}`,
//       pay.PaymentMethod || 'N/A',
//       pay.MonthYear || 'N/A'
//     ]);

//     payments.push(['', '', 'Total', `R${filteredGroupedData[buildingName].totalAmount}`, '', '']);

//     autoTable(doc, {
//       head: [['Tenant', 'Apartment Number', 'Payment Date', 'Amount', 'Payment Method', 'Month']],
//       body: payments,
//       startY: 20
//     });

//     doc.save(`${buildingName}_Payments.pdf`);
//   };

//   return (
//     <>
//       <h2>Statement by Building</h2>
//       <h3>Total Amount Paid Across All Buildings: R{totalAmountAllBuildings}</h3>

//       <div style={{ marginBottom: '20px' }}>
//         <label htmlFor="search">Search by Building Name, User Name, User Surname, Apartment Number, or Date: </label>
//         <input
//           type="text"
//           id="search"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Enter search term"
//           style={{ padding: '10px', width: '300px' }}
//         />
//       </div>

//       <div style={{ marginBottom: '20px' }}>
//         <button onClick={downloadAllExcel}>Download All Data as Excel</button>
//         <button onClick={downloadAllPDF}>Download All Data as PDF</button>
//       </div>

//       <div className="container" style={{ marginTop: '19px' }}>
//         {error && <p className='error'>{error}</p>}

//         {Object.keys(filteredGroupedData).length > 0 ? (
//           Object.keys(filteredGroupedData).map(buildingName => (
//             <div key={buildingName}>
//               <h3>{buildingName}</h3>
//               <p>Total Amount Paid: R{filteredGroupedData[buildingName].totalAmount}</p>

//               <button onClick={() => downloadBuildingExcel(buildingName)}>Download {buildingName} Excel</button>
//               <button onClick={() => downloadBuildingPDF(buildingName)}>Download {buildingName} PDF</button>

//               <table className='payment-table'>
//                 <thead>
//                   <tr>
//                     <th>Tenant</th>
//                     <th>Apartment Number</th>
//                     <th>Payment Date</th>
//                     <th>Amount</th>
//                     <th>Payment Method</th>
//                     <th>Month</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {filteredGroupedData[buildingName].payments.map((pay, index) => (
//                     <tr key={index}>
//                       <td>{pay.UserName} {pay.UserSurname}</td>
//                       <td>{pay.ApartmentNumber}</td>
//                       <td className='date'>{formatDate(pay.PaymentDate)}</td>
//                       <td className='amount'>R{pay.AmountPaid}</td>
//                       <td>{pay.PaymentMethod || 'N/A'}</td>
//                       <td>{pay.MonthYear || 'N/A'}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ))
//         ) : (
//           <p>No records found matching the search criteria.</p>
//         )}
//       </div>

//       <p>Use Reference: 076BWN-R01
//         <br />
//         Pay into the following account:   <br />
//         JAES Properties   <br />
//         FNB   <br />
//         Savings Account   <br />
//         Acc#: 62793916192
//       </p>
//     </>
//   );
// }

// export default RegularAdminPay;
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import './userPayment.css';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf'; // For PDF
import autoTable from 'jspdf-autotable'; // For PDF Table
import * as XLSX from 'xlsx'; // For Excel
import { saveAs } from 'file-saver'; // For saving files

function RegularAdminPay() {
  const [payment, setPayment] = useState([]);
  const [info, setInfo] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const { userId } = useAuth();
  const [error, setError] = useState(null);
  const [totalAmountAllBuildings, setTotalAmountAllBuildings] = useState(0);
  const [landLord, setLandLord] = useState();
  const [owner, setOwner] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const fetchGroupedWalletData = () => {
    if (userId) {
      fetch(`https://backendjaes.passiontrials.co.za/groupedWalletByBuilding`, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (!res.ok) { throw new Error('Failed to get wallet data'); }
          return res.json();
        })
        .then(data => {
          if (owner?.AdminEmail) {
            const filtered = data.filter(payment => payment.CreatedBy === owner.AdminEmail);
            const grouped = filtered.reduce((acc, current) => {
              const buildingName = current.BuildingName;
              if (!acc[buildingName]) {
                acc[buildingName] = {
                  totalAmount: 0,
                  payments: [],
                };
              }
              acc[buildingName].totalAmount += current.AmountPaid;
              acc[buildingName].payments.push(current);
              return acc;
            }, {});

            const totalAllBuildings = Object.values(grouped).reduce(
              (total, building) => total + building.totalAmount,
              0
            );

            setTotalAmountAllBuildings(totalAllBuildings);
            setGroupedData(grouped);
          }
        })
        .catch(err => {
          console.error('Error:', err);
          setError('Failed to fetch payment data');
        });
    }
  };

  // Fetch data when the component mounts and refresh every 5 minutes
  useEffect(() => {
    fetchGroupedWalletData(); // Initial fetch

    const intervalId = setInterval(fetchGroupedWalletData, 300000); // Fetch every 5 minutes (300000 ms)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [userId, owner]);

  useEffect(() => {
    if (userId) {
      fetch('https://backendjaes.passiontrials.co.za/admin_tb')
        .then(res => res.json())
        .then(data => {
          setInfo(data)
          // console.log('all admins', data);
        })
        .catch(err => console.log('An error occurred:', err));
    }
  }, [userId]);

  useEffect(() => {
    if (userId && info.length > 0) {
      const filtered = info.filter((admin) => admin.AdminId == userId);
      setOwner(filtered[0]);

      // console.log('this is the admin data ', filtered);
    }
  }, [userId, info]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  const filterPayments = (payments) => {
    return payments.filter((payment) => {
      const searchTermLower = searchTerm.toLowerCase();
      const paymentDate = formatDate(payment.PaymentDate).toLowerCase();
      const matchesBuildingName = payment.BuildingName.toLowerCase().includes(searchTermLower);
      const matchesUserName = payment.UserName.toLowerCase().includes(searchTermLower);
      const matchesUserSurname = payment.UserSurname.toLowerCase().includes(searchTermLower);
      const matchesApartmentNumber = payment.ApartmentNumber.toLowerCase().includes(searchTermLower);
      const matchesDate = paymentDate.includes(searchTermLower);

      return matchesBuildingName || matchesUserName || matchesUserSurname || matchesApartmentNumber || matchesDate;
    });
  };

  const filteredGroupedData = Object.keys(groupedData).reduce((acc, buildingName) => {
    const filteredPayments = filterPayments(groupedData[buildingName].payments);
    if (filteredPayments.length > 0) {
      acc[buildingName] = {
        ...groupedData[buildingName],
        payments: filteredPayments
      };
    }
    return acc;
  }, {});

  const downloadAllExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      Object.keys(filteredGroupedData).flatMap(buildingName =>
        filteredGroupedData[buildingName].payments.map(pay => ({
          Building: buildingName,
          Tenant: `${pay.UserName} ${pay.UserSurname}`,
          ApartmentNumber: pay.ApartmentNumber,
          PaymentDate: formatDate(pay.PaymentDate),
          Amount: pay.AmountPaid,
          PaymentMethod: pay.PaymentMethod || 'N/A',
          MonthYear: pay.MonthYear || 'N/A'
        }))
      )
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Payments');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'All_Payments.xlsx');
  };

  const downloadAllPDF = () => {
    const doc = new jsPDF();
    Object.keys(filteredGroupedData).forEach((buildingName, index) => {
      if (index !== 0) doc.addPage();
      doc.text(buildingName, 10, 10);

      const payments = filteredGroupedData[buildingName].payments.map(pay => [
        `${pay.UserName} ${pay.UserSurname}`,
        pay.ApartmentNumber,
        formatDate(pay.PaymentDate),
        `R${pay.AmountPaid}`,
        pay.PaymentMethod || 'N/A',
        pay.MonthYear || 'N/A'
      ]);

      autoTable(doc, {
        head: [['Tenant', 'Apartment Number', 'Payment Date', 'Amount', 'Payment Method', 'Month']],
        body: payments,
        startY: 20
      });
    });

    doc.save('All_Payments.pdf');
  };

  const downloadBuildingExcel = (buildingName) => {
    const ws = XLSX.utils.json_to_sheet([
      ...filteredGroupedData[buildingName].payments.map(pay => ({
        Building: buildingName,
        Tenant: `${pay.UserName} ${pay.UserSurname}`,
        ApartmentNumber: pay.ApartmentNumber,
        PaymentDate: formatDate(pay.PaymentDate),
        Amount: pay.AmountPaid,
        PaymentMethod: pay.PaymentMethod || 'N/A',
        MonthYear: pay.MonthYear || 'N/A'
      })),
      {},
      { Building: buildingName, Tenant: 'Total', Amount: filteredGroupedData[buildingName].totalAmount }
    ]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${buildingName} Payments`);
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${buildingName}_Payments.xlsx`);
  };

  const downloadBuildingPDF = (buildingName) => {
    const doc = new jsPDF();
    doc.text(buildingName, 10, 10);

    const payments = filteredGroupedData[buildingName].payments.map(pay => [
      `${pay.UserName} ${pay.UserSurname}`,
      pay.ApartmentNumber,
      formatDate(pay.PaymentDate),
      `R${pay.AmountPaid}`,
      pay.PaymentMethod || 'N/A',
      pay.MonthYear || 'N/A'
    ]);

    payments.push(['', '', 'Total', `R${filteredGroupedData[buildingName].totalAmount}`, '', '']);

    autoTable(doc, {
      head: [['Tenant', 'Apartment Number', 'Payment Date', 'Amount', 'Payment Method', 'Month']],
      body: payments,
      startY: 20
    });

    doc.save(`${buildingName}_Payments.pdf`);
  };

  return (
    <>
      <div className="husky-container">

        {/* <h2>Statement by Building</h2>
        <h3>Total Amount Paid Across All Buildings: R{totalAmountAllBuildings}</h3> */}
        <h2 className="husky-title">Statement by Building</h2>
        <h3 className="husky-subtitle">Total Amount Paid Across All Buildings: R{totalAmountAllBuildings.toFixed(2)}</h3>


        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="search">Search by Building Name, User Name, User Surname, Apartment Number, or Date: </label>
          <input
            type="text"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Enter search term"
            style={{ padding: '10px', width: '300px' }}
          />
        </div>

        <div className="husky-button-group">
          <button onClick={downloadAllExcel}>Download All Data as Excel</button>
          <button onClick={downloadAllPDF}>Download All Data as PDF</button>
        </div>

        <div className="container" style={{ marginTop: '19px' }}>
          {error && <p className="husky-error">{error}</p>}

          {Object.keys(filteredGroupedData).length > 0 ? (
            Object.keys(filteredGroupedData).map(buildingName => (
              <div key={buildingName} className="husky-building">
                <h3>{buildingName}</h3>
                <p>Total Amount Paid: R{filteredGroupedData[buildingName].totalAmount}</p>
                {/* 
              <button onClick={() => downloadBuildingExcel(buildingName)}>Download {buildingName} Excel</button>
              <button onClick={() => downloadBuildingPDF(buildingName)}>Download {buildingName} PDF</button> */}

                <div className="husky-button-group">
                  <button onClick={() => downloadBuildingExcel(buildingName)}>
                    Download {buildingName} Excel
                  </button>
                  <button onClick={() => downloadBuildingPDF(buildingName)}>
                    Download {buildingName} PDF
                  </button>
                </div>

                <table className='husky-table'>
                  <thead>
                    <tr>
                      <th>Tenant</th>
                      <th>Apartment Number</th>
                      <th>Payment Date</th>
                      <th>Amount</th>
                      <th>Payment Method</th>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredGroupedData[buildingName].payments.map((pay, index) => (
                      <tr key={index}>
                        <td>{pay.UserName} {pay.UserSurname}</td>
                        <td>{pay.ApartmentNumber}</td>
                        <td className='date'>{formatDate(pay.PaymentDate)}</td>
                        <td className='amount'>R{pay.AmountPaid}</td>
                        <td>{pay.PaymentMethod || 'N/A'}</td>
                        <td>{pay.MonthYear || 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))
          ) : (
            <p>No records found matching the search criteria.</p>
          )}
        </div>
        <div className="husky-footer">
          <p>
            <br />
            Pay into the following account:   <br />
            JAES Properties   <br />
            FNB   <br />
            Savings Account   <br />
            Acc#: 62793916192
          </p>
        </div>
      </div>
    </>
  );
}

export default RegularAdminPay;
