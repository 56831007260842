import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'

import { useNavigate } from 'react-router-dom';

const TransportAdminPayment = () => {
    const [payments, setPayments] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState('');
    const navigate = useNavigate();
    const [newPayment, setNewPayment] = useState({
        University: '',
        Building: '',
        PaymentStatus: 'Active',
    });
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state

    const [filters, setFilters] = useState({
        UserName: '',
        Building: '',
        University: '',
        PaymentStatus: '',
    });

    // Remove duplicates based on UserId
    const removeDuplicates = (data) => {
        const uniquePayments = [];
        const seenUserIds = new Set();

        data.forEach((payment) => {
            if (!seenUserIds.has(payment.UserId)) {
                uniquePayments.push(payment);
                seenUserIds.add(payment.UserId);
            }
        });

        return uniquePayments;
    };

    // Fetch users and payments data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [usersResponse, paymentsResponse] = await Promise.all([
                    fetch('https://backendjaes.passiontrials.co.za/get_users'),
                    fetch('https://backendjaes.passiontrials.co.za/get_payments'),
                ]);

                const usersData = await usersResponse.json();
                setUsers(usersData);

                const paymentsData = await paymentsResponse.json();
                const paymentsWithUserDetails = await Promise.all(
                    paymentsData.map(async (payment) => {
                        const userResponse = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${payment.UserId}`);
                        const userData = await userResponse.json();
                        const user = userData[0]; // Assuming one user per ID
                        return {
                            ...payment,
                            UserName: user ? `${user.Name} ${user.Surname}` : 'Unknown User',
                        };
                    })
                );

                const uniquePayments = removeDuplicates(paymentsWithUserDetails);
                setPayments(uniquePayments);
                setFilteredPayments(uniquePayments); // Initialize filtered payments
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
        // Handle adding a new payment
        const handleAddPayment = async () => {
            if (!selectedUserId || !newPayment.University || !newPayment.Building) {
                //alert('Please fill in all fields.');
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Please fill in all fields.",
                    showConfirmButton: false,
                    timer: 5500
                });
                return;
            }
    
            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/save_payment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId: selectedUserId,
                        building: newPayment.Building,
                        price: 250, // Default to 0; you can make this editable later
                        university: newPayment.University,
                        paymentStatus: newPayment.PaymentStatus,
                        transRole: 'usertransport', // Example role; adjust as needed
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Failed to save payment');
                }
    
                const data = await response.json();
                //alert(data.message);
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: data.message,
                    showConfirmButton: false,
                    timer: 5500
                });

                    // Update user role after adding payment
                    await updateUserRoleToReserve(selectedUserId, newPayment.University);

                // Reload payments after adding
                const newPaymentsResponse = await fetch('https://backendjaes.passiontrials.co.za/get_payments');
                const newPaymentsData = await newPaymentsResponse.json();
                setPayments(newPaymentsData);
                setFilteredPayments(newPaymentsData);
                setIsModalOpen(false); // Close modal after adding payment

            } catch (error) {
                console.error('Error adding payment:', error);
                //alert('Failed to add payment');
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Failed to add payment",
                    showConfirmButton: false,
                    timer: 5500
                });
            }
        };

        const updateUserRoleToReserve = async (userId, instituteName) => {
            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/updateUserRoleUniversity', {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId, newRole: 'TransportOnly', instituteName }),
                });
        
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to update user role and institute name: ${errorText}`);
                }
        
                console.log('User role and institute name updated successfully!');
            } catch (error) {
                console.error(`Error updating user role and institute name: ${error.message}`);
            }
        };
        
        
    // Fetch payment data
    useEffect(() => {
        const fetchPaymentsWithUserDetails = async () => {
            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/get_payments');
                const paymentsData = await response.json();

                const paymentsWithUserDetails = await Promise.all(
                    paymentsData.map(async (payment) => {
                        const userResponse = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${payment.UserId}`);
                        const userData = await userResponse.json();
                        const user = userData[0]; // Assuming one user per ID
                        return {
                            ...payment,
                            UserName: user ? `${user.Name} ${user.Surname}` : 'Unknown User',
                        };
                    })
                );

                const uniquePayments = removeDuplicates(paymentsWithUserDetails);

                setPayments(uniquePayments);
                setFilteredPayments(uniquePayments); // Initialize filtered payments
            } catch (error) {
                console.error('Error fetching payments or user details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentsWithUserDetails();
    }, []);

    // Handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));

        setFilteredPayments(
            payments.filter((payment) =>
                Object.keys(filters).every((key) =>
                    payment[key]?.toLowerCase().includes(key === name ? value.toLowerCase() : filters[key].toLowerCase())
                )
            )
        );
    };

    const handleHistory = (pay) => {
        console.log(`/TransportHistory/${pay}`);
        navigate(`/TransportHistory/${pay}`);
    };
    // Handle data updates
    const handleUpdate = (index, field, value) => {
        const updatedPayments = [...filteredPayments];
        updatedPayments[index][field] = value;
        setFilteredPayments(updatedPayments);
    };

    // Handle save button click
    const handleSave = async (payment) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/update_payment', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    PaymentId: payment.PaymentId,
                    Building: payment.Building,
                    Price: payment.Price,
                    University: payment.University,
                    PaymentStatus: payment.PaymentStatus,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save payment details');
            }

            const data = await response.json();
            //alert(data.message);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: data.message,
                showConfirmButton: false,
                timer: 5500
            });
        } catch (error) {
            console.error('Error saving payment details:', error);
            //alert('Failed to save payment details');
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Failed to save payment details",
                showConfirmButton: false,
                timer: 5500
            });
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="transport-admin-payment">
                {/* Add Payment Form */}
                    {/* Button to Open Modal */}
            <button className="open-modal-btn" onClick={() => setIsModalOpen(true)}>
                Add Student 
            </button>
              {/* Modal */}
              {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Add Payment</h2>
                        <label>
                            Select User:
                            <select value={selectedUserId} onChange={(e) => setSelectedUserId(e.target.value)}>
                                <option value="">Select a User</option>
                                {users.map((user) => (
                                    <option key={user.UserId} value={user.UserId}>
                                        {user.Name} {user.Surname}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Select University:
                            <select
                                value={newPayment.University}
                                onChange={(e) => setNewPayment((prev) => ({ ...prev, University: e.target.value }))}
                            >
                                <option value="">Select University</option>
                                
                                <option value="Ukzn Howard">UKZN Howard</option>
                                <option value="UKZN Medical School">UKZN Medical School</option>
                                <option value="Umbilo College">Umbilo College</option>
                                <option value="Coastal College">Coastal College</option>
                                <option value="Ukzn Westville">Ukzn Westville</option>
                            </select>
                        </label>
                        <label>
                            Building Address:
                            <input
                                type="text"
                                value={newPayment.Building}
                                onChange={(e) => setNewPayment((prev) => ({ ...prev, Building: e.target.value }))}
                            />
                        </label>
                        <label>
                            Payment Status:
                            <select
                                value={newPayment.PaymentStatus}
                                onChange={(e) => setNewPayment((prev) => ({ ...prev, PaymentStatus: e.target.value }))}
                            >
                                <option value="Active">Active</option>
                                <option value="Deactivated">Deactivated</option>
                            </select>
                        </label>
                        <div className="modal-actions">
                            <button className="save-btn" onClick={handleAddPayment}>
                                Save Payment
                            </button>
                            <button className="cancel-btn" onClick={() => setIsModalOpen(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <h1>Transport Payments</h1>
            
            <button onClick={() => navigate('/AllTransportHistory')}>All Transport History</button>

            {filteredPayments.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by User Name"
                                    name="UserName"
                                    value={filters.UserName}
                                    onChange={handleFilterChange}
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by Building"
                                    name="Building"
                                    value={filters.Building}
                                    onChange={handleFilterChange}
                                />
                            </th>
                            <th>Price</th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by University"
                                    name="University"
                                    value={filters.University}
                                    onChange={handleFilterChange}
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by Payment Status"
                                    name="PaymentStatus"
                                    value={filters.PaymentStatus}
                                    onChange={handleFilterChange}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPayments.map((payment, index) => (
                            <tr key={index}>
                                <td>{payment.UserName}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={payment.Building}
                                        onChange={(e) => handleUpdate(index, 'Building', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={payment.Price}
                                        onChange={(e) => handleUpdate(index, 'Price', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select
                                        value={payment.University}
                                        onChange={(e) => handleUpdate(index, 'University', e.target.value)}
                                    >
                                <option value="Ukzn Howard">UKZN Howard</option>
                                <option value="UKZN Medical School">UKZN Medical School</option>
                                <option value="Umbilo College">Umbilo College</option>
                                <option value="Coastal College">Coastal College</option>
                                <option value="Ukzn Westville">Ukzn Westville</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        value={payment.PaymentStatus}
                                        onChange={(e) => handleUpdate(index, 'PaymentStatus', e.target.value)}
                                    >
                                        <option value="Active">Active</option>
                                        <option value="Deactivated">Deactivated</option>
                                    </select>
                                </td>
                                <td>
                                    <button onClick={() => handleSave(payment)}>Save</button>
                                </td>
                                <td>
                                        <button onClick={() => handleHistory(payment.UserId)}>History</button>
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No payment records found.</p>
            )}
            <style>{`.transport-admin-payment {
    padding: 20px;
    font-family: Arial, sans-serif;
}
@media (max-width: 768px) {
    table {
        min-width: 1200px; /* Increase table width for mobile */
    }

    .transport-admin-payment {
        overflow-x: auto; /* Ensure horizontal scrolling for small screens */
    }
}

.transport-admin-payment h1 {
    text-align: center;
    margin-bottom: 20px;
}

table {
    width: 100%; /* Keep table responsive */
    min-width: 800px; /* Ensure table does not shrink below a readable size */
    border-collapse: collapse;
    margin: 0 auto;
}


thead {
    background-color: #f4f4f4;
}

th, td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

th input, td input, td select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

td button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

td button:hover {
    background-color: #45a049;
}
`}</style>

<style>{`
               .transport-admin-payment {
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

                .open-modal-btn {
                    margin-bottom: 20px;
                    padding: 10px 15px;
                    background-color: #007BFF;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .open-modal-btn:hover {
                    background-color: #0056b3;
                }

                .modal {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .modal-content {
                    background-color: white;
                    padding: 20px;
                    border-radius: 10px;
                    width: 400px;
                }

                .modal-content h2 {
                    margin-bottom: 20px;
                }

                .modal-content label {
                    display: block;
                    margin-bottom: 10px;
                }

                .modal-content input,
                .modal-content select {
                    width: 100%;
                    padding: 8px;
                    margin-bottom: 15px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                }

                .modal-actions {
                    display: flex;
                    justify-content: space-between;
                }

                .save-btn {
                    padding: 10px 15px;
                    background-color: #4CAF50;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .save-btn:hover {
                    background-color: #45a049;
                }

                .cancel-btn {
                    padding: 10px 15px;
                    background-color: #f44336;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .cancel-btn:hover {
                    background-color: #e31e10;
                }
            `}</style>
        </div>
    );
};

export default TransportAdminPayment;
