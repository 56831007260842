import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function TransportPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const building = queryParams.get('address');
    const price = queryParams.get('price');
    const university = queryParams.get('university');
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [userData, setUserData] = useState(null);
    const [roleUpdated, setRoleUpdated] = useState(false); // State to track role update
    const { login, logout } = useAuth(); // AuthProvider methods

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                setUserData(data);
                console.log('Fetched user data:', data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [userId]);

    useEffect(() => {
        if (roleUpdated) {
            // Logout the user and immediately log them back in
            logout();
            setTimeout(() => {
                login(userId, 'TransportOnly'); // Relogin the user with the new role
                console.log('User logged back in with updated role.');
                navigate('/userDashboard'); // Redirect to user dashboard
            }, 1000);
        }
    }, [roleUpdated, logout, login, userId, navigate]);

    const updateUserRoleToTransportOnly = async () => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/updateUserRole', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, newRole: 'TransportOnly' }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update user role: ${errorText}`);
            }

            console.log('User role updated to TransportOnly successfully!');
            setRoleUpdated(true); // Set role updated to trigger relogin
        } catch (error) {
            console.error(`Error updating user role: ${error.message}`);
        }
    };

    useEffect(() => {
        // Check role and update to TransportOnly if current role is User
        if (userData && userData[0]?.Role === 'User') {
            updateUserRoleToTransportOnly();
        }
    }, [userData]);

    const savePaymentDetails = async () => {
        try {
            const numericPrice = parseFloat(price.replace('R', ''));
            const response = await fetch('https://backendjaes.passiontrials.co.za/save_payment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId,
                    building,
                    price: numericPrice,
                    university,
                    paymentStatus: 'Active',
                    transRole: 'usertransport',
                }),
            });

            if (!response.ok) throw new Error('Failed to save payment details');
            console.log('Payment details saved successfully.');
            setPaymentStatus('Success');
        } catch (error) {
            console.error('Error saving payment details:', error);
            setPaymentStatus('Failed to save payment details. Please try again.');
        }
    };

    useEffect(() => {
        savePaymentDetails();
    }, []);

    return (
        <div>
            <h2>Transport Payment</h2>
            <p>Building: {building}</p>
            <p>Price: {price}</p>
            <p>University: {university}</p>
            <p>
                {paymentStatus === 'Success'
                    ? 'Payment completed successfully.'
                    : paymentStatus || 'Processing payment...'}
            </p>
        </div>
    );
}

export default TransportPayment;
