// import React, { useState, useEffect } from 'react';

// const EftBookings = () => {
//     const [bookings, setBookings] = useState([]);
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(true); // Loading state

//     useEffect(() => {
//         fetchEFTBookings();
//     }, []);

//     const fetchEFTBookings = () => {
//         fetch('https://backendjaes.passiontrials.co.za/api/eft-bookings')
//             .then((response) => {
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 return response.json();
//             })
//             .then((data) => setBookings(data))
//             .catch((err) => setError('Failed to fetch bookings'))
//             .finally(() => setLoading(false)); // Stop loading
//     }

//     const handleApproval = (id, status, user, room) => {
//         fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${id}`, {
//             method: 'PUT',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ ApprovalStatus: status, Approval: status === 'Approved' ? 1 : 0, }),
//         })
//             .then((response) => {
//                 if (!response.ok) {
//                     throw new Error('Failed to update approval status');
//                 }
//                 return response.json();
//             })
//             .then(() => {
//                 // setBookings((prevBookings) =>
//                 //     prevBookings.map((booking) =>
//                 //         booking.Id === id
//                 //             ? { ...booking, ApprovalStatus: status, Approval: status === 'Approved' }
//                 //             : booking
//                 //     )
//                 // );
//                 if (status == 'Approved') {
//                     associateUserWithApartment(room, user);
//                     markApartmentAsOccupied(room);

//                 }

//                 if (status == 'Dismissed') {
//                     dismissUser(room, user);
//                 }
//                 fetchEFTBookings();


//             })
//             .catch((err) => {
//                 console.error(`Error updating booking with ID ${id}:`, err);
//             });
//     };


//     const markApartmentAsOccupied = async (roomid) => {
//         try {
//             // Ensure apartmentId exists before proceeding
//             if (roomid) {
//                 // Decrement the Capacity2 in the apartment_tb table
//                 const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${roomid}`, {
//                     method: 'PUT',
//                     headers: { 'Content-Type': 'application/json' }
//                 });

//                 if (!decrementCapacity.ok) {
//                     const errorText = await decrementCapacity.text();
//                     throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
//                 }

//                 console.log('Apartment capacity has been decremented successfully');
//             } else {
//                 console.log('No apartment or room found');
//             }
//         } catch (error) {
//             throw new Error(`Error marking apartment as occupied: ${error.message}`);
//         }
//     };

//     const associateUserWithApartment = async (room, user) => {
//         try {
//             const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     UserId: user,
//                     ApartmentId: room,
//                     // MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
//                     // MoveOutDate: formattedMoveOutDate, // Use the formatted date
//                     MoveInDate: 'test',
//                     MoveOutDate: 'test',
//                     RentAmount: 0,
//                     LeaseTerm: '1 Month',
//                     IsActive: true,
//                     DepositPaid: true,
//                     ApartmentRating: 5,
//                     Guest: 1, // Default guest number
//                     Notes: 'No specific notes',
//                     TenantAgreementType: 'Month-to-Month',
//                     ChosenPlan: "Self & Bursary Funded",
//                     ServiceFee: 0,
//                     RentCost: 0,
//                     PayableRent: 0
//                 })
//             });

//             if (!response.ok) {
//                 const errorText = await response.text();
//                 throw new Error(`Failed to associate user with apartment: ${errorText}`);
//             }

//             console.log('User associated with apartment successfully!');
//         } catch (error) {
//             throw new Error(`Error associating user with apartment: ${error.message}`);
//         }
//     };

//     const dismissUser = async (roomid, userid) => {

//         const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/delete-user-apartment/${userid}`, {
//             method: 'DELETE',
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (!removeApartmentFromUser.ok) {
//             if (removeApartmentFromUser.status !== 404) { // Ignore 404 errors as this might be expected
//                 const errorText = await removeApartmentFromUser.text();
//                 throw new Error(`Failed to delete user apartment record: ${errorText}`);
//             } else {
//                 console.log('No apartment record found for the user, which is expected.');
//             }
//         } else {
//             console.log('Apartment has been removed from user successfully');
//         }

//         // Increment the Capacity2 for the ApartmentId
//         const incrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/increment-capacity/${roomid}`, {
//             method: 'PUT',
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (!incrementCapacity.ok) {
//             if (incrementCapacity.status !== 404) { // Ignore 404 errors as this might be expected
//                 const errorText = await incrementCapacity.text();
//                 throw new Error(`Failed to increment apartment capacity: ${errorText}`);
//             } else {
//                 console.log('No apartment record found to increment capacity, which may be expected.');
//             }
//         } else {
//             console.log('Apartment capacity has been incremented successfully');
//         }
//     };


//     if (loading) {
//         return <div>Loading bookings...</div>;
//     }

//     if (error) {
//         return console.log('an error occured', error);
//     }

//     return (
//         <div className="eft-bookings-container">
//             <h1>EFT Bookings</h1>
//             {bookings.length === 0 ? ( // Check if bookings array is empty
//                 <div>No bookings found.</div>
//             ) : (
//                 <table className="eft-bookings-table">
//                     <thead>
//                         <tr>
//                             <th>Booking Number</th>
//                             <th>Name</th>
//                             <th>Surname</th>
//                             <th>Email</th>
//                             <th>Approval Status</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {bookings.map((booking) => (
//                             <tr key={booking.Id}>
//                                 <td>{booking.BookingNumber}</td>
//                                 <td>{booking.Name}</td>

//                                 <td>{booking.Surname}</td>
//                                 <td>{booking.Email}</td>
//                                 <td>{booking.ApprovalStatus}</td>
//                                 <td>
//                                     <button
//                                         onClick={() => handleApproval(booking.Id, 'Approved', booking.UserId, booking.ApartmentId)}
//                                         disabled={booking.ApprovalStatus === 'Approved'}
//                                         style={{ backgroundColor: 'green' }}
//                                     >
//                                         Approve
//                                     </button>
//                                     <button
//                                         onClick={() => handleApproval(booking.Id, 'Dismissed', booking.UserId, booking.ApartmentId)}
//                                         disabled={booking.ApprovalStatus === 'Dismissed'}
//                                     >
//                                         Dismiss
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             )
//             }
//             <style>{`
//                 .eft-bookings-container {
//                     padding: 20px;
//                 }
//                 .eft-bookings-table {
//                     width: 100%;
//                     border-collapse: collapse;
//                     margin-top: 20px;
//                 }
//                 .eft-bookings-table th,
//                 .eft-bookings-table td {
//                     border: 1px solid #ddd;
//                     padding: 8px;
//                     text-align: left;
//                 }
//                 .eft-bookings-table th {
//                     background-color: #f4f4f4;
//                     font-weight: bold;
//                 }
//                 .eft-bookings-table tr:hover {
//                     background-color: #f1f1f1;
//                 }
//                 .eft-bookings-table button {
//                     margin-right: 10px;
//                     padding: 5px 10px;
//                     cursor: pointer;
//                 }
//             `}</style>
//         </div >
//     );
// };

// export default EftBookings;
import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react';

const EftBookings = () => {
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        fetchEFTBookings();
    }, []);

    const fetchEFTBookings = () => {
        fetch('https://backendjaes.passiontrials.co.za/api/eft-bookings')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => setBookings(data))
            .catch((err) => setError('Failed to fetch bookings'))
            .finally(() => setLoading(false)); // Stop loading
    };


    const getLatestInfo = async (user) => {
        try {
            const d = await fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${user}`, {
                headers: { 'Content-Type': 'application/json' },

            });
            const data = await d.json();
            if (data && data.length > 0) {

                console.log("User fetched data:", data[0]);
                return data[0]; // Return fetched data
            } else {
                throw new Error("No user data found for the given user ID.");
            }
        } catch (error) {
            console.log('Failed to retrieve eft info ');
        }

    };

    const isAssociated = async (user) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/user_apartment_exists/${user}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                console.error("Failed to fetch association status:", response.statusText);
                throw new Error("Failed to fetch association status"); // Throw an error instead of returning false
            }

            const data = await response.json();
            return data.exists; // Return the "exists" boolean value only if the response is valid
        } catch (error) {
            console.error("Error occurred while checking if associated:", error.message);
            throw error; // Re-throw the error for handling elsewhere
        }
    };



    const handleApproval = (id, status, user, room) => {
        fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ApprovalStatus: status, Approval: status === 'Approved' ? 1 : 0 }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update approval status');
                }
                return response.json();
            })
            .then(() => {
                if (status === 'Approved') {
                    associateUserWithApartment(room, user);
                    markApartmentAsOccupied(room);
                }

                if (status === 'Dismissed') {
                    dismissUser(room, user);
                }
                fetchEFTBookings();
            })
            .catch((err) => {
                console.error(`Error updating booking with ID ${id}:`, err);
            });
    };

    const dismissUser = async (roomid, userid) => {
        try {


            // const checkstatus = await getLatestInfo(userid);
            const isAss = await isAssociated(userid);

            if (isAss) {
                console.log("Deleted the user is was associated.");

            } else {
                console.log("Deleted user is NOT associated with an apartment.");
            }


            const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${userid}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!removeApartmentFromUser.ok && removeApartmentFromUser.status !== 404) {
                const errorText = await removeApartmentFromUser.text();
                throw new Error(`Failed to delete user apartment record: ${errorText}`);
            }

            if (removeApartmentFromUser.ok) {
                fetchEFTBookings();
                console.log('deleted and refreshed');
            }
            // if (checkstatus.ApprovalStatus.toLowerCase() == 'approved') {
            //     const incrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/increment-capacity/${roomid}`, {
            //         method: 'PUT',
            //         headers: { 'Content-Type': 'application/json' },
            //     });

            //     if (!incrementCapacity.ok && incrementCapacity.status !== 404) {
            //         const errorText = await incrementCapacity.text();
            //         throw new Error(`Failed to increment apartment capacity: ${errorText}`);
            //     }

            //     console.log('decrement apartment successfully');

            // }



            console.log('Dismiss process completed successfully');
        } catch (error) {
            console.error('Error dismissing user:', error.message);
        }
    };

    const markApartmentAsOccupied = async (roomid) => {
        try {
            // Ensure apartmentId exists before proceeding
            if (roomid) {
                // Decrement the Capacity2 in the apartment_tb table
                const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${roomid}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!decrementCapacity.ok) {
                    const errorText = await decrementCapacity.text();
                    throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                }

                console.log('Apartment capacity has been decremented successfully');
            } else {
                console.log('No apartment or room found');
            }
        } catch (error) {
            throw new Error(`Error marking apartment as occupied: ${error.message}`);
        }
    };

    const associateUserWithApartment = async (room, user) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    // MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    // MoveOutDate: formattedMoveOutDate, // Use the formatted date
                    MoveInDate: 'test',
                    MoveOutDate: 'test',
                    RentAmount: 0,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'EFT booker',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: "Self & Bursary Funded",
                    ServiceFee: 0,
                    RentCost: 0,
                    PayableRent: 0
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate user with apartment: ${errorText}`);
            }

            console.log('User associated with apartment successfully!');
        } catch (error) {
            throw new Error(`Error associating user with apartment: ${error.message}`);
        }
    };

    const handleDismissConfirmation = (id, user, room) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to dismiss this user from the booking list. This action cannot be undone.User remains associated with room ,if approved.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, dismiss it!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleApproval(id, 'Dismissed', user, room);
                Swal.fire('Dismissed!', 'The booking has been dismissed.', 'success');
            }
        });
    };

    if (loading) {
        return <div>Loading bookings...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="eft-bookings-container">
            <h1>EFT Bookings</h1>
            {bookings.length === 0 ? (
                <div>No bookings found.</div>
            ) : (
                <table className="eft-bookings-table">
                    <thead>
                        <tr>
                            <th>Booking Number</th>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>Email</th>
                            <th>Approval Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking) => (
                            <tr key={booking.Id}>
                                <td>{booking.BookingNumber}</td>
                                <td>{booking.Name}</td>
                                <td>{booking.Surname}</td>
                                <td>{booking.Email}</td>
                                <td>{booking.ApprovalStatus}</td>
                                <td>
                                    <button
                                        onClick={() =>
                                            handleApproval(booking.Id, 'Approved', booking.UserId, booking.ApartmentId)
                                        }
                                        disabled={booking.ApprovalStatus === 'Approved'}
                                        style={{ backgroundColor: 'green' }}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleDismissConfirmation(booking.Id, booking.UserId, booking.ApartmentId)
                                        }
                                        disabled={booking.ApprovalStatus === 'Dismissed'}
                                    >
                                        Dismiss
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <style>{`
                .eft-bookings-container {
                    padding: 20px;
                }
                .eft-bookings-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px;
                }
                .eft-bookings-table th,
                .eft-bookings-table td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                .eft-bookings-table th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                }
                .eft-bookings-table tr:hover {
                    background-color: #f1f1f1;
                }
                .eft-bookings-table button {
                    margin-right: 10px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default EftBookings;
