// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
// import './LoginModal.css';

// function LoginModal({ clickedAction, onClose, onSuccess, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');

//     const navigate = useNavigate();
//     const { login } = useAuth();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError('');

//         try {
//             const response = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ Email: email, Password: password }),
//             });

//             const data = await response.json();

//             if (response.ok) {
//                 // Update global auth state
//                 login(data.userId, data.Role);

//                 // Trigger onSuccess and onClose callbacks
//                 onSuccess();
//                 onClose();

//                 // Handle navigation based on user action
//                 if (clickedAction === 'downloadPDF') {
//                     onSuccess(); // Trigger PDF download
//                 } else if (clickedAction === 'pay') {
//                     navigate('/YocoPayment', {
//                         state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate },
//                     });
//                 }
//             } else {
//                 setError(data.message || 'Login failed. Please try again.');
//             }
//         } catch (err) {
//             console.error('Login error:', err);
//             setError('An error occurred. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="login-modal-overlay">
//             <div className="login-modal-content">
//                 <h2>Login</h2>
//                 {error && <p className="error-message">{error}</p>}
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-group">
//                         <label>Email</label>
//                         <input
//                             type="email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             placeholder="Enter your email"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>Password</label>
//                         <input
//                             type="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             placeholder="Enter your password"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <button type="button" onClick={onClose}>
//                             Close
//                         </button>
//                         <button type="submit" disabled={loading}>
//                             {loading ? 'Logging in...' : 'Login'}
//                         </button>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     );
// }

// export default LoginModal;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import './LoginModal.css';
import VerificationPopup2 from './loginVerificationPopup.js';
import Swal from "sweetalert2";

function LoginModal({ clickedAction, onClose, onSuccess, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [verificationNeeded, setVerificationNeeded] = useState(false);//9 jan 2025
    const [timeLeft, setTimeLeft] = useState(null);


    const navigate = useNavigate();
    const { login } = useAuth();

    const liveData = async () => {
        const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
            headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if (data && data.length > 0) {
            console.log('the boolean', data[0].AccountVerification);
            return data[0].AccountVerification;
        };

    };

    const resendVerificationCode = async () => {
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Sent",
                    text: data.message,
                    icon: "success",
                });
                setTimeLeft(120);
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.error || "Failed to resend code.",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while resending the code.",
                icon: "error",
            });
        }
    };
    useEffect(() => {
        if (verificationNeeded) {
            // Start timer when popup is shown
            setTimeLeft(120); // Set countdown duration (e.g., 120 seconds)
        } else {
            // Clear timer when popup is hidden
            setTimeLeft(null);
        }
    }, [verificationNeeded]);

    useEffect(() => {
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else {
                resendVerificationCode(); // Auto-resend when timer reaches 0
            }
        }
    }, [timeLeft]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const isVerified = await liveData();

        if (!isVerified) {
            setVerificationNeeded(true);
            return;
        }

        setLoading(true);
        setError('');



        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password }),
            });

            const data = await response.json();

            if (response.ok) {
                // Update global auth state


                login(data.userId, data.Role);

                // Trigger onSuccess and onClose callbacks
                onSuccess();
                onClose();

                // Handle navigation based on user action
                if (clickedAction === 'downloadPDF') {
                    onSuccess(); // Trigger PDF download
                } else if (clickedAction === 'pay') {
                    navigate('/YocoPayment', {
                        state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate },
                    });
                }
            } else {
                setError(data.message || 'Login failed. Please try again.');
            }
        } catch (err) {
            console.error('Login error:', err);
            setError('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-modal-overlay">
            {verificationNeeded && (
                <VerificationPopup2
                    email={email}
                    onResend={resendVerificationCode}
                    onVerify={() => setVerificationNeeded(false)} // Hide popup after verification
                    timeLeft={timeLeft}
                />
            )}
            <div className="login-modal-content">
                <h2>Login</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <button type="button" onClick={onClose}>
                            Close
                        </button>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginModal;
