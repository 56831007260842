import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import './mycard.css';

const ServiceBox = () => {
    const [selectedLevel, setSelectedLevel] = useState('Urgent');
    const [selectedGroup, setSelectedGroup] = useState('Website');
    const [selectedCategory, setSelectedCategory] = useState('Access');
    const [selectedItem, setSelectedItem] = useState('Doors');
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [serviceBoxData, setServiceBoxData] = useState([]);

    const handleLevelChange = (e) => setSelectedLevel(e.target.value);
    const handleGroupChange = (e) => setSelectedGroup(e.target.value);
    const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
    const handleItemChange = (e) => setSelectedItem(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);

    const { userId } = useAuth();
    const location = useLocation();
    const { buildingName, name, surname, buildingID, CreatedBy } = location.state || {};
    const [management, setManagement] = useState([]);

    console.log("name nan surname", surname)
    useEffect(() => {
        const fetchServiceBoxData = async () => {
            try {
                const response = await fetch(`https://backendjaes.passiontrials.co.za/api/servicebox/${userId}`);
                const data = await response.json();
                setServiceBoxData(Array.isArray(data) ? data : [data]);
            } catch (error) {
                console.error('Error fetching service box data:', error);
            }
        };

        if (userId) {
            fetchServiceBoxData();
        }
    }, [userId]);

    const handleSubmit = async () => {
        setIsSubmitting(true);

        // Data to be sent to the backend
        const formData = {
            userId: userId,
            level: selectedLevel,
            group: selectedGroup,
            category: selectedCategory,
            item: selectedItem,
            description: description,
            active: 'Active', // Set default value for active
            buildingName: buildingName,
            name: name,  // Add name
            surname: surname  // Add surname
        };

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/servicebox', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Form submitted and data saved successfully!');
                await mailToManagement(buildingID, CreatedBy);

            } else {
                alert('There was an error submitting the form.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting the form. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };



    useEffect(() => {
        if (management) {
            getManager(management.AdminEmail);
        };

    }, []);

    const getManager = async (email) => {
        const info = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`);

        if (!info.ok) {
            console.log('failed to get management information');
        }

        const data = await info.json();
        if (data && data.length > 0) {
            setManagement(data[0]);
            return data[0];
        }
        else {
            console.warn('No management data found for the given email');
            return null;
        }
    };

    const mailToManagement = async (houseId, managerEmail) => {
        const manage = await getManager(managerEmail);
        try {




            const formData2 = {
                Name: manage.AdminName,
                Surname: manage.AdminSurname,
                level: selectedLevel,
                group: selectedGroup,
                category: selectedCategory,
                item: selectedItem,
                description: description,
                active: 'Active', // Set default value for active
                buildingName: buildingName,
                issuerName: name,  // Add name
                issuerSurname: surname,  // Add surname
                receiver: managerEmail
            };
            console.log(formData2);
            if (manage.Package.toLowerCase().trim() == 'basic') {

                try {
                    const response = await fetch('https://backendjaes.passiontrials.co.za/sendComplaint', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData2),
                    });

                    if (response.ok) {
                        console.log('Form submitted and data saved successfully!✔✔✔');
                    } else {
                        console.log('There was an error submitting the form.');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    alert('Error submitting the form. Please try again later.');
                }

            }
            else {

                try {
                    const response2 = await fetch('https://backendjaes.passiontrials.co.za/sendComplaintToManagement', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData2),
                    });

                    if (response2.ok) {
                        console.log('Form submitted and data saved successfully!✨✨✨');
                    } else {
                        console.log('There was an error submitting the form.');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    alert('Error submitting the form. Please try again later.');
                }

            }



        }
        catch (error) {
            console.log('Failed to Mail complaint to Admin or Mangement  because :', error.message);
        }
    }

    // Remove any processing-related logic from here

    return (
        <div style={styles.container}>
            <div style={styles.form}>
                <h2>Service Box</h2>

                <p>{buildingName || "Not available"}</p>

                <div style={styles.inputGroup}>
                    <div style={styles.inputWrapper}>
                        <select id="level" value={selectedLevel} onChange={handleLevelChange} style={styles.input}>
                            <option value="Urgent">Urgent</option>
                            <option value="Normal">Normal</option>
                        </select>
                    </div>

                    <div style={styles.inputWrapper}>
                        <select id="group" value={selectedGroup} onChange={handleGroupChange} style={styles.input}>
                            <option value="Website">Website</option>
                            <option value="Security and Access">Security and Access</option>
                            <option value="Health And Academic">Health And Academic</option>
                            <option value="Other">Other</option>
                            <option value="Gardening">Gardening</option>
                            <option value="Cleaning">Cleaning</option>
                            <option value="Maintenance">Maintenance</option>
                            <option value="Transport">Transport</option>
                        </select>
                    </div>
                </div>

                <div style={styles.inputGroup}>
                    <div style={styles.inputWrapper}>
                        <select id="category" value={selectedCategory} onChange={handleCategoryChange} style={styles.input}>
                            <option value="Access">Access</option>
                            <option value="Incident">Incident</option>
                            <option value="Risk">Risk</option>
                            <option value="Study Amenities">Study Amenities</option>
                            <option value="Sanitary Services">Sanitary Services</option>
                            <option value="Incidents">Incidents</option>
                            <option value="Accounts">Accounts</option>
                            <option value="General Admin">General Admin</option>
                            <option value="Web Assistance">Web Assistance</option>
                            <option value="Book a meeting">Book a meeting</option>
                            <option value="Property Snags">Property Snags</option>
                            <option value="Other">Other</option>
                            <option value="Gardening">Gardening</option>
                            <option value="Cleaning services">Cleaning services</option>
                            <option value="Website">Website</option>
                            <option value="Lighting">Lighting</option>
                        </select>
                    </div>

                    <div style={styles.inputWrapper}>
                        <select id="item" value={selectedItem} onChange={handleItemChange} style={styles.input}>
                            <option value="Doors">Doors</option>
                            <option value="Windows">Windows</option>
                            <option value="Keys">Keys</option>
                            <option value="Theft">Theft</option>
                            <option value="Unknown Personnel">Unknown Personnel</option>
                            <option value="Other">Other</option>
                            <option value="Breach Of House Rules">Breach Of House Rules</option>
                            <option value="Electrical Issues">Electrical Issues</option>
                            <option value="Furniture">Furniture</option>
                            <option value="Wi-Fi">Wi-Fi</option>
                            <option value="Lighting">Lighting</option>
                        </select>
                    </div>
                </div>

                <textarea
                    id="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    style={styles.textarea}
                    placeholder="Enter the description"
                ></textarea>

                <button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    style={isSubmitting ? styles.buttonDisabled : styles.button}>
                    {isSubmitting ? 'Submitting...' : 'SUBMIT'}
                </button>

                {serviceBoxData && serviceBoxData.length > 0 && (
                    <div style={styles.tilesContainer}>
                        <h3>Your Service Box Data</h3>
                        {serviceBoxData.slice().reverse().map((entry, index) => (
                            <div key={index} className="mycard" style={styles.tile}>
                                <div style={styles.levelTopRight}>{entry.level}</div>
                                <div style={styles.tileContent}>
                                    <p><strong>Group:</strong> {entry.group}</p>
                                    <p><strong>Category:</strong> {entry.category}</p>
                                    <p><strong>Item:</strong> {entry.item}</p>
                                    <p><strong>Description:</strong> {entry.description}</p>
                                </div>
                                <div style={styles.statusBottomRight}>
                                    <strong>Status: {entry.active}</strong>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        minHeight: '100vh',
    },
    form: {
        backgroundColor: '#fff',
        padding: '40px',
        borderRadius: '30px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '600px',
        textAlign: 'center',
    },
    inputGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    inputWrapper: {
        flex: '1',
        marginRight: '10px',
    },
    input: {
        padding: '12px',
        width: '100%',
        borderRadius: '30px',
        border: '1px solid #ccc',
        fontSize: '16px',
        backgroundColor: '#ff5a5f',
        color: '#ffffff',
    },
    textarea: {
        padding: '12px',
        marginBottom: '20px',
        width: '100%',
        height: '100px',
        borderRadius: '30px',
        border: '1px solid #ccc',
        fontSize: '16px',
        backgroundColor: '#ff5a5f',
        color: '#ffffff',
    },
    button: {
        padding: '12px 20px',
        backgroundColor: '#ff0055',
        color: 'white',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    buttonDisabled: {
        padding: '12px 20px',
        backgroundColor: '#ccc',
        color: 'white',
        border: 'none',
        borderRadius: '30px',
        cursor: 'not-allowed',
        fontSize: '16px',
    },
    tilesContainer: {
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tile: {
        position: 'relative',
        width: '100%',
        maxWidth: '400px',
        border: '1px solid #ccc',
        borderRadius: '30px',
        padding: '20px',
        marginBottom: '20px',
        textAlign: 'left',
    },
    tileContent: {
        paddingBottom: '20px',
    },
    levelTopRight: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '5px 10px',
        borderRadius: '30px',
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: '#ff5a5f',
        color: '#ffffff',
    },
    statusBottomRight: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        backgroundColor: '#ddd',
        padding: '5px 10px',
        borderRadius: '30px',
        fontSize: '14px',
        backgroundColor: '#ff5a5f',
        color: '#ffffff',
    },
};

export default ServiceBox;
