import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './AuthProvider';

function RecurringPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const { apartment, nextPayDate, latestInfo, amount, dailyPay } = location.state || {};
    //  const { latestInfo } = location.state || {};
    const checker = useRef(false);
    let amountConverted = parseFloat(amount);
    let recurringAmount = amountConverted;
    useEffect(() => {
        if (!apartment && !nextPayDate && !latestInfo && !amount) {
            console.warn("Missing information: ");
            return;
        }
        if (!checker.current) {
            console.log('Processing payment for:');
          

            //createSubscriptionForm();
            createYocoPayment();
            checker.current = true;
        }
    }, [apartment, nextPayDate, latestInfo]);


    // if (latestInfo?.PaymentStatus.trim() == 'OVERDUE') {// needs to be fixed
    //     amount = amount + latestInfo?.PayAmount; // last months cost and this months cost
    // }

    const createYocoPayment = async () => {
        try {
            const amountInCents = Math.round(parseFloat(recurringAmount) * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/Wallet?status=success&user=${apartment.UserId}&apartmentId=${apartment.ApartmentId}&apartmentPrice=${apartment.ApartmentPrice}&amount=${amount}`,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel',
                    room: `${apartment.RoomIdentifier}-${apartment.Email}`
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }

        } catch (error) {
            console.error('Payment error', error);
        }
    };
   
    if (!apartment && !nextPayDate && !latestInfo) {
        return <div style={{ marginTop: '149px' }}>Error: missing information ,please ensure all data is provided.</div>
    }

    return (
        <div style={{ marginTop: '149px' }}>
            <h1>Processing Payment...</h1>
            <p>If you are not automatically redirected, please click submit. </p>
        </div>
    )



}

export default RecurringPayment;