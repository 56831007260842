import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const ManualReorderBuildings = () => {
    const [buildings, setBuildings] = useState([]);

    // Fetch the buildings on component mount
    useEffect(() => {
        fetch("https://backendjaes.passiontrials.co.za/buildings")
            .then((response) => response.json())
            .then((data) => {
                setBuildings(data);
            })
            .catch((error) => {
                console.error("Failed to fetch buildings:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch buildings. Please try again.",
                });
            });
    }, []);

    const moveUp = (index) => {
        if (index === 0) return; // Already at the top

        const updatedBuildings = [...buildings];
        const temp = updatedBuildings[index - 1];
        updatedBuildings[index - 1] = updatedBuildings[index];
        updatedBuildings[index] = temp;

        setBuildings(updatedBuildings);
    };

    const moveDown = (index) => {
        if (index === buildings.length - 1) return; // Already at the bottom

        const updatedBuildings = [...buildings];
        const temp = updatedBuildings[index + 1];
        updatedBuildings[index + 1] = updatedBuildings[index];
        updatedBuildings[index] = temp;

        setBuildings(updatedBuildings);
    };

    const saveOrder = () => {
        const orderedIds = buildings.map((building) => building.BuildingId);
        console.log("Saved Order:", orderedIds);

        // Add API call to save the updated order
        fetch("https://backendjaes.passiontrials.co.za/updateBuildingOrder", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ orderedBuildings: orderedIds }),
        })
            .then((response) => {
                if (response.ok) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Building order saved successfully!",
                    });
                } else {
                    throw new Error("Failed to save order");
                }
                return response.json();
            })
            .then((data) => console.log("Order saved successfully:", data))
            .catch((error) => {
                console.error("Failed to save order:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to save the order. Please try again.",
                });
            });
    };

    return (
        <div style={{ padding: "20px" }}>
            <h1>Reorder Buildings</h1>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                {buildings.map((building, index) => (
                    <li
                        key={building.BuildingId}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                            padding: "10px",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                        }}
                    >
                        <span style={{ flexGrow: 1, width: "100%" }}>{building.BuildingName}</span>
                        <button
                            onClick={() => moveUp(index)}
                            disabled={index === 0}
                            style={{ marginRight: "5px" }}
                        >
                            Move Up
                        </button>
                        <button
                            onClick={() => moveDown(index)}
                            disabled={index === buildings.length - 1}
                        >
                            Move Down
                        </button>
                    </li>
                ))}
            </ul>
            <button
                onClick={saveOrder}
                style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                }}
            >
                Save Order
            </button>
        </div>
    );
};

export default ManualReorderBuildings;
