import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

function VerificationPopup({ email, onResend, onVerify, timeLeft }) {
    const [codeInput, setCodeInput] = useState("");
    const [showVerificationPopup, setShowVerificationPopup] = useState(false);
    const [inputs, setInputs] = useState(["", "", "", ""]);


    const handleVerification = async (code) => {
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/verify_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, codeInput: code }),
            });

            const data = await response.json();

            if (response.ok) {
                Swal.fire({
                    title: "Verified!",
                    text: data.message,
                    icon: "success",
                }).then(onVerify); // Notify parent to close popup
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.error || "Incorrect code.",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Failed to verify the code.",
                icon: "error",
            });
        }
    };

    const handleVerify = () => {
        const code = inputs.join(""); // Combine inputs into a single code
        handleVerification(code);
    };

    // useEffect(() => {// 10 jan 2025
    //     if (timeLeft !== null) {
    //         if (timeLeft > 0) {
    //             const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
    //             return () => clearTimeout(timer);
    //         } else {
    //             // Trigger auto-resend and reset the timer
    //             handleResendCode();
    //         }
    //     }
    // }, [timeLeft]);

    // useEffect(() => {
    //     if (showVerificationPopup) {
    //         // Initialize the timer to 30 seconds when the popup is shown
    //         setTimeLeft(120);
    //     } else {
    //         // Clear the timer when the popup is closed
    //         setTimeLeft(null);
    //     }
    // }, [showVerificationPopup]);

    const handleInputChange = (index, value) => {// 10 jan 2025
        if (!isNaN(value) && value.length <= 1) {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            // Automatically focus the next box
            if (value && index < 3) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };

    return (
        <div className="verification-popup">
            <div className="popup-content">
                <h2>Enter Verification Code</h2>
                <p>Please enter the 4-digit code sent to your email.</p>
                {/* <input
                    type="text"
                    placeholder="Enter code"
                    value={codeInput}
                    onChange={(e) => setCodeInput(e.target.value)}
                    maxLength={4}
                />
                <div className="button-group">
                    <button className="verify-button" onClick={handleVerification}>
                        Verify
                    </button>
                    <button className="resend-button" onClick={onResend}>
                        Resend Code
                    </button>
                </div> */}
                <div className="input-boxes">
                    {inputs.map((value, index) => (
                        <input
                            key={index}
                            id={`input-${index}`}
                            type="text"
                            maxLength={1}
                            value={value}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Backspace" && index > 0 && !value) {
                                    document.getElementById(`input-${index - 1}`).focus();
                                }
                            }}
                            className="verification-input"
                        />
                    ))}
                </div>
                <button
                    className="verify-button"
                    onClick={handleVerify}
                    disabled={inputs.some((input) => input === "")} // Disable if inputs are incomplete
                >
                    Verify
                </button>
                <p className="resend-timer">
                    Resending a new code in <strong>{String(timeLeft).padStart(2, "0")}</strong> seconds...

                </p>
            </div>
            <style>{`
       .verification-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .popup-content {
          background: white;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
        .input-boxes {
          display: flex;
          justify-content: center;
          gap: 10px;
          margin: 20px 0;
        }
        .verification-input {
          width: 50px;
          height: 50px;
          text-align: center;
          font-size: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .verify-button {
          padding: 10px 20px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
        .resend-timer {
          margin-top: 15px;
          font-size: 14px;
          color: #555;
        }
        .resend-button {
          background: none;
          border: none;
          color: #007bff;
          cursor: pointer;
          text-decoration: underline;
        }
      `}</style>
        </div>
    );
}

export default VerificationPopup;
