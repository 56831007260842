// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
// import './LoginModal.css'; // Add your modal styling here
// import { EyeClosed, Eye } from 'lucide-react';

// function LoginModal({onClose }) {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [loading, setLoading] = useState(false);

//     const navigate = useNavigate();
//     const { login } = useAuth();
//     const [showPassword, setShowPassword] = useState(false); // Password visibility toggle

//     const togglePasswordVisibility = () => {
//         setShowPassword((prevShowPassword) => !prevShowPassword);
//     };
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);

//         try {
//             const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ Email: email, Password: password }),
//             });

//             const loginData = await loginResponse.json();

//             if (loginResponse.ok) {
//                 login(loginData.userId, loginData.Role);


//                 // Trigger the success callback and navigate to Yoco Payment
             
//                            navigate('/SignupTransport');
          
//             } else {
//                 alert(loginData.message || 'Failed to login');
//             }
//         } catch (error) {
//             console.error('Login error:', error);
//             alert('An error occurred during login');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="login-modal-overlay">
//             <div className="login-modal-content">
//                 <h2>Login</h2>
//                 <form onSubmit={handleSubmit} className="cabernet-form">
//     <div className="cabernet-form-group">
//         <label>Email</label>
//         <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter your email"
//             required
//         />
//     </div>

//     <div className="cabernet-form-group">
//         <label>Password</label>
//         <div className="cabernet-input-with-icon">
//             <input
//                 type={showPassword ? 'text' : 'password'}
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 placeholder="Enter your password"
//                 required
//             />
//             <span onClick={togglePasswordVisibility} className="cabernet-eye-icon">
//                 {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
//             </span>
//         </div>
//     </div>

//     <div className="cabernet-buttons">
//         <button type="button" className="cabernet-close-btn" onClick={onClose}>Close</button>
//         <button type="submit" disabled={loading} className="cabernet-login-btn">
//             {loading ? 'Logging in...' : 'Login'}
//         </button>
//     </div>

//     <style>{`
//         .cabernet-form .cabernet-form-group {
//             position: relative;
//             display: flex;
//             flex-direction: column;
//             margin-bottom: 1em;
//         }

//         .cabernet-form .cabernet-input-with-icon {
//             position: relative;
//             width: 100%;
//         }

//         .cabernet-form .cabernet-eye-icon {
//             position: absolute;
//             top: 50%;
//             right: 10px;
//             transform: translateY(-50%);
//             cursor: pointer;
//             color: #888;
//         }

//         .cabernet-form .cabernet-buttons {
//             display: flex;
//             justify-content: space-between;
//             margin-top: 1em;
//         }

//         .cabernet-form .cabernet-buttons .cabernet-close-btn,
//         .cabernet-form .cabernet-buttons .cabernet-login-btn {
//             width: 48%;
//         }
//     `}</style>
// </form>




//             </div>
//         </div>
//     );
// }

// export default LoginModal;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import './LoginModal.css'; // Add your modal styling here
import { EyeClosed, Eye } from 'lucide-react';
import VerificationPopup2 from './loginVerificationPopup.js';

import Swal from "sweetalert2";


function LoginModal({ onClose }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { login } = useAuth();
    const [showPassword, setShowPassword] = useState(false); // Password visibility toggle

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const [verificationNeeded, setVerificationNeeded] = useState(false);//9 jan 2025
    const [timeLeft, setTimeLeft] = useState(null);


    const liveData = async () => {
        const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
            headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if (data && data.length > 0) {
            console.log('the boolean', data[0].AccountVerification);
            return data[0].AccountVerification;
        };

    };

    const resendVerificationCode = async () => {
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Sent",
                    text: data.message,
                    icon: "success",
                });
                setTimeLeft(120);
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.error || "Failed to resend code.",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while resending the code.",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        if (verificationNeeded) {
            // Start timer when popup is shown
            setTimeLeft(120); // Set countdown duration (e.g., 120 seconds)
        } else {
            // Clear timer when popup is hidden
            setTimeLeft(null);
        }
    }, [verificationNeeded]);

    useEffect(() => {
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else {
                resendVerificationCode(); // Auto-resend when timer reaches 0
            }
        }
    }, [timeLeft]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const isVerified = await liveData();

        if (!isVerified) {
            setVerificationNeeded(true);
            return;
        }
        setLoading(true);

        try {
            const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password }),
            });

            const loginData = await loginResponse.json();

            if (loginResponse.ok) {
                login(loginData.userId, loginData.Role);


                // Trigger the success callback and navigate to Yoco Payment

                navigate('/userDashboard');

            } else {
                // alert(loginData.message || 'Failed to login');
                Swal.fire({
                    title: "Login Failure!",
                    text: loginData.message || 'Failed to login',
                    icon: "error"
                });

            }
        } catch (error) {
            console.error('Login error:', error);
            // alert('An error occurred during login');
            Swal.fire({
                title: "Login Error!",
                text: 'An error occurred during login',
                icon: "error"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-modal-overlay">
            {verificationNeeded && (
                <VerificationPopup2
                    email={email}
                    onResend={resendVerificationCode}
                    onVerify={() => setVerificationNeeded(false)} // Hide popup after verification
                    timeLeft={timeLeft}
                />
            )}
            <div className="login-modal-content">
                <h2>Login</h2>
                <form onSubmit={handleSubmit} className="cabernet-form">
                    <div className="cabernet-form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="cabernet-form-group">
                        <label>Password</label>
                        <div className="cabernet-input-with-icon">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                required
                            />
                            <span onClick={togglePasswordVisibility} className="cabernet-eye-icon">
                                {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
                            </span>
                        </div>
                    </div>

                    <div className="cabernet-buttons">
                        <button type="button" className="cabernet-close-btn" onClick={onClose}>Close</button>
                        <button type="submit" disabled={loading} className="cabernet-login-btn">
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>

                    <style>{`
        .cabernet-form .cabernet-form-group {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .cabernet-form .cabernet-input-with-icon {
            position: relative;
            width: 100%;
        }

        .cabernet-form .cabernet-eye-icon {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            cursor: pointer;
            color: #888;
        }

        .cabernet-form .cabernet-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 1em;
        }

        .cabernet-form .cabernet-buttons .cabernet-close-btn,
        .cabernet-form .cabernet-buttons .cabernet-login-btn {
            width: 48%;
        }
    `}</style>
                </form>




            </div>
        </div>
    );
}

export default LoginModal;
