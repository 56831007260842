// wallet js page 
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider'; //8 jan 2025

function Wallet() {
    const { isLoggedIn, logout, role, userId } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const errorPreventorRef = useRef();


    const queryParams = new URLSearchParams(location.search);
    const apartmentId = queryParams.get('apartmentId');
    const apartmentPrice = parseFloat(queryParams.get('apartmentPrice'));
    const amount = parseFloat(queryParams.get('amount'));
    const userID = parseFloat(queryParams.get('user'));
    const Email = null;
    const Password = null;


    useEffect(() => {
        // Redirect if the payment has already been processed
        // if (sessionStorage.getItem('paymentProcessed')) {
        //     navigate('/userdashboard', { replace: true });
        //     return;
        // }

        if (!userID || !apartmentId || !apartmentPrice) {
            setError('Missing necessary data.');
            return;
        }

        // const getDaysInCurrentMonth = () => {
        //     const now = new Date();
        //     return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        // };

        // const getMonthYear = () => {
        //     const now = new Date();
        //     return `${now.toLocaleString('en-US', { month: 'long' })} ${now.getFullYear()}`;
        // };

        // const calculateDailyRateAndDaysPaidFor = () => {
        //     const daysInMonth = getDaysInCurrentMonth();
        //     const dailyRate = apartmentPrice / daysInMonth;
        //     const daysPaidFor = Math.floor(amount / dailyRate);

        //     // Format payment date with time (local time)
        //     const now = new Date();

        //     // Get YYYY-MM-DD HH:MM:SS format
        //     const formatDate = (date) => {
        //         const year = date.getFullYear();
        //         const month = String(date.getMonth() + 1).padStart(2, '0');
        //         const day = String(date.getDate()).padStart(2, '0');
        //         const hours = String(date.getHours()).padStart(2, '0');
        //         const minutes = String(date.getMinutes()).padStart(2, '0');
        //         const seconds = String(date.getSeconds()).padStart(2, '0');
        //         return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        //     };

        //     const paymentDate = formatDate(now);
        //     const monthYear = getMonthYear();

        //     return { dailyRate, daysPaidFor, paymentDate, monthYear };
        // };


        // const insertIntoWallet = async () => {
        //     try {
        //         const { dailyRate, daysPaidFor, paymentDate, monthYear } = calculateDailyRateAndDaysPaidFor();
        //         const response = await fetch('https://backendjaes.passiontrials.co.za/wallet/update', {
        //             method: 'POST',
        //             headers: { 'Content-Type': 'application/json' },
        //             body: JSON.stringify({
        //                 UserId: userId,
        //                 ApartmentId: apartmentId,
        //                 PaymentDate: paymentDate,
        //                 AmountPaid: amount,
        //                 DailyRate: dailyRate.toFixed(2),
        //                 DaysPaidFor: daysPaidFor,
        //                 MonthYear: monthYear,
        //                 PaymentMethod: "Yoco"
        //             }),
        //         });

        //         const data = await response.json();
        //         if (!response.ok) {
        //             throw new Error(data.error || 'Failed to update wallet');
        //         }

        //         //     setStatus('Payment successfully added to your wallet.');
        //         //     sessionStorage.setItem('paymentProcessed', 'true'); // Store the flag
        //         //   //  await transitionReserverToBooker();
        //         //     setTimeout(() => navigate('/userdashboard', { replace: true }), 3000);

        //     } catch (err) {
        //         console.error('Error updating wallet:', err);
        //         setError('Failed to process your wallet entry. Please try again.');
        //     }
        // };

        // insertIntoWallet();
        if (!errorPreventorRef.current) {
            errorPreventorRef.current = true;
            transitionReserverToBooker();

        }
    }, [userID, apartmentId, apartmentPrice, amount, navigate]);

    //associateUserWithRoom
    //RemoveUserFromReservation
    //ChangeRoleOfReserver

    const getDaysInCurrentMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    };

    const getMonthYear = () => {
        const now = new Date();
        return `${now.toLocaleString('en-US', { month: 'long' })} ${now.getFullYear()}`;
    };

    const calculateDailyRateAndDaysPaidFor = () => {
        const daysInMonth = getDaysInCurrentMonth();
        const dailyRate = apartmentPrice / daysInMonth;
        const daysPaidFor = Math.floor(amount / dailyRate);

        // Format payment date with time (local time)
        const now = new Date();

        // Get YYYY-MM-DD HH:MM:SS format
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const paymentDate = formatDate(now);
        const monthYear = getMonthYear();

        return { dailyRate, daysPaidFor, paymentDate, monthYear };
    };

    const getCredentials = async (userId) => {

        try {
            const d = await fetch(`https://backendjaes.passiontrials.co.za/user_tb/${userId}`, {
                headers: { 'Content-Type': 'application/json' },

            });
            const data = await d.json();
            if (data) {
                Email = data.Email;
                Password = data.Password;
            }

        } catch (error) {
            console.log('Failed to retrieve credentials');
        }

    };

    const transitionReserverToBooker = async () => {
        try {
            // Step 1: Check if the user is already associated with the apartment
            const isAlreadyAssociated = await checkUserApartmentAssociation();
    
            if (isAlreadyAssociated) {

                console.log('User is already associated with the apartment. Skipping unnecessary steps.');
            } else {
                // Step 2: Associate the user with the apartment
                await associateUserWithRoom();
                console.log('Associated reserver with apartment');
    
                // Step 3: Mark apartment as occupied
                await markApartmentAsOccupied();
                console.log('Marked apartment as occupied');
    
                // Step 4: Remove user from reservation
                await RemoveUserFromReservation();
                console.log('Removed user from reservation');
    
                // Step 5: Change user role
                await changeRole();
                console.log('Changed role');
            }
       // Step 6: Insert payment into wallet (always runs)
       await insertIntoWallet();
       console.log('Payment successfully recorded in wallet.');

            // Navigate to user dashboard
            setTimeout(() => navigate('/userdashboard', { replace: true }), 3000);
        } catch (error) {
            console.error('Error processing your reserver transition:', error);
            alert(`Error processing your reserver transition: ${error.message}`);
        }
    };
    const checkUserApartmentAssociation = async () => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/checkUserApartment/${userID}/${apartmentId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to check user-apartment association: ${errorText}`);
            }
    
            const data = await response.json();
            console.log('User-apartment association check:', data);
    
            return data.exists; // Assume the API returns a boolean `exists` field
        } catch (error) {
            console.error('Error checking user-apartment association:', error);
            throw error;
        }
    };
    

    const insertIntoWallet = async () => {
        try {
            const { dailyRate, daysPaidFor, paymentDate, monthYear } = calculateDailyRateAndDaysPaidFor();
            const response = await fetch('https://backendjaes.passiontrials.co.za/wallet/update', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: userID,
                    ApartmentId: apartmentId,
                    PaymentDate: paymentDate,
                    AmountPaid: amount,
                    DailyRate: dailyRate.toFixed(2),
                    DaysPaidFor: daysPaidFor,
                    MonthYear: monthYear,
                    PaymentMethod: "Yoco"
                }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Failed to update wallet');
            }

            //     setStatus('Payment successfully added to your wallet.');
            //     sessionStorage.setItem('paymentProcessed', 'true'); // Store the flag
            //   //  await transitionReserverToBooker();
            //     setTimeout(() => navigate('/userdashboard', { replace: true }), 3000);

        } catch (err) {
            console.error('Error updating wallet:', err);
            setError('Failed to process your wallet entry. Please try again.');
        }
    };


    const changeRole = async () => {
        try {
            // Ensure apartmentId exists before proceeding
            if (userID) {
                // Decrement the Capacity2 in the apartment_tb table
                const changeR = await fetch(`https://backendjaes.passiontrials.co.za/updateUserRole`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    
                    body: JSON.stringify({ userID, newRole: 'User' })
                });

                if (!changeR.ok) {
                    const errorText = await changeR.text();
                    throw new Error(`Failed to change role: ${errorText}`);
                }

                console.log('Role has been changed successfully');
            } else {
                console.log('No user found');
            }
        } catch (error) {
            throw new Error(`Error changing the role: ${error.message}`);
        }
    };

    const associateUserWithRoom = async () => {
        try {

            const now = new Date();
            const moveInDate = new Date(now);
            const moveOutDate = new Date(moveInDate); // Clone the move-in date
            moveOutDate.setMonth(moveOutDate.getMonth() + 1);

            // Format the moveOutDate to the required format
            const formattedMoveOutDate = moveOutDate.toLocaleString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
            });

            const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: userID,
                    ApartmentId: apartmentId,
                    MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    MoveOutDate: formattedMoveOutDate, // Use the formatted date
                    RentAmount: amount,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'transitioned from reserver to associated user',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: 'Paid in Full',
                    ServiceFee: 0,
                    RentCost: amount,
                    PayableRent: amount
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate reserver with apartment: ${errorText}`);
            }

            console.log('Reserver associated with apartment successfully!');



        } catch (error) {
            throw new Error(`Error associating Reserver with apartment: ${error.message}`);

        }
    };


    const markApartmentAsOccupied = async () => {
        try {
            // Ensure apartmentId exists before proceeding
            if (apartmentId) {
                // Decrement the Capacity2 in the apartment_tb table
                const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${apartmentId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!decrementCapacity.ok) {
                    const errorText = await decrementCapacity.text();
                    throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                }

                console.log('Apartment capacity has been decremented successfully');
            } else {
                console.log('No apartment or room found');
            }
        } catch (error) {
            throw new Error(`Error marking apartment as occupied: ${error.message}`);
        }
    };
    const RemoveUserFromReservation = async () => {
        const removeUser = await fetch(`https://backendjaes.passiontrials.co.za/api/reserve/cancel/${userID}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!removeUser.ok) {
            if (removeUser.status !== 404) { // Ignore 404 errors as this might be expected
                const errorText = await removeUser.text();
                throw new Error(`Failed to delete user reservation record: ${errorText}`);
            } else {
                console.log('No reservation record found for the user, which is expected.');
            }
        }
    };

    if (error) {
        return <div style={{ marginTop: '149px' }}>Error: {error}</div>;
    }

    return (
        <div style={{ marginTop: '149px' }}>
            <h1>{status || 'Processing your payment...'}</h1>
            <p>Please wait, you will be redirected shortly.</p>
        </div>
    );
}

export default Wallet;
