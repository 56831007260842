import React, { useState, useEffect } from 'react';
// import './NsfasBookings.css';

function NsfasApproval() {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBookings();
    }, []);

    const fetchBookings = () => {
        setLoading(true);
        fetch('https://backendjaes.passiontrials.co.za/api/nsfas-bookings')
            .then(res => res.json())
            .then(data => {
                setBookings(data);
                setLoading(false);
            })
            .catch(err => {
                console.error("Error fetching bookings:", err);
                setError("Failed to fetch bookings.");
                setLoading(false);
            });
    };

    const handleApproval = (id, status, user, room) => {
        fetch(`https://backendjaes.passiontrials.co.za/api/nsfas-bookings/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ApprovalStatus: status, // e.g., "Approved" or "Dismissed"
                Approval: status === "Approved" ? 1 : 0, // 1 for Approved, 0 for Dismissed
            }),
        })
            .then(res => res.json())
            .then(() => {
                if (status == 'Approved') {
                    associateUserWithApartment(room, user);
                    markApartmentAsOccupied(room);

                }

                if (status == 'Dismissed') {
                    dismissUser(room, user);
                }

                fetchBookings(); // Refresh bookings after update
            })
            .catch(err => {
                console.error(`Error updating booking with ID ${id}:`, err);
            });
    };

    const markApartmentAsOccupied = async (roomid) => {
        try {
            // Ensure apartmentId exists before proceeding
            if (roomid) {
                // Decrement the Capacity2 in the apartment_tb table
                const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${roomid}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!decrementCapacity.ok) {
                    const errorText = await decrementCapacity.text();
                    throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                }

                console.log('Apartment capacity has been decremented successfully');
            } else {
                console.log('No apartment or room found');
            }
        } catch (error) {
            throw new Error(`Error marking apartment as occupied: ${error.message}`);
        }
    };

    const associateUserWithApartment = async (room, user) => {
        try {
            //  const moveInDate = new Date(date);
            // const moveOutDate = new Date(moveInDate); // Clone the move-in date
            // moveOutDate.setMonth(moveOutDate.getMonth() + 1);

            // Format the moveOutDate to the required format
            // const formattedMoveOutDate = moveOutDate.toLocaleString('en-US', {
            //     day: 'numeric',
            //     month: 'long',
            //     year: 'numeric',
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     second: '2-digit',
            //     hour12: true,
            // });

            const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    // MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    // MoveOutDate: formattedMoveOutDate, // Use the formatted date
                    MoveInDate: 'test',
                    MoveOutDate: 'test',
                    RentAmount: 0,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'No specific notes',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: "NSFAS",
                    ServiceFee: 0,
                    RentCost: 0,
                    PayableRent: 0
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate user with apartment: ${errorText}`);
            }

            console.log('User associated with apartment successfully!');
        } catch (error) {
            throw new Error(`Error associating user with apartment: ${error.message}`);
        }
    };

    const dismissUser = async (roomid, userid) => {

        const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/delete-user-apartment/${userid}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!removeApartmentFromUser.ok) {
            if (removeApartmentFromUser.status !== 404) { // Ignore 404 errors as this might be expected
                const errorText = await removeApartmentFromUser.text();
                throw new Error(`Failed to delete user apartment record: ${errorText}`);
            } else {
                console.log('No apartment record found for the user, which is expected.');
            }
        } else {
            console.log('Apartment has been removed from user successfully');
        }

        // Increment the Capacity2 for the ApartmentId
        const incrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/increment-capacity/${roomid}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!incrementCapacity.ok) {
            if (incrementCapacity.status !== 404) { // Ignore 404 errors as this might be expected
                const errorText = await incrementCapacity.text();
                throw new Error(`Failed to increment apartment capacity: ${errorText}`);
            } else {
                console.log('No apartment record found to increment capacity, which may be expected.');
            }
        } else {
            console.log('Apartment capacity has been incremented successfully');
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return console.log('an error occured', error);
    }

    return (
        <div className="nsfas-bookings-container">
            <h2>NSFAS Bookings</h2>
            {bookings.length === 0 ? (
                <div>No bookings found.</div>
            ) :
                (<table className="nsfas-bookings-table">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>Email</th>
                            <th>Approval Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map(booking => (
                            <tr key={booking.BookingId}>
                                <td>{booking.BookNumber}</td>
                                <td>{booking.Name}</td>
                                <td>{booking.Surname}</td>
                                <td>{booking.Email}</td>
                                <td>{booking.ApprovalStatus}</td>
                                <td>
                                    <button
                                        onClick={() => handleApproval(booking.BookingId, "Approved", booking.UserId, booking.ApartmentId)}
                                        disabled={booking.ApprovalStatus === "Approved"}
                                        style={{ backgroundColor: 'green' }}

                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => handleApproval(booking.BookingId, "Dismissed", booking.UserId, booking.ApartmentId)}
                                        disabled={booking.ApprovalStatus === "Dismissed"}
                                    >
                                        Dismiss
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>)}
            <style>{`.nsfas-bookings-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nsfas-bookings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.nsfas-bookings-table th,
.nsfas-bookings-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
}

.nsfas-bookings-table th {
    background-color: #007bff;
    color: white;
}

.nsfas-bookings-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.nsfas-bookings-table button {
    padding: 8px 12px;
    margin: 0 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.nsfas-bookings-table button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.nsfas-bookings-table button:hover:not(:disabled) {
    background-color: #0056b3;
    color: white;
}
`}</style>
        </div>
    );
}

export default NsfasApproval;
