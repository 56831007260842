import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Add_building from "./Add_building";
//<style>{``}</style>
import Home_page from "./Home_page";
import PackagesAdmin from "./PackagesAdmin";
import Login from "./login";
import BuildingRegister from "./BuildingRegister";
import Register from "./register";
import RegisterAdmin from "./RegisterAdmin";
import Apartments from "./Apartments";
import AllApartments from "./AllApartments";
import Edit_building from "./Edit_building";
import ApartmentDetails from "./apartmentDetails";
import Invoice from "./Invoice";
import PDFPreview from "./PDFPreview";
import Reserve from "./Reserve";
import ReserveInvoice from "./ReserveInvoice.js";
import ReserveNew from "./ReserveNew.js";
import SignupTransport from "./SignupTransport.js";
import TransportPayment from "./TransportPayment.js";
import IntroTransport from "./IntroTransport.js";
import TransportuserDashboard from "./TransportuserDashboard.js";
import Payment from "./Payment";
import ReservePayment from "./Reservepayment";
import Cancel from "./cancel";
import UserDashboard from "./userDashboard";
import ReserveUserDashboard from "./ReserveUserDashboard";
import AdminDashboard from "./adminDashboard";
import { AuthProvider } from "./AuthProvider";
import ProtectedRoute from "./protectedRoute";
import PaymentProcess from "./paymentProcess";
import Navbar from "./navBar";
import MyApartment from "./myApartment";
import Profile from "./Profile";
import Practice from "./practice";
import RecurringPayment from "./recurringPayment";
import UserPayment from "./userPayment";
import UserInvoice from "./userInvoice";
import CheckOut from "./checkOut";
import CheckOutAdmin from "./checkOutAdmin";
import TransactionInvoice from "./transactionInvoice";
import AdminApartments from "./adminApartments";
import AdminApartmentDetails from "./adminApartmentDetails";
import SingleTransaction from "./singleTransaction";
import Customers from "./customers";
import IndividualCustomer from "./individualCustomer";
import Manage from "./manage";
import YOCO from "./YOCO";
import Design from "./design";
import YocoPayment from "./yocoPayment";
import ReserveYocoPayment from "./ReserveYocoPayment";
import Transport from "./Transport";
import TransportAdmin from "./TransportAdmin";
import TransportAdminPayment from "./TransportAdminPayment.js";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Building from "./Building";
import Wallet from "./Wallet";
import WalletAdmin from "./WalletAdmin";
import ContactUs from "./ContactUs.js";
import ServiceBox from "./ServiceBox.js";
import ServiceBoxAdmin from "./ServiceBoxAdmin.js";
import Event_Ticket from "./Event_Ticket.js";
import ThankYou from "./ThankYou.js"; // Import the ThankYou component
import EventDetails from "./EventDetails.js";
import Event_Ticket_Student from "./Event_Ticket_Student.js";
import Amber from "./Amber.js";
import ListHomes from "./ListHomes.js";

import SingleUserPayment from "./singleUserPayments";
import AccessedUserPayment from "./AccessedUserPayment";
import Test from "./test.js";
import WaterSlideshow from "./ImageSlideshow.js";

import NewAdminDashboard from "./newAdminDashboard.js";
import UserAccess from "./userAccess.js";
import AccessedProfile from "./userAcccessedProfile.js";
import Properties from "./Properties.js";
import RegularAdmin from "./regularAdminDashboard.js";
import LandLordProperties from "./landLordProperties.js";
import LandLord from "./landLordCustomer.js";
import RegularAdminPay from "./regularUserPayment.js";
import StepsAdmin from "./StepsAdmin.js";
import Steps2Admin from "./Steps2Admin.js";
import Steps3Admin from "./Steps3Admin.js";
import Steps3_5Admin from "./Steps3_5Admin.js";
import Steps3_2Admin from "./Steps3_2Admin.js";
import Steps3_1Admin from "./Steps3_1Admin.js";
import Steps4Admin from "./Steps4Admin.js";
import Steps5Admin from "./Steps5Admin.js";
import Steps6Admin from "./Steps6Admin.js";
import Steps7Admin from "./Steps7Admin.js";
import Steps8Admin from "./Steps8Admin.js";
import Steps9Admin from "./Steps9Admin.js";
import NewReserve from "./newReserve.js";
import NewInvoice4 from "./newInvoice4.js";
import OnDragEnd from "./onDragEnd.js";

import ReserveCustomers from "./reserveCustomers.js";
import ReserveUserAccess from "./reserveUserAccess.js";
import ReserveApproval from "./reserveApproval.js";
import NsfasApproval from "./nsfasApproval.js";
import EftBookings from "./eftApproval.js";
import VerificationPopup from "./loginVerificationPopup.js";
import TransportHistory from "./transportHistory.js";
import AllTransportHistory from "./allTransportHistory.js";

import RecurringTransport from "./recurringTransport.js";
import TransportWallet from "./transportWallet.js";
import TransportCancel from "./transportCancel.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <AppContent /> {/* Moved content into a separate component */}
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}
// Extracted the logic for conditional rendering into a separate component
function AppContent() {
  const location = useLocation(); // Hook to get the current route
  // Define the routes where you want to hide the Navbar
  const excludeNavbarRoutes = ["/allapartments", "/"]; // Add any other routes you want to exclude
  return (
    <>
      {/* Conditionally render the Navbar based on the current route */}
      {!excludeNavbarRoutes.includes(location.pathname) && <Navbar />}
      <Routes>
        <Route path="/" element={<Home_page />} />
        <Route path="/stepsAdmin" element={<StepsAdmin />} />
        <Route path="/onDragEnd" element={<OnDragEnd />} />
        <Route path="/steps2Admin" element={<Steps2Admin />} />
        <Route path="/steps3Admin" element={<Steps3Admin />} />
        <Route path="/steps3_5Admin" element={<Steps3_5Admin />} />
        <Route path="/steps3_2Admin" element={<Steps3_2Admin />} />
        <Route path="/steps3_1Admin" element={<Steps3_1Admin />} />
        <Route path="/steps4Admin" element={<Steps4Admin />} />
        <Route path="/steps5Admin" element={<Steps5Admin />} />
        <Route path="/steps6Admin" element={<Steps6Admin />} />
        <Route path="/steps7Admin" element={<Steps7Admin />} />
        <Route path="/steps8Admin" element={<Steps8Admin />} />
        <Route path="/steps9Admin" element={<Steps9Admin />} />
        <Route path="/PackagesAdmin" element={<PackagesAdmin />} />
        <Route path="/buildingRegister" element={<BuildingRegister />} />
        <Route path="/Event_Ticket" element={<Event_Ticket />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route
          path="/event-details"
          element={<ProtectedRoute element={<EventDetails />} />}
        />
        <Route
          path="/Event_Ticket_Student"
          element={<Event_Ticket_Student />}
        />
        <Route path="/test" element={<Test />} />
        <Route path="/ImageSlideshow" element={<WaterSlideshow />} />
        <Route path="/Apartments/:buildingId" element={<Apartments />} />
        <Route
          path="/Add_building"
          element={<ProtectedRoute element={<Add_building />} />}
        />
        <Route
          path="/Edit_building/:buildingId"
          element={<ProtectedRoute element={<Edit_building />} />}
        />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/RegisterAdmin" element={<RegisterAdmin />} />
        <Route path="/ListHomes" element={<ListHomes />} />
        <Route path="/AllApartments" element={<AllApartments />} />
        <Route
          path="/ApartmentDetails/:apartmentId"
          element={<ApartmentDetails />}
        />
        <Route path="/Invoice" element={<Invoice />} />
        <Route path="/Reserve" element={<Reserve />} />
        <Route path="/ReserveInvoice" element={<ReserveInvoice />} />
        <Route path="/ReserveNew" element={<ReserveNew />} />
        <Route
          path="/UserInvoice/:id"
          element={<ProtectedRoute element={<UserInvoice />} />}
        />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/ReservePayment" element={<ReservePayment />} />
        <Route path="/Cancel" element={<Cancel />} />
        <Route path="/Practice" element={<Practice />} />
        <Route path="/Design" element={<Design />} />
        <Route path="/Building" element={<Building />} />
        <Route
          path="/SingleUserPayment"
          element={<ProtectedRoute element={<SingleUserPayment />} />}
        />
        <Route
          path="/AccessedUserPayment/:userId"
          element={<ProtectedRoute element={<AccessedUserPayment />} />}
        />
        <Route
          path="/AdminApartmentDetails/:id"
          element={<AdminApartmentDetails />}
        />
        <Route
          path="/UserPayment"
          element={<ProtectedRoute element={<UserPayment />} />}
        />
        <Route path="/TransactionInvoice" element={<TransactionInvoice />} />
        <Route path="/SingleTransaction/:id" element={<SingleTransaction />} />
        <Route
          path="/CheckOut"
          element={<ProtectedRoute element={<CheckOut />} />}
        />
        <Route
          path="/CheckOutAdmin"
          element={<ProtectedRoute element={<CheckOutAdmin />} />}
        />
        <Route path="/customers" element={<Customers />} />
        <Route path="/PDFPreview" element={<PDFPreview />} />
        <Route path="/amber" element={<Amber />} />
        <Route path="/Manage" element={<Manage />} />
        <Route path="/YOCO" element={<YOCO />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/ServiceBox" element={<ServiceBox />} />
        <Route path="/ServiceBoxAdmin" element={<ServiceBoxAdmin />} />
        <Route
          path="/IndividualCustomer/:UserId"
          element={<IndividualCustomer />}
        />
        <Route
          path="/UserDashboard/*"
          element={<ProtectedRoute element={<UserDashboard />} />}
        >
          <Route
            path="MyApartment/:userId/:apartmentId"
            element={<MyApartment />}
          />
        </Route>
        <Route
          path="/ReserveUserDashboard"
          element={<ProtectedRoute element={<ReserveUserDashboard />} />}
        />
        <Route
          path="/TransportuserDashboard"
          element={<ProtectedRoute element={<TransportuserDashboard />} />}
        />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword/:token" element={<ResetPassword />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="/Transport" element={<Transport />} />
        <Route path="/SignupTransport" element={<SignupTransport />} />
        <Route path="/TransportPayment" element={<TransportPayment />} />
        <Route path="/IntroTransport" element={<IntroTransport />} />
        <Route path="/transport-admin" element={<TransportAdmin />} />{" "}
        {/* Add the admin page route */}
        <Route
          path="/TransportAdminPayment"
          element={<TransportAdminPayment />}
        />{" "}
        {/* Add the admin page route */}
        <Route
          path="/AdminDashboard"
          element={<ProtectedRoute element={<AdminDashboard />} />}
        />
        <Route
          path="/AdminApartments"
          element={<ProtectedRoute element={<AdminApartments />} />}
        />
        <Route
          path="/NewAdminDashboard"
          element={<ProtectedRoute element={<NewAdminDashboard />} />}
        />
        <Route
          path="/RegularAdmin"
          element={<ProtectedRoute element={<RegularAdmin />} />}
        />
        <Route
          path="/RegularAdminPay"
          element={<ProtectedRoute element={<RegularAdminPay />} />}
        />
        <Route
          path="/AdminApartments"
          element={<ProtectedRoute element={<AdminApartments />} />}
        />
        <Route
          path="/UserAccess/:userAccess"
          element={<ProtectedRoute element={<UserAccess />} />}
        />
        <Route
          path="/AccessedProfile/:userId"
          element={<ProtectedRoute element={<AccessedProfile />} />}
        />
        <Route
          path="/Properties"
          element={<ProtectedRoute element={<Properties />} />}
        />
        <Route
          path="/LandLordProperties"
          element={<ProtectedRoute element={<LandLordProperties />} />}
        />
        <Route
          path="/LandLord"
          element={<ProtectedRoute element={<LandLord />} />}
        />
        <Route
          path="/Wallet"
          element={<ProtectedRoute element={<Wallet />} />}
        />
        <Route
          path="/WalletAdmin"
          element={<ProtectedRoute element={<WalletAdmin />} />}
        />
        <Route
          path="/PaymentProcess"
          element={<ProtectedRoute element={<PaymentProcess />} />}
        />
        <Route
          path="/YocoPayment"
          element={<ProtectedRoute element={<YocoPayment />} />}
        />
        <Route
          path="/ReserveYocoPayment"
          element={<ProtectedRoute element={<ReserveYocoPayment />} />}
        />
        <Route
          path="/RecurringPayment"
          element={<ProtectedRoute element={<RecurringPayment />} />}
        />
        <Route path="/NewReserve" element={<NewReserve />} />
        <Route path="/NewInvoice4" element={<NewInvoice4 />} />
        <Route path="/ReserveCustomers" element={<ReserveCustomers />} />
        <Route
          path="/ReserveUserAccess/:userId"
          element={<ProtectedRoute element={<ReserveUserAccess />} />}
        />
        <Route
          path="ReserveApproval/"
          element={<ProtectedRoute element={<ReserveApproval />} />}
        />
        <Route
          path="NsfasApproval/"
          element={<ProtectedRoute element={<NsfasApproval />} />}
        />
        <Route
          path="EftBookings/"
          element={<ProtectedRoute element={<EftBookings />} />}
        />
        <Route
          path="VerificationPopup /"
          element={<ProtectedRoute element={<VerificationPopup />} />}
        />
        <Route
          path="/TransportHistory/:userId"
          element={<TransportHistory />}
        />
        <Route path="/AllTransportHistory" element={<AllTransportHistory />} />
        <Route
          path="/TransportWallet"
          element={<ProtectedRoute element={<TransportWallet />} />}
        />
        <Route
          path="/TransportCancel"
          element={<ProtectedRoute element={<TransportCancel />} />}
        />
        <Route
          path="/RecurringTransport"
          element={<ProtectedRoute element={<RecurringTransport />} />}
        />
      </Routes>
    </>
  );
}
export default App;
