import React from 'react';
import { useNavigate } from 'react-router-dom';

function TransportCancel() {
    const navigate = useNavigate();

    const handleReturnHome = () => {
        navigate('/'); // Navigate to the homepage or another appropriate route
    };

    const handleRetryPayment = () => {
        navigate('invoice'); // Navigate to the payment retry page or similar route
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Transport payment Cancelled</h1>
            <p>You have cancelled the payment process.</p>
            <p>If this was an error, you can attempt to make the payment again.</p>
            <div>
                <button onClick={handleReturnHome} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#ff5a5f', color: 'white', border: 'none', borderRadius: '5px', marginRight: '10px' }}>
                    Return Home
                </button>
                {/* <button onClick={handleRetryPayment} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#ff5a5f', color: 'white', border: 'none', borderRadius: '5px' }}>
                    Retry Payment
                </button> */}
            </div>
        </div>
    );
}

export default TransportCancel;




